import React, { FC } from 'react';
import { Checkbox } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { RulesExtend } from 'styles/theme';

import { Button, Icon, IconType } from 'modules/ui';
import { DocumentFile, DocumentDownloadType, useDownloadDocuments } from 'modules/entities/modules/documents';

import * as felaRules from './ItemActions.styles';

type ItemActionsProps = {
    id: string;
    file: DocumentFile;
    extend?: RulesExtend<typeof felaRules>;
};

const ItemActions: FC<ItemActionsProps> = ({ id, file, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    const { selectDocument, selectedDocuments, unselectDocument, downloadDocuments } = useDownloadDocuments();

    return (
        <div className={styles.actions}>
            {file && (
                <>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            downloadDocuments([file], DocumentDownloadType.DOWNLOAD);
                        }}
                        icon={<Icon type={IconType.DOWNLOAD} />}
                        type="link"
                        size="small"
                    />

                    <Checkbox
                        className={styles.checkbox}
                        onClick={e => {
                            // When the checkbox is clicked, we don't want the onClick on table table row to be executed.
                            e.stopPropagation();
                        }}
                        onChange={e => {
                            if (e.target.checked) {
                                selectDocument([id]);
                            } else {
                                unselectDocument(id);
                            }
                        }}
                        checked={selectedDocuments.includes(id)}
                    />
                </>
            )}
        </div>
    );
};

export default ItemActions;
