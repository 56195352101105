import { put } from 'redux-saga/effects';
import { noop } from 'lodash';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';
import { takeRequest } from 'services/sagas/takeRequest';

import type { ElevatorDetail } from '../../types';
import { parseFloor } from '../utils';
import { fetchElevatorTypes, fetchElevator as actions } from '../actions';

const transformElevatorData = (elevator: any) => ({
    ...elevator,
    currentFloor: elevator.currentFloor
        ? {
              ...elevator.currentFloor,
              floor: parseFloor(elevator.currentFloor.floor),
          }
        : elevator.currentFloor,
    floorMapping: elevator.floorMapping
        ? elevator.floorMapping.map((mapping, index: number) => ({
              ...mapping,
              floor: parseFloor(mapping.floor, index),
          }))
        : elevator.floorMapping,
});

function* fetchElevator(action) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.get<ElevatorDetail>(config.api.elevator, {
            uriParams: { id },
        });

        yield put(actions.fetchElevatorSuccess(id, transformElevatorData(data)));
    } catch (error) {
        const uiError = createUIErrorMessage(error);

        log.error(error);

        yield put(actions.fetchElevatorFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchElevatorRequests',
        },
        {
            pattern: fetchElevatorTypes.FETCH_ELEVATOR_REQUEST,
            handler: fetchElevator,
        },
        {
            pattern: fetchElevatorTypes.FETCH_ELEVATOR_CANCEL,
            handler: noop,
        },
    );
}
