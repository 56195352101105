import { Cluster } from '@googlemaps/markerclusterer';

import type { ElevatorGroup } from 'modules/entities/modules/elevators';

import type { MarkerWithElevatorGroup } from '../types';

export const prevInfoWindows = new Map<google.maps.Map, google.maps.InfoWindow>();

export const createInfoWindow = (
    map: google.maps.Map,
    anchor: google.maps.MVCObject,
    options: {
        marker?: google.maps.InfoWindowOptions;
        cluster?: (props: ElevatorGroup[]) => google.maps.InfoWindowOptions;
    },
) => {
    const infoWindow = new google.maps.InfoWindow();

    google.maps.event.addListener(map, "click", function(event) {
        infoWindow.close()
    });

    const onMarkerClick = () => {
        prevInfoWindows.get(map)?.close();

        infoWindow.setOptions(options.marker);
        infoWindow.open({
            anchor,
            map,
            shouldFocus: false,
        });

        prevInfoWindows.set(map, infoWindow);
    };

    const onClusterClick = (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => {
        const elevatorGroups = cluster.markers.map((marker: MarkerWithElevatorGroup) => marker.elevatorGroup);

        prevInfoWindows.get(map)?.close();

        infoWindow.setOptions(options.cluster(elevatorGroups));
        infoWindow.setPosition(cluster.bounds.getCenter());
        infoWindow.open({
            anchor,
            map,
            shouldFocus: true,
        });

        prevInfoWindows.set(map, infoWindow);
    };

    return { infoWindow, onMarkerClick, onClusterClick };
};
