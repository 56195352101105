import { basicApiReducer } from '@ackee/redux-utils';
import { fetchWorkorderTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchWorkorderTypes.FETCH_WORKORDERS_REQUEST,
        SUCCESS: fetchWorkorderTypes.FETCH_WORKORDERS_SUCCESS,
        FAILURE: fetchWorkorderTypes.FETCH_WORKORDERS_FAILURE,
        CANCEL: fetchWorkorderTypes.FETCH_WORKORDERS_CANCEL,
        RESET: fetchWorkorderTypes.FETCH_WORKORDERS_RESET,
    },
});
