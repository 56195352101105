import { createSelector } from 'reselect';
import { apiSelector, ApiReducerState } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey } from 'constants/index';

import { ElevatorDetail } from '../../types';

export const selectElevatorApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.ELEVATORS, ApiReducerKey.DETAIL);

export const selectElevator = (state): ElevatorDetail => state.entities[EntityKey.ELEVATORS][ApiReducerKey.DETAIL];

export const selectElevatorId = createSelector(selectElevator, elevator => elevator?.id);
export const selectElevatorState = createSelector(selectElevator, elevator => elevator?.state?.state);

export const selectElevatorFloorLabel = createSelector(
    selectElevator,
    (_: any, { floor }: { floor: number }) => floor,
    (elevator, floor) => elevator?.floorMapping?.find(mapping => mapping.floor === floor)?.label ?? floor,
);

export const selectElevatorFloor = createSelector(selectElevator, elevator => elevator?.currentFloor ?? null);

export const selectElevatorSubStates = createSelector(selectElevator, elevator => elevator?.state?.subStates);
