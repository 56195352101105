import { useSelector } from 'react-redux';

import { useFetchInvoices, selectors } from 'modules/entities/modules/documents-invoices';
import { useDisplayError } from 'modules/errors';
import type { Search, Sorting } from 'constants/index';
import { SortingType } from 'constants/index';
import { Fields } from '../components/InvoicesListTab/types';

export const defaultSort: Sorting = { field: Fields.INVOICE_DATE, type: SortingType.DESC };

const useInvoiceList = (sorting?: Sorting, search?: Search[]) => {
    const api = useFetchInvoices();
    const invoices = useSelector(state =>
        selectors.selectSortedAndFilteredInvoices(state, sorting ?? defaultSort, search),
    );
    useDisplayError(api.error);

    return { invoices, api };
};

export default useInvoiceList;
