import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch, useTableSearch, TableSearchSelect, TableSearchDateRange } from 'modules/table';
import {
    useAssessmentTypeOptions,
    useAssessmentResultOptions,
    AssessmentReportField,
} from 'modules/entities/modules/documents-assessment-reports';

export interface AssessmentReportsSearchProps {
    defaultValue?: SearchValue;
    isElevatorDetail: boolean;
}

const defaultSearch = {};

export const AssessmentReportsSearch = ({
    defaultValue = defaultSearch,
    isElevatorDetail,
}: AssessmentReportsSearchProps) => {
    const intl = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const assessmentTypeOptions = useAssessmentTypeOptions();
    const assessmentResultOptions = useAssessmentResultOptions();

    return (
        <TableSearch
            fields={[
                ...(isElevatorDetail
                    ? []
                    : [
                          {
                              label: <FormattedMessage id={`documents.search.elevator.factoryNumber`} />,
                              labelFor: 'factoryNumber',
                              renderer: (
                                  <Input
                                      id="factoryNumber"
                                      data-testid="factory-number"
                                      value={value[AssessmentReportField.ELEVATOR_FACTORY_NUMNER]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [AssessmentReportField.ELEVATOR_FACTORY_NUMNER]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                          {
                              label: <FormattedMessage id={`documents.search.elevator.street`} />,
                              labelFor: 'street',
                              renderer: (
                                  <Input
                                      id="street"
                                      value={value[AssessmentReportField.ELEVATOR_PROPERTY_UNIT_STREET]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [AssessmentReportField.ELEVATOR_PROPERTY_UNIT_STREET]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                          {
                              label: <FormattedMessage id={`documents.search.elevator.city`} />,
                              labelFor: 'city',
                              renderer: (
                                  <Input
                                      id="city"
                                      data-testid="city"
                                      value={value[AssessmentReportField.ELEVATOR_PROPERTY_UNIT_CITY]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [AssessmentReportField.ELEVATOR_PROPERTY_UNIT_CITY]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                        //Note: Uncomment this once pagination is implemented [Temp fix]
                        //   {
                        //       label: <FormattedMessage id={`documents.search.elevator.businessUnit`} />,
                        //       labelFor: 'businessUnit',
                        //       renderer: (
                        //           <Input
                        //               id="businessUnit"
                        //               value={value[AssessmentReportField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]}
                        //               placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                        //               onChange={({ target }) => {
                        //                   handleValue({
                        //                       [AssessmentReportField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]:
                        //                           target.value,
                        //                   });
                        //               }}
                        //           />
                        //       ),
                        //   },
                      ]),
                {
                    label: <FormattedMessage id={`documents.assessment-reports.search.assessmentDate`} />,
                    labelFor: 'assessmentDate',
                    renderer: (
                        <TableSearchDateRange
                            id="assessmentDate"
                            value={{
                                from: value[AssessmentReportField.ASSESSMENT_REPORT_DATE_FROM],
                                to: value[AssessmentReportField.ASSESSMENT_REPORT_DATE_TO],
                            }}
                            onChange={(from, to) => {
                                handleValue({
                                    [AssessmentReportField.ASSESSMENT_REPORT_DATE_FROM]: from,
                                    [AssessmentReportField.ASSESSMENT_REPORT_DATE_TO]: to,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.assessment-reports.search.assessmentType`} />,
                    labelFor: 'assessmentType',
                    renderer: (
                        <TableSearchSelect
                            id="assessmentType"
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                            options={assessmentTypeOptions}
                            value={value[AssessmentReportField.ASSESSMENT_REPORT_TYPE]}
                            disabled={isEmpty(assessmentTypeOptions)}
                            onChange={value => {
                                handleValue({
                                    [AssessmentReportField.ASSESSMENT_REPORT_TYPE]: value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.assessment-reports.search.result`} />,
                    labelFor: 'result',
                    renderer: (
                        <TableSearchSelect
                            id="result"
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                            options={assessmentResultOptions}
                            value={value[AssessmentReportField.RESULT]}
                            disabled={isEmpty(assessmentResultOptions)}
                            onChange={value => {
                                handleValue({
                                    [AssessmentReportField.RESULT]: value,
                                });
                            }}
                        />
                    ),
                },
            ]}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
        />
    );
};
