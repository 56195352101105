import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import type { DocumentElevator as DocumentElevatorType } from 'modules/entities/modules/documents';

import { TableDataSpace } from 'modules/table';
import { Paragraph } from 'modules/ui';

import * as felaRules from './DocumentElevator.styles';
import { generatePath, Link } from 'react-router-dom';
import config from 'config';

interface DocumentElevatorWithUnitProps {
    elevator: DocumentElevatorType;
    hideBusinessUnit?: boolean;
}

export const DocumentElevator: FC<DocumentElevatorWithUnitProps> = ({ elevator, hideBusinessUnit = false }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <div className={styles.elevator}>
                {elevator?.elevatorId ? (
                    <Link
                        to={generatePath(config.routes.elevatorDetail, { id: elevator?.elevatorId })}
                        onClick={e => e.stopPropagation()}
                    >
                        <Paragraph className={styles.factoryNumber} extend={{ paragraph: rules.factoryNumber }}>
                            {elevator.factoryNumber}
                        </Paragraph>
                    </Link>
                ) : (
                    <Paragraph className={styles.factoryNumber} extend={{ paragraph: rules.factoryNumber }}>
                        {elevator.factoryNumber}
                    </Paragraph>
                )}

                <Paragraph extend={{ paragraph: rules.street }}>{elevator.propertyUnit.street}</Paragraph>
                <Paragraph extend={{ paragraph: rules.city }}>{elevator.propertyUnit.city}</Paragraph>
            </div>
            {!hideBusinessUnit ? (
                <div className={styles.businessUnit}>
                    <TableDataSpace
                        key={elevator.factoryNumber}
                        title={<FormattedMessage id="documents.table.businessUnit" />}
                        value={elevator.propertyUnit.businessUnit ?? <>&nbsp;</>}
                    />
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};
