import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

import { DialogState, SupportDialogType } from 'constants/common';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

export const NewSupportButton = () => {
    const { navigate } = useContext(GlobalDialogContext);

    return (
        <Button
            type="primary"
            size="large"
            onClick={() => {
                navigate(DialogState.CREATE_SUPPORT, null, { type: SupportDialogType.SUPPORT });
            }}
        >
            <FormattedMessage id="support.request.new.trigger" />
        </Button>
    );
};
