import { basicResetReducer } from '@ackee/redux-utils';

import type { ElevatorState } from 'modules/entities/modules/elevators';

import { ApiAction } from '../../../../../types';
import { fetchElevatorStatesTypes } from '../../actions';

const initialState: ElevatorState[] = [];

const listReducer = (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_SUCCESS: {
            return action.payload;
        }

        default:
            return state;
    }
};

export default basicResetReducer(listReducer, fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_RESET);
