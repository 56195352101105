import React from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './WorkOrdersMap.rules';
import { WorkOrdersMap } from 'modules/workorders-map';

export const WorkOrdersPageMap = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <WorkOrdersMap />
        </div>
    );
};
