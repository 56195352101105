import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    changeRoleRequestFailure,
    changeRoleRequest,
    changeRoleRequestSuccess,
    changeRoleRequestReset,
} from '../../actions'
import { CHANGE_ROLE_SELECTOR_ID } from '../../../types';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: changeRoleRequest.toString(),
        SUCCESS: changeRoleRequestSuccess.toString(),
        FAILURE: changeRoleRequestFailure.toString(),
        RESET: changeRoleRequestReset.toString(),
    },
});

export const changeRoleReducer = containerReducer({
    childReducer,
    actionTypes: [
        changeRoleRequest.toString(),
        changeRoleRequestSuccess.toString(),
        changeRoleRequestFailure.toString(),
        changeRoleRequestReset.toString(),
    ],
    selectors: {
        itemId: () => CHANGE_ROLE_SELECTOR_ID,
    },
});