import React from 'react';
import { Button, Form } from 'antd';
import * as felaRules from './TeamDialogFooter.styles';
import { useFelaEnhanced } from 'hooks';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';

interface TeamDialogFooterProps {
    disabled?: boolean;
    beforeButtons?: React.ReactNode;
    onclose?: () => void
    onConfirm?: () => void
}

const TeamDialogFooter = ({ disabled, beforeButtons, onclose, onConfirm }: TeamDialogFooterProps) => {
    const { styles, css } = useFelaEnhanced(felaRules);
    const { setState } = useTeamDialog();

    const handleClose = () => {
        setState(null);
        onclose?.()
    };

    return (
        <div className={styles.footer}>
            <div>{beforeButtons}</div>
            <div className={styles.buttons}>
                <Button key="back" onClick={handleClose} className={styles.button} disabled={disabled}>
                    Cancel
                </Button>
                <Form.Item
                    className={css({
                        marginBottom: '0px !important',
                    })}
                >
                    <Button onClick={onConfirm} className={styles.button} htmlType="submit" type="primary" disabled={disabled}>
                        Confirm
                    </Button>
                </Form.Item>
            </div>
        </div>
    );
};

export default TeamDialogFooter;
