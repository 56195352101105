import { combineReducers } from '@reduxjs/toolkit';
import { ApiReducerKey } from 'constants/index';

import { listReducerFactory } from 'services/utils/reducers';

import { fetchWorkordersReset, fetchWorkordersSuccess } from '../actions';

export const entityReducer = combineReducers({
    [ApiReducerKey.LIST]: listReducerFactory({
        setActionCreator: fetchWorkordersSuccess,
        resetActionCreator: fetchWorkordersReset,
    }),
});
