import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { Select, FloatingLabel } from 'modules/form';
import { Icon, IconType } from 'modules/ui';
import { DialogState, useNewElevatorDialog } from 'modules/elevator-new';
import { GeneralDocumentsUploadType } from 'modules/entities/modules/documents';

import * as felaRules from './GeneralUploadTypeInput.rules';
export interface GeneralUploadTypeInputProps {
    value: GeneralDocumentsUploadType | null;
    onChange: (value: GeneralDocumentsUploadType) => void;
    disabled: boolean;
}

export const GeneralUploadTypeInput = ({ value = null, onChange, disabled }: GeneralUploadTypeInputProps) => {
    const intl = useIntl();

    const { styles } = useFelaEnhanced(felaRules);

    const { navigate } = useNewElevatorDialog();

    const options = Object.values(GeneralDocumentsUploadType).map(type => ({
        label: intl.formatMessage({
            id: `documents.generalUpload.type.${type}`,
        }),
        value: type,
    }));

    return (
        <div className={styles.container}>
            <FloatingLabel
                htmlFor="type"
                floating={Boolean(value)}
                label={intl.formatMessage({
                    id: 'documents.generalUpload.type.label',
                })}
            >
                {({ onFocus, onBlur }) => (
                    <Select
                        id="type"
                        value={value}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        placeholder={intl.formatMessage({
                            id: 'documents.generalUpload.type.placeholder',
                        })}
                        fullWidth
                        floatingLabel
                        data-testid="general-upload-type-input"
                    />
                )}
            </FloatingLabel>
            <Typography.Paragraph type="secondary" className={styles.info}>
                <FormattedMessage id="documents.generalUpload.type.info" />
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary" className={styles.notice}>
                <Icon type={IconType.INFO} className={styles.icon} />
                <div>
                    <FormattedMessage
                        id="documents.generalUpload.type.notice"
                        values={{
                            title: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
                            cta: (...chunks: ReactNode[]) => (
                                <Button
                                    type="link"
                                    className={styles.cta}
                                    onClick={() => {
                                        navigate(DialogState.SELECTION);
                                    }}
                                >
                                    {chunks}
                                </Button>
                            ),
                        }}
                    />
                </div>
            </Typography.Paragraph>
        </div>
    );
};
