import React, { FC, ReactNode } from 'react';
import { FormattedTime, FormattedMessage, FormattedDate } from 'react-intl';
import { Alert } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { IconType, Paragraph } from 'modules/ui';

import * as felaRules from './SubSectionDetailStatus.styles';

export interface SectionMeta {
    isOkState?: boolean;
    iconType?: IconType;
    parentState?: string;
    priorityField?: string;
    name: string;
    subStates?: any;
}

export interface SubSectionDetailStatusProps {
    date?: string;
    formattedDate?: string;
    title: ReactNode;
    icon: ReactNode;
    layout?: 'vertical' | 'horizontal';
    extend?: RulesExtend<typeof felaRules>;
    subSection?: SectionMeta;
}

const SubSectionDetailStatus: FC<SubSectionDetailStatusProps> = ({
    date,
    formattedDate,
    title,
    icon,
    layout = 'vertical',
    extend,
    subSection,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, layout });
    return (
        <Alert
            type="info"
            className={styles.alert}
            showIcon
            message={
                <>
                    {(date || formattedDate) && (
                        <p className={styles.date}>
                            {formattedDate ?? <FormattedTime value={date} timeZone="Europe/Berlin" />}
                        </p>
                    )}
                    <h3 className={styles.title}>{title}</h3>
                    {subSection && (
                        <div className={styles.descriptionWrapper}>
                            {subSection?.isOkState ? (
                                <Paragraph key={subSection?.parentState} size="small" faded>
                                    <FormattedMessage id={`healthstate.section.${subSection?.parentState}.OK`} />
                                </Paragraph>
                            ) : (
                                subSection?.subStates?.map(subState => (
                                    <Paragraph key={`${subSection?.parentState}_${subState?.name}`} size="small" faded>
                                        <FormattedMessage
                                            id={`healthstate.section.${subState.name}`}
                                            values={{
                                                variable:
                                                    subState.priorityField === 'createdAt' ? (
                                                        <>
                                                            <FormattedDate
                                                                value={
                                                                    subState[subState.priorityField]
                                                                        ? subState[subState.priorityField]
                                                                        : ''
                                                                }
                                                                timeZone="Europe/Berlin"
                                                            />
                                                            ,{' '}
                                                            <FormattedTime
                                                                value={subState[subState.priorityField]}
                                                                timeZone="Europe/Berlin"
                                                            />
                                                        </>
                                                    ) : (
                                                        subState[subState.priorityField]
                                                    ),
                                                floorname: `${subState.lastFloor}`,
                                                events: `${subState.lastTripDoorCloseCount}`,
                                            }}
                                        />
                                    </Paragraph>
                                ))
                            )}
                        </div>
                    )}
                </>
            }
            icon={icon}
        />
    );
};

export default SubSectionDetailStatus;
