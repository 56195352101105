import { TRuleWithTheme } from 'styles/theme';

export const alert: TRuleWithTheme = ({ theme }) => ({
    marginTop: '-1rem',
    marginBottom: '1.75rem',

    padding: '0.875rem',
    whiteSpace: 'pre-line',
    borderColor: theme.colors.info,

    '& .ant-alert-message': {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.43em',

        color: theme.colors.textGrey,
    },
    '& .ant-alert-icon': {
        color: theme.colors.icon,
    },
    '& a': {
        color: '#1A86E5',
        textDecoration: 'underline'
    },
});

export const icon: TRuleWithTheme = () => ({
    width: '1em',
    height: '1em',

    marginRight: '0.5em',

    fontSize: '1.5rem',
    alignSelf: 'flex-start',
});
