import React from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import * as felaRules from './BrowserIncompatiblePage.rules';
import { Link } from 'react-router-dom';
import { ReactComponent as WhiteLogo } from 'assets/images/logo_white.svg';
import { RulesExtend } from 'styles/theme';
import config from 'config';
import { WarningOutlined } from '@ant-design/icons';
import { Icon, IconType } from 'modules/ui';
import { BROWSER_URLS } from 'modules/browser-incompability/config';

export interface BrowserIncompatiblePageProps {
    extend?: RulesExtend<typeof felaRules>;
}
export const BrowserIncompatiblePage = ({ extend }: BrowserIncompatiblePageProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Link to={config.routes.home}>
                    <WhiteLogo className={styles.logo} />
                </Link>
            </div>

            <div className={styles.heading}>
                <FormattedMessage id="page.browserIncompatible.title" />
                <WarningOutlined className={styles.icon} />
            </div>

            <div className={styles.subHeading}>
                <div>
                    <FormattedMessage id="page.browserIncompatible.detail.1" />
                </div>

                <div className={styles.detail2}>
                    <FormattedMessage id="page.browserIncompatible.detail.2" />
                </div>
                <div className={styles.browserList}>
                    {BROWSER_URLS.map(url => {
                        return (
                            <div key={url.key} className={styles.browserListIcons}>
                                {<Icon type={IconType[url.key]} className={styles.browserIcons} />}
                                <a className={styles.urlLink} target="_blank" rel="noopener noreferrer" href={url.url}>
                                    {url.browser}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
