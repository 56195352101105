import React from 'react';

export const NoActiveIncident = () => (
    <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.425 7.5875C27.625 7.3625 27.7625 7.075 27.7625 6.75C27.7625 6.0625 27.2 5.5 26.5125 5.5H10.4875L6.9875 0.5L4.9125 1.8875L7.4375 5.5H1.5C0.8125 5.5 0.25 6.0625 0.25 6.75C0.25 7.075 0.375 7.3625 0.5875 7.5875L12.7625 20.9875V33H7.75V35.5H20.25V33H15.25V20.9875L27.425 7.5875ZM12.75 9.25C12.75 9.9375 12.1875 10.5 11.5 10.5C10.8125 10.5 10.25 9.9375 10.25 9.25C10.25 8.5625 10.8125 8 11.5 8C12.1875 8 12.75 8.5625 12.75 9.25ZM11.5 13C11.8125 13 12.125 12.95 12.4125 12.875L13.65 14.7375L15.725 13.35L14.4875 11.4875C14.9625 10.8625 15.25 10.0875 15.25 9.25C15.25 8.8125 15.1625 8.3875 15.025 8H23.675L14 18.6375L4.325 8H7.975C7.8375 8.3875 7.75 8.8125 7.75 9.25C7.75 11.3125 9.4375 13 11.5 13Z"
            fill="#A6A6A6"
        />
    </svg>
);
