import { basicApiReducer } from '@ackee/redux-utils';
import { fetchIotConfigTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchIotConfigTypes.FETCH_IOT_CONFIG_REQUEST,
        SUCCESS: fetchIotConfigTypes.FETCH_IOT_CONFIG_SUCCESS,
        FAILURE: fetchIotConfigTypes.FETCH_IOT_CONFIG_FAILURE,
        CANCEL: fetchIotConfigTypes.FETCH_IOT_CONFIG_CANCEL,
        RESET: fetchIotConfigTypes.FETCH_IOT_CONFIG_RESET,
    },
});
