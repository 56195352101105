import React from 'react';

import type { Theme } from 'styles/theme';

import { AssessmentReportStatus } from 'modules/entities/modules/documents-assessment-reports';

import { DocumentStatusIcon, DocumentStatusIconProps } from '../../DocumentStatusIcon';

export interface AssessmentReportStatusIconProps
    extends Pick<DocumentStatusIconProps<AssessmentReportStatus>, 'status' | 'small'> {}

const colorMap = (theme: Theme) => ({
    [AssessmentReportStatus.DATA_CAPTURE]: theme.colors.statusDataCapture,
    [AssessmentReportStatus.IN_PROGRESS]: theme.colors.statusInProgress,
    [AssessmentReportStatus.DONE]: theme.colors.statusDone,
});

const getColor = (theme: Theme, status: AssessmentReportStatus) => colorMap(theme)[status] ?? theme.colors.icon;

export const AssessmentReportStatusIcon = ({ status, small }: AssessmentReportStatusIconProps) => (
    <DocumentStatusIcon<AssessmentReportStatus> status={status} small={small} getColor={getColor} />
);
