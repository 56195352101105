import React, { useMemo } from 'react';
import { InfoWindow } from '../InfoWindow';
import { WorkorderGroup } from 'modules/entities/modules/workorders';
export interface ClusterInfoWindowProps {
    workorderGroups: WorkorderGroup[];
}

export const ClusterInfoWindow = ({ workorderGroups }: ClusterInfoWindowProps) => {
    const workorders = useMemo(
        () =>
            workorderGroups
                .sort((a, b) => a.propertyUnit.address.localeCompare(b.propertyUnit.address))
                .reduce((workorders, workorderGroup) => [...workorders, ...workorderGroup.workorders], []),
        [workorderGroups],
    );

    return <InfoWindow workorders={workorders} />;
};
