import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useFelaEnhanced } from 'hooks';
import config from 'config';
import { useFormSubmission } from 'modules/form';
import { useNotifications } from 'modules/ui';
import { DialogState } from 'constants/common';
import {
    CANCELLATION_ACTION,
    CONFIRMATION_ACTION,
    forms,
    HIDE_VALIDATE_INCIDENT_DIALOG,
    INCIDENT_DIALOG_CANCEL,
    VALIDATE_INCIDENT_DIALOG,
} from 'modules/incidents/constants';
import { Incident } from 'modules/entities/modules/incidents';
import IncidentReports from '../IncidentReports/IncidentReports';
import ServiceProvider from '../ServiceProvider/ServiceProvider';
import IncidentCardItem from '../IncidentCardItem/IncidentCardItem';
import { ValidationButtons } from '../ValidationButtons';
import * as felaRules from './IncidentCard.styles';
import { useGlobalDialog } from 'hooks/useGlobalDialog';
import { ConfirmButtons } from '../ConfirmButtons';

interface Props {
    incident: Incident;
    isClosed: boolean;
}

const IncidentCard: FC<Props> = ({ incident, isClosed }) => {
    const { formatMessage } = useIntl();
    const { styles } = useFelaEnhanced(felaRules);
    const notifications = useNotifications();
    const { navigate } = useGlobalDialog();
    const [hasError, setHasError] = useState(null);
    const [hasSuccess, setHasSuccess] = useState(null);
    const { handleSubmit, error, succeeded, clearForm, meta, submitting } = useFormSubmission<any>(
        forms.updateIncident,
    );

    useEffect(() => {
        setHasError(error);
    }, [error]);
    useEffect(() => {
        setHasSuccess(succeeded);
    }, [succeeded]);

    useEffect(() => {
        const showNotification = (type: string, titleId: string, messageId: string) => {
            notifications[type]({
                key: type,
                description: formatMessage({ id: messageId }),
                message: formatMessage({ id: titleId }),
            });
        };

        if (hasSuccess && meta?.caseNumber && meta?.caseNumber === incident?.case?.caseNumber) {
            showNotification(
                'success',
                `page.incidents.table.${meta.requestType}.notification.success.title`,
                `page.incidents.table.${meta.requestType}.notification.success.message`,
            );
            setHasSuccess(null);
            clearForm();
        }

        if (hasError && meta?.caseNumber && meta?.caseNumber === incident?.case?.caseNumber) {
            showNotification(
                'error',
                `page.incidents.table.${meta.requestType}.notification.error.title`,
                `page.incidents.table.${meta.requestType}.notification.error.message`,
            );
            setHasError(null);
            clearForm();
        }
    }, [notifications, hasSuccess, hasError, formatMessage, meta, incident, clearForm]);

    const validationRequired = incident.case.validationStatus === 'Open';
    return (
        <div className={!validationRequired ? styles.container : styles.containerBordered}>
            {!!validationRequired && <div className={styles.validationLayerBackground}></div>}
            <div className={styles.topContainer}>
                <div className={styles.widthFull}>
                    {validationRequired && (
                        <div data-test-id="validation-actions" className={styles.validationContainer}>
                            <ValidationButtons
                                submitting={submitting}
                                loadingIndicator={
                                    meta?.caseNumber && meta?.caseNumber === incident?.case?.caseNumber && submitting
                                        ? meta.requestType
                                        : ''
                                }
                                onCancel={() => {
                                    const shouldShowDialog = localStorage.getItem(INCIDENT_DIALOG_CANCEL);
                                    if (shouldShowDialog === HIDE_VALIDATE_INCIDENT_DIALOG) {
                                        handleSubmit(
                                            { elevator: incident.elevatorId, approvalStatus: 'Reject' },
                                            {
                                                requestType: CANCELLATION_ACTION,
                                                caseNumber: incident.case.caseNumber,
                                            },
                                        );
                                        return;
                                    }
                                    navigate(DialogState.CANCEL_CONFIRM, null, {
                                        elevatorId: incident.elevatorId,
                                        caseNumber: incident.case.caseNumber,
                                    });
                                }}
                                onValidate={() => {
                                    const shouldShowDialog = localStorage.getItem(VALIDATE_INCIDENT_DIALOG);
                                    if (shouldShowDialog === HIDE_VALIDATE_INCIDENT_DIALOG) {
                                        handleSubmit(
                                            { elevator: incident.elevatorId, approvalStatus: 'Approve' },
                                            {
                                                requestType: CONFIRMATION_ACTION,
                                                caseNumber: incident.case.caseNumber,
                                            },
                                        );
                                        return;
                                    }
                                    navigate(DialogState.VALIDATE_CONFIRM, null, {
                                        elevatorId: incident.elevatorId,
                                        caseNumber: incident.case.caseNumber,
                                    });
                                }}
                            />
                        </div>
                    )}

                    {!validationRequired && !isClosed && (
                        <div data-test-id="validation-actions" className={styles.validationContainer}>
                            <ConfirmButtons
                                submitting={submitting}
                                loadingIndicator={
                                    meta?.caseNumber && meta?.caseNumber === incident?.case?.caseNumber && submitting
                                        ? meta.requestType
                                        : ''
                                }
                                onConfirm={() => {
                                    navigate(DialogState.COMPLETE_CONFIRM, null, {
                                        elevatorId: incident.elevatorId,
                                        caseNumber: incident.case.caseNumber,
                                        status: incident.case.status,
                                        severity: incident.case.severity,
                                    });
                                }}
                            />
                        </div>
                    )}
                    <div className={!!validationRequired ? styles.rowtop : styles.rowtopWithMoreSpace}>
                        <IncidentCardItem
                            label="factoryNumber"
                            value={incident.factoryNumber}
                            link={{ link: config.routes.elevatorDetail, id: incident?.elevatorId }}
                        />

                        <IncidentCardItem
                            label="address"
                            value={`${incident?.propertyUnit?.street}, ${incident?.propertyUnit?.postalCode ?? ''} ${
                                incident?.propertyUnit?.city ?? ''
                            }`}
                        />

                        <IncidentCardItem label="businessUnit" value={incident.propertyUnit.businessUnit ?? ''} />
                    </div>
                </div>
                <div style={{ zIndex: 2 }}>
                    <ServiceProvider
                        serviceLevelAgreement={incident?.case?.serviceLevelAgreement}
                        serviceProviderInfo={incident?.case?.serviceProviderInfo}
                    />
                </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.rowBottom}>
                <IncidentCardItem
                    label="opened"
                    value={moment(incident.case?.dateTimeOpened).format('DD.MM.YYYY, HH:mm')}
                />

                <IncidentCardItem label="daysOpen" value={incident.case?.caseAge} />

                {!!isClosed && (
                    <IncidentCardItem
                        label="closed"
                        value={moment(incident.case?.resolvedOn).format('DD.MM.YYYY, HH:mm')}
                    />
                )}

                {!isClosed && (
                    <IncidentCardItem
                        label="presumably"
                        value={
                            incident.case.presumablyOutOfOperation
                                ? moment(incident.case.presumablyOutOfOperation, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                : formatMessage({
                                      id: 'incident.unknown',
                                  })
                        }
                    />
                )}
                <IncidentCardItem
                    label="severity"
                    value={
                        incident.case.severity
                            ? incident.case.severity
                            : formatMessage({
                                  id: 'incident.unknown',
                              })
                    }
                />

                <IncidentCardItem label="origin" value={incident.case.origin} />

                {!isClosed && <IncidentCardItem label="phase" value={incident.case.status} />}

                {!!isClosed && <IncidentCardItem label="component" value={incident.case?.component} />}

                <IncidentCardItem
                    label="status"
                    value={
                        incident.case.subStatus
                            ? incident.case.subStatus
                            : formatMessage({
                                  id: 'incident.unknown',
                              })
                    }
                />
            </div>
            <IncidentReports incident={incident} />
        </div>
    );
};

export default IncidentCard;
