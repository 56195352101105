import { ApiAction } from '../../../../../types';
import { fetchIncidentsTypes } from '../../actions';

const initialState: any = {
    picklist: {},
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchIncidentsTypes.FETCH_INCIDENTS_SUCCESS: {
            const picklist: { picklist: any } = action.payload?.picklist;
            // TODO: performance update

            if (picklist) {
                return picklist;
            }
            return { ...state, picklist }; // Return the updated state
        }

        case fetchIncidentsTypes.FETCH_INCIDENTS_RESET:
            return initialState;

        default:
            return state;
    }
};