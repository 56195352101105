import React from 'react';

export const ReportUpload = () => (
    <svg width="32" height="32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m25.71 3.296 4 4-1.42 1.42-2.29-2.3v9.59h-2v-9.59l-2.29 2.3-1.42-1.42 4-4a1 1 0 0 1 1.42 0ZM7 19.006h7a1 1 0 1 1 0 2H7a1 1 0 0 1 0-2Zm7 4H7a1 1 0 1 0 0 2h7a1 1 0 0 0 0-2Zm-1.29-15.71 6 6a1 1 0 0 1 .21.32c.05.124.077.256.08.39v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-20a1 1 0 0 1 1-1h9a1 1 0 0 1 .38.08.93.93 0 0 1 .33.21Zm2.88 5.71L13 10.416v2.59h2.59ZM4 9.006v18h13v-12h-5a1 1 0 0 1-1-1v-5H4Z"
        />
    </svg>
);
