import React, { FC, useEffect, useRef, useState } from 'react';
import type { ActionType } from '@ant-design/pro-table';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { SeoHead } from 'modules/seo';
import {
    Table,
    useDefaultTableConfig,
    RowLoader,
    DOCUMENT_CURRENT_PAGE_STORAGE_KEY,
    DOCUMENT_PAGE_SIZE_STORAGE_KEY,
} from 'modules/table';
import type { Invoice } from 'modules/entities/modules/documents-invoices';

import useInvoicesListTable from '../../hooks/useInvoicesListTable';
import { useViewDocument } from '../../hooks/useViewDocument';

import { columns } from './config';

import ActionPanel from './ActionPanel';
import FilterPanel from './FilterPanel';

import * as felaRules from './InvoicesListTab.styles';
import { useRouteMatch } from 'react-router-dom';
import { invoiceElevatorDetailColumns } from './config/columns';
import { useDownloadDocuments } from 'modules/entities/modules/documents';

type InvoicesTabProps = {};

const InvoicesListTab: FC<InvoicesTabProps> = () => {
    const ref = useRef<ActionType>();
    const formRef = useRef();
    const { invoices, api, defaultValueSearch } = useInvoicesListTable(ref);
    const [isElevatorDetail, setIsElevatorDetail] = useState(false);
    const { resetDownloadSelect } = useDownloadDocuments();
    const [page, setPage] = useState(1);

    const { rules } = useFelaEnhanced(felaRules);

    useEffect(() => {
        if (page === 1) {
            sessionStorage.setItem(DOCUMENT_CURRENT_PAGE_STORAGE_KEY, '1');
        }
    }, [page]);

    const intl = useIntl();

    const defaultPaginationConfig = useDefaultTableConfig();

    const { rowKey, openDocument, opening } = useViewDocument();

    let match = useRouteMatch('/elevators/:id');


    useEffect(() => {
        if (match?.isExact) {
            setIsElevatorDetail(true);
        }
    }, [match]);

    return (
        <div data-testid="invoices-tab">
            <SeoHead title={intl.formatMessage({ id: 'documents.invoices.title' })} />
            <FilterPanel defaultSearch={defaultValueSearch} isElevatorDetail={isElevatorDetail} />

            <ActionPanel invoices={invoices} />
            <Table
                loading={api.inProgress}
                extend={{ table: match?.isExact ? rules.tableElevatorDetail : rules.table }}
                actionRef={ref}
                formRef={formRef}
                form={{ syncToUrl: values => values }}
                columns={match?.isExact ? invoiceElevatorDetailColumns : columns}
                rowKey="id"
                onChange={pageInfo => {
                    setPage(pageInfo?.current);
                    resetDownloadSelect(); // when the page is changed we reset the selected download
                    sessionStorage.setItem(DOCUMENT_CURRENT_PAGE_STORAGE_KEY, pageInfo?.current?.toString());
                    sessionStorage.setItem(DOCUMENT_PAGE_SIZE_STORAGE_KEY, pageInfo?.pageSize?.toString());
                }}
                search={false}
                dataSource={invoices}
                toolBarRender={false}
                pagination={defaultPaginationConfig}
                onRow={(invoice: Invoice) => ({
                    onClick: e => {
                        e.stopPropagation();
                        if (invoice.file) {
                            openDocument({
                                id: invoice.id,
                                file: invoice.file,
                            });
                        }
                    },
                })}
                data-testid="invoices-table"
                footer={() => <RowLoader show={opening} rowKey={rowKey} />}
            />
        </div>
    );
};

export default InvoicesListTab;
