import { put, takeEvery } from 'redux-saga/effects';
import { editNotificationRequestSuccess, editNotificationRequestFailure, editNotificationRequest } from '../actions';
import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

function* editNotificationRequestHandler(action: ReturnType<typeof editNotificationRequest>) {
    try {
        yield* authApi.put(`${config.api.users}/${action.meta.email}/notifications`, action.payload);

        yield put(editNotificationRequestSuccess());
    } catch (error) {
        log.error(error);

        yield put(editNotificationRequestFailure(error));
    }
}

export function* sendEditNotificationRequest() {
    yield takeEvery(editNotificationRequest.toString(), editNotificationRequestHandler);
}
