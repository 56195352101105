import React from 'react';

const Download = props => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 17" {...props}>
        <defs>
            <path
                d="M19 16c.55 0 1 .45 1 1v2c0 .51-.388.935-.884.993L19 20H5c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v1h12v-1c0-.55.45-1 1-1zM12 3a1 1 0 011 1v8l2.4-1.8a1 1 0 111.2 1.6l-4 3a1 1 0 01-1.175.018l-4-2.814a1 1 0 111.15-1.636l2.428 1.71A1.015 1.015 0 0111 12V4a1 1 0 011-1z"
                id="download"
            />
        </defs>
        <use fill="currentColor" xlinkHref="#download" transform="translate(-4 -3)" fillRule="evenodd" />
    </svg>
);

export default Download;
