import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useFelaEnhanced } from 'hooks';
import { Popover, PopoverContainer } from 'modules/ui';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './MaintenanceTableDataSpace.styles';
import { Paragraph } from 'modules/ui';
import { InfoIcon } from 'modules/maintenance/InfoIcon';

export interface IMaintenanceValuePopover {
    title: string;
    list: String[];
    withBulletPoint?: boolean;
}

type InvoiceElevatorBusinessUnitProps = {
    title: ReactNode;
    value: ReactNode | number;
    extend?: RulesExtend<typeof felaRules>;
    link?: string;
    titlePopoverTexts?: any;
    valuePopover?: IMaintenanceValuePopover;
};

const MaintenanceTableDataSpace: FC<InvoiceElevatorBusinessUnitProps> = ({
    title,
    value,
    extend,
    link,
    titlePopoverTexts,
    valuePopover,
}) => {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    const renderValueList = () => {
        if (valuePopover.withBulletPoint) {
            return (
                <ul>
                    {valuePopover.list.map(e => (
                        <li>{e}</li>
                    ))}
                </ul>
            );
        } else {
            return (
                <>
                    {valuePopover.list.map(e => (
                        <p>{e}</p>
                    ))}
                </>
            );
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    {title}
                    {!!titlePopoverTexts && titlePopoverTexts.length && (
                        <Popover
                            placement="leftTop"
                            content={
                                <PopoverContainer>
                                    <PopoverContainer.Item
                                        secondaryText={
                                            <div>
                                                {titlePopoverTexts.map(e => (
                                                    <p>{e}</p>
                                                ))}
                                            </div>
                                        }
                                    />
                                </PopoverContainer>
                            }
                            trigger="hover"
                        >
                            <InfoIcon className={styles.iconContainer} />
                        </Popover>
                    )}
                </div>
            </div>
            {link ? (
                <Link
                    to={`${link}?filter=cancel_until_<_5_months%2Ccanceled%2Cactive&search=name%3A${value}`}
                    onClick={e => e.stopPropagation()}
                >
                    <Paragraph className={styles.links} extend={{ paragraph: rules.links }}>
                        {value}
                    </Paragraph>
                </Link>
            ) : (
                <span className={styles.value}>
                    {value}
                    {!!valuePopover && (
                        <Popover
                            placement="leftTop"
                            content={
                                <PopoverContainer>
                                    <PopoverContainer.Item
                                        secondaryText={
                                            <div>
                                                <p>{valuePopover.title}</p>
                                                {valuePopover.list && valuePopover.list.length && renderValueList()}
                                            </div>
                                        }
                                    />
                                </PopoverContainer>
                            }
                            trigger="hover"
                        >
                            <InfoIcon className={styles.iconContainerValue} />
                        </Popover>
                    )}
                </span>
            )}
        </div>
    );
};

export default MaintenanceTableDataSpace;
