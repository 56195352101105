import React, { FC } from 'react';
import { XAxis, YAxis } from 'recharts';

import * as felaRules from './VerticalBarChart.rules';

import BarChart, { BarChartProps } from '../BarChart';
import YAxisLabel from '../BarChart/YAxisLabel';
import { measureTicsText } from '../../services/utils';
import { useMemo } from 'react';
import { useFelaEnhanced } from 'hooks';

export interface VerticalBarChartProps extends Omit<BarChartProps, 'axes'> {}

const VerticalBarChart: FC<VerticalBarChartProps> = props => {
    const { rules } = useFelaEnhanced(felaRules);
    const width = useMemo(() => measureTicsText(props.data, props.yAxisDataKey) + 5, [props.data, props.yAxisDataKey]);

    return (
        <BarChart
            {...props}
            axisDataKey={props.yAxisDataKey}
            layout="vertical"
            extend={{ container: rules.container }}
            axes={[
                <XAxis key="xAxis" type="number" hide axisLine={false} />,
                <YAxis
                    key="yAxis"
                    type="category"
                    yAxisId={0}
                    dataKey={props.yAxisDataKey}
                    axisLine={false}
                    tickLine={false}
                    width={width}
                    label={<YAxisLabel textId="elevator.doorMovements.yAxisLabel" dx={8} dy={0} />}
                />,
            ]}
        />
    );
};

export default VerticalBarChart;
