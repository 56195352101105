import type { TRuleWithTheme } from 'styles/theme';

//import bg from 'assets/images/upload_info_bg.svg';

export const container: TRuleWithTheme = ({ theme }) => ({
    padding: '2rem',

    marginBottom: '1rem',

    color: theme.colors.text,
    backgroundColor: theme.colors.white,

    borderRadius: 2,

    //backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 0',
    backgroundSize: 'contain',
});

export const title: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        fontSize: '1.5rem',
        lineHeight: '1.33em',

        color: theme.colors.simplifa2,

        marginBottom: '1em',
    },
});

export const description: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '2em',
    },
});

export const subTitle: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        fontSize: '1.25rem',
        lineHeight: '1.4em',

        color: theme.colors.primary,

        marginBottom: '0.6em',
    },
});

export const list: TRuleWithTheme = () => ({
    counterReset: 'counter',

    listStyle: 'none',

    padding: 0,
    margin: 0,
});

export const listItem: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    counterIncrement: 'counter',

    fontSize: '0.875rem',
    lineHeight: '1.43em',

    marginBottom: '0.75rem',

    ':last-child': {
        marginBottom: 0,
    },

    ':before': {
        content: '"0" counter(counter)',

        display: 'flex',

        alignItems: 'center',
        justifyContent: 'center',

        width: '2rem',
        height: '2rem',

        marginRight: '0.75rem',

        fontSize: '0.75rem',
        lineHeight: '1.33em',

        borderRadius: '1rem',

        background: [
            theme.colors.simplifa2,
            `linear-gradient(270deg, ${theme.colors.simplifa1} 0%, ${theme.colors.simplifa2} 100%)`,
        ],

        color: theme.colors.white,
    },
});
