import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import { Button, Typography } from 'antd';

import config from 'config';

import { useFelaEnhanced } from 'hooks';

import DocumentTabs from '../DocumentTabs';

import * as felaRules from './DocumentsPage.styles';

type DocumentsPageProps = {};

const DocumentsPage: FC<DocumentsPageProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div>
            <div className={styles.header}>
                <Typography.Title level={4} className={styles.title}>
                    <FormattedMessage id="documents.pageTitle" />
                </Typography.Title>
                <Link to={generatePath(config.routes.documentsUpload)}>
                    <Button type="primary" size="large">
                        <FormattedMessage id="documents.generalUpload.cta" />
                    </Button>
                </Link>
            </div>
            <DocumentTabs />
        </div>
    );
};

export default DocumentsPage;
