import { createContext } from 'react';

import type { DialogStateValue } from '../types';
import type { useUploadNewElevatorDocument } from '../hooks/useUploadNewElevatorDocument';

export const NewElevatorDialogContext = createContext<{
    state: DialogStateValue;
    navigate: (state: DialogStateValue) => void;
    upload: ReturnType<typeof useUploadNewElevatorDocument>;
}>(null);
