import { combineRules } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

const btnHover = theme => ({
    color: 'initial',
    borderColor: theme.colors.borderPrimaryHover,
    boxShadow: `0 0 2px 2px ${theme.colors.selectedSecondary}`,
});

export const selectContainer: TRuleWithTheme<{ isVisible: boolean; fullWidth: boolean }> = ({
    theme,
    isVisible,
    fullWidth,
}) => ({
    display: 'flex',
    alignItems: 'center',
    '& .ant-select': {
        minWidth: fullWidth ? '100%' : theme.width.select,
    },

    '& .ant-select-arrow .anticon:not(.ant-select-suffix)': {
        pointerEvents: 'unset',
    },

    '& .ant-selector .ant-select-arrow': {
        right: 14,
    },

    '& > .ant-select-single .ant-select-selector .ant-select-selection-item::after': {
        display: 'none',
    },

    '& > .ant-select .ant-select-selector .ant-select-selection-placeholder': {
        fontWeight: 'normal',
        color: theme.colors.inputPlaceholder,
    },

    '& .ant-select.ant-select-show-arrow .ant-select-selection-item': {
        fontWeight: 'normal',
    },

    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        padding: '0.25rem 1.8125rem 0.25rem 0.75rem',
        borderWidth: 1,
        display: 'flex',
        borderStyle: 'solid',
        borderColor: theme.colors.outlineGrey,
        height: 'auto',

        ':hover': btnHover(theme),

        ':focus': btnHover(theme),

        extend: {
            condition: isVisible,
            style: btnHover(theme),
        },
    },

    '& .ant-select.ant-select-multiple .ant-select-selection-search': {
        marginInlineStart: '-1px',
    },
});

export const floatingLabelselectContainer: TRuleWithTheme = combineRules(selectContainer, () => ({
    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        height: '3rem',

        paddingTop: '0.75rem',
        paddingRight: '1rem',
        paddingBottom: '0.75rem',
        paddingLeft: '0.75rem',
    },
    '& .ant-select-single .ant-select-selection-item': {
        fontSize: '1rem',
        lineHeight: '1.5em',

        transform: 'translateY(0.375rem)',
    },
    '& .ant-select-single .ant-select-selection-placeholder': {
        fontSize: '1rem',
        lineHeight: '1.5em',

        transform: 'translateY(0)',
    },
    '& .ant-select-single.ant-select-focused .ant-select-selection-placeholder': {
        fontSize: '1rem',
        lineHeight: '1.5em',

        transform: 'translateY(0.375rem)',
    },
    '& .ant-select-arrow': {
        right: '1rem',
    },
}));

export const dropdown: TRuleWithTheme = ({ theme }) => ({
    width: theme.width.dropdownMenu,
    boxShadow: theme.shadows.dropdownMenu,

    '& .ant-select-item': {
        padding: '.5rem 1.25rem',

        ':hover': {
            backgroundColor: theme.colors.selectedSecondary,
        },

        '& .ant-select-item-option-state .anticon': {
            color: theme.colors.text,
        },
    },

    '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
        fontWeight: 'normal',
    },
});

export const icon: TRuleWithTheme<{ isVisible: boolean }> = ({ theme, isVisible }) => ({
    transition: 'all .25s',
    transformOrigin: 'center center',
    color: theme.colors.iconActive,
    lineHeight: 0,

    extend: {
        condition: isVisible,
        style: {
            color: theme.colors.primary,
            transform: `rotate(-180deg)`,
        },
    },
});
