import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Search, SearchValue, SORT_DELIMITER, UrlParam } from 'constants/index';

function useSearchSearchParams(): { search: Search[]; defaultValue: SearchValue } | null {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        const decodedSearch: Array<string> = params.get(UrlParam.SEARCH)?.split(',') ?? [];

        if (!decodedSearch) {
            return null;
        }

        const defaultValue: SearchValue = {};
        const finalSearch = decodedSearch.map(searchItem => {
            const [field, query] = searchItem.split(SORT_DELIMITER);
            defaultValue[field] = query;
            return { field, query };
        });

        const filter = params.get(UrlParam.FILTER);
        if (filter) {
            finalSearch.push({ field: 'status', query: filter });
        }

        return { search: finalSearch, defaultValue };
    }, [search]);
}

export default useSearchSearchParams;
