import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import {
    TableSearch as Search,
    SearchField,
    useTableSearch,
    TableSearchDateRange,
    TableSearchSelect,
} from 'modules/table';
import { selectInvoiceReasons } from 'modules/entities/modules/documents-invoices/services/selectors';
import { useDownloadDocuments } from 'modules/entities/modules/documents';

export type TableSearchProps = {
    defaultValue: SearchValue;
    isElevatorDetail: boolean;
};

const TableSearch: FC<TableSearchProps> = ({ defaultValue, isElevatorDetail }) => {
    const invoiceReasons = useSelector(selectInvoiceReasons);

    const { formatMessage } = useIntl();

    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);
    const { resetDownloadSelect } = useDownloadDocuments();

    const fields: SearchField[] = [
        ...(isElevatorDetail
            ? []
            : [
                  {
                      label: <FormattedMessage id={`elevator.properties.factoryNumber`} />,
                      renderer: (
                          <Input
                              data-testid="factory-number"
                              value={value['items.elevator.factoryNumber']}
                              placeholder={formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'items.elevator.factoryNumber': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`page.elevators.table.street`} />,
                      renderer: (
                          <Input
                              value={value['items.elevator.propertyUnit.street']}
                              placeholder={formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'items.elevator.propertyUnit.street': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`page.elevators.table.city`} />,
                      renderer: (
                          <Input
                              data-testid="city"
                              value={value['items.elevator.propertyUnit.city']}
                              placeholder={formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'items.elevator.propertyUnit.city': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`documents.table.businessUnit`} />,
                      renderer: (
                          <Input
                              value={value['items.elevator.propertyUnit.businessUnit']}
                              placeholder={formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'items.elevator.propertyUnit.businessUnit': target.value,
                                  });
                              }}
                          />
                      ),
                  },
              ]),

        {
            label: <FormattedMessage id={`documents.table.invoiceReason`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={invoiceReasons}
                    value={value['invoiceReason']}
                    onChange={value => {
                        handleValue({
                            invoiceReason: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.invoiceDate`} />,
            renderer: (
                <TableSearchDateRange
                    value={{
                        from: value['invoiceDate.from'],
                        to: value['invoiceDate.to'],
                    }}
                    onChange={(from, to) => {
                        handleValue({
                            'invoiceDate.from': from,
                            'invoiceDate.to': to,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.netTotal`} />,
            renderer: (
                <Input
                    type="number"
                    value={value['netTotal']}
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    onChange={({ target }) => {
                        handleValue({
                            netTotal: target.value,
                        });
                    }}
                />
            ),
        },
    ];

    return (
        <Search
            fields={fields}
            handleSubmit={e => {
                handleSubmit(e);
                resetDownloadSelect();
            }}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
        />
    );
};

export default TableSearch;
