import React from 'react';

import { useFelaEnhanced } from 'hooks';

import type { RulesExtend } from 'styles/theme';

import { Icon, IconType } from 'modules/ui';

import * as felaRules from './WorkorderStatusIcon.styles';
import { WorkOrderState } from 'modules/entities/modules/workorders';

interface WorkorderStatusIconProps {
    state?: WorkOrderState;
    extend?: RulesExtend<typeof felaRules>;
}

export const WorkOrderStatusIcon = ({ state, extend }: WorkorderStatusIconProps) => {
    const { styles } = useFelaEnhanced(felaRules, { state, extend });
    return <Icon type={IconType.ELEVATOR_STATUS} data-testid={`status-icon-${state}`} className={styles.icon} />;
};
