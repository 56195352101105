import { Image, Typography } from 'antd';
import React, { useEffect } from 'react';
import userSvg from 'assets/images/user.svg';
import * as felaRules from './DeactivateActivateUser.styles';
import { useFelaEnhanced } from 'hooks';
import { useIntl } from 'react-intl';
import TeamDialogFooter from '../TeamDialogFooter';
import { useDeactivateActivateUser } from 'modules/entities/modules/teams/hooks';
import { useNotifications } from 'modules/ui';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import { useSetUser } from 'modules/entities/modules/teams/hooks/useSetUser';

const DeactivateActivateUser = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const { api, handleSubmit } = useDeactivateActivateUser();
    const { setState } = useTeamDialog();
    const notifications = useNotifications();
    const intl = useIntl();
    const { api: user } = useSetUser()
    const onConfirm = () => {
        handleSubmit(user);
    };

    useEffect(() => {
        let main = 'invite.deactivate.user.request.success.title';
        let description = !user.isActive  ? 'invite.activate.user.request.success.description' : 'invite.deactivate.user.request.success.description';

        if (api.success) {
            notifications.success({
                message: intl.formatMessage({
                    id: main,
                }),
                description: intl.formatMessage({
                    id: description,
                }),
            });

            setState(null);
        }

        if (api.error) {
            notifications.error({
                message: intl.formatMessage({
                    id: api.error,
                }),
            });
        }
    }, [api, intl, notifications, setState, user.isActive]);

    return (
        <div className={styles.deactivateActivateUser}>
            <div className={styles.deactivateActivateUserHeading}>
                <Image src={userSvg} alt="user" preview={false} />
                <Typography.Title level={4} className={styles.title}>
                    {intl.formatMessage(
                        {
                            id: !user.isActive ? 'teams.activate.user' : 'teams.deactivate.user',
                        },
                        {
                            name: user.name,
                        },
                    )}
                </Typography.Title>
            </div>
            <Typography.Text className={styles.text}>
                {intl.formatMessage({
                    id: !user.isActive  ? 'teams.activate.user.description' : 'teams.deactivate.user.description',
                })}
            </Typography.Text>

            <TeamDialogFooter onConfirm={onConfirm} />
        </div>
    );
};

export default DeactivateActivateUser;
