// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"shadows.less"}]);
// Exports
exports.locals = {
	"default": "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
	"panel": "0 2px 6px rgba(0, 0, 0, 0.06)",
	"dropdownMenu": "0px 6px 16px 0px rgba(0, 0, 0, 0.14)"
};
module.exports = exports;
