import { useSelector, useDispatch } from 'react-redux';
import { selectAverageTripsApi } from '../services/selectors';
import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useEffect } from 'react';
import { fetchAverageTrips as actions } from '../services/actions';
import { useParams } from 'react-router-dom';

const useFetchAverageTrips = (startTime: string, endTime: string): ApiReducerState => {
    const { id } = useParams<{ id: string }>();
    const api: ApiReducerState = useSelector(selectAverageTripsApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchAverageTripsRequest(id, { startTime, endTime }));

        return () => {
            dispatch(actions.fetchAverageTripsCancel(id));
            dispatch(actions.fetchAverageTripsReset(id));
        };
    }, [dispatch, id, startTime, endTime]);

    return api;
};

export default useFetchAverageTrips;
