import React from 'react';

import { Divider } from 'modules/ui';
import { TableFilterPanel } from 'modules/table';

import TableSearch, { TableSearchProps } from './TableSearch';
import StatusFilter from './StatusFilter';

export interface ContractsFilterPanelProps {
    defaultValue: TableSearchProps['defaultValue'];
    isElevatorDetail: boolean;
}

export const ContractsFilterPanel = ({ defaultValue, isElevatorDetail }: ContractsFilterPanelProps) => {
    return (
        <TableFilterPanel>
            <StatusFilter />
            <Divider />
            <TableSearch defaultValue={defaultValue} isElevatorDetail={isElevatorDetail} />
        </TableFilterPanel>
    );
};
