export enum SortingType {
    ASC = 'ascend',
    DESC = 'descend',
}

export const SORT_DELIMITER = ':';

export type Sorting<Field = string> = {
    field?: Field;
    type?: SortingType;
};
