import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useFelaEnhanced } from 'hooks';
import { DataCard, DataSpace } from 'modules/ui';
import { selectors } from 'modules/entities/modules/elevators';

import * as felaRules from './TechnicalDataTab.styles';
import TechnicalDataLoader from './TechnicalDataLoader';

type TechnicalDataTabProps = {};

const cards: { nameId: string; key: string; rows: { nameId: string; value: string }[][] }[] = [
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.cabin.heading',
        key: 'technical-data-cabin',
        rows: [
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.cabin.maximumPersons',
                    value: 'maxPassengers',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.cabin.cabinDoors',
                    value: 'doorDrives',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.cabin.carDoorWidth',
                    value: 'carDoorWidth',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.cabin.carDoorHeight',
                    value: 'carDoorHeight',
                },
            ],
        ],
    },
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.shaft.heading',
        key: 'technical-data-shaft',
        rows: [
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.shaftHeight',
                    value: 'staticHead',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.hoistingHeight',
                    value: 'liftHeight',
                },
            ],
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.upperCrossover',
                    value: 'upperCrossingPath',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.upperProtectiveAreaHeight',
                    value: 'upperHeightProtectiveArea',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.lowerCrossover',
                    value: 'lowerCrossingPath',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.shaft.lowerProtectiveAreaHeight',
                    value: 'lowerHeightProtectiveArea',
                },
            ],
        ],
    },
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.drive.heading',
        key: 'technical-data-drive',
        rows: [
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.machineRoom',
                    value: 'enginePosition',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.inGroup',
                    value: 'controlGroup',
                },
            ],
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.speed',
                    value: 'speed',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.suspension',
                    value: 'ropeRatio',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.loadCarrier',
                    value: 'diameterRopes',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.drive.speedRestrictor',
                    value: 'diameterOverspeedGovernorRope',
                },
            ],
        ],
    },
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.general.heading',
        key: 'technical-data-general',
        rows: [
            [
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.general.constructionBasis',
                    value: 'installationRegulation',
                },
                {
                    nameId: 'page.elevatorDetail.tabs.technicalData.general.lastModernization',
                    value: 'modernizationDegree',
                },
            ],
        ],
    },
];

const TechnicalDataTab: FC<TechnicalDataTabProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const elevator = useSelector(selectors.selectElevator);
    const { inProgress } = useSelector(selectors.selectElevatorApi);

    if (!elevator && !inProgress) return null;

    if (inProgress) return <TechnicalDataLoader />;

    return (
        <div className={styles.container}>
            {cards.map(card => (
                <DataCard
                    title={
                        <Typography.Title level={4}>
                            <FormattedMessage id={card.nameId} />
                        </Typography.Title>
                    }
                    spacingSize="large"
                    extend={{ card: rules.card }}
                    key={`technical-data-${card.nameId}`}
                >
                    {card.rows.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${card.nameId}Row${index}`}>
                            <Row>
                                {row.map(item => (
                                    <Col md={6} key={item.nameId}>
                                        <DataSpace
                                            name={<FormattedMessage id={item.nameId} />}
                                            data={elevator[item.value]}
                                            isDataText
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {card.rows.length > 1 && index + 1 !== card.rows.length && (
                                <div className={styles.divider} />
                            )}
                        </div>
                    ))}
                </DataCard>
            ))}
        </div>
    );
};

export default TechnicalDataTab;
