import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';

import { Elevator, ElevatorFloorHistory } from '../../types';

type PrepareFloorHistoryAction = (
    id: Elevator['id'],
) => {
    meta: {
        id: Elevator['id'];
    };
    payload: null;
};

type PrepareFloorHistoryFailureAction = (
    id: Elevator['id'],
    error: Error,
) => {
    meta: {
        id: Elevator['id'];
    };
    error: Error;
    payload: null;
};

const prepareFloorHistoryAction: PrepareFloorHistoryAction = id => ({
    meta: {
        id,
    },
    payload: null,
});

const prepareFloorHistoryFailureAction: PrepareFloorHistoryFailureAction = (id, error) => ({
    meta: {
        id,
    },
    error,
    payload: null,
});

export const subscribeElevatorFloorHistory = createAction<PrepareFloorHistoryAction>(
    `${EntityKey.ELEVATORS}/subscribeElevatorFloorHistory`,
    prepareFloorHistoryAction,
);

export const subscribedElevatorFloorHistoryFailure = createAction<PrepareFloorHistoryFailureAction>(
    `${EntityKey.ELEVATORS}/subscribeElevatorFloorHistoryFailure`,
    prepareFloorHistoryFailureAction,
);

export const unsubscribeElevatorFloorHistory = createAction<PrepareFloorHistoryAction>(
    `${EntityKey.ELEVATORS}/unsubscribeElevatorFloorHistory`,
    prepareFloorHistoryAction,
);

export const updateElevatorFloorHistory = createAction<ElevatorFloorHistory>(
    `${EntityKey.ELEVATORS}/updateElevatorFloorHistory`,
);

export type SubscribeElevatorFloorHistoryAction = ReturnType<typeof subscribeElevatorFloorHistory>;
export type UnubscribeElevatorFloorHistoryAction = ReturnType<typeof unsubscribeElevatorFloorHistory>;
export type SubscribeElevatorFloorHistoryFailureAction = ReturnType<typeof subscribedElevatorFloorHistoryFailure>;
export type UpdateElevatorFloorHistoryAction = ReturnType<typeof updateElevatorFloorHistory>;
