import { put, takeEvery } from 'redux-saga/effects';
import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import {
    postSupportRequest,
    postSupportRequestRequest,
    postSupportRequestSuccess,
    postSupportRequestFailure,
    fetchSupports as actions,
} from '../actions';
import { Support } from '../../types';

function* postSupportRequestHandler(action: ReturnType<typeof postSupportRequest>) {
    yield put(postSupportRequestRequest(action.meta.subject));

    try {
        yield* authApi.post(config.api.supportRequest, action.payload);

        yield put(postSupportRequestSuccess(action.meta.subject));
        const controller = new AbortController();
        const { data } = yield* authApi.get<Support[]>(config.api.support, {
            signal: controller.signal,
        });
        yield put(actions.fetchSupportsSuccess(data));
    } catch (error) {
        log.error(error);

        yield put(postSupportRequestFailure(error, action.meta.subject));
    }
}

export function* sendSupportRequestSaga() {
    yield takeEvery(postSupportRequest.toString(), postSupportRequestHandler);
}
