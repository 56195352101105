import { noop } from 'lodash';
import { put } from 'redux-saga/effects';

import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';
import { authApi } from 'config/antonio';
import config from 'config';

import { fetchIncidentTypes, fetchIncident as actions } from '../actions';

type AxiosData = { [key: string]: any }[];

function* fetchIncident(action) {
    const { id } = action.meta;
    try {
        const { data } = yield* authApi.get<AxiosData>(config.api.activeIncident, {
            uriParams: { id },
        });
        yield put(actions.fetchIncidentSuccess(id, data));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchIncidentFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchIncident',
            leading: true,
        },
        {
            pattern: fetchIncidentTypes.FETCH_INCIDENT_REQUEST,
            handler: fetchIncident,
        },
        {
            pattern: fetchIncidentTypes.FETCH_INCIDENT_CANCEL,
            handler: noop,
        },
    );
}
