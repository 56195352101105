import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';

import { ApiReducerKey, EntityKey } from 'constants/index';
import { createSelector } from 'reselect';

export const selectDownloadDocumentsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENTS, ApiReducerKey.DOWNLOAD);

export const selectUploadDocumentsApi = (state): Record<string, ApiReducerState> =>
    apiSelector(state, EntityKey.DOCUMENTS, ApiReducerKey.UPLOAD);

export const selectUploadDocumentApi = createSelector(
    [selectUploadDocumentsApi, (_, { uploadId }: { uploadId: string }) => uploadId],
    (apis, id) => apis[id] ?? apis.placeholder,
);

export const selectSelectedDocuments = (state): string[] => state.entities[EntityKey.DOCUMENTS]['select'];
