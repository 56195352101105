import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './PhaseTag.rules';
import { Tag } from 'modules/ui';

interface Props {
    status: any;
}

const PhaseTag: FC<Props> = ({ status }) => {
    const { styles } = useFelaEnhanced(felaRules, { status });

    return <Tag className={styles.buttonColor}>{status}</Tag>;
};

export default PhaseTag;
