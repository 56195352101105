import { SUPPORTED_ELEVATOR_STATES } from '../constants';
import { IoTDevice, Elevator, ElevatorDetail, ElevatorState } from '../types';

export const isDeviceActive = (device: IoTDevice) => Boolean(device.installation);

export const elevatorHasDevice = (elevator: Elevator | ElevatorDetail) =>
    elevator?.state && elevator.devices?.length > 0 && isDeviceActive(elevator.devices[0]);

export const parseFloor = (floor: any, fallback: number = 0) => {
    const parsedFloor = Number.parseInt(floor, 10);

    return typeof parsedFloor !== 'number' || isNaN(parsedFloor) ? fallback : parsedFloor;
};

export const getPropertyUnitAdress = (propertyUnit: Elevator['propertyUnit']) =>
    `${propertyUnit?.street}, ${
        propertyUnit?.postalCode
    } ${propertyUnit?.city}`;

export const isSupportedState = (state: ElevatorState) => SUPPORTED_ELEVATOR_STATES.includes(state);

export const isSamePropertyUnit = (first: Elevator['propertyUnit'], second: Elevator['propertyUnit']) =>
    first.businessUnit === second.businessUnit &&
    first.street === second.street &&
    first.postalCode === second.postalCode &&
    first.city === second.city;
