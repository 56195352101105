import { ElevatorTotalTripsAggregation } from '../../../entities/modules/elevators';
import moment from 'moment';
import { TotalTrips } from '../../../entities/modules/trips';
import { FormatDateOptions } from '@formatjs/intl/src/types';

const generateTotalTripsName = (
    trip: TotalTrips,
    aggregationBy: ElevatorTotalTripsAggregation,
    formatDate: (value: Parameters<Intl.DateTimeFormat['format']>[0] | string, opts?: FormatDateOptions) => string,
) => {
    switch (aggregationBy) {
        case ElevatorTotalTripsAggregation.DAY:
            return formatDate(trip.date, { day: 'numeric', month: 'numeric' });
        case ElevatorTotalTripsAggregation.WEEK:
            return moment(trip.date).format('YYYY-WW.');
        case ElevatorTotalTripsAggregation.MONTH:
            return formatDate(trip.date, { month: '2-digit', year: '2-digit' });
        case ElevatorTotalTripsAggregation.YEAR:
            return formatDate(trip.date, { year: 'numeric' });
        default:
            return formatDate(trip.date);
    }
};

export default generateTotalTripsName;
