import React from 'react';

const SortDown = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={16}
            height={16}
            fill="currentColor"
            {...props}
        >
            <path
                d="M8 2a1 1 0 0 1 1 1v8l2.4-1.8a1 1 0 1 1 1.2 1.6l-4 3a1 1 0 0 1-1.175.018l-4-2.814a1 1 0 1 1 1.15-1.636l2.428 1.71A1.015 1.015 0 0 1 7 11V3a1 1 0 0 1 1-1Z"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default SortDown;
