import React, { FC } from 'react';
import { XAxis, YAxis } from 'recharts';
import { useFela } from 'react-fela';

import type { Theme } from 'styles/theme';

import BarChart, { BarChartProps } from '../BarChart';

export interface HorizontalBarChartProps extends Omit<BarChartProps, 'axes'> {
    widerYAxis?: boolean;
    angle?: number;
    dx?: number;
    dy?: number;
}

const HorizontalBarChart: FC<HorizontalBarChartProps> = ({ axisDataKey = 'name', ...props }) => {
    const { theme } = useFela<Theme>({});

    return (
        <BarChart
            {...props}
            axisDataKey={axisDataKey}
            axes={[
                <XAxis
                    key="xAxis"
                    dataKey={axisDataKey}
                    interval={0}
                    minTickGap={props.minTickGap}
                    angle={props.angle}
                    dx={props.dx}
                    dy={props.dy}
                    height={props.angle ? theme.chart.xAxisHeight : 30}
                />,
                <YAxis key="yAxis" width={props.widerYAxis ? theme.width.chartYAxisWider : theme.width.chartYAxis} />,
            ]}
        />
    );
};

export default HorizontalBarChart;
