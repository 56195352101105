import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { clearQuotations, fetchQuotations } from '../services/actions';
import { selectQuotationsApi } from '../services/selectors';

export const useFetchQuotations = () => {
    const dispatch = useDispatch();

    const api = useSelector(selectQuotationsApi);
    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        dispatch(fetchQuotations({elevatorId: isElevatorDetail? id: null}));

        return () => {
            dispatch(clearQuotations());
        };
    }, [dispatch, isElevatorDetail, id]);

    return api;
};
