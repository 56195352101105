import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '& label': {
        fontSize: '0.625rem',
    },
    '& .ant-form-item': {
        marginBottom: '2rem',
    },
});
export const bottomButtons: TRuleWithTheme = ({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',

    columnGap: '1rem',

    marginTop: '2.5rem',
});

export const footer: TRuleWithTheme = ({ theme }) => ({
    marginTop: '2rem',
});

export const bottomNotification: TRuleWithTheme = ({ theme }) => ({
    columnGap: '1rem',
    alignSelf: 'center',
    marginTop: '0.5rem',
});
export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        color: theme.colors.textSecondary,

        fontSize: '0.88rem',
        lineHeight: 1.5,

        marginTop: '0.25rem',
        marginBottom: '1.5rem',
    },
});

export const textArea: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
});

export const navigation: TRuleWithTheme = () => ({
    display: 'grid',

    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',

    marginBottom: '0.5rem',
});

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});
export const alert: TRuleWithTheme = () => ({
    marginBottom: '1.94rem',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        fontSize: '1.5rem',
        lineHeight: 1.33,

        margin: 0,
    },
});

export const content: TRuleWithTheme<{
    goBack: boolean;
}> = ({ goBack }) => ({
    marginLeft: goBack ? '2.5rem' : 0,
});
