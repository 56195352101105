export const forms = {
    login: 'loginForm',
    register: 'register',
    createPassword: 'createPassword',
    forgottenPassword: 'forgottenPassword',
    forgottenPasswordEmail: 'forgottenPasswordEmail',
};

export const fields = {
    login: {
        email: 'email',
        password: 'password',
    },
    register: {
        email: 'email',
    },
    confirmPassword: {
        newPassword: 'newPassword',
        confirmPassword: 'confirmPassword',
        oldPassword: 'oldPassword',
    },
    forgottenPassword: {
        code: 'code',
        email: 'email',
        newPassword: 'newPassword',
        confirmPassword: 'confirmPassword',
    },
    forgottenPasswordEmail: {
        email: 'email',
    },
};
