import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingTop: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',

    borderRadius: '0.125rem',

    backgroundColor: theme.colors.white,
});

export const portfolio: TRuleWithTheme = () => ({
    marginRight: '1rem',
    fontSize: '1rem',
    fontWeight: 600,
});

export const count: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',

    fontWeight: 500,

    color: theme.colors.textSecondary,
});
