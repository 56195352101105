import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '@media (min-width: 992px)': {
        '& .ant-col-lg-5': {
            display: 'block',
            flex: '0 0 19.83333333%',
            maxwidth: '20.83333333%',
        },
    },
});
export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
});
export const tableContainer: TRuleWithTheme = () => ({
    '& .ant-table-cell': {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    '& Table': {
        borderCollapse: 'separate',
        borderSpacing: '0 1rem',
    },
    '& .ant-table': {
        backgroundColor: 'transparent',
    },
    '& .ant-card': {
        backgroundColor: 'transparent',
    },
    '& tr::after': {
        height: '0px !important',
    },
});

export const headerTitle: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const title: TRuleWithTheme = () => ({
    fontSize: '1.5rem',
    '&.ant-typography': {
        fontSize: '1.5rem',
        lineHeight: 1.33,

        margin: 0,
    },
});

export const button: TRuleWithTheme = () => ({
    flexShrink: 0,

    padding: 0,

    marginRight: '1rem',
    ':hover': {
        background: 'none',
    },

    width: '1.5rem',
    height: '1.5rem',

    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.iconActive,
});

export const searchContainer: TRuleWithTheme = ({ theme }) => ({
    marginTop: '2.06rem',
});

export const content: TRuleWithTheme<{
    goBack: boolean;
}> = ({ goBack }) => ({
    marginLeft: goBack ? '2.5rem' : 0,
});
