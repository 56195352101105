import { useSelector, useDispatch } from 'react-redux';
import { selectTotalTripsApi } from '../services/selectors';
import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useEffect } from 'react';
import { fetchTotalTrips as actions } from '../services/actions';
import { useParams } from 'react-router-dom';
import { ElevatorTotalTripsAggregation } from '../../elevators';

const useFetchTotalTrips = (
    startTime: string,
    endTime: string,
    granularity: ElevatorTotalTripsAggregation,
): ApiReducerState => {
    const { id } = useParams<{ id: string }>();
    const api: ApiReducerState = useSelector(selectTotalTripsApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchTotalTripsRequest(id, { startTime, endTime, granularity }));

        return () => {
            dispatch(actions.fetchTotalTripsCancel(id));
            dispatch(actions.fetchTotalTripsReset(id));
        };
    }, [dispatch, id, startTime, endTime, granularity]);

    return api;
};

export default useFetchTotalTrips;
