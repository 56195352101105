import { put, takeEvery } from 'redux-saga/effects';
import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import { changeRoleRequest, changeRoleRequestSuccess, changeRoleRequestFailure } from '../actions';

export function* changeRoleRequestHandler(action: ReturnType<typeof changeRoleRequest>) {
    try {
        yield* authApi.put(`${config.api.users}/${action.meta.email}`, action.payload);

        yield put(changeRoleRequestSuccess());
    } catch (error) {
        log.error(error);

        yield put(changeRoleRequestFailure(error));
    }
}

export function* sendChangeRoleRequest() {
    yield takeEvery(changeRoleRequest.toString(), changeRoleRequestHandler);
}
