import React, { FC } from 'react';
import { Form, FormProps, Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './AuthForm.styles';

interface AuthFormProps extends FormProps {
    extend?: RulesExtend<typeof felaRules>;
}

const AuthForm: FC<AuthFormProps> = ({ children, extend, title, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <Form {...rest} className={styles.form}>
            {title && (
                <Typography.Title level={3} className={styles.title}>
                    {title}
                </Typography.Title>
            )}

            {children}
        </Form>
    );
};

export default AuthForm;
