import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const container: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& .ant-drawer-body': {
        paddingTop: '0',
    },
    '& .ant-drawer-header': {
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingBottom: '1.25rem',

        '& .ant-drawer-close': {
            position: 'unset',
            order: '-1',
            marginLeft: '-1em',
        },
    },
});

export const heading = () => ({
    marginTop: '1em',
    '&.ant-typography': {
        marginBottom: '0',
    },
});

export const closeIcon: TRule<{ theme: Theme }> = ({ theme }) => ({
    color: theme.colors.iconActive,
});
