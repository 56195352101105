import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFelaEnhanced } from 'hooks';
import { Row, Col, Card, Result } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { Loader } from 'modules/ui';
import { HEALTH_STATE_TYPES, selectors as elevatorsSelectors } from 'modules/entities/modules/elevators';

import * as felaRules from './IoTDataTab.styles';
import StatusCard from './StatusCard';
import { selectElevatorHasIoTDevice } from '../../services/selectors';
import AverageTrips from './AverageTrips';
import TotalTrips from './TotalTrips';
import History from './History';
import LiveLocation from './LiveLocation';
import DoorMovement from './DoorMovement';
import { ShaftTemperature } from './ShaftTemperature';
import ActiveIncident from './ActiveIncident';
import { useIotConfig } from 'modules/entities/modules/config/hooks';
import { useElevatorDetail } from 'modules/elevator-detail/hooks';
import useElevatorState from 'modules/elevator-detail/hooks/useElevatorState';
// import useIsDemo from 'hooks/useIsDemo';
import HealthStateCard from './HealthStateCard';
import { IotInfo } from './IotInfo';
import { DeactivateDialogContainer } from './DeactivateDialogContainer';
import { EXPECTED_MATURED_DAYS } from 'constants/elevator';

interface IotDataTabProps {}
interface Config {
    history: true;
    totalTrips: true;
    averageTrips: true;
    liveLocation: true;
}

export enum IOT_DEVICE_DISABLE_REASONS {
    DEVICE_OFFLINE = 'DEVICE_OFFLINE',
    MARKED_AS_DISABLED = 'MARKED_AS_DISABLED',
    NO_DEVICE_ORDERED = 'NO_DEVICE_ORDERED',
    DEVICE_NOT_INSTALLED = 'DEVICE_NOT_INSTALLED',
    LEARNING_PHASE = 'LEARNING_PHASE',
}

export interface DEVICE_STATE {
    disabled: boolean;
    isLessThanExpectedMatureDate: boolean;
    message?: string;
    installation?: string;
    daysToMaturity?: number;
    key?: IOT_DEVICE_DISABLE_REASONS;
}

const sectionDemo = {
    elevatorHealth: [
        {
            isOkState: true,
            iconType: 'technician_not_on_site',
            parentState: 'TECHNICIAN',
            name: 'TECHNICIAN',
            priorityField: 'createdAt',
            subStates: [
                {
                    name: 'TECHNICIAN_NOTONSITE',
                    active: true,
                    priorityField: 'createdAt',
                    createdAt: '1970-01-01T00:00:00.000Z',
                    probability: null,
                },
            ],
        },
        {
            isOkState: true,
            iconType: 'elevator_status_success',
            parentState: 'TRIPANALYSIS',
            name: 'TRIPANALYSIS',
            priorityField: 'createdAt',
            subStates: [
                {
                    name: 'TRIPANALYSIS_OK',
                    active: true,
                    priorityField: 'createdAt',
                    createdAt: '1970-01-01T00:00:00.000Z',
                    probability: null,
                },
            ],
        },
        {
            isOkState: true,
            iconType: 'elevator_status_success',
            parentState: 'USAGEPROFILE',
            name: 'USAGEPROFILE',
            subStates: [
                { name: 'USAGEPROFILE_OK', active: true, createdAt: '1970-01-01T00:00:00.000Z', probability: 46 },
            ],
        },
        {
            isOkState: true,
            iconType: 'elevator_status_success',
            parentState: 'DOORS',
            name: 'DOORS',
            priorityField: 'createdAt',
            subStates: [
                {
                    name: 'DOORS_OK',
                    active: true,
                    priorityField: 'createdAt',
                    createdAt: '1970-01-01T00:00:00.000Z',
                    probability: null,
                    lastFloor: 2,
                    lastTripDoorCloseCount: 1,
                },
            ],
        },
        { enabled: false, name: 'CABINLIGHT', isOkState: false, parentState: 'CABINLIGHT' },
    ],
    deviceHealth: [
        {
            isOkState: true,
            iconType: 'elevator_status_success',
            parentState: 'IOTDEVICE',
            priorityField: 'createdAt',
            subStates: [
                {
                    name: 'IOTDEVICE_BLACKOUT',
                    priorityField: 'createdAt',
                    iconType: 'elevator_status_success',
                    createdAt: '2023-02-14T08:29:12.000Z',
                },
                {
                    name: 'IOTDEVICE_OFFLINE',
                    priorityField: 'createdAt',
                    iconType: 'elevator_status_success',
                    createdAt: '2023-02-18T13:35:30.820Z',
                },
            ],
            name: 'IOTDEVICE',
        },
    ],
};

const elevatorStateDemo = {
    overallState: { key: '', desc: '' },
    deviceConnectivity: [
        { ipAddress: '2.247.241.137', deviceId: 'argos-2021400000', state: 'connected', timestamp: 1676727330820 },
    ],
    blackoutHistory: [
        {
            deviceBlackout: false,
            elevatorId: 'A-00001410',
            deviceId: 'staging_argos_2021400000',
            timestamp: 1676363352,
        },
    ],
    deviceHealth: {
        overallState: 'RUNNING',
        sections: {
            IOTDEVICE: {
                IOTDEVICE_OFFLINE: [
                    {
                        ipAddress: '2.247.241.137',
                        deviceId: 'argos-2021400000',
                        state: 'connected',
                        timestamp: 1676727330820,
                    },
                ],
                IOTDEVICE_BLACKOUT: [
                    {
                        deviceBlackout: false,
                        elevatorId: 'A-00001410',
                        deviceId: 'staging_argos_2021400000',
                        timestamp: 1676363352,
                    },
                ],
            },
        },
    },
    elevatorHealth: {
        deviceId: 'argos-2021400000',
        timestamp: 1677499031,
        overallState: 'RUNNING',
        elevatorId: 'A-00001410',
        sections: {
            TRIPANALYSIS: {
                TRIPANALYSIS_BREAKDOWNEMERGENCYSTOP: { active: false, timestamp: null },
                TRIPANALYSIS_OK: { active: true, timestamp: null },
                enabled: true,
                timestamp: 1677497635,
                TRIPANALYSIS_TEMPORARYEMERGENCYSTOPS: { active: false, timestamp: null },
            },
            TECHNICIAN: {
                TECHNICIAN_ONSITE: { active: false, timestamp: null },
                TECHNICIAN_NOTONSITE: { active: true, timestamp: null },
                enabled: true,
                timestamp: 1677497635,
            },
            USAGEPROFILE: {
                USAGEPROFILE_TESTTRIP_FAILED: { active: false, timestamp: null },
                USAGEPROFILE_OK: { active: true, timestamp: null },
                USAGEPROFILE_OUTOFORDER: { active: false, timestamp: null },
                probability: 0.4588751239379888,
                enabled: true,
                timestamp: 1677498731.380248,
            },
            CABINLIGHT: { enabled: false },
            DOORS: {
                lastTripDoorCloseCount: 1,
                lastFloor: 2,
                DOORS_OK: { active: true, timestamp: null },
                DOORS_REVERSING_BREAKDOWN: { active: false, timestamp: null },
                enabled: true,
                timestamp: 1677497635,
            },
        },
        overallStateID: 10,
    },
};

const IotDataTab: FC<IotDataTabProps> = () => {
    const { inProgress } = useSelector(elevatorsSelectors.selectElevatorApi);
    const hasIoTDevice = useSelector(selectElevatorHasIoTDevice);
    const { styles, theme } = useFelaEnhanced(felaRules, { inProgress });
    const iot_config = useIotConfig();
    const configuration: Config = Object.values(iot_config)[0]?.elevators?.iot;
    const { history, liveLocation } = { ...configuration };
    const { elevator, inProgress: stateInProgress } = useElevatorDetail();
    const { elevatorState, sections } = useElevatorState();
    const [deviceState, setDeviceState] = useState<DEVICE_STATE>();
    const [showDeviceState, setShowDeviceState] = useState(false);
    const [showElevatorState, setShowElevatorState] = useState(false);
    const isDemo = '1';

    useEffect(() => {
        if (!elevator) return; // return early if elevator is falsy
        const device = elevator.devices?.find(d => !d.expansion || d.expansion === null);
        const installation = device?.installation;
        const ds: DEVICE_STATE = {
            disabled: false,
            message: '',
            key: null,
            installation: installation,
            isLessThanExpectedMatureDate: false,
            daysToMaturity: 0,
        };
        const dateDiff = installation ? moment().diff(moment(installation), 'days') : 0;

        if (installation && dateDiff < EXPECTED_MATURED_DAYS) {
            ds.daysToMaturity = EXPECTED_MATURED_DAYS - dateDiff;
            ds.isLessThanExpectedMatureDate = true;
            ds.key = IOT_DEVICE_DISABLE_REASONS.LEARNING_PHASE;
        }

        if (device?.deviceState?.disabled) {
            ds.key = IOT_DEVICE_DISABLE_REASONS.MARKED_AS_DISABLED;
            ds.message = device.deviceState.message;
            ds.disabled = true;
        } else if (
            !!elevatorState?.deviceConnectivity &&
            !!elevatorState?.deviceConnectivity[0]?.state &&
            elevatorState?.deviceConnectivity[0]?.state !== 'connected' &&
            !stateInProgress
        ) {
            ds.key = IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE;
            ds.message = '';
            ds.disabled = true;
        } else if (!hasIoTDevice) {
            ds.key = IOT_DEVICE_DISABLE_REASONS.NO_DEVICE_ORDERED;
            ds.message = '';
            ds.disabled = true;
        }

        setDeviceState(ds);
    }, [elevator, elevatorState, hasIoTDevice, stateInProgress]);


    useEffect(() => {
        if (
            (elevatorState?.deviceHealth?.overallState &&
                sections?.deviceHealth &&
                Object.keys(sections?.deviceHealth).length > 0) ||
            deviceState?.disabled
        ) {
            setShowDeviceState(true);
        } else {
            setShowDeviceState(false);
        }

        if (
            (elevatorState?.elevatorHealth?.overallState && sections?.elevatorHealth?.length > 0) ||
            deviceState?.disabled
        ) {
            setShowElevatorState(true);
        } else {
            setShowElevatorState(true);
        }
    }, [deviceState, elevatorState, sections]);

    return (
        <div className={styles.container}>
            {!hasIoTDevice && !!elevator && <DeactivateDialogContainer />}
            {!!deviceState?.disabled &&
                deviceState?.key &&
                deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.NO_DEVICE_ORDERED && (
                    <div>
                        <IotInfo>
                            <FormattedMessage
                                id={`elevator.iot.notInstalled.${deviceState?.key}`}
                                values={{
                                    link: '',
                                    br: <br />,
                                }}
                            />
                            <br />
                            {deviceState?.message}
                        </IotInfo>
                    </div>
                )}

            {!deviceState?.disabled && deviceState?.key && !!deviceState?.isLessThanExpectedMatureDate && (
                <div>
                    <IotInfo>
                        <FormattedMessage
                            id={`elevator.iot.notInstalled.${deviceState?.key}`}
                            values={{
                                b: chunks => <strong>{chunks}</strong>,
                                variable: chunks => <strong>{deviceState?.daysToMaturity}</strong>,
                            }}
                        />
                        <br />
                        {deviceState?.message}
                    </IotInfo>
                </div>
            )}

            {!!deviceState?.disabled && deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE && (
                <div className={styles.deviceIotContainerBlur} />
            )}
            <Loader show={inProgress} extend={{ loader: felaRules.loader }}>
                {!!elevator ? (
                    <Row gutter={[theme.rowGutter.x, theme.rowGutter.y]}>
                        <Col
                            xs={24}
                            lg={12}
                            xl={6}
                            xxl={6}
                            className={isDemo !== '1' && !deviceState?.disabled ? styles.statusCardCol : ''}
                        >
                            <ActiveIncident
                                elevator={elevator}
                                withShadows={
                                    deviceState?.disabled &&
                                    deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                }
                            />

                            {isDemo === '1' || !!deviceState?.disabled ? (
                                <>
                                    {!!showElevatorState &&
                                        (!!deviceState?.key || !!elevatorState?.elevatorHealth?.overallState) && (
                                            <HealthStateCard
                                                isDeactivated={
                                                    deviceState?.disabled &&
                                                    deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                }
                                                isLearning={deviceState?.isLessThanExpectedMatureDate}
                                                type={HEALTH_STATE_TYPES.ELEVATOR}
                                                // @ts-ignore
                                                sections={
                                                    deviceState?.disabled &&
                                                    deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                        ? sectionDemo.elevatorHealth
                                                        : sections.elevatorHealth
                                                }
                                                isDisconnected={
                                                    deviceState?.key === IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                }
                                                // @ts-ignore
                                                overallState={
                                                    deviceState?.disabled &&
                                                    deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                        ? elevatorStateDemo.elevatorHealth.overallState
                                                        : deviceState?.key === IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                        ? 'UNKNOWN'
                                                        : elevatorState?.elevatorHealth?.overallState
                                                }
                                            />
                                        )}
                                    <div style={{ height: 24 }} />

                                    {showDeviceState && (
                                        <HealthStateCard
                                            isDeactivated={
                                                deviceState?.disabled &&
                                                deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                            }
                                            isLearning={false}
                                            type={HEALTH_STATE_TYPES.DEVICE}
                                            // @ts-ignore
                                            sections={
                                                deviceState?.disabled &&
                                                deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                    ? sectionDemo.deviceHealth
                                                    : sections.deviceHealth
                                            }
                                            // @ts-ignore
                                            overallState={
                                                deviceState?.disabled &&
                                                deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                                    ? elevatorStateDemo.deviceHealth.overallState
                                                    : elevatorState?.deviceHealth?.overallState
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <StatusCard
                                    isDeactivated={
                                        deviceState?.disabled &&
                                        deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                    }
                                />
                            )}
                        </Col>
                        <Col xs={24} lg={12} xl={10} xxl={12}>
                            <Row gutter={[theme.rowGutter.x, theme.rowGutter.y]}>
                                <Col xs={24}>
                                    <TotalTrips
                                        isDeactivated={
                                            deviceState?.disabled &&
                                            deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                        }
                                    />
                                </Col>
                                <Col xs={24} xxl={12}>
                                    <DoorMovement
                                        isDeactivated={
                                            deviceState?.disabled &&
                                            deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                        }
                                    />
                                </Col>
                                <Col xs={24} xxl={12}>
                                    <AverageTrips
                                        isDeactivated={
                                            deviceState?.disabled &&
                                            deviceState?.key !== IOT_DEVICE_DISABLE_REASONS.DEVICE_OFFLINE
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={8} xxl={6}>
                            <Row gutter={[theme.rowGutter.x, theme.rowGutter.y]}>
                                {liveLocation && <LiveLocation isDeactivated={deviceState?.disabled} />}
                                <ShaftTemperature isDeactivated={deviceState?.disabled} />
                                {history && <History />}
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Card>
                        <Result title={<FormattedMessage id="elevator.iot.notInstalled" />} />
                    </Card>
                )}
            </Loader>
        </div>
    );
};

export default IotDataTab;
