import React, { FC } from 'react';

import { TableFilterPanel } from 'modules/table';

import TableSearch, { TableSearchProps } from './TableSearch';

type FilterPanelProps = {
    defaultSearch: TableSearchProps['defaultValue'];
    isClosed?: boolean;
};

const FilterPanel: FC<FilterPanelProps> = ({ defaultSearch, isClosed }) => (
    <TableFilterPanel>
        <TableSearch defaultValue={defaultSearch} isClosed={isClosed} />
    </TableFilterPanel>
);

export default FilterPanel;
