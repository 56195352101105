import { ApiAction } from '../../../../../types';
import { AverageTrips } from '../../../types';

import { fetchAverageTripsTypes } from '../../actions';

const initialState: AverageTrips[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_SUCCESS: {
            const trips: AverageTrips[] = action.payload;

            return trips;
        }
        case fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_RESET:
            return initialState;
        default:
            return state;
    }
};
