import React from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import type { AssessmentReport } from 'modules/entities/modules/documents-assessment-reports';
import { AssessmentReportField, DEFAULT_SORT } from 'modules/entities/modules/documents-assessment-reports';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

const SORT_FIELDS = [
    {
        field: AssessmentReportField.ASSESSMENT_REPORT_DATE,
        labelId: 'documents.assessment-reports.sort.assessmentDate',
    },
    {
        field: AssessmentReportField.SERVICE_PROVIDER_NAME,
        labelId: 'documents.assessment-reports.sort.serviceProvider.name',
    },
    {
        field: AssessmentReportField.RESULT,
        labelId: 'documents.assessment-reports.sort.result',
    },
    {
        field: AssessmentReportField.NO_TOTAL_DEFICIENCIES,
        labelId: 'documents.assessment-reports.sort.noTotalDeficiencies',
    },
    {
        field: AssessmentReportField.DUE_DATE,
        labelId: 'documents.assessment-reports.sort.dueDate',
    },
    {
        field: AssessmentReportField.NEXT_ASSESSMENT,
        labelId: 'documents.assessment-reports.sort.nextAssessment',
    },
];

export interface ActionPanelProps {
    documents: AssessmentReport[];
}

export const AssessmentReportsActionPanel = ({ documents }: ActionPanelProps) => {
    return (
        <TableActionPanel testId="assessment-reports-actions">
            <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
            <DocumentsDownloadAll documents={documents} />
        </TableActionPanel>
    );
};
