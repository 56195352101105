import * as sagaEffects from 'redux-saga/effects';

import saga from 'services/sagas';

import { saga as localizationSaga } from 'modules/core/modules/localization';

const { all } = sagaEffects;

export default function* rootSaga() {
    yield all([localizationSaga(), saga()]);
}
