import React, { FC } from 'react';

import { TableFilterPanel } from 'modules/table';

import TableSearch, { TableSearchProps } from './TableSearch';

type FilterPanelProps = {
    defaultSearch: TableSearchProps['defaultValue'];
};

const FilterPanel: FC<FilterPanelProps> = ({ defaultSearch }) => (
    <TableFilterPanel>
        <TableSearch defaultValue={defaultSearch} />
    </TableFilterPanel>
);

export default FilterPanel;
