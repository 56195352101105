import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { useHistory } from 'react-router-dom';
import { render, unmountComponentAtNode } from 'react-dom';

import { useFelaEnhanced } from 'hooks';
import { ElevatorGroup } from 'modules/entities/modules/elevators';

import { MarkerWithElevatorGroup, MARKER_TYPES } from '../../types';
import { createCluster, getElevatorGroupState, fitBounds, createMap, createMarker, prevInfoWindows } from '../../utils';

import { InfoWindow } from '../InfoWindow';
import { ClusterInfoWindow } from '../ClusterInfoWindow';
import { InfoWindowProviders } from '../InfoWindowProviders';
import { MapControls } from '../MapControls';

import * as felaRules from './Map.rules';

export interface MapProps {
    elevatorGroups: ElevatorGroup[];
}

export const Map = ({ elevatorGroups }: MapProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const ref = useRef<HTMLDivElement>();
    const mapRef = useRef<google.maps.Map>(null);
    const markersRef = useRef<google.maps.Marker[]>([]);

    const history = useHistory();
    const intl = useIntl();
    const { renderer, theme } = useFela();

    useEffect(() => {
        mapRef.current = createMap(ref);
    }, []);

    useEffect(() => {
        const map = mapRef.current;

        const infoWindowNodes = [];

        

        const markers = elevatorGroups.map((elevatorGroup, i) => {
            const { propertyUnit, elevators } = elevatorGroup;

            const elevatorGroupState = getElevatorGroupState(elevatorGroup);
            // if there is a potential issue, extract the set and create a group
            const markerOptions = {
                position: {
                    lat: propertyUnit.lat,
                    lng: propertyUnit.lng,
                },
                map: map,
                label: {
                    text: elevators.length.toString(),
                    className: elevators.length > 0 ? styles.markerLabel : styles.emptyMarkerLabel,
                    fontFamily:'WorkSans'
                },
                icon: MARKER_TYPES[elevatorGroupState].markerIcon,
                // zIndex: i === 0? 33333333333: 2000
            };

            const infoContentNode = document.createElement('div');
            infoWindowNodes.push(infoContentNode);

            render(
                <InfoWindowProviders intl={intl} history={history} renderer={renderer} theme={theme}>
                    <InfoWindow elevators={elevators} />
                </InfoWindowProviders>,
                infoContentNode,
            );

            const infoWindowOptions = {
                content: infoContentNode,
            };

            const marker: MarkerWithElevatorGroup = createMarker(map, markerOptions, infoWindowOptions);

            // Note: We need to add elevatorGroup here, so we can read it from cluster and pass it to InfoWindow
            marker.elevatorGroup = elevatorGroup;

            return marker;
        });

        

        fitBounds(map, markers);

        const getInfoWindowOptions = (elevatorGroups: ElevatorGroup[]) => {
            const infoContentNode = document.createElement('div');
            infoWindowNodes.push(infoContentNode);

            render(
                <InfoWindowProviders intl={intl} history={history} renderer={renderer} theme={theme}>
                    <ClusterInfoWindow elevatorGroups={elevatorGroups} />
                </InfoWindowProviders>,
                infoContentNode,
            );

            return {
                content: infoContentNode,
            };
        };

        // const issuesMarkers = markers.slice(0,1)
        // const technicianMarkers = markers.slice(2,60)
        // const operationalMarkers = markers.slice(60,92)
        // const notOperationalMarkers = markers.slice(30,50)

        // const cluster2 = createCluster(map, technicianMarkers, getInfoWindowOptions, { className: styles.clusterLabel });
        // const cluster3 = createCluster(map, operationalMarkers, getInfoWindowOptions, { className: styles.clusterLabel });
        const cluster = createCluster(map, markers, getInfoWindowOptions, { className: styles.clusterLabel });


        markersRef.current = markers;

        return () => {
            cluster.removeMarkers(markers);
            cluster.setMap(null);

            markers.forEach(marker => {
                marker.setMap(null);
            });

            infoWindowNodes.forEach(node => {
                unmountComponentAtNode(node);
            });

            if (prevInfoWindows.has(map)) {
                prevInfoWindows.delete(map);
            }
        };
    }, [
        renderer,
        theme,
        history,
        intl,
        elevatorGroups,
        styles.markerLabel,
        styles.emptyMarkerLabel,
        styles.map,
        styles.clusterLabel,
    ]);

    return (
        <>
            <div ref={ref} id="map" className={styles.map} />
            <MapControls
                disabled={!mapRef.current}
                onZoomInClick={() => {
                    const map = mapRef.current;
                    map.setZoom(map.getZoom() + 1);
                }}
                onZoomOutClick={() => {
                    const map = mapRef.current;
                    map.setZoom(map.getZoom() - 1);
                }}
                onResetClick={() => {
                    const map = mapRef.current;
                    const markers = markersRef.current;
                    fitBounds(map, markers);
                }}
            />
        </>
    );
};
