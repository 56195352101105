import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';

import { createUIErrorMessage } from 'modules/errors';
import { ElevatorState } from 'modules/entities/modules/elevators';

import { takeRequest } from 'services/sagas/takeRequest';

import type { TokenPaginationApiReducerState } from '../../../../services/reducers/tokenPaginationApi';

import { UrlParam } from 'constants/index';

import { StatesResponse } from '../../types';
import { fetchElevatorStates as actions, FetchElevatorStatesAction } from '../actions';
import { selectElevatorStatesApi, selectElevatorStatesEntityList } from '../selectors';
import { RequestResult } from '@ackee/antonio-core';

function* fetchElevatorStates(
    action: FetchElevatorStatesAction,
    currentApi: TokenPaginationApiReducerState,
    currentStates: ElevatorState[],
) {
    const { id, pageToken, pageSize, enableShareToken } = action.meta;

    yield put(actions.fetchElevatorStatesRequest());

    try {
        const { data }: RequestResult<StatesResponse> = yield call([authApi, authApi.get], config.api.elevatorStates, {
            uriParams: {
                id,
            },
            params: pageToken
                ? {
                      pageSize,
                      pageToken,
                  }
                : { pageSize },
        });

        if (isEmpty(data.states) && !data.sharePageToken) {
            // User requested data via prev/next token, but the token leads non-existing page
            // (due to the nature of BE) and thus we keep the current states and omit the token
            // that led to non-existing page from pageTokens and we push the page token of
            // the current states back to url if share token enabled

            yield put(
                actions.fetchElevatorStatesSuccess(id, currentStates, {
                    pageSize,
                    pageTokens: omit(currentApi.pageTokens, pageToken === currentApi.pageTokens.prev ? 'prev' : 'next'),
                }),
            );

            if (enableShareToken) {
                const query = new URLSearchParams();
                query.append(UrlParam.PAGE_TOKEN_KEY, currentApi.pageTokens.share);

                yield put(replace(`?${query.toString()}`));
            }
        } else {
            yield put(
                actions.fetchElevatorStatesSuccess(id, data.states, {
                    pageSize,
                    pageTokens: {
                        prev: data.prevPageToken,
                        share: data.sharePageToken,
                        next: data.nextPageToken,
                    },
                }),
            );

            if (enableShareToken && !pageToken) {
                const query = new URLSearchParams();
                query.append(UrlParam.PAGE_TOKEN_KEY, data.sharePageToken);

                yield put(replace(`?${query.toString()}`));
            }
        }
    } catch (error) {
        const uiError = createUIErrorMessage(error);

        log.error(error);

        yield put(actions.fetchElevatorStatesFailure(id, uiError));
    }
}

function* fetchElevatorStatesHandler(action: FetchElevatorStatesAction) {
    const api: TokenPaginationApiReducerState = yield select(selectElevatorStatesApi);
    const states = yield select(selectElevatorStatesEntityList);

    if (
        api.pageSize !== action.meta.pageSize ||
        // Do not care about the tokens if not share token enabled,
        !action.meta.enableShareToken ||
        // If share token enabled, refetch only if the previous tokens are not the same
        // or if there are no tokens at all
        api.pageTokens.share !== action.meta.pageToken ||
        (!api.pageTokens?.share && !action.meta.pageToken)
    ) {
        if (api.success || api.error) {
            yield put(actions.fetchElevatorStatesReset());
        }

        yield fetchElevatorStates(action, api, states);
    } else {
        yield put(actions.fetchElevatorStatesAbort());
    }
}

function* clearElevatorStatesHandler() {
    const api: TokenPaginationApiReducerState = yield select(selectElevatorStatesApi);

    if (api.success || api.error) {
        yield put(actions.fetchElevatorStatesReset());
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchElevatorStates',
        },
        {
            pattern: actions.fetchElevatorStates.toString(),
            handler: fetchElevatorStatesHandler,
        },
        {
            pattern: actions.clearElevatorStates.toString(),
            handler: clearElevatorStatesHandler,
        },
    );
}
