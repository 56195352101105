import type { TRuleWithTheme } from 'styles/theme';

export const icon: TRuleWithTheme = () => ({
    fontSize: '1rem',
    marginRight: '.5rem',

    '& svg': {
        height: '.875rem',
        width: '.875rem',
    },
});
