import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.SUPPORT_REQUEST,
});

export const fetchSupportsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_SUPPORTS_' }),
});

