import React from 'react';

const AlertWarningIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.2467 13.6466L8.58005 1.64656C8.5219 1.54396 8.43758 1.45862 8.33569 1.39925C8.23379 1.33988 8.11798 1.30859 8.00005 1.30859C7.88212 1.30859 7.7663 1.33988 7.6644 1.39925C7.56251 1.45862 7.47819 1.54396 7.42005 1.64656L0.753379 13.6466C0.694867 13.7479 0.664063 13.8629 0.664062 13.9799C0.664062 14.0969 0.694867 14.2119 0.753379 14.3132C0.812956 14.4137 0.897896 14.4968 0.99969 14.5541C1.10148 14.6115 1.21656 14.641 1.33338 14.6399H14.6667C14.7824 14.6399 14.8961 14.6097 14.9966 14.5525C15.0972 14.4952 15.181 14.4127 15.24 14.3132C15.2996 14.2125 15.3315 14.0978 15.3327 13.9808C15.3339 13.8638 15.3042 13.7485 15.2467 13.6466ZM2.46671 13.3066L8.00005 3.34656L13.5334 13.3066H2.46671Z"
            fill="#E61919"
        />
        <path
            d="M7.33325 6.63981V9.97314C7.33325 10.15 7.40349 10.3195 7.52851 10.4445C7.65354 10.5696 7.82311 10.6398 7.99992 10.6398C8.17673 10.6398 8.3463 10.5696 8.47132 10.4445C8.59635 10.3195 8.66659 10.15 8.66659 9.97314V6.63981C8.66659 6.463 8.59635 6.29343 8.47132 6.16841C8.3463 6.04338 8.17673 5.97314 7.99992 5.97314C7.82311 5.97314 7.65354 6.04338 7.52851 6.16841C7.40349 6.29343 7.33325 6.463 7.33325 6.63981Z"
            fill="#E61919"
        />
        <path
            d="M7.99992 12.64C8.36811 12.64 8.66659 12.3415 8.66659 11.9733C8.66659 11.6051 8.36811 11.3066 7.99992 11.3066C7.63173 11.3066 7.33325 11.6051 7.33325 11.9733C7.33325 12.3415 7.63173 12.64 7.99992 12.64Z"
            fill="#E61919"
        />
    </svg>
);

export default AlertWarningIcon;
