import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';
import { createFetchFailureAction, createFetchSuccessAction } from 'services/utils/actions';

import { Workorder, WorkorderType } from '../types';

export const fetchWorkorders = createAction(
    `${EntityKey.DOCUMENT_WORKORDER}/FETCH_WORKORDER`,
    ({ type }: { type: WorkorderType }, elevatorId?: string) => ({
        meta: {
            type,
            elevatorId,
        },
        payload: null,
    }),
);
export const fetchWorkordersRequest = createAction(`${EntityKey.DOCUMENT_WORKORDER}/FETCH_WORKORDER_REQUEST`);
export const fetchWorkordersSuccess = createFetchSuccessAction<Workorder>(
    `${EntityKey.DOCUMENT_WORKORDER}/FETCH_WORKORDER_SUCCESS`,
);
export const fetchWorkordersFailure = createFetchFailureAction(
    `${EntityKey.DOCUMENT_WORKORDER}/FETCH_WORKORDER_FAILURE`,
);
export const fetchWorkordersClear = createAction(`${EntityKey.DOCUMENT_WORKORDER}/FETCH_WORKORDER_CLEAR`);
export const fetchWorkordersReset = createAction(`${EntityKey.DOCUMENT_WORKORDER}/FETCH_RISK_ASSESSMENT_RESET`);
