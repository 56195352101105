import { useSelector } from 'react-redux';

import { selectFilteredOpenSupports, selectFilteredClosedSupports } from '../services/selectors';
import { useSearchSearchParams } from 'modules/table';
import { useFetchSupports } from './useFetchSupports';
import { useDisplayError } from 'modules/errors';

export const useSupports = () => {
    const { search, defaultValue } = useSearchSearchParams();

    const api = useFetchSupports();

    const openSupports = useSelector(state => selectFilteredOpenSupports(state, { search }));
    const closedSupports = useSelector(state => selectFilteredClosedSupports(state, { search }));

    useDisplayError(api.error);

    return {
        loading: api.inProgress,
        error: api.error,
        openSupports,
        closedSupports,
        defaultSearch: defaultValue,
    };
};
