import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { UrlParam } from 'constants/index';

import { manipulateQueryString } from 'services/utils';
import { useSearchParams } from 'services/hooks';

import { selectCountInvoicesByState } from 'modules/entities/modules/documents-invoices/services/selectors';
import { InvoiceState } from 'modules/entities/modules/documents-invoices';

const useInvoiceListStatusFilter = () => {
    const countByState = useSelector(selectCountInvoicesByState);
    const location = useLocation();
    const history = useHistory();

    const filterParams = useSearchParams(UrlParam.FILTER) as string[];

    useEffect(() => {
        if (!filterParams)
            history.push({
                search: manipulateQueryString(InvoiceState.CORRECT, UrlParam.FILTER, location.search, true),
            });
    }, [filterParams, history, location]);

    const onCheckboxChange = e => {
        const value = e.target.value;

        const finalSearch =
            value === InvoiceState.SHOW_ALL
                ? manipulateQueryString(InvoiceState.SHOW_ALL, UrlParam.FILTER, location.search)
                : manipulateQueryString(
                      value,
                      UrlParam.FILTER,
                      location.search,
                      !filterParams.includes(InvoiceState.SHOW_ALL),
                  );

        history.push({ search: finalSearch });
    };

    return { countByState, filterParams, onCheckboxChange };
};

export default useInvoiceListStatusFilter;
