import React from 'react';

const CloseIcon = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="elevator_detail_contacts" transform="translate(-1386.000000, -18.000000)" fill="currentColor">
                <g id="Fill-1" transform="translate(1384.000000, 16.000000)">
                    <path d="M13.4141,12 L21.7071,3.707 C22.0981,3.316 22.0981,2.684 21.7071,2.293 C21.3161,1.902 20.6841,1.902 20.2931,2.293 L12.0001,10.586 L3.7071,2.293 C3.3161,1.902 2.6841,1.902 2.2931,2.293 C1.9021,2.684 1.9021,3.316 2.2931,3.707 L10.5861,12 L2.2931,20.293 C1.9021,20.684 1.9021,21.316 2.2931,21.707 C2.4881,21.902 2.7441,22 3.0001,22 C3.2561,22 3.5121,21.902 3.7071,21.707 L12.0001,13.414 L20.2931,21.707 C20.4881,21.902 20.7441,22 21.0001,22 C21.2561,22 21.5121,21.902 21.7071,21.707 C22.0981,21.316 22.0981,20.684 21.7071,20.293 L13.4141,12 Z" />
                </g>
            </g>
        </g>
    </svg>
);
export default CloseIcon;
