import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { GeneralUpload } from '../GeneralUpload';

import * as felaRules from './GeneralDocumentUploadPage.rules';
import { SeoHead } from 'modules/seo';

export const GeneralDocumentUploadPage = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'documents.generalUpload.seoTitle' })} />
            <Typography.Title level={2} className={styles.title}>
                <FormattedMessage id="documents.generalUpload.title" />
            </Typography.Title>
            <Typography.Paragraph type="secondary" className={styles.description}>
                <FormattedMessage id="documents.generalUpload.description" />
            </Typography.Paragraph>
            <GeneralUpload />
        </div>
    );
};
