import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography } from 'antd';

import { SeoHead } from 'modules/seo';
import * as felaRules from './IncidentsPage.rules';
import { useFelaEnhanced } from 'hooks';
import { Icon, IconType } from 'modules/ui';
import { useIncidents } from 'modules/entities/modules/incidents/hooks/useIncidents';
import { useHistory, useLocation } from 'react-router-dom';
import { NewIncidentButton } from 'modules/incident-new/components/NewIncidentButton';
import IncidentsTab from '../IncidentsTab';
import { IncidentInfo } from '../IncidentInfo';

export const IncidentsPage = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const { push } = useHistory();
    const location = useLocation();

    const {
        activeIncidents,
        resolvedIncidents,
        defaultSearch,
        loading,
        unvalidatedIncidents,
        unFilteredUnvalidatedIncidents,
    } = useIncidents();

    const isActivePage = location.pathname === '/incidents/active' || location.pathname === '/incidents';
    const shouldScrollToTop = isActivePage && (unvalidatedIncidents?.length ?? 0) > 0;

    useEffect(() => {
        if (shouldScrollToTop) {
            window.scrollTo(0, 0);
        }
    }, [shouldScrollToTop]);
    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'page.incidents.title' })} />

            {!!unFilteredUnvalidatedIncidents?.length && unFilteredUnvalidatedIncidents?.length > 0 && isActivePage && (
                <IncidentInfo>
                    <FormattedMessage
                        id="page.incidents.table.validation.text.message"
                        values={{
                            variable: unFilteredUnvalidatedIncidents.length,

                            link: (chunks: ReactNode[] | ReactNode) => (
                                <a
                                    href="https://simplifa.atlassian.net/wiki/spaces/SO/pages/2606694408/Informationen+zu+St+rungen+in+Kl+rung "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                            br: <br />,
                        }}
                    />
                </IncidentInfo>
            )}
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <Button
                        aria-label={intl.formatMessage({
                            id: 'elevator.new.dialog.goBack',
                        })}
                        icon={<Icon type={IconType.BACK} className={styles.icon} />}
                        onClick={() => {
                            push('/');
                        }}
                        className={styles.button}
                    />
                    <Typography.Title level={4} className={styles.title}>
                        <FormattedMessage id="page.incidents.title" />
                    </Typography.Title>
                </div>
                <NewIncidentButton />
            </div>

            <IncidentsTab
                activeIncidents={activeIncidents}
                closedIncidents={resolvedIncidents}
                unvalidatedIncidents={isActivePage ? unvalidatedIncidents : []}
                loading={loading}
                defaultSearch={defaultSearch}
            />
        </div>
    );
};
