import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';
import { combineRules } from 'fela';

export const container: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const animationWrapper: TRule = () => ({
    flexShrink: 0,

    width: 130,
});

export const floorWrapper: TRule = () => ({
    flexShrink: 0,
});

export const floor: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    height: 30,

    fontSize: '1rem',
    lineHeight: '1em',
});

export const currentFloorWrapper: TRule = () => ({
    '@media (min-width: 1745px)': {
        marginRight: '1rem',
    },
});

export const timeWrapper: TRule = () => ({
    flexShrink: 0,

    paddingLeft: '1rem',

    fontSize: '1rem',
    lineHeight: '1.5em',

    '@media (min-width: 1745px)': {
        display: 'flex',
    },
});

export const dateTimeTitle: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginTop: '1rem',
        marginBottom: '0.66em',

        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1.33em',

        color: theme.colors.textSecondary,
    },
});

export const floorTitle: TRuleWithTheme = combineRules(dateTimeTitle, ({ theme }) => ({
    display: 'flex',
    '&.ant-typography': {
        marginBottom: 0,
    },
}));

export const dateTimeRow: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    marginBottom: '0.143em',

    fontSize: '0.875rem',
    lineHeight: '1.43em',
});

export const dateTime: TRule = () => ({
    marginBottom: 0,
});

export const dateTimeIcon: TRule = () => ({
    fontSize: '0.75rem',
    marginRight: '0.5rem',
});
