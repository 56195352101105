import { ApiAction } from '../../../../../types';
import { USER_STATE } from '../../../types';

const initialState = {
    email: '',
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case `${USER_STATE}/SEND`: {
            return {
                ...action.payload,
            };
        }

        case `${USER_STATE}/SEND_RESET`:
            return initialState;

        default:
            return state;
    }
};
