import React, { FC } from 'react';

import { Icon, IconType } from 'modules/ui';
import { InvoiceState } from 'modules/entities/modules/documents-invoices/types';
import { ContractState } from 'modules/entities/modules/documents-contracts/types';
import { OtherDocumentState } from 'modules/entities/modules/other-documents/types';
import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import { Tables } from '../../types';
import { StatusIconSize } from './types';

import * as felaRules from './StatusIcon.styles';

type StatusIconProps = {
    state: InvoiceState | ContractState | OtherDocumentState;
    table: Tables;
    size?: StatusIconSize;
    extend?: RulesExtend<typeof felaRules>;
};

const StatusIcon: FC<StatusIconProps> & { Size: typeof StatusIconSize } = ({ state, table, size, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { state, table, extend, size });

    return <Icon type={IconType.ELEVATOR_STATUS} data-testid={`status-icon-${state}`} className={styles.icon} />;
};

StatusIcon.Size = StatusIconSize;
export default StatusIcon;
