import React, { useEffect, useState } from 'react';

import { DOCUMENT_CURRENT_PAGE_STORAGE_KEY, DOCUMENT_PAGE_SIZE_STORAGE_KEY, RowLoader, Table, useDefaultTableConfig } from 'modules/table';
import type { TableProps } from 'modules/table';
import { useDownloadDocuments } from 'modules/entities/modules/documents';
import { useViewDocument } from '../../hooks/useViewDocument';
import type { DocumentFile } from 'modules/entities/modules/documents';
export interface DocumentsTableProps<T> extends Pick<TableProps<T>, 'columns' | 'loading' | 'dataSource' | 'extend'> {
    'data-testid'?: string;
}

export function DocumentsTable<
    DataType extends {
        id: string;
        file?: DocumentFile;
    }
>({ columns, loading, dataSource, 'data-testid': testId, extend }: DocumentsTableProps<DataType>) {
    const defaultPaginationConfig = useDefaultTableConfig();

    const { rowKey, opening, openDocument } = useViewDocument();
    const { resetDownloadSelect } = useDownloadDocuments();

    const [page, setPage] = useState(1);


    useEffect(() => {
        if (page === 1) {
            sessionStorage.setItem(DOCUMENT_CURRENT_PAGE_STORAGE_KEY, '1');
        }
    }, [page]);

    return (
        <Table<DataType>
            columns={columns}
            loading={loading}
            dataSource={dataSource}
            rowKey="id"
            toolBarRender={false}
            search={false}
            pagination={defaultPaginationConfig}
            data-testid={testId}
            onChange={pageInfo => {
                setPage(pageInfo?.current);
                resetDownloadSelect(); // when the page is changed we reset the selected download
                sessionStorage.setItem(DOCUMENT_CURRENT_PAGE_STORAGE_KEY, pageInfo?.current?.toString());
                sessionStorage.setItem(DOCUMENT_PAGE_SIZE_STORAGE_KEY, pageInfo?.pageSize?.toString());
            }}
            extend={extend}
            onRow={doc => ({
                style: {
                    cursor: doc.file ? 'pointer' : 'default',
                    verticalAlign: 'top',
                },
                onClick: e => {
                    e.stopPropagation();
                    if (doc.file) {
                        openDocument({
                            id: doc.id,
                            file: doc.file,
                        });
                    }
                },
            })}
            footer={() => <RowLoader show={opening} rowKey={rowKey} />}
        />
    );
}
