import { combineReducers } from 'redux';
import routingHistory from '@ackee/chris/routing-history';

import { reducer as translateReducer } from 'modules/core/modules/localization';
import { connectRouterWithHistory } from 'modules/core/modules/router';
import reducers from 'services/reducers';

export default combineReducers({
    ...reducers,
    translate: translateReducer,
    router: connectRouterWithHistory,
    history: routingHistory.reducer,
});
