import { combineReducers } from 'redux';

import fetchUsers from './fetchUsers';
import  setUser from  './setUser'
import fetchUsersRolesPermissions from './fetchUsersRolesPermissions'

export default combineReducers({
    fetchUsers,
    setUser,
    fetchUsersRolesPermissions
});
