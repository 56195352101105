import { createReducer, combineReducers } from '@reduxjs/toolkit';
import type { AnyAction } from '@reduxjs/toolkit';

import { UNREGISTER_FORM } from 'modules/form/services/actions/types';
import type { UnregisterFormAction } from 'modules/form/services/actions/types';

import { forms } from '../../config';
import type { RegistrationStatus } from '../../types';

import { updateRegistrationStatus } from '../actions';

const initialState: RegistrationStatus | null = null;

function isUnregisterAction(action: AnyAction): action is UnregisterFormAction {
    return action.type === UNREGISTER_FORM && action?.meta?.form === forms.register;
}

const status = createReducer(initialState, builder =>
    builder.addCase(updateRegistrationStatus, (_, action) => action.payload).addMatcher(isUnregisterAction, () => null),
);

export const registration = combineReducers({
    status,
});
