import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'antd';

import { useFormSubmission } from 'modules/form';
import { Paragraph, useUIMessage } from 'modules/ui';
import { useFelaEnhanced } from 'hooks';

import { fields, forms } from '../../config';
import AuthForm, { SubmitAuthFormButton } from '../AuthForm';
import { isFormSubmissionError } from '../../utils';
import type { ConfirmPasswordFormValues } from '../../types';

import * as felaRules from './ForgottenPasswordEmailForm.styles';

type ForgottenPasswordEmailFormProps = {};

const ForgottenPasswordEmailForm: FC<ForgottenPasswordEmailFormProps> = () => {
    const { formatMessage } = useIntl();
    const { rules } = useFelaEnhanced(felaRules);

    const { handleSubmit, submitting, error, succeeded } = useFormSubmission<ConfirmPasswordFormValues>(
        forms.forgottenPasswordEmail,
    );

    const message = useUIMessage();

    useEffect(() => {
        if (succeeded) {
            message('success', 'auth.forgotPasswordEmail.success');
        }
    }, [succeeded, message]);

    return (
        <AuthForm
            name={forms.forgottenPasswordEmail}
            title={formatMessage({ id: 'auth.forgotPasswordTitle' })}
            layout="vertical"
            onFinish={handleSubmit}
            extend={{ form: rules.form }}
        >
            <Paragraph size="small" faded extend={{ paragraph: rules.paragraph }}>
                <FormattedMessage id="auth.forgotPasswordMessage" />
            </Paragraph>
            <Form.Item
                name={fields.forgottenPasswordEmail.email}
                rules={[{ required: true, message: formatMessage({ id: 'form.validation.required.email' }) }]}
                validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                help={isFormSubmissionError(error) ? formatMessage({ id: error }) : undefined}
            >
                <Input
                    type="email"
                    size="large"
                    autoComplete="email"
                    placeholder={formatMessage({ id: 'auth.email' })}
                />
            </Form.Item>
            <Form.Item>
                <SubmitAuthFormButton
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={submitting}
                    loading={submitting}
                >
                    {formatMessage({ id: 'auth.forgotPassword.continue' })}
                </SubmitAuthFormButton>
            </Form.Item>
        </AuthForm>
    );
};

export default ForgottenPasswordEmailForm;
