export enum DialogState {
    SELECTION = 'selection',
    CREATE_EXCEL = 'create-excel',
    CREATE_ASSESSMENT_REPORT = 'create-assessment-report',
    CREATE_MANUAL = 'create-manul',
}

export const eventMap = {
    [DialogState.CREATE_MANUAL]: 'ELEVATOR_CREATION_MANUAL',
    [DialogState.CREATE_EXCEL]: 'ELEVATOR_CREATION_EXCEL',
    [DialogState.CREATE_ASSESSMENT_REPORT]: 'ELEVATOR_CREATION_ASSESSMENT',
};
