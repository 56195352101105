import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'constants/index';

const apiRequest = createApiRequestType({ modulePrefix: EntityKey.DOCUMENTS });

export const downloadDocumentsTypes = strictObjectAccess({ ...apiRequest({ typePrefix: 'DOWNLOAD_DOCUMENTS_' }) });

export const SELECT_DOWNLOAD = `${EntityKey.DOCUMENTS}/SELECT_DOWNLOAD`;
export const UNSELECT_DOWNLOAD = `${EntityKey.DOCUMENTS}/UNSELECT_DOWNLOAD`;
export const RESET_SELECT_DOWNLOAD = `${EntityKey.DOCUMENTS}/RESET_SELECT_DOWNLOAD`;
