import { useSelector } from 'react-redux';
import type { Location } from 'history';

import { previousLocationSelector } from 'services/selectors';

const usePreviousLocation = (fallbackPath: string = '/') => {
    const previousLocation = useSelector(previousLocationSelector) as Location;

    if (previousLocation && previousLocation.pathname) return previousLocation;
    return fallbackPath;
};

export default usePreviousLocation;
