import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Col, Typography } from 'antd';

import { DataCard } from 'modules/ui';
import { selectors as elevatorsSelectors, useSubscribeElevatorFloorHistory } from 'modules/entities/modules/elevators';

import { card } from './LiveLocation.rules';

import LiveLocationContent from '../LiveLocationContent';

const LiveLocation: FC<{ isDeactivated?: boolean }> = ({ isDeactivated = false }) => {
    const { inProgress, success } = useSelector(elevatorsSelectors.selectElevatorApi);

    const { id } = useParams<{
        id: string;
    }>();

    let currentFloor = useSubscribeElevatorFloorHistory(id);

    if (success && !currentFloor) {
        return null;
    }

    if (isDeactivated && !currentFloor) {
        currentFloor = { time: '2023-02-27T11:25:24.000Z', floor: 4 };
    }

    if (isDeactivated) {
        return <div />;
    }

    return (
        <Col xs={24} lg={12} xl={24}>
            <DataCard
                style={{ filter: isDeactivated ? 'blur(1px)' : 'none' }}
                title={
                    <Typography.Title level={4}>
                        <FormattedMessage id="page.elevatorDetail.tabs.iotData.liveLocation.heading" />
                    </Typography.Title>
                }
                loading={inProgress}
                extend={{
                    card,
                }}
            >
                <LiveLocationContent currentFloor={currentFloor} isDeactivated={isDeactivated} />
            </DataCard>
        </Col>
    );
};

export default LiveLocation;
