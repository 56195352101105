import React, { FC, ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import { Typography } from 'antd';
import { DataCard, Icon, IconType, Popover, PopoverContainer } from 'modules/ui';
import { ElevatorState, HEALTH_STATE_TYPES, ParsedSection } from 'modules/entities/modules/elevators';

import * as felaRules from './HealthStateCard.styles';
import OverallStatusAlert from './OverallStatusAlert/OverallStatusAlert';
import Sections from './Sections';
import { InfoIcon } from 'modules/maintenance/InfoIcon';

type HealthStateCardProps = {
    type: HEALTH_STATE_TYPES;
    overallState: ElevatorState;
    sections: ParsedSection[];
    inProgress?: boolean;
    isDeactivated?: boolean;
    isDisconnected?: boolean;
    isLearning?: boolean;
};

const HealthStateCard: FC<HealthStateCardProps> = ({
    type,
    inProgress,
    sections,
    overallState,
    isDeactivated,
    isLearning,
    isDisconnected,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { type, isDeactivated });
    const [isOpened, setIsOpened] = useState(overallState !== 'RUNNING' && overallState !== 'UNKNOWN');
    const intl = useIntl();
    const renderCollapsableTrigger = () => {
        return (
            <div
                className={styles.collapseTrigger}
                onClick={() => {
                    if (!isDisconnected) setIsOpened(opened => !opened);
                }}
            >
                <div className={!isDisconnected ? styles.collectionTrigger : styles.collectionTriggerDisable}>
                    <div className={!isDisconnected ? styles.collapseTriggerText : styles.collapseTriggerTextDisabled}>
                        <FormattedMessage id={`healthstate.collapse.label.${isOpened ? 'hide' : 'show'}`} />
                    </div>
                    <Icon
                        className={!isDisconnected ? styles.iconStyle : styles.iconStyleDisable}
                        type={isOpened ? IconType.ARROW_UP : IconType.ARROW_DOWN}
                    />
                </div>
            </div>
        );
    };
    return (
        <DataCard
            className={isDeactivated ? styles.blur : styles.card}
            title={
                <Typography.Title className={styles.healthHeader} level={4}>
                    <div className={styles.healthHeader}>
                        <div className={styles.healthHeaderText}>
                            <FormattedMessage id={`healthstate.title.${type}`} />
                        </div>
                        <Popover
                            placement="bottomRight"
                            content={
                                <PopoverContainer>
                                    <PopoverContainer.Item
                                        secondaryText={
                                            <div>
                                                <div className={styles.popoverHeader}>
                                                    <FormattedMessage
                                                        id={
                                                            type === HEALTH_STATE_TYPES.DEVICE
                                                                ? 'healthstate.popover.title'
                                                                : 'healthstate.popover.iot.title'
                                                        }
                                                    />
                                                </div>
                                                <div className={styles.popoverBody}>
                                                    <FormattedMessage
                                                        id={
                                                            type === HEALTH_STATE_TYPES.DEVICE
                                                                ? 'healthstate.popover.message'
                                                                : 'healthstate.popover.iot.message'
                                                        }
                                                        values={{
                                                            link: (chunks: ReactNode[] | ReactNode) => (
                                                                <a
                                                                    href={intl.formatMessage({
                                                                        id: 'healthstate.popover.message.link',
                                                                    })}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {chunks}
                                                                </a>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </PopoverContainer>
                            }
                            trigger="hover"
                        >
                            <InfoIcon className={styles.iconContainer} />
                        </Popover>
                    </div>
                </Typography.Title>
            }
            loading={inProgress}
        >
            {isLearning ? (
                <div className={styles.deactivated}>
                    <div className={styles.deactivatedIcon}>
                        <Icon type={IconType.BOOK} />
                    </div>{' '}
                    <FormattedMessage id={`healthstate.title.elevator.disabled`} />
                </div>
            ) : (
                <div>
                    <OverallStatusAlert
                        state={overallState}
                        descriptions={[
                            <div>
                                <FormattedMessage
                                    id={`healthstate.status.${type}.${overallState?.trim()}.description`}
                                />
                            </div>,
                        ]}
                    />
                    {renderCollapsableTrigger()}

                    {!!isOpened ? <Sections sections={sections} type={type} /> : <div />}
                </div>
            )}
        </DataCard>
    );
};

export default HealthStateCard;
