export const manipulateQueryString = (newItemValue, newItemName, locationSearch, isArray = false) => {
    const params = new URLSearchParams(locationSearch);
    const theParam = params.get(newItemName);

    if (!theParam || !isArray) {
        params.set(newItemName, newItemValue);
    } else {
        const filterQueryArray = theParam.split(',');
        const indexOfNewItem = filterQueryArray.indexOf(newItemValue);
        if (indexOfNewItem >= 0) {
            filterQueryArray.splice(indexOfNewItem, 1);
        } else {
            filterQueryArray.push(newItemValue);
        }
        if (filterQueryArray.length > 0) {
            params.set(newItemName, filterQueryArray.join(','));
        } else {
            params.delete(newItemName);
        }
    }
    return params.toString();
};


export const replaceQueryString = (newItemValue, newItemName, locationSearch, isArray = false) => {
    const params = new URLSearchParams(locationSearch);
    params.set(newItemName, newItemValue);
    return params.toString();
};

export const omitQueryString = (removedItemName, locationSearch) => {
    const params = new URLSearchParams(locationSearch);

    params.delete(removedItemName);
    return params.toString();
};
