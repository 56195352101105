import type { TRuleWithTheme } from 'styles/theme';

export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',

        color: theme.colors.textSecondary,
    },
});
