import React, { useRef, useState } from 'react';
import { Modal, Form } from 'antd';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './NewElevatorDialog.rules';
import { NewIncidentDialogForm } from '../NewIncidentDialogForm';
import { ErrorTypes } from 'modules/incident-new/types';
import { useLocation } from 'react-router-dom';
import { useGlobalDialog } from 'hooks/useGlobalDialog';
import { DialogState } from 'constants/common';

export const NewIncidentDialog = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const containerRef = useRef();
    const { state, navigate } = useGlobalDialog();
    const [messageType, setMessageType] = useState<ErrorTypes | null>();
    const [description, setDescription] = useState('');
    const [type, setType] = useState<string>(null);
    const [elevator, setElevator] = useState('');
    const [form] = Form.useForm();
    const { pathname } = useLocation();

    return (
        <div ref={containerRef}>
            <Modal
                title={null}
                footer={null}
                closable={false}
                visible={state === DialogState.CREATE_INCIDENT}
                getContainer={() => containerRef.current}
                className={styles.modal}
                width={738}
                onCancel={() => {
                    form.resetFields();
                    navigate(null);
                    setMessageType(null);
                    setType(null);
                    setDescription(null);
                    if (!pathname.includes('elevator')) setElevator('');
                }}
            >
                <NewIncidentDialogForm
                    form={form}
                    messageType={messageType}
                    setMessageType={setMessageType}
                    type={type}
                    setType={setType}
                    description={description}
                    setDescription={setDescription}
                    elevator={elevator}
                    setElevator={setElevator}
                />
            </Modal>
        </div>
    );
};
