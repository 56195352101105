import React from 'react';

const AlarmOutOfOrder = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="ic/24/o-o-o" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M18.4735,19.9722 C16.1795,19.6842 12.9315,18.6712 9.6835,15.7312 L10.4955,14.9182 C12.1905,16.3002 13.7945,16.6432 14.4625,16.7282 C14.9555,16.7922 15.4625,16.6182 15.8145,16.2662 L16.9905,15.0902 L19.8925,16.9602 L18.4735,19.9722 Z M4.0285,5.5292 L7.0395,4.1102 L8.9115,7.0142 L7.7415,8.1852 C7.3845,8.5362 7.2095,9.0432 7.2735,9.5392 C7.3605,10.2092 7.7045,11.8152 9.0825,13.5042 L8.2695,14.3182 C5.3285,11.0692 4.3145,7.8202 4.0285,5.5292 L4.0285,5.5292 Z M21.2475,15.4542 L17.7975,13.2332 C17.1565,12.8312 16.3275,12.9252 15.7905,13.4612 L14.5355,14.7162 C14.0005,14.6222 13.0115,14.3322 11.9225,13.4922 L21.9985,3.4182 L20.5835,2.0052 L10.5095,12.0772 C9.6725,10.9932 9.3805,10.0052 9.2855,9.4692 L10.5415,8.2122 C11.0785,7.6752 11.1735,6.8502 10.7635,6.1962 L8.5545,2.7692 C8.1135,2.0572 7.2095,1.7992 6.4755,2.1662 L2.9025,3.8492 C2.2985,4.1472 1.9395,4.7962 2.0085,5.4672 C2.2855,8.0942 3.3615,11.9442 6.8405,15.7452 L1.9995,20.5852 L3.4125,21.9992 L8.2545,17.1592 C12.0575,20.6382 15.9085,21.7142 18.5365,21.9922 C18.5915,21.9962 18.6465,21.9992 18.7005,21.9992 C19.3095,21.9992 19.8805,21.6502 20.1605,21.0802 L21.8275,17.5442 C22.2015,16.7952 21.9485,15.8942 21.2475,15.4542 L21.2475,15.4542 Z"
                id="Fill-1"
                fill="#A6A6A6"
            ></path>
        </g>
    </svg>
);

export default AlarmOutOfOrder;
