import { IncidentStatus } from '../../../entities/modules/incidents/types';
import { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const buttonColor: TRule<{ status?: any; theme: Theme }> = ({ theme, status }) => {
    let backgroundColor;
    let borderColor;
    let fontSize = '1rem';
    let fontWeight = 500;
    switch (status) {
        case IncidentStatus.Neu:
            backgroundColor = theme.colors.bgStatusWarning;
            borderColor = theme.colors.outlineStatusWarning;

            break;
        case IncidentStatus.Reparatur:
            backgroundColor = theme.colors.bgStatusHighAlert;
            borderColor = theme.colors.outlineStatusHighAlert;
            break;
        case IncidentStatus.Störungsbeseitigung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            break;
        case IncidentStatus.Entstörung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            break;
        case 'Geschlossen':
            backgroundColor = theme.colors.bgStatusSuccess;
            borderColor = theme.colors.outlineStatusSuccess;
            break;
        default:
            backgroundColor = theme.colors.white;
            borderColor = theme.colors.white;
    }

    return { backgroundColor, borderColor, fontSize, fontWeight, '&:hover': { backgroundColor: backgroundColor } };
};
