import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { UploadDocument } from '../UploadDocument';
import { UploadInfo } from '../UploadInfo';
import { UploadDescription } from '../UploadDescription';
import { UploadPage } from '../UploadPage';

export const UploadZUESDeficiencyPage = () => {
    const intl = useIntl();

    return (
        <UploadPage>
            <SeoHead title={intl.formatMessage({ id: 'page.uploadZUESDeficiency.seoTitle' })} />
            <UploadInfo
                title={<FormattedMessage id="page.uploadZUESDeficiency.title" />}
                description={<FormattedMessage id="page.uploadZUESDeficiency.description" />}
                subTitle={<FormattedMessage id="page.uploadDocument.info.title" />}
                listItems={[
                    <FormattedMessage id="page.uploadDocument.info.item-1" />,
                    <FormattedMessage id="page.uploadDocument.info.item-2" />,
                    <FormattedMessage id="page.uploadDocument.info.item-3" />,
                    <FormattedMessage id="page.uploadDocument.info.item-4" />,
                ]}
            />
            <UploadDocument type={ChecktypeDocumentsUploadType.ZUES_DEFICIENCY} />
            <UploadDescription>
                <FormattedMessage id="page.uploadZUESDeficiency.infoText" />
            </UploadDescription>
        </UploadPage>
    );
};
