import React from 'react';

const HomeIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 20H16V14C16 13.447 15.553 13 15 13H9C8.447 13 8 13.447 8 14V20H6V10.414L12 4.41401L18 10.414V20ZM10 20H14V15H10V20ZM19.707 9.29301L12.707 2.29301C12.316 1.90201 11.684 1.90201 11.293 2.29301L4.293 9.29301C4.105 9.48001 4 9.73401 4 10V21C4 21.553 4.447 22 5 22H9H15H19C19.553 22 20 21.553 20 21V10C20 9.73401 19.895 9.48001 19.707 9.29301Z"
            fill="currentColor"
        />
    </svg>
);

export default HomeIcon;
