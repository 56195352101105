import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    editNotificationRequestFailure,
    editNotificationRequest,
    editNotificationRequestSuccess,
    editNotificationRequestReset,
} from '../../actions';
import { EDIT_NOTIFICATION_SELECTOR_ID } from '../../../types';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: editNotificationRequest.toString(),
        SUCCESS: editNotificationRequestSuccess.toString(),
        FAILURE: editNotificationRequestFailure.toString(),
        RESET: editNotificationRequestReset.toString(),
    },
});

export const editNotificationReducer = containerReducer({
    childReducer,
    actionTypes: [
        editNotificationRequest.toString(),
        editNotificationRequestSuccess.toString(),
        editNotificationRequestFailure.toString(),
        editNotificationRequestReset.toString(),
    ],
    selectors: {
        itemId: () => EDIT_NOTIFICATION_SELECTOR_ID
    },
});
