import { put, cancelled } from 'redux-saga/effects';
import { noop } from 'lodash';
import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';
import { fetchUsersRolesPermissions as actions, fetchUsersRolesAndPermissionsTypes } from '../actions';

function* fetchUsersRolesAndPermissionsHandler() {
    const controller = new AbortController();
    try {
        const { data } = yield* authApi.get(`${config.api.users}/roles`);
        yield put(actions.fetchUsersRolesPermissionsSuccess(data));

    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchUsersRolesPermissionsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchUsersRolesPermissionsRequest',
            leading: true,
        },
        {
            pattern: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_REQUEST,
            handler: fetchUsersRolesAndPermissionsHandler,
        },
        {
            pattern: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_CANCEL,
            handler: noop,
        },
    );
}
