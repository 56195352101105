import React from 'react';
import { useLocation } from 'react-router';

import { UrlParam } from 'constants/index';

function useSearchParams(key: UrlParam, formatType?: 'array') {
    const { search } = useLocation();
    return React.useMemo(() => {
        const params = new URLSearchParams(search);
        const value = params.get(key);

        switch (formatType) {
            case 'array':
                return value?.split(',');
            default:
                return value;
        }

        // Props key and formatType won't change
        // eslint-disable-next-line
    }, [search]);
}

export default useSearchParams;
