import { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    textAlign: 'center',
    padding: '2.5rem',
    borderRadius: 2,
    maxWidth: 628,
});

export const title: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1.25rem',
    marginBottom: '1rem',
});

export const secondaryText: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textGrey,
    '&.ant-typography': {
        marginBottom: 0,
    },
});

export const mailLink: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.primary,
    fontSize: '1.25rem',
    fontWeight: 500,
    borderBottom: `2px solid ${theme.colors.primary}`,
    margin: '2rem 0',
    display: 'inline-block',
});
