import { TRuleWithTheme } from 'styles/theme';



export const container: any = ({ theme }) => ({
    '& .ant-input-affix-wrapper-focused': {
        '& .anticon': {
            color: '#f57645 !important',
        },
    },
})

export const input: TRuleWithTheme = ({ theme }) => ({
    padding: '0.40625rem .75rem',
    '& .anticon': {
        color: '#A6A6A6',
    },
    ':hover': {
        '& .anticon': {
            color: '#f57645 !important',
        },
    },
});
