import { IconType } from 'modules/ui';
import { ElevatorSubStateName } from 'modules/entities/modules/elevators';

export const statusIconMap = {
    [ElevatorSubStateName.TECHNICIAN_ONSITE]: IconType.TECHNICIAN_ON_SITE,
    [ElevatorSubStateName.CABINLIGHT_SENSOR_NOT_INSTALLABLE]: IconType.ELEVATOR_STATUS_INACTIVE,
    [ElevatorSubStateName.CABINLIGHT_LOW]: IconType.ELEVATOR_STATUS_WARNING,
    [ElevatorSubStateName.CABINLIGHT_OFF]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_ISSUE]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_BREAKDOWNEMERGENCYSTOP]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_TEMPORARYEMERGENCYSTOPS]: IconType.ELEVATOR_STATUS_WARNING,
    [ElevatorSubStateName.IOTDEVICE_BLACKOUT]: IconType.POWER_OUTAGE,
    [ElevatorSubStateName.IOTDEVICE_OFFLINE]: IconType.NO_IOT,
};
