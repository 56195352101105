import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { UrlParam } from 'constants/index';

import type { TokenPaginationApiReducerState } from '../../../services/reducers/tokenPaginationApi';

import { selectElevatorStatesApi } from '../services/selectors';

type ElevatorStatesPagingHook = () => {
    api: TokenPaginationApiReducerState;
    goPrev: () => void;
    goNext: () => void;
};

export const useElevatorStatesPaging: ElevatorStatesPagingHook = () => {
    const history = useHistory();

    const api = useSelector(selectElevatorStatesApi);

    const goPrev = () => {
        const newQueryParams = new URLSearchParams();

        newQueryParams.append(UrlParam.PAGE_TOKEN_KEY, api.pageTokens.prev);

        history.push(`?${newQueryParams.toString()}`);
    };

    const goNext = () => {
        const newQueryParams = new URLSearchParams();

        newQueryParams.append(UrlParam.PAGE_TOKEN_KEY, api.pageTokens.next);

        history.push(`?${newQueryParams.toString()}`);
    };

    return { api, goPrev, goNext };
};
