import { Button, Image, Select, Typography } from 'antd';
import { useFelaEnhanced } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as felaRules from './AssignElevators.styles';
import elevatorSvg from 'assets/images/elevator.svg';
import TeamDialogFooter from '../TeamDialogFooter';
import { CloseOutlined } from '@ant-design/icons';

interface SelectOption {
    label: string;
    value: string;
}

const AssignElevators = () => {
    const { styles, css } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const [elevators] = useState([
        {
            value: '1',
            label: 'FN 9274 AB67491,  Lützowufer 1,  Berlin',
        },
        {
            value: '2',
            label: 'FN 9274 AB67491,  Lützowufer 1,  Berlin',
        },
        {
            value: '3',
            label: 'FN 9274 AB67491,  Lützowufer 1,  Berlin',
        },
    ]);

    const [selectedElevator, setSelectedElevator] = useState('');
    const [options, setOptions] = useState<SelectOption[]>();
    const [selectedElevators, setSelectedElevators] = useState([]);

    const selectedElevatorsIds = useMemo(() => selectedElevators?.map?.(elevator => elevator.value), [
        selectedElevators,
    ]);

    useEffect(() => {
        const newOptions = elevators.filter(elevator => !selectedElevatorsIds.includes(elevator.value)) || [];
        setOptions(newOptions);
    }, [selectedElevators, elevators, selectedElevatorsIds]);

    const onChange = (value: string) => {
        const newOption = elevators.find(elevator => elevator.value === value);

        setSelectedElevators(elevators => [...elevators, newOption]);

        setSelectedElevator(value);
    };

    const unselectElevator = (value: string) => {
        const newSelectedElevators = selectedElevators.filter(elevator => elevator.value !== value);

        setSelectedElevators(newSelectedElevators);
    };

    return (
        <div>
            <Typography.Title level={4} className={styles.title}>
                {intl.formatMessage(
                    {
                        id: 'teams.manage.elevators.assign.elevators.to',
                    },
                    {
                        name: 'Max',
                    },
                )}
            </Typography.Title>
            <Typography.Paragraph className={styles.description}>
                <FormattedMessage id="teams.manage.elevators.assign.elevators.to.description" />
            </Typography.Paragraph>
            <div>
                <Select
                    size="large"
                    value={elevators.find(elevator => elevator.value === selectedElevator)?.label}
                    onChange={onChange}
                    style={{ width: '100%', height: '40px' }}
                    placeholder={intl.formatMessage({
                        id: 'teams.manage.elevators.assign.elevators.search',
                    })}
                    listHeight={300}
                >
                    {options?.map?.(option => (
                        <Select.Option value={option.value}>
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'space-between',

                                    ':hover': {
                                        '> button': {
                                            display: 'block',
                                            background: 'transparent',
                                            color: '#E84E1B',
                                        },
                                    },

                                    '> button': {
                                        display: 'none',
                                    },
                                })}
                            >
                                <Typography.Text>{option.label}</Typography.Text>
                                <Button
                                    className={css({
                                        border: 'none',

                                        '> span': {
                                            textDecoration: 'underline !important',
                                        },
                                    })}
                                >
                                    {intl.formatMessage({
                                        id: 'teams.manage.elevators.assign.elevators.select.all',
                                    })}
                                </Button>
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {selectedElevators.length ? (
                <>
                    <div
                        className={css({
                            marginTop: '30px',
                        })}
                    >
                        <Typography.Title level={5}>
                            {intl.formatMessage(
                                {
                                    id: 'teams.manage.elevators.selected',
                                },
                                {
                                    number: selectedElevators.length,
                                },
                            )}
                        </Typography.Title>
                    </div>
                    <div
                        className={css({
                            marginTop: '10px',
                        })}
                    >
                        {selectedElevators.map(elevator => (
                            <div
                                className={css({
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                })}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                    })}
                                >
                                    <Image src={elevatorSvg} alt="location" preview={false} />
                                    <Typography.Paragraph
                                        className={css({
                                            padding: 0,
                                            marginLeft: '10px',
                                            marginBottom: '0px !important',
                                        })}
                                    >
                                        {elevator.label}
                                    </Typography.Paragraph>
                                </div>
                                <Button type="text" onClick={() => unselectElevator(elevator.value)}>
                                    <CloseOutlined />
                                </Button>
                            </div>
                        ))}
                    </div>
                </>
            ) : ''}
            <TeamDialogFooter />
        </div>
    );
};

export default AssignElevators;
