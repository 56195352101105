import { TRuleWithTheme } from 'styles/theme';

const paginationItemRule = {
    margin: '0 .25em',
    padding: '.25em .4em',
    height: 32,
    width: 32,
};

const jumpItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: paginationItemRule.height,
    '& a': { width: '100%' },
};

const labels = colors => ({
    color: colors.textSecondary,
    fontSize: '.75rem',
});

const sorter = {
    fontSize: '.5rem',
};

export const tableWrapper: TRuleWithTheme<{ hideSort: boolean }> = ({ theme: { colors }, hideSort }) => ({
    '& .ant-row': {
        flexWrap: 'nowrap',
    },

    '& .ant-table-cell': {
        verticalAlign: 'top',
    },

    '& .ant-card-body': { padding: 0 },
    '& .ant-pro-table-search.ant-pro-table-search-query-filter': {
        paddingTop: '1.375rem',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,

        '& .ant-row': {
            flexWrap: 'wrap',
            '& .ant-col:last-of-type': {
                marginLeft: 0,

                '& .ant-form-item-control-input-content .ant-space': {
                    float: 'left',
                },
            },
            '&.ant-form-item': {
                marginBottom: '1rem',
            },
        },

        '& .ant-col': {
            '& .ant-form-item-control-input-content > .ant-input-number': {
                padding: '.25em 7px',
            },
            '& .ant-pro-form-collapse-button': {
                display: 'none',
            },
            '& input': {
                padding: '.25em 0',
            },
            '& .ant-form-item-label > label': labels(colors),
        },
    },

    '& .ant-form-item-control-input-content .ant-space-item > .ant-btn': {
        height: 'auto',
        paddingTop: '0.4375rem',
        paddingBottom: '0.4375rem',
        '&:not(.ant-btn-primary)': {
            color: colors.textSecondary,
        },
    },

    '& .ant-table.ant-table-middle .ant-table-footer': {
        padding: 0,
    },

    extend: {
        // @ts-ignore
        condition: hideSort,
        style: {
            '& .ant-table-content .ant-table-thead': {
                display: 'none',
            },
        },
    },

    desktop: {
        '& .ant-pro-table-search.ant-pro-table-search-query-filter .ant-col': {
            flexBasis: 'calc(100% / 7)',
        },
    },

    '@media (min-width: 1024px) and (max-width: 2150px)': {
        '& .ant-pro-table-search.ant-pro-table-search-query-filter .ant-row .ant-col:not(:last-of-type)': {
            flexBasis: `${100 / 6}%`,
        },
    },

    '@media (max-width: 2150px)': {
        '& .ant-pro-table-search.ant-pro-table-search-query-filter .ant-row .ant-col:last-of-type .ant-form-item-label': {
            display: 'none',
        },
    },
});

export const table: TRuleWithTheme<{ showCurrentPaginationTotal: boolean }> = ({
    theme: { colors, borderRadius },
    showCurrentPaginationTotal,
}) => ({
    '& .ant-table-thead': {
        '& .ant-table-cell': labels(colors),
    },

    '& .ant-pagination.mini .ant-pagination-item': {
        ...paginationItemRule,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& a': {
            padding: 0,
        },
    },

    '& .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active)': {
        borderColor: colors.outlineGrey,
        backgroundColor: colors.white,
    },

    '& .ant-pagination.mini .ant-pagination-prev': {
        ...paginationItemRule,

        backgroundColor: colors.white,
        border: `1px solid ${colors.outlineGrey}`,
    },

    '& .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link': {
        borderColor: colors.outlineGrey,
        backgroundColor: colors.white,
        ...paginationItemRule,
    },

    '& .ant-table-pagination.ant-pagination': {
        padding: '16px 0',
        margin: 0,
        backgroundColor: colors.bgPrimary,
    },

    '& .ant-pagination.mini .ant-pagination-jump-next': jumpItem,

    '& .ant-pagination.mini .ant-pagination-jump-prev': jumpItem,

    '& .ant-pagination-item-active': {
        backgroundColor: colors.primary,

        '& a': {
            color: colors.white,
        },
    },

    '& .ant-table-column-sorter-up': sorter,
    '& .ant-table-column-sorter-down': sorter,

    '& ant-table-tbody': {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr': {
        position: 'relative',
        '::after': {
            position: 'absolute',
            bottom: 0,
            left: 24,
            right: 24,
            content: '""',
            backgroundColor: colors.dividerColor,
            height: 1,
        },
        ':hover': {
            cursor: 'pointer',
            backgroundColor: colors.onHover,
        },
        '&:hover td:last-child .anticon': {
            color: colors.primary,
        },
        '& > td': {
            padding: '1.25rem .5rem',
            borderBottom: 'none',
        },
        '& > td:first-of-type': {
            paddingLeft: '1.5em',
        },
        '& > td:last-of-type': {
            paddingRight: '1.5em',
        },
    },
    '& .ant-table.ant-table-middle .ant-table-thead > tr': {
        '& > th': {
            padding: '.8rem .5rem',
            backgroundColor: colors.white,
            borderWidth: '2px',
        },
        '& > th:first-of-type': {
            paddingLeft: '1.2em',
        },
        '& > th:last-of-type': {
            paddingRight: '1.2em',
        },
    },
    '& .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector': {
        padding: paginationItemRule.padding,
        height: 'auto',
    },

    '& .ant-pagination.mini .ant-pagination-options': {
        marginLeft: '.25rem',
    },

    '& .ant-pagination-total-text': {
        marginRight: '1rem',
    },

    extend: {
        // @ts-ignore
        condition: !showCurrentPaginationTotal,
        style: {
            // Hide text "n of total number of records"
            '& .ant-pagination-total-text': {
                display: 'none',
            },
        },
    },
});
