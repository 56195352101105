import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './ButtonCheckbox.styles';

interface ButtonCheckboxProps extends CheckboxProps {
    count?: number;
    extend?: RulesExtend<typeof felaRules>;
}

const ButtonCheckbox: FC<ButtonCheckboxProps> = ({ children, count, extend, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <Checkbox className={styles.checkbox} {...rest}>
            {children}
            {Number.isInteger(count) ? <span className={styles.count}>({count})</span> : null}
        </Checkbox>
    );
};

export default ButtonCheckbox;
