import React, { FunctionComponent } from 'react';

const Logout: FunctionComponent = () => (
    <svg width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M21.707 11.293l-5-5-1.414 1.414L18.586 11H8v2h10.586l-3.293 3.293 1.414 1.414 5-5a.999.999 0 000-1.414" />
            <path d="M12 20H4V4h8v2h2V3a1 1 0 00-1-1H3a1 1 0 00-1 1v18a1 1 0 001 1h10a1 1 0 001-1v-3h-2v2z" />
        </g>
    </svg>
);

export default Logout;
