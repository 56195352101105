import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';
import { NewElevatorButton } from 'modules/elevator-new';

import * as felaRules from './EmptyWorkordersMap.rules';

export const EmptyWorkordersMap = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <Icon type={IconType.NO_ELEVATORS} className={styles.icon} />
            <Typography.Title level={2} className={styles.title}>
                <FormattedMessage id="workorders.map.empty.title" />
            </Typography.Title>
            <Typography.Paragraph className={styles.paragraph}>
                <FormattedMessage id="elevators.map.empty.info" />
            </Typography.Paragraph>
            <NewElevatorButton avoidFlexBasis />
        </div>
    );
};
