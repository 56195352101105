import React from 'react';
import { Select } from 'antd';
import { Elevator } from 'modules/entities/modules/elevators';
import * as felaRules from './NewIncidentSearchInput.rules';
import { useFelaEnhanced } from 'hooks';
import { Icon, IconType } from 'modules/ui';
import { FormattedMessage } from 'react-intl';
import { NewIncidentSearchInputOption } from '../NewIncidentSearchOption';

const { Option } = Select;

export interface NewIncidentSearchInputProps {
    elevators: Elevator[];
    onNotFound: (e: any) => void;
    onChange: (value: any) => void;
    elevator: Elevator;
    setContractLength: (value: boolean) => void;
    value?: string;
}

export const NewIncidentSearchInput = ({
    elevators,
    onNotFound,
    elevator,
    value,
    onChange,
    setContractLength,
    ...rest
}: NewIncidentSearchInputProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const handleOnChange = (value, option) => {
        const elevator: Elevator = option?.children?.props?.item;
        setContractLength(elevator?.maintenanceContractPresent);

        onChange(value);
    };

    const handleFilterOption = (input, option) => {
        const elevator: Elevator = option.children.props.item;
        const query = input.toLowerCase();
        return (
            elevator?.factoryNumber?.toString().toLowerCase().includes(query) ||
            elevator?.internalCustomerName?.toString().toLowerCase().includes(query) ||
            elevator?.id?.toString().toLowerCase().includes(query) ||
            elevator?.propertyUnit?.city?.toString().toLowerCase().includes(query) ||
            elevator?.propertyUnit?.street?.toString().toLowerCase().includes(query) ||
            elevator?.propertyUnit?.businessUnit?.toString().toLowerCase().includes(query)
        );
    };

    if (elevator) {
        return (
            <div className={styles.containerStatic}>
                <NewIncidentSearchInputOption item={elevator} selectedElevatorId={value} />
            </div>
        );
    }

    return (
        <Select
            className={value ? styles.container : styles.containerSmall}
            allowClear
            showArrow={false}
            showSearch
            size={value ? 'large' : 'middle'}
            notFoundContent={
                <div>
                    <FormattedMessage
                        id="incident.new.search.notfound"
                        values={{
                            link: (chunks: any) => (
                                <a href="#close" className={styles.close} onClick={onNotFound}>
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </div>
            }
            placeholder={
                <div style={{ display: 'flex', alignItems: 'center', height: value ? 56 : 48 }}>
                    <FormattedMessage id="incident.new.dialog.selection.placeholder" />
                </div>
            }
            clearIcon={<Icon type={IconType.CLOSE_BOLD} />}
            optionFilterProp="children"
            filterOption={handleFilterOption}
            {...rest}
            onChange={handleOnChange}
        >
            {elevators.map(item => (
                <Option key={item.id} value={item.id} label={item.factoryNumber}>
                    <NewIncidentSearchInputOption item={item} selectedElevatorId={value} />
                </Option>
            ))}
        </Select>
    );
};
