import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,

    padding: '1.5rem',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
});

export const row: TRuleWithTheme = ({ theme }) => ({
    fontWeight: 'normal',

    '@media (min-width: 1600px) and (max-width: 2500px)': {
        '& .ant-col-xxl-3': {
            flexBasis: '14.287%',
            maxWidth: '14.28%',
        },
    },
});

export const resetButton: TRuleWithTheme = ({ theme }) => ({
    marginRight: '.75rem',
    color: theme.colors.textSecondary,
});

export const data: TRuleWithTheme = ({ theme }) => ({
    marginTop: '0.5rem',
});


export const buttonCol: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '100%',
});

export const buttonContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
});
