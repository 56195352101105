import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: '18px',
    fontSize: '0.87rem',
    fontWeight: 'bold',
    paddingTop: '0.68rem',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    width: '0.9em',
    height: '0.69em',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.53rem',
    color: '#03be19',
    fontSize: '1.5rem',
});
export const description: TRuleWithTheme = ({ theme }) => ({
    color: '#8F8F8F',
    fontSize: '0.7rem',
    lineHeight: '1rem',
    marginBottom: '0.63rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
    
});

export const ball: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: '#EBEBEB',
    height: '0.25rem',
    width: '0.25rem',
    borderRadius: '0.25rem',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
});
