import React from 'react';

const ArrowUp = props => {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-2.8785e-07 6.74938L6.293 0.502103C6.684 0.113882 7.316 0.113882 7.707 0.502103L14 6.74938L12.587 8.15433L7 2.60803L1.414 8.15333L-2.8785e-07 6.74938Z"
            />
        </svg>
    );
};

export default ArrowUp;
