import { combineRules } from 'fela';

import {
    description,
    navigation as navigationBase,
} from '../NewElevatorDialogUploadSelection/NewElevatorDialogUploadSelection.rules';

export { description };

export const navigation = combineRules(navigationBase, () => ({
    gridTemplateColumns: 'repeat(2, 1fr)',
}));
