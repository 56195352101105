import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import * as felaRules from './ActiveIncidentsButton.rules'
import { useFelaEnhanced } from 'hooks';
import { DialogState } from 'constants/common';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

export const ActiveIncidentsButton = ({size}) => {
    const { styles } = useFelaEnhanced({ ...felaRules });
    const { navigate } = useContext(GlobalDialogContext);

    return (
        <Button
            type="primary"
            className={styles.reportIncident}
            size={size}
            onClick={() => {
                navigate(DialogState.CREATE_INCIDENT, null);
            }}
        >
            <FormattedMessage id="incident.new.trigger" />
        </Button>
    );
};
