import React, { FC, ReactNode } from 'react';

import type { RulesExtend } from 'styles/theme';

import { useFelaEnhanced } from 'hooks';

import { Icon } from 'modules/ui';
import type { IconProps } from 'modules/ui';

import * as felaRules from './OptionRenderer.styles';

export type OptionRendererProps = {
    icon?: IconProps['type'];
    text?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
};

export const OptionRenderer: FC<OptionRendererProps> = ({ text, icon, extend }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            {text}
            <Icon type={icon} className={styles.icon} />
        </div>
    );
};
