import { ApiAction } from '../../../../../types';
import { Workorder } from '../../../types';
import { fetchWorkorderTypes } from '../../actions';

const initialState: Workorder[]= []

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchWorkorderTypes.FETCH_WORKORDERS_SUCCESS: {
            return action.payload;
        }

        case fetchWorkorderTypes.FETCH_WORKORDERS_RESET:
            return initialState;

        default:
            return state;
    }
};