import loglevel from 'loglevel';

import { captureException } from 'config/sentry';
import { isEnvProduction } from 'constants/index';

isEnvProduction ? loglevel.disableAll() : loglevel.enableAll();

export const { warn, info, trace, debug } = loglevel;

export const error = (err: any) => {
    loglevel.error(err);
    if (!window.Cypress) {
        captureException(err);
    }
};
