import felaPluginValidator from 'fela-plugin-validator';
import felaPluginUnit from 'fela-plugin-unit';
import felaPluginNamedKeys from 'fela-plugin-named-keys';
import felaPluginFriendlyPseudoClass from 'fela-plugin-friendly-pseudo-class';
import felaPluginExtend from 'fela-plugin-extend';
import felaPluginEmbedded from 'fela-plugin-embedded';
import felaPluginPlaceholderPrefixer from 'fela-plugin-placeholder-prefixer';
import felaPluginPrefixer from 'fela-plugin-prefixer';
import felaPluginFallbackValue from 'fela-plugin-fallback-value';
import felaPluginExpandShorthand from 'fela-plugin-expand-shorthand';

import namedKeys from './namedKeys';

const defaultUnit = 'px';

const plugins = [
    felaPluginExtend(),
    felaPluginEmbedded(),
    felaPluginPlaceholderPrefixer(),
    felaPluginPrefixer(),
    felaPluginFallbackValue(),
    felaPluginUnit(defaultUnit),
    felaPluginFriendlyPseudoClass(),
    felaPluginNamedKeys(namedKeys),
    felaPluginExpandShorthand(true),

    // felaPluginValidator should be the last plugin
    process.env.NODE_ENV === 'development' &&
        felaPluginValidator({
            logInvalid: true,
            deleteInvalid: false,
            useCSSLint: false,
        }),
].filter(Boolean);

export default plugins;
