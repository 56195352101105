import { InvoiceState } from 'modules/entities/modules/documents-invoices/types';
import { ContractState } from 'modules/entities/modules/documents-contracts/types';
import { OtherDocumentState } from 'modules/entities/modules/other-documents';
import { TRuleWithTheme } from 'styles/theme';
import { Tables } from '../../types';
import { StatusIconSize } from './types';

const colorMap = theme => ({
    [Tables.INVOICES]: {
        [InvoiceState.NEW]: theme.colors.statusNew,
        [InvoiceState.CORRECT]: theme.colors.statusSuccess,
        [InvoiceState.IN_CLARIFICATION]: theme.colors.statusInClarification,
        [InvoiceState.WRONG]: theme.colors.statusHighAlert,
        [InvoiceState.ONGOING]: theme.colors.statusOngoing,
        [InvoiceState.NOT_CHECKED]: theme.colors.statusNotChecked,
        [InvoiceState.SHOW_ALL]: theme.colors.statusInfo,
    },
    [Tables.CONTRACTS]: {
        [ContractState.CANCELATION_5_MONTHS]: theme.colors.statusOngoing,
        [ContractState.CANCELED]: theme.colors.statusNew,
        [ContractState.ACTIVE]: theme.colors.statusSuccess,
        [ContractState.INACTIVE]: theme.colors.statusInactive,
    },
    [Tables.OTHER_DOCUMENTS]: {
        [OtherDocumentState.ONGOING]: theme.colors.statusOngoing,
        [OtherDocumentState.NEW]: theme.colors.statusNew,
        [OtherDocumentState.DONE]: theme.colors.statusSuccess,
    },
});

export const icon: TRuleWithTheme<{
    state?: InvoiceState | ContractState | OtherDocumentState;
    table: Tables;
    size: StatusIconSize;
}> = ({ theme, table, state, size }) => ({
    color: colorMap(theme)[table][state] ?? theme.colors.icon,
    extend: {
        condition: size === StatusIconSize.SMALL,
        style: {
            marginRight: '.5rem',
            fontSize: '1rem',
            '& svg': {
                height: '.875rem',
                width: '.875rem',
            },
        },
    },
});
