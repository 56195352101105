import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useNotifications } from 'modules/ui';
import { useUploadDocument } from 'modules/entities/modules/documents';

export const useDocumentUpload = (uid?: string) => {
    const notifications = useNotifications();

    const notificationsRef = useRef(notifications);

    const { api, uploadDocument } = useUploadDocument(uid);

    useEffect(() => {
        if (api.success) {
            notificationsRef.current.open({
                type: 'success',
                message: <FormattedMessage id="documents.upload.success" />,
                description: <FormattedMessage id="documents.upload.success.desc" />,
                duration: 4,
            });
        }
    }, [api.success]);

    useEffect(() => {
        if (api.error) {
            notificationsRef.current.open({
                type: 'error',
                message: <FormattedMessage id="documents.upload.error" />,
                description: <FormattedMessage id="documents.upload.error.desc" />,
                duration: 4,
            });
        }
    }, [api.error]);

    return {
        api,
        uploadDocument,
    };
};
