import type { TRule } from 'fela';

export const changeRole: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});

export const changeRoleHeading: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const title: TRule = () => ({
    marginBottom: '0 !important',
    padding: 0,
    fontSize: '24px',
    marginLeft: '0.6rem',
});

export const text: TRule = () => ({
    color: '#8F8F8F',
    fontSize: '14px',

    '> strong': {
        color: '#262626',
    },
});
