import { get } from 'lodash';
import { createSelector } from 'reselect';
import { apiSelector, ApiReducerState } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey, SortingType } from 'constants/index';
import type { Search, Sorting } from 'constants/index';
import type { ListReducerState } from '../../../../types';
import { Incident, IncidentSearchFields, Picklist } from '../../types';
import { compareValueByType } from 'services/utils';
import { stringComparatorFactory } from 'modules/table';

export const selectIncidentsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.INCIDENTS, ApiReducerKey.LIST);

export const selectActiveIncidentsEntityList = (state): ListReducerState<Incident> =>
    state.entities[EntityKey.INCIDENTS][ApiReducerKey.ACTIVE];

export const selectResolvedIncidentsEntityList = (state): ListReducerState<Incident> =>
    state.entities[EntityKey.INCIDENTS][ApiReducerKey.RESOLVED];
export const selectUnvalidatedIncidentsEntityList = (state): ListReducerState<Incident> =>
    state.entities[EntityKey.INCIDENTS][ApiReducerKey.UNVALIDATED];

export const selectActiveIncidents = createSelector(selectActiveIncidentsEntityList, ({ byIds, ids }): Incident[] =>
    ids.map(id => byIds[id]),
);

export const selectResolvedIncidents = createSelector(selectResolvedIncidentsEntityList, ({ byIds, ids }): Incident[] =>
    ids.map(id => byIds[id]),
);
export const selectPicklist = (state): Picklist => state.entities[EntityKey.INCIDENTS][ApiReducerKey.PICKLIST];
export const selectUnvalidatedIncidents = createSelector(
    selectUnvalidatedIncidentsEntityList,
    ({ byIds, ids }): Incident[] => ids.map(id => byIds[id]),
);

export const selectFilteredIncidents = createSelector(
    [
        selectActiveIncidents,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (elevators, search) => {
        return elevators.filter(elevator =>
            search.every(({ field, query }) => {
                if (query === 'Unknown' && get(elevator, field) === undefined) {
                    return elevator;
                } else {
                    return compareValueByType({
                        value: get(elevator, field),
                        query,
                        isMultiple:
                            [IncidentSearchFields.CASE_STATUS].includes(field) ||
                            [IncidentSearchFields.CASE_SEVERITY].includes(field) ||
                            [IncidentSearchFields.CASE_ORIGIN].includes(field) ||
                            [IncidentSearchFields.CASE_PHASE].includes(field) ||
                            [IncidentSearchFields.CASE_SUBSTATUS].includes(field),
                    });
                }
            }),
        );
    },
);

export const selectFilteredActiveIncidentsBySearch = createSelector(
    [
        selectActiveIncidents,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (incidents, search) => {
        return incidents.filter(incident =>
            search.every(({ field, query }) => {
                const value = get(incident, field);
                switch (field) {
                    case IncidentSearchFields.CASE_STATUS:
                    case IncidentSearchFields.CASE_SEVERITY:
                    case IncidentSearchFields.CASE_ORIGIN:
                    case IncidentSearchFields.CASE_PHASE:
                    case IncidentSearchFields.CASE_SUBSTATUS:
                        return compareValueByType({ value, query, isMultiple: true });
                    default:
                        return query === 'Unknown' ? value === undefined : compareValueByType({ value, query });
                }
            }),
        );
    },
);

export const selectFilteredResolvedIncidentsBySearch = createSelector(
    [
        selectResolvedIncidents,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (incidents, search) => {
        return incidents.filter(incident =>
            search.every(({ field, query }) => {
                const value = get(incident, field);
                switch (field) {
                    case IncidentSearchFields.CASE_STATUS:
                    case IncidentSearchFields.CASE_SEVERITY:
                    case IncidentSearchFields.CASE_ORIGIN:
                    case IncidentSearchFields.CASE_PHASE:
                    case IncidentSearchFields.CASE_SUBSTATUS:
                        return compareValueByType({ value, query, isMultiple: true });
                    default:
                        return query === 'Unknown' ? value === undefined : compareValueByType({ value, query });
                }
            }),
        );
    },
);
export const selectFilteredUnvalidatedIncidentsBySearch = createSelector(
    [
        selectUnvalidatedIncidents,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (incidents, search) => {
        return incidents.filter(incident =>
            search.every(({ field, query }) => {
                const value = get(incident, field);
                switch (field) {
                    case IncidentSearchFields.CASE_STATUS:
                    case IncidentSearchFields.CASE_SEVERITY:
                    case IncidentSearchFields.CASE_ORIGIN:
                    case IncidentSearchFields.CASE_PHASE:
                    case IncidentSearchFields.CASE_SUBSTATUS:
                        return compareValueByType({ value, query, isMultiple: true });
                    default:
                        return query === 'Unknown' ? value === undefined : compareValueByType({ value, query });
                }
            }),
        );
    },
);
export const selectUnFilteredUnvalidatedIncidentsBySearch = createSelector(
    [
        selectUnvalidatedIncidents,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (incidents, search) => {
        return incidents.filter(incident =>
            search.every(({ field, query }) => {
                return true;
            }),
        );
    },
);

export const selectSortedActiveIncidents = createSelector(
    [
        selectFilteredIncidents,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectSortedUnvalidatedIncidents = createSelector(
    [
        selectFilteredUnvalidatedIncidentsBySearch,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectUnfilteredUnvalidatedIncidents = createSelector(
    [
        selectUnFilteredUnvalidatedIncidentsBySearch,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectSortedRejectedIncidents = createSelector(
    [
        selectFilteredResolvedIncidentsBySearch,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectSortedResolvedIncidents = createSelector(
    [
        selectFilteredResolvedIncidentsBySearch,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectIncidentsCount = createSelector([selectActiveIncidents], incidents => incidents.length);

export const selectUpdateIncidentsApi = (state): Record<string, ApiReducerState> =>
    apiSelector(state, EntityKey.INCIDENTS, ApiReducerKey.UPDATE);

export const selectUpdateIncidentApi = createSelector(
    [selectUpdateIncidentsApi, (_, { caseNumber }: { caseNumber: string }) => caseNumber],
    (apis, id) => apis[id] ?? apis.caseNumber,
);
export const selectIncidentSeverities = createSelector(selectActiveIncidents, incidents => {
    let incidentSeverities = incidents.map(incident => incident.case.severity);
    const uniqueIncidentSeverities = new Set(incidentSeverities);
    return Array.from(uniqueIncidentSeverities)
        .map(severity =>
            severity
                ? { value: severity, label: severity }
                : { value: 'Unknown', label: navigator.language === 'en' ? 'Unknown' : 'Unbekannt' },
        )
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});

export const selectIncidentOrigins = createSelector(selectActiveIncidents, incidents => {
    let incidentOrigins = incidents.map(incident => (incident.case.origin ? incident.case.origin : 'Unknown'));
    const uniqueIncidentOrigins = new Set(incidentOrigins);
    return Array.from(uniqueIncidentOrigins)
        .map(origin =>
            origin
                ? { value: origin, label: origin }
                : { value: 'Unknown', label: navigator.language === 'en' ? 'Unknown' : 'Unbekannt' },
        )
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});
export const selectIncidentPhases = createSelector(selectActiveIncidents, incidents => {
    let incidentPhases = incidents.map(incident => (incident.case.status ? incident.case.status : 'Unknown'));
    const uniqueIncidentPhases = new Set(incidentPhases);
    return Array.from(uniqueIncidentPhases)
        .map(phase =>
            phase
                ? { value: phase, label: phase }
                : { value: 'Unknown', label: navigator.language === 'en' ? 'Unknown' : 'Unbekannt' },
        )
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});

export const selectIncidentStatuses = createSelector(selectActiveIncidents, incidents => {
    let incidentStatuses = incidents.map(incident => (incident.case.subStatus ? incident.case.subStatus : 'Unknown'));
    const uniqueIncidentStatuses = new Set(incidentStatuses);
    return Array.from(uniqueIncidentStatuses)
        .map(status =>
            status
                ? { value: status, label: status }
                : { value: 'Unknown', label: navigator.language === 'en' ? 'Unknown' : 'Unbekannt' },
        )
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});
