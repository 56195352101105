import { basicApiReducer } from '@ackee/redux-utils';

import { fetchTotalTripsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_REQUEST,
        SUCCESS: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_SUCCESS,
        FAILURE: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_FAILURE,
        CANCEL: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_CANCEL,
        RESET: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_RESET,
    },
});
