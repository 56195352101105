import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';

import { selectDownloadDocumentsApi } from '../services/selectors';

const useDisplayDownloadError = () => {
    const api: ApiReducerState = useSelector(selectDownloadDocumentsApi);

    useDisplayError(api.error);
};

export default useDisplayDownloadError;
