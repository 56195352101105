import React from 'react';

const ChevronRightIcon = () => (
    <svg width="8" height="14" viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="lists/elevator_list" transform="translate(-1385.000000, -25.000000)" fill="currentColor">
                <g id="Fill-1" transform="translate(1380.000000, 24.000000)">
                    <path d="M6.4147,15 L12.7067,8.707 C13.0977,8.316 13.0977,7.684 12.7067,7.293 L6.4147,1 L4.9997,2.413 L10.5857,8 L5.0007,13.586 L6.4147,15 Z"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default ChevronRightIcon;
