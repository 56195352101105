import { put, takeEvery } from 'redux-saga/effects';
import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import { deactivateActivateUserRequest, deactivateActivateUserRequestSuccess, deactivateActivateUserRequestFailure } from '../actions';

export function* deactivateActivateUserRequestHandler(action: ReturnType<typeof deactivateActivateUserRequest>) {
    try {
        yield* authApi.put(`${config.api.users}/${action.meta.email}/status`, action.payload);

        yield put(deactivateActivateUserRequestSuccess());
    } catch (error) {
        log.error(error);

        yield put(deactivateActivateUserRequestFailure(error));
    }
}

export function* sendDeactivateActivateUserRequest() {
    yield takeEvery(deactivateActivateUserRequest.toString(), deactivateActivateUserRequestHandler);
}
