import type { TRule } from 'fela';

export const cta: TRule = () => ({
    // marginBottom: '1rem',
});
export const reportContainer: TRule = () => ({
    marginTop: '1rem',
});
export const ctaCount: TRule = () => ({
    color: '#8F8F8F',
});
export const ctaIcon: TRule = () => ({
    color: '#E84E1B',
    marginLeft: '.37rem',

    '& svg': {
        height: '0.75rem',
        width: '0.75rem',
    },
});

export const container: TRule = () => ({
    marginTop: '1.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
});
