import { useEffect } from 'react';

import { useUIMessage } from 'modules/ui';
import { errorMessages, HTTPStatusCode } from 'modules/errors';
import { useElevator, useFetchElevator } from 'modules/entities/modules/elevators';

const useElevatorDetail = () => {
    const message = useUIMessage();

    const api = useFetchElevator();
    const elevator = useElevator();

    useEffect(() => {
        if (api.error && api.error !== errorMessages.status[HTTPStatusCode.NotFound]) {
            message('error', api.error, 5);
        }
    }, [api.error, message]);

    return { elevator, ...api };
};

export default useElevatorDetail;
