import { createContext } from 'react';

export enum TeamDialogState {
    INVITE_USER = 'invite-user',
    CHANGE_ROLE = 'change-role',
    DEACTIVATE_USER = 'deactivate-user',
    NOTIFICATIONS = 'notifications',
    MANAGE_ELEVATOR = 'manage-elevator',
    ASSIGN_ELEVATORS = 'assign-elevators'
}

export const TeamDialogContext = createContext<{
    state: TeamDialogState;
    setState: (state: TeamDialogState) => void;
    isDisabled: boolean;
    setIsDisabled: (isDisabled: boolean) => void
}>(null);
