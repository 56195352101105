import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserOutlined } from '@ant-design/icons';
import { useFelaEnhanced } from 'hooks';
import { Icon, IconType } from 'modules/ui';

import * as felaRules from './ServiceProvider.styles';
import { Incident } from 'modules/entities/modules/incidents';

interface Props {
    serviceProviderInfo: Incident['case']['serviceProviderInfo'];
    serviceLevelAgreement: Incident['case']['serviceLevelAgreement'];
}

const ServiceProvider: FC<Props> = ({ serviceProviderInfo, serviceLevelAgreement }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.flag}>
            <div className={styles.flagCol}>
                <span className={styles.title}>
                    <FormattedMessage id="page.incidents.company" />
                </span>
                {!!serviceProviderInfo?.serviceProviderName && (
                    <div className={styles.flagValue}>
                        <UserOutlined className={styles.flagIcon} />
                        <span className={styles.company}>{serviceProviderInfo?.serviceProviderName}</span>
                    </div>
                )}
                {!!serviceProviderInfo?.malfunctionHotline && (
                    <div className={styles.flagValue}>
                        <Icon type={IconType.PHONE} className={styles.flagIcon} />
                        <a className={styles.link} href={`tel:${serviceProviderInfo?.malfunctionHotline}`}><span>{serviceProviderInfo?.malfunctionHotline}</span></a>
                    </div>
                )}
                {!!serviceProviderInfo?.malfunctionEmail && (
                    <div className={styles.flagValue}>
                        <Icon type={IconType.EMAIL} className={styles.flagIcon} />
                        <a className={styles.link} href={`mailto:${serviceProviderInfo?.malfunctionEmail}`}><span>{serviceProviderInfo?.malfunctionEmail}</span></a>
                    </div>
                )}

                {!serviceProviderInfo?.serviceProviderName &&
                    !serviceProviderInfo?.malfunctionHotline &&
                    !serviceProviderInfo?.malfunctionEmail && <FormattedMessage id="incident.unknown" />}
            </div>
            <div className={styles.flagColRight}>
                <span className={styles.title}>
                    <FormattedMessage id="page.incidents.reactionTime" />
                </span>
                {!!serviceLevelAgreement?.reactionTime ? (
                    <div className={styles.flagValue}>
                        {/* <Icon type={IconType.CLOCK} className={styles.flagIcon} /> */}
                        <span>{serviceLevelAgreement?.reactionTime} Std.</span>
                    </div>
                ) : (
                    <div className={styles.flagValue}>
                        <span>
                            <FormattedMessage id="incident.unknown" />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceProvider;
