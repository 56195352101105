import { TRuleWithTheme } from 'styles/theme';

export const icon: TRuleWithTheme = ({ theme }) => ({
    '&.anticon': {
        color: theme.colors.icon,
        position: 'relative',
        zIndex: 10,

        '& svg': {
            width: 24,
            height: 24,
        },
    },
});

export const iconWrapper: TRuleWithTheme = ({ theme }) => ({
    // width: 24,
    // height: 24,
    // marginLeft: '.5rem',
    // position: 'relative',

    // '& > .ant-popover-open::after': {
    //     position: 'absolute',
    //     content: '""',
    //     left: -2,
    //     right: -2,
    //     top: -2,
    //     bottom: -2,
    //     backgroundColor: theme.colors.onHover,
    //     borderRadius: '50%',
    //     zIndex: 0,
    // },
});
