import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        fontSize: '1.5rem',
        lineHeight: 1.33,

        margin: 0,
    },
});

export const button: TRuleWithTheme = () => ({
    flexShrink: 0,

    padding: 0,

    marginRight: '1rem',

    width: '1.5rem',
    height: '1.5rem',

    border: 'none',
    boxShadow: 'none',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.iconActive,
});

export const content: TRuleWithTheme<{
    goBack: boolean;
}> = ({ goBack }) => ({
    marginLeft: goBack ? '2.5rem' : 0,
});
