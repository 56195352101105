import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.MAINTENANCES,
});

export const fetchMaintenancesTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_MAINTENANCES_' }),
});

