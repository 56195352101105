export enum AvailableDocumentTabs {
    INVOICES = 'invoices',
    CONTRACTS = 'contracts',
    EMERGENCY_PLANS = 'emergency-plans',
    RISK_ASSESSMENTS = 'risk-assessments',
    CONDITION_REPORTS = 'condition-reports',
    QUOTATIONS = 'quotations',
    ASSESSMENT_REPORTS = 'assessment-reports',
    OTHER_DOCUMENT = 'other-documents',
}
