import React, { FC, ReactNode, useCallback, useState } from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './FloatingLabel.rules';

export interface FloatingLabelProps {
    label: string | ReactNode;
    floating: boolean;
    htmlFor: string;
    children: (handlers: { onFocus: () => void; onBlur: () => void }) => ReactNode;
    fontSize?: string;
}

export const FloatingLabel: FC<FloatingLabelProps> = ({ label, htmlFor, floating, children, fontSize }) => {
    const [isFocused, setFocus] = useState(false);

    const { styles } = useFelaEnhanced(felaRules, { isFloating: isFocused || floating, fontSize });

    const handleFocus = useCallback(() => {
        setFocus(true);
    }, []);

    const handleBlur = useCallback(() => {
        setFocus(false);
    }, []);

    return (
        <div className={styles.container}>
            <label htmlFor={htmlFor} className={styles.label}>
                {label}
            </label>
            {children({
                onFocus: handleFocus,
                onBlur: handleBlur,
            })}
        </div>
    );
};
