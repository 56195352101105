import { Incident } from './../../../../incidents/types';
import type { ApiAction } from '../../../../../types';

import { fetchClosedIncidentsTypes } from '../../actions';

const initialState: Incident[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_SUCCESS:
            return action.payload;
        case fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_RESET:
            return initialState;
        default:
            return state;
    }
};
