import type { RequestResult } from '@ackee/antonio-core';
import { put } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as Log from 'config/loglevel';

import { actions as formActions, takeFormSubmission } from 'modules/form';
import { createUIErrorMessage } from 'modules/errors';

import type { RegisterFormValues } from '../../types';
import { RegistrationStatus } from '../../types';
import { forms } from '../../config';

import { updateRegistrationStatus } from '../actions';

interface ResponseData {
    externalUsers: {
        status: RegistrationStatus;
    }[];
}

function* handleRegisterForm(action: formActions.types.SubmitFormAction<RegisterFormValues>) {
    const { email } = action.payload;

    try {
        const response: RequestResult<ResponseData> = yield* authApi.post(config.api.salesforceRegister, {
            externalUsers: [
                {
                    email,
                },
            ],
        });

        const registrationStatus = response.data.externalUsers[0].status.toLowerCase();

        if (registrationStatus !== RegistrationStatus.CREATED && registrationStatus !== RegistrationStatus.SUBMITTED) {
            throw new Error('Registration failed');
        }

        yield put(updateRegistrationStatus(registrationStatus));
        yield put(formActions.submitFormSuccess(forms.register));
    } catch (error) {
        Log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(formActions.submitFormFailure(forms.register, uiError));
    }
}

export default function* registerForm() {
    yield takeFormSubmission(forms.register, handleRegisterForm);
}
