import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';
import { NewIncidentFormValues } from 'modules/incident-new/types';

export const updateIncident = createAction(
    `${EntityKey.INCIDENTS}/UPDATE_INCIDENT`,
    ({ payload, caseNumber }: { payload: NewIncidentFormValues; caseNumber: string }) => ({
        payload: payload,
        meta: {
            caseNumber,
        },
    }),
);

export const updateIncidentRequest = createAction(`${EntityKey.INCIDENTS}/UPDATE_INCIDENT_REQUEST`, caseNumber => ({
    payload: null,
    meta: {
        caseNumber,
    },
}));

export const updateIncidentSuccess = createAction(
    `${EntityKey.INCIDENTS}/UPDATE_INCIDENT_SUCCESS`,
    (caseNumber: string, lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            caseNumber,
            lastSuccessAt,
        },
    }),
);

export const updateIncidentFailure = createAction(
    `${EntityKey.INCIDENTS}/UPDATE_INCIDENT_FAILURE`,
    (error: any, caseNumber: string) => ({
        payload: null,
        error,
        meta: {
            caseNumber,
        },
    }),
);

export const updateIncidentReset = createAction(
    `${EntityKey.INCIDENTS}/UPDATE_INCIDENT_RESET`,
    (caseNumber: string) => ({
        payload: null,
        meta: {
            caseNumber,
        },
    }),
);
