import { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    paddingBottom: '1rem',

    '& .ant-space-item': {
        marginBottom: 0,
    },

    tablet: {
        paddingBottom: 0,
    },
});

export const name: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    fontSize: '.75rem',
    display: 'table-cell',
});

export const data: TRule<{ isDataBold?: boolean }> = ({ isDataBold }) => {
    let fontWeight;
    switch (isDataBold) {
        case false:
            fontWeight = 500;
            break;
        default:
            fontWeight = 600;
    }
    return { fontWeight, margin: 0 };
};
