import tokenPaginationApiReducer from '../../../../../services/reducers/tokenPaginationApi';
import { fetchElevatorStatesTypes } from '../../actions';

export default tokenPaginationApiReducer({
    actionTypes: {
        REQUEST: fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_REQUEST,
        SUCCESS: fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_SUCCESS,
        FAILURE: fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_FAILURE,
        CANCEL: fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_CANCEL,
        RESET: fetchElevatorStatesTypes.FETCH_ELEVATOR_STATES_RESET,
    },
});
