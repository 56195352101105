import React, { useMemo, useState } from 'react';

import { useMapElevators, ElevatorField } from 'modules/entities/modules/elevators';

import { ElevatorMapStatusFilter } from '../ElevatorMapStatusFilter';
import { ElevatorsMapWrapper } from '../ElevatorsMapWrapper';
import { Map } from '../Map';

export const ElevatorsMap = () => {
    const [filter, setFilter] = useState<string | null>(null);

    const search = useMemo(() => (filter ? [{ field: ElevatorField.STATUS, query: filter }] : []), [filter]);

    const { elevatorGroups, loading, empty } = useMapElevators(search);

    return (
        <ElevatorsMapWrapper loading={loading} empty={empty}>
            {loading || empty ? null : <ElevatorMapStatusFilter value={filter} onChange={setFilter} />}
            <Map elevatorGroups={elevatorGroups} />
        </ElevatorsMapWrapper>
    );
};
