import type { ApiAction } from '../../../../../types';

import { fetchIotConfigTypes } from '../../actions';

const initialState = [];

export default (state = initialState, action: ApiAction) => { 
    switch (action.type) {      
        case fetchIotConfigTypes.FETCH_IOT_CONFIG_SUCCESS:
            return action.payload;
        case fetchIotConfigTypes.FETCH_IOT_CONFIG_RESET:
            return initialState;
        default:
            return state;
    }
};