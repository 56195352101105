import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'antd';

import { SeoHead } from 'modules/seo';

import { HomepageElevators } from '../HomepageElevators';
import { HomepageElevatorsMap } from '../HomepageElevatorsMap';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './HomePage.rules';
import { useAirtimeScript } from 'modules/analytics';
import config from 'config/config';
import { useBrowserComptabilityScript } from 'modules/analytics/hooks/useBrowserCompatabilityScript';
import useHotjarScript from 'modules/analytics/hooks/useHotjarScript';
import IncidentsWidget from '../IncidentsWidget/IncidentsWidget';

export const HomePage = () => {
    const intl = useIntl();
    const { styles } = useFelaEnhanced(felaRules);
    useAirtimeScript(config.airtime.url, config.airtime.token);
    useBrowserComptabilityScript();
    useHotjarScript();
    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'page.home.title' })} />
            <Row gutter={[24, 24]}>
                <Col span={24} md={15}>
                    <HomepageElevatorsMap />
                </Col>
                <Col span={24} md={9}>
                    <HomepageElevators />

                    <div className={styles.divider}>
                        <IncidentsWidget />
                    </div>
                </Col>
            </Row>
        </div>
    );
};
