import { useEffect } from 'react';
import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import { fetchFloorActivity as actions } from '../services/actions';
import { selectFloorActivityApi } from '../services/selectors';
import type { Elevator } from '../../elevators';

const useFetchFloorActivity = (startTime: string, endTime: string): ApiReducerState => {
    const { id } = useParams<{ id: Elevator['id'] }>();
    const api: ApiReducerState = useSelector(selectFloorActivityApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchFloorActivityRequest(id, { startTime, endTime }));

        return () => {
            batch(() => {
                dispatch(actions.fetchFloorActivityCancel(id));
                dispatch(actions.fetchFloorActivityReset(id));
            });
        };
    }, [dispatch, id, startTime, endTime]);

    return api;
};

export default useFetchFloorActivity;
