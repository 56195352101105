import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import { useIncidents } from 'modules/entities/modules/incidents';

import * as felaRules from './ScheduleRoute.rules';
import RouteList from 'modules/workorders-routing/components/RouteList';

const ScheduleRoute = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const { activeIncidents, loading, error, unvalidatedIncidents } = useIncidents();

    return (
        <>
            <div className={styles.container}>
                <span className={styles.portfolio}>
                    <FormattedMessage
                        id="page.workorders.routes"
                        values={{
                            count: (
                                <span className={styles.count}>
                                    ({loading ? '?' : activeIncidents.length + unvalidatedIncidents.length})
                                </span>
                            ),
                        }}
                    />
                </span>
            </div>
            <RouteList
                error={error}
                activeIncidents={activeIncidents}
                loading={loading}
                unvalidatedIncidents={unvalidatedIncidents}
            />
        </>
    );
};

export default ScheduleRoute;
