import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch, useTableSearch, TableSearchSelect, TableSearchDateRange } from 'modules/table';
import {
    useQuotationReasonOptions,
    useQuotationResultOptions,
    useQuotationTypeOptions,
    QuotationField,
} from 'modules/entities/modules/documents-quotations';

export interface QuotationsSearchProps {
    defaultValue?: SearchValue;
    isElevatorDetail: boolean;
}

const defaultSearch = {};

export const QuotationsSearch = ({ defaultValue = defaultSearch, isElevatorDetail }: QuotationsSearchProps) => {
    const intl = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const quotationTypeOptions = useQuotationTypeOptions();
    const quotationResultOptions = useQuotationResultOptions();
    const quotationReasonOptions = useQuotationReasonOptions();

    return (
        <TableSearch
            fields={[
                ...(isElevatorDetail
                    ? []
                    : [
                          {
                              label: <FormattedMessage id={`documents.quotations.search.factoryNumber`} />,
                              labelFor: 'factoryNumber',
                              renderer: (
                                  <Input
                                      id="factoryNumber"
                                      data-testid="factory-number"
                                      value={value[QuotationField.ELEVATORS_FACTORY_NUMNER]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [QuotationField.ELEVATORS_FACTORY_NUMNER]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                          {
                              label: <FormattedMessage id={`documents.quotations.search.street`} />,
                              labelFor: 'street',
                              renderer: (
                                  <Input
                                      id="street"
                                      value={value[QuotationField.ELEVATORS_PROPERTY_UNIT_STREET]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [QuotationField.ELEVATORS_PROPERTY_UNIT_STREET]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                          {
                              label: <FormattedMessage id={`documents.quotations.search.city`} />,
                              labelFor: 'city',
                              renderer: (
                                  <Input
                                      id="city"
                                      data-testid="city"
                                      value={value[QuotationField.ELEVATORS_PROPERTY_UNIT_CITY]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [QuotationField.ELEVATORS_PROPERTY_UNIT_CITY]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                          {
                              label: <FormattedMessage id={`documents.quotations.search.businessUnit`} />,
                              labelFor: 'businessUnit',
                              renderer: (
                                  <Input
                                      id="businessUnit"
                                      value={value[QuotationField.ELEVATORS_PROPERTY_UNIT_BUSINESS_UNIT]}
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      onChange={({ target }) => {
                                          handleValue({
                                              [QuotationField.ELEVATORS_PROPERTY_UNIT_BUSINESS_UNIT]: target.value,
                                          });
                                      }}
                                  />
                              ),
                          },
                      ]),

                {
                    label: <FormattedMessage id={`documents.quotations.search.quotationDate`} />,
                    labelFor: 'quotationDate',
                    renderer: (
                        <TableSearchDateRange
                            id="quotationDate"
                            value={{
                                from: value[QuotationField.QUOTATION_DATE_FROM],
                                to: value[QuotationField.QUOTATION_DATE_TO],
                            }}
                            onChange={(from, to) => {
                                handleValue({
                                    [QuotationField.QUOTATION_DATE_FROM]: from,
                                    [QuotationField.QUOTATION_DATE_TO]: to,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.quotations.search.quotationType`} />,
                    labelFor: 'quotationType',
                    renderer: (
                        <TableSearchSelect
                            id="quotationType"
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                            options={quotationTypeOptions}
                            value={value[QuotationField.QUOTATION_TYPE]}
                            disabled={isEmpty(quotationTypeOptions)}
                            onChange={value => {
                                handleValue({
                                    [QuotationField.QUOTATION_TYPE]: value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.quotations.search.serviceProvider.name`} />,
                    labelFor: 'serviceProvider.name',
                    renderer: (
                        <Input
                            id="serviceProvider.name"
                            value={value[QuotationField.SERVICE_PROVIDER_NAME]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [QuotationField.SERVICE_PROVIDER_NAME]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.quotations.search.result`} />,
                    labelFor: 'result',
                    renderer: (
                        <TableSearchSelect
                            id="result"
                            placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                            options={quotationResultOptions}
                            value={value[QuotationField.RESULT]}
                            disabled={isEmpty(quotationResultOptions)}
                            onChange={value => {
                                handleValue({
                                    [QuotationField.RESULT]: value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`documents.quotations.search.reason`} />,
                    labelFor: 'reason',
                    renderer: (
                        <TableSearchSelect
                            id="reason"
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                            options={quotationReasonOptions}
                            value={value[QuotationField.REASON]}
                            disabled={isEmpty(quotationReasonOptions)}
                            onChange={value => {
                                handleValue({
                                    [QuotationField.REASON]: value,
                                });
                            }}
                        />
                    ),
                },
            ]}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
        />
    );
};
