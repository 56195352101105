import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = () => ({
    marginRight: '1.5rem',
});

export const checkbox: TRuleWithTheme = () => ({
    '& .ant-checkbox + span': {
        fontSize: '.875rem',
    },
});
