import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';
import { selectors, useFetchOtherDocuments } from 'modules/entities/modules/other-documents';
import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { Sorting, SortingType } from 'constants/index';

import { Fields } from '../components/OtherDocumentsTab/types';

const defaultSort: Sorting = { field: Fields.DATE, type: SortingType.DESC };

export const useOtherDocumentsTable = () => {
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams();

    const documents = useSelector(state =>
        selectors.selectFilteredSortedOtherDocuments(state, {
            sorting: sorting ?? defaultSort,
            search,
        }),
    );

    const api = useFetchOtherDocuments();

    useDisplayError(api.error);

    return { api, documents, defaultSearch: defaultValue };
};
