import { IconType } from 'modules/ui';

export interface ParsedHealthState {
    elevatorHealth: ParsedSection[];
    deviceHealth: ParsedSection[];
}

export enum HEALTH_STATE_TYPES {
    ELEVATOR = 'elevator',
    DEVICE = 'device',
}
export interface ParsedSection {
    isOkState?: boolean;
    name: HealthStateSectionsName;
    iconType?: IconType;
    subStates?: any;
    enabled?: boolean;
    active?: boolean;
    parentState?: string;
    createdAt?: any;
    priorityField?: string;
    probability?: any;
    fallBackDescriptionId?: string;
    timestamp?: any;
}
export interface IoTDevice {
    installation: string;
    id: string;
    type: 'IOT' | 'Smart_Flap'
    deviceInstalled: boolean
    daysAgoItWasInstalled?: number;
    deviceState: {
        disabled: boolean;
        message: string;
    };
}

export interface Contract {
    startDate: string;
    endDate: string;
    contractNumber: string;
    active: boolean;
}

export interface Contact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    type: ContactType;
}

export enum ElevatorState {
    RUNNING = 'RUNNING',
    POTENTIAL_ISSUES = 'POTENTIAL_ISSUES',
    OUT_OF_ORDER_TRIP_PROFILE = 'OUT_OF_ORDER_TRIP_PROFILE',
    OUT_OF_ORDER = 'OUT_OF_ORDER',
    OUT_OF_ORDER_ALARM_DEVICE = 'OUT_OF_ORDER_ALARM_DEVICE',
    TECHNICIAN_ON_SITE = 'TECHNICIAN_ON_SITE',
    DEVICE_OFFLINE = 'DEVICE_OFFLINE',
    UNKNOWN = 'UNKNOWN',
}

export enum ElevatorStateGroup {
    UNKNOWN = 'UNKNOWN',
}

export enum ContactType {
    SERVICE_COMPANY = 'serviceCompany',
    LIFT_GUARD = 'liftGuard',
    ASSET_MANAGER = 'assetManager',
    PROPERTY_MANAGER = 'propertyManager',
    PROPERTY_ADMINISTRATION = 'propertyAdministration',
    FACILITY_MANAGER = 'facilityManager',
    ACCOUNT_MANAGER = 'accountManager',
    OBJECT_SUPERVISOR = 'objectSupervisor',
    JANITOR = 'janitor',
}

export enum ElevatorStateName {
    TECHNICIAN = 'TECHNICIAN',
    CABINLIGHT = 'CABINLIGHT',
    OTHER = 'OTHER',
    TRIPPROFILE = 'TRIPPROFILE',
    IOTDEVICE = 'IOTDEVICE',
}

export enum HealthStateSectionsName {
    TECHNICIAN = 'TECHNICIAN',
    TRIPANALYSIS = 'TRIPANALYSIS',
    USAGEPROFILE = 'USAGEPROFILE',
    DOORS = 'DOORS',
    CABINLIGHT = 'CABINLIGHT',
    IOTDEVICE = 'IOTDEVICE',
}

export enum ElevatorSubStateName {
    TECHNICIAN_ONSITE = 'TECHNICIAN_ONSITE',
    TECHNICIAN_NOTONSITE = 'TECHNICIAN_NOTONSITE',
    CABINLIGHT_SENSOR_NOT_INSTALLABLE = 'CABINLIGHT_SENSOR_NOT_INSTALLABLE',
    CABINLIGHT_OFF = 'CABINLIGHT_OFF',
    CABINLIGHT_LOW = 'CABINLIGHT_LOW',
    TRIPPROFILE_ISSUE = 'TRIPPROFILE_ISSUE',
    TRIPPROFILE_BREAKDOWNEMERGENCYSTOP = 'TRIPPROFILE_BREAKDOWNEMERGENCYSTOP',
    TRIPPROFILE_TEMPORARYEMERGENCYSTOPS = 'TRIPPROFILE_TEMPORARYEMERGENCYSTOPS',
    IOTDEVICE_BLACKOUT = 'IOTDEVICE_BLACKOUT',
    IOTDEVICE_OFFLINE = 'IOTDEVICE_OFFLINE',
}

export enum HealthStateSubSectionName {
    TECHNICIAN_ONSITE = 'TECHNICIAN_ONSITE',
    TECHNICIAN_NOTONSITE = 'TECHNICIAN_NOTONSITE',
    CABINLIGHT_SENSOR_NOT_INSTALLABLE = 'CABINLIGHT_SENSOR_NOT_INSTALLABLE',
    CABINLIGHT_OFF = 'CABINLIGHT_OFF',
    CABINLIGHT_LOW = 'CABINLIGHT_LOW',
    CABINLIGHT_OK = 'CABINLIGHT_OK',
    TRIPPROFILE_ISSUE = 'TRIPPROFILE_ISSUE',
    TRIPPROFILE_BREAKDOWNEMERGENCYSTOP = 'TRIPPROFILE_BREAKDOWNEMERGENCYSTOP',
    TRIPPROFILE_TEMPORARYEMERGENCYSTOPS = 'TRIPPROFILE_TEMPORARYEMERGENCYSTOPS',
    IOTDEVICE_BLACKOUT = 'IOTDEVICE_BLACKOUT',
    IOTDEVICE_OFFLINE = 'IOTDEVICE_OFFLINE',
    IOTDEVICE_OK = 'IOTDEVICE_OK',
    TRIPANALYSIS_BREAKDOWNEMERGENCYSTOP = 'TRIPANALYSIS_BREAKDOWNEMERGENCYSTOP',
    TRIPANALYSIS_TEMPORARYEMERGENCYSTOPS = 'TRIPANALYSIS_TEMPORARYEMERGENCYSTOPS',
    TRIPANALYSIS_OK = 'TRIPANALYSIS_OK',
    USAGEPROFILE_TESTTRIP_FAILED = 'USAGEPROFILE_TESTTRIP_FAILED',
    USAGEPROFILE_OUTOFORDER = 'USAGEPROFILE_OUTOFORDER',

    USAGEPROFILE_OK = 'USAGEPROFILE_OK',
    DOORS_REVERSING_BREAKDOWN = 'DOORS_REVERSING_BREAKDOWN',
    DOORS_OK = 'DOORS_OK',
}

export enum ElevatorTotalTripsAggregation {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum MeasureName {
    FLOOR = 'floor',
}

export interface Elevator {
    id: string;
    factoryNumber: number;
    maintenancePerYear?: number;
    recommendedMaintenancePerYear?: number;
    recommendationForMaintenanceInterval?: string;
    owner: string;
    internalCustomerName: string;
    maintenanceContractPresent: boolean;
    propertyUnit: {
        businessUnit: string;
        street: string;
        postalCode: string;
        city: string;
        longitude?: string;
        latitude?: string;
    };
    devices: IoTDevice[];
    mappedDevice?: {
        elevatorId: string;
        validFrom: number;
        daysAgoItWasInstalled?: number;
        deviceId: string;
    };
    contracts: Contract[];
    state: {
        elevatorId: Elevator['id'];
        state: ElevatorState;
        createdAt: string;
        subStates?: ElevatorSubStatesKeyValue[];
    };
}

export interface ElevatorGroup {
    // TODO: Change after coordinates are in the Elevator type
    propertyUnit: Elevator['propertyUnit'] &
        google.maps.LatLngLiteral & {
            address: string;
        };
    elevators: Elevator[];
}
export interface CurrentFloor {
    floor: number;
    time: string;
}

export interface ElevatorDetail extends Elevator {
    manufacturer: string;
    driveType: string;
    type: string;
    manufactureYear: string;
    modernizationDate: string;
    modernizationDegree: string;
    stops: number;
    doorDrives: number;
    maxPayload: number;
    iotLiveSince: string;
    portalUrl: string;
    contacts: Contact[];
    currentFloor: CurrentFloor;
    floorMapping: FloorMapping[];
    alarmDeviceManufacturer: string;
    alarmDeviceModel: string;
    brakeCatchingDeviceManufacturer: string;
    brakeCatchingDeviceModel: string;
    cabinDoorDriveManufacturer: string;
    cabinDoorDriveModel: string;
    carBufferManufacturer: string;
    carBufferModel: string;
    carDoorWidth: string;
    carDoorHeight: string;
    controlGroup: string;
    controlManufacturer: string;
    controlModel: string;
    counterWeightBufferManufacturer: string;
    counterWeightBufferModel: string;
    diameterRopes: string;
    diameterOverspeedGovernorRope: string;
    enginePosition: string;
    installationRegulation: string;
    frequencyInverterManufacturer: string;
    frequencyInverterModel: string;
    liftHeight: number;
    liftMachineManufacturer: string;
    liftMachineModel: string;
    lowerCrossingPath: string;
    lowerHeightProtectiveArea: string;
    overspeedGovernorManufacturer: string;
    overspeedGovernorModel: string;
    ropeRatio: string;
    shaftCopyingManufacturer: string;
    shaftCopyingModel: string;
    shaftDoorManufacturer: string;
    shaftDoorModel: string;
    speed: string;
    staticHead: string;
    upperCrossingPath: string;
    upperHeightProtectiveArea: string;
    shaftTemperature: number;
}

export interface FloorMapping {
    floor: number;
    label: string;
}

export interface ElevatorFloorHistory {
    measure_name: MeasureName;
    measure_value: string;
    time: string;
}

export type ElevatorSubStatesKeyValue = Record<ElevatorSubStateName, ElevatorSubStates[]>;
export type HealthSectionsKeyValue = Record<HealthStateSectionsName, ParsedSection>;

export interface ElevatorSubStates {
    name: ElevatorSubStateName;
    state: number;
    createdAt: string;
    timestamp: number;
    level?: number;
    probability?: number;
}

export enum ElevatorField {
    STATUS = 'status',
    FACTORY_NUMBER = 'factoryNumber',
    PROPERTY_UNIT_STREET = 'propertyUnit.street',
    PROPERTY_UNIT_POSTAL_CODE = 'propertyUnit.postalCode',
    PROPERTY_UNIT_CITY = 'propertyUnit.city',
    PROPERTY_UNIT_BUSINESS_UNIT = 'propertyUnit.businessUnit',
    HARDWARE = 'devices',
    OWNER = 'owner',
}
