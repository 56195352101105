import React, { FC } from 'react';
import { Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './Popover.styles';

interface PopoverProps extends AntPopoverProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Popover: FC<PopoverProps> = ({ children, extend, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <AntPopover overlayClassName={styles.overlay} {...rest}>
            {children}
        </AntPopover>
    );
};

export default Popover;
