import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useFela } from 'react-fela';
import { useHistory } from 'react-router-dom';
import { render, unmountComponentAtNode } from 'react-dom';

import { useFelaEnhanced } from 'hooks';

import { MARKER_TYPES, MarkerWithWorkorderGroup } from '../../types';
import {
    createCluster,
    getWorkorderGroupState,
    fitBounds,
    createMap,
    createMarker,
    prevInfoWindows,
} from '../../utils';

import { InfoWindow } from '../InfoWindow';
import { ClusterInfoWindow } from '../ClusterInfoWindow';
import { InfoWindowProviders } from '../InfoWindowProviders';
import { MapControls } from '../MapControls';

import * as felaRules from './Map.rules';
import { WorkorderGroup } from 'modules/entities/modules/workorders';

export interface MapProps {
    workorderGroups: WorkorderGroup[];
}

export const Map = ({ workorderGroups }: MapProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const ref = useRef<HTMLDivElement>();
    const mapRef = useRef<google.maps.Map>(null);
    const markersRef = useRef<google.maps.Marker[]>([]);

    const history = useHistory();
    const intl = useIntl();
    const { renderer, theme } = useFela();

    useEffect(() => {
        mapRef.current = createMap(ref);
    }, []);

    useEffect(() => {
        const map = mapRef.current;

        const infoWindowNodes = [];

        const markers = workorderGroups.map((workorderGroup, i) => {
            const { propertyUnit, workorders } = workorderGroup;
            const workorderGroupState = getWorkorderGroupState(workorderGroup);

            // if there is a potential issue, extract the set and create a group
            const markerOptions = {
                position: {
                    lat: propertyUnit.lat,
                    lng: propertyUnit.lng,
                },
                map: map,
                label: {
                    text: workorders.length.toString(),
                    className: workorders.length > 0 ? styles.markerLabel : styles.emptyMarkerLabel,
                    fontFamily: 'WorkSans',
                },
                icon: MARKER_TYPES[workorderGroupState].markerIcon,
                // zIndex: i === 0? 33333333333: 2000
            };
            const infoContentNode = document.createElement('div');
            infoWindowNodes.push(infoContentNode);
            render(
                <InfoWindowProviders intl={intl} history={history} renderer={renderer} theme={theme}>
                    <InfoWindow workorders={workorders} />
                </InfoWindowProviders>,
                infoContentNode,
            );

            const infoWindowOptions = {
                content: infoContentNode,
            };

            const marker: MarkerWithWorkorderGroup = createMarker(map, markerOptions, infoWindowOptions);
            // Note: We need to add workorderGroup here, so we can read it from cluster and pass it to InfoWindow
            marker.workorderGroup = workorderGroup;

            return marker;
        });
        fitBounds(map, markers);

        const getInfoWindowOptions = (workorderGroups: WorkorderGroup[]) => {
            const infoContentNode = document.createElement('div');
            infoWindowNodes.push(infoContentNode);

            render(
                <InfoWindowProviders intl={intl} history={history} renderer={renderer} theme={theme}>
                    <ClusterInfoWindow workorderGroups={workorderGroups} />
                </InfoWindowProviders>,
                infoContentNode,
            );

            return {
                content: infoContentNode,
            };
        };
        const cluster = createCluster(map, markers, getInfoWindowOptions, { className: styles.clusterLabel });

        markersRef.current = markers;

        return () => {
            cluster.removeMarkers(markers);
            cluster.setMap(null);

            markers.forEach(marker => {
                marker.setMap(null);
            });

            infoWindowNodes.forEach(node => {
                unmountComponentAtNode(node);
            });

            if (prevInfoWindows.has(map)) {
                prevInfoWindows.delete(map);
            }
        };
    }, [
        renderer,
        theme,
        history,
        intl,
        workorderGroups,
        styles.markerLabel,
        styles.emptyMarkerLabel,
        styles.map,
        styles.clusterLabel,
    ]);

    return (
        <>
            <div ref={ref} id="map" className={styles.map} />
            <MapControls
                disabled={!mapRef.current}
                onZoomInClick={() => {
                    const map = mapRef.current;
                    map.setZoom(map.getZoom() + 1);
                }}
                onZoomOutClick={() => {
                    const map = mapRef.current;
                    map.setZoom(map.getZoom() - 1);
                }}
                onResetClick={() => {
                    const map = mapRef.current;
                    const markers = markersRef.current;
                    fitBounds(map, markers);
                }}
            />
        </>
    );
};
