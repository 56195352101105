import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Popover, PopoverContainer } from 'modules/ui';

import type { Contract } from 'modules/entities/modules/documents-contracts';

import { DocumentInfoIcon } from '../../DocumentInfoIcon';
import { generatePath, Link } from 'react-router-dom';
import config from 'config';
import * as felaRules from './ContractElevators.styles';
import { useFelaEnhanced } from 'hooks';

export interface ContractElevatorProps {
    contract: Contract;
}

export const ContractElevators = ({ contract }: ContractElevatorProps) =>{
    const { styles } = useFelaEnhanced(felaRules);
    return(    
        contract.elevators ? (
        <>
            {contract.elevators.length}{' '}
            <Popover
                placement="leftTop"
                content={
                    <PopoverContainer>
                        {contract.elevators.slice(0, 5).map(elevator => (
                            <PopoverContainer.Item
                                key={elevator.factoryNumber}
                                title={
                                    <Link className={styles.factoryNumber} to={generatePath(config.routes.elevatorDetail, { id: elevator?.elevatorId })}>
                                        {elevator.factoryNumber}
                                    </Link>
                                }
                                text={`${elevator.propertyUnit.street}, ${elevator.propertyUnit.postalCode} ${elevator.propertyUnit.city}`}
                                secondaryText={elevator.propertyUnit.businessUnit}
                                //extend={{title: rules.factoryNumber}}
                            />
                        ))}
                        {contract.elevators.length > 5 ? (
                            <PopoverContainer.Item
                                size="small"
                                secondaryText={
                                    <FormattedMessage
                                        id="table.nMore"
                                        values={{ number: contract.elevators.length - 5 }}
                                    />
                                }
                            />
                        ) : null}
                    </PopoverContainer>
                }
                trigger="hover"
            >
                <DocumentInfoIcon />
            </Popover>
        </>
    ) : (
        <>0</>
    ))
}
