import { ElevatorStateName } from 'modules/entities/modules/elevators';
import { ElevatorSubStateName } from 'modules/entities/modules/elevators';
export const elevatorSubStatePriorityField = {
    [ElevatorStateName.TRIPPROFILE]: 'probability',
    [ElevatorSubStateName.TRIPPROFILE_BREAKDOWNEMERGENCYSTOP]: 'createdAt',
    [ElevatorSubStateName.TRIPPROFILE_TEMPORARYEMERGENCYSTOPS]: 'createdAt',
    [ElevatorSubStateName.TRIPPROFILE_ISSUE]: 'probability',
    [ElevatorStateName.CABINLIGHT]: 'level',
    [ElevatorStateName.IOTDEVICE]: 'createdAt',
    [ElevatorStateName.TECHNICIAN]: 'createdAt',
};
