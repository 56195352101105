import type { ApiAction } from '../../../../../types';
import { downloadDocumentsTypes } from '../../actions';

export default function (state = '', action: ApiAction) {
    switch (action.type) {
        case downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_SUCCESS:
            const downloadUrl: string = action.payload;

            return downloadUrl;
        case downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_RESET:
            return '';
        default:
            return state;
    }
}
