import { useSelector } from 'react-redux';

import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { useDisplayError } from 'modules/errors';

import { selectElevators, selectSortedElevators } from '../services/selectors';

import { useFetchElevators } from '../hooks/useFetchElevators';
import { DEFAULT_SORT } from '../constants';

export const useElevators = (setUp?: { disableSearch: boolean; disableSorting: boolean }) => {
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const api = useFetchElevators();

    const elevators = useSelector(state =>
        selectSortedElevators(state, {
            search: setUp?.disableSearch ? [] : search,
            sorting: setUp?.disableSorting ? null : sorting,
        }),
    );

    const unfilteredElevators = useSelector(selectElevators);
    useDisplayError(api.error);

    return {
        loading: api.inProgress,
        elevators,
        defaultSearch: defaultValue,
        unfilteredElevators,
    };
};
