import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Typography } from 'antd';

import * as felaRules from './DividerName.styles';

type DividerNameProps = {};

const DividerName: FC<DividerNameProps> = ({ children }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return <Typography.Text className={styles.dividerName}>{children}</Typography.Text>;
};

export default DividerName;
