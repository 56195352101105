import { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    padding: '2rem 1rem 3rem',

    '& .ant-form-item-label > label.ant-form-item-required::before': {
        display: 'none',
    },

    tablet: {
        padding: '2.5rem 2rem 3.75rem',
    },
});

export const title: TRuleWithTheme = ({ theme }) => ({
    height: theme.height.logoLarge,

    marginBottom: '2rem',

    tablet: {
        marginBottom: '4rem',
    },
});
