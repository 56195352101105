import { put } from 'redux-saga/effects';
import { noop } from 'lodash';

import { authApi } from 'config/antonio';
import config from 'config';
import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';

import { fetchTotalTrips as actions, fetchTotalTripsTypes } from '../actions';
import { TotalTrips } from '../../types';

function* fetchTotalTrips(action) {
    const { id } = action.meta;

    try {
        const { startTime, endTime, granularity } = action.payload;

        const params = new URLSearchParams();

        if (startTime) params.append('startTime', startTime);
        if (endTime) params.append('endTime', endTime);
        if (granularity) params.append('granularity', granularity);

        const { data } = yield* authApi.get<TotalTrips[]>(config.api.totalTrips, {
            uriParams: { id },
            params: params,
        });

        yield put(actions.fetchTotalTripsSuccess(id, data));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchTotalTripsFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchTotalTrips',
        },
        {
            pattern: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_REQUEST,
            handler: fetchTotalTrips,
        },
        {
            pattern: fetchTotalTripsTypes.FETCH_TOTAL_TRIPS_CANCEL,
            handler: noop,
        },
    );
}
