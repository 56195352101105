import { RESET_SELECT_DOWNLOAD, SELECT_DOWNLOAD, UNSELECT_DOWNLOAD } from './types';

export interface SelectDownloadAction {
    type: typeof SELECT_DOWNLOAD;
    payload: string[];
}

export interface ResetDownloadSelectAction {
    type: typeof RESET_SELECT_DOWNLOAD;
}

export interface UnselectDownloadSelectAction {
    type: typeof UNSELECT_DOWNLOAD;
    payload: string;
}

export const selectDownload = (ids: string[]): SelectDownloadAction => ({
    type: SELECT_DOWNLOAD,
    payload: ids,
});

export const unselectDownload = (id): UnselectDownloadSelectAction => ({
    type: UNSELECT_DOWNLOAD,
    payload: id,
});

export const resetDownloadSelect = (): ResetDownloadSelectAction => ({
    type: RESET_SELECT_DOWNLOAD,
});
