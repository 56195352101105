import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { FormState } from '../types';
import * as actions from '../services/actions';
import { formStateSelector } from '../services/selectors';

interface FormSubmissionProps<FormValues> extends FormState {
    handleSubmit: (value: FormValues, meta?: any) => void;
    clearForm: () => void;
}

const useFormSubmission = <FormValues extends object>(form: string): FormSubmissionProps<FormValues> => {
    const formState = useSelector(state => formStateSelector(state, form));
    const dispatch = useDispatch();

    const handleSubmit = (values: FormValues, meta?: any) => {
        dispatch(actions.submitForm(form, values, meta));
    };

    const clearForm = () => {
        dispatch(actions.unregisterForm(form));
    };

    useEffect(() => {
        dispatch(actions.registerForm(form));

        return () => {
            dispatch(actions.unregisterForm(form));
        };
    }, [dispatch, form]);

    return {
        handleSubmit,
        clearForm,
        ...formState,
    };
};

export default useFormSubmission;
