import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';

import Core from 'modules/core';
import Application from 'modules/application';

import loadPolyfills from './polyfills';

const renderApp = () => {
    ReactDOM.render(
        <Core>
            <Application />
        </Core>,
        document.getElementById('root'),
    );
};

loadPolyfills()
    .then(renderApp)
    .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Failed to load polyfills', error);
        renderApp();
    });
