import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

import { InvoiceState } from 'modules/entities/modules/documents-invoices';
import { StatusFilterContainer, stateComparatorFactory, Tables } from 'modules/table';
import { SortingType } from 'constants/index';

import StatusCheckbox from '../StatusCheckbox';
import useInvoiceListStatusFilter from '../../../../hooks/useInvoiceListStatusFilter';
import { useDownloadDocuments } from 'modules/entities/modules/documents';

type StatusFilterProps = {
    isElevatorDetail: boolean;
};

const StatusFilter: FC<StatusFilterProps> = ({ isElevatorDetail }) => {
    const { onCheckboxChange, countByState, filterParams } = useInvoiceListStatusFilter();
    const { resetDownloadSelect } = useDownloadDocuments();

    const stateKeys = React.useMemo(
        () => Object.keys(InvoiceState).sort(stateComparatorFactory({ type: SortingType.ASC }, Tables.INVOICES)),
        [],
    );

    return (
        <StatusFilterContainer title={<FormattedMessage id="documents.table.status" />}>
            <Checkbox.Group
                value={filterParams ?? [InvoiceState.SHOW_ALL]}
                data-test-id="invoice-status-filter-checkboxgroup"
            >
                {stateKeys.map((state: InvoiceState) => (
                    <StatusCheckbox
                        key={state}
                        state={InvoiceState[state]}
                        count={countByState[state]}
                        onChange={e => {
                            onCheckboxChange(e);
                            resetDownloadSelect();
                        }}
                    />
                ))}
            </Checkbox.Group>
        </StatusFilterContainer>
    );
};

export default StatusFilter;
