import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const container = () => ({});

export const healthHeaderText: TRule = () => ({
    fontSize: '1.25rem',
});
export const healthHeader: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: '0rem !important',
    // marginTop: '1.33rem',
});
export const blur: TRule = () => ({
    filter: 'blur(1px)',
});
export const card: TRule = () => ({
    '> .ant-card-body': {
        paddingBottom: '0px',
    },
});

export const popoverHeader: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '500',
    color: theme.colors.text,
    fontSize: '0.88rem',
    marginBottom: '0.63rem',
});

export const popoverBody: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '500',
    color: theme.colors.textSecondary,
    fontSize: '0.75rem',
    marginBottom: '0.88rem',
});

export const deactivated: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '1.56rem',
    paddingBottom: '3.06rem',
    fontSize: '1rem',
    color: '#262626',
});
export const deactivatedIcon: TRuleWithTheme = ({ theme }) => ({
    height: '22px',
    marginRight: '0.94rem',
    paddingLeft: '10px',
});

export const iconContainer: TRule = () => ({
    height: '1.8rem',
    '& .anticon': {
        '& svg': {
            width: '1.06rem !important',
        },
    },
});

export const collapseTrigger: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '0.75rem',
    marginTop: '0.75rem',
});

export const collectionTrigger: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
        color: theme.colors.primary,
        '& span': {
            color: theme.colors.primary,
        },
        '& div': {
            color: theme.colors.primary,
        },
    },
});

export const collectionTriggerDisable: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const collapseTriggerText: TRuleWithTheme = ({ theme }) => ({
    fontSize: '0.88rem',
    color: theme.colors.textSecondary,
    cursor: 'pointer',
});

export const collapseTriggerTextDisabled: TRuleWithTheme = ({ theme }) => ({
    fontSize: '0.88rem',
    color: '#CBCBCB',
});

export const iconStyle: TRuleWithTheme = ({ theme }) => ({
    cursor: 'pointer',
});

export const iconStyleDisable: TRuleWithTheme = ({ theme }) => ({
    color: '#CBCBCB',
});
