import { useEffect } from 'react';

const useAirtimeScript = (url: string, token: string) => {
    useEffect(() => {
        window.airtime = window.airtime || { id: token };
        const script = document.createElement('script');
        script.async = true;
        script.src = url;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [url, token]);
};
export default useAirtimeScript;
