import React from 'react';

export const QuotationCheck = () => (
    <svg width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 18H8a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v1h6v-1a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1Z" fill="currentColor" />
        <path
            d="m14.71 8.29-2-2a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-2 2a1.004 1.004 0 0 0 1.42 1.42l.29-.3V14a1 1 0 0 0 2 0V9.41l.29.3a1 1 0 0 0 1.42 0 .998.998 0 0 0 0-1.42Z"
            fill="currentColor"
        />
        <path
            d="M19 22H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1ZM6 20h12V4H6v16Z"
            fill="currentColor"
        />
    </svg>
);
