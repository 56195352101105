import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Table, useUrlPagination } from 'modules/table';

import * as felaRules from './SupportTable.rules';
import SupportCard from '../SupportCard/SupportCard';
import { Support } from 'modules/entities/modules/support';


export interface SupportTableProps {
    isClosed: boolean;
    loading: boolean;
    requests: Support[];
}

export const SupportTable = ({ isClosed, loading, requests }: SupportTableProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const pagination = useUrlPagination();

    return (
        <div className={styles.tableContainer}>
            <Table
                loading={loading}
                form={{ syncToUrl: values => values }}
                columns={[
                    {
                        title: <FormattedMessage id="documents.table.invoiceReason" />,
                        key: 'invoiceReason',
                        dataIndex: 'invoiceReason',
                        valueType: 'text',
                        sorter: null,
                        render: (_, record) => <SupportCard request={record} isClosed={isClosed} />,
                    },
                ]}
                rowKey="id"
                search={false}
                bordered={false}
                dataSource={requests}
                toolBarRender={false}
                pagination={pagination}
                data-testid="invoices-table"
            />
        </div>
    );
};
