import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import config from 'config';

export const appSyncClient = new AWSAppSyncClient({
    url: config.aws.amplify.aws_appsync_graphqlEndpoint,
    region: config.aws.amplify.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
});
