import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { FloatingLabel, Input } from 'modules/form';

import * as felaRules from './TitleInput.rules';
export interface TitleInputProps {
    value: string | null;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    disabled: boolean;
}

export const TitleInput = ({ value = null, onChange, disabled }: TitleInputProps) => {
    const intl = useIntl();

    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <FloatingLabel
                htmlFor="type"
                floating={Boolean(value)}
                label={intl.formatMessage({
                    id: 'support.request.placeholder.title',
                })}
            >
                {({ onFocus, onBlur, ...rest }) => (
                    <Input
                        value={value}
                        className={Boolean(value)? styles.inputWithValue: styles.input}
                        placeholder={intl.formatMessage({ id: 'support.request.placeholder.title' })}
                        onChange={onChange}
                        disabled={disabled}
                    />
                )}
            </FloatingLabel>
        </div>
    );
};
