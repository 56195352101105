import { useDispatch } from 'react-redux';

import { resetDownloadSelect as resetDownloadSelectAction } from '../services/actions';

export const useResetSelectedDocuments = () => {
    const dispatch = useDispatch();

    const resetDownloadSelect = () => {
        dispatch(resetDownloadSelectAction());
    };

    return resetDownloadSelect;
};
