import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchElevators as actions } from '../services/actions';
import { selectElevatorsApi } from '../services/selectors';

export const useFetchElevators = () => {
    const api = useSelector(selectElevatorsApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchElevatorsRequest());

        return () => {
            batch(() => {
                dispatch(actions.fetchElevatorsCancel());
                dispatch(actions.fetchElevatorsReset());
            });
        };
    }, [dispatch]);

    return api;
};
