import { basicApiReducer } from '@ackee/redux-utils';
import { fetchOtherDocumentsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_REQUEST,
        SUCCESS: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_SUCCESS,
        FAILURE: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_FAILURE,
        CANCEL: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_CANCEL,
        RESET: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_RESET,
    },
});
