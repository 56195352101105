import { useSelector } from 'react-redux';
import { useFetchIncident } from 'modules/entities/modules/incidents';
import { selectIncident } from 'modules/entities/modules/incidents/services/selectors';
const useIncidentWidget = () => {
    const api = useFetchIncident();
    const activeIncident = useSelector(selectIncident);

    return { activeIncident, ...api };
};

export default useIncidentWidget;
