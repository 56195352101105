import { IconType } from 'modules/ui';
import { ElevatorSubStateName, HealthStateSubSectionName } from 'modules/entities/modules/elevators';

export const statusIconMap = {
    [ElevatorSubStateName.TECHNICIAN_ONSITE]: IconType.TECHNICIAN_ON_SITE,
    [ElevatorSubStateName.CABINLIGHT_SENSOR_NOT_INSTALLABLE]: IconType.ELEVATOR_STATUS_INACTIVE,
    [ElevatorSubStateName.CABINLIGHT_LOW]: IconType.ELEVATOR_STATUS_WARNING,
    [ElevatorSubStateName.CABINLIGHT_OFF]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_ISSUE]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_BREAKDOWNEMERGENCYSTOP]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [ElevatorSubStateName.TRIPPROFILE_TEMPORARYEMERGENCYSTOPS]: IconType.ELEVATOR_STATUS_WARNING,
    [ElevatorSubStateName.IOTDEVICE_BLACKOUT]: IconType.POWER_OUTAGE,
    [ElevatorSubStateName.IOTDEVICE_OFFLINE]: IconType.NO_IOT,
};

export const healthStateStatusIconMap = {
    [HealthStateSubSectionName.TECHNICIAN_ONSITE]: IconType.TECHNICIAN_ON_SITE,
    [HealthStateSubSectionName.CABINLIGHT_SENSOR_NOT_INSTALLABLE]: IconType.ELEVATOR_STATUS_INACTIVE,
    [HealthStateSubSectionName.CABINLIGHT_LOW]: IconType.ELEVATOR_STATUS_WARNING,
    [HealthStateSubSectionName.CABINLIGHT_OFF]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.TRIPPROFILE_ISSUE]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.TRIPPROFILE_BREAKDOWNEMERGENCYSTOP]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.TRIPPROFILE_TEMPORARYEMERGENCYSTOPS]: IconType.ELEVATOR_STATUS_WARNING,
    [HealthStateSubSectionName.IOTDEVICE_BLACKOUT]: IconType.POWER_OUTAGE,
    [HealthStateSubSectionName.IOTDEVICE_OFFLINE]: IconType.NO_IOT,
    [HealthStateSubSectionName.DOORS_REVERSING_BREAKDOWN]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.USAGEPROFILE_OUTOFORDER]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.USAGEPROFILE_TESTTRIP_FAILED]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.TRIPANALYSIS_BREAKDOWNEMERGENCYSTOP]: IconType.ELEVATOR_STATUS_HIGH_ALERT,
    [HealthStateSubSectionName.TRIPANALYSIS_TEMPORARYEMERGENCYSTOPS]: IconType.ELEVATOR_STATUS_WARNING,
};
