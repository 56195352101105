import React from 'react';
import { Card, Skeleton } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { bodyWrapper } from '../HeaderCard/HeaderCard.styles';

const felaRules = { bodyWrapper };

const HeaderCardLoader: React.FC = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Card>
            <div className={styles.bodyWrapper}>
                <Skeleton />
            </div>
        </Card>
    );
};

export default HeaderCardLoader;
