import { TRuleWithTheme } from 'styles/theme';

export const coloredDot: TRuleWithTheme<{ dotColor: string }> = ({ dotColor }) => ({
    height: 12,
    width: 12,
    display: 'inline-block',
    backgroundColor: dotColor,
    borderRadius: '50%',
    marginRight: '.5rem',
});

export const container: TRuleWithTheme = ({ theme }) => ({
    marginRight: '1.3125rem',
    fontSize: '0.875rem',
    ':last-of-type': {
        marginRight: '0',
    },
});
