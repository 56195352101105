import React, { FC, useContext } from 'react';
import { Button } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as rules from './FeedbackButton.styles';
import { useIntl } from 'react-intl';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';
import { DialogState, SupportDialogType } from 'constants/common';

const FeedbackButton: FC = () => {
    const { styles } = useFelaEnhanced(rules);
    const { formatMessage } = useIntl();

    const { navigate } = useContext(GlobalDialogContext);

    return (
        <Button
            className={styles.button}
            type="primary"
            size="middle"
            onClick={() => {
                navigate(DialogState.CREATE_SUPPORT, null, { type: SupportDialogType.FEEDBACK });
            }}
        >
            {formatMessage({ id: 'support.feedback.button' })}
        </Button>
    );
};

export default FeedbackButton;
