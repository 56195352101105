import React, { FC } from 'react';

import { Pagination } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ElevatorHistoryPaging.rules';

import { useElevatorHistoryPaging } from '../../hooks';

const ElevatorHistoryPaging: FC = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const { handlePageChange, currentPage, total } = useElevatorHistoryPaging();

    return (
        <div className={styles.pagination}>
            <Pagination current={currentPage} total={total} pageSize={1} onChange={handlePageChange} />
        </div>
    );
};

export default ElevatorHistoryPaging;
