import axios from 'axios';
import type { AxiosResponse } from 'axios';
import type { RequestResult } from '@ackee/antonio-core';
import { noop } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import jwtDecode from 'jwt-decode';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { createUIErrorMessage } from 'modules/errors';
import { takeRequest } from 'services/sagas/takeRequest';
import { authUserSelector } from 'modules/auth';

import { downloadDocumentsTypes, downloadDocuments as actions, resetDownloadSelect } from '../actions';
import type { DecodedFileToken } from '../../types';
import { DocumentDownloadType } from '../../types';

const DEFAULT_FILE_NAME = 'download';

function* getResponseFile(data: Blob, headers: Record<string, string>) {
    if (data.type === 'application/json') {
        const { url } = JSON.parse(yield call([data, data.text]));
        //headers['x-goog-sf-user-id'] = 'U-000060';
        const response: AxiosResponse = yield call(axios.get, url, {
            headers: headers,
            responseType: 'blob',
        });

        return response.data;
    }

    return data;
}

function* downloadDocuments(action) {
    try {
        const user = yield select(authUserSelector);

        const headers = {
            'x-goog-sf-user-id': user.customUserId,
        };

        const { files, type } = action.payload;

        const { data }: RequestResult<Blob> = yield authApi.post(
            config.api.downloadDocuments,
            {
                files,
            },
            {
                headers,
                responseDataType: 'blob',
            },
        );

        const file = yield call(getResponseFile, data, headers);

        if (type === DocumentDownloadType.DOWNLOAD && files.length === 1) {
            let name = DEFAULT_FILE_NAME;

            try {
                const decodedToken = jwtDecode<DecodedFileToken>(files[0].fileToken);
                name = decodedToken.fileName ?? DEFAULT_FILE_NAME;
            } catch (e) {
            } finally {
                saveAs(file, name);
            }
        } else {
            window.open(URL.createObjectURL(file), '_blank', 'noopener,noreferrer');
        }

        yield put(resetDownloadSelect());
        yield put(actions.downloadDocumentsSuccess(data));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.downloadDocumentsFailure(uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'downloadDocuments',
        },
        {
            pattern: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_REQUEST,
            handler: downloadDocuments,
        },
        {
            pattern: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_CANCEL,
            handler: noop,
        },
    );
}
