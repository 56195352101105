import React from 'react';
import type { ReactNode } from 'react';
import accept from 'attr-accept';

import type { RcFile } from 'antd/lib/upload';

import { DropZoneInput } from './DropZoneInput';
import { DropZoneFile } from './DropZoneFile';

export enum DropZoneFileAction {
    DROPPED = 'dropped',
    UNSUPPORTED = 'unsupported',
    REMOVED = 'remove',
}

export interface DropZoneProps {
    text: ReactNode;
    files: RcFile[];
    onFile: (file: RcFile, action: DropZoneFileAction) => void;
    supportedFileTypes?: string[];
    disabled?: boolean;
    multiple?: boolean;
}

const defaultFiles = [];

export const DropZone = ({
    files = defaultFiles,
    text,
    disabled,
    onFile,
    supportedFileTypes,
    multiple = false,
}: DropZoneProps) => (
    <>
        <DropZoneInput
            text={text}
            disabled={disabled || (!multiple && files.length >= 1)}
            multiple={multiple}
            onFile={file => {
                onFile(
                    file,
                    supportedFileTypes && !accept(file, supportedFileTypes)
                        ? DropZoneFileAction.UNSUPPORTED
                        : DropZoneFileAction.DROPPED,
                );
            }}
        />
        {files.map(file => (
            <DropZoneFile
                key={file.uid}
                file={file}
                disabled={disabled}
                onRemove={file => {
                    onFile(file, DropZoneFileAction.REMOVED);
                }}
            />
        ))}
    </>
);
