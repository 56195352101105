import { basicApiReducer } from '@ackee/redux-utils';

import { fetchFloorActivityTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_REQUEST,
        CANCEL: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_CANCEL,
        SUCCESS: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_SUCCESS,
        FAILURE: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_FAILURE,
        RESET: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_RESET,
    },
});
