import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',

    columnGap: '1rem',

    marginTop: '2rem',
});
