import { SortingType } from 'constants/index';

import { ElevatorField, ElevatorState } from './types';

export const DEFAULT_SORT = {
    field: ElevatorField.STATUS,
    type: SortingType.ASC,
};

export const SUPPORTED_ELEVATOR_STATES = [
    ElevatorState.OUT_OF_ORDER,
    ElevatorState.POTENTIAL_ISSUES,
    ElevatorState.TECHNICIAN_ON_SITE,
    ElevatorState.RUNNING,
    ElevatorState.DEVICE_OFFLINE,
    ElevatorState.UNKNOWN,
];
