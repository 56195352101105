import { basicApiReducer } from '@ackee/redux-utils';
import { fetchMaintenancesTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchMaintenancesTypes.FETCH_MAINTENANCES_REQUEST,
        SUCCESS: fetchMaintenancesTypes.FETCH_MAINTENANCES_SUCCESS,
        FAILURE: fetchMaintenancesTypes.FETCH_MAINTENANCES_FAILURE,
        CANCEL: fetchMaintenancesTypes.FETCH_MAINTENANCES_CANCEL,
        RESET: fetchMaintenancesTypes.FETCH_MAINTENANCES_RESET,
    },
});
