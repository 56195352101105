import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ApiReducerKey, EntityKey, Search, Sorting, SortingType } from 'constants/index';
import { Fields } from 'modules/documents/components/InvoicesListTab/types';
import {
    dateComparatorFactory,
    numberComparatorFactory,
    stringComparatorFactory,
    stateComparatorFactory,
} from 'modules/table/utils/comparators';
import { Tables } from 'modules/table/types';
import { generateUTCDate, passArgs, passDeeperArgs } from 'services/utils';

import { Invoice, InvoiceState } from '../types';

export const selectInvoicesApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_INVOICES, ApiReducerKey.LIST);

export const selectInvoicesEntityList = (state): Invoice[] =>
    state.entities[EntityKey.DOCUMENT_INVOICES][ApiReducerKey.LIST];

export const selectInvoices = createSelector(selectInvoicesEntityList, (invoices): Invoice[] =>
    invoices.map(invoice => ({
        ...invoice,
        items: [...new Map(invoice.items.map(item => [get(item, 'elevator.factoryNumber'), item])).values()],
        status: invoice.status?.toLowerCase().replaceAll(' ', '_') as InvoiceState,
    })),
);

export const selectInvoiceReasons = createSelector(selectInvoices, invoices => {
    const invoiceReasons = invoices.map(invoice => invoice.invoiceReason);
    const uniqueInvoiceReasons = new Set(invoiceReasons);

    return Array.from(uniqueInvoiceReasons)
        .map(reason => ({ value: reason, label: reason }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});

const invoiceStateKeys = Object.keys(InvoiceState);
export const selectCountInvoicesByState = createSelector(selectInvoices, invoices =>
    invoiceStateKeys.reduce((stateToCountMap, state) => {
        stateToCountMap[state] =
            state === 'SHOW_ALL'
                ? invoices.length
                : invoices.reduce((count, invoice) => {
                      const shouldBeCounted = invoice.status === InvoiceState[state];
                      return shouldBeCounted ? count + 1 : count;
                  }, 0);
        return stateToCountMap;
    }, {}),
);

function chooseInvoicesComparator(invoice: Invoice, sorting: Sorting) {
    switch (sorting.field) {
        case Fields.STATUS:
            return stateComparatorFactory(sorting, Tables.INVOICES);

        case Fields.INVOICE_DATE:
            return dateComparatorFactory(sorting);

        default:
            const field = invoice[sorting.field];

            return typeof field === 'number' ? numberComparatorFactory(sorting) : stringComparatorFactory(sorting);
    }
}

export const selectSortedInvoices = createSelector(selectInvoices, passArgs<Sorting>(), (invoices, sorting) => {
    if (!sorting || invoices.length === 0) {
        return invoices;
    }

    const comparator = chooseInvoicesComparator(invoices[0], sorting);

    return [...invoices].sort(comparator);
});

export const selectSortedAndFilteredInvoices = createSelector(
    selectSortedInvoices,
    passArgs<Sorting>(),
    passDeeperArgs<Search[]>(),
    (invoices, sorting, search) => {
        if (search?.length === 0 || invoices.length === 0) {
            return invoices;
        }

        return invoices.filter(invoice => {
            return search.every(({ field, query }) => {
                const foundItem = invoice[field];

                if (field === 'status' && query.includes(InvoiceState.SHOW_ALL)) return true;

                if (field === 'status') {
                    return query.includes(invoice.status?.toLowerCase());
                }

                if (field.includes('items.elevator.')) {
                    const splitField = field.split('items.');
                    return invoice.items.some(item => {
                        const foundItem = get(item, splitField[1]);

                        return foundItem?.toLowerCase().includes(query.toLowerCase());
                    });
                }

                if (field.includes('invoiceDate')) {
                    const foundItemTime = generateUTCDate(new Date(invoice.invoiceDate)).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === 'invoiceDate.from') {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === 'invoiceDate.to') {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                switch (typeof foundItem) {
                    case 'string':
                        // return foundItem?.toLowerCase().includes(query.toLowerCase());
                        return query?.toLowerCase().includes(foundItem.toLowerCase());
                    case 'number':
                        return Math.floor(foundItem) === parseInt(query);
                    default:
                        return foundItem === query;
                }
            });
        });
    },
);
