import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch, TableFilterPanel, TableSearchDateRange, useTableSearch } from 'modules/table';
import { WorkorderField } from 'modules/entities/modules/workorder-documents';

export interface EmergencyRescuePlansSearchPanel {
    defaultValue?: SearchValue;
    isElevatorDetail: boolean;
}

const defaultSearch = {};

export const EmergencyRescuePlansSearchPanel = ({
    defaultValue = defaultSearch,
    isElevatorDetail,
}: EmergencyRescuePlansSearchPanel) => {
    const intl = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    return (
        <TableFilterPanel>
            <TableSearch
                fields={[
                    ...(isElevatorDetail
                        ? []
                        : [
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.factoryNumber`} />,
                                  labelFor: 'factoryNumber',
                                  renderer: (
                                      <Input
                                          id="factoryNumber"
                                          data-testid="factory-number"
                                          value={value[WorkorderField.ELEVATOR_FACTORY_NUMNER]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_FACTORY_NUMNER]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.street`} />,
                                  labelFor: 'street',
                                  renderer: (
                                      <Input
                                          id="street"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_STREET]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_STREET]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.city`} />,
                                  labelFor: 'city',
                                  renderer: (
                                      <Input
                                          id="city"
                                          data-testid="city"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_CITY]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_CITY]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.businessUnit`} />,
                                  labelFor: 'businessUnit',
                                  renderer: (
                                      <Input
                                          id="businessUnit"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                          ]),

                    {
                        label: <FormattedMessage id={`documents.workOrder.search.inspectionDate`} />,
                        labelFor: 'inspectionDate',
                        renderer: (
                            <TableSearchDateRange
                                id="inspectionDate"
                                value={{
                                    from: value[WorkorderField.INSPECTION_DATE_FROM],
                                    to: value[WorkorderField.INSPECTION_DATE_TO],
                                }}
                                onChange={(from, to) => {
                                    handleValue({
                                        [WorkorderField.INSPECTION_DATE_FROM]: from,
                                        [WorkorderField.INSPECTION_DATE_TO]: to,
                                    });
                                }}
                            />
                        ),
                    },
                ]}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                rowGutter={[16, 16]}
                colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
            />
        </TableFilterPanel>
    );
};
