import type { TRuleWithTheme } from 'styles/theme';

export const dragger: TRuleWithTheme<{
    disabled: boolean;
}> = ({ disabled }) => ({
    '&.ant-upload.ant-upload-drag': {
        marginBottom: '1.5rem',

        borderRadius: 4,

        background: 'transparent',
    },

    '&.ant-upload.ant-upload-drag .ant-upload': {
        height: '4.5rem',

        padding: '1.25rem',

        cursor: disabled ? 'default' : undefined,
    },
});

export const icon: TRuleWithTheme = () => ({
    marginRight: '0.5rem',
    verticalAlign: 'middle',
});

export const text: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1rem',
    lineHeight: 1.5,

    verticalAlign: 'middle',

    color: theme.colors.textSecondary,
});

export const span: TRuleWithTheme<{ disabled: boolean }> = ({ theme, disabled }) => ({
    color: disabled ? theme.colors.textSecondary : theme.colors.primary,

    textDecoration: 'underline',
});
