import { put, takeEvery } from 'redux-saga/effects';
import { inviteUsersRequest, inviteUsersRequestSuccess, inviteUsersRequestFailure } from '../actions';
import config from 'config';
import { authApi } from 'config/antonio';

function* inviteUsersRequestHandler(action: ReturnType<typeof inviteUsersRequest>) {
    const res = yield* authApi.post(`${config.api.users}`, action.payload);
    
    if(res.data.error) {
        yield put(inviteUsersRequestFailure(res.data.error));
    } else {
        yield put(inviteUsersRequestSuccess());    
    } 
}

export function* sendInviteRequest() {
    yield takeEvery(inviteUsersRequest.toString(), inviteUsersRequestHandler);
}
