import { put, cancelled } from 'redux-saga/effects';

import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import { Incident } from '../../types';
import { fetchIncidentsTypes, fetchIncidents as actions } from '../actions';

function* fetchIncidents() {
    const controller = new AbortController();

    try {
        const { data } = yield* authApi.get<Incident[]>(config.api.incidents, {
            signal: controller.signal,
        });
        yield put(actions.fetchIncidentsSuccess(data));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchIncidentsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchIncidentsRequest',
            leading: true,
        },
        {
            pattern: fetchIncidentsTypes.FETCH_INCIDENTS_REQUEST,
            handler: fetchIncidents,
        },
        {
            pattern: fetchIncidentsTypes.FETCH_INCIDENTS_CANCEL,
            handler: noop,
        },
    );
}
