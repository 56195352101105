import { noop } from 'lodash';
import { put } from 'redux-saga/effects';

import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';
import { authApi } from 'config/antonio';
import config from 'config';

import { fetchIotConfigTypes, fetchIotConfig as actions } from '../actions';

type AxiosData = { [key: string]: any }[];

function* fetchIotConfig() {
    try {
        const { data } = yield* authApi.get<AxiosData>(config.api.iotConfig);
        yield put(actions.fetchIotConfigSuccess(data));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchIotConfigFailure(uiError));
    }
}
export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchIotConfig',
        },
        {
            pattern: fetchIotConfigTypes.FETCH_IOT_CONFIG_REQUEST,
            handler: fetchIotConfig,
        },
        {
            pattern: fetchIotConfigTypes.FETCH_IOT_CONFIG_CANCEL,
            handler: noop,
        },
    );
}
