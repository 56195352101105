import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import {
    TableSearch as Search,
    TableSearchDateRange,
    TableSearchSelect,
    SearchField,
    useTableSearch,
} from 'modules/table';
import { selectors } from 'modules/entities/modules/other-documents';

export type TableSearchProps = {
    defaultValue: SearchValue;
    isElevatorDetail: boolean;
};

const TableSearch: FC<TableSearchProps> = ({ defaultValue, isElevatorDetail }) => {
    const typeSearchOptions = useSelector(selectors.selectOtherDocumentTypeSearchOptions);

    const intl = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const fields: SearchField[] = [
        ...(isElevatorDetail
            ? []
            : [
                  {
                      label: <FormattedMessage id={`documents.otherDocuments.table.factoryNumber`} />,
                      renderer: (
                          <Input
                              data-testid="factory-number"
                              value={value['elevators.factoryNumber']}
                              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'elevators.factoryNumber': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`documents.otherDocuments.table.street`} />,
                      renderer: (
                          <Input
                              value={value['elevators.propertyUnit.street']}
                              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'elevators.propertyUnit.street': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`documents.otherDocuments.table.city`} />,
                      renderer: (
                          <Input
                              data-testid="city"
                              value={value['elevators.propertyUnit.city']}
                              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'elevators.propertyUnit.city': target.value,
                                  });
                              }}
                          />
                      ),
                  },
                  {
                      label: <FormattedMessage id={`documents.otherDocuments.table.businessUnit`} />,
                      renderer: (
                          <Input
                              value={value['elevators.propertyUnit.businessUnit']}
                              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                              onChange={({ target }) => {
                                  handleValue({
                                      'elevators.propertyUnit.businessUnit': target.value,
                                  });
                              }}
                          />
                      ),
                  },
              ]),

        {
            label: <FormattedMessage id={`documents.otherDocuments.table.documentType`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                    options={typeSearchOptions}
                    value={value['documentType']}
                    onChange={value => {
                        handleValue({
                            documentType: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.otherDocuments.table.documentDate`} />,
            renderer: (
                <TableSearchDateRange
                    value={{
                        from: value['documentDate.from'],
                        to: value['documentDate.to'],
                    }}
                    onChange={(from, to) => {
                        handleValue({
                            'documentDate.from': from,
                            'documentDate.to': to,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.otherDocuments.table.sendBy`} />,
            renderer: (
                <Input
                    value={value['sendBy']}
                    placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                    onChange={({ target }) => {
                        handleValue({
                            sendBy: target.value,
                        });
                    }}
                />
            ),
        },
    ];

    return (
        <Search
            fields={fields}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
        />
    );
};

export default TableSearch;
