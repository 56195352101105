import type { TRuleWithTheme } from 'styles/theme';

import bg from 'assets/images/upload_info_bg.svg';

export const container: TRuleWithTheme = ({ theme }) => ({
    paddingTop: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2.5rem',
    paddingLeft: '2rem',

    marginBottom: '1rem',

    backgroundColor: theme.colors.white,
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 0',
    backgroundSize: 'contain',

    borderRadius: 2,
});

export const title: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: '1em',

        fontSize: '1.5rem',
        lineHeight: '1.33em',

        color: theme.colors.simplifa2,
    },
});

export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: '2.5em',
    },
});

export const list: TRuleWithTheme = () => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '1rem',

    padding: '0',

    listStyle: 'none',
});

export const listItem: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    overflow: 'hidden',

    fontSize: '0.875rem',
    lineHeight: '1.43em',

    textAlign: 'center',
});
