import React from 'react';
import type { ReactNode } from 'react';
import ReactGA from 'react-ga4';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { Icon, IconType } from 'modules/ui';

import { DialogState, eventMap } from '../../constants';

import { useNewElevatorDialog } from '../../hooks/useNewElevatorDialog';

import * as felaRules from './NewElevatorDialogUploadSelectionItem.rules';

export interface NewElevatorDialogUploadSelectionItemProps {
    state: DialogState;
    iconType: IconType;
    title: ReactNode;
    description: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

export const NewElevatorDialogUploadSelectionItem = ({
    state,
    iconType,
    title,
    description,
}: NewElevatorDialogUploadSelectionItemProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const { navigate } = useNewElevatorDialog();

    return (
        <button
            className={styles.button}
            onClick={() => {
                navigate(state);

                ReactGA.event({
                    category: eventMap[state],
                    action: 'click',
                });
            }}
        >
            <Icon type={iconType} className={styles.icon} />
            <Typography.Text className={styles.title}>{title}</Typography.Text>
            <Typography.Text type="secondary" className={styles.desc}>
                {description}
            </Typography.Text>
        </button>
    );
};
