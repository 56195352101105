import { useSelector } from 'react-redux';

import { useFetchAverageTrips } from 'modules/entities/modules/trips';
import { selectAverageTrips } from '../services/selectors';

const useAverageTrips = ({ startTime, endTime }: { startTime: string; endTime: string }) => {
    const api = useFetchAverageTrips(startTime, endTime);
    const trips = useSelector(selectAverageTrips);

    return { trips, ...api };
};

export default useAverageTrips;
