import type { TRule } from 'fela';

export const title: TRule = () => ({
    fontSize: '1.5rem',
});

export const header: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const teamPage: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
});
