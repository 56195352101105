import React from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './TemperatureTag.rules';
import { Typography } from 'antd';
import { FormattedNumber } from 'react-intl';

export interface TemperatureTagProps {
    temperature: number;
}

export const TemperatureTag = ({ temperature }: TemperatureTagProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    function calc(temp: number) {
        return parseFloat(temp.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]);
    }

    return (
        <Typography.Text className={styles.tag}>
            <FormattedNumber
                value={temperature ? calc(temperature) : 0}
                // eslint-disable-next-line react/style-prop-object
                style="unit"
                unit="celsius"
            />
        </Typography.Text>
    );
};
