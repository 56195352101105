import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { ContractState, selectors } from 'modules/entities/modules/documents-contracts';
import { useSearchParams } from 'services/hooks';
import { manipulateQueryString } from 'services/utils';

import { UrlParam } from 'constants/index';

const useContractListStatusFilter = () => {
    const countByState = useSelector(selectors.selectCountContractsByState);
    const location = useLocation();
    const history = useHistory();

    const filterParams = useSearchParams(UrlParam.FILTER) as string[];

    useEffect(() => {
        if (!filterParams)
            history.push({
                search: manipulateQueryString(
                    [ContractState.CANCELATION_5_MONTHS, ContractState.CANCELED, ContractState.ACTIVE],
                    UrlParam.FILTER,
                    location.search,
                    true,
                ),
            });
    }, [filterParams, history, location]);

    const onCheckboxChange = e => {
        const { value } = e.target;

        const finalSearch =
            value === ContractState.SHOW_ALL
                ? manipulateQueryString(ContractState.SHOW_ALL, UrlParam.FILTER, location.search)
                : manipulateQueryString(
                      value,
                      UrlParam.FILTER,
                      location.search,
                      !filterParams?.includes(ContractState.SHOW_ALL),
                  );

        history.push({ search: finalSearch });
    };

    return { onCheckboxChange, countByState, filterParams };
};
export default useContractListStatusFilter;
