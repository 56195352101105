import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { OtherDocument } from 'modules/entities/modules/other-documents';

import ItemActions from '../../ItemActions';
import * as felaRules from './OtherDocumentItemInfo.styles';

interface OtherDocumentItemInfo {
    document: OtherDocument;
}

const OtherDocItemInfo: FC<OtherDocumentItemInfo> = ({ document }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ItemActions id={document.id} file={document.file} />
            </div>
        </div>
    );
};

export default OtherDocItemInfo;
