import type { ThemeProps, TRuleWithTheme } from 'styles/theme';
import { combineRules, TRule } from 'fela';

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    marginBottom: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 500,
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontWeight: 500,

    '& .anticon': {
        color: theme.colors.text,
    },
});

export const container: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.25rem',
    minHeight: '2rem',
    ':last-of-type': {
        marginBottom: 0,
    },
});

export const titleContainer: TRule = () => ({
    display: 'flex !important',
    flexDirection: 'column',
    minHeight: '4rem',
    justifyContent: 'end',
    ':last-of-type': {
        marginBottom: 0,
    },
});

export const iconContainer: TRule = () => ({
    display: 'inline-flex',
    marginLeft: '.5rem',
    marginTop: '-.2rem',
    position: 'absolute'
});
export const iconContainerValue: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    marginLeft: '.5rem',
    marginTop: '-.2rem',
    position: 'relative',
    '& span': {
        color: `rgb(166, 166, 166) !important` ,
    }
});

const paragraph: TRule = () => ({
    lineHeight: '1.5rem',
    fontWeight: 500,

    '&.ant-typography': {
        marginBottom: '0.25rem',
    },
});


export const links: TRule = combineRules<ThemeProps, ThemeProps>(paragraph, () => ({
    fontWeight: 700,
    textDecorationLine:"underline",
    textDecorationThickness: '0.1rem',
    ':hover': {
        color: '#f57645',
    },
}));