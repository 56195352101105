import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
});

export const icon: TRuleWithTheme = () => ({
    fontSize: '1rem',
    marginRight: '.5rem',

    '& svg': {
        height: '.875rem',
        width: '.875rem',
    },
});
