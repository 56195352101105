import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { TableDataSpace } from 'modules/table';

import { Paragraph } from 'modules/ui';
import { Workorder } from 'modules/entities/modules/workorder-documents';

import ItemActions from '../../ItemActions';
import { DocumentElevator } from '../../DocumentElevator';
import { DocumentsTable } from '../../DocumentsTable';

import * as felaRules from './ConditionReportsTable.rule';
import { useRouteMatch } from 'react-router-dom';
export interface ConditionReportsTableProps {
    loading: boolean;
    documents: Workorder[];
}

export const ConditionReportsTable = ({ loading, documents }: ConditionReportsTableProps) => {
    const { rules } = useFelaEnhanced(felaRules);
    const match = useRouteMatch('/elevators/:id');

    return (
        <DocumentsTable<Workorder>
            columns={[
                ...[
                    match?.isExact
                        ? {}
                        : {
                              key: 'elevators.elevatorId',
                              dataIndex: 'elevators',
                              width: '40%',
                              render: (_, record) => <DocumentElevator elevator={record.elevator} />,
                          },
                ],
                {
                    key: 'inspectionDate',
                    width: '20%',
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.workOrder.table.inspectionDate" />}
                            value={<FormattedDate value={record.inspectionDate} />}
                        />
                    ),
                },
                {
                    key: 'inspectionRecommendation',
                    width: match?.isExact? '70%': '30%',
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.workOrder.table.inspectionRecommendation" />}
                            value={
                                <>
                                    {record.inspectionRecommendation.map(recommendation => (
                                        <Paragraph
                                            extend={{
                                                paragraph: rules.inspectionRecommendation,
                                            }}
                                        >
                                            {recommendation}
                                        </Paragraph>
                                    ))}
                                </>
                            }
                            extend={{
                                value: rules.inspectionRecommendations,
                            }}
                        />
                    ),
                },
                {
                    key: 'id',
                    width: '10%',
                    render: (_, record) => <ItemActions id={record.id} file={record.file} />,
                },
            ]}
            loading={loading}
            dataSource={documents}
            data-testid="condition-reports-table"
        />
    );
};
