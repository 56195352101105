import { createAction } from '@reduxjs/toolkit';

import { INVITE_USERS_STATE } from '../../types';

export const inviteUsersRequest = createAction(`${INVITE_USERS_STATE}/SEND`, ({ users, email }) => ({
    payload: { users },
    meta: {},
}));

export const inviteUsersRequestSuccess = createAction(
    `${INVITE_USERS_STATE}/SEND_SUCCESS`,
    (lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            lastSuccessAt,
        },
    }),
);

export const inviteUsersRequestFailure = createAction(`${INVITE_USERS_STATE}/SEND_FAILURE`, (error: any) => ({
    payload: null,
    error,
    meta: {},
}));

export const inviteUsersRequestReset = createAction(`${INVITE_USERS_STATE}/SEND_RESET`, () => ({
    payload: null,
    meta: {},
}));
