import React from 'react';
import type { ReactNode } from 'react';
import { Button, Typography } from 'antd';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';

import { DialogState } from '../../constants';
import { useNewElevatorDialog } from '../../hooks/useNewElevatorDialog';

import * as felaRules from './NewElevatorDialogContent.rules';

export interface NewElevatorDialogContentProps {
    goBack?: boolean;
    title: ReactNode;
    children: ReactNode;
    'data-testid': string;
}

export const NewElevatorDialogContent = ({
    goBack = false,
    title,
    children,
    'data-testid': testId,
}: NewElevatorDialogContentProps) => {
    const { styles } = useFelaEnhanced(felaRules, { goBack });

    const intl = useIntl();

    const {
        navigate,
        upload: { api },
    } = useNewElevatorDialog();

    return (
        <div data-testid={testId}>
            <div className={styles.header}>
                {goBack ? (
                    <Button
                        aria-label={intl.formatMessage({
                            id: 'elevator.new.dialog.goBack',
                        })}
                        disabled={api.inProgress}
                        icon={<Icon type={IconType.BACK} className={styles.icon} />}
                        onClick={() => {
                            navigate(DialogState.SELECTION);
                        }}
                        className={styles.button}
                    />
                ) : null}
                <Typography.Title level={2} className={styles.title}>
                    {title}
                </Typography.Title>
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
