import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const title: TRuleWithTheme = ({ theme }) => {
    return {
        color: theme.colors.textSecondary,
        marginBottom: '0.5rem',
        fontSize: '0.75rem',
        fontWeight: 500,
    };
};
export const flagTitle: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: theme.colors.text,
    },
});

export const flagValue: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.13rem',
    alignItems: 'center',
    '& .anticon': {
        color: `${theme.colors.textSecondary} !important`,
    },
});

export const link: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    textDecoration: 'underline',
    ':hover': {
        color: theme.colors.text,
        textDecoration: 'underline',
    },
    ':focus': {
        color: theme.colors.text,
        textDecoration: 'underline',
    },
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: `${theme.colors.textSecondary} !important`,
    },
});
export const company: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '12.5rem',
});

export const flag: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    // position: 'absolute',
    zIndex: 2,
    right: 0,
    marginRight: '1.5rem',
    backgroundColor: '#F6F7F9',
    paddingLeft: '1rem',
    paddingRight: '0.94rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.63rem',
});
export const flagCol: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '120px',
});
export const flagIcon: TRule = () => ({
    color: '#A6A6A6',
    marginRight: '.46rem',

    '& svg': {
        height: '0.75rem',
        width: '0.75rem',
    },
});
export const flagColRight: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    borderLeft: 'solid 1px #EBEBEB',
    paddingLeft: '1rem',
    marginLeft: '0.88rem',
    minWidth: '120px',
});
