import { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const paragraph: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '1rem'
});
