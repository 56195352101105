import React from 'react';

export const DocumentSmall = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
            <path d="m13.71 6.29-6-6A1 1 0 0 0 7 0H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-.29-.71ZM8 3.41 10.59 6H8V3.41ZM4 14V2h2v5a1 1 0 0 0 1 1h5v6H4Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
