import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import { useSelector } from 'react-redux';
import { Drawer, DrawerProps, Typography } from 'antd';

import { Icon, IconType } from 'modules/ui';

import { selectContactData } from '../../services/selectors';
import ContactDataCard from './ContactDataCard';
import * as felaRules from './ContactData.styles';

const ContactData: FC<DrawerProps> = ({ ...rest }) => {
    const { styles, theme } = useFelaEnhanced(felaRules);
    const { factoryNumber, contacts } = useSelector(selectContactData);

    return (
        <Drawer
            title={
                <Typography.Title level={4} className={styles.heading}>
                    <FormattedMessage id="page.elevatorDetail.contacts" />
                </Typography.Title>
            }
            className={styles.container}
            closeIcon={<Icon className={styles.closeIcon} type={IconType.CLOSE} />}
            closable
            mask={false}
            width={theme.width.drawer}
            {...rest}
        >
            {contacts.map((contact, index) => (
                <ContactDataCard key={index} contact={contact} factoryNumber={factoryNumber} />
            ))}
        </Drawer>
    );
};

export default ContactData;
