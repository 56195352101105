import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const OperatorObligationsDate = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.5 7H31V5.5a.5.5 0 0 0-1 0V7H10V5.5a.5.5 0 1 0-1 0V7H5.5a.5.5 0 0 0-.5.5v25a.5.5 0 0 0 .5.5h29a.5.5 0 0 0 .5-.5v-25a.5.5 0 0 0-.5-.5ZM34 32H6V8h28v24Z"
            fill="#fff"
        />
        <path
            d="M21.5 15h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM29.5 15h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM13.5 20h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM21.5 20h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM29.5 20h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM13.5 25h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM21.5 25h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM29.5 25h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1ZM14.15 12.149l-3.65 3.64-1.65-1.64a.495.495 0 0 0-.7.7l2 2a.48.48 0 0 0 .7 0l4-4a.496.496 0 0 0-.35-.845.495.495 0 0 0-.35.145Z"
            fill="#fff"
        />
    </svg>
);
