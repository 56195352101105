import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import config from 'config';
import * as log from 'config/loglevel';
import { sentryReduxEnhancer } from 'config/sentry';

import { routerMiddlewareWithHistory } from 'modules/core/modules/router';

export default function configureEnhancer() {
    const sagaMiddleware = createSagaMiddleware({
        onError: log.error,
    });

    const middlewares = [routerMiddlewareWithHistory, sagaMiddleware];
    const enhancerArgs = [applyMiddleware(...middlewares), sentryReduxEnhancer];

    if (config.devTools) {
        // eslint-disable-next-line
        const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
        if (reduxDevTools) {
            enhancerArgs.push(reduxDevTools());
        }
    }

    return {
        enhancer: compose(...enhancerArgs),
        sagaMiddleware,
    };
}
