import { noop, uniqueId } from 'lodash';
import { put } from 'redux-saga/effects';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';

import { fetchOtherDocumentsTypes, fetchOtherDocuments as actions } from '../actions';
import type { OtherDocument } from '../../types';

type ResponseData = { [key: string]: any }[];

const transformData = (data: ResponseData) =>
    data.map(doc => ({
        id: uniqueId('other-document'),
        ...doc,
    })) as OtherDocument[];

function* fetchOtherDocuments(action) {
    const { elevatorId } = action?.payload;
    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.otherDocuments);
        const t = transformData(data);
        if (elevatorId) {
            const filtedData = t.filter(tag => {
                return tag.elevators.some(item => {
                    return item.elevatorId === elevatorId;
                });
            });
            yield put(actions.fetchOtherDocumentsSuccess(filtedData));
            return;
        }
        yield put(actions.fetchOtherDocumentsSuccess(transformData(data)));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchOtherDocumentsFailure(uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchOtherDocuments',
        },
        {
            pattern: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_REQUEST,
            handler: fetchOtherDocuments,
        },
        {
            pattern: fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_CANCEL,
            handler: noop,
        },
    );
}
