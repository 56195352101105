import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';


export const requestTitle: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.black,
    marginBottom: '0.375rem',
    fontSize: '1rem',
    fontWeight: 700,
});

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    marginBottom: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 500,
});
export const flagTitle: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: theme.colors.text,
    },
});

export const flagValue: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    fontWeight: 500,

    '& .anticon': {
        color: `${theme.colors.textSecondary} !important`,
    },
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: `${theme.colors.textSecondary} !important`,
    },
});
export const company: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '12.5rem',
});

export const container: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.25rem',
    paddingTop: '1.5rem',
    minHeight: '10.44rem',
    ':last-of-type': {
        marginBottom: 0,
    },
    '&:hover': {
        cursor: 'auto',
    },
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: '#262626',
    background: '#fff',
    borderRadius: '2px',
});
export const subStatus: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
});
export const subStatusChange: TRule = () => ({
    marginLeft: '0.5rem',
    '& .ant-space-item': {
        textDecoration: 'underline',
    },
});

export const tag: TRuleWithTheme = ({theme}) => ({
    marginRight: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .ant-tag': {
        fontWeight: '500',
        color: theme.colors.text,
    },
    '& .anticon': {
        color: `${theme.colors.primary} !important`,
    },
});

export const iconContainer: TRule = () => ({
    marginRight: '.8rem',
    marginTop: '.2rem',
});
export const row: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '3.13rem',
});
export const rowtop: TRule = () => ({
 

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '1.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
});
export const rowBottom: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    // borderTop: 'solid 1px #EBEBEB'
});
export const divider: TRule = () => ({
    borderTop: 'solid 1px #EBEBEB',
});
export const topSelect: TRule = () => ({
    // marginBottom: '1.5rem',
    paddingLeft: '1.5rem',
    display: 'flex',
    flexDirection: 'row'
    
});

export const menu: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,
    margin: 0,

    ':hover': {
        background: 'none',
    },
    ':active': {
        background: 'none',
    },
    ':focus': {
        background: 'none',
    },
    '& .anticon': {
        marginRight: '1.25em',
    },
});
export const cell: TRule = () => ({
    width: '140px',
    marginRight: '1rem',
});
export const cellFlex: TRule = () => ({
    width: '70%',
    marginRight: '2rem',
    display: 'block'
});
export const desc: TRule = () => ({
    width: '850px',
    marginRight: '2rem',
    display: 'block',
    whiteSpace: 'pre-line'
});
export const flag: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    marginRight: '1.44rem',
    backgroundColor: '#F6F7F9',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
});
export const flagCol: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
});
export const flagIcon: TRule = () => ({
    color: '#A6A6A6',
    marginRight: '.46rem',
    marginTop: '.23rem',

    '& svg': {
        height: '0.75rem',
        width: '0.75rem',
    },
});
export const flagColRight: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    borderLeft: 'solid 1px #EBEBEB',
    paddingLeft: '1rem',
    marginLeft: '0.88rem',
});
export const factoryNumber: TRule =() => ({
    textDecorationLine:"underline",
    textDecorationThickness: '0.1rem',
    ':hover': {
        color: '#f57645',
    },
})
