import type { TRuleWithTheme } from 'styles/theme';
import type { DetailedStatusAlertProps } from './DetailedStatusAlert';
import type { TRule } from 'fela';

type StyleProps = Required<Pick<DetailedStatusAlertProps, 'layout'>>;

export const alert: TRuleWithTheme<StyleProps> = ({ theme, layout }) => {
    const isHorizontal = layout === 'horizontal';

    return {
        alignItems: 'flex-start',
        padding: '1.25em 0',

        backgroundColor: theme.colors.white,
        borderColor: theme.colors.dividerColor,
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',

        '& .ant-alert-content': {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '1.125em',
        },

        '& .ant-alert-message': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },

        '& .anticon': {
            marginTop: isHorizontal ? 0 : 2,
        },

        '& .ant-alert-description': {
            color: theme.colors.textSecondary,
            fontSize: '.875rem',
        },

        desktop: {
            '& .ant-alert-content': {
                flexDirection: isHorizontal ? 'row' : 'column',
            },

            '& .ant-alert-message': {
                flexDirection: isHorizontal ? 'row' : 'column',
                alignItems: isHorizontal ? 'center' : 'flex-start',
            },

            '& .anticon': {
                marginTop: isHorizontal ? 0 : 2,
            },

            '& .ant-alert-description': {
                color: theme.colors.textSecondary,
            },
        },
    };
};

export const title: TRuleWithTheme<StyleProps> = ({ layout }) => ({
    fontWeight: 600,
    fontSize: '1rem',
    marginBottom: 0,

    desktop: {
        marginLeft: layout === 'horizontal' ? '5rem' : 0,
    },
});

export const date: TRuleWithTheme<StyleProps> = ({ theme, layout }) => ({
    width: 70,
    fontSize: '0.75rem',
    color: theme.colors.textSecondary,
    marginBottom: '0.185rem',

    desktop: {
        marginBottom: layout === 'vertical' ? '0.185rem' : 0,
    },
});

export const descriptionWrapper: TRule = () => ({
    marginTop: '.25rem',

    '& .ant-typography': {
        margin: '0 0 .25rem',
    },
});
