import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';
import { createFetchFailureAction, createFetchSuccessAction } from 'services/utils/actions';

import type { AssessmentReport } from '../types';

export const fetchAssessmentReports = createAction(
    `${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/FETCH_ASSESSMENT_REPORTS`,
    ({ elevatorId }: { elevatorId: string }) => ({
        meta: {
            elevatorId,
        },
        payload: null,
    }),
);
export const fetchAssessmentReportsRequest = createAction(
    `${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/FETCH_ASSESSMENT_REPORTS_REQUEST`,
);
export const fetchAssessmentReportsSuccess = createFetchSuccessAction<AssessmentReport>(
    `${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/FETCH_ASSESSMENT_REPORTS_SUCCESS`,
);
export const fetchAssessmentReportsFailure = createFetchFailureAction(
    `${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/FETCH_ASSESSMENT_REPORTS_FAILURE`,
);
export const clearAssessmentReports = createAction(`${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/CLEAR_ASSESSMENT_REPORTS`);
export const resetAssessmentReports = createAction(`${EntityKey.DOCUMENT_ASSESSMENT_REPORTS}/RESET_QUOTATIONS`);
