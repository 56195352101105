import React from 'react';

export const Message = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V21C2.00099 21.1974 2.06039 21.3901 2.17072 21.5539C2.28105 21.7176 2.43738 21.845 2.62 21.92C2.73868 21.976 2.86882 22.0034 3 22C3.13161 22.0008 3.26207 21.9755 3.38391 21.9258C3.50574 21.876 3.61656 21.8027 3.71 21.71L8.41 17H21C21.2652 17 21.5196 16.8946 21.7071 16.7071C21.8946 16.5196 22 16.2652 22 16V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM20 15H8C7.86839 14.9992 7.73793 15.0245 7.61609 15.0742C7.49426 15.124 7.38344 15.1973 7.29 15.29L4 18.59V4H20V15Z"
            fill="#A6A6A6"
        />
        <path
            d="M7.5 11C8.32843 11 9 10.3284 9 9.5C9 8.67157 8.32843 8 7.5 8C6.67157 8 6 8.67157 6 9.5C6 10.3284 6.67157 11 7.5 11Z"
            fill="#A6A6A6"
        />
        <path
            d="M12.5 11C13.3284 11 14 10.3284 14 9.5C14 8.67157 13.3284 8 12.5 8C11.6716 8 11 8.67157 11 9.5C11 10.3284 11.6716 11 12.5 11Z"
            fill="#A6A6A6"
        />
        <path
            d="M17.5 11C18.3284 11 19 10.3284 19 9.5C19 8.67157 18.3284 8 17.5 8C16.6716 8 16 8.67157 16 9.5C16 10.3284 16.6716 11 17.5 11Z"
            fill="#A6A6A6"
        />
    </svg>
);
