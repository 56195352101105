import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'block',

    width: 340,

    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.138565)',
    borderRadius: 2,
    fontWeight: 400,
});

export const header: TRuleWithTheme = ({ theme }) => ({
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 16,
    paddingRight: 16,

    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.375,

    color: theme.colors.text,
});

export const row: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily:'WorkSans',
    padding: 15,

    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.dividerColor,
});
export const status: TRuleWithTheme = () => ({});
export const elevatorInfo: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    marginLeft: 12,
    marginRight: 12,
});
export const id: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '0.25rem',

    fontWeight: 700,
    fontSize: 16,

    color: theme.colors.text,
});
export const owner: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '0.25rem',

    fontWeight: 500,
    fontSize: '0.875rem',

    color: theme.colors.text,
});
export const address: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '0.5rem',

    fontWeight: 500,
    fontSize: '0.875rem',
    color: theme.colors.textSecondary,
});
