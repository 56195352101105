import { apiSelector } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey } from 'constants/index';
import type { TokenPaginationApiReducerState } from 'modules/entities/services/reducers/tokenPaginationApi';
import type { Elevator } from 'modules/entities/modules/elevators';

export const selectElevatorStatesApi = (state): TokenPaginationApiReducerState =>
    apiSelector(state, EntityKey.ELEVATOR_STATES, ApiReducerKey.LIST);

export const selectElevatorStatesEntityList = (state): Elevator['state'][] =>
    state.entities[EntityKey.ELEVATOR_STATES][ApiReducerKey.LIST];
