import React, { FC } from 'react';

import { TableFilterPanel } from 'modules/table';

import { TableSearchProps } from './TableSearch';
import { IncidentsSearch } from '../IncidentsSearch';

type FilterPanelProps = {
    defaultSearch: TableSearchProps['defaultValue'];
    loading?: boolean;
    isClosed?: boolean;
};

const FilterPanel: FC<FilterPanelProps> = ({ defaultSearch, loading, isClosed }) => (
    <TableFilterPanel>
        <IncidentsSearch loading={loading} defaultValue={defaultSearch} isClosed={isClosed} />
    </TableFilterPanel>
);

export default FilterPanel;
