import { TRuleWithTheme } from 'styles/theme';

export const description: TRuleWithTheme = ({ theme }) => ({
    padding: '2rem',

    color: theme.colors.text,
    backgroundColor: theme.colors.white,

    borderRadius: 2,
});
