import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '@media (min-width: 992px)': {
        '& .ant-col-lg-5': {
            display: 'block',
            flex: '0 0 19.83333333%',
            maxwidth: '20.83333333%',
        },
    },
});

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '1em',

        fontSize: '1.5rem',
        lineHeight: 1.33,
    },
});