import React from 'react';

import { URL_ARRAY_SEPARATOR } from 'constants/index';

import { Select, SelectProps } from 'modules/form';

export interface TableSearchSelectProps extends Omit<SelectProps, 'maxTagCount' | 'fullWidth' | 'onChange'> {
    onChange: (value: string) => void;
}

export const TableSearchSelect = ({ value, mode, onChange, ...props }: TableSearchSelectProps) => (
    <Select
        {...props}
        mode={mode}
        maxTagCount="responsive"
        fullWidth
        value={mode === 'multiple' ? (((value ? value.split(URL_ARRAY_SEPARATOR) : []) as unknown) as string) : value}
        onChange={value => {
            onChange(mode === 'multiple' ? ((value as unknown) as string[]).join(URL_ARRAY_SEPARATOR) : value);
        }}
    />
);
