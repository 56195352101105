import React from 'react';

import type { Theme } from 'styles/theme';

import { QuotationStatus } from 'modules/entities/modules/documents-quotations';

import { DocumentStatusIcon, DocumentStatusIconProps } from '../../DocumentStatusIcon';

export interface QuotationStatusIconProps extends Pick<DocumentStatusIconProps<QuotationStatus>, 'status' | 'small'> {}

const colorMap = (theme: Theme) => ({
    [QuotationStatus.DATA_CAPTURE]: theme.colors.statusDataCapture,
    [QuotationStatus.IN_PROGRESS]: theme.colors.statusInProgress,
    [QuotationStatus.DONE]: theme.colors.statusDone,
});

const getColor = (theme: Theme, status: QuotationStatus) => colorMap(theme)[status] ?? theme.colors.icon;

export const QuotationStatusIcon = ({ status, small }: QuotationStatusIconProps) => (
    <DocumentStatusIcon<QuotationStatus> status={status} small={small} getColor={getColor} />
);
