import { TRuleWithTheme } from 'styles/theme';

export const loaderContainer: TRuleWithTheme<{
    dimensions: { width: number; height: number; y: number; x: number };
    show: boolean;
}> = ({ dimensions, show }) => ({
    position: 'absolute',
    extend: {
        condition: show,
        style: {
            width: dimensions?.width,
            height: dimensions?.height,
            left: 0,
            top: dimensions?.y,
        },
    },
});

export const loader: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.onHover,
});
