import * as types from './types';
import { FormSubmissionError } from '../../types';

export const registerForm = (form: string): types.RegisterFormAction => ({
    type: types.REGISTER_FORM,
    meta: {
        form,
    },
});

export const unregisterForm = (form: string): types.UnregisterFormAction => ({
    type: types.UNREGISTER_FORM,
    meta: {
        form,
    },
});

export const submitForm = <FormValues>(
    form: string,
    values: FormValues,
    meta?: any,
): types.SubmitFormAction<FormValues> => ({
    type: types.SUBMIT_FORM,
    meta: {
        form,
        meta,
    },
    payload: values,
});

export const submitFormSuccess = (form: string, payload?: any, meta?: any): types.SubmitFormSuccesAction => ({
    type: types.SUBMIT_FORM_SUCCESS,
    meta: {
        form,
        meta,
    },
    payload,
});

export const submitFormFailure = (
    form: string,
    error: FormSubmissionError,
    meta?: any,
): types.SubmitFormFailureAction => ({
    type: types.SUBMIT_FORM_FAILURE,
    meta: {
        form,
        meta,
    },
    payload: error,
});
