import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

type SeoHeadProps = {
    title?: string;
};

const SeoHead: FC<SeoHeadProps> = ({ title }) => {
    const appName = useIntl().formatMessage({ id: 'app.name' });
    const fullTitle = title ? `${title} - ${appName}` : appName;

    return (
        <Helmet>
            <title>{fullTitle}</title>
        </Helmet>
    );
};

export default SeoHead;
