import type { TRule } from 'fela';
import { combineRules } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const newCard = () => ({
    '& .ant-card-body': {
        paddingLeft: '1.85rem',
        paddingRight: '1.85rem',
        position: 'relative',
    },
    '@media (max-width: 1720px)': {
        '& .ant-card-head': {
            '& > .ant-card-head-wrapper': {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },

            '& .ant-card-head-title': {
                paddingBottom: 0,
            },

            '& .ant-card-extra': {
                paddingTop: 0,
                float: 'unset',
                marginLeft: '0',
                width: '100%',
            },
        },
    },
});

export const chevron: TRule = () => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '& svg': {
        fontSize: '1.5rem',
    },
    cursor: 'pointer',
    padding: 0,
});

export const chevronLeftButton: TRule = combineRules<{}, {}>(chevron, () => ({
    left: '.5rem',
}));

export const chevronLeft: TRuleWithTheme<{ page: number }> = ({ page, theme }) => ({
    color: page === 0 ? theme.colors.iconDisabled : theme.colors.icon,
});

export const chevronRightButton: TRule = combineRules<{}, {}>(chevron, () => ({
    right: '.5rem',
}));

export const chevronRight: TRuleWithTheme<{ page: number; pageTotal: number }> = ({ theme, page, pageTotal }) => ({
    color: page === pageTotal - 1 || pageTotal === 1 ? theme.colors.iconDisabled : theme.colors.icon,
});

export const headerWrapper: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .ant-btn-link:last-of-type': {
        marginRight: '1.5rem',
    },

    '& .ant-picker:not(.ant-picker-range) .ant-picker-input .ant-picker-suffix': {
        marginLeft: '5.5625rem',
    },

    '@media (max-width: 1720px)': {
        '& .ant-btn-link:first-of-type': {
            marginLeft: 0,
        },
    },
});

export const link: TRuleWithTheme = ({ theme }) => ({
    margin: '0 .5rem',
    fontSize: '.875rem',
    padding: '0',

    '&:hover': {
        color: theme.colors.primary,
    },
});
