import { Modal } from 'antd';
import { TeamDialogState } from 'modules/teams/context/TeamDialogContext';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import React from 'react';
import Notifications from '../Notifications';
import ChangeRole from '../ChangeRole';
import DeactivateActivateUser from '../DeactivateActivateUser';
import InviteUser from '../InviteUser';

import * as felaRules from './TeamDialog.styles';
import { useFelaEnhanced } from 'hooks';
import AssignElevators from '../AssignElevators';

const TeamDialog = () => {
    const { state, setState, setIsDisabled } = useTeamDialog();
    const { styles } = useFelaEnhanced(felaRules);

    const handleClose = () => {
        setState(null);
        setIsDisabled(true);
    };

    return (
        <>
            {state && (
                <Modal
                    className={styles.modal}
                    visible={Boolean(state)}
                    width="690px"
                    onCancel={handleClose}
                    footer={[]}
                >
                    {state === TeamDialogState.NOTIFICATIONS ? <Notifications /> : null}
                    {state === TeamDialogState.CHANGE_ROLE ? <ChangeRole /> : null}
                    {state === TeamDialogState.DEACTIVATE_USER ? <DeactivateActivateUser /> : null}
                    {state === TeamDialogState.INVITE_USER ? <InviteUser /> : null}
                    {state === TeamDialogState.ASSIGN_ELEVATORS ? <AssignElevators /> : null}
                </Modal>
            )}
        </>
    );
};

export default TeamDialog;
