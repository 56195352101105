import React, { FC, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useFormSubmission } from 'modules/form';
import { useUIMessage } from 'modules/ui';
import config from 'config';

import { fields, forms } from '../../config';
import { ConfirmPasswordFormValues } from '../../types';
import AuthForm, { SubmitAuthFormButton } from '../AuthForm';
import { isFormSubmissionError } from '../../utils';
import { USER_EMAIL_STORAGE_KEY, USER_TEMP_PASSWORD } from '../../constants';
import { UrlParam } from 'constants/index';
import { useUserAuthentication } from 'modules/auth/hooks/useUserAuthentication';
import { success } from 'modules/fake-pages/components/FakeSuccess/FakeSuccess.rules';

type CreatePasswordFormProps = {};

const CreatePasswordForm: FC<CreatePasswordFormProps> = () => {
    const [temporalPassword, setTemporalPassword] = useState('');
    const { login, api } = useUserAuthentication();
    const message = useUIMessage();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();
    const { handleSubmit, submitting, error } = useFormSubmission<ConfirmPasswordFormValues>(forms.createPassword);

    const { search } = useLocation();

    useEffect(() => {
        if (!success && api.error) {
            history.push(config.routes.login);
        }
    }, [api, history]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        const tempPassword: string = params.get(UrlParam.TEMPORAl_PASSWORD);
        const userEmail: string = params.get(UrlParam.USER_EMAIL);

        const storedEmail = localStorage.getItem(USER_EMAIL_STORAGE_KEY);
        const storedPassword = localStorage.getItem(USER_TEMP_PASSWORD);

        if (!storedEmail && !userEmail) {
            history.push(config.routes.login);
        } else {
            if ((!storedEmail || storedEmail !== userEmail) && !!userEmail) localStorage.setItem(USER_EMAIL_STORAGE_KEY, userEmail);
            if (tempPassword) {
                login({ email: userEmail, password: tempPassword });
            }
            setTemporalPassword(tempPassword? tempPassword: storedPassword);
            message('info', 'auth.createPasswordMessage');
        }
    }, [message, history, search, login]);

    const handleOnSubmit = values => {
        const payload = { ...values, oldPassword: temporalPassword };
        handleSubmit(payload);
    };

    return (
        <AuthForm
            name={forms.createPassword}
            layout="vertical"
            form={form}
            title={formatMessage({ id: 'auth.createPasswordTitle' })}
            onFinish={handleOnSubmit}
        >
            {!temporalPassword && (
                <Form.Item
                    name={fields.confirmPassword.oldPassword}
                    label={formatMessage({ id: 'auth.oldPassword' })}
                    rules={[{ required: true, message: formatMessage({ id: 'form.validation.required.oldPassword' }) }]}
                    validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                    help={isFormSubmissionError(error) ? formatMessage({ id: error }) : undefined}
                >
                    <Input
                        type="password"
                        autoComplete="current-password"
                        size="large"
                        placeholder={formatMessage({ id: 'auth.oldPassword' })}
                    />
                </Form.Item>
            )}
            <Form.Item
                name={fields.confirmPassword.newPassword}
                label={formatMessage({ id: 'auth.newPassword' })}
                rules={[
                    { required: true, message: formatMessage({ id: 'form.validation.required.newPassword' }) },
                    {
                        pattern: new RegExp(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/),
                        message: formatMessage({ id: 'form.validation.password.specialCharacters' }),
                    },
                    {
                        pattern: /[0-9]/,
                        message: formatMessage({ id: 'form.validation.password.numericValue' }),
                    },
                    { min: 8, message: formatMessage({ id: 'form.validation.password.minLength' }) },
                    {
                        pattern: /[A-Z]/,
                        message: formatMessage({ id: 'form.validation.password.uppercaseLetter' }),
                    },
                    {
                        pattern: /[a-z]/,
                        message: formatMessage({ id: 'form.validation.password.lowercaseLetter' }),
                    },
                ]}
                validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                help={isFormSubmissionError(error) ? formatMessage({ id: error }) : undefined}
            >
                <Input.Password
                    type="password"
                    autoComplete="new-password"
                    size="large"
                    placeholder={formatMessage({ id: 'auth.newPassword' })}
                />
            </Form.Item>
            <Form.Item>
                <SubmitAuthFormButton
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={submitting}
                    loading={submitting}
                >
                    {formatMessage({ id: 'auth.button.save' })}
                </SubmitAuthFormButton>
            </Form.Item>
        </AuthForm>
    );
};

export default CreatePasswordForm;
