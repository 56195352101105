import { all } from 'redux-saga/effects';

import fetchElevators from './fetchElevators';
import fetchElevator from './fetchElevator';
import subscribeElevatorState from './subscribeElevatorState';
import subscribeElevatorFloorHistory from './subscribeElevatorFloorHistory';

export default function* () {
    yield all([fetchElevators(), fetchElevator(), subscribeElevatorState(), subscribeElevatorFloorHistory()]);
}
