import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'antd';

import { isEmpty } from 'lodash';
import type { RcFile } from 'antd/lib/upload';

import { useFelaEnhanced } from 'hooks';

import type { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { useNewElevatorDialog } from '../../hooks/useNewElevatorDialog';

import * as felaRules from './NewElevatorUploadButtons.rules';

export interface NewElevatorUploadButtonsProps {
    files?: RcFile[];
    type?: ChecktypeDocumentsUploadType.ASSESSMENT_REPORT | ChecktypeDocumentsUploadType.EXCEL;
}

export const NewElevatorUploadButtons = ({ files, type }: NewElevatorUploadButtonsProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const {
        navigate,
        upload: { api, uploadDocument },
    } = useNewElevatorDialog();

    return (
        <div className={styles.container}>
            <Button
                size="large"
                disabled={api.inProgress}
                onClick={() => {
                    navigate(null);
                }}
            >
                <FormattedMessage id="elevator.new.upload.button.cancel" />
            </Button>
            {type ? (
                <Button
                    type="primary"
                    size="large"
                    loading={api.inProgress}
                    disabled={isEmpty(files) || api.inProgress}
                    onClick={() => {
                        uploadDocument({
                            files: files.map(file => ({
                                fileUrl: URL.createObjectURL(file),
                                name: file.name,
                            })),
                            type,
                        });
                    }}
                >
                    <FormattedMessage id="elevator.new.upload.button.submit" />
                </Button>
            ) : null}
        </div>
    );
};
