import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useUIMessage } from 'modules/ui';
import { errorMessages, HTTPStatusCode } from 'modules/errors';

import { useFetchElevatorState } from 'modules/entities/modules/elevator-state';
import { selectElevatorStateNew, selectFilteredSubSectionStates } from '../services/selectors/elevatorState';

const useElevatorState = () => {
    const message = useUIMessage();

    const api = useFetchElevatorState();
    // TODO: change this to select from a map with elevatorId as key
    const elevatorState = useSelector(selectElevatorStateNew);
    const sections = useSelector(selectFilteredSubSectionStates);

    useEffect(() => {
        if (api.error && api.error !== errorMessages.status[HTTPStatusCode.NotFound]) {
            message('error', api.error, 5);
        }
    }, [api.error, message]);

    return { ...api, elevatorState, sections };
};

export default useElevatorState;
