import { noop } from 'lodash';
import { put } from 'redux-saga/effects';

import takeRequest from 'services/sagas/takeRequest';
import config from 'config';
import { authApi } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';

import { fetchFloorActivity as actions, fetchFloorActivityTypes } from '../actions';
import type { FloorActivity } from '../../types';

function* fetchFloorActivity(action) {
    const { id } = action.meta;

    try {
        const { startTime, endTime } = action.payload;

        const params = new URLSearchParams();

        if (startTime) params.append('startTime', startTime);
        if (endTime) params.append('endTime', endTime);

        const { data } = yield* authApi.get<FloorActivity>(config.api.floorActivity, {
            uriParams: { id },
            params: params,
        });

        yield put(actions.fetchFloorActivitySuccess(id, data));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchFloorActivityFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: action => action.meta.id,
        },
        { pattern: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_REQUEST, handler: fetchFloorActivity },
        { pattern: fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_CANCEL, handler: noop },
    );
}
