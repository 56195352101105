import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const OperatorObligationsOverview = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M37.76 20.07 33 17.22v-5.45a.52.52 0 0 0-.25-.44L29 9.17V6.5a.5.5 0 0 0-.5-.5h-20a.5.5 0 0 0-.5.5v26a.5.5 0 0 0 .5.5h15a.41.41 0 0 0 .19 0 .36.36 0 0 0 .16-.11l5-5a.48.48 0 0 0 .12-.22.629.629 0 0 0 0-.07v-7.42l3.53-2 4.71 2.83a.48.48 0 0 0 .26.07.52.52 0 0 0 .43-.24.51.51 0 0 0-.14-.77ZM24 31.29V28h3.29L24 31.29ZM28 27h-4.5a.5.5 0 0 0-.5.5V32H9V7h19v1.59l-.25-.14a.5.5 0 0 0-.5 0l-4.52 2.61a.46.46 0 0 0-.23-.06h-11a.5.5 0 0 0 0 1H22v4H11.5a.5.5 0 0 0 0 1H22v.54a.49.49 0 0 0 .25.43l5 2.89a.45.45 0 0 0 .25.07.45.45 0 0 0 .25-.07l.25-.14V27Zm4-9.75-4.5 2.6-4.5-2.6v-5.19l4.5-2.6 4.5 2.6v5.19Z"
            fill="#fff"
        />
        <path
            d="M25.5 21h-14a.5.5 0 0 0 0 1h14a.5.5 0 0 0 0-1ZM20.5 26h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1Z"
            fill="#fff"
        />
    </svg>
);
