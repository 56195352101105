import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './LegendItem.styles';

type LegendItemProps = {
    dotColor: string;
};

const LegendItem: FC<LegendItemProps> = ({ dotColor, children }) => {
    const { styles } = useFelaEnhanced(felaRules, { dotColor });
    return (
        <li className={styles.container}>
            <div className={styles.coloredDot} />
            {children}
        </li>
    );
};

export default LegendItem;
