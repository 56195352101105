import { useEffect } from 'react';
import { notification } from 'antd';

import { useNotifications } from 'modules/ui';
import { useFormSubmission } from 'modules/form';

import { FakeType, forms } from '../constants';
import { useIntl } from 'react-intl';

export const useFakeSubscribe = (type: FakeType) => {
    const notifications = useNotifications();

    const { submitting, succeeded, error, handleSubmit } = useFormSubmission(forms[type]);

    const intl = useIntl();

    useEffect(() => {
        if (succeeded) {
            notifications.success({
                key: type,
                message: intl.formatMessage({
                    id: 'page.fakePages.notification.success',
                }),
                description: intl.formatMessage({
                    id: 'page.fakePages.notification.success.desc',
                }),
            });

            return () => {
                notification.close(type);
            };
        }
    }, [notifications, succeeded, type, intl]);

    useEffect(() => {
        if (error) {
            notifications.error({
                key: type,
                message: intl.formatMessage({
                    id: 'page.fakePages.notification.error',
                }),
                description: intl.formatMessage({
                    id: 'page.fakePages.notification.error.desc',
                }),
            });

            return () => {
                notification.close(type);
            };
        }
    }, [notifications, error, type, intl]);

    return {
        submitting,
        succeeded,
        error,
        handleClick: () => {
            handleSubmit(null);
        },
    };
};
