import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { UrlParam } from 'constants/index';
import { manipulateQueryString, omitQueryString } from 'services/utils';
import { useSearchParams } from 'services/hooks';

import { OtherDocumentState, selectors } from 'modules/entities/modules/other-documents';

export const useOtherDocumentsStatusFilter = () => {
    const { search } = useLocation();
    const history = useHistory();

    const filters = useSearchParams(UrlParam.FILTER) as string;

    const countsByStatus = useSelector(selectors.selectCountOtherDocumentsByState);

    const selectedStatuses = filters ? filters.split(',') : [OtherDocumentState.SHOW_ALL];

    const handleStatusFilter = (e: CheckboxChangeEvent) => {
        const { value } = e.target;
        const newSearch =
            value === OtherDocumentState.SHOW_ALL
                ? omitQueryString(UrlParam.FILTER, search)
                : manipulateQueryString(value, UrlParam.FILTER, search, true);

        history.push({ search: newSearch });
    };

    return { selectedStatuses, countsByStatus, handleStatusFilter };
};
