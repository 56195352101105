import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const container: TRule<{ theme: Theme }> = ({ theme: { colors } }) => ({
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: '0',

    '& .ant-card-body': {
        padding: '1.25rem 0',
    },

    '&:not(:last-of-type)': {
        borderBottom: 'none',
    },
});

export const roleName: TRule<{ theme: Theme }> = ({ theme: { colors } }) => ({
    color: colors.textSecondary,
    fontSize: '.75rem',
});

export const name = () => ({
    fontWeight: 600,
    margin: '.3rem 0',
});

export const link: TRule<{ theme: Theme }> = ({ theme: { colors } }) => ({
    color: colors.text,
});

export const linkIcon = () => ({
    marginRight: '.57rem',
});

export const contactItem = () => ({
    display: 'flex',
    alignItems: 'center',
});
