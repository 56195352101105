import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/actions';
import { selectChangeRoleRequestApi } from '../services/selector';
import { useEffect } from 'react';

export const useChangeRole = () => {
    const dispatch = useDispatch();
    const api = useSelector(state => selectChangeRoleRequestApi(state))

    const handleSubmit = ({ email, role, userId }) => {
        dispatch(
            actions.changeRoleRequest({
                email,
                role,
                userId
            }),
        );
    };

    useEffect(() => {
        if (api.success) {
            dispatch(actions.fetchUsers.fetchUsersRequest())
            dispatch(actions.changeRoleRequestReset())
        }
    }, [api, dispatch])

    const resetAction = () => {
        dispatch(
            actions.changeRoleRequestReset(),
        );
    };

    useEffect(() => {
        return () => {
            actions.changeRoleRequestReset();
        };
    }, []);

    return {
        handleSubmit,
        api,
        resetAction
    };
};