import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { SearchValue } from 'constants/index';

import {
    TableSearch as Search,
    TableSearchDateRange,
    TableSearchSelect,
    SearchField,
    useTableSearch,
} from 'modules/table';
import { Input } from 'modules/form';
import { selectors } from 'modules/entities/modules/documents-contracts';

export type TableSearchProps = {
    defaultValue: SearchValue;
    isElevatorDetail: boolean;
};

const TableSearch: FC<TableSearchProps> = ({ defaultValue, isElevatorDetail }) => {
    const contractTypes = useSelector(selectors.selectContractTypes);

    const { formatMessage } = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const agreementOptions = [
        { value: 'true', label: formatMessage({ id: 'general.true' }) },
        { value: 'false', label: formatMessage({ id: 'general.false' }) },
    ];

    const fields: SearchField[] = [
        {
            label: <FormattedMessage id={`documents.table.contractNumber`} />,
            renderer: (
                <Input
                    data-testid="contract-number"
                    value={value['name']}
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    onChange={({ target }) => {
                        handleValue({
                            name: target.value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.contractType`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.choose.placeholder' })}
                    options={contractTypes}
                    value={value['contractType']}
                    onChange={value => {
                        handleValue({
                            contractType: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.frameworkAgreement`} />,
            renderer: (
                <TableSearchSelect
                    placeholder={formatMessage({ id: 'table.choose.placeholder' })}
                    options={agreementOptions}
                    value={value['frameworkAgreement']}
                    onChange={value => {
                        handleValue({
                            rameworkAgreement: value,
                        });
                    }}
                    allowClear
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.serviceProvider`} />,
            renderer: (
                <Input
                    value={value['serviceProvider.name']}
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    onChange={({ target }) => {
                        handleValue({
                            'serviceProvider.name': target.value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.startDate`} />,
            renderer: (
                <TableSearchDateRange
                    value={{
                        from: value['startDate.from'],
                        to: value['startDate.to'],
                    }}
                    onChange={(from, to) => {
                        handleValue({
                            'startDate.from': from,
                            'startDate.to': to,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`documents.table.validUntil`} />,
            renderer: (
                <TableSearchDateRange
                    value={{
                        from: value['validUntil.from'],
                        to: value['validUntil.to'],
                    }}
                    onChange={(from, to) => {
                        handleValue({
                            'validUntil.from': from,
                            'validUntil.to': to,
                        });
                    }}
                />
            ),
        },
    ];

    return (
        <Search
            fields={fields}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            colSizes={{ lg: 6, xl: 4 }}
            rowGutter={[16, 16]}
        />
    );
};

export default TableSearch;
