import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
});
export const price: TRuleWithTheme = () => ({
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'right',
});
export const content: TRuleWithTheme = () => ({
    width: '100%',
});
