import { apiSelector } from "@ackee/redux-utils/es";
import { ApiReducerState } from "@ackee/redux-utils/es/config";
import { groupBy } from "lodash";
import { createSelector } from 'reselect';
import { EntityKey, ApiReducerKey } from 'constants/index';


export const selectClosedIncidentsApi = (state):ApiReducerState =>
    apiSelector(state, EntityKey.CLOSED_INCIDENTS, ApiReducerKey.LIST)

export const selectClosedIncidents = (state): any[] => state.entities[EntityKey.CLOSED_INCIDENTS][ApiReducerKey.LIST];

export const selectClosedIncidentsGrouped = createSelector([selectClosedIncidents], elevators => {
    //This selector is used to group closed-incidents by year
    const closedCases= elevators[0]?.cases
    const closedIncidentsGrouped = groupBy(closedCases, function (closedCases) {
        return closedCases?.dateTimeOpened?.substring(0, 4);
    });
    return closedIncidentsGrouped;
});
