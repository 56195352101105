import { createSelector } from 'reselect';
import {
    ElevatorStateName,
    ElevatorSubStateName,
    ElevatorSubStates,
    ElevatorSubStatesKeyValue,
    selectors as elevatorsSelectors,
} from '../../../entities/modules/elevators';
import { elevatorSubStatePriorityField } from '../../components/IotDataTab/StatusCard/DetailedStatus/config';
import { IconType } from '../../../ui';
import { statusIconMap } from '../../components/IotDataTab/StatusCard/DetailedStatus/DetailedStatusIcon';

export const selectUnFilteredSubStates = createSelector(
    elevatorsSelectors.selectElevatorSubStates,
    (states: ElevatorSubStatesKeyValue[]) => {
        if (!states) return [];
        const stateNameKeys = Object.keys(ElevatorStateName);
        const stateKeys = Object.keys(states).sort((a: ElevatorStateName, b: ElevatorStateName) => {
            return stateNameKeys.indexOf(a.split('_')[0]) - stateNameKeys.indexOf(b.split('_')[0]);
        });

        const unSortedSubStatesByState: ElevatorSubStatesKeyValue[] = [];

        stateKeys.forEach(key => {
            const stateName = key.split('_')[0];
            const subState = states[key];
            if (!unSortedSubStatesByState[stateName]) {
                unSortedSubStatesByState[stateName] = [];
            }

            unSortedSubStatesByState[stateName].push({ ...subState, name: key });
        });

        return unSortedSubStatesByState;
    },
);

const subStateNameKeys = Object.keys(ElevatorSubStateName);
const sortFn = (a: ElevatorSubStates, b: ElevatorSubStates) =>
    subStateNameKeys.indexOf(a.name) - subStateNameKeys.indexOf(b.name);

export const selectFilteredSubStates = createSelector(
    selectUnFilteredSubStates,
    (states: ElevatorSubStatesKeyValue[]) =>
        Object.entries(states).reduce((subStatesByState, entry) => {
            const [name, substates] = entry;

            if (elevatorSubStatePriorityField[name] && Array.isArray(substates)) {
                subStatesByState[name] = [...substates].sort(sortFn);
            } else {
                subStatesByState[name] = substates;
            }

            return subStatesByState;
        }, {}),
);

export const selectActiveSubStates = createSelector(selectFilteredSubStates, (states: ElevatorSubStates[]) => {
    const subStateKeys: string[] = Object.keys(states);
    const filteredSubStates: ElevatorSubStatesKeyValue[] = [];
    subStateKeys.forEach(key => {
        if (!filteredSubStates[key]) filteredSubStates[key] = [];
        filteredSubStates[key] = states[key].filter(subState => subState.state);
    });

    return filteredSubStates;
});

export const selectUISubStates = createSelector(
    selectActiveSubStates,
    selectFilteredSubStates,
    (activeStates: ElevatorSubStatesKeyValue[], allSubStates: ElevatorSubStatesKeyValue[]) => {
        if (!activeStates && !allSubStates) return [];
        const subStateKeys = Object.keys(activeStates);
        return subStateKeys.map((key: ElevatorStateName) => ({
            iconType:
                key === 'TECHNICIAN' && activeStates[key].length === 0
                    ? IconType.TECHNICIAN_NOT_ON_SITE
                    : activeStates[key].length === 0
                    ? IconType.ELEVATOR_STATUS_SUCCESS
                    : statusIconMap[activeStates[key][0].name],
            subStates: activeStates[key],
            parentState: key,
            priorityField:
                key === 'TRIPPROFILE' && activeStates[key].length !== 0
                    ? elevatorSubStatePriorityField[activeStates['TRIPPROFILE'][0]['name']]
                    : elevatorSubStatePriorityField[key],
            fallBackDescriptionId: activeStates[key].length === 0 ? `elevator.subStateStatus.${key}.OK` : null,
        }));
    },
);
