import { ReactNode } from 'react';
import { SortingType } from '../../constants';

export enum Tables {
    ELEVATOR = 'elevators',
    INVOICES = 'invoices',
    CONTRACTS = 'contracts',
    OTHER_DOCUMENTS = 'other-documents'
}

export type SortableField = { name: string; key: string; text: ReactNode; type: SortingType };

export type RowLoaderDimensions = {
    y: number;
    width: number;
    height: number;
};
