import React from 'react';
import { Elevator } from 'modules/entities/modules/elevators';
import * as felaRules from './NewIncidentSearchInputOption.rules';
import { useFelaEnhanced } from 'hooks';
import { Icon, IconType } from 'modules/ui';

export interface NewIncidentSearchInputOptionProps {
    item: Elevator;
    selectedElevatorId: string;
}

export const NewIncidentSearchInputOption = ({ item, selectedElevatorId }: NewIncidentSearchInputOptionProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <>
            <div className={styles.wrapper}>
                {item.factoryNumber}
                {selectedElevatorId === item.id ? (
                    <Icon type={IconType.CHECKMARK_BOLD} className={styles.icon} />
                ) : null}
            </div>
            <div data-business-unit={item.propertyUnit.businessUnit} className={styles.description}>
                {item?.internalCustomerName} {item?.internalCustomerName && <div className={styles.ball} />}{' '}
                {item.propertyUnit.street},{item.propertyUnit.city}{' '}
            </div>
        </>
    );
};
