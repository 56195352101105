import { combineReducers } from 'redux';

import { ApiReducerKey } from 'constants/index';

import download from './download';
import { uploadApiReducer } from './upload';

export default combineReducers({
    [ApiReducerKey.DOWNLOAD]: download,
    [ApiReducerKey.UPLOAD]: uploadApiReducer,
});
