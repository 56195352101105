import React from 'react';
import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './TableActionPanel.styles';

export interface TableActionPanelProps {
    testId?: string;
    children: ReactNode;
}

const TableActionPanel = ({ testId, children }: TableActionPanelProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div data-testid={testId} className={styles.actionPanel}>
            {children}
        </div>
    );
};

export default TableActionPanel;
