import { basicApiReducer } from '@ackee/redux-utils';

import { combineReducers } from '@reduxjs/toolkit';

import { ApiReducerKey } from 'constants/index';

import {
    fetchAssessmentReportsRequest,
    fetchAssessmentReportsSuccess,
    fetchAssessmentReportsFailure,
    resetAssessmentReports,
} from '../actions';

export const apiReducer = combineReducers({
    [ApiReducerKey.LIST]: basicApiReducer({
        actionTypes: {
            REQUEST: fetchAssessmentReportsRequest.toString(),
            SUCCESS: fetchAssessmentReportsSuccess.toString(),
            FAILURE: fetchAssessmentReportsFailure.toString(),
            RESET: resetAssessmentReports.toString(),
        },
    }),
});
