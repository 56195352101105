import React from 'react';
import moment from 'moment';

import { useFelaEnhanced } from 'hooks';

import { generateUTCDateMoment } from 'services/utils';

import { DateRange, DateRangeProps } from 'modules/form';

import * as felaRules from './TableSearchDateRange.rules';

export interface TableSearchDateRangeProps extends Omit<DateRangeProps, 'value' | 'onChange'> {
    value: {
        from: string;
        to: string;
    };
    onChange: (from: string, to: string) => void;
}

export const TableSearchDateRange = ({ value, onChange }: TableSearchDateRangeProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <DateRange
            value={value.from && value.to ? [moment(value.from), moment(value.to)] : [null, null]}
            onChange={values => {
                const from = values === null ? undefined : generateUTCDateMoment(values[0]).toDateString();
                const to = values === null ? undefined : generateUTCDateMoment(values[1]).toDateString();

                onChange(from, to);
            }}
            extend={{ datePicker: rules.picker }}
        />
    );
};
