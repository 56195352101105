import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginBottom: '2.5rem',
});

export const info: TRuleWithTheme = () => ({
    marginTop: '1.5rem',

    fontSize: '0.875rem',
    lineHeight: 1.43,
});

export const notice: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'flex-start',

    marginTop: '1rem',

    fontSize: '0.875rem',
    lineHeight: 1.43,
});

export const cta: TRuleWithTheme = () => ({
    fontSize: 'inherit',
    lineHeight: 'inherit',

    height: 'auto',

    padding: 0,
    border: 'none',

    '> span': {
        textDecoration: 'underline',

        ':hover': {
            textDecoration: 'none',
        },
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    flexShrink: 0,

    width: '1em',
    height: '1em',

    marginTop: '0.125rem',
    marginRight: '0.5em',

    fontSize: '1rem',
});
