import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Button, Icon, IconType } from 'modules/ui';

import * as felaRules from './MapControls.rules';

export interface MapControlsProps {
    onZoomInClick: () => void;
    onZoomOutClick: () => void;
    onResetClick: () => void;
    disabled: boolean;
}

export const MapControls = ({ onResetClick, onZoomInClick, onZoomOutClick, disabled }: MapControlsProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    return (
        <>
            <Button
                className={styles.zoomInButton}
                disabled={disabled}
                onClick={onZoomInClick}
                icon={
                    <Icon
                        type={IconType.PLUS}
                        aria-label={intl.formatMessage({
                            id: 'elevators.map.controls.zoom-in',
                        })}
                    />
                }
            />
            <Button
                className={styles.zoomOutButton}
                disabled={disabled}
                onClick={onZoomOutClick}
                icon={
                    <Icon
                        type={IconType.MINUS}
                        aria-label={intl.formatMessage({
                            id: 'elevators.map.controls.zoom-out',
                        })}
                    />
                }
            />

            <Button
                type="primary"
                className={styles.resetButton}
                disabled={disabled}
                onClick={onResetClick}
                icon={
                    <Icon
                        type={IconType.RESET}
                        aria-label={intl.formatMessage({
                            id: 'elevators.map.controls.reset',
                        })}
                    />
                }
            />
        </>
    );
};
