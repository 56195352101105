import React from 'react';

const cards: {
    nameId: string;
    key: string;
    rows: { nameId: string; manufacturer: string; model: string; key: string }[];
}[] = [
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.cabin.heading',
        key: 'component_cabin',
        rows: [
            {
                nameId: 'page.elevatorDetail.tabs.components.cabin.doorDrive',
                key: 'cabinDoorDrive',
                manufacturer: 'cabinDoorDriveManufacturer',
                model: 'cabinDoorDriveModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.cabin.emergencyCall',
                key: 'cabinEmergencyCall',
                manufacturer: 'alarmDeviceManufacturer',
                model: 'alarmDeviceModel',
            },
        ],
    },
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.shaft.heading',
        key: 'component_shaft',
        rows: [
            {
                nameId: 'page.elevatorDetail.tabs.components.shaft.door',
                key: 'shaftDoor',
                manufacturer: 'shaftDoorManufacturer',
                model: 'shaftDoorModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.shaft.copyingSystem',
                key: 'shaftCopyingSystem',
                manufacturer: 'shaftCopyingManufacturer',
                model: 'shaftCopyingModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.shaft.cabinBuffer',
                key: 'shaftCabinBuffer',
                manufacturer: 'carBufferManufacturer',
                model: 'carBufferModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.shaft.counterweightBuffer',
                key: 'shaftCounterweightBuffer',
                manufacturer: 'counterWeightBufferManufacturer',
                model: 'counterWeightBufferModel',
            },
        ],
    },
    {
        nameId: 'page.elevatorDetail.tabs.technicalData.drive.heading',
        key: 'component_drive',
        rows: [
            {
                nameId: 'page.elevatorDetail.tabs.components.drive.drive',
                key: 'driveDrive',
                manufacturer: 'liftMachineManufacturer',
                model: 'liftMachineModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.drive.speedRestrictor',
                key: 'driveSpeedRestrictor',
                manufacturer: 'overspeedGovernorManufacturer',
                model: 'overspeedGovernorModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.drive.brakeCatching',
                key: 'driveBrakeCatching',
                manufacturer: 'brakeCatchingDeviceManufacturer',
                model: 'brakeCatchingDeviceModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.drive.frequencyInverter',
                key: 'driveFrequencyInverter',
                manufacturer: 'frequencyInverterManufacturer',
                model: 'frequencyInverterModel',
            },
            {
                nameId: 'page.elevatorDetail.tabs.components.drive.controller',
                key: 'driveController',
                manufacturer: 'controlManufacturer',
                model: 'controlModel',
            },
        ],
    },
];

export const useAvailableCards = elevator =>
    React.useMemo(() => {
        if (!elevator) return [];
        return cards
            .map(card => ({
                ...card,
                rows: card.rows.filter(row => elevator[row.model] || elevator[row.manufacturer]),
            }))
            .filter(card => card.rows.length > 0);
    }, [elevator]);
