import React from 'react';
import { Typography } from 'antd';

import type { Contract } from 'modules/entities/modules/documents-contracts';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ContractInfo.styles';

export interface ContractInfoProps {
    contract: Contract;
}

export const ContractInfo = ({ contract }: ContractInfoProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            <Typography.Text className={styles.name}>{contract.name}</Typography.Text>
            <Typography.Text className={styles.type}>{contract.contractType}</Typography.Text>
        </div>
    );
};
