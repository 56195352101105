import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '1rem',
    },
});

export const text: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '2rem',
    },
});

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        width: '100%',
    },
});
