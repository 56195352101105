import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { get } from 'lodash';
import { ApiReducerKey, EntityKey, SortingType } from 'constants/index';
import { createSelector } from 'reselect';
import type { Search, Sorting } from 'constants/index';
import { ListReducerState } from 'modules/entities/types';
import { Support, SupportFilterFields } from '../types';
import { numberComparatorFactoryWithUndefined } from 'modules/table';
import { compareValueByType } from 'services/utils';

export const selectSupportRequestsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.SUPPORT_REQUEST, ApiReducerKey.LIST);

export const selectSupportRequestsPostApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.SUPPORT_REQUEST, ApiReducerKey.SUPPORT_REQUEST);

export const selectSupportRequestApi = createSelector(
    [selectSupportRequestsPostApi, (_, { subject }: { subject: string }) => subject],
    (apis, id) => apis[id] ?? apis.placeholder,
);

const selectOpenSupportsEntityList = (state): ListReducerState<Support> =>
    state.entities[EntityKey.SUPPORT_REQUEST][ApiReducerKey.SUPPORT_OPENED];

const selectClosedSupportsEntityList = (state): ListReducerState<Support> =>
    state.entities[EntityKey.SUPPORT_REQUEST][ApiReducerKey.SUPPORT_CLOSED];
const selectSupportCategoriesEntityList = (state): string[] =>
    state.entities[EntityKey.SUPPORT_REQUEST][ApiReducerKey.SUPPORT_CATEGORY];

export const selectOpenSupports = createSelector(selectOpenSupportsEntityList, ({ byIds, ids }): Support[] =>
    ids.map(id => byIds[id]),
);

export const selectClosedSupports = createSelector(selectClosedSupportsEntityList, ({ byIds, ids }): Support[] =>
    ids.map(id => byIds[id]),
);

const filterSupport = (isClosed: boolean) => (supports: Support[], search) =>
    supports.filter(support =>
        search.every(({ field, query }) => {
            if (isClosed && field === SupportFilterFields.STATUS) {
                return true;
            }
            const k = get(support, field);
            if (field === SupportFilterFields.SEARCH) {
                return compareValueByType({
                    value: `${support.caseNumber}${support.description}${support.category}${support.subject}${support.caseOrigin} `,
                    query,
                });
            }
            const ii = compareValueByType({
                value: k ? `${k}` : k,
                query,
                isMultiple: [
                    SupportFilterFields.STATUS,
                    SupportFilterFields.CATEGORY,
                    SupportFilterFields.CREATOR,
                ].includes(field),
            });

            return ii;
        }),
    );

export const selectFilteredOpenSupports = createSelector(
    [
        selectOpenSupports,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    filterSupport(false),
);

export const selectFilteredClosedSupports = createSelector(
    [
        selectClosedSupports,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    filterSupport(true),
);

export const selectSortedOpenSupports = createSelector(
    [
        selectFilteredOpenSupports,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => docs,
);

export const selectSupportStatus = (key: 'status' | 'contactName' | 'category', isClosed: boolean) => {
    return createSelector(isClosed ? selectClosedSupports : selectOpenSupports, supports => {
        let listItems = supports.map(s => s[key]);
        listItems = listItems.filter(function (el) {
            return !!el;
        });
        const uniqueItems = new Set(listItems);

        return Array.from(uniqueItems)
            .map(reason => ({ value: reason, label: reason }))
            .sort(numberComparatorFactoryWithUndefined({ field: 'value', type: SortingType.ASC }));
    });
};

export const selectSupportCategories = createSelector(selectSupportCategoriesEntityList, categories => {
    return categories.map(reason => ({ value: reason, label: reason }));
});
