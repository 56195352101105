import React from 'react';

const Checkmark = props => (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.293 4.293 6 10.586 3.707 8.293a.999.999 0 1 0-1.414 1.414l3 3a.997.997 0 0 0 1.414 0l7-7a.999.999 0 1 0-1.414-1.414Z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default Checkmark;
