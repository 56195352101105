import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const MaintenanceAnalysisThumbsUp = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36 18.5a3.3 3.3 0 0 1-.82 2.12c-.112.13-.24.243-.38.34.15.263.24.557.26.86a4.3 4.3 0 0 1-.28 2 2 2 0 0 1-.84.9c.018.133.018.267 0 .4a4.94 4.94 0 0 1-.46 2.82 2.238 2.238 0 0 1-.54.6 4 4 0 0 1-.5 2 3.6 3.6 0 0 1-3.18 1.74H17.88a2 2 0 0 1-1.88-2v-8.7a10.28 10.28 0 0 1 1.24-4.54l1.2-2a7.18 7.18 0 0 0 1.02-3.68V9a3 3 0 0 1 6 0c0 1.2 0 3.34-.14 6.3h7.08a4 4 0 0 1 2.56.8A3.14 3.14 0 0 1 36 18.5ZM12 19.16H6v12h6v-12Z"
            stroke="#fff"
        />
    </svg>
);
