import { cancelled, put } from 'redux-saga/effects';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import { Workorder } from '../../types';

import {
    fetchWorkorders,
    fetchWorkordersClear,
    fetchWorkordersFailure,
    fetchWorkordersRequest,
    fetchWorkordersReset,
    fetchWorkordersSuccess,
} from '../actions';

type ResponseData = { [key: string]: any }[];

const INSPECTION_RECOMMENDATION_SEPARATOR = ';';

const transformData = (data: ResponseData) =>
    data.map(doc => {
        doc.id = doc.workOrderId;
        doc.inspectionRecommendation = doc.inspectionRecommendation
            ? doc.inspectionRecommendation.split(INSPECTION_RECOMMENDATION_SEPARATOR)
            : [];

        delete doc.workOrderId;

        return doc;
    }) as Workorder[];

function* fetchWorkordersHandler({ meta: { type, elevatorId } }: ReturnType<typeof fetchWorkorders>) {
    const controller = new AbortController();
   

    yield put(fetchWorkordersRequest());

    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.workorderDocuments, {
            signal: controller.signal,
            params: {
                type,
            },
        });
        const t = transformData(data);


        if (elevatorId) {
            const filtedData = t.filter(tag => {
                return tag.elevator.elevatorId === elevatorId;
            });
            yield put(fetchWorkordersSuccess(filtedData));
            return;
        }

        yield put(fetchWorkordersSuccess(t));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(fetchWorkordersFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

function* fetchWorkordersClearHandler() {
    yield put(fetchWorkordersReset());
}

export function* fetchWorkordersSaga() {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchWorkorders',
        },
        {
            pattern: fetchWorkorders.toString(),
            handler: fetchWorkordersHandler,
        },
        {
            pattern: fetchWorkordersClear.toString(),
            handler: fetchWorkordersClearHandler,
        },
    );
}
