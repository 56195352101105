export enum SupportSubjectsType {
    Incident = 'incident',
    Quotation = 'quotation',
    Invoice = 'invoice',
    Assessment = 'assessment',
    Issues = 'issues',
    Feedback = 'feedback',
    Hub_Issues = 'hubIssues',
    Others = 'others',   
}


export enum SupportSubjectsTypeMap {
    incident = 'Störung',
    quotation = 'Angebot',
    invoice = 'Rechnung',
    assessment = 'TÜV-/Dekra-Prüfung',
    issues = 'Probleme mit Dienstleister',
    feedback = 'Feedback zum Elevator HUB',
    hubIssues = 'Probleme mit HUB (Portal)',
    others = 'Anderes Thema',
}


export enum SupportFilterFields {
    SEARCH = 'search',
    STATUS = 'status',
    CREATOR = 'contactName',
    CATEGORY = 'category',
}


export interface Support {
    id: string
    caseNumber: string
    subject?: string
    category?: string
    status?: string
    dateTimeOpened?: string
    description?: string
    contactName?: string
    closedDate?: string
    caseOrigin?: string
    caseAge?: number
  }
