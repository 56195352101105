import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { apiSelector } from '@ackee/redux-utils/es';
import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { createSelector } from 'reselect';

import { ApiReducerKey, EntityKey, SortingType, URL_ARRAY_SEPARATOR } from 'constants/index';
import type { Sorting, Search } from 'constants/index';
import { generateUTCDate, compareValueByType } from 'services/utils';

import { dateComparatorFactory, defaultCompare, numberComparatorFactory, stringComparatorFactory } from 'modules/table';

import type { Quotation } from '../types';
import { QuotationField, QuotationStatus } from '../types';

export const selectQuotationsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_QUOTATIONS, ApiReducerKey.LIST);

export const selectQuotationsEntityList = (state): Quotation[] =>
    state.entities[EntityKey.DOCUMENT_QUOTATIONS][ApiReducerKey.LIST];

export const selectQuotations = createSelector([selectQuotationsEntityList], docs =>
    docs.map(doc => ({
        ...doc,
        file: doc.file ?? null,
    })),
);

export const selectQuotationTypeOptions = createSelector([selectQuotations], docs =>
    Array.from(new Set(docs.map(doc => doc.quotationType)))
        .filter(Boolean)
        .map(quotationType => ({ value: quotationType, label: startCase(quotationType) }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

export const selectQuotationResultOptions = createSelector([selectQuotations], docs =>
    Array.from(new Set(docs.map(doc => doc.result)))
        .filter(Boolean)
        .map(result => ({ value: result, label: result }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

export const selectQuotationReasonOptions = createSelector([selectQuotations], docs =>
    Array.from(new Set(docs.flatMap(doc => doc.reason)))
        .filter(Boolean)
        .map(reason => ({ value: reason, label: reason }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

export const selectQuotationsCountByStatus = createSelector([selectQuotations], docs =>
    Object.keys(QuotationStatus).reduce<Record<QuotationStatus, number>>((counts, statusKey) => {
        counts[QuotationStatus[statusKey]] = docs.filter(({ status }) => status === QuotationStatus[statusKey]).length;
        return counts;
    }, {} as Record<QuotationStatus, number>),
);

const sortQuotationsFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case QuotationField.REDUCED_AMOUNT:
        case QuotationField.TOTAL_REDUCTION: {
            return numberComparatorFactory(sorting);
        }
        case QuotationField.QUOTATION_DATE: {
            return dateComparatorFactory(sorting);
        }
        case QuotationField.SERVICE_PROVIDER_NAME: {
            return stringComparatorFactory(sorting);
        }
        default: {
            return defaultCompare;
        }
    }
};

export const selectSortedQuotations = createSelector(
    [
        selectQuotations,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => [...docs].sort(sortQuotationsFactory(sorting)),
);

export const selectFilteredQuotations = createSelector(
    [
        selectSortedQuotations,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (docs, search) =>
        docs.filter(doc =>
            search.every(({ field, query }) => {
                if (field.startsWith(QuotationField.ELEVATORS)) {
                    const elevatorField = field.split(`${QuotationField.ELEVATORS}.`)[1];

                    return doc.elevators.some(elevator =>
                        compareValueByType({
                            value: get(elevator, elevatorField),
                            query,
                        }),
                    );
                }

                if (field.startsWith(QuotationField.QUOTATION_DATE)) {
                    const foundItemTime = generateUTCDate(new Date(doc.quotationDate)).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === QuotationField.QUOTATION_DATE_FROM) {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === QuotationField.QUOTATION_DATE_TO) {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                if (field === QuotationField.QUOTATION_TYPE) {
                    const queries = query.split(URL_ARRAY_SEPARATOR);
                    return queries.some(q =>
                        compareValueByType({
                            value: doc.quotationType,
                            query: q,
                            strict: true,
                        }),
                    );
                }

                if (field === QuotationField.REASON) {
                    const queries = query.split(URL_ARRAY_SEPARATOR);
                    return queries.some(q =>
                        compareValueByType({
                            value: doc.reason,
                            query: q,
                            strict: true,
                        }),
                    );
                }

                if (field === QuotationField.RESULT) {
                    return compareValueByType({
                        value: doc.result,
                        query,
                        strict: true,
                    });
                }

                if (field === QuotationField.STATUS) {
                    return doc.status && query.includes(doc.status);
                }

                return compareValueByType({
                    value: get(doc, field),
                    query,
                });
            }),
        ),
);
