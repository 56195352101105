import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import type { RcFile } from 'antd/lib/upload';

import { DropZone, DropZoneFileAction, useUIMessage } from 'modules/ui';

import type { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { useNewElevatorDialog } from '../../hooks/useNewElevatorDialog';

import { NewElevatorUploadButtons } from '../NewElevatorUploadButtons';

export interface NewElevatorUploadProps {
    text: ReactNode;
    type: ChecktypeDocumentsUploadType.ASSESSMENT_REPORT | ChecktypeDocumentsUploadType.EXCEL;
    supportedFileTypes: string[];
    multiple?: boolean;
}

const defaultFiles = [];

export const NewElevatorUpload = ({ text, type, supportedFileTypes, multiple = false }: NewElevatorUploadProps) => {
    const message = useUIMessage();

    const {
        navigate,
        upload: { api, resetUpload },
    } = useNewElevatorDialog();

    const [files, setFiles] = useState<RcFile[]>(defaultFiles);

    const filesRef = useRef(files);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    useEffect(() => {
        if (api.lastSuccessAt) {
            resetUpload();
            navigate(null);
        }
    }, [api.lastSuccessAt, resetUpload, navigate]);

    return (
        <>
            <DropZone
                supportedFileTypes={supportedFileTypes}
                text={text}
                multiple={multiple}
                disabled={api.inProgress}
                files={files}
                onFile={(file, action) => {
                    switch (action) {
                        case DropZoneFileAction.DROPPED: {
                            setFiles(prevFiles => [...prevFiles, file]);
                            return;
                        }
                        case DropZoneFileAction.REMOVED: {
                            setFiles(prevFiles => prevFiles.filter(f => f.uid !== file.uid));
                            return;
                        }
                        case DropZoneFileAction.UNSUPPORTED: {
                            message('error', {
                                id: 'general.upload.unsupported',
                                values: {
                                    name: file.name,
                                },
                            });
                        }
                    }
                }}
            />
            <NewElevatorUploadButtons files={files} type={type} />
        </>
    );
};
