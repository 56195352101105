import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Elevator } from '../types';

import { subscribeElevatorFloorHistory, unsubscribeElevatorFloorHistory } from '../services/actions';
import { selectElevatorFloor } from '../services/selectors';

const useSubscribeElevatorFloorHistory = (id?: Elevator['id']) => {
    const dispatch = useDispatch();
    const currentFloor = useSelector(selectElevatorFloor);

    useEffect(() => {
        if (id) {
            dispatch(subscribeElevatorFloorHistory(id));

            return () => {
                dispatch(unsubscribeElevatorFloorHistory(id));
            };
        }
    }, [id, dispatch]);

    return currentFloor;
};

export default useSubscribeElevatorFloorHistory;
