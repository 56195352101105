import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spin, Progress } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { useElevatorCoordinates } from 'modules/entities/modules/elevator-coordinates';

import * as felaRules from './ElevatorsMapLoader.rules';

export const ElevatorsMapLoader = () => {
    const { styles, theme } = useFelaEnhanced(felaRules);
    const { progress } = useElevatorCoordinates();

    return (
        <div className={styles.container}>
            {progress < 100 ? (
                <>
                    <span className={styles.message}>
                        <FormattedMessage id="elevatorsMap.coordinatesLoading" />
                    </span>
                    <Progress percent={progress} showInfo={false} strokeColor={theme.colors.primary} />
                </>
            ) : (
                <Spin />
            )}
        </div>
    );
};
