import { DialogStateValue } from 'modules/entities';
import { Elevator } from 'modules/entities/modules/elevators';
import { createContext } from 'react';

export const GlobalDialogContext = createContext<{
    state: DialogStateValue;
    lastDialogType: DialogStateValue;
    elevator: Elevator;
    meta?: any;
    navigate: (state: DialogStateValue, elevator?: Elevator, meta?: any) => void;
}>(null);
