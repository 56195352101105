import React, { FC } from 'react';

import CreatePasswordForm from '../CreatePasswordForm';
import AuthPage from '../AuthPage';

const CreatePasswordPage: FC = () => {
    return (
        <AuthPage>
            <CreatePasswordForm />
        </AuthPage>
    );
};

export default CreatePasswordPage;
