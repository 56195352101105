import { ElevatorStateGroup, ElevatorState } from 'modules/entities/modules/elevators/types';
import type { ElevatorGroup } from 'modules/entities/modules/elevators';

export interface MarkerWithElevatorGroup extends google.maps.Marker {
    elevatorGroup?: ElevatorGroup;
}

export enum MarkerIcon {
    green = 'map/markers/green.svg',
    yellow = 'map/markers/yellow.svg',
    red = 'map/markers/red.svg',
    blue = 'map/markers/blue.svg',
    grey = 'map/markers/grey.svg',
}

export enum ClusterIcon {
    green = 'map/clusters/green.svg',
    yellow = 'map/clusters/yellow.svg',
    red = 'map/clusters/red.svg',
    blue = 'map/clusters/blue.svg',
    grey = 'map/clusters/grey.svg',
}

export const CLOSE_ICON = 'map/infoWindow/close.svg';

export const MARKER_TYPES = {
    [ElevatorState.RUNNING]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.green,
        clusterIcon: ClusterIcon.green,
    },
    [ElevatorState.POTENTIAL_ISSUES]: {
        labelColor: '#000000',
        markerIcon: MarkerIcon.yellow,
        clusterIcon: ClusterIcon.yellow,
    },
    [ElevatorState.OUT_OF_ORDER]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.red,
        clusterIcon: ClusterIcon.red,
    },
    [ElevatorState.TECHNICIAN_ON_SITE]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.blue,
        clusterIcon: ClusterIcon.blue,
    },
    [ElevatorStateGroup.UNKNOWN]: {
        labelColor: '#FFFFFF',
        markerIcon: MarkerIcon.grey,
        clusterIcon: ClusterIcon.grey,
    },
};

export const ELEVATOR_STATE_BY_MARKER_ICON = {
    [MarkerIcon.green]: ElevatorState.RUNNING,
    [MarkerIcon.yellow]: ElevatorState.POTENTIAL_ISSUES,
    [MarkerIcon.red]: ElevatorState.OUT_OF_ORDER,
    [MarkerIcon.blue]: ElevatorState.TECHNICIAN_ON_SITE,
    [MarkerIcon.grey]: ElevatorStateGroup.UNKNOWN,
};
