import { createSelector } from 'reselect';

import { selectors as tripsSelectors, TotalTrips } from '../../../entities/modules/trips';
import { ElevatorTotalTripsAggregation } from '../../../entities/modules/elevators';
import { DAY_SPLIT_CHUNK, DEFAULT_SPLIT_CHUNK } from '../../config';
import { chunkEnd } from '../utils';

export const selectAverageTrips = createSelector(tripsSelectors.selectAverageTrips, trips =>
    trips.map(trip => ({ ...trip, value: Math.round(trip.value) })),
);

export const selectTotalTripsRoundedValue = createSelector(tripsSelectors.selectTotalTrips, (trips: TotalTrips[]) =>
    trips
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map(trip => ({ ...trip, value: Math.round(trip.value) })),
);

export const selectTotalTrips = createSelector(
    selectTotalTripsRoundedValue,
    (_, granularity: ElevatorTotalTripsAggregation) => granularity,
    (trips: TotalTrips[], granularity: ElevatorTotalTripsAggregation) => {
        let splitChunk: number;

        switch (granularity) {
            case ElevatorTotalTripsAggregation.DAY:
                splitChunk = DAY_SPLIT_CHUNK;
                break;
            default:
                splitChunk = DEFAULT_SPLIT_CHUNK;
        }

        return chunkEnd(trips, splitChunk);
    },
);
