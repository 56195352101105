import { useHistory, useLocation } from 'react-router-dom';

import type { TablePaginationConfig } from 'antd/es/table/interface';

import { UrlParam } from 'constants/index';

import { manipulateQueryString } from 'services/utils';

import useDefaultTableConfig from './useDefaultTableConfig';

export const useUrlPagination = (): TablePaginationConfig => {
    const location = useLocation();
    const history = useHistory();

    const urlParams = new URLSearchParams(location.search);

    const defaultPaginationConfig = useDefaultTableConfig();

    const current = parseInt(urlParams.get(UrlParam.PAGE));

    return {
        ...defaultPaginationConfig,
        onChange: page => {
            history.push({ search: manipulateQueryString(page, UrlParam.PAGE, location.search) });
        },
        current: Number.isNaN(current) ? 1 : current,
    };
};
