import { ListReducerState, ApiAction } from '../../../../../types';
import { createByIds, createIds } from '../../../../../services/utils';

import { Incident } from '../../../types';
import { fetchIncidentsTypes } from '../../actions';

const initialState: ListReducerState<Incident, Incident['elevatorId']> = {
    byIds: {},
    ids: [],
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchIncidentsTypes.FETCH_INCIDENTS_SUCCESS: {
            const unvalidated = action?.payload?.unvalidated ? action?.payload?.unvalidated : [];
            // TODO: performance update
            return {
                byIds: createByIds(unvalidated),
                ids: createIds(unvalidated),
            };
        }

        case fetchIncidentsTypes.FETCH_INCIDENTS_RESET:
            return initialState;

        default:
            return state;
    }
};
