import { ListReducerState, ApiAction } from '../../../../../types';
import { createByIds, createIds } from '../../../../../services/utils';

import { Elevator } from '../../../types';
import { fetchElevatorsTypes } from '../../actions';

const initialState: ListReducerState<Elevator, Elevator['id']> = {
    byIds: {},
    ids: [],
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchElevatorsTypes.FETCH_ELEVATORS_SUCCESS: {
            const elevators: Elevator[] = action.payload;

            return {
                byIds: createByIds(elevators),
                ids: createIds(elevators),
            };
        }

        case fetchElevatorsTypes.FETCH_ELEVATORS_RESET:
            return initialState;

        default:
            return state;
    }
};
