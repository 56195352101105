import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch as Search, SearchField, useTableSearch, TableSearchSelect } from 'modules/table';
import {
    selectContractedAnnualNumbers,
    selectRecommendedMaintenancePerYearNumbers,
    selectSimplifaRecommendationList,
} from 'modules/entities/modules/maintenances/services/selectors';

export type TableSearchProps = {
    defaultValue: SearchValue;
};

const TableSearch: FC<TableSearchProps> = ({ defaultValue }) => {
    const contractedAnnualNumbers = useSelector(selectContractedAnnualNumbers);
    const recommendedMaintenancePerYearNumbers = useSelector(selectRecommendedMaintenancePerYearNumbers);
    const selectSimplifaRecommendationListValues = useSelector(selectSimplifaRecommendationList);
    const { formatMessage } = useIntl();

    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const fields: SearchField[] = [
        {
            label: <FormattedMessage id={`maintenance.filter.factoryNumber`} />,
            renderer: (
                <Input
                    data-testid="factory-number"
                    value={value['factoryNumber']}
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    onChange={({ target }) => {
                        handleValue({
                            factoryNumber: target.value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`maintenance.filter.contractedAnnualMaintenances`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={contractedAnnualNumbers}
                    value={value['maintenancePerYear']}
                    onChange={value => {
                        handleValue({
                            maintenancePerYear: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`maintenance.table.recommendedMaintenancePerYear`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={recommendedMaintenancePerYearNumbers}
                    value={value['recommendedMaintenancePerYear']}
                    onChange={value => {
                        handleValue({
                            recommendedMaintenancePerYear: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`maintenance.table.simplifaRecommendation`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={selectSimplifaRecommendationListValues}
                    value={value['recommendationForMaintenanceInterval']}
                    onChange={value => {
                        handleValue({
                            recommendationForMaintenanceInterval: value,
                        });
                    }}
                />
            ),
        },
    ];

    return (
        <Search
            fields={fields}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 6, xxl: 6 }}
        />
    );
};

export default TableSearch;
