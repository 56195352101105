import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchWorkorders as actions } from '../services/actions';
import { selectWorkordersApi } from '../services/selectors';

export const useFetchWorkorders = () => {
    const api = useSelector(selectWorkordersApi);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchWorkordersRequest());
        return () => {
            batch(() => {
                dispatch(actions.fetchWorkordersCancel());
                dispatch(actions.fetchWorkordersReset());
            });
        };
    }, [dispatch]);

    return api;
};
