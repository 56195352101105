import React from 'react';

import { useFelaEnhanced } from 'hooks';

import { ElevatorsMap } from 'modules/elevators-map';

import * as felaRules from './HomepageElevatorsMap.rules';

export const HomepageElevatorsMap = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <ElevatorsMap />
        </div>
    );
};
