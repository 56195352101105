import type { TRule } from 'fela';

export const button: TRule = () => ({
    // marginRight: 24,
    // padding: 0,

    // ':hover': {
    //     background: 'none',
    // },
    // ':active': {
    //     background: 'none',
    // },
    // ':focus': {
    //     background: 'none',
    // },

    // '& > span:last-child': {
    //     display: 'none',
    // },

    // tablet: {
    //     marginRight: 32,

    //     '& > span:last-child': {
    //         display: 'inline',
    //     },
    // },
});
