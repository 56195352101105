import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
    width: '100%'
});

export const label: TRuleWithTheme<{ isFloating: boolean; fontSize: string; }> = ({ theme, isFloating, fontSize }) => ({
    position: 'absolute',
    zIndex: 2,
    top: '0.375rem',
    left: '0.75rem',

    color: theme.colors.textSecondary,

    fontSize: fontSize || '0.25rem',
    lineHeight: '1.5em',

    pointerEvents: 'none',
    transitionDuration: theme.transition.duration,
    transitionProperty: 'opacity',

    opacity: isFloating ? 1 : 0,
});
