export const compareValueByType = ({
    value,
    query,
    strict = false,
    isMultiple = false,
}: {
    value: any;
    query: string;
    strict?: boolean;
    isMultiple?: boolean;
}) => {
    switch (typeof value) {
        case 'string': {
            if (isMultiple) {
                const paths = query.split('|');
                if (paths && paths.length) {
                    return paths.map(e => e.toLowerCase()).includes(value.toLowerCase());
                }
            }
            return strict ? value === query : value.toLowerCase().includes(query.toLowerCase());
        }
        case 'object': {
            if (Array.isArray(value)) {
                return value.includes(query);
            }
            // eslint-disable-next-line eqeqeq
            return value == query;
        }
        default:
            // eslint-disable-next-line eqeqeq
            return value == query;
    }
};


export const compareValueByVType = ({
    value,
    query,
    strict = false,
    isMultiple = false,
}: {
    value: any;
    query: string;
    strict?: boolean;
    isMultiple?: boolean;
}) => {
    switch (typeof value) {
        case 'string': {
            if (isMultiple) {
                const paths = query.split('|');
                if (paths && paths.length) {
                    return paths.map(e => e.toLowerCase()).includes(value.toLowerCase());
                }
            }
            return strict ? value === query : value.toLowerCase().includes(query.toLowerCase());
        }
        case 'object': {
            if (Array.isArray(value)) {
                return value.includes(query);
            }
            // eslint-disable-next-line eqeqeq
            return value == query;
        }
        default:
            // eslint-disable-next-line eqeqeq
            return value == query;
    }
};
