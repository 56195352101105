import type { Elevator } from '../elevators';

export type DocumentFile = {
    bucketId: string;
    path: string;
    fileToken: string;
    meta?: any
    saveAs?: string;
};

export interface DecodedFileToken {
    fileName?: string;
}

export interface DownloadableDocument {
    id: string;
    file: DocumentFile;
}

export interface DocumentElevator {
    propertyUnit: Elevator['propertyUnit'];
    elevatorId: Elevator['id'];
    factoryNumber: string;
}

export enum DocumentDownloadType {
    OPEN,
    DOWNLOAD,
}

export interface DocumentServiceProvider {
    name: string;
}

export enum ChecktypeDocumentsUploadType {
    QUOTATION = 'quotation',
    CONTRACT = 'contract',
    ZUES = 'zues',
    ZUES_DEFICIENCY = 'zues-deficiency',
    EXCEL = 'excel',
    ASSESSMENT_REPORT = 'assessment-report',
}

export enum GeneralDocumentsUploadType {
    CONTRACT = 'contract',
    INVOICE = 'invoice',
    QUOTATION = 'quotation',
    ASSESSMENT_REPORT = 'assessmentReport',
    OTHER_DOCUMENTS = 'otherDocuments',
}

export enum UploadService {
    CHECKTYPE_DOCUMENTS = 'checktype-documents',
    GENERAL_DOCUMENTS = 'general-documents',
}

export interface UploadFile {
    fileUrl: string;
    name: string;
}
