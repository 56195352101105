import React, { FC, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Card, Result, Space } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { Icon, DataSpace, IconType } from 'modules/ui';
import { ElevatorDetail } from 'modules/entities/modules/elevators';

import HeaderCardLoader from '../HeaderCardLoader';

import ContactData from '../ContactData';

import * as felaRules from './HeaderCard.styles';

type HeaderCardProps = { elevator?: ElevatorDetail; inProgress: boolean };

const checkValidDate = (date: string) => !isNaN(Date.parse(date));

const getColumns = (
    elevator: ElevatorDetail,
): {
    nameId: string;
    data: any;
}[] => [
    {
        nameId: 'factoryNumber',
        data: elevator?.factoryNumber || '-',
    },
    {
        nameId: 'manufacturer',
        data: elevator?.manufacturer || '-',
    },
    { nameId: 'elevatorType', data: elevator?.type || '-' },
    {
        nameId: 'driveType',
        data: elevator?.driveType || '-',
    },
    {
        nameId: 'constructionYear',
        data: checkValidDate(elevator?.manufactureYear) ? (
            <FormattedDate value={elevator.manufactureYear} year="numeric" />
        ) : (
            '-'
        ),
    },
    {
        nameId: 'modernizationYear',
        data: checkValidDate(elevator?.modernizationDate) ? (
            <FormattedDate value={elevator.modernizationDate} year="numeric" />
        ) : (
            '-'
        ),
    },
    {
        nameId: 'stops',
        data: elevator?.stops,
    },
    // {
    //     nameId: 'loadCapacity',
    //     data: elevator?.maxPayload ? `${elevator.maxPayload} Kg` : '',
    // },
    {
        nameId: 'iotLiveSince',
        data: elevator?.devices?.find(device => device.type === 'IOT') ? (
            <FormattedDate
                value={elevator?.devices.find(device => device.type === 'IOT').installation}
                year="numeric"
                month="2-digit"
                day="numeric"
            />
        ) : (
            '-'
        ),
    },
    {
        nameId: 'smartFlap',
        data: elevator?.devices?.find(device => device.type === 'Smart_Flap') ? (
            <FormattedDate
                value={elevator?.devices.find(device => device.type === 'Smart_Flap').installation}
                year="numeric"
                month="2-digit"
                day="numeric"
            />
        ) : (
            '-'
        ),
    },
];

const HeaderCard: FC<HeaderCardProps> = ({ elevator, inProgress }) => {
    const { styles } = useFelaEnhanced(felaRules);
    // const location = useLocation();
    const { goBack} = useHistory();
    const { formatMessage } = useIntl();
    //const isElevoniq = window?.location?.hostname === 'elevoniq' || window?.location?.hostname === 'localhost';

    const [contactDrawerVisible, setContactDrawerVisible] = useState(false);

    const columns = React.useMemo(() => getColumns(elevator), [elevator]);

    if (inProgress) {
        return <HeaderCardLoader />;
    }

    if (!elevator) {
        return <Result status="warning" title={<FormattedMessage id="error.general" />} />;
    }
    
    const elevoniqUrl = elevator.portalUrl ? elevator.portalUrl.replace('simplifa', 'elevoniq') : '';

    // const replaceUrlDomain = (originalUrl, oldDomain, newDomain) => {
    //     // Create a regular expression to match the old domain globally
    //     const regex = new RegExp(oldDomain, 'gi');
    //     if (originalUrl && isElevoniq) {
    //         // Replace the old domain with the new domain
    //         const newUrl = originalUrl.replace(regex, newDomain);

    //         return newUrl;
    //     }
    //     return originalUrl;
    // };

    //Example usage:
    //const newUrl = replaceUrlDomain(elevator.portalUrl, 'simplifa', 'elevoniq');

    return (
        <>
            <Card className={styles.card}>
                <div className={styles.cardHeader}>
                    <Space className={styles.infoWrapper}>
                        <Icon
                            onClick={() => {
                                goBack();
                            }}
                            type={IconType.CHEVRON_LEFT}
                        />
                        <h2 className={styles.heading}>{formatMessage({ id: 'page.elevatorDetail.title' })}</h2>
                        <div className={styles.location}>
                            <Icon type={IconType.MAP_PIN} />
                            <span>{`${elevator.propertyUnit.street}, ${elevator.propertyUnit.postalCode}, ${elevator.propertyUnit.city}`}</span>
                        </div>
                    </Space>
                    <Space className={styles.buttonWrapper}>
                        <a href={elevoniqUrl} target="_blank" rel="noopener noreferrer">
                            <Button type="primary" size="large" disabled={!elevator.portalUrl}>
                                {formatMessage({ id: 'page.elevatorDetail.360view' })}
                            </Button>
                        </a>
                        {elevator.contacts.length > 0 && (
                            <Button type="primary" size="large" onClick={() => setContactDrawerVisible(true)}>
                                {formatMessage({ id: 'page.elevatorDetail.showContacts' })}
                            </Button>
                        )}
                    </Space>
                </div>
            </Card>
            <Card>
                <div className={styles.bodyWrapper}>
                    {columns.map(column => (
                        <DataSpace
                            key={column.nameId}
                            name={formatMessage({ id: `elevator.properties.${column.nameId}` })}
                            data={column.data}
                        />
                    ))}
                </div>
            </Card>
            <ContactData
                visible={contactDrawerVisible}
                onClose={() => {
                    setContactDrawerVisible(false);
                }}
            />
        </>
    );
};

export default HeaderCard;
