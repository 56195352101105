import gql from 'graphql-tag';
import noop from 'lodash/noop';

import { take, call, put } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import * as subscriptions from 'graphql/subscriptions';
import * as log from 'config/loglevel';

import { takeRequest } from 'services/sagas/takeRequest';
import { appSyncClient } from 'modules/amplify';

import { Elevator, ElevatorFloorHistory, MeasureName } from '../../types';

import {
    subscribeElevatorFloorHistory,
    subscribedElevatorFloorHistoryFailure,
    unsubscribeElevatorFloorHistory,
    updateElevatorFloorHistory,
    SubscribeElevatorFloorHistoryAction,
} from '../actions';

interface ElevatorFloorHistoryEvent {
    floorHistory?: ElevatorFloorHistory;
    error?: Error | string;
}

const filterInvalidMeasures = floorHistory => {
    if (!Object.values(MeasureName).includes(floorHistory?.measure_name)) {
        log.warn(`${floorHistory?.measure_name} is an invalid MeasureName`);
        return false;
    }

    return true;
};

export const createSubscriptionChannel = (elevatorId: Elevator['id']) =>
    eventChannel<ElevatorFloorHistoryEvent>(emit => {
        const subscription = appSyncClient
            .subscribe({
                query: gql(subscriptions.onFloorHistoryPublish),
                variables: { elevatorId },
            })
            .map((value): ElevatorFloorHistory => value.data.onFloorHistoryPublish)
            .filter(filterInvalidMeasures)
            .subscribe(
                floorHistory => {
                    emit({
                        floorHistory,
                    });
                },
                error => {
                    emit({
                        error,
                    });
                    emit(END);
                },
            );

        return () => {
            subscription.unsubscribe();
        };
    });

export function* subscribeElevatorFloorHistoryHandler(action: SubscribeElevatorFloorHistoryAction) {
    const { id } = action.meta;

    const channel = yield call(createSubscriptionChannel, id);

    try {
        while (true) {
            const { floorHistory, error }: ElevatorFloorHistoryEvent = yield take(channel);

            if (error) {
                throw error;
            }

            yield put(updateElevatorFloorHistory(floorHistory));
        }
    } catch (error) {
        log.error(error);

        yield put(subscribedElevatorFloorHistoryFailure(id, error));
    } finally {
        yield call([channel, channel.close]);
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: action => action.meta.id,
        },
        {
            pattern: subscribeElevatorFloorHistory.toString(),
            handler: subscribeElevatorFloorHistoryHandler,
        },
        {
            pattern: unsubscribeElevatorFloorHistory.toString(),
            handler: noop,
        },
    );
}
