import { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import type { ApiReducerState } from '@ackee/redux-utils/es/config';

import { fetchInvoices as actions } from '../services/actions';
import { selectInvoicesApi } from '../services/selectors';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

const useFetchInvoices = () => {
    const api: ApiReducerState = useSelector(selectInvoicesApi);
    const dispatch = useDispatch();
    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        if (isElevatorDetail) {
            dispatch(actions.fetchInvoicesRequest({ elevatorId: id }));
        } else {
            dispatch(actions.fetchInvoicesRequest());
        }

        return () => {
            batch(() => {
                dispatch(actions.fetchInvoicesCancel());
                dispatch(actions.fetchInvoicesReset());
            });
        };
    }, [dispatch, isElevatorDetail, id]);

    return api;
};
export default useFetchInvoices;
