import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useUIMessage } from 'modules/ui';
import { errorMessages, HTTPStatusCode } from 'modules/errors';
import { useFetchFloorActivity } from 'modules/entities/modules/elevator-floor-activity';

import {
    selectTotalTrips,
    selectDoorMovements,
    selectFloors,
    prepareChartData,
} from '../services/selectors/floorActivity';

const useFloorActivity = ({ startTime, endTime }: { startTime: string; endTime: string }) => {
    const message = useUIMessage();

    const api = useFetchFloorActivity(startTime, endTime);
    const totalTrips = useSelector(selectTotalTrips);
    const doorMovements = useSelector(selectDoorMovements);
    const floors = useSelector(selectFloors);
    const chartData = useSelector(prepareChartData);

    useEffect(() => {
        if (api.error && api.error !== errorMessages.status[HTTPStatusCode.NotFound]) {
            message('error', api.error, 5);
        }
    }, [api.error, message]);

    return { ...api, totalTrips, chartData, doorMovements, floors };
};

export default useFloorActivity;
