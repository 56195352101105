import { useRef, useEffect, useReducer } from 'react';
import { createAction } from '@reduxjs/toolkit';

import { CurrentFloor } from 'modules/entities/modules/elevators';

type CallbackFn = () => void;

export enum ElevatorState {
    IDLE = 'idle',
    CLOSING_DOOR = 'closingDoor',
    MOVING = 'moving',
    OPENING_DOOR = 'openingDoor',
}

export enum TripDirection {
    UP = 'up',
    DOWN = 'down',
}

enum LiveLocationActionType {
    START_TRIP = 'startTrip',
    START_MOVING = 'startMoving',
    NEXT_FLOOR_REACHED = 'nextFloorReached',
    FINAL_FLOOR_REACHED = 'finalFloorReached',
    TRIP_FINISHED = 'tripFinished',
}

interface LiveLocationState {
    activeFloor: CurrentFloor;
    elevatorState: ElevatorState;
    finalFloor?: CurrentFloor;
    tripLength?: number;
    tripDirection?: TripDirection;
    floorQueue: CurrentFloor[];
}

interface LiveLocationAction {
    type: LiveLocationActionType;
    payload: any;
}

type LiveLocationReducer<A = LiveLocationAction> = (state: LiveLocationState, action: A) => LiveLocationState;

export type LiveLocationHookReturnValue = [
    LiveLocationState,
    {
        onDoorClosed: CallbackFn;
        onFloorFinished: CallbackFn;
        onTripFinished: CallbackFn;
        onDoorOpened: CallbackFn;
    },
];

const startTrip = createAction<CurrentFloor, LiveLocationActionType>(LiveLocationActionType.START_TRIP);
const startMoving = createAction<void, LiveLocationActionType>(LiveLocationActionType.START_MOVING);
const nextFloorReached = createAction<void, LiveLocationActionType>(LiveLocationActionType.NEXT_FLOOR_REACHED);
const finalFloorReacted = createAction<void, LiveLocationActionType>(LiveLocationActionType.FINAL_FLOOR_REACHED);
const tripFinished = createAction<void, LiveLocationActionType>(LiveLocationActionType.TRIP_FINISHED);

const startTripReducer: LiveLocationReducer<ReturnType<typeof startTrip>> = (state, action) => {
    const finalFloor = action.payload;
    const finalFloorDate = new Date(finalFloor.time);
    const currentFloorDate = new Date(state.activeFloor.time);
    if (finalFloorDate.getTime() < currentFloorDate.getTime()) {
        return state;
    } else if (state.activeFloor.floor === finalFloor.floor) {
        return {
            ...state,
            activeFloor: finalFloor,
        };
    } else {
        return {
            ...state,
            finalFloor,
            elevatorState: ElevatorState.CLOSING_DOOR,
        };
    }
};

const storeToQueue: LiveLocationReducer<ReturnType<typeof startTrip>> = (state, action) => ({
    ...state,
    floorQueue: [...state.floorQueue, action.payload],
});

const startMovingReducer: LiveLocationReducer = state => {
    const floorDiff = state.activeFloor.floor - state.finalFloor.floor;
    return {
        ...state,
        tripLength: Math.abs(floorDiff),
        tripDirection: floorDiff < 0 ? TripDirection.UP : TripDirection.DOWN,
        elevatorState: ElevatorState.MOVING,
    };
};

const nextFloorReachedReducer: LiveLocationReducer = state => ({
    ...state,
    activeFloor: {
        floor:
            // Allow updating activeFloor only until the finalFloor is reached
            state.activeFloor.floor !== state.finalFloor.floor
                ? state.activeFloor.floor + (state.tripDirection === TripDirection.UP ? 1 : -1)
                : state.activeFloor.floor,
        time: state.activeFloor.time,
    },
});

const finalFloorReachedReducer: LiveLocationReducer = state => {
    // Don't allow to change state to OPENING_DOOR when activeFloor hasn't been reached yet
    if (state.activeFloor.floor === state.finalFloor.floor) {
        return {
            ...state,
            activeFloor: state.finalFloor,
            finalFloor: null,
            tripDirection: null,
            tripLength: 0,
            elevatorState: ElevatorState.OPENING_DOOR,
        };
    }
    return state;
};

const tripFinishedReducer: LiveLocationReducer = state => {
    if (state.floorQueue.length === 0) {
        return {
            ...state,
            elevatorState: ElevatorState.IDLE,
        };
    } else {
        const floorQueue = [...state.floorQueue];
        let activeFloor = state.activeFloor;

        while (floorQueue[0]?.floor === state.activeFloor.floor) {
            activeFloor = floorQueue.shift();
        }

        const finalFloor = floorQueue.shift() ?? null;

        return {
            ...state,
            activeFloor,
            finalFloor,
            floorQueue,
            elevatorState: finalFloor ? ElevatorState.CLOSING_DOOR : ElevatorState.IDLE,
        };
    }
};

const stateMachine = {
    [ElevatorState.IDLE]: {
        [LiveLocationActionType.START_TRIP]: startTripReducer,
    },
    [ElevatorState.CLOSING_DOOR]: {
        [LiveLocationActionType.START_TRIP]: storeToQueue,
        [LiveLocationActionType.START_MOVING]: startMovingReducer,
    },
    [ElevatorState.MOVING]: {
        [LiveLocationActionType.START_TRIP]: storeToQueue,
        [LiveLocationActionType.NEXT_FLOOR_REACHED]: nextFloorReachedReducer,
        [LiveLocationActionType.FINAL_FLOOR_REACHED]: finalFloorReachedReducer,
    },
    [ElevatorState.OPENING_DOOR]: {
        [LiveLocationActionType.START_TRIP]: storeToQueue,
        [LiveLocationActionType.TRIP_FINISHED]: tripFinishedReducer,
    },
};

const reducer: LiveLocationReducer = (state, action) => {
    const childReducer: LiveLocationReducer | undefined = stateMachine[state.elevatorState][action.type];
    if (childReducer) {
        return childReducer(state, action);
    }
    return state;
};

const stateInitializer = (currentFloor: CurrentFloor): LiveLocationState => ({
    activeFloor: currentFloor,
    elevatorState: ElevatorState.IDLE,
    finalFloor: null,
    tripDirection: null,
    tripLength: 0,
    floorQueue: [],
});

const demoState: LiveLocationState = {
    activeFloor: { time: '2023-02-24T09:16:18.000Z', floor: 2 },
    elevatorState: ElevatorState.IDLE,
    finalFloor: null,
    tripDirection: null,
    tripLength: 0,
    floorQueue: [],
};
export const useLiveLocation = (currentFloor: CurrentFloor, isDeactivated?: boolean): LiveLocationHookReturnValue => {
    const [state, dispatch] = useReducer(reducer, currentFloor, stateInitializer);

    const isInitialFloor = useRef<boolean>(true);

    const onDoorClosed = () => {
        dispatch(startMoving());
    };

    const onFloorFinished = () => {
        dispatch(nextFloorReached());
    };

    const onTripFinished = () => {
        dispatch(finalFloorReacted());
    };

    const onDoorOpened = () => {
        dispatch(tripFinished());
    };

    useEffect(() => {
        if (isInitialFloor.current) {
            isInitialFloor.current = false;
        } else {
            dispatch(startTrip(currentFloor));
        }
    }, [currentFloor]);

    return [
        isDeactivated ? demoState : state,
        {
            onDoorClosed,
            onFloorFinished,
            onTripFinished,
            onDoorOpened,
        },
    ];
};
