import type { TRuleWithTheme } from 'styles/theme';


export const search: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.black,
    marginRight: '0.375rem',
    fontSize: '1rem',
    fontWeight: 700,
});

