import type { TRuleWithTheme } from 'styles/theme';

export const modal: TRuleWithTheme = () => ({
    '& .ant-modal-content': {
        padding: '1.5rem',
    },

    '& .ant-modal-header': {
        padding: 0,

        borderBottom: 'none',
    },

    '& .ant-modal-body': {
        padding: 0,
    },

    '& .ant-modal-footer': {
        padding: 0,

        borderTop: 'none',
    },

    '& .ant-modal-close-x': {
        width: '2.5rem',
        height: '2.5rem',

        lineHeight: '2.5rem',
    },
});

export const closeIcon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.iconActive,
});

export const bottomButtons: TRuleWithTheme = ({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',

    columnGap: '1rem',
});

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '-0.19rem',
});
export const alert: TRuleWithTheme = () => ({
    marginBottom: '1.94rem',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        fontSize: '1.56rem',
        lineHeight: 1.33,

        margin: 0,
    },
});

export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        color: theme.colors.textSecondary,

        fontSize: '0.88rem',
        lineHeight: 1.5,

        marginTop: '0.25rem',
        marginBottom: '1.5rem',
    },
});

export const content: TRuleWithTheme<{
    goBack: boolean;
}> = ({ goBack }) => ({
    marginLeft: goBack ? '2.5rem' : 0,
    marginTop: '0.5rem',
    fontSize: '0.88rem',
});

export const checkboxRow = () => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '3.38rem',
    marginBottom: '2rem',
    fontSize: '0.75rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& label': {
        fontSize: '0.75rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    '& label:last-child': {
        width: '300px',
    },
    '& .ant-checkbox': {
        marginTop: '4px',
        height: '15.998px',
    },
});
export const checkoutText = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1.5px',
});
export const alertContainer = () => ({
    display: 'flex',
    flexDirection: 'row',
    width: '18.6875rem',
});
export const alertIcon = () => ({
    marginRight: '0.5rem',
    paddingTop: '0.2rem'
});
export const alertMessage: TRuleWithTheme = ({ theme }) => ({
    width: '18.6875rem',
    fontSize: '0.75rem',
    color: theme.colors.text,
    lineHeight: '131.8%',
});
