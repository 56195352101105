import type { DocumentFile, DocumentElevator, DocumentServiceProvider } from 'modules/entities/modules/documents';

export enum QuotationStatus {
    DATA_CAPTURE = 'data_capture',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export interface Quotation {
    id: string;
    status: QuotationStatus;
    result: string;
    reason: string[];
    totalReduction: number;
    reducedAmount: number;
    quotationType: string;
    quotationDate: string;
    serviceProvider: DocumentServiceProvider;
    elevators: DocumentElevator[];
    file?: DocumentFile;
}

export enum QuotationField {
    STATUS = 'status',
    QUOTATION_TYPE = 'quotationType',
    QUOTATION_DATE = 'quotationDate',
    QUOTATION_DATE_FROM = 'quotationDate.from',
    QUOTATION_DATE_TO = 'quotationDate.to',
    RESULT = 'result',
    REASON = 'reason',
    SERVICE_PROVIDER_NAME = 'serviceProvider.name',
    TOTAL_REDUCTION = 'totalReduction',
    REDUCED_AMOUNT = 'reducedAmount',
    ELEVATORS = 'elevators',
    ELEVATORS_FACTORY_NUMNER = 'elevators.factoryNumber',
    ELEVATORS_PROPERTY_UNIT_CITY = 'elevators.propertyUnit.city',
    ELEVATORS_PROPERTY_UNIT_STREET = 'elevators.propertyUnit.street',
    ELEVATORS_PROPERTY_UNIT_BUSINESS_UNIT = 'elevators.propertyUnit.businessUnit',
}
