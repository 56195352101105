import React from 'react';

const SortUp = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={16}
            height={16}
            fill="currentColor"
            {...props}
        >
            <path
                d="M8 14a1 1 0 0 1-1-1V5L4.6 6.8a1 1 0 1 1-1.2-1.6l4-3a1 1 0 0 1 1.175-.018l4 2.814a1 1 0 1 1-1.15 1.636l-2.428-1.71L9 5v8a1 1 0 0 1-1 1Z"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default SortUp;
