import { useElevatorStatesPaging } from 'modules/entities/modules/elevator-states';

const useElevatorHistoryPaging = () => {
    const { api, goPrev, goNext } = useElevatorStatesPaging();

    let currentPage = 2;
    let total = 3;

    if (!api.pageTokens?.prev && !api.pageTokens?.next) {
        currentPage = 1;
        total = 1;
    } else if (!api.pageTokens?.prev) {
        currentPage = 1;
        total = 2;
    } else if (!api.pageTokens?.next) {
        currentPage = 2;
        total = 2;
    }

    const handlePageChange = (page: number) => {
        if (page < currentPage) {
            goPrev();
        } else {
            goNext();
        }
    };

    return { handlePageChange, currentPage, total };
};

export default useElevatorHistoryPaging;
