import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import { Button } from 'modules/ui';
import { ButtonProps } from 'modules/ui/components/Button/Button';

import * as felaRules from './SubmitAuthFormButton.styles';

interface SubmitAuthFormButton extends ButtonProps {}

const SubmitAuthFormButton: FC<SubmitAuthFormButton> = ({ children, ...rest }) => {
    const { rules } = useFelaEnhanced(felaRules);
    return (
        <Button extend={{ button: rules.button }} {...rest}>
            {children}
        </Button>
    );
};

export default SubmitAuthFormButton;
