import { Antonio } from '@ackee/antonio-core';
import { setAuthHeader } from '@ackee/antonio-utils';
import { getAccessToken } from '@ackee/petrus';

import config from 'config/config';

const authApi = new Antonio({
    baseURL: config.api.base,
});

authApi.interceptors.request.use(null, function* (request) {
    const accessToken = yield* getAccessToken();

    if(accessToken)  setAuthHeader(request.headers, accessToken?.token);

    return request;
});

export { authApi };
