export enum AuthChallenges {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export enum ForgotPasswordSteps {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum AuthErrorCodes {
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
}

export interface User {
  customUserId?: string;
  username: string;
  email?: string;
  avatar?: string;
  data?: Record<string, unknown>;
  role?: string;
  permissions?: {
    View_Teams__c: string;
    View_Support_Request__c: string;
    View_Operator_Obligations__c: string;
    View_Maintenance_Optimization__c: string;
    Upload_Contract_Check__c: string;
    Upload_Quotation_Check__c: string;
    Upload_Deficiency_Check__c: string;
    Upload_Price_Check_Documents__c: string;
    Upload_Documents__c: string;
    View_Documents__c: string;
    Edit_Elevators__c: string;
    View_Elevators__c: string;
    View_Work_Orders__c: string;
    View_Invoices__c: string;
    View_Contracts__c: string;
    View_Emergency_Rescue_Plans__c: string;
    View_Risk_Assessment_Reports__c: string;
    View_Condition_Reports__c: string;
    View_Other_Documents__c: string;
    View_Quotations__c: string;
    View_Assessment_Reports__c: string;
    View_Elevator_Documents__c: string;
    View_Elevator_Components__c: string;
    View_Technical_Data__c: string;
    View_Live_Data__c: string;
    value: string;
    name: string;
  };
}

export interface PetrusState {
  user?: User;
}

export interface LoginFormValues {
  email: string;
  password: string;
  disableRedirect?: boolean;
}

export interface RegisterFormValues {
  email: string;
}

export interface ConfirmPasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface ForgottenPasswordEmailValues {
  email: string;
}

export interface ForgottenPasswordValues {
  email: string;
  confirmNewPassword: string;
  newPassword: string;
  code: string;
}

export enum RegistrationStatus {
  SUBMITTED = 'submitted',
  CREATED = 'created',
  FAILED = 'failed',
}
