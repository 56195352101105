import React from 'react';
import { useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { Divider } from 'modules/ui';
import { TableFilterPanel } from 'modules/table';
import { Elevator, useElevators } from 'modules/entities/modules/elevators';

import { ElevatorsTable } from '../ElevatorsTable';
import { ElevatorSearch } from '../ElevatorsSearch';
import { ElevatorStatusFilter } from '../ElevatorStatusFilter';
import { ElevatorsActionPanel } from '../ElevatorsActionPanel';

export const ElevatorsPage = () => {
    const intl = useIntl();

    const { elevators, loading, defaultSearch, unfilteredElevators } = useElevators();
    function findInstalledDeviceTypes(elevators: Elevator[]) {
        if (!Array.isArray(elevators)) {
            return [];
        }

        const deviceTypes = new Set();
        const addedTypes = new Set();

        for (const elevator of elevators) {
            if (elevator && elevator.devices && Array.isArray(elevator.devices)) {
                for (const device of elevator.devices) {
                    if (device && device.deviceInstalled && device.type && !addedTypes.has(device.type)) {
                        deviceTypes.add(device.type);
                        addedTypes.add(device.type);
                        if (addedTypes.size === 2) {
                            return Array.from(addedTypes);
                        }
                    }
                }
            }
        }

        return Array.from(addedTypes);
    }

    const availableHardwareOptions: any = findInstalledDeviceTypes(unfilteredElevators);
    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'page.elevators.title' })} />
            <TableFilterPanel>
                <ElevatorStatusFilter />
                <Divider />
                <ElevatorSearch defaultValue={defaultSearch} availableHardwareOptions={availableHardwareOptions} />
            </TableFilterPanel>
            <ElevatorsActionPanel />
            <ElevatorsTable loading={loading} elevators={elevators} />
        </>
    );
};
