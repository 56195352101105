import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { useWorkorderDocuments, WorkorderType } from 'modules/entities/modules/workorder-documents';

import { DocumentInfo } from '../DocumentInfo';

import { EmergencyRescuePlansSearchPanel } from './EmergencyRescuePlansSearchPanel';
import { EmergencyRescuePlansActionPanel } from './EmergencyRescuePlansActionPanel';
import { EmergencyRescuePlansTable } from './EmergencyRescuePlansTable';
import { useRouteMatch } from 'react-router-dom';

export const EmergencyRescuePlansTab = () => {
    const intl = useIntl();

    const { loading, documents, defaultSearch } = useWorkorderDocuments(WorkorderType.NBA);
    const match = useRouteMatch('/elevators/:id');

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'documents.emergencyPlan.title' })} />
            <DocumentInfo>
                <FormattedMessage id="documents.emergencyPlan.info" />
            </DocumentInfo>
            <EmergencyRescuePlansSearchPanel defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            <EmergencyRescuePlansActionPanel documents={documents} />
            <EmergencyRescuePlansTable loading={loading} documents={documents} />
        </>
    );
};
