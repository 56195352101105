import React, { createContext } from 'react';
import type { ReactNode } from 'react';
import { notification } from 'antd';
import type { NotificationInstance } from 'antd/es/notification';

const { useNotification, config } = notification;

export const NotificationsContext = createContext<NotificationInstance>(null);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
    const [api, contextHolder] = useNotification();
    config({ duration: 6 });

    return (
        <NotificationsContext.Provider value={api}>
            {contextHolder}
            {children}
        </NotificationsContext.Provider>
    );
};
