import { createSelector } from 'reselect';
import { selectors as floorActivitySelectors } from 'modules/entities/modules/elevator-floor-activity';

export const selectTotalTrips = createSelector(
    floorActivitySelectors.selectFloorActivity,
    floorActivity => floorActivity?.totalTrips,
);

export const selectDoorMovements = createSelector(
    floorActivitySelectors.selectFloorActivity,
    floorActivity => floorActivity?.doors ?? [],
);

export const selectFloors = createSelector(
    floorActivitySelectors.selectFloorActivity,
    floorActivity => floorActivity?.floors ?? [],
);

export const prepareChartData = createSelector(selectFloors, floorActivity =>
    floorActivity.map(floor => ({
        floor: floor.floor,
        trips: floor.trips,
        doorMovements: floor.doors[0]?.movement ?? 0,
    })),
);
