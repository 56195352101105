import { combineReducers } from '@reduxjs/toolkit';

import { ApiReducerKey } from 'constants/index';

import { listReducerFactory } from 'services/utils/reducers';

import { resetQuotations, fetchQuotationsSuccess } from '../actions';

export const entityReducer = combineReducers({
    [ApiReducerKey.LIST]: listReducerFactory({
        setActionCreator: fetchQuotationsSuccess,
        resetActionCreator: resetQuotations,
    }),
});
