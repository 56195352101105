import React, { FC } from 'react';

import { Helmet } from 'react-helmet';
import config from 'config';

const CookiebotWidget: FC = () => {
    return <Helmet>{config.cookieBot}</Helmet>;
};

export default CookiebotWidget;
