import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import config from 'config/index';

import { useUser } from 'modules/auth';

const notTrackUserPattern = /^(.*)@(ackee.cz|simplifa.de|ack.ee|doe.com)$/;

const useAnalytics = () => {
    const user = useUser();
    const location = useLocation();

    const initialized = useRef(false);

    useEffect(() => {
        if (user && !notTrackUserPattern.test(user.email) && !initialized.current) {
            initialized.current = true;
            ReactGA.initialize(config.analytics.trackingId);
        }
    }, [user]);

    useEffect(() => {
        if (initialized.current) {
            ReactGA.send('pageview');
        }
    }, [location.pathname]);
};

export default useAnalytics;
