import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing.unit,
    borderBottom: `1px solid ${theme.colors.dividerColor}`,
});

export const chart: TRuleWithTheme = ({ theme }) => ({
    '& .recharts-layer.recharts-bar-rectangle .recharts-rectangle': {
        borderRadius: 2,
    },
    '& .recharts-text.recharts-cartesian-axis-tick-value': {
        fill: theme.colors.black,
    },
});
