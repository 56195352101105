import { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = ({ theme }) => ({
    width: '100%',
    padding: '2rem 1rem 3rem',

    '& .ant-btn': {
        width: '100%',
        marginTop: '1rem',
    },

    '& .ant-form-item-label > label.ant-form-item-required::before': {
        display: 'none',
    },

    tablet: {
        padding: '2.5rem 2rem 3.75rem',
    },
});

export const logo: TRuleWithTheme = ({ theme }) => ({
    width: 300,
    height:70,
    marginBottom: '2rem',

    tablet: {
        marginBottom: '4rem',
    },
});

export const link: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.5rem',
});
