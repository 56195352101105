import React from 'react';
import startCase from 'lodash/startCase';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';

import config from 'config/index';

import { useFelaEnhanced } from 'hooks';

import { Paragraph } from 'modules/ui';
import { TableDataSpace } from 'modules/table';

import type { Quotation } from 'modules/entities/modules/documents-quotations';

import ItemActions from '../../ItemActions';
import { DocumentElevator } from '../../DocumentElevator';
import { DocumentsTable } from '../../DocumentsTable';

import { QuotationStatusIcon } from '../QuotationStatusIcon';

import * as felaRules from './QuotationsTable.rules';
import { useRouteMatch } from 'react-router-dom';

export interface QuotationsTableProps {
    loading: boolean;
    documents: Quotation[];
}

export const QuotationsTable = ({ loading, documents }: QuotationsTableProps) => {
    const { rules } = useFelaEnhanced(felaRules);
    const match = useRouteMatch('/elevators/:id');

    const defaultColumns = [
        {
            key: 'status',
            width: '44px',
            render: (_, record) => <QuotationStatusIcon status={record.status} />,
        },
        ...[
            match?.isExact
                ? {}
                : {
                      key: 'elevators.elevatorId',
                      dataIndex: 'elevators',
                      width: 'calc(20% - 44px)',
                      render: (_, record) => (
                          <>
                              {record.elevators.map((elevator, index) => (
                                  <DocumentElevator key={elevator.elevatorId + index} elevator={elevator} />
                              ))}
                          </>
                      ),
                  },
        ],

        {
            key: 'quotationDate',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.quotationDate" />}
                    value={<FormattedDate value={record.quotationDate} />}
                />
            ),
        },
        {
            key: 'quotationType',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.quotationType" />}
                    value={startCase(record.quotationType)}
                />
            ),
        },
        {
            key: 'serviceProvider.name',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.serviceProvider.name" />}
                    value={record.serviceProvider.name}
                />
            ),
        },
        {
            key: 'result',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.result" />}
                    value={record.result}
                />
            ),
        },
        {
            key: 'reason',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.reason" />}
                    value={
                        <>
                            {record.reason.map(res => (
                                <Paragraph
                                    extend={{
                                        paragraph: rules.reason,
                                    }}
                                >
                                    {res}
                                </Paragraph>
                            ))}
                        </>
                    }
                    extend={{
                        value: rules.reasons,
                    }}
                />
            ),
        },
        {
            key: 'totalReduction',
            width: '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.totalReduction" />}
                    value={
                        <FormattedNumber
                            value={record.totalReduction}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={config.currency}
                        />
                    }
                />
            ),
        },
        {
            key: 'reducedAmount',
            width: match?.isExact ? 'calc(30% - 44px)' : '10%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.reducedAmount" />}
                    value={
                        <FormattedNumber
                            value={record.reducedAmount}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={config.currency}
                        />
                    }
                />
            ),
        },
        {
            key: 'id',
            width: '10%',
            render: (_, record) => <ItemActions id={record.id} file={record.file} />,
        },
    ];

    const elevatorDetailColumns = [
        {
            key: 'status',
            render: (_, record) => <QuotationStatusIcon status={record.status} />,
        },

        {
            key: 'quotationDate',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.quotationDate" />}
                    value={<FormattedDate value={record.quotationDate} />}
                />
            ),
        },
        {
            key: 'quotationType',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.quotationType" />}
                    value={startCase(record.quotationType)}
                />
            ),
        },
        {
            key: 'serviceProvider.name',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.serviceProvider.name" />}
                    value={record.serviceProvider.name}
                />
            ),
        },
        {
            key: 'result',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.result" />}
                    value={record.result}
                />
            ),
        },
        {
            key: 'reason',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.reason" />}
                    value={
                        <>
                            {record.reason.map(res => (
                                <Paragraph
                                    extend={{
                                        paragraph: rules.reason,
                                    }}
                                >
                                    {res}
                                </Paragraph>
                            ))}
                        </>
                    }
                    extend={{
                        value: rules.reasons,
                    }}
                />
            ),
        },
        {
            key: 'totalReduction',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.totalReduction" />}
                    value={
                        <FormattedNumber
                            value={record.totalReduction}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={config.currency}
                        />
                    }
                />
            ),
        },
        {
            key: 'reducedAmount',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.quotations.table.reducedAmount" />}
                    value={
                        <FormattedNumber
                            value={record.reducedAmount}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={config.currency}
                        />
                    }
                />
            ),
        },
        {
            key: 'id',
            render: (_, record) => <ItemActions id={record.id} file={record.file} />,
        },
    ];

    return (
        <DocumentsTable<Quotation>
            columns={match?.isExact ? elevatorDetailColumns : defaultColumns}
            loading={loading}
            dataSource={documents}
            data-testid="quotations-table"
        />
    );
};
