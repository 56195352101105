import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const MaintenanceAnalysisCostSave = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27 34.42A16.84 16.84 0 0 1 18 37a17 17 0 1 1 17-17" stroke="#fff" />
        <path d="M23 24.9a7.02 7.02 0 1 1 0-9.84M9 18h10M9 22h8M35 25v10M35 35l4-4M35 35l-4-4" stroke="#fff" />
    </svg>
);
