import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import { ElevatorSubStateName } from 'modules/entities/modules/elevators';
import { Icon, IconType } from 'modules/ui';

import * as felaRules from './DetailedStatusIcon.styles';

interface DetailedStatusIconProps {
    name?: ElevatorSubStateName;
    type?: IconType;
}

const invalidStateIcon = IconType.UNKNOWN_ERROR;

const DetailedStatusIcon: FC<DetailedStatusIconProps> = ({ type }) => {
    const { styles } = useFelaEnhanced(felaRules, { type });

    return <Icon type={type ?? invalidStateIcon} className={styles.container} />;
};

export default DetailedStatusIcon;
