import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Spin as AntdSpin } from 'antd';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ConfirmButtons.rules';
import {  CONFIRMATION_ACTION } from 'modules/incidents/constants';

export interface GeneralUploadButtonsProps {
    onConfirm: () => void;
    loadingIndicator?: string;
    submitting: boolean;
}

export const ConfirmButtons = ({ onConfirm, loadingIndicator, submitting }: GeneralUploadButtonsProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div data-test-id="validation-buttons" className={styles.container}>
            <div className={styles.info}></div>
            <div className={styles.buttonContainer}>
                <Button
                    data-test-id="validation-complete"
                    className={
                        !!loadingIndicator && loadingIndicator === CONFIRMATION_ACTION
                            ? styles.buttonLoading
                            : styles.button
                    }
                    type="primary"
                    size="large"
                    disabled={submitting}
                    onClick={onConfirm}
                >
                    {!!loadingIndicator && loadingIndicator === CONFIRMATION_ACTION ? (
                        <AntdSpin />
                    ) : (
                        <FormattedMessage id="page.incidents.table.validation.button.complete" />
                    )}
                </Button>
            </div>
        </div>
    );
};
