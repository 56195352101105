import React from 'react';

const Search = props => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.71 12.29L10 8.61C10.6441 7.70027 10.9932 6.61464 11 5.5C11 4.41221 10.6774 3.34884 10.0731 2.44437C9.46874 1.5399 8.60976 0.834947 7.60476 0.418665C6.59977 0.00238308 5.4939 -0.106535 4.42701 0.105683C3.36011 0.317902 2.3801 0.841726 1.61092 1.61092C0.841726 2.3801 0.317902 3.36011 0.105683 4.42701C-0.106535 5.4939 0.00238308 6.59977 0.418665 7.60476C0.834947 8.60976 1.5399 9.46874 2.44437 10.0731C3.34884 10.6774 4.41221 11 5.5 11C6.61464 10.9932 7.70027 10.6441 8.61 10L12.29 13.68C12.383 13.7737 12.4936 13.8481 12.6154 13.8989C12.7373 13.9497 12.868 13.9758 13 13.9758C13.132 13.9758 13.2627 13.9497 13.3846 13.8989C13.5064 13.8481 13.617 13.7737 13.71 13.68C13.8902 13.4935 13.991 13.2443 13.991 12.985C13.991 12.7257 13.8902 12.4765 13.71 12.29ZM5.5 9C4.80777 9 4.13108 8.79473 3.55551 8.41015C2.97993 8.02556 2.53133 7.47894 2.26642 6.83939C2.00152 6.19985 1.93221 5.49612 2.06725 4.81719C2.2023 4.13825 2.53565 3.51461 3.02513 3.02513C3.51461 2.53565 4.13825 2.2023 4.81719 2.06725C5.49612 1.93221 6.19985 2.00152 6.83939 2.26642C7.47894 2.53133 8.02556 2.97993 8.41015 3.55551C8.79473 4.13108 9 4.80777 9 5.5C9 6.42826 8.63125 7.3185 7.97488 7.97488C7.3185 8.63125 6.42826 9 5.5 9Z" />
        </svg>
    );
};

export default Search;
