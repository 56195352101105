import { ApiAction } from '../../../../../types';

import { fetchUsersRolesAndPermissionsTypes } from '../../actions';

const initialState = {
    roles: [],
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_SUCCESS: {
            return {
              roles: action.payload,
            };
        }

        case fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_RESET:
            return initialState;

        default:
            return state;
    }
};
