import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { ApiReducerKey, EntityKey } from '../../../../../constants';
import type { FloorActivity } from '../types';

export const selectFloorActivityApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.FLOOR_ACTIVITY, ApiReducerKey.DETAIL);

export const selectFloorActivity = (state): FloorActivity =>
    state.entities[EntityKey.FLOOR_ACTIVITY][ApiReducerKey.DETAIL];
