import React from 'react';

const PhoneBold = () => (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="elevator_detail_contacts" transform="translate(-1397.000000, -204.000000)" fill="currentColor">
                <g id="Fill-1" transform="translate(1396.000000, 203.000000)">
                    <path d="M3.0495,3.8855 C3.2885,5.4635 4.0485,7.6545 6.1995,9.8045 C8.3495,11.9555 10.5405,12.7155 12.1195,12.9555 L12.8365,11.4325 L11.3715,10.4905 L10.7785,11.0835 C10.4735,11.3915 10.0375,11.5445 9.6055,11.4875 C9.0545,11.4175 7.6365,11.1075 6.2675,9.7385 C4.8985,8.3685 4.5885,6.9515 4.5185,6.4055 C4.4645,5.9655 4.6105,5.5365 4.9215,5.2265 L5.5155,4.6335 L4.5715,3.1685 L3.0495,3.8855 Z M12.4685,15.0055 C12.4205,15.0055 12.3725,15.0035 12.3245,14.9985 C10.4065,14.7965 7.5425,13.9775 4.7855,11.2195 C2.0265,8.4625 1.2085,5.5975 1.0075,3.6815 C0.9465,3.0955 1.2605,2.5295 1.7865,2.2695 L4.1755,1.1445 C4.8075,0.8265 5.5955,1.0475 5.9855,1.6685 L7.4555,3.9525 C7.8145,4.5255 7.7325,5.2455 7.2645,5.7135 L6.5535,6.4245 C6.6565,6.8565 6.9365,7.5785 7.6815,8.3245 C8.4255,9.0685 9.1465,9.3475 9.5815,9.4525 L10.2925,8.7415 C10.7645,8.2715 11.4845,8.1935 12.0435,8.5445 L14.3465,10.0265 C14.9575,10.4095 15.1785,11.1975 14.8495,11.8525 L13.7425,14.2015 C13.5005,14.6985 13.0025,15.0055 12.4685,15.0055 L12.4685,15.0055 Z" />
                </g>
            </g>
        </g>
    </svg>
);

export default PhoneBold;
