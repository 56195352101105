import React, { FC } from 'react';
import DocumentTabs from 'modules/documents/components/DocumentTabs';

type DocumentsTabProps = {};

const DocumentsTab: FC<DocumentsTabProps> = () => {    
    return (
        <div>
            <DocumentTabs/>
        </div>
    );
};

export default DocumentsTab;
