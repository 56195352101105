import { put } from 'redux-saga/effects';
import { noop } from 'lodash';

import { authApi } from 'config/antonio';
import config from 'config';
import * as log from 'config/loglevel';
import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';

import { fetchAverageTrips as actions, fetchAverageTripsTypes } from '../actions';
import { AverageTrips } from '../../types';

function* fetchAvgTrips(action) {
    const { id } = action.meta;

    try {
        const { startTime, endTime } = action.payload;

        const params = new URLSearchParams();

        if (startTime) {
            params.append('startTime', startTime);
        }
        if (endTime) {
            params.append('endTime', endTime);
        }

        const { data } = yield* authApi.get<AverageTrips[]>(config.api.averageTrips, {
            uriParams: { id },
            params,
        });

        yield put(actions.fetchAverageTripsSuccess(id, data));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchAverageTripsFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchAverageTrips',
        },
        {
            pattern: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_REQUEST,
            handler: fetchAvgTrips,
        },
        {
            pattern: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_CANCEL,
            handler: noop,
        },
    );
}
