import React from 'react';

export const Back = () => (
    <svg width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m14.293 22.414-10-10a.999.999 0 0 1 0-1.414l10-10 1.414 1.414-9.293 9.293L15.707 21l-1.414 1.414Z"
        />
    </svg>
);
