import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRule<{ backgroundImage }> = ({ backgroundImage }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundImage:`url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

export const content: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    maxWidth: 400,
    width: '100%',
    minHeight: theme.height.authPage,

    margin: theme.spacing.unit,
    padding: 0,

    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows.panel,
});

export const image: TRule = () => ({
    display: 'none',
    width: '398px',

    tablet: {
        display: 'block',
        objectFit: 'cover',
    },
});
