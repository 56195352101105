import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { NewElevatorDialogContent } from '../NewElevatorDialogContent';
import { NewElevatorUpload } from '../NewElevatorUpload';

import * as felaRules from './NewElevatorDialogUploadAssessmentReport.rules';

const SUPPORTED_FILE_TYPES = ['.pdf'];

export const NewElevatorDialogUploadAssessmentReport = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <NewElevatorDialogContent
            goBack
            title={<FormattedMessage id="elevator.new.dialog.upload.assessment-report.title" />}
            data-testid="new-elevator-dialog-assessment-report"
        >
            <Typography.Paragraph className={styles.description}>
                <FormattedMessage id="elevator.new.dialog.upload.assessment-report.desc" />
            </Typography.Paragraph>
            <NewElevatorUpload
                type={ChecktypeDocumentsUploadType.ASSESSMENT_REPORT}
                supportedFileTypes={SUPPORTED_FILE_TYPES}
                multiple
                text={<FormattedMessage id="elevator.new.dialog.upload.assessment-report.dragger" />}
            />
        </NewElevatorDialogContent>
    );
};
