import type { TRule } from 'fela';
import { TRuleWithTheme } from 'styles/theme';

export const card: TRuleWithTheme<{ spacingSize: 'medium' | 'large' }> = ({ spacingSize }) => ({
    '& .ant-card-head-title h4': {
        fontWeight: 600,
        fontSize: '1.25rem',
        margin: '.25rem 0',
    },
    // '& .ant-card-head-title': {
    //     paddingBottom: '1.23rem',
    //     paddingTop: '1.23rem',
    // },
    '& .ant-card-head': {
        padding: '0 1rem',
    },
    '& .ant-card-body': {
        paddingTop: '1rem',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        paddingBottom: '.25rem',
    },

    extend: {
        // @ts-ignore
        condition: spacingSize === 'large',
        style: {
            '& .ant-card-head': {
                padding: '0 1.5rem',
            },
            '& .ant-card-head-title h4': {
                margin: '.125rem 0',
            },
            '& .ant-card-body': {
                paddingTop: '1.25rem',
                paddingRight: '1.5rem',
                paddingLeft: '1.5rem',
                paddingBottom: '1.25rem',
            },
        },
    },
});

export const footer: TRule = () => ({
    marginTop: '1.5rem',
    marginBottom: '1.25rem',
});

export const heading: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const backLink: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    paddingRight: '0.75rem',
    marginRight: '0.75rem',

    '& .anticon': {
        transitionProperty: 'color',
        transitionDuration: theme.transition.duration,
    },

    ':hover .anticon': {
        color: theme.colors.primary,
    },
});
