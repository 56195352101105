import { get } from 'lodash';

import { Sorting, SortingType } from 'constants/index';
import { InvoiceState } from 'modules/entities/modules/documents-invoices/types';
import { OtherDocumentState } from 'modules/entities/modules/other-documents/types';
import { ContractState } from 'modules/entities/modules/documents-contracts/types';
import { Fields } from 'modules/documents/components/InvoicesListTab/types';

import { Tables } from '../types';

export function numberComparatorFactory(sorting: Sorting) {
    if (sorting.type === SortingType.ASC) {
        return (a, b) => {
            const aa = a[sorting.field];
            const bb = b[sorting.field];
            const v = aa - bb;
            return v;
        };
    }

    return (a, b) => b[sorting.field] - a[sorting.field];
}

export function numberComparatorFactoryWithUndefined(sorting: Sorting) {
    if (sorting.type === SortingType.ASC) {
        return (a, b) => {
            const aa = a[sorting.field] ? a[sorting.field] : 0;
            const bb = b[sorting.field] ? b[sorting.field] : 0;
            return aa - bb;
        };
    }

    return (a, b) => {
        const aa = a[sorting.field] ? a[sorting.field] : 0;
        const bb = b[sorting.field] ? b[sorting.field] : 0;
        return bb - aa;
    };
}

export function stringComparatorFactory(sorting: Sorting, defaultValue = undefined) {
    if (sorting.type === SortingType.ASC) {
        return (a, b) => {
            const valueA = get(a, sorting.field, defaultValue) ?? defaultValue;
            const valueB = get(b, sorting.field, defaultValue) ?? defaultValue;
            return valueA?.localeCompare(valueB);
        };
    }

    return (a, b) => {
        const valueA = get(a, sorting.field, defaultValue) ?? defaultValue;
        const valueB = get(b, sorting.field, defaultValue) ?? defaultValue;
        return valueB?.localeCompare(valueA);
    };
}

const getTime = (value: Date | string | number) => {
    if (typeof value === 'string') {
        return Date.parse(value);
    }

    if (value instanceof Date) {
        return value.getTime();
    }

    return value;
};

export function dateComparatorFactory(sorting: Sorting) {
    if (sorting.type === SortingType.ASC) {
        return (a, b) => {
            return Math.sign(getTime(a[sorting.field]) - getTime(b[sorting.field]));
        };
    }

    return (a, b) => {
        return Math.sign(getTime(b[sorting.field]) - getTime(a[sorting.field]));
    };
}

export function arrayLengthComparatorFactory(sorting: Sorting) {
    if (sorting.type === SortingType.ASC) {
        return (a, b) => a[sorting.field]?.length - b[sorting.field]?.length;
    }

    return (a, b) => b[sorting.field]?.length - a[sorting.field]?.length;
}

const statusesOrder = (table: Tables): Map<any, number> => {
    switch (table) {
        case Tables.INVOICES:
            return new Map<InvoiceState, number>(
                Object.values(InvoiceState).map<[InvoiceState, number]>((state, index) => [state, index]),
            );
        case Tables.CONTRACTS:
            return new Map<ContractState, number>(
                Object.values(ContractState).map<[ContractState, number]>((state, index) => [state, index]),
            );
        case Tables.OTHER_DOCUMENTS:
            return new Map<OtherDocumentState, number>(
                Object.values(OtherDocumentState).map<[OtherDocumentState, number]>((state, index) => [state, index]),
            );
    }
};

export function stateComparatorFactory(sorting: Partial<Sorting>, table: Tables) {
    const field = Fields.STATUS;
    const orderedStatuses = statusesOrder(table);

    if (sorting.type === SortingType.ASC) {
        return (a, b) => orderedStatuses.get(a[field]) - orderedStatuses.get(b[field]);
    }

    return (a, b) => orderedStatuses.get(b[field]) - orderedStatuses.get(a[field]);
}

export const defaultCompare = () => 1;
