import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Spin as AntdSpin } from 'antd';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ValidationButtons.rules';
import { Icon, IconType } from 'modules/ui';
import { CANCELLATION_ACTION, CONFIRMATION_ACTION } from 'modules/incidents/constants';

export interface GeneralUploadButtonsProps {
    onValidate: () => void;
    onCancel: () => void;
    loadingIndicator?: string;
    submitting: boolean
}

export const ValidationButtons = ({ onValidate, onCancel, loadingIndicator, submitting }: GeneralUploadButtonsProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div data-test-id="validation-buttons" className={styles.container}>
            <div className={styles.info}>
                <Icon className={styles.icon} type={IconType.INFO} />{' '}
                <div className={styles.text}>
                    <FormattedMessage id="page.incidents.table.validation.text.label" />
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    data-test-id="validation-reject"
                    className={styles.buttonReject}
                    size="large"
                    disabled={submitting}
                    onClick={onCancel}
                >
                    {!!loadingIndicator && loadingIndicator === CANCELLATION_ACTION ? (
                        <AntdSpin />
                    ) : (
                        <FormattedMessage id="page.incidents.table.validation.button.reject" />
                    )}
                    
                </Button>
                <Button
                    data-test-id="validation-validate"
                    className={
                        !!loadingIndicator && loadingIndicator === CONFIRMATION_ACTION
                            ? styles.buttonLoading
                            : styles.button
                    }
                    type="primary"
                    size="large"
                    disabled={submitting}
                    onClick={onValidate}
                >
                    {!!loadingIndicator && loadingIndicator === CONFIRMATION_ACTION ? (
                        <AntdSpin />
                    ) : (
                        <FormattedMessage id="page.incidents.table.validation.button.validate" />
                    )}
                </Button>
            </div>
        </div>
    );
};
