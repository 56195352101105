import { put, cancelled } from 'redux-saga/effects';
import { noop, uniq } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';
import { geocodeCoordinates } from 'modules/entities/modules/elevator-coordinates';

import { Elevator } from '../../types';
import { fetchElevatorsTypes, fetchElevators as actions } from '../actions';
import { getPropertyUnitAdress } from '../utils';

function* fetchElevators() {
    const controller = new AbortController();

    try {
        const { data } = yield* authApi.get<Elevator[]>(config.api.elevators, {
            signal: controller.signal,
        });
        yield put(actions.fetchElevatorsSuccess(data));

        const addresses = uniq(data.filter(elevator => !elevator.propertyUnit.latitude && !elevator.propertyUnit.longitude).map(elevator => getPropertyUnitAdress(elevator.propertyUnit)));

        yield put(geocodeCoordinates(addresses));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchElevatorsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchElevatorsRequest',
            leading: true,
        },
        {
            pattern: fetchElevatorsTypes.FETCH_ELEVATORS_REQUEST,
            handler: fetchElevators,
        },
        {
            pattern: fetchElevatorsTypes.FETCH_ELEVATORS_CANCEL,
            handler: noop,
        },
    );
}
