import React from 'react';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import config from 'config';

import { Registration } from '../Registration/Registration';

import * as felaRules from './RegistrationPage.rules';
import {ReactComponent as WhiteLogo} from 'assets/images/logo_white.svg';

export interface RegistrationPageProps {
    extend?: RulesExtend<typeof felaRules>;
}

export const RegistrationPage = ({ extend }: RegistrationPageProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.container}>
            <Link to={config.routes.home}>
                <WhiteLogo className={styles.logo} />
            </Link>
            <Registration />
        </div>
    );
};
