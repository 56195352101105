import { TRuleWithTheme } from 'styles/theme';

export const reportIncident: TRuleWithTheme = () => ({
    gap: '0.625rem',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    lineHeight: '1.5rem',
    borderRadius: '0.125rem',
    textAlign: 'center',
    justifyContent:'center',
    flex: '0 0 190px',
});
