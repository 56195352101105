import { useState } from 'react';
import type { Moment } from 'moment';

import { generateUTCDateMoment } from '../services/utils';

const useDateRange = () => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const handleDateChange = (dates: [Moment, Moment]) => {
        let startDate: string;
        let endDate: string;

        if (dates) {
            startDate = generateUTCDateMoment(dates[0]).toISOString();
            endDate = generateUTCDateMoment(dates[1]).toISOString();
        }

        setStartTime(startDate);
        setEndTime(endDate);
    };

    return { startTime, endTime, handleDateChange };
};

export default useDateRange;
