import React, { forwardRef } from 'react';
import AntDatePicker from 'antd/es/date-picker';
import type { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';

import type { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './DatePicker.styles';

type DatePickerProps = AntDatePickerProps & {
    extend?: RulesExtend<typeof felaRules>;
};

const DatePicker = forwardRef<any, DatePickerProps>(({ extend, ...rest }, ref) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    // Pass the ref to the AntDatePicker component
    return (
        <div className={styles.pickerContainer}>
            <AntDatePicker ref={ref} className={styles.datePicker} {...rest} />
        </div>
    );
});

export default DatePicker;
