import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    padding: '2rem',

    marginLeft: '1rem',
    marginRight: '1rem',

    maxWidth: 400,
    width: '100%',

    background: theme.colors.white,

    borderRadius: 2,

    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.0576257))',
});
