import React from 'react';

const Document = () => (
    <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M6 20V5h6v5a1 1 0 001 1h5v9H6zm8-13.586L16.586 9H14V6.414zm5.923 3.204a.99.99 0 00-.217-.326l-5.998-5.998a.99.99 0 00-.326-.217A.991.991 0 0013 3H5a1 1 0 00-1 1v17a1 1 0 001 1h14a1 1 0 001-1V10.375c0-.065-.025-.122-.037-.185a.98.98 0 00-.04-.572z" />
            <path d="M15 13H9a1 1 0 100 2h6a1 1 0 100-2M15 16H9a1 1 0 100 2h6a1 1 0 100-2" />
        </g>
    </svg>
);

export default Document;
