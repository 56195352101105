import React, { useRef, useState } from 'react';
import { Modal, Form } from 'antd';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './SupportDialog.rules';
import { useGlobalDialog } from 'hooks/useGlobalDialog';
import { DialogState } from 'constants/common';
import { SupportRequestPage } from '../SupportRequestPage';
import { SupportSubjectsType } from 'modules/entities/modules/support';

export const SupportDialog = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const containerRef = useRef();
    const { state, navigate } = useGlobalDialog();
    const [subject, setSubject] = useState<SupportSubjectsType>(null);
    const [title, setTitle] = useState<string>(null);
    const [description, setDescription] = useState<string>(null);
    const [form] = Form.useForm();

    return (
        <div ref={containerRef}>
            <Modal
                title={null}
                footer={null}
                closable={false}
                visible={state === DialogState.CREATE_SUPPORT}
                getContainer={() => containerRef.current}
                className={styles.modal}
                width={738}
                onCancel={() => {
                    form.resetFields();
                    
                    setDescription(null);
                    setTitle(null);
                    setSubject(null);
                    navigate(null);
                }}
            >
                <SupportRequestPage
                    subject={subject}
                    setSubject={setSubject}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                />
            </Modal>
        </div>
    );
};
