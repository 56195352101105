import { config, Action, undefinedActionTypesWarning } from '@ackee/redux-utils/es/config';
import makeBasicApiReducer from '@ackee/redux-utils/es/reducers/api/basic';

import * as reducerConfig from './config';
import type { TokenPaginationApiReducerState, TokenPaginationParams } from './types';

// Extended version of pagination reducer from redux-utils https://github.com/AckeeCZ/redux-utils/blob/master/src/reducers/api/pagination/factoryReducer.ts

const getParams = (customParams: TokenPaginationParams = {}) => {
    if (!customParams.actionTypes) {
        config.logger.warn(undefinedActionTypesWarning('tokenPaginationApiReducer', customParams));
    }

    return {
        initialState: Object.freeze({
            ...config.basicApiReducer.initialState,
            ...reducerConfig.initialState,
            ...customParams.initialState,
        }),
        actionTypes: customParams.actionTypes,
        selectors: {
            ...config.basicApiReducer.selectors,
            ...reducerConfig.selectors,
            ...customParams.selectors,
        },
        actionFilters: customParams.actionFilters,
    };
};

export default function makeTokenPaginationApiReducer(customParams: TokenPaginationParams) {
    const { actionTypes: types, initialState, selectors, options, actionFilters }: TokenPaginationParams = getParams(
        customParams,
    );

    const basicApiReducer = makeBasicApiReducer({
        initialState,
        options,
        actionFilters,
        actionTypes: types,
    });

    function tokenPaginationApiReducer(
        state: TokenPaginationApiReducerState = initialState,
        action: Action,
    ): TokenPaginationApiReducerState {
        switch (action.type) {
            case types.REQUEST:
            case types.CANCEL:
            case types.FAILURE:
            case types.RESET:
            case types.UPDATE:
                return {
                    ...state,
                    ...basicApiReducer(state, action),
                };

            case types.SUCCESS: {
                const pageTokens = selectors.pageTokens(action);
                const pageSize = selectors.pageSize(action) ?? state.pageSize;

                return {
                    ...state,
                    ...basicApiReducer(state, action),
                    pageSize,
                    pageTokens,
                };
            }

            default:
                return state;
        }
    }

    tokenPaginationApiReducer.INITIAL_STATE = initialState;

    return tokenPaginationApiReducer;
}
