import type { ApiAction } from '../../../../../types';
import type { FloorActivity } from '../../../types';

import { fetchFloorActivityTypes } from '../../actions';

const initialState: FloorActivity = null;

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_SUCCESS:
            const floorActivity: FloorActivity = action.payload;

            return floorActivity;
        case fetchFloorActivityTypes.FETCH_FLOOR_ACTIVITY_RESET:
            return initialState;
        default:
            return state;
    }
};
