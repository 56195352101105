import { TRuleWithTheme } from 'styles/theme';

export const spin: TRuleWithTheme = () => ({
    height: 25,
});
export const actions: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2rem',
    minHeight: 25,
});
export const checkbox: TRuleWithTheme = () => ({
    marginLeft: '1.5rem',
});
export const loader: TRuleWithTheme = ({ theme }) => ({
    position: 'unset',
    background: 'unset',
    width: 'auto',

    '& .ant-spin': {
        height: 25,
    },
});
