import type { TRule } from 'fela';

export const photo: TRule = () => ({
    borderRadius: '50%',
});

export const moreButton: TRule = () => ({
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',

    ':hover': {
        background: 'transparent',
    }
});