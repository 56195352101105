import { TRuleWithTheme } from 'styles/theme';

export const actionPanel: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '1.5rem',

    backgroundColor: theme.colors.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.colors.outlineGrey,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
});
