import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Table, useUrlPagination } from 'modules/table';
import type { Elevator } from 'modules/entities/modules/elevators';

import * as felaRules from './MaintenanceTable.rules';

import { DocumentElevator } from 'modules/documents/components/DocumentElevator';
import MaintenanceTableDataSpace from '../MaintenanceTableDataSpace';
import { IMaintenanceValuePopover } from '../MaintenanceTableDataSpace/MaintenanceTableDataSpace';

export interface MaintenanceTableProps {
    loading: boolean;
    elevators: Elevator[];
}

export const MaintenanceTable = ({ loading, elevators }: MaintenanceTableProps) => {
    const { rules } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const pagination = useUrlPagination();

    const getSimplifaRecommendationPopoverValue = (rec: Elevator): IMaintenanceValuePopover => {
        if (rec.recommendationForMaintenanceInterval === 'Wartungsintervall erhöhen') {
            return {
                title: intl.formatMessage({
                    id: 'maintenance.table.increaseInterval.popoverTitle',
                }),
                list: [
                    intl.formatMessage({
                        id: 'maintenance.table.increaseInterval.popoverListOne',
                    }),
                    intl.formatMessage({
                        id: 'maintenance.table.increaseInterval.popoverListTwo',
                    }),
                    intl.formatMessage({
                        id: 'maintenance.table.increaseInterval.popoverListThree',
                    }),
                ],
                withBulletPoint: true
            };
        } else if (rec.recommendationForMaintenanceInterval === 'Wartungsintervall reduzieren') {
            return {
                title: intl.formatMessage({
                    id: 'maintenance.table.decreaseInterval.popoverTitle',
                }),
                list: [
                    intl.formatMessage({
                        id: 'maintenance.table.decreaseInterval.popoverListOne',
                    }),
                    intl.formatMessage({
                        id: 'maintenance.table.decreaseInterval.popoverListTwo',
                    }),
                    intl.formatMessage({
                        id: 'maintenance.table.decreaseInterval.popoverListThree',
                    }),
                ],
                withBulletPoint: true
            };
        } else if (rec.recommendationForMaintenanceInterval === 'Optimale Wartung') {
            return {
                title: intl.formatMessage({
                    id: 'maintenance.table.optimalInterval.popoverTitle',
                }),
                list: [
                    intl.formatMessage({
                        id: 'maintenance.table.optimalInterval.popoverListOne',
                    }),
                ],
            };
        } else if (rec.recommendationForMaintenanceInterval === 'Nicht verfügbar') {
            return {
                title: intl.formatMessage({
                    id: 'maintenance.table.notAvaliable.popoverTitle',
                }),
                list: [
                    intl.formatMessage({
                        id: 'maintenance.table.notAvaliable.popoverListOne',
                    }),
                ],
            };
        }
    };

    return (
        <Table<Elevator>
            hideSort={true}
            toolBarRender={false}
            search={false}
            extend={{ table: rules.table }}
            columns={[
                {
                    title: <FormattedMessage id="page.elevators.table.factoryNumber" />,
                    key: 'factoryNumber',
                    render: (_, record) => (
                        <DocumentElevator
                            key={record.id}
                            hideBusinessUnit
                            elevator={{
                                elevatorId: record.id,
                                factoryNumber: `${record.factoryNumber}`,
                                propertyUnit: record.propertyUnit,
                            }}
                        />
                    ),
                },
                {
                    title: <FormattedMessage id="maintenance.table.contractedAnnualMaintenances" />,
                    key: 'contractedAnnualMaintenances',
                    render: (_, record) => (
                        <MaintenanceTableDataSpace
                            title={<FormattedMessage id="maintenance.table.contractedAnnualMaintenances" />}
                            value={record.maintenancePerYear ? record.maintenancePerYear : '-'}
                        />
                    ),
                },
                {
                    title: <FormattedMessage id="maintenance.table.recommendedMaintenancePerYear" />,
                    key: 'recommendedMaintenancePerYear',
                    render: (_, record) => (
                        <MaintenanceTableDataSpace
                            title={<FormattedMessage id="maintenance.table.recommendedMaintenancePerYear" />}
                            value={record.recommendedMaintenancePerYear ? record.recommendedMaintenancePerYear : '-'}
                        />
                    ),
                },
                {
                    title: <FormattedMessage id="maintenance.table.simplifaRecommendation" />,
                    key: 'simplifaRecommendation',
                    render: (_, record) => (
                        <MaintenanceTableDataSpace
                            title={<FormattedMessage id="maintenance.table.simplifaRecommendation" />}
                            value={
                                record.recommendationForMaintenanceInterval
                                    ? record.recommendationForMaintenanceInterval
                                    : '-'
                            }
                            valuePopover={getSimplifaRecommendationPopoverValue(record)}
                        />
                    ),
                },
                // {
                //     title: <FormattedMessage id="maintenance.table.cancelledUntil" />,
                //     key: 'cancelledUntil',
                //     render: (_, record) => (
                //         <MaintenanceTableDataSpace
                //             title={<FormattedMessage id="maintenance.table.cancelledUntil" />}
                //             value={'-'}
                //         />
                //     ),
                // },
            ]}
            rowKey="id"
            loading={loading}
            dataSource={elevators}
            pagination={pagination}
            data-testid="maintenance-table"
        />
    );
};
