import type { DocumentElevator, DocumentFile } from '../documents';

export interface Invoice {
    id: string;
    name: string;
    status: InvoiceState;
    invoiceDate: Date;
    invoiceReason: string;
    creditPeriod: string;
    discountPeriod: string;
    grossTotal: number;
    vatRate: number;
    vat: number;
    netTotal: number;
    totalReduction: number;
    items: InvoiceItem[];
    file: DocumentFile;
}

interface InvoiceItem {
    quantity: number;
    unitPrice: number;
    subTotal: number;
    reduction: number;
    elevator: DocumentElevator;
}

export enum InvoiceState {
    SHOW_ALL = 'show_all',
    NEW = 'new',
    ONGOING = 'ongoing',
    IN_CLARIFICATION = 'in_clarification',
    CORRECT = 'correct',
    WRONG = 'wrong',
    NOT_CHECKED = 'could_not_be_checked',
}
