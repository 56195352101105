import { useMemo } from 'react';

const minLow = {
    temperature: 10,
    angle: 0,
};

export const lowToMid = {
    temperature: 38,
    angle: 55,
};

export const midToHigh = {
    temperature: 41,
    angle: 125,
};

const maxHigh = {
    temperature: 50,
    angle: 180,
};

const computeTemperatureAngle = (
    temperature: number,
    lower: {
        temperature: number;
        angle: number;
    },
    upper: {
        temperature: number;
        angle: number;
    },
) => {
    const normalizedUpperAngle = upper.angle - lower.angle;
    const normalizedUpperTemperature = upper.temperature - lower.temperature;
    const normalizedTemperature = temperature - lower.temperature;

    const normalizedAngle = (normalizedUpperAngle * normalizedTemperature) / normalizedUpperTemperature;

    return normalizedAngle + lower.angle;
};

const getBoundTemperature = (temperature: number) =>
    Math.min(maxHigh.temperature, Math.max(minLow.temperature, temperature));

export const useTemperatureScaleAngle = (temperature: number) =>
    useMemo(() => {
        const boundTemp = getBoundTemperature(temperature);

        if (minLow.temperature <= boundTemp && boundTemp < lowToMid.temperature) {
            return computeTemperatureAngle(boundTemp, minLow, lowToMid);
        } else if (lowToMid.temperature <= boundTemp && boundTemp <= midToHigh.temperature) {
            return computeTemperatureAngle(boundTemp, lowToMid, midToHigh);
        } else {
            return computeTemperatureAngle(boundTemp, midToHigh, maxHigh);
        }
    }, [temperature]);
