import { all, put } from 'redux-saga/effects';

import config from 'config';
import * as loglevel from 'config/loglevel';
import { authApi } from 'config/antonio';

import { createUIErrorMessage } from 'modules/errors';
import { takeFormSubmission, actions as formActions } from 'modules/form';

import { FakeType, forms } from '../constants';

const infoRequestMap = {
    [FakeType.OPERATOR_OBLIGATION]: 'operator_obligations',
    [FakeType.MAINTENANCE_ANALYSIS]: 'maintatnence_effort_analysis',
};

const handleSubmissionFactory = (type: FakeType) =>
    function* () {
        const formKey = forms[type];

        try {
            yield* authApi.get(config.api.subscribeFake, {
                params: {
                    infoRequest: infoRequestMap[type],
                },
            });
            
            yield put(formActions.submitFormSuccess(formKey));
        } catch (error) {
            loglevel.error(error);

            const uiError = createUIErrorMessage(error);
            yield put(formActions.submitFormFailure(formKey, uiError));
        }
    };

export function* saga() {
    yield all([
        takeFormSubmission(forms[FakeType.OPERATOR_OBLIGATION], handleSubmissionFactory(FakeType.OPERATOR_OBLIGATION)),
        takeFormSubmission(
            forms[FakeType.MAINTENANCE_ANALYSIS],
            handleSubmissionFactory(FakeType.MAINTENANCE_ANALYSIS),
        ),
    ]);
}
