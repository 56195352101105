export enum DialogState {
    CREATE_INCIDENT = 'create-incident',
    CREATE_SUPPORT = 'create-support',
    VALIDATE_CONFIRM = 'validate-confirm',
    COMPLETE_CONFIRM = 'complete-confirm',
    CANCEL_CONFIRM = 'cancel-confirm',
}

export enum SupportDialogType {
    FEEDBACK = 'FEEDBACK',
    SUPPORT = 'SUPPORT',
}
