import { get } from 'lodash';
import { createSelector } from 'reselect';
import { apiSelector, ApiReducerState } from '@ackee/redux-utils';
import { defaultCompare, numberComparatorFactoryWithUndefined } from 'modules/table';

import { EntityKey, ApiReducerKey, Search, Sorting, SortingType } from 'constants/index';

import type { ListReducerState } from '../../../../types';
import { Elevator, ElevatorMaintenanceField } from '../../types';
import { compareValueByType } from 'services/utils';
import { MaintenanceSortFields } from 'modules/maintenance/components/ActionPanel/ActionPanel';

export const selectMaintenancesApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.MAINTENANCES, ApiReducerKey.LIST);

const selectMaintenancesEntityList = (state): ListReducerState<Elevator> =>
    state.entities[EntityKey.MAINTENANCES][ApiReducerKey.LIST];

export const selectMaintenances = createSelector(selectMaintenancesEntityList, ({ byIds, ids }): Elevator[] =>
    ids.map(id => byIds[id]),
);

export const selectRecommendedMaintenancePerYearNumbers = createSelector(selectMaintenances, maintenances => {
    let recommendedMaintenancePerYear = maintenances.map(maintenances => maintenances.recommendedMaintenancePerYear);
    recommendedMaintenancePerYear = recommendedMaintenancePerYear.filter(function (el) {
        return !!el;
    });
    const uniqueRecommendedMaintenancePerYear = new Set(recommendedMaintenancePerYear);

    return Array.from(uniqueRecommendedMaintenancePerYear)
        .map(reason => ({ value: reason, label: reason }))
        .sort(numberComparatorFactoryWithUndefined({ field: 'value', type: SortingType.ASC }));
});

export const selectSimplifaRecommendationList = createSelector(selectMaintenances, maintenances => {
    let recommendations = maintenances.map(maintenances => maintenances.recommendationForMaintenanceInterval);
    recommendations = recommendations.filter(function (el) {
        return !!el;
    });
    const uniqueRecommendation = new Set(recommendations);

    return Array.from(uniqueRecommendation)
        .map(reason => ({ value: reason, label: reason }))
        .sort(numberComparatorFactoryWithUndefined({ field: 'value', type: SortingType.ASC }));
});

export const selectContractedAnnualNumbers = createSelector(selectMaintenances, maintenances => {
    let contractedNumbers = maintenances.map(maintenances => maintenances.maintenancePerYear);
    contractedNumbers = contractedNumbers.filter(function (el) {
        return !!el;
    });
    const uniqueAnnualMaintenances = new Set(contractedNumbers);

    return Array.from(uniqueAnnualMaintenances)
        .map(reason => ({ value: reason, label: reason }))
        .sort(numberComparatorFactoryWithUndefined({ field: 'value', type: SortingType.ASC }));
});

export const selectMaintenancesCount = createSelector([selectMaintenances], elevators => elevators.length);

export const selectFilteredMaintenances = createSelector(
    [
        selectMaintenances,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (elevators, search) =>
        elevators.filter(elevator =>
            search.every(({ field, query }) => {
                const k = get(elevator, field);
                return compareValueByType({
                    value: k ? `${k}` : k,
                    query,
                    isMultiple: [
                        ElevatorMaintenanceField.RECOMMENDED_MAINTENANCE,
                        ElevatorMaintenanceField.MAINTENANCE_PER_YEAR,
                        ElevatorMaintenanceField.SIMPLIFA_RECOMMENDATION,
                    ].includes(field),
                });
            }),
        ),
);

const sortElevatorsFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case MaintenanceSortFields.MAINTENANCE_PER_YEAR: {
            return numberComparatorFactoryWithUndefined(sorting);
        }
        case MaintenanceSortFields.RECOMMENDED: {
            return numberComparatorFactoryWithUndefined(sorting);
        }
        default: {
            return defaultCompare;
        }
    }
};

const sortSimplifaRecommended = (elevators: Elevator[], sorting: Sorting) => {
    let increaseInterval = [];
    let decreaseInterval = [];
    let notAvailable = [];
    let others = [];
    let optimalInterval = [];
    for (let index = 0; index < elevators.length; index++) {
        const elevator = elevators[index];

        if (elevator?.recommendationForMaintenanceInterval?.trim() === 'Wartungsintervall erhöhen') {
            increaseInterval.push(elevator);
        } else if (elevator?.recommendationForMaintenanceInterval?.trim().toLocaleLowerCase() === 'optimale wartung') {
            optimalInterval.push(elevator);
        } else if (
            elevator?.recommendationForMaintenanceInterval?.trim().toLocaleLowerCase() ===
            'wartungsintervall reduzieren'
        ) {
            decreaseInterval.push(elevator);
        } else if (elevator?.recommendationForMaintenanceInterval?.trim().toLocaleLowerCase() === 'nicht verfügbar') {
            notAvailable.push(elevator);
        } else {
            others.push(elevator);
        }
    }
    increaseInterval = increaseInterval.sort((a, b) => a.factoryNumber.localeCompare(b.factoryNumber));
    decreaseInterval = decreaseInterval.sort((a, b) => a.factoryNumber.localeCompare(b.factoryNumber));
    optimalInterval = optimalInterval.sort((a, b) => a.factoryNumber.localeCompare(b.factoryNumber));
    notAvailable = notAvailable.sort((a, b) => a.factoryNumber.localeCompare(b.factoryNumber));
    others = others.sort((a, b) => a.factoryNumber.localeCompare(b.factoryNumber));
    if (sorting.type === SortingType.ASC) {
        return [...others, ...notAvailable, ...increaseInterval, ...optimalInterval, ...decreaseInterval];
    } else {
        return [...decreaseInterval, ...optimalInterval, ...increaseInterval, ...notAvailable, ...others];
    }
};

export const selectSortedMaintenances = createSelector(
    [
        selectFilteredMaintenances,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (maintenances, sorting) => {
        if (sorting.field === MaintenanceSortFields.SIMPLIFA_RECOMMENDED) {
            return sortSimplifaRecommended(maintenances, sorting);
        }

        return [...maintenances].sort(sortElevatorsFactory(sorting));
    },
);
