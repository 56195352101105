import { TeamDialogContext, TeamDialogState } from 'modules/teams/context/TeamDialogContext';
import React, { useMemo, useState } from 'react';

const TeamDialogProvider = ({ children }) => {
    const [state, setState] = useState<TeamDialogState>(null);
    const [isDisabled, setIsDisabled] = useState(true)

    const context = useMemo(
        () => ({
            state,
            setState,
            isDisabled,
            setIsDisabled
        }),
        [state, isDisabled],
    );

    return <TeamDialogContext.Provider value={context}>{children}</TeamDialogContext.Provider>;
};

export default TeamDialogProvider;
