import type { TRuleWithTheme } from 'styles/theme';

export const attachment: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',

    paddingTop: '0.75rem',
    paddingRight: '0.75rem',
    paddingBottom: '0.75rem',
    paddingLeft: '1.25rem',

    marginTop: '0.75rem',

    backgroundColor: theme.colors.uploadDraggerDisabled,

    borderRadius: '0.25rem',
});

export const docIcon: TRuleWithTheme = ({ theme }) => ({
    marginRight: '0.625rem',
    verticalAlign: 'middle',
});

export const name: TRuleWithTheme = ({ theme }) => ({
    verticalAlign: 'middle',

    color: theme.colors.text,
});

export const trashButton: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,

    '&.ant-btn-icon-only': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
    },
});

export const trashIcon: TRuleWithTheme = ({ theme }) => ({
    marginRight: '-0.5rem',

    color: theme.colors.uploadRemove,

    verticalAlign: 'middle',
});
