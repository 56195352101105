import React from 'react';

export const NoActiveIncidents = () => (
    <svg width="67" height="74" viewBox="0 0 67 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.6875 27.0417L15.1042 20.4583H57.8958L51.3125 27.0417H21.6875ZM33.2083 46.7917V66.5417H16.75V73.125H56.25V66.5417H39.7917V46.7917L66.125 20.4583V13.875H6.875V20.4583L33.2083 46.7917Z"
            fill="#D9D9D9"
        />
        <line x1="0.707107" y1="1.29289" x2="28.7071" y2="29.2929" stroke="#D9D9D9" strokeWidth="2" />
    </svg>
);
