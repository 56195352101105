import { take, fork, cancel, call, cancelled, actionChannel } from 'redux-saga/effects';
import { buffers, Task } from 'redux-saga';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ActionSubPattern } from '@redux-saga/types';

import { actionPatternTest } from './helpers';

type AnyAction = PayloadAction<any, string, any, any>;

interface RequestOptions {
    pattern?: ActionSubPattern<any>;
    handler?: (...args: any[]) => any;
}

type RequestIdSelector = (action: AnyAction) => any;

export const checkRequestIdSelector = (effectName: string, requestIdSelector: RequestIdSelector) => {
    if (typeof requestIdSelector !== 'function') {
        throw new Error(`${effectName}: requestIdSelector has to be a function.`);
    }
};

export const safelySelectRequestId = (
    effectName: string,
    requestIdSelector: RequestIdSelector,
    action: AnyAction,
): ReturnType<RequestIdSelector> => {
    const requestId = requestIdSelector(action);

    if (!requestId) {
        throw new Error(`${effectName}: requestIdSelector has to return a value.`);
    }

    return requestId;
};

const EFFECT_NAME = 'takeRequest';

interface RequestEffectOptions {
    requestIdSelector: (action: AnyAction) => any;
    leading?: boolean;
    latest?: boolean;
}

export const takeRequest = (
    { requestIdSelector = () => null, leading = false, latest = true }: RequestEffectOptions,
    requestOptions: RequestOptions = {},
    cleanupRequestOptions: RequestOptions = {},
) => {
    checkRequestIdSelector(EFFECT_NAME, requestIdSelector);

    return fork(function* () {
        const requestTaskMap = new Map<ReturnType<RequestIdSelector>, Task>();

        // The channel iscomplusory, otherwise we loose actions
        const channel = yield actionChannel(
            [requestOptions.pattern, cleanupRequestOptions.pattern].filter(Boolean),
            buffers.expanding(1),
        );

        try {
            while (true) {
                const action = yield take(channel);

                const requestId = safelySelectRequestId(EFFECT_NAME, requestIdSelector, action);

                if (actionPatternTest(action, requestOptions.pattern)) {
                    const previousRequestTask = requestTaskMap.get(requestId);
                    if (previousRequestTask) {
                        if (leading) {
                            continue;
                        } else if (latest) {
                            yield cancel(previousRequestTask);

                            if (cleanupRequestOptions.pattern) {
                                yield call(cleanupRequestOptions.handler, action);
                            }

                            requestTaskMap.delete(requestId);
                        }
                    }

                    const requestTask: Task = yield fork(requestOptions.handler, action);

                    requestTaskMap.set(requestId, requestTask);

                    requestTask.toPromise().then(() => {
                        if (requestTask.isCancelled()) {
                            // If has been cancelled by the cleanup than it was removed from the map there
                            return;
                        }
                        if (requestTaskMap.has(requestId)) {
                            requestTaskMap.delete(requestId);
                        }
                    });
                } else {
                    const requestTask = requestTaskMap.get(requestId);

                    if (requestTask) {
                        yield cancel(requestTask);
                        requestTaskMap.delete(requestId);
                    }

                    yield call(cleanupRequestOptions.handler, action);
                }
            }
        } finally {
            if (yield cancelled()) {
                for (const [, task] of requestTaskMap.entries()) {
                    yield cancel(task);
                }
            }
        }
    });
};

export default takeRequest;
