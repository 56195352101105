import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authUserSelector } from 'modules/auth';
import { useUIMessage } from 'modules/ui';

import { DocumentFile, DocumentDownloadType } from '../types';

import {
    downloadDocuments as actions,
    resetDownloadSelect as resetDownloadSelectAction,
    selectDownload,
    unselectDownload as unselectDownloadAction,
} from '../services/actions';

import { useDownloadApi } from './useDownloadApi';
import { useSelectedDocuments } from './useSelectedDocuments';

export const useDownloadDocuments = () => {
    const dispatch = useDispatch();
    const api = useDownloadApi();
    const selectedDocuments = useSelectedDocuments();

    const user = useSelector(authUserSelector);

    const message = useUIMessage();

    const selectDocument = (names: string[]) => {
        dispatch(selectDownload(names));
    };

    const unselectDocument = (name: string) => {
        dispatch(unselectDownloadAction(name));
    };

    const resetDownloadSelect = useCallback(() => {
        dispatch(resetDownloadSelectAction());
    }, [dispatch]);

    const downloadDocuments = (files: DocumentFile[], type: DocumentDownloadType = DocumentDownloadType.OPEN) => {
        if (user.customUserId) {
            dispatch(actions.downloadDocumentsRequest({ files, type }));
        } else {
            message('error', 'documents.open.unauthorized', 5);
        }
    };

    return { downloadDocuments, selectDocument, resetDownloadSelect, unselectDocument, selectedDocuments, api };
};
