import React, { FC } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Elevator } from 'modules/entities/modules/elevators';
import config from 'config/index';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ElevatorDetailLink.styles';

type ElevatorDetailLinkProps = {
    elevatorId: Elevator['id'];
};

const ElevatorDetailLink: FC<ElevatorDetailLinkProps> = ({ elevatorId, children }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div>
            <Link to={generatePath(config.routes.elevatorDetail, { id: elevatorId })} className={styles.link} />
            {children}
        </div>
    );
};

export default ElevatorDetailLink;
