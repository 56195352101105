import { useSelector } from 'react-redux';

import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { useDisplayError } from 'modules/errors';

import { selectMaintenancesCount, selectSortedMaintenances } from '../services/selectors';
import { useFetchMaintenances } from './useFetchMaintenances';
import { MaintenanceSortFields } from 'modules/maintenance/components/ActionPanel/ActionPanel';
import { SortingType } from 'constants/index';


export const DEFAULT_SORT = {
    field: MaintenanceSortFields.SIMPLIFA_RECOMMENDED,
    type: SortingType.DESC,
};

export const useMaintenances = (setUp?: { disableSearch: boolean; disableSorting: boolean }) => {
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const api = useFetchMaintenances();

    const maintenances = useSelector(state =>
        selectSortedMaintenances(state, {
            search: setUp?.disableSearch ? [] : search,
            sorting,
        }),
    );
    const maintenancesCount = useSelector(selectMaintenancesCount);

    useDisplayError(api.error);

    return {
        loading: api.inProgress,
        maintenances,
        maintenancesCount,
        defaultSearch: defaultValue,
    };
};
