import React from 'react';

const Phone = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="ic/24/phone" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M4.02765013,5.52879623 C4.33665013,7.99479623 5.45665013,11.5677962 8.94565013,15.0587962 C12.4346501,18.5477962 16.0066501,19.6667962 18.4746501,19.9767962 L19.8936501,16.9637962 L16.9906501,15.0937962 L15.8146501,16.2697962 C15.4636501,16.6217962 14.9566501,16.7977962 14.4636501,16.7317962 C13.6976501,16.6347962 11.7036501,16.1987962 9.75465013,14.2507962 C7.80965013,12.3067962 7.37265013,10.3097962 7.27365013,9.54079623 C7.21065013,9.04479623 7.38565013,8.53779623 7.74165013,8.18679623 L8.91265013,7.01579623 L7.04065013,4.10979623 L4.02765013,5.52879623 Z M18.7016501,22.0047962 C18.6476501,22.0047962 18.5936501,22.0017962 18.5386501,21.9967962 C15.7416501,21.7017962 11.5636501,20.5047962 7.53165013,16.4727962 C3.49965013,12.4397962 2.30265013,8.26279623 2.00865013,5.46779623 C1.93865013,4.79679623 2.29865013,4.14779623 2.90165013,3.84979623 L6.47565013,2.16579623 C7.21065013,1.79979623 8.11365013,2.05679623 8.55465013,2.76879623 L10.7636501,6.19579623 C11.1746501,6.85179623 11.0796501,7.67679623 10.5426501,8.21379623 L9.28565013,9.47079623 C9.40265013,10.1307962 9.80265013,11.4707962 11.1686501,12.8367962 C12.5396501,14.2067962 13.8796501,14.6047962 14.5356501,14.7197962 L15.7916501,13.4637962 C16.3286501,12.9267962 17.1566501,12.8327962 17.7986501,13.2347962 L21.2496501,15.4577962 C21.9496501,15.8977962 22.2026501,16.7987962 21.8296501,17.5487962 L20.1626501,21.0857962 C19.8826501,21.6547962 19.3106501,22.0047962 18.7016501,22.0047962 L18.7016501,22.0047962 Z"
                id="Fill-1"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default Phone;
