import React from 'react';
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';

import { mapInitializationStatus } from '../../services/slices';
import config from 'config';

export interface MapProviderProps {
    children: ReactNode;
}

const API_KEY = config.mapApiKey

export const MapProvider = ({ children }: MapProviderProps) => {
    const dispatch = useDispatch();

    return (
        <Wrapper
            apiKey={API_KEY}
            callback={status => {
                dispatch(mapInitializationStatus(status));
            }}
            render={() => <>{children}</>}
        />
    );
};
