import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from 'hooks';

import config from 'config/config';
import { Icon, IconType } from 'modules/ui';

import * as felaRules from './InfoWindow.rules';
import { getEnumKeyByValue, Workorder, WorkOrderState } from 'modules/entities/modules/workorders';
import { WorkOrderStatusIcon } from 'modules/workOrders/components/WorkOrderStatusIcon';

export interface InfoWindowProps {
    workorders: Workorder[];
}

export const InfoWindow = ({ workorders }: InfoWindowProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <FormattedMessage id="workorders.map.info-window.title" />
            </div>

            {workorders.map(workorder => {
                const { Id, Street__c, Zip_Code__c, City__c, Status__c, Name } = workorder;
                return (
                    <a
                        href={`${config.salesforce}/${Id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.row}
                        key={Id}
                    >
                        <WorkOrderStatusIcon state={Status__c} />
                        <div id={Id} className={styles.status} />
                        <div className={styles.elevatorInfo}>
                            <div className={styles.id}>{Name}</div>
                            <div className={styles.owner}>
                                <FormattedMessage
                                    id={`page.workorders.filter.${getEnumKeyByValue(WorkOrderState, Status__c)}`}
                                />
                            </div>
                            <div className={styles.address}>{`${Street__c},${Zip_Code__c}, ${City__c}`}</div>
                        </div>
                        <Icon type={IconType.CHEVRON_RIGHT} />
                    </a>
                );
            })}
        </div>
    );
};
