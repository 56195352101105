import { Auth } from 'aws-amplify';

import createExpirationDate from './utils/createExpirationDate';

export default function* refreshTokens() {
    const session = yield Auth.currentSession();
    const accessToken = session.getAccessToken();
    const expiration = createExpirationDate(accessToken.payload.exp);

    return {
        accessToken: {
            token: accessToken.getJwtToken(),
            expiration,
        },
        refreshToken: {
            token: null,
        },
    };
}
