import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography, Upload } from 'antd';

import { noop } from 'lodash';
import type { RcFile } from 'antd/lib/upload';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from '../../Icon';

import * as felaRules from './DropZoneInput.rules';

const { Dragger } = Upload;

export interface DropZoneInputProps {
    disabled: boolean;
    onFile: (file: RcFile) => void;
    multiple?: boolean;
    text: ReactNode;
}

export const DropZoneInput = ({ disabled, onFile, text, multiple = false }: DropZoneInputProps) => {
    const { styles } = useFelaEnhanced(felaRules, {
        disabled,
    });

    return (
        <div data-testid="dropzone">
            <Dragger
                className={styles.dragger}
                name="file"
                multiple={multiple}
                showUploadList={false}
                disabled={disabled}
                customRequest={noop}
                onChange={data => {
                    onFile(data.file.originFileObj);
                }}
            >
                <Icon type={IconType.DRAG_N_DROP} className={styles.icon} />
                <Typography.Text className={styles.text}>
                    <FormattedMessage
                        id="general.upload.placeholder"
                        values={{
                            text,
                            span: (chunks: ReactNode) => <span className={styles.span}>{chunks}</span>,
                        }}
                    />
                </Typography.Text>
            </Dragger>
        </div>
    );
};
