import { TRuleWithTheme } from '../../../../styles/theme';

const fontSizeMap = {
    xsmall: '.75rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
};

const lineHeightMap = {
    xsmall: '1rem',
    small: '1.25rem',
    medium: '1.5rem',
    large: '1.5rem',
};

export const paragraph: TRuleWithTheme<{ size?: string; faded: boolean; noMargin?: boolean }> = ({
    size,
    theme,
    noMargin,
    faded,
}) => ({
    fontSize: fontSizeMap[size],
    color: faded ? theme.colors.textSecondary : theme.colors.text,
    margin: ' 0',
    lineHeight: lineHeightMap[size],

    extend: [
        {
            condition: size === 'xsmall' || noMargin,
            style: {
                '&.ant-typography': {
                    marginBottom: 0,
                },
            },
        },
    ],
});
