import { all } from 'redux-saga/effects';

import { saga as auth } from 'modules/auth';
import { saga as newIncident } from 'modules/incident-new';
import { saga as entities } from 'modules/entities';
import { saga as fakePages } from 'modules/fake-pages';

export default function* appSaga() {
    yield all([auth(), entities(), newIncident(), fakePages()]);
}
