import { createReducer } from '@reduxjs/toolkit';
import type { PayloadActionCreator } from '@reduxjs/toolkit';

export function listReducerFactory<DataType = string>({
    setActionCreator,
    resetActionCreator,
}: {
    setActionCreator: PayloadActionCreator<DataType[]>;
    resetActionCreator: PayloadActionCreator;
}) {
    const initialState: DataType[] = [];

    return createReducer(initialState, builder => {
        builder.addCase(setActionCreator, (_, { payload }) => payload).addCase(resetActionCreator, () => initialState);
    });
}
