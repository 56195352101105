import { Auth } from 'aws-amplify';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import config from 'config';
import * as Log from 'config/loglevel';
import { actions as formActions, takeFormSubmission } from 'modules/form';
import { createUIErrorMessage } from 'modules/errors';

import { ConfirmPasswordFormValues } from '../../types';
import { forms } from '../../config';
import { USER_EMAIL_STORAGE_KEY, USER_TEMP_PASSWORD } from '../../constants';

function* redirectToLogin() {
    const redirectPath = config.routes.login;
    yield put(push(redirectPath, { passwordConfirmed: true }));
}

function* handleConfirmSignUpForm(action: formActions.types.SubmitFormAction<ConfirmPasswordFormValues>) {
    const { oldPassword, newPassword } = action.payload;
    const email = localStorage.getItem(USER_EMAIL_STORAGE_KEY);
    try {
        const user = yield Auth.signIn(email, oldPassword);

        yield Auth.completeNewPassword(user, newPassword, { email });

        yield put(formActions.submitFormSuccess(forms.createPassword));

        localStorage.removeItem(USER_EMAIL_STORAGE_KEY);
        localStorage.removeItem(USER_TEMP_PASSWORD);
        yield redirectToLogin();
    } catch (error) {
        const customError = { ...error };
        if (error.code === 'NotAuthorizedException') {
            customError.code = 'NotAuthorizedExceptionCreatePassword';
        }
        Log.error(customError);

        const uiError = createUIErrorMessage(customError);
        yield put(formActions.submitFormFailure(forms.createPassword, uiError));
    }
}

export default function* confirmPasswordForm() {
    yield takeFormSubmission(forms.createPassword, handleConfirmSignUpForm);
}
