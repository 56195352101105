import { OtherDocument } from '../../../types';
import { ApiAction } from '../../../../../types';
import { fetchOtherDocumentsTypes } from '../../actions';

const initialState: OtherDocument[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_SUCCESS:
            return action.payload;
        case fetchOtherDocumentsTypes.FETCH_OTHER_DOCUMENTS_RESET:
            return initialState;
        default:
            return state;
    }
};
