import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { TableDataSpace } from 'modules/table';

import type { AssessmentReport } from 'modules/entities/modules/documents-assessment-reports';

import ItemActions from '../../ItemActions';
import { DocumentElevator } from '../../DocumentElevator';
import { DocumentsTable } from '../../DocumentsTable';

import { AssessmentReportStatusIcon } from '../AssessmentReportStatusIcon';
//import { AssessmentReportDeficiencies } from '../AssessmentReportDeficiencies';
import { useRouteMatch } from 'react-router-dom';

export interface AssessmentReportsTableProps {
    loading: boolean;
    documents: AssessmentReport[];
}

export const AssessmentReportsTable = ({ loading, documents }: AssessmentReportsTableProps) => {
    const match = useRouteMatch('/elevators/:id');
    const defaultColumns = [
        {
            key: 'status',
            width: '44px',
            render: (_, record) => <AssessmentReportStatusIcon status={record.status} />,
        },
        ...[
            match?.isExact
                ? {}
                : {
                      key: 'elevators.elevatorId',
                      dataIndex: 'elevators',
                      width: '17%',
                    // Note: Remove hide business unit once pagination is implemented [Temp fix]
                      render: (_, record) => <DocumentElevator  hideBusinessUnit={true} elevator={record.elevator} />,
                  },
        ],

        {
            key: 'assessmentDate',
            width: '13%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.assessmentDate" />}
                    value={<FormattedDate value={record.assessmentDate} />}
                />
            ),
        },
        {
            key: 'assessmentType',
            width: '13%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.assessmentType" />}
                    value={record.assessmentType}
                />
            ),
        },
        {
            key: 'serviceProvider.name',
            width: '13%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.serviceProvider.name" />}
                    value={record.serviceProvider.name}
                />
            ),
        },
        {
            key: 'result',
            width: '15%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.result" />}
                    value={record.result}
                />
            ),
        },
        //Note: Uncomment this once pagination is implemented [Temp fix]
        // {
        //     key: 'noTotalDeficiencies',
        //     width: '10%',
        //     render: (_, record) => (
        //         <TableDataSpace
        //             title={<FormattedMessage id="documents.assessment-reports.table.noTotalDeficiencies" />}
        //             value={
        //                 <AssessmentReportDeficiencies
        //                     noTotalDeficiencies={record.noTotalDeficiencies}
        //                     noDangerousDeficiencies={record.noDangerousDeficiencies}
        //                     noExtensiveDeficiencies={record.noExtensiveDeficiencies}
        //                     noMinorDeficiencies={record.noMinorDeficiencies}
        //                 />
        //             }
        //         />
        //     ),
        // },
        {
            key: 'reducedAmount',
            width: '13%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.dueDate" />}
                    value={record.dueDate ? <FormattedDate value={record.dueDate} /> : null}
                />
            ),
        },
        {
            key: 'reducedAmount',
            width: match?.isExact ? 'calc(30% - 44px)' : '13%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.nextAssessment" />}
                    value={record.nextAssessment ? <FormattedDate value={record.nextAssessment} /> : null}
                />
            ),
        },
        {
            key: 'id',
            width: '3%',
            render: (_, record) => <ItemActions id={record.id} file={record.file} />,
        },
    ];

    const elevatorDetailsColumns = [
        {
            key: 'status',
            width: '4%',
            render: (_, record) => <AssessmentReportStatusIcon status={record.status} />,
        },

        {
            key: 'assessmentDate',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.assessmentDate" />}
                    value={<FormattedDate value={record.assessmentDate} />}
                />
            ),
        },
        {
            key: 'assessmentType',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.assessmentType" />}
                    value={record.assessmentType}
                />
            ),
        },
        {
            key: 'serviceProvider.name',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.serviceProvider.name" />}
                    value={record.serviceProvider.name}
                />
            ),
        },
        {
            key: 'result',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.result" />}
                    value={record.result}
                />
            ),
        },
    //    Note: Uncomment this once pagination is implemented [Temp fix]
    //     {
    //         key: 'noTotalDeficiencies',
    //         render: (_, record) => (
    //             <TableDataSpace
    //                 title={<FormattedMessage id="documents.assessment-reports.table.noTotalDeficiencies" />}
    //                 value={1
    //                     <AssessmentReportDeficiencies
    //                         noTotalDeficiencies={record.noTotalDeficiencies}
    //                         noDangerousDeficiencies={record.noDangerousDeficiencies}
    //                         noExtensiveDeficiencies={record.noExtensiveDeficiencies}
    //                         noMinorDeficiencies={record.noMinorDeficiencies}
    //                     />
    //                 }
    //             />
    //         ),
    //     },
        {
            key: 'reducedAmount',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.dueDate" />}
                    value={record.dueDate ? <FormattedDate value={record.dueDate} /> : null}
                />
            ),
        },
        {
            key: 'reducedAmount',
            width: '15.5%',
            render: (_, record) => (
                <TableDataSpace
                    title={<FormattedMessage id="documents.assessment-reports.table.nextAssessment" />}
                    value={record.nextAssessment ? <FormattedDate value={record.nextAssessment} /> : null}
                />
            ),
        },
        {
            key: 'id',
            width: '3%',
            render: (_, record) => <ItemActions id={record.id} file={record.file} />,
        },
    ];
    return (
        <DocumentsTable<AssessmentReport>
            columns={match?.isExact ? elevatorDetailsColumns : defaultColumns}
            loading={loading}
            dataSource={documents}
            data-testid="assessment-reports-table"
        />
    );
};
