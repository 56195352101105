import type { ApiReducerState } from '@ackee/redux-utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchElevator as actions } from '../services/actions';

import { useElevatorDetailApi } from './useElevatorDetailApi';

export default (): ApiReducerState => {
    const { id } = useParams<{ id: string }>();
    const api = useElevatorDetailApi();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchElevatorRequest(id));

        return () => {
            dispatch(actions.fetchElevatorCancel());
            dispatch(actions.fetchElevatorReset());
        };
    }, [dispatch, id]);

    return api;
};
