import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const card: TRule = () => ({
    height: 250,
});

export const cardTitle: TRuleWithTheme = () => ({
    width: '100%',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
});
