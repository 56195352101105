import type { Incident } from '../../../types';
import type { ApiAction } from '../../../../../types';

import { fetchIncidentTypes } from '../../actions';

const initialState: Incident[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchIncidentTypes.FETCH_INCIDENT_SUCCESS:
            return action.payload;
        case fetchIncidentTypes.FETCH_INCIDENT_RESET:
            return initialState;
        default:
            return state;
    }
};
