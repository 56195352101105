import { take, cancel, call, put, fork } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import gql from 'graphql-tag';
import { noop } from 'lodash';

import { takeRequest } from 'services/sagas/takeRequest';
import * as subscriptions from 'graphql/subscriptions';
import { appSyncClient } from 'modules/amplify';
import * as log from 'config/loglevel';

import { Elevator, ElevatorState } from '../../types';
import {
    subscribeElevatorStateTypes as types,
    subscribeElevatorState as subscribeActions,
    updateElevatorState,
} from '../actions';

interface ElevatorStateEvent {
    state?: ElevatorState;
    error?: Error | string;
}

export const createSubscriptionChannel = (elevatorId: Elevator['id']) =>
    eventChannel<ElevatorStateEvent>(emit => {
        const subscription = appSyncClient
            .subscribe({
                query: gql(subscriptions.onIoTElevatorStatePublish),
                variables: { elevatorId },
            })
            .map((value): ElevatorState => value.data.onIoTElevatorStatePublish?.state)
            .filter(state => {
                if (!state) {
                    return false;
                }

                if (!Object.values(ElevatorState).includes(state)) {
                    log.warn(`Received invalid state: '${state}'`);
                    return false;
                }

                return true;
            })
            .subscribe({
                next: state => {
                    emit({ state });
                },
                error: error => {
                    emit({ error });
                    emit(END);
                },
            });

        return () => {
            subscription.unsubscribe();
        };
    });

export function* elevatorStateSubscriptionTask(channel) {
    while (true) {
        const { state, error }: ElevatorStateEvent = yield take(channel);

        if (state) {
            yield put(updateElevatorState(state));
        } else if (error) {
            log.error(error);
            yield put(subscribeActions.subscribeElevatorStateFailure('error.appSync.elevatorStateSubscription'));
        }
    }
}

export function* subscribeElevatorState(action) {
    const channel = yield call(createSubscriptionChannel, action.meta.id);

    try {
        const subscriptionTask = yield fork(elevatorStateSubscriptionTask, channel);

        yield take(types.SUBSCRIBE_ELEVATOR_STATE_CANCEL);
        yield cancel(subscriptionTask);
    } catch (error) {
        log.error(error);
        yield put(subscribeActions.subscribeElevatorStateFailure('error.appSync.elevatorStateSubscription'));
    } finally {
        channel.close();
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: action => action.meta?.id ?? 'subscribeElevatorState',
        },
        {
            pattern: types.SUBSCRIBE_ELEVATOR_STATE_REQUEST,
            handler: subscribeElevatorState,
        },
        {
            pattern: types.SUBSCRIBE_ELEVATOR_STATE_CANCEL,
            handler: noop,
        },
    );
}
