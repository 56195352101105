import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ApiReducerKey, EntityKey, Search, Sorting, SortingType, URL_ARRAY_SEPARATOR } from 'constants/index';
import { generateUTCDate, passArgs, passDeeperArgs } from 'services/utils';

import {
    arrayLengthComparatorFactory,
    dateComparatorFactory,
    numberComparatorFactory,
    stateComparatorFactory,
    stringComparatorFactory,
} from 'modules/table/utils/comparators';
import { Tables } from 'modules/table/types';
import { ContractField } from '../types';

import { Contract, ContractState } from '../types';

export const selectContractsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_CONTRACTS, ApiReducerKey.LIST);

export const selectContractsEntityList = (state): Contract[] =>
    state.entities[EntityKey.DOCUMENT_CONTRACTS][ApiReducerKey.LIST];

export const selectContracts = createSelector(selectContractsEntityList, (contracts): Contract[] =>
    contracts.map(contract => ({
        ...contract,
        status: contract.status?.toLowerCase().replaceAll(' ', '_') as ContractState,
    })),
);

function chooseContractsComparator(contract: Contract, sorting: Sorting) {
    switch (sorting.field) {
        case ContractField.STATUS:
            return stateComparatorFactory(sorting, Tables.CONTRACTS);
        case ContractField.VALID_UNTIL:
        case ContractField.START_DATE:
            return dateComparatorFactory(sorting);
        case ContractField.ELEVATORS:
            return arrayLengthComparatorFactory(sorting);

        default:
            const field = contract[sorting.field];

            return ['number', 'boolean'].includes(typeof field)
                ? numberComparatorFactory(sorting)
                : stringComparatorFactory(sorting);
    }
}

export const selectSortedContracts = createSelector(selectContracts, passArgs<Sorting>(), (contracts, sorting) => {
    if (!sorting || contracts.length === 0) {
        return contracts;
    }

    const comparator = chooseContractsComparator(contracts[0], sorting);

    return [...contracts].sort(comparator);
});

export const selectCountContractsByState = createSelector(selectContracts, contracts => {
    const counters = Object.fromEntries(Object.values(ContractState).map(key => [key, 0]));
    counters[ContractState.SHOW_ALL] = contracts.length;
    contracts.forEach(contract => {
        counters[contract.status]++;
    });
    return counters;
});

export const selectContractTypes = createSelector(selectContracts, contracts => {
    const contractTypes = contracts.map(contract => contract.contractType);
    const uniqueContractTypes = new Set(contractTypes);

    return Array.from(uniqueContractTypes)
        .map(contractType => ({ value: contractType, label: contractType }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC }));
});

export const selectFilteredContracts = createSelector(
    selectSortedContracts,
    passArgs<Sorting>(),
    passDeeperArgs<Search[]>(),
    (contracts, sorting, search) => {
        if (search?.length === 0 || contracts.length === 0) {
            return contracts;
        }

        return contracts.filter(contract => {
            return search.every(({ field, query }) => {
                const foundItem = get(contract, field);

                if (field === 'status' && query.includes(ContractState.SHOW_ALL)) return true;

                if (field === 'status') {
                    const querySplits = query.split(',');
                    return querySplits.includes(contract.status?.toLowerCase());
                }

                if (field.includes('frameworkAgreement')) {
                    if (query === 'true') return contract.frameworkAgreement;

                    return query === 'true' ? contract.frameworkAgreement : !contract.frameworkAgreement;
                }

                if (field.includes('contractType')) {
                    const arrayQuery = query.split(URL_ARRAY_SEPARATOR);

                    return arrayQuery.includes(contract.contractType);
                }

                if (field.includes('startDate') || field.includes('validUntil')) {
                    const [fieldName] = field.split('.');
                    const foundItemTime = generateUTCDate(new Date(contract[fieldName])).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === `${fieldName}.from`) {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === `${fieldName}.to`) {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                switch (typeof foundItem) {
                    case 'string':
                        return foundItem?.toLowerCase().includes(query.toLowerCase());
                    case 'number':
                        return Math.floor(foundItem) === parseInt(query);
                    default:
                        return foundItem === query;
                }
            });
        });
    },
);
