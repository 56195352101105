import enAntd from 'antd/es/locale-provider/en_US';
import deAntd from 'antd/es/locale-provider/de_DE';
import moment from 'moment';

import * as translations from 'translations';

enum Language {
    EN = 'en',
    DE = 'de',
}

const changeWeekStartDay = {
    week: {
        dow: 1,
    },
};

moment.locale(Language.EN, changeWeekStartDay);
moment.locale(Language.DE, changeWeekStartDay);

export const languages: string[] = Object.values(Language);

export const intlData = {
    [Language.EN]: translations[Language.EN],
    [Language.DE]: translations[Language.DE],
};

export const antdData = {
    [Language.EN]: enAntd,
    [Language.DE]: deAntd,
};
