import type { TRule } from 'fela';

export const title: TRule = () => ({
    fontSize: '24px',
    margin: 0,
    marginBottom: '0px !important',
});

export const description: TRule = () => ({
    fontSize: '0.875rem',
    color: '#8F8F8F',
    marginTop: '10px',
});

export const header: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const assignElevators: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
});
