import { Typography, Switch, Form } from 'antd';
import React from 'react';
import * as felaRules from './Notification.styles';
import { useFelaEnhanced } from 'hooks';

interface Notification {
    title: string;
    defaultChecked: string;
    id: string;
}

const Notification = ({ title, id, defaultChecked }) => {
    const { styles, css } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.Notification}>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                })}
            >
                <Typography.Text className={styles.title}>{title}</Typography.Text>
            </div>
            <div>
                <Form.Item name={id} valuePropName="checked" className={styles.toggle}>
                    <Switch defaultChecked={defaultChecked} />
                </Form.Item>
            </div>
        </div>
    );
};

export default Notification;
