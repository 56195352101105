import React, { FunctionComponent, createContext, Context, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { message } from 'antd';

const UIMessageContext = createContext(null);

type MessageType = 'info' | 'success' | 'warning' | 'error';

export type OpenUIMessage = (
    type: MessageType,
    message: string | { id: string; values: Record<string, any> },
    duration?: number,
) => void;

const UIMessageProvider: FunctionComponent & { Context: Context<OpenUIMessage> } = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const open: OpenUIMessage = useCallback(
        (type, message, duration = 3) => {
            messageApi.open({
                type,
                content: (
                    <span data-testid="ui-message">
                        {typeof message === 'string' ? (
                            <FormattedMessage id={message} />
                        ) : (
                            <FormattedMessage {...message} />
                        )}
                    </span>
                ),
                duration,
            });
        },
        [messageApi],
    );

    return (
        <UIMessageContext.Provider value={open}>
            {contextHolder}
            {children}
        </UIMessageContext.Provider>
    );
};

UIMessageProvider.Context = UIMessageContext;

export default UIMessageProvider;
