import { DocumentElevator, DocumentFile } from '../documents/types';

export enum ContractState {
    SHOW_ALL = 'show_all',
    CANCELATION_5_MONTHS = 'cancel_until_<_5_months',
    CANCELED = 'canceled',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

interface ServiceProvider {
    name: string;
    phone: string;
    billingStreet: string;
    billingPostalCode: string;
    billingCity: string;
}

export interface Contract {
    id: string;
    name: string;
    status: ContractState;
    contractType: string;
    frameworkAgreement: boolean;
    startDate: string;
    validUntil: string;
    noticePeriod: number;
    extensionPeriod: number;
    cancelUntil: string;
    cancellationDate: string;
    netPrice: number;
    serviceProvider: ServiceProvider;
    elevators: DocumentElevator[];
    file: DocumentFile;
}

export enum ContractField {
    STATUS = 'status',
    CONTRACT_TYPE = 'contractType',
    FRAMEWORK_AGREEMENT = 'frameworkAgreement',
    START_DATE = 'startDate',
    VALID_UNTIL = 'validUntil',
    CANCEL_UNTIL = 'cancelUntil',
    SERVICE_PROVIDER = 'serviceProvider.name',
    ELEVATORS = 'elevators',
    NET_PRICE = 'netPrice',
}
