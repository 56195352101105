import React from 'react';

const UnknownError = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design-system" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="assets" transform="translate(-1234.000000, -1336.000000)" fill="currentColor">
                <g id="Group" transform="translate(1232.000000, 1334.000000)">
                    <path
                        d="M12,20 C7.589,20 4,16.411 4,12 C4,7.589 7.589,4 12,4 C16.411,4 20,7.589 20,12 C20,16.411 16.411,20 12,20 M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2"
                        id="Fill-1"
                    />
                    <path
                        d="M13.3896,7.3262 C12.5456,6.8662 11.3326,6.9072 10.5146,7.3572 C10.0766,7.5972 9.7326,7.9322 9.4896,8.3542 C9.2496,8.7702 9.1266,9.2582 9.1266,9.8042 C9.1266,10.0462 9.2126,10.2602 9.3756,10.4202 C9.7076,10.7522 10.3006,10.7412 10.6176,10.4182 C10.7746,10.2562 10.8586,10.0432 10.8586,9.8042 C10.8586,9.4402 10.9516,9.1602 11.1436,8.9472 C11.3256,8.7442 11.6136,8.6462 12.0256,8.6462 C12.2376,8.6462 12.4126,8.6902 12.5496,8.7772 C12.6936,8.8682 12.8006,8.9812 12.8766,9.1282 C12.9566,9.2732 12.9946,9.4272 12.9946,9.5952 C12.9946,9.8472 12.9536,10.0612 12.8756,10.2282 C12.7906,10.4062 12.6806,10.5632 12.5426,10.6992 C12.3866,10.8572 12.2146,11.0152 12.0276,11.1772 C11.6986,11.4592 11.4516,11.7872 11.2906,12.1582 C11.1346,12.5242 11.0546,12.8692 11.0546,13.1812 C11.0546,13.4222 11.1406,13.6352 11.3026,13.7962 C11.4646,13.9612 11.6786,14.0472 11.9206,14.0472 C12.1716,14.0472 12.3886,13.9592 12.5486,13.7922 C12.7046,13.6312 12.7866,13.4192 12.7866,13.1812 C12.7866,13.1022 12.8026,12.9562 12.8766,12.7082 C12.9276,12.5402 13.0146,12.4072 13.1406,12.3052 C13.4306,12.0742 13.6916,11.8372 13.9136,11.6032 C14.1586,11.3472 14.3516,11.0562 14.4886,10.7402 C14.6316,10.4142 14.7046,10.0292 14.7046,9.5952 C14.7046,9.0832 14.5846,8.6272 14.3486,8.2362 C14.1116,7.8492 13.7886,7.5432 13.3896,7.3262"
                        id="Fill-3"
                    />
                    <path
                        d="M11.1787,15.1943 C10.9757,15.3983 10.8677,15.6583 10.8677,15.9463 C10.8677,16.2333 10.9727,16.4823 11.1807,16.6893 C11.3847,16.8923 11.6417,17.0003 11.9207,17.0003 C12.2117,17.0003 12.4647,16.8953 12.6737,16.6883 C12.8767,16.4843 12.9857,16.2273 12.9857,15.9463 C12.9857,15.6593 12.8767,15.3983 12.6727,15.1933 C12.2667,14.7903 11.6007,14.7763 11.1787,15.1943"
                        id="Fill-5"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default UnknownError;
