import React from 'react';

import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';

import useInvoiceList from './useInvoiceList';

const useInvoicesListTable = ref => {
    const sorting = useOrderSearchParams();
    const { search, defaultValue: defaultValueSearch } = useSearchSearchParams();
    const { invoices, api } = useInvoiceList(sorting, search);

    React.useEffect(() => {
        if (invoices) {
            ref.current?.reload();
        }
    }, [ref, invoices]);

    return { invoices, defaultValueSearch, api };
};
export default useInvoicesListTable;
