import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import { useUploadNewElevatorDocument } from 'modules/elevator-new/hooks/useUploadNewElevatorDocument';

import type { DialogStateValue } from '../../types';
import { NewElevatorDialogContext } from '../../contexts/NewElevatorDialogContext';

interface NewElevatorDialogProviderProps {
    children: ReactNode;
}

export const NewElevatorDialogProvider = ({ children }: NewElevatorDialogProviderProps) => {
    const [state, setState] = useState<DialogStateValue>(null);

    const { api, uploadDocument, resetUpload } = useUploadNewElevatorDocument();

    const context = useMemo(
        () => ({
            state,
            navigate: (state: DialogStateValue) => {
                setState(state);
            },
            upload: {
                api,
                uploadDocument,
                resetUpload,
            },
        }),
        [state, setState, api, uploadDocument, resetUpload],
    );

    return <NewElevatorDialogContext.Provider value={context}>{children}</NewElevatorDialogContext.Provider>;
};
