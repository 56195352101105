import { basicApiReducer } from '@ackee/redux-utils';
import { fetchClosedIncidentsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_REQUEST,
        SUCCESS: fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_SUCCESS,
        FAILURE: fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_FAILURE,
        CANCEL: fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_CANCEL,
        RESET: fetchClosedIncidentsTypes.FETCH_CLOSED_INCIDENTS_RESET,
    },
});
