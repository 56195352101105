import React from 'react';

const Envelope = () => (
    <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="elevator_detail_contacts" transform="translate(-1397.000000, -230.000000)" fill="currentColor">
                <g id="Fill-1" transform="translate(1396.000000, 227.000000)">
                    <path d="M3,11 L3,6.116 L7.479,8.854 C7.639,8.951 7.819,9 8,9 C8.181,9 8.361,8.951 8.521,8.854 L13,6.116 L13,11 L3,11 Z M10.991,5 L8,6.828 L5.009,5 L10.991,5 Z M14,3 L2,3 C1.447,3 1,3.447 1,4 L1,12 C1,12.553 1.447,13 2,13 L14,13 C14.553,13 15,12.553 15,12 L15,4 C15,3.447 14.553,3 14,3 L14,3 Z" />
                </g>
            </g>
        </g>
    </svg>
);

export default Envelope;
