import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Table, useUrlPagination } from 'modules/table';
import { Icon, IconType } from 'modules/ui';
import type { Elevator } from 'modules/entities/modules/elevators';

import ElevatorDetailLink from '../ElevatorDetailLink';
import { ElevatorStatusIcon } from '../ElevatorStatusIcon';

import * as felaRules from './ElevatorsTable.rules';

export interface ElevatorsTableProps {
    loading: boolean;
    elevators: Elevator[];
}

export const ElevatorsTable = ({ loading, elevators }: ElevatorsTableProps) => {
    const { rules, styles } = useFelaEnhanced(felaRules);

    const pagination = useUrlPagination();

    return (
        <Table<Elevator>
            hideSort={false}
            toolBarRender={false}
            search={false}
            extend={{ table: rules.table }}
            columns={[
                {
                    title: <FormattedMessage id="page.elevators.table.status" />,
                    key: 'state.state',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>
                            <ElevatorStatusIcon state={record.state?.state} />
                        </ElevatorDetailLink>
                    ),
                },
                {
                    title: <FormattedMessage id="page.elevators.table.factoryNumber" />,
                    key: 'factoryNumber',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>{record.factoryNumber}</ElevatorDetailLink>
                    ),
                },
                {
                    title: <FormattedMessage id="page.elevators.table.street" />,
                    key: 'propertyUnit.street',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>{record.propertyUnit.street}</ElevatorDetailLink>
                    ),
                },
                // {
                //     title: <FormattedMessage id="page.elevators.table.postalCode" />,
                //     key: 'propertyUnit.postalCode',
                //     render: (_, record) => (
                //         <ElevatorDetailLink elevatorId={record.id}>{record.propertyUnit.postalCode}</ElevatorDetailLink>
                //     ),
                // },
                {
                    title: <FormattedMessage id="page.elevators.table.city" />,
                    key: 'propertyUnit.city',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>{record.propertyUnit.city}</ElevatorDetailLink>
                    ),
                },
                {
                    title: <FormattedMessage id="page.elevators.table.businessUnit" />,
                    key: 'propertyUnit.businessUnit',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>
                            {record.propertyUnit.businessUnit}
                        </ElevatorDetailLink>
                    ),
                },
                {
                    title: <FormattedMessage id="page.elevators.table.owner" />,
                    key: 'owner',
                    className: 'owner',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>{record.owner}</ElevatorDetailLink>
                    ),
                },
                {
                    title: <FormattedMessage id="page.elevators.table.hardware" />,
                    key: 'owner',
                    className: 'hardware',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>
                            <div className={styles.hardwareContainer}>
                                <div
                                    className={
                                        record?.devices?.find(
                                            device => device.type === 'IOT' && device.deviceInstalled === true,
                                        )
                                            ? styles.hardwareActive
                                            : styles.hardwareDisabled
                                    }
                                >
                                    <div><FormattedMessage id="page.elevators.table.hardware.iot" /></div>
                                    <div className={styles.hardwareCheckBox}>
                                        {record?.devices?.find(
                                            device => device.type === 'IOT' && device.deviceInstalled === true,
                                        ) && <Icon type={IconType.GreenCheck} />}
                                    </div>
                                </div>
                                <div
                                    className={
                                        record?.devices?.find(
                                            device => device.type === 'Smart_Flap' && device.deviceInstalled === true,
                                        )
                                            ? styles.hardwareActive
                                            : styles.hardwareDisabled
                                    }
                                >
                                    <div><FormattedMessage id="page.elevators.table.hardware.smartflap" /></div>
                                    <div className={styles.hardwareCheckBox}>
                                        {record?.devices?.find(
                                            device => device.type === 'Smart_Flap' && device.deviceInstalled === true,
                                        ) && <Icon type={IconType.GreenCheck} />}
                                    </div>
                                </div>
                            </div>
                        </ElevatorDetailLink>
                    ),
                },
                {
                    key: 'action',
                    render: (_, record) => (
                        <ElevatorDetailLink elevatorId={record.id}>
                            <Icon type={IconType.CHEVRON_RIGHT} />
                        </ElevatorDetailLink>
                    ),
                },
            ]}
            rowKey="id"
            loading={loading}
            dataSource={elevators}
            pagination={pagination}
            data-testid="elevators-table"
        />
    );
};
