import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchSupports as actions } from '../services/actions';
import { selectSupportRequestsApi } from '../services/selectors';

export const useFetchSupports = () => {
    const api: ApiReducerState = useSelector(selectSupportRequestsApi);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(actions.fetchSupportsRequest());

        return () => {
            batch(() => {
                dispatch(actions.fetchSupportsCancel());
                dispatch(actions.fetchSupportsReset());
            });
        };
    }, [dispatch]);

    return api;
};
