import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{ active: boolean }> = ({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',

    '& .anticon:first-child': {
        fontSize: 16,
        marginRight: 'auto',
    },

    extend: {
        condition: active,
        style: {
            backgroundColor: theme.colors.selectedSecondary,
        },
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    marginLeft: '.25rem',
});
