import { AxiosError } from 'axios';

import { init, createReduxEnhancer, captureException, ErrorBoundary } from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

import config from 'config/config';
import { environments, currentEnv } from 'constants/index';

import { HTTPStatusCode } from 'modules/errors';

export const ErrorMessage = {
    RESIZE_OBSERVER: 'ResizeObserver loop limit exceeded',
};

if (process.env.NODE_ENV === environments.PRODUCTION) {
    init({
        dsn: config.sentry.dsn,
        debug: false,
        environment: currentEnv,
        release: `simplifa-web@${process.env.REACT_APP_VERSION}`,
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
        normalizeDepth: 5,
        integrations: [new ExtraErrorDataIntegration()],
        beforeSend: (event, hint) => {
            const originalException = hint?.originalException as AxiosError;

            if (originalException?.response?.status === HTTPStatusCode.NotFound) {
                return null;
            }

            return event;
        },
        ignoreErrors: [ErrorMessage.RESIZE_OBSERVER],
    });
}

const sentryReduxEnhancer = createReduxEnhancer();

export { sentryReduxEnhancer, captureException, ErrorBoundary };
