import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AuthPage from '../AuthPage';
import ForgottenPasswordForm from '../ForgottenPasswordForm';
import ForgottenPasswordEmailForm from '../ForgottenPasswordEmailForm';
import { ForgotPasswordSteps } from '../../types';

type ResetPasswordPageProps = {};

const ForgottenPasswordPage: FC<ResetPasswordPageProps> = () => {
    const params = useParams<{ step: ForgotPasswordSteps }>();

    return (
        <AuthPage>
            {params.step === ForgotPasswordSteps.PASSWORD ? <ForgottenPasswordForm /> : <ForgottenPasswordEmailForm />}
        </AuthPage>
    );
};

export default ForgottenPasswordPage;
