import { error } from 'config/loglevel';
import { backOff } from 'exponential-backoff';

const STARTING_DELAY = 750;

const geocodeAddress = async (geocoder: google.maps.Geocoder, address: string) => {
    const { results } = await geocoder.geocode({ address });

    return results[0].geometry.location.toJSON();
};

export const geocode = async (geocoder: google.maps.Geocoder, address: string) => {
    return backOff(() => geocodeAddress(geocoder, address), {
        delayFirstAttempt: true,
        startingDelay: STARTING_DELAY,
        retry: err => {
            error(`Geocoding API error: ${err}`);
            return true;
        },
    });
};
