import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'antd';
import isEmpty from 'lodash/isEmpty';
import config from 'config/index';
import { useFelaEnhanced } from 'hooks';

import { Button } from 'modules/ui';
import { useDownloadDocuments, DocumentFile, DocumentDownloadType } from 'modules/entities/modules/documents';

import * as felaRules from './DocumentsDownloadAll.rules';
import {
    DOCUMENT_CURRENT_PAGE_STORAGE_KEY,
    DOCUMENT_PAGE_SIZE_STORAGE_KEY,
    PAGE_SIZE_STORAGE_KEY,
} from 'modules/table';

interface DocumentsDownloadAllProps<T> {
    documents: T[];
}

export function DocumentsDownloadAll<
    T extends {
        file?: DocumentFile;
        id: string;
    }
>({ documents }: DocumentsDownloadAllProps<T>) {
    const { downloadDocuments, selectDocument, resetDownloadSelect, selectedDocuments, api } = useDownloadDocuments();

    const { styles } = useFelaEnhanced(felaRules);

    const defaultPageSize = sessionStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    const pageSizeD = defaultPageSize ? parseInt(defaultPageSize) : config.tables.defaultPageSize;
    const pageSize = Number(sessionStorage.getItem(DOCUMENT_PAGE_SIZE_STORAGE_KEY)) || pageSizeD;
    const currentPage = Number(sessionStorage.getItem(DOCUMENT_CURRENT_PAGE_STORAGE_KEY)) || 1;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const dd = useMemo(
        () =>
            documents
                .slice(startIndex, endIndex)
                .filter(({ file }) => Boolean(file))
                .map(({ file, id }) => ({ file, id })),
        [documents, startIndex, endIndex],
    );

    useEffect(() => {
        return () => {
          // cleanup when component unmounts
          resetDownloadSelect();
        };
      }, [resetDownloadSelect]);

    const noDownloadableDocuments = isEmpty(dd); // is there any downloadable on this page
    return (
        <div className={styles.wrapper}>
            <Checkbox
                className={styles.checkbox}
                onChange={e => {
                    if (e.target.checked) {
                        selectDocument(dd.map(doc => doc.id));
                    } else {
                        resetDownloadSelect();
                    }
                }}
                disabled={noDownloadableDocuments}
                checked={
                    selectedDocuments.length > 0 && selectedDocuments.length === dd.length && !noDownloadableDocuments
                }
            >
                <FormattedMessage id="table.selectAll" />
            </Checkbox>
            <Button
                disabled={selectedDocuments.length === 0}
                onClick={() => {
                    const files = selectedDocuments
                        .map(docId => {
                            const doc = dd.find(doc => doc.id === docId);
                            return doc?.file;
                        })
                        .filter(Boolean);
                    downloadDocuments(files, DocumentDownloadType.DOWNLOAD);
                }}
                loading={api.inProgress && !(selectedDocuments.length === 0)}
            >
                <FormattedMessage id="table.download" />

                {selectedDocuments.length > 0 && `(${selectedDocuments.length}/${pageSize})`}
            </Button>
        </div>
    );
}
