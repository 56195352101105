import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './SupportPage.rules';
import { SeoHead } from 'modules/seo';
import SupportTabs from '../SupportTabs';
import { useSupports } from 'modules/entities/modules/support/hooks/useSupports';
import { NewSupportButton } from '../NewSupportButton';

export const SupportPage = () => {
    const { openSupports, closedSupports, defaultSearch, loading } = useSupports();
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'support.request.seoTitle' })} />
            <div className={styles.header}>
                <Typography.Title level={2} className={styles.title}>
                    <FormattedMessage id="page.support.header.title" />
                </Typography.Title>
                <NewSupportButton />
            </div>
            <SupportTabs
                openSupports={openSupports}
                closedSupports={closedSupports}
                defaultSearch={defaultSearch}
                loading={loading}
            />
        </div>
    );
};
