import { useEffect } from 'react';

import { useUIMessage } from 'modules/ui';

function useDisplayError(error: string) {
    const message = useUIMessage();

    useEffect(() => {
        if (error) {
            message('error', error, 5);
        }
    }, [error, message]);
}

export default useDisplayError;
