import { combineReducers } from '@reduxjs/toolkit';

import { ApiReducerKey } from 'constants/index';

import { listReducerFactory } from 'services/utils/reducers';

import { fetchAssessmentReportsSuccess, resetAssessmentReports } from '../actions';

export const entityReducer = combineReducers({
    [ApiReducerKey.LIST]: listReducerFactory({
        setActionCreator: fetchAssessmentReportsSuccess,
        resetActionCreator: resetAssessmentReports,
    }),
});
