import { createAction } from '@reduxjs/toolkit';
import { apiRequestActions } from '@ackee/redux-utils';

import { EntityKey } from 'constants/index';

import type { TokenPaginationApiReducerState } from 'modules/entities/services/reducers/tokenPaginationApi';

import { fetchElevatorStatesTypes } from './types';

import { Elevator } from '../../../elevators/types';

interface FetchStatesMeta {
    id: Elevator['id'];
    pageSize?: TokenPaginationApiReducerState['pageSize'];
    pageToken?: TokenPaginationApiReducerState['pageTokens']['share'];
    enableShareToken: boolean;
}

type PrepareFetchElevatorStatesAction = (
    meta: FetchStatesMeta,
) => {
    meta: FetchStatesMeta;
    payload: null;
};

const prepareFetchStatesAction: PrepareFetchElevatorStatesAction = meta => ({
    meta,
    payload: null,
});

export const fetchElevatorStates = createAction<PrepareFetchElevatorStatesAction>(
    `${EntityKey.ELEVATOR_STATES}/FETCH_ELEVATOR_STATES`,
    prepareFetchStatesAction,
);

export const fetchElevatorStatesAbort = createAction(`${EntityKey.ELEVATOR_STATES}/FETCH_ELEVATOR_STATES_ABORT`);

export const clearElevatorStates = createAction(`${EntityKey.ELEVATOR_STATES}/CLEAR_ELEVATOR_STATES`);

export type FetchElevatorStatesAction = ReturnType<typeof fetchElevatorStates>;
export type FetchElevatorStatesAbortAction = ReturnType<typeof fetchElevatorStatesAbort>;
export type ClearElevatorStatesAction = ReturnType<typeof clearElevatorStates>;

export default {
    ...apiRequestActions(fetchElevatorStatesTypes, { isDetailRequest: true }),
    fetchElevatorStates,
    fetchElevatorStatesAbort,
    clearElevatorStates,
};
