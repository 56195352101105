import React from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './Divider.rules';

export const Divider = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return <hr className={styles.divider} />;
};
