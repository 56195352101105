import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkorderType } from '../types';

import { fetchWorkordersClear, fetchWorkorders } from '../services/actions';
import { selectWorkordersApi } from '../services/selectors';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

export const useFetchWorkorders = (type: WorkorderType) => {
    const dispatch = useDispatch();

    const api = useSelector(selectWorkordersApi);
    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        if(isElevatorDetail){
            dispatch(fetchWorkorders({ type }, id));
        }else{
            dispatch(fetchWorkorders({ type })); 
        }
        

        return () => {
            dispatch(fetchWorkordersClear());
        };
    }, [dispatch, type, id, isElevatorDetail]);

    return api;
};
