import type { TRuleWithTheme } from 'styles/theme';

export const cta: TRuleWithTheme = () => ({
    paddingTop: '2rem',
    paddingRight: '2rem',
    paddingBottom: '2.5rem',
    paddingLeft: '2rem',

    textAlign: 'left',
});

export const ctaTitle: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        maxWidth: 411,
        marginRight: 'auto',

        color: theme.colors.white,

        fontSize: '1.5rem',
        lineHeight: '1.33em',

        marginBottom: '1em',
    },
});

export const ctaFullTitle: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginRight: 'auto',

        color: theme.colors.white,

        fontSize: '1.5rem',
        lineHeight: '1.33em',

        marginBottom: '1em',
    },
});
