import React, { FC, useEffect } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';
import type { Location } from 'history';
import { generatePath } from 'react-router';

import { useFelaEnhanced } from 'hooks';
import { Logo, useUIMessage } from 'modules/ui';
import { useFormSubmission } from 'modules/form';
import config from 'config/index';

import { forms, fields } from '../../config';
import type { LoginFormValues } from '../../types';
import AuthForm, { SubmitAuthFormButton } from '../AuthForm';
import { isFormSubmissionError } from '../../utils';
import * as rules from './LoginForm.styles';
import { ForgotPasswordSteps } from '../../types';

export interface LoginFormProps {}

const LoginForm: FC<LoginFormProps> = () => {
    const { styles } = useFelaEnhanced(rules);
    const { formatMessage } = useIntl();
    const location: Location<{ passwordConfirmed: boolean }> = useLocation();
    const message = useUIMessage();
    const { handleSubmit, submitting, error } = useFormSubmission<LoginFormValues>(forms.login);

    useEffect(() => {
        if (location.state?.passwordConfirmed) {
            message('info', 'auth.passwordWasChanged');
        }
    }, [location.state, message]);

    return (
        <>
            <AuthForm name={forms.login} layout="vertical" className={styles.form} onFinish={handleSubmit}>
                <Logo className={styles.logo} />

                <Form.Item
                    name={fields.login.email}
                    label={formatMessage({ id: 'auth.email' })}
                    rules={[{ required: true, message: formatMessage({ id: 'form.validation.required.email' }) }]}
                >
                    <Input
                        type="email"
                        size="large"
                        autoComplete="email"
                        placeholder={formatMessage({ id: 'auth.email' })}
                    />
                </Form.Item>

                <Form.Item
                    name={fields.login.password}
                    label={formatMessage({ id: 'auth.password' })}
                    rules={[{ required: true, message: formatMessage({ id: 'form.validation.required.password' }) }]}
                    validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                    help={isFormSubmissionError(error) ? formatMessage({ id: error }) : undefined}
                >
                    <Input
                        type="password"
                        size="large"
                        autoComplete="current-password"
                        placeholder={formatMessage({ id: 'auth.password' })}
                    />
                </Form.Item>

                <Form.Item>
                    <SubmitAuthFormButton
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                    >
                        {formatMessage({ id: 'auth.button.login' })}
                    </SubmitAuthFormButton>
                </Form.Item>
                <div className={styles.link}>
                    <Link to={config.routes.registration}>
                        <FormattedMessage id="auth.login.registration" />
                    </Link>
                </div>
                <div className={styles.link}>
                    <Link to={generatePath(config.routes.forgotPassword, { step: ForgotPasswordSteps.EMAIL })}>
                        <FormattedMessage id="auth.login.forgottenPassword" />
                    </Link>
                </div>
            </AuthForm>
        </>
    );
};

export default LoginForm;
