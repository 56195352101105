import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    marginTop: '25px',
});

export const card: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '1.5rem',

    ':last-of-type': {
        marginBottom: 0,
    },
});

export const rowName: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    fontWeight: 600,
});

export const rowNameContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
});

export const divider: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    height: 1,
    backgroundColor: theme.colors.dividerColor,
    margin: '1.25rem 0',
});
