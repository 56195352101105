import { put, cancelled } from 'redux-saga/effects';

import { noop } from 'lodash';
import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import { fetchWorkorderTypes, fetchWorkorders as actions } from '../actions';
import { Workorder } from '../../types';

function* fetchWorkorders() {
    const controller = new AbortController();

    try {
        const { data } = yield* authApi.get<Workorder[]>(config.api.workorders, {
            signal: controller.signal,
        });
        yield put(actions.fetchWorkordersSuccess(data));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchWorkordersFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchWorkordersRequest',
            leading: true,
        },
        {
            pattern: fetchWorkorderTypes.FETCH_WORKORDERS_REQUEST,
            handler: fetchWorkorders,
        },
        {
            pattern: fetchWorkorderTypes.FETCH_WORKORDERS_CANCEL,
            handler: noop,
        },
    );
}
