import { combineRules } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',

    position: 'relative',

    width: 200,

    marginTop: '0.25rem',
    marginRight: 'auto',
    marginBottom: '0.75rem',
    marginLeft: 'auto',

    overflow: 'hidden',
});

export const scale: TRuleWithTheme = () => ({
    position: 'relative',
    zIndex: 0,

    width: '100%',
});

export const value: TRuleWithTheme<{
    angle: number;
}> = ({ angle }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 1,

    width: '100%',
    height: 2,

    transform: `rotate(${angle}deg)`,

    background: ['black', 'linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 50%)'],
});

const bound: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: -1,

    fontSize: '0.875rem',
    lineHeight: 1.43,

    color: theme.colors.textSecondary,
});

export const lowerBound = combineRules(bound, () => ({
    left: 3,
}));

export const upperBound = combineRules(bound, () => ({
    right: 4,
}));
