import { ContactType, elevatorHasDevice, selectors as elevatorsSelectors } from '../../../entities/modules/elevators';
import { createSelector } from 'reselect';

const contactKeys = Object.keys(ContactType);

const contactValues = contactKeys.map(key => ContactType[key]);

const selectElevatorContacts = createSelector(elevatorsSelectors.selectElevator, elevator => elevator?.contacts ?? []);

export const selectElevatorContactsSorted = createSelector(selectElevatorContacts, contacts =>
    contacts.sort((a, b) => contactValues.indexOf(a.type) - contactValues.indexOf(b.type)),
);

export const selectContactData = createSelector(
    elevatorsSelectors.selectElevator,
    selectElevatorContactsSorted,
    (elevator, contacts) => ({
        factoryNumber: elevator ? elevator.factoryNumber : null,
        contacts,
    }),
);

export const selectElevatorState = createSelector(
    elevatorsSelectors.selectElevator,
    elevator => elevator?.state?.state,
);

export const selectElevatorHasIoTDevice = createSelector(elevatorsSelectors.selectElevator, elevator =>
    elevatorHasDevice(elevator),
);
