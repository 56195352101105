import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.IOT_CONFIG,
});

export const fetchIotConfigTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_IOT_CONFIG_' }),
});