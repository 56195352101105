import { TRuleWithTheme } from 'styles/theme';
import { combineRules } from 'fela';

const calculatePadding = size => {
    switch (size) {
        case 'small':
            return '.75rem 1rem';
        default:
            return '1rem';
    }
};

export const popoverItem: TRuleWithTheme<{ size: 'small' | 'medium' }> = ({ theme, size }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 218,
    boxSizing: 'border-box',
    padding: calculatePadding(size),
    borderBottom: `1px solid ${theme.colors.dividerColor}`,

    ':last-of-type': {
        borderBottom: 'none',
    },
});
export const title: TRuleWithTheme = ({ theme }) => ({
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    marginBottom: '.125rem',
});
const basicText: TRuleWithTheme = () => ({
    fontSize: '.75rem',
    lineHeight: '1rem',
});
export const text: TRuleWithTheme = combineRules(basicText, ({ theme }) => ({
    marginBottom: '.125rem',
}));
export const secondaryText: TRuleWithTheme = combineRules(basicText, ({ theme }) => ({
    color: theme.colors.textSecondary,
}));
