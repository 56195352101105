import React from 'react';

const MapPin = () => (
    <svg width="14px" height="20px" viewBox="0 0 14 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="elevator_detail" transform="translate(-523.000000, -102.000000)" fill="currentColor">
                <g id="Group" transform="translate(518.000000, 100.000000)">
                    <path
                        d="M12,19.7559 C7.087,15.8689 7,9.0859 7,8.9999 C7,6.2429 9.243,3.9999 12,3.9999 C14.757,3.9999 17,6.2429 17,8.9999 C17,9.0859 16.913,15.8689 12,19.7559 M12,1.9999 C8.141,1.9999 5,5.1409 5,8.9999 C5,9.3419 5.073,17.4179 11.431,21.8219 C11.602,21.9409 11.801,21.9999 12,21.9999 C12.199,21.9999 12.398,21.9409 12.569,21.8219 C18.927,17.4179 19,9.3419 19,8.9999 C19,5.1409 15.859,1.9999 12,1.9999"
                        id="Fill-1"
                    />
                    <path
                        d="M12,10 C11.448,10 11,9.552 11,9 C11,8.448 11.448,8 12,8 C12.552,8 13,8.448 13,9 C13,9.552 12.552,10 12,10 M12,6 C10.346,6 9,7.346 9,9 C9,10.654 10.346,12 12,12 C13.654,12 15,10.654 15,9 C15,7.346 13.654,6 12,6"
                        id="Fill-4"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default MapPin;
