import { get, isEqual } from 'lodash';
import moment from 'moment';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { apiSelector, ApiReducerState } from '@ackee/redux-utils';

import { EntityKey, ApiReducerKey, SortingType } from 'constants/index';
import type { Search, Sorting } from 'constants/index';

import { compareValueByType } from 'services/utils';

import { defaultCompare, stringComparatorFactory } from 'modules/table';
import { coordinatesSelector } from 'modules/entities/modules/elevator-coordinates/services/selectors';

import type { ListReducerState } from '../../../../types';
import type { Elevator, ElevatorGroup } from '../../types';

import { ElevatorState, ElevatorField, ElevatorStateGroup } from '../../types';
import { getPropertyUnitAdress, isSupportedState } from '../utils';
import { SUPPORTED_ELEVATOR_STATES } from '../../constants';
import { EXPECTED_MATURED_DAYS } from 'constants/elevator';

export const selectElevatorsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.ELEVATORS, ApiReducerKey.LIST);

const selectElevatorsEntityList = (state): ListReducerState<Elevator> =>
    state.entities[EntityKey.ELEVATORS][ApiReducerKey.LIST];

export const selectElevators = createSelector(selectElevatorsEntityList, ({ byIds, ids }): Elevator[] => {
    const elevatorCheck = byIds[ids[0]];
    if (!elevatorCheck?.id) {
        return [];
    }
    return ids.map(id => {
        const elevator = byIds[id];

        if (elevator?.mappedDevice) {
            const dtime = elevator.mappedDevice.validFrom * 1000;
            elevator.mappedDevice.daysAgoItWasInstalled = moment().diff(moment(dtime), 'days');

            const dateDiff = elevator?.devices[0]?.installation
                ? moment().diff(moment(elevator.devices[0].installation), 'days')
                : 0;

            if (!!elevator?.devices[0]?.installation && dateDiff < EXPECTED_MATURED_DAYS) {
                elevator.state.state = ElevatorState.UNKNOWN;
            }
        }

        return elevator;
    });
});

export const selectElevatorsCount = createSelector([selectElevators], elevators => elevators.length);

const ElevatorStateAndGroup = [...SUPPORTED_ELEVATOR_STATES, ElevatorStateGroup.UNKNOWN];

export const selectElevatorsCountByState = createSelector([selectElevators], elevators =>
    ElevatorStateAndGroup.reduce<Record<ElevatorState | ElevatorStateGroup, number>>((counts, filter) => {
        counts[filter] = elevators.filter(({ state }) => {
            switch (filter) {
                case ElevatorStateGroup.UNKNOWN: {
                    return !isSupportedState(state?.state) || state?.state === ElevatorState.DEVICE_OFFLINE;
                }
                default: {
                    return state?.state === filter;
                }
            }
        }).length;

        return counts;
    }, {} as Record<ElevatorState | ElevatorStateGroup, number>),
);

export const stateOrderMap = {
    [ElevatorState.OUT_OF_ORDER]: 0,
    [ElevatorState.POTENTIAL_ISSUES]: 1,
    [ElevatorState.TECHNICIAN_ON_SITE]: 2,
    [ElevatorState.RUNNING]: 3,
    [ElevatorState.DEVICE_OFFLINE]: 4,
    [ElevatorStateGroup.UNKNOWN]: 5,
};

export const getElevatorStateOrder = (elevator: Elevator) =>
    stateOrderMap[isSupportedState(elevator?.state?.state) ? elevator?.state?.state : ElevatorStateGroup.UNKNOWN];

export function stateComparatorFactory(type: SortingType) {
    return (a: Elevator, b: Elevator) => {
        const orderA = getElevatorStateOrder(a);
        const orderB = getElevatorStateOrder(b);

        return type === SortingType.ASC ? orderA - orderB : orderB - orderA;
    };
}

const sortElevatorsFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case ElevatorField.OWNER:
        case ElevatorField.FACTORY_NUMBER:
        case ElevatorField.PROPERTY_UNIT_STREET:
        case ElevatorField.PROPERTY_UNIT_CITY:
        case ElevatorField.PROPERTY_UNIT_POSTAL_CODE:
        case ElevatorField.PROPERTY_UNIT_BUSINESS_UNIT: {
            return stringComparatorFactory(sorting, '');
        }
        case ElevatorField.STATUS: {
            return stateComparatorFactory(sorting.type);
        }
        default: {
            return defaultCompare;
        }
    }
};

export const selectFilteredElevators = createSelector(
    [
        selectElevators,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (elevators, search) =>
        elevators.filter(elevator =>
            search.every(({ field, query }) => {
                console.log(field, query);
                if (field === ElevatorField.STATUS) {
                    return SUPPORTED_ELEVATOR_STATES.includes(elevator.state?.state) &&
                        elevator.state?.state !== ElevatorState.DEVICE_OFFLINE
                        ? elevator.state?.state && query.includes(elevator.state.state)
                        : query.includes(ElevatorStateGroup.UNKNOWN);
                }

                if (field === ElevatorField.HARDWARE) {
                    const paths = query.split('|');
                    if (paths && paths.length && elevator && elevator.devices && elevator.devices.length) {
                        for (let i = 0; i < elevator.devices.length; i++) {
                            if (paths.includes(elevator.devices[i]?.type) && elevator.devices[i]?.deviceInstalled) {
                                return true;
                            }
                        }
                    }
                    return false;
                }

                return compareValueByType({
                    value: get(elevator, field),
                    query,
                });
            }),
        ),
);

export const selectSortedElevators = createSelector(
    [
        selectFilteredElevators,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => [...docs].sort(sortElevatorsFactory(sorting)),
);

const createDeepComparisonSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectElevatorGroups = createDeepComparisonSelector(
    [selectSortedElevators, coordinatesSelector],
    (elevators, coordinates) => {
        console.log(elevators);
        const elevatorGroupsMap = elevators.reduce((groups, elevator) => {
            const { propertyUnit } = elevator;
            const state = elevator?.state?.state || '';

            const propertyUnitAddress = getPropertyUnitAdress(propertyUnit);
            const coordinate = coordinates[propertyUnitAddress];

            // console.log("propertyUnitpropertyUnitpropertyUnitpropertyUnit", propertyUnit)

            // or if the property unit has no coordinates
            if (!coordinate?.lng && !propertyUnit?.longitude) {
                return groups;
            }

            if (!groups.has(propertyUnitAddress + state)) {
                groups.set(propertyUnitAddress + state, {
                    propertyUnit: {
                        address: propertyUnitAddress,
                        ...elevator.propertyUnit,
                        ...coordinate,
                        ...(propertyUnit?.longitude && propertyUnit?.latitude
                            ? { lat: propertyUnit.latitude, lng: propertyUnit.longitude }
                            : {}),
                    },
                    elevators: [elevator],
                });
            } else {
                groups.get(propertyUnitAddress + state).elevators.push(elevator);
            }

            return groups;
        }, new Map<string, ElevatorGroup>());

        return [...elevatorGroupsMap.values()];
    },
);
