import { AverageTrips } from '../../../entities/modules/trips';
import { FormatDateOptions } from '@formatjs/intl/src/types';

const today = new Date();

const generateTotalTripsTooltip = (
    trip: AverageTrips,
    formatTime: (value: Parameters<Intl.DateTimeFormat['format']>[0] | string, opts?: FormatDateOptions) => string,
) => {
    if (!trip.bucket.includes('-')) return '';
    const nameToTime = trip.bucket.split('-').reduce((nameMap, hours, index) => {
        const trimmed = hours.trim();
        const utcDate = new Date(
            Date.UTC(today.getFullYear(), today.getMonth(), today.getUTCDate(), parseInt(trimmed)),
        );
        utcDate.setMinutes(today.getTimezoneOffset());
        nameMap[index === 0 ? 'from' : 'to'] = utcDate.toString();
        return nameMap;
    }, {} as Record<'from' | 'to', string>);

    return `${formatTime(nameToTime.from)} - ${formatTime(nameToTime.to)}`;
};

export default generateTotalTripsTooltip;
