import React, { FC } from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import { Invoice } from 'modules/entities/modules/documents-invoices';

import { sortFields } from '../constants';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

import { defaultSort } from '../../../hooks/useInvoiceList';

type ActionPanelProps = {
    invoices: Invoice[];
};

const ActionPanel: FC<ActionPanelProps> = ({ invoices }) => (
    <TableActionPanel>
        <TableSort fields={sortFields} defaultField={defaultSort.field} defaultType={defaultSort.type} />
        <DocumentsDownloadAll documents={invoices} />
    </TableActionPanel>
);

export default ActionPanel;
