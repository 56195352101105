import { Elevator } from '../elevators';

export enum IncidentStatus {
    Neu = 'Neu',
    Störungsbeseitigung = 'Störungsbeseitigung',
    Reparatur = 'Reparatur',
    Erledigt = 'Erledigt',
    Entstörung = 'Entstörung',
}

export enum IncidentStatusFilter {
    NEW = 'Neu',
    TROUBLESHOOTING = 'Entstörung',
    REPAIR = 'Reparatur',
}

export enum TroubleshootingPhase {
    RequestedfromSP = 'Störungsbeseitigung beim Dienstleister angefragt',
    TechnicianOnSite = 'Techniker vor Ort',
    TroubleshootingSuccessful = 'Störungsbeseitigung erfolgreich',
    TroubleshootingUnsuccessful = 'Störungsbeseitigung nicht erfolgreich',
}

export enum MarkIncidentCompleteOptions {
    ErfolgreicheEntstörung = 'Troubleshooting Successful',
    ErfolgreicheReparatur = 'Repair Successful',
}

export enum RepairPhase {
    TechnicianOnSite = 'Techniker vor Ort',
    TroubleshootingUnsuccessful = 'Störungsbeseitigung nicht erfolgreich',
    QuotationRequested = 'Angebot angefragt',
    QuotationReceived = 'Angebot erhalten',
    RepairRequested = 'Reparatur beauftragt',
    QuotationRejected = 'Angebot abgeleht',
}
export enum Severity {
    Entrapment = 'Entrapment',
    Breakdown = 'Breakdown',
    Issues = 'Issues',
}

export interface Cases {
    dateTimeOpened: string;
    status: IncidentStatus;
    severity: Severity;
}

export interface Incident {
    id: string;
    elevatorId?: string;
    factoryNumber: string;
    propertyUnit: Elevator['propertyUnit'];
    case: Case;
    serviceProvider?: ServiceProvider;
    customerSLAs?: CustomerSLA[];
}

export interface Picklist {
    categoryPicklist?: string[];
    statusPicklist?: string[];
    substatusPicklist?: string[];
    componentDependentPicklist?: { [key: string]: string[] };
}

interface ServiceProvider {
    accountName: string;
    phone: string;
    email: string;
}

export interface Case {
    subStatus: string;
    status: string;
    severity: string;
    elevatorStates?: Array<ElevatorState>;
    origin: string;
    serviceProviderInfo: ServiceProviderInfo;
    serviceLevelAgreement?: ServiceLevelAgreement;
    dateTimeOpened: string;
    presumablyOutOfOperation?: string;
    validationStatus?: 'Open' | 'Approved' | 'Rejected';
    caseNumber: string;
    caseAge: number;
    closedDate?: string;
    resolvedOn?: string;
    component?: string;
    componentGroup?: string;
    currentResolutionTimeInMinutes: number;
    currentResolutionTimeInDays: number;
}

export interface ServiceLevelAgreement {
    reactionTime?: number;
    type?: string;
    penalty?: number;
    calculationType?: string;
}

export interface ServiceProviderInfo {
    serviceProviderName?: string;
    malfunctionHotline?: string;
    malfunctionEmail?: string;
}

export interface ElevatorState {
    timeStamp: string;
    description?: string;
    origin?: string;
    state: boolean;
    status?: string;
    resolvedOn?: string;
}

interface CustomerSLA {
    type: string;
    reactionTime: number;
    penalty: number;
    calculationType: string;
}

export enum IncidentSearchFields {
    CASE_STATUS = 'case.status',
    CASE_SUBSTATUS = 'case.subStatus',
    FACTORY_NUMBER = 'factoryNumber',
    PROPERTY_UNIT_STREET = 'propertyUnit.street',
    PROPERTY_UNIT_POSTAL_CODE = 'propertyUnit.postalCode',
    PROPERTY_UNIT_CITY = 'propertyUnit.city',
    PROPERTY_UNIT_BUSINESS_UNIT = 'propertyUnit.businessUnit',
    NAME = 'name',
    CASE_SEVERITY = 'case.severity',
    CASE_PHASE = 'case.phase',
    CASE_ORIGIN = 'case.origin',
}
