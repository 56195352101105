import React from 'react';
import type { ReactNode } from 'react';

import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import * as felaRules from './UploadInfo.rules';

export interface UploadInfoProps {
    title: ReactNode;
    description: ReactNode;
    subTitle: ReactNode;
    listItems: ReactNode[];
}

export const UploadInfo = ({ title, description, subTitle, listItems }: UploadInfoProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <Typography.Title level={1} className={styles.title}>
                {title}
            </Typography.Title>
            <Typography.Paragraph className={styles.description}>{description}</Typography.Paragraph>
            <Typography.Title level={2} className={styles.subTitle}>
                {subTitle}
            </Typography.Title>
            <ul className={styles.list}>
                {listItems.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};
