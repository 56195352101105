import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from '../config';

declare global {
    interface Window {
        Cypress: any;
        store: any;
    }
}

const Redux: FC = ({ children }) => {
    const { store, persistor } = configureStore();

    if (window.Cypress) {
        window.store = store;
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};

export default Redux;
