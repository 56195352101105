export enum HTTPStatusCode {
    BadRequest = 400,
    Unauthorized = 401,
    NotFound = 404,
    InternalServerError = 500,
    ServiceUnavailable = 503,
}

export type UIMessageID = string;

export interface UIErrorMessages {
    code?: Record<string | number, UIMessageID>;
    status?: Partial<Record<HTTPStatusCode, UIMessageID>>;
    fallbackId: UIMessageID;
}

export const errorMessages: UIErrorMessages = {
    code: {
        // AWS Cognito errors - https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html
        NotAuthorizedException: 'error.api.invalidCredentials',
        LimitExceededException: 'error.api.attemptLimitExceeded',
        NotAuthorizedExceptionCreatePassword: 'error.api.invalidPassword',
        CodeMismatchException: 'error.api.codeMismatch',
        ExpiredCodeException: 'error.api.codeMismatch',
        UNAUTHORIZED_ACCESS: 'error.api.401',
        IOT_DEVICE_NOT_FOUND: 'elevator.iot.notInstalled',
        INVALID_PAGE_TOKEN: 'error.api.invalidPageToken',
    },
    status: {
        [HTTPStatusCode.BadRequest]: 'error.api.400',
        [HTTPStatusCode.Unauthorized]: 'error.api.401',
        [HTTPStatusCode.NotFound]: 'error.api.404',
        [HTTPStatusCode.InternalServerError]: 'error.api.500',
        [HTTPStatusCode.ServiceUnavailable]: 'error.api.503',
    },
    fallbackId: 'error.api.general',
};
