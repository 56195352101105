import { TRuleWithTheme } from 'styles/theme';

export const table: TRuleWithTheme = ({ theme: { colors } }) => ({
    '& .ant-table-row.ant-table-row-level-0': {
        border: 'none',
        position: 'relative',

        '&::after': {
            height: '1px',
            content: "''",
            backgroundColor: colors.dividerColor,
            position: 'absolute',
            bottom: 0,
            left: '1.5em',
            right: '1.5em',
        },
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:nth-of-type(2)': {
        verticalAlign: 'top',
        '@media(min-width: 1250px)': {
            width: '35%',
        },
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(:nth-of-type(2))': {
        paddingLeft: '1.5rem',
        verticalAlign: 'top',
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-of-type': {
        paddingRight: '1rem',
        width: 24,
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:last-of-type': {
        paddingRight: '1.5rem',
    },
});

export const tableElevatorDetail: TRuleWithTheme = ({ theme: { colors } }) => ({
    '& .ant-table-row.ant-table-row-level-0': {
        border: 'none',
        position: 'relative',

        '&::after': {
            height: '1px',
            content: "''",
            backgroundColor: colors.dividerColor,
            position: 'absolute',
            bottom: 0,
            left: '1.5em',
            right: '1.5em',
        },
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:nth-of-type(2)': {
        verticalAlign: 'top',
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(:nth-of-type(2))': {
        paddingLeft: '1.5rem',
        verticalAlign: 'top',
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-of-type': {
        paddingRight: '1rem',
        width: 24,
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:last-of-type': {
        paddingRight: '1.5rem',
    },
});
export const toolbar: TRuleWithTheme = ({ theme }) => ({});
