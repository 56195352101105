import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/actions';
import { selectInviteUsersRequestApi } from '../services/selector';
import { useEffect } from 'react';

export const useInviteUser = () => {
    const dispatch = useDispatch();
    const api = useSelector(state => selectInviteUsersRequestApi(state));

    const handleSubmit = ({ users, email }) => {
        dispatch(
            actions.inviteUsersRequest({
                users,
                email,
            }),
        );
    };

    const resetAction = () => {
        dispatch(
            actions.inviteUsersRequestReset(),
        );
    };


    useEffect(() => {
        if (api.success) {
            dispatch(actions.fetchUsers.fetchUsersRequest())
            dispatch(actions.inviteUsersRequestReset())
        }
    }, [api, dispatch])

    return {
        handleSubmit,
        api,
        resetAction
    };
};
