import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';

import config from 'config/index';

import { useFelaEnhanced } from 'hooks';
import { useResetSelectedDocuments } from 'modules/entities/modules/documents';

import { IncidentsTabs } from './types';

import * as felaRules from './IncidentsTab.styles';
import { IncidentsTable } from '../IncidentsTable';
import FilterPanel from '../FilterPanel';
import { SearchValue } from 'constants/index';
import { Incident } from 'modules/entities/modules/incidents';

const { TabPane } = Tabs;

const tabPanes = {
    [IncidentsTabs.OPEN]: {
        name: 'page.incidents.tab.active',
    },
    [IncidentsTabs.CLOSED]: {
        name: 'page.incidents.tab.resolved',
    },
};

const pathnameToTab = {
    [config.routes.incidentsActive]: IncidentsTabs.OPEN,
    [config.routes.incidentsResolved]: IncidentsTabs.CLOSED,
};

const tabToPathname = {
    [IncidentsTabs.OPEN]: config.routes.incidentsActive,
    [IncidentsTabs.CLOSED]: config.routes.incidentsResolved,
};

export interface IncidentsTabProps {
    defaultSearch: SearchValue;
    activeIncidents: Incident[];
    closedIncidents: Incident[];
    unvalidatedIncidents: Incident[];
    loading: boolean;
}

const IncidentsTab: FC<IncidentsTabProps> = ({
    defaultSearch,
    activeIncidents,
    closedIncidents,
    loading,
    unvalidatedIncidents,
}) => {
    const { pathname } = useLocation();
    const [internalRouting] = useState('');
    const history = useHistory();
    const { search } = useLocation();
    // what is this
    const resetDownloadSelect = useResetSelectedDocuments();

    const { styles } = useFelaEnhanced(felaRules);

    const onTabChange = (tab: string) => {
        resetDownloadSelect();
        const searchParams = new URLSearchParams(search);
        const searchs = searchParams.get('search');
        let route = `${tabToPathname[tab]}`;
        if (searchs) {
            route = `${tabToPathname[tab]}?search=${encodeURIComponent(searchs)}`;
        }

        history.push(route);
    };

    let activeKey = pathnameToTab[pathname] ?? IncidentsTabs.OPEN;
    if (internalRouting) {
        activeKey = pathnameToTab[internalRouting] ?? IncidentsTabs.OPEN;
    }

    return (
        <>
            <Tabs activeKey={activeKey} className={styles.tabs} onChange={onTabChange} destroyInactiveTabPane>
                {Object.keys(tabPanes).map(pane => (
                    <TabPane tab={<FormattedMessage id={tabPanes[pane].name} />} key={pane} />
                ))}
            </Tabs>
            <FilterPanel
                defaultSearch={defaultSearch}
                loading={loading}
                isClosed={activeKey === IncidentsTabs.CLOSED}
            />
            <IncidentsTable
                items={activeKey === IncidentsTabs.OPEN ? activeIncidents : closedIncidents}
                unvalidatedIncidents={unvalidatedIncidents}
                loading={loading}
                isClosed={activeKey === IncidentsTabs.CLOSED}
            />
        </>
    );
};

export default IncidentsTab;
