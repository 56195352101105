import React, { FC } from 'react';
import { Avatar, Dropdown, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useFelaEnhanced } from 'hooks';
import { useUser } from 'modules/auth';

import * as rules from './UserAccount.styles';
import UserMenu from '../UserMenu';

const UserAccount: FC = () => {
    const { styles } = useFelaEnhanced(rules);
    const { avatar, email } = useUser();

    return (
        <Dropdown overlay={<UserMenu />} trigger={['click']}>
            <Button type="text" className={styles.button} data-testid="user">
                <Avatar src={avatar} className={styles.avatar} icon={<UserOutlined />} size={32} />
                <span>{email}</span>
            </Button>
        </Dropdown>
    );
};

export default UserAccount;
