import { useSelector } from 'react-redux';
import { authUserSelector } from '../services/selectors';

const useUserPermissions = () => {
  const user = useSelector(authUserSelector);
  const permissions = user?.permissions;

  return {
    hasEditElevatorsPermission: permissions?.Edit_Elevators__c === 'true',
    hasViewElevatorsPermission: permissions?.View_Elevators__c === 'true',
    hasUploadDocumentsPermission: permissions?.Upload_Documents__c === 'true',
    hasViewDocumentsPermission: permissions?.View_Documents__c === 'true',
    shouldUploadPriceCheckDocumentsPermissions: permissions?.Upload_Price_Check_Documents__c === 'true',
    shouldUploadDeficiencyCheckPermissions: permissions?.Upload_Deficiency_Check__c === 'true',
    shouldUploadQuotationCheckPermissions: permissions?.Upload_Quotation_Check__c === 'true',
    shouldUploadContractCheckPermissions: permissions?.Upload_Contract_Check__c === 'true',
    shouldViewMaintenanceOptimizationPermissions: permissions?.View_Maintenance_Optimization__c === 'true',
    shouldViewOperatorObligationsPermissions: permissions?.View_Operator_Obligations__c === 'true',
    shouldViewSupportRequestPermissions: permissions?.View_Support_Request__c === 'true',
    shouldViewTeamsPermissions: permissions?.View_Teams__c === 'true',
    shouldViewWorkOrders: permissions?.View_Work_Orders__c === 'true',
    shouldViewInvoices: permissions?.View_Invoices__c === 'true',
    shouldViewContracts: permissions?.View_Contracts__c === 'true',
    shouldViewEmergencyRescuePlan: permissions?.View_Emergency_Rescue_Plans__c === 'true',
    shouldViewRiskAssessmentReports: permissions?.View_Risk_Assessment_Reports__c === 'true',
    shouldViewConditionReports: permissions?.View_Condition_Reports__c === 'true',
    shouldViewOtherDocuments: permissions?.View_Other_Documents__c === 'true',
    shouldViewQuotations: permissions?.View_Quotations__c === 'true',
    shouldViewAssessmentReports: permissions?.View_Assessment_Reports__c === 'true',
    shouldViewElevatorDocuments: permissions?.View_Elevator_Documents__c === 'true',
    shouldViewElevatorComponents: permissions?.View_Elevator_Components__c === 'true',
    shouldViewTechnicalData: permissions?.View_Technical_Data__c === 'true',
    shouldViewLiveData: permissions?.View_Live_Data__c === 'true',
  };
};

export default useUserPermissions;
