import { batch, useDispatch, useSelector } from 'react-redux';
import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useEffect, useMemo } from 'react';

import { fetchOtherDocuments as actions } from '../services/actions';
import { selectOtherDocumentsApi } from '../services/selectors';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

const useFetchOtherDocuments = () => {
    const api: ApiReducerState = useSelector(selectOtherDocumentsApi);
    const dispatch = useDispatch();

    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        dispatch(actions.fetchOtherDocumentsRequest({ elevatorId: isElevatorDetail ? id : null }));

        return () => {
            batch(() => {
                dispatch(actions.fetchOtherDocumentsCancel());
                dispatch(actions.fetchOtherDocumentsReset());
            });
        };
    }, [dispatch, id, isElevatorDetail]);

    return api;
};

export default useFetchOtherDocuments;
