import { Auth } from 'aws-amplify';
import { authApi } from 'config/antonio';
import mapUserData from './utils/mapUserData';
import config from 'config';

export default function* getAuthUser() {
    const user = yield Auth.currentAuthenticatedUser();
    const userData = yield mapUserData(user);

    const { data } = yield* authApi.get<Array<{
          id: string;
          photo: string;
          name: string;
          email: string;
          role: string;
          elevators: number;
      }>
      >(`${config.api.users}/${userData.email}`);

    return { ...userData, ...data };
}
