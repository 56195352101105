import felaMonolithic from 'fela-monolithic';
import enforceLonghands from 'fela-enforce-longhands';

const enhancers = [
    enforceLonghands(),
    process.env.NODE_ENV === 'development' &&
        felaMonolithic({
            prettySelectors: true,
        }),
].filter(Boolean);

export default enhancers;
