import type { TRuleWithTheme } from 'styles/theme';

export const headerSkeleton: TRuleWithTheme = ({ theme }) => ({
    '& .ant-skeleton-title': {
        height: 32,
    },

    '& .ant-skeleton-paragraph': {
        display: 'none',
    },
});
export const bodySkeleton: TRuleWithTheme = ({ theme }) => ({
    '& .ant-skeleton-title': {
        display: 'none',
    },
});
