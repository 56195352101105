import React from 'react';
import { Switch, Route, RouteProps } from 'react-router-dom';
import config from 'config/index';

import { BrowserIncompatiblePage } from 'modules/browser-incompability';

const specialRoutes: Array<RouteProps> = [
    {
        path: config.routes.browserIssues,
        exact: true,
        render: () => <BrowserIncompatiblePage />,
    },
];
// These routes are not part of the Ant Design Layout
const SpecialRoutes = () => (
    <Switch>
        {specialRoutes.map((route, index) => (
            <Route key={String(route.path ?? index)} {...route} />
        ))}
    </Switch>
);

export default SpecialRoutes;
