import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';

import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.TRIPS,
});

export const fetchAverageTripsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_AVERAGE_TRIPS_' }),
});

export const fetchTotalTripsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_TOTAL_TRIPS_' }),
});
