import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUsersRolesPermissions as actions } from '../services/actions';
import { selectFetchUsersRolesApi, selectFetchUsersRolesUsers } from '../services/selector';

export const useFetchUsersRolesPermissions = () => {
  const api = useSelector(selectFetchUsersRolesApi);
  const roles = useSelector(selectFetchUsersRolesUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (roles.length === 0) dispatch(actions.fetchUsersRolesPermissionsRequest());
  }, [dispatch, roles.length]);

  return {
    roles: roles.filter(role => role.value.toLowerCase() !== 'primary').map(role => ({
      value: role.value,
      label: role.name,
    })),
    loading: api.inProgress,
  };
};
