import type { ApiAction } from '../../../../../types';
import type { HealthState } from '../../../types';

import { fetchElevatorStateTypes } from '../../actions';

const initialState: HealthState = null;

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_SUCCESS:
            const elevatorState: HealthState = action.payload;
            return elevatorState;
        case fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_RESET:
            return initialState;
        default:
            return state;
    }
};
