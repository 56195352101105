import { createAction } from '@reduxjs/toolkit';
import { EDIT_NOTIFICATION_STATE } from '../../types';

export const editNotificationRequest = createAction(
    `${EDIT_NOTIFICATION_STATE}/SEND`,
    (payload) => ({
        payload: {
            notifications: payload.notifications,
            userId: payload.userId
        },
        meta: {
            email: payload.email,
        },
    }),
);

export const editNotificationRequestSuccess = createAction(
    `${EDIT_NOTIFICATION_STATE}/SEND_SUCCESS`,
    (lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            lastSuccessAt,
        },
    }),
);

export const editNotificationRequestFailure = createAction(
    `${EDIT_NOTIFICATION_STATE}/SEND_FAILURE`,
    (error: any) => ({
        payload: null,
        error,
        meta: {},
    }),
);

export const editNotificationRequestReset = createAction(`${EDIT_NOTIFICATION_STATE}/SEND_RESET`, () => ({
    payload: null,
    meta: {},
}));
