import { takeLeading, put } from 'redux-saga/effects';
import { AUTH_SESSION_END } from '@ackee/petrus';
import { logoutFailure } from '@ackee/petrus/es/modules/auth-session/actions/logout';
import { Auth } from 'aws-amplify';

import * as Log from 'config/loglevel';

function* handleLogout() {
    try {
        yield Auth.signOut();
    } catch (error) {
        yield put(logoutFailure(error));
        Log.error(error);
    }
}

export default function* logout() {
    yield takeLeading(AUTH_SESSION_END, handleLogout);
}
