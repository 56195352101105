import { basicApiReducer } from '@ackee/redux-utils';
import { combineReducers } from '@reduxjs/toolkit';

import { ApiReducerKey } from 'constants/index';

import { fetchQuotationsFailure, fetchQuotationsRequest, fetchQuotationsSuccess, resetQuotations } from '../actions';

export const listApiReducer = basicApiReducer({
    actionTypes: {
        REQUEST: fetchQuotationsRequest.toString(),
        SUCCESS: fetchQuotationsSuccess.toString(),
        FAILURE: fetchQuotationsFailure.toString(),
        RESET: resetQuotations.toString(),
    },
});

export const apiReducer = combineReducers({
    [ApiReducerKey.LIST]: listApiReducer,
});
