export const initialState = {
    pageSize: 10,
    pageTokens: {
        prev: null,
        share: null,
        next: null,
    },
};

export const selectors = {
    pageSize: action => action.meta.pageSize,
    pageTokens: action => action.meta.pageTokens,
};
