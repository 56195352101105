import React from 'react';

import {
    AssessmentReportStatus,
    useAssessmentReportsCountByStatus,
} from 'modules/entities/modules/documents-assessment-reports';

import { DocumentsStatusFilter } from '../../DocumentsStatusFilter';

import { AssessmentReportStatusIcon } from '../AssessmentReportStatusIcon';

export const AssessmentReportsStatusFilter = () => {
    const statusCount = useAssessmentReportsCountByStatus();

    const statuses = React.useMemo(() => {
        return Object.values(AssessmentReportStatus).map(status => ({
            icon: <AssessmentReportStatusIcon status={status} small />,
            value: status,
            count: statusCount[status],
        }));
    }, [statusCount]);

    return <DocumentsStatusFilter<AssessmentReportStatus> statuses={statuses} />;
};
