import React from 'react';

export const ContractCheck = () => (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m6 20h12v-16h-12v16zm13-18h-14c-0.553 0-1 0.447-1 1v18c0 0.553 0.447 1 1 1h14c0.553 0 1-0.447 1-1v-18c0-0.553-0.447-1-1-1z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="m10.954 18.651c0.017 0.02 0.041 0.026 0.06 0.045s0.028 0.045 0.049 0.063c0.049 0.042 0.107 0.064 0.162 0.095 0.038 0.022 0.072 0.049 0.113 0.066 0.121 0.049 0.248 0.081 0.376 0.081 0.129 0 0.256-0.032 0.377-0.081 0.039-0.016 0.071-0.042 0.108-0.063 0.056-0.031 0.114-0.054 0.165-0.097 0.023-0.019 0.032-0.047 0.053-0.067 0.016-0.018 0.04-0.023 0.056-0.042l4.286-5c0.36-0.419 0.311-1.05-0.109-1.409-0.417-0.36-1.05-0.313-1.409 0.108l-3.526 4.114-0.955-1.114c-0.361-0.421-0.99204-0.467-1.41-0.109-0.42 0.359-0.469 0.991-0.11 1.41l1.714 2z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
    </svg>
);
