import { createInfoWindow } from './infoWindow';

export const createMarker = (
    map: google.maps.Map,
    markerOptions: google.maps.MarkerOptions,
    infoWindowOptions: google.maps.InfoWindowOptions,
) => {
    const marker = new google.maps.Marker(markerOptions);

    const { onMarkerClick } = createInfoWindow(map, marker, { marker: infoWindowOptions });

    marker.addListener('click', onMarkerClick);

    return marker;
};
