import React, { FC } from 'react';

import LoginForm from '../LoginForm';
import AuthPage from '../AuthPage';
import CookiebotWidget from '../CookiebotWidget';

const LoginPage: FC = () => {
    return (
        <AuthPage>
            <CookiebotWidget />
            <LoginForm />
        </AuthPage>
    );
};

export default LoginPage;
