import { apiRequestActions } from '@ackee/redux-utils';

import { subscribeElevatorStateTypes, UPDATE_ELEVATOR_STATE } from './types';
import { ElevatorState } from '../../types';

export default apiRequestActions(subscribeElevatorStateTypes, { isDetailRequest: true });

export interface UpdateElevatorStateAction {
    type: typeof UPDATE_ELEVATOR_STATE;
    payload: {
        state: ElevatorState;
    };
}

export const updateElevatorState = (state: ElevatorState): UpdateElevatorStateAction => ({
    type: UPDATE_ELEVATOR_STATE,
    payload: {
        state,
    },
});
