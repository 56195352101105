import { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ApiReducerState } from '@ackee/redux-utils/es/config';

import { selectContractsApi } from '../services/selectors';
import { fetchContracts as actions } from '../services/actions';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

const useFetchContracts = () => {
    const api: ApiReducerState = useSelector(selectContractsApi);
    const dispatch = useDispatch();
    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        if (isElevatorDetail) {
            dispatch(actions.fetchContractsRequest({ elevatorId: id }));
        }else{
            dispatch(actions.fetchContractsRequest());
        }

        return () => {
            batch(() => {
                dispatch(actions.fetchContractsCancel());
                dispatch(actions.fetchContractsReset());
            });
        };
    }, [dispatch, isElevatorDetail, id]);

    return api;
};

export default useFetchContracts;
