import React, { FC } from 'react';
import { InputProps as AntInputProps, Input as AntInput } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './Input.styles';

interface InputProps extends AntInputProps {
    extend?: RulesExtend<typeof felaRules>;
}

const Input: FC<InputProps> = ({ extend, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <div className={styles.container}>
            <AntInput className={styles.input} {...rest} />
        </div>
    );
};

export default Input;
