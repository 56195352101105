import type { TRuleWithTheme } from 'styles/theme';

export const table: TRuleWithTheme = () => ({
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td': {
        verticalAlign: 'top',
    },
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(:nth-of-type(2))': {
        paddingLeft: '1.5rem',
    },
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:nth-of-type(2)': {
        '@media(min-width: 1250px)': {
            width: '45%',
        },
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-of-type': {
        paddingRight: '1rem',
        width: 24,
    },
});

export const tableElevatorDetailOtherDocuments: TRuleWithTheme = () => ({
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td': {
        verticalAlign: 'top',
    },
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(:nth-of-type(2))': {
        paddingLeft: '1.5rem',
    },

    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-of-type': {
        paddingRight: '1rem',
        width: 24,
    },
});
