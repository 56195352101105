import React from 'react';
import { useIntl } from 'react-intl';
import { useFelaEnhanced } from 'hooks';
import TextArea from 'antd/lib/input/TextArea';
import { FloatingLabel } from 'modules/form';
import { SupportDialogType } from 'constants/common';

import * as felaRules from './DescriptionInput.rules';

const labelMessages = {
    [SupportDialogType.SUPPORT]: 'support.description.type.placeholder',
    [SupportDialogType.FEEDBACK]: 'support.feedback.empty',
};

const placeholderMessages = {
    [SupportDialogType.SUPPORT]: 'support.description.type.placeholder',
    [SupportDialogType.FEEDBACK]: 'support.feedback.empty',
};

export const DescriptionInput = ({ value = null, onChange, disabled, type = SupportDialogType.SUPPORT }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();

    const handleChange = event => {
        onChange(event.target.value);
    };

    const labelId = labelMessages[type] || '';
    const placeholderId = placeholderMessages[type] || '';

    const label = labelId ? intl.formatMessage({ id: labelId }) : '';
    const placeholder = placeholderId ? intl.formatMessage({ id: placeholderId }) : '';
    if (type === SupportDialogType.FEEDBACK) {
        return (
            <div className={styles.container}>
                <div></div>
                <FloatingLabel htmlFor="type" floating={Boolean(value)} label={label}>
                    {({ onFocus, onBlur, ...rest }) => (
                        <TextArea
                            showCount
                            value={value}
                            maxLength={1000}
                            className={Boolean(value) ? styles.inputWithValue : styles.input}
                            disabled={disabled}
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            placeholder={placeholder}
                            onChange={handleChange}
                            rows={3}
                        />
                    )}
                </FloatingLabel>
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <FloatingLabel htmlFor="type" floating={Boolean(value)} label={label}>
                {({ onFocus, onBlur, ...rest }) => (
                    <TextArea
                        showCount
                        value={value}
                        maxLength={1000}
                        className={Boolean(value) ? styles.inputWithValue : styles.input}
                        disabled={disabled}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        placeholder={placeholder}
                        onChange={handleChange}
                        rows={3}
                    />
                )}
            </FloatingLabel>
        </div>
    );
};
