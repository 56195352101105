import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useSearchParams } from 'services/hooks';
import { UrlParam } from 'constants/index';
import config from 'config';
import { useFelaEnhanced } from 'hooks';

import { NotFound, errorMessages, HTTPStatusCode } from 'modules/errors';
import { DataCard } from 'modules/ui';
import { useElevatorStates } from 'modules/entities/modules/elevator-states';

import ElevatorHistoryList from '../ElevatorHistoryList';
import ElevatorHistoryPaging from '../ElevatorHistoryPaging';

import { HISTORY_LIST_PAGE_SIZE } from '../../config';

import * as felaRules from './ElevatorHistory.styles';
import { Select } from '../../../form';
import { PAGE_SIZE_STORAGE_KEY, pageSizeOptions as defaultPageSizeOptions } from '../../../table';

export interface HistoryListProps {}

const ElevatorHistory: FC<HistoryListProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const pageSize = (useSearchParams(UrlParam.PAGE_SIZE) as string) ?? sessionStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    const { id } = useParams<{ id: string }>();

    const history = useHistory();

    const {
        api: { inProgress, error },
        data,
    } = useElevatorStates(id, {
        pageSize: pageSize ? parseInt(pageSize) : HISTORY_LIST_PAGE_SIZE,
        enableShareToken: true,
    });

    const pageSizeOptions = React.useMemo(
        () =>
            defaultPageSizeOptions.map(value => ({
                value: value,
                label: <FormattedMessage id="table.paginationSizeTitle" values={{ number: value }} />,
            })),
        [],
    );

    const handlePageSizeSelectChange = value => {
        sessionStorage.setItem(PAGE_SIZE_STORAGE_KEY, value);
        history.push({ search: `${UrlParam.PAGE_SIZE}:${value}` });
    };

    if (error === errorMessages.status[HTTPStatusCode.NotFound]) {
        return <NotFound />;
    }

    return (
        <section className={styles.container}>
            <DataCard
                title={
                    <Typography.Title level={1} className={styles.title}>
                        <FormattedMessage id="elevator.history.title" />
                    </Typography.Title>
                }
                backLink={generatePath(config.routes.elevatorDetail, { id })}
                loading={inProgress}
                error={error}
                empty={data?.length === 0}
            >
                <ElevatorHistoryList layout="horizontal" elevatorStates={data} />
            </DataCard>
            <div className={styles.pagination}>
                <Select
                    options={pageSizeOptions}
                    defaultValue={pageSize ?? pageSizeOptions[1].value}
                    onChange={handlePageSizeSelectChange}
                    extend={{ selectContainer: rules.selectContainer }}
                />
                <ElevatorHistoryPaging />
            </div>
        </section>
    );
};

export default ElevatorHistory;
