const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const daysDiff = (a: Date, b: Date) => {
    const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utcA - utcB) / MS_PER_DAY);
};

export const generateUTCDate = (date: Date): Date => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0));
};

export const generateUTCDateMoment = (date: moment.Moment): Date => {
    return new Date(Date.UTC(date.year(), date.month(), date.date(), 0));
};
