import { put, cancelled } from 'redux-saga/effects';

import { noop } from 'lodash';

import config from 'config';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import { Support } from '../../types';
import { fetchSupportsTypes, fetchSupports as actions } from '../actions';

function* fetchSupports() {
    const controller = new AbortController();

    try {
        const { data } = yield* authApi.get<Support[]>(config.api.support, {
            signal: controller.signal,
        });
        yield put(actions.fetchSupportsSuccess(data));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchSupportsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchSupportsRequest',
            leading: true,
        },
        {
            pattern: fetchSupportsTypes.FETCH_SUPPORTS_REQUEST,
            handler: fetchSupports,
        },
        {
            pattern: fetchSupportsTypes.FETCH_SUPPORTS_CANCEL,
            handler: noop,
        },
    );
}
