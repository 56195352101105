import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const MaintenanceAnalysisManagement = () => (
    <svg width="35" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.674 11.171V4.224L23.587.75 17.5 4.224v6.947l6.087 3.474 6.087-3.474ZM34.24 27.248v-5.399l-10.653-6.06-10.652 6.06v5.399M12.935 12.046V6.828L8.37 4.227 3.804 6.828v5.218l4.566 2.602 4.565-2.602Z"
            stroke="#fff"
        />
        <path d="m15.476 20.404-7.106-4.15L.76 20.69v6.556" stroke="#fff" />
    </svg>
);
