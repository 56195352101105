import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './TableFilterPanel.styles';

type TableFilterPanelProps = {
    extend?: RulesExtend<typeof felaRules>;
};

const TableFilterPanel: FC<TableFilterPanelProps> = ({ extend, children }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <div className={styles.filterPanel} data-testid="filter-panel">
            {children}
        </div>
    );
};

export default TableFilterPanel;
