import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { createSelector } from 'reselect';
import { apiSelector } from '@ackee/redux-utils/es';
import { ApiReducerKey, EntityKey, Sorting } from 'constants/index';
import {
    CHANGE_ROLE_SELECTOR_ID,
    DEACTIVATE_USER_SELECTOR_ID,
    EDIT_NOTIFICATION_SELECTOR_ID,
    INVITE_USERS_SELECTOR_ID,
} from '../types';
import { TeamsSortFields } from '../constants';
import { stringComparatorFactory, defaultCompare } from 'modules/table';
import { compareValueByType } from 'services/utils';
import { get } from 'lodash';

export const selectInviteUsersRequestApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TEAMS, ApiReducerKey.CREATE_USERS, INVITE_USERS_SELECTOR_ID);

export const selectdeactivateActivateUserRequestApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TEAMS, ApiReducerKey.DEACTIVATE_USER, DEACTIVATE_USER_SELECTOR_ID);

export const selectChangeRoleRequestApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TEAMS, ApiReducerKey.CHANGE_ROLE, CHANGE_ROLE_SELECTOR_ID);

export const selectEditNotificationRequestApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TEAMS, ApiReducerKey.EDIT_NOTIFICATION, EDIT_NOTIFICATION_SELECTOR_ID);

export const selectFetchUsersApi = (state): ApiReducerState => apiSelector(state, EntityKey.TEAMS, ApiReducerKey.USERS);

export const selectFetchUsersRolesApi = (state): ApiReducerState => apiSelector(state, EntityKey.TEAMS, ApiReducerKey.ROLES);

export const selectFetchUsers = state => state.entities[EntityKey.TEAMS].fetchUsers[ApiReducerKey.USERS];

export const selectFetchUsersRolesUsers = state => state.entities[EntityKey.TEAMS].fetchUsersRolesPermissions[ApiReducerKey.ROLES];

export const selectUserNotificationsApi = (state): ApiReducerState => apiSelector(state, EntityKey.TEAMS, ApiReducerKey.NOTIFICATIONS);

export const selectUserNotifications = state => state.entities[EntityKey.TEAMS].fetchUserNotifications[ApiReducerKey.NOTIFICATIONS];

export const selectUser = state => state.entities[EntityKey.TEAMS].setUser.user

const sortUsersFactory = sorting => {
    switch (sorting?.field) {
        case TeamsSortFields.NAME:
        case TeamsSortFields.ROLE: {
            return stringComparatorFactory(sorting, '');
        }
        default: {
            return defaultCompare;
        }
    }
};

export const selectFilteredElevators = createSelector([selectFetchUsers, (_, { search }) => search], (users, search) =>
    users.filter(elevator =>
        search.every(({ field, query }) => {
            return compareValueByType({
                value: get(elevator, field),
                query,
            });
        }),
    ),
);

export const selectSortedFetchUsers = createSelector([selectFilteredElevators, (_, { sorting }: {
    sorting: Sorting
}) => sorting], (docs, sorting) =>
    [...docs].sort(sortUsersFactory(sorting)),
);
