import { noop, uniqueId } from 'lodash';
import { put } from 'redux-saga/effects';

import { takeRequest } from 'services/sagas/takeRequest';
import { createUIErrorMessage } from 'modules/errors';
import { authApi } from 'config/antonio';
import * as log from 'config/loglevel';
import config from 'config';

import { fetchInvoicesTypes, fetchInvoices as actions } from '../actions';
import type { Invoice } from '../../types';

type ResponseData = { [key: string]: any }[];

const transformData = (data: ResponseData) =>
    data.map(data => ({
        id: uniqueId('invoices'),
        ...data,
    })) as Invoice[];

function* fetchInvoices(action) {
    const { elevatorId } = action?.payload;
    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.documentInvoices);
        const t = transformData(data);
        if (elevatorId) {
            const filtedData = t.filter(invoice => {
                return invoice.items.some(item => {
                    return item.elevator.elevatorId === elevatorId;
                });
            });
            yield put(actions.fetchInvoicesSuccess(filtedData));
            return;
        }
        yield put(actions.fetchInvoicesSuccess(t));
    } catch (error) {
        log.error(error);
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchInvoicesFailure(uiError));
    }
}
export default function* () {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchInvoices',
        },
        {
            pattern: fetchInvoicesTypes.FETCH_INVOICES_REQUEST,
            handler: fetchInvoices,
        },
        { pattern: fetchInvoicesTypes.FETCH_INVOICES_CANCEL, handler: noop },
    );
}
