import { ApiReducerKey } from 'constants/index';
import { combineReducers } from 'redux';

import list from './list';
import detail from './detail';

export default combineReducers({
    [ApiReducerKey.LIST]: list,
    [ApiReducerKey.DETAIL]: detail,
});
