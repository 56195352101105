import type { TRuleWithTheme } from 'styles/theme';

export const table: TRuleWithTheme = () => ({
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:nth-of-type(2)': {
        paddingLeft: '1.125rem',
    },
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td:not(:nth-of-type(2))': {
        paddingLeft: '1.5rem',
    },
    '& .ant-table.ant-table-middle .ant-table-tbody > tr > td': {
        verticalAlign: 'top',
    },
});
