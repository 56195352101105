import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

import { ButtonCheckbox } from 'modules/ui';
import { StatusFilterContainer, stateComparatorFactory, StatusIcon, Tables } from 'modules/table';
import { OtherDocumentState } from 'modules/entities/modules/other-documents';
import { SortingType } from 'constants/index';

import { useOtherDocumentsStatusFilter } from '../../../../hooks/useOtherDocumentsStatusFilter';

type StatusFilterProps = {};

const statuses = Object.values(OtherDocumentState).sort(
    stateComparatorFactory({ type: SortingType.ASC }, Tables.OTHER_DOCUMENTS),
);

const StatusFilter: FC<StatusFilterProps> = () => {
    const { selectedStatuses, countsByStatus, handleStatusFilter } = useOtherDocumentsStatusFilter();

    return (
        <StatusFilterContainer title={<FormattedMessage id="documents.table.status" />}>
            <Checkbox.Group value={selectedStatuses} data-test-id="invoice-status-filter-checkboxgroup">
                {statuses.map((state: OtherDocumentState) => (
                    <ButtonCheckbox
                        value={state}
                        key={state}
                        count={countsByStatus[state]}
                        onChange={handleStatusFilter}
                    >
                        {state !== OtherDocumentState.SHOW_ALL ? (
                            <StatusIcon table={Tables.OTHER_DOCUMENTS} state={state} size={StatusIcon.Size.SMALL} />
                        ) : null}
                        <FormattedMessage id={`documents.table.status.${state}`} />{' '}
                    </ButtonCheckbox>
                ))}
            </Checkbox.Group>
        </StatusFilterContainer>
    );
};

export default StatusFilter;
