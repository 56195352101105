import { createAction } from '@reduxjs/toolkit';

import { DEACTIVATE_USER_STATE } from '../../types';

export const deactivateActivateUserRequest = createAction(`${DEACTIVATE_USER_STATE}/SEND`, (user) => ({
    payload: user,
    meta: {
        email: user.email,
    },
}));

export const deactivateActivateUserRequestSuccess = createAction(
    `${DEACTIVATE_USER_STATE}/SEND_SUCCESS`,
    (lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            lastSuccessAt,
        },
    }),
);

export const deactivateActivateUserRequestFailure = createAction(`${DEACTIVATE_USER_STATE}/SEND_FAILURE`, (error: any) => ({
    payload: null,
    error,
    meta: {},
}));

export const deactivateActivateUserRequestReset = createAction(`${DEACTIVATE_USER_STATE}/SEND_RESET`, () => ({
    payload: null,
    meta: {},
}));