import type { TRuleWithTheme } from 'styles/theme';

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '2rem',
    },
});

export const item: TRuleWithTheme = () => ({
    '&.ant-form-item': {
        marginBottom: '2.5rem',
    },

    '&.ant-form-item > .ant-form-item-label': {
        display: 'none',
    },
});

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        width: '100%',

        marginBottom: '1.5rem',
    },
});

export const terms: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: 0,
    },
});
