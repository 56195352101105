import React, { useRef } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { NewElevatorDialogContent } from '../NewElevatorDialogContent';
import { NewElevatorUpload } from '../NewElevatorUpload';
import { NewElevatorDialogUploadExcelTooltip } from '../NewElevatorDialogUploadExcelTooltip';

import * as felaRules from './NewElevatorDialogUploadExcel.rules';

const EXCEL_TEMPLATE_LINK =
    'https://simplifagmbhcokg.sharepoint.com/sites/ElevatorTwin/_layouts/15/download.aspx?e=z4JgHG&share=ETHucHIg0BxBrAS69KFRzV4BoDh7TlRMJfFjQkmue6KO-w';

const SUPPORTED_FILE_TYPES = [
    '.xls',
    'xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const NewElevatorDialogUploadExcel = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const ref = useRef();

    return (
        <NewElevatorDialogContent
            goBack
            title={<FormattedMessage id="elevator.new.dialog.upload.excel.title" />}
            data-testid="new-elevator-dialog-excel"
        >
            <div ref={ref}>
                <Typography.Paragraph type="secondary" className={styles.info}>
                    {
                        <FormattedMessage
                            id="elevator.new.dialog.upload.excel.desc"
                            values={{
                                ul: (chunks: ReactNode[] | ReactNode) => <ul>{chunks}</ul>,
                                li: (chunks: ReactNode[] | ReactNode) => (
                                    <li>
                                        <span className={styles.listItemInner}>{chunks}</span>
                                    </li>
                                ),
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                link: (chunks: ReactNode[] | ReactNode) => (
                                    <a href={EXCEL_TEMPLATE_LINK} target="_blank" rel="noopener noreferrer">
                                        {chunks}
                                    </a>
                                ),
                                br: <br />,
                                tooltipTitle: (chunks: ReactNode[] | ReactNode) => (
                                    <Typography.Text className={styles.tooltipTitle}>{chunks}</Typography.Text>
                                ),
                                tooltip: (chunks: ReactNode[] | ReactNode) => (
                                    <NewElevatorDialogUploadExcelTooltip>{chunks}</NewElevatorDialogUploadExcelTooltip>
                                ),
                            }}
                        />
                    }
                </Typography.Paragraph>
            </div>
            <NewElevatorUpload
                type={ChecktypeDocumentsUploadType.EXCEL}
                supportedFileTypes={SUPPORTED_FILE_TYPES}
                text={<FormattedMessage id="elevator.new.dialog.upload.excel.dragger" />}
            />
        </NewElevatorDialogContent>
    );
};
