import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

const controlButton: TRuleWithTheme = () => ({
    position: 'absolute',

    right: '1.5rem',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '2.5rem',
    height: '2.5rem',

    '&.ant-btn > span': {
        display: 'flex',

        color: 'inherit',
    },
});

export const zoomInButton: TRuleWithTheme = combineRules(controlButton, ({ theme }) => ({
    bottom: '4.25rem',

    color: theme.colors.primary,

    borderColor: 'transparent',
}));

export const zoomOutButton: TRuleWithTheme = combineRules(controlButton, ({ theme }) => ({
    bottom: '1.5rem',

    color: theme.colors.primary,

    borderColor: 'transparent',
}));

export const resetButton: TRuleWithTheme = combineRules(controlButton, () => ({
    bottom: '7.75rem',
}));
