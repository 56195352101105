import { basicApiReducer } from '@ackee/redux-utils';
import { combineReducers } from '@reduxjs/toolkit';

import { ApiReducerKey } from 'constants/index';

import {
    fetchWorkordersFailure,
    fetchWorkordersRequest,
    fetchWorkordersReset,
    fetchWorkordersSuccess,
} from '../actions';

export const listApiReducer = basicApiReducer({
    actionTypes: {
        REQUEST: fetchWorkordersRequest.toString(),
        SUCCESS: fetchWorkordersSuccess.toString(),
        FAILURE: fetchWorkordersFailure.toString(),
        RESET: fetchWorkordersReset.toString(),
    },
});

export const apiReducer = combineReducers({
    [ApiReducerKey.LIST]: listApiReducer,
});
