import React, { FC, FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, RowProps } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { DataSpace, Button } from 'modules/ui';

import * as felaRules from './TableSearch.styles';
import type { ColSizes, SearchField } from './types';

type TableSearchProps = {
    fields: SearchField[];
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    handleReset: () => void;
    rowGutter?: RowProps['gutter'];
    colSizes?: ColSizes;
    
};

const TableSearch: FC<TableSearchProps> = ({ fields, handleSubmit, handleReset, rowGutter, colSizes }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container} data-testid="table-search">
            <form onSubmit={handleSubmit}>
                <Row align="bottom" gutter={rowGutter} className={styles.row}>
                    {fields.map((field, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Col key={i} {...colSizes}>
                            <DataSpace
                                extend={{ data: rules.data }}
                                name={field.label}
                                labelFor={field.labelFor}
                                data={field.renderer}
                                isDataText={false}
                            />
                        </Col>
                    ))}
                    <Col className={styles.buttonCol} {...colSizes} flex="auto">
                        <div className={styles.buttonContainer}>
                            <Button
                                type="default"
                                onClick={handleReset}
                                size="large"
                                extend={{ button: rules.resetButton }}
                            >
                                <FormattedMessage id="table.search.reset" />
                            </Button>
                            <Button type="primary" size="large" htmlType="submit">
                                <FormattedMessage id="table.search.submit" />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default TableSearch;
