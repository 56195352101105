import { cancelled, put } from 'redux-saga/effects';
import { snakeCase } from 'lodash';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import type { AssessmentReport } from '../../types';

import {
    fetchAssessmentReports,
    fetchAssessmentReportsRequest,
    fetchAssessmentReportsSuccess,
    fetchAssessmentReportsFailure,
    clearAssessmentReports,
    resetAssessmentReports,
} from '../actions';

type ResponseData = { [key: string]: any }[];

const transformData = (data: ResponseData) =>
    data.map(doc => {
        doc.id = doc.assessmentId;
        doc.status = snakeCase(doc.status);

        delete doc.assessmentId;

        return doc;
    }) as AssessmentReport[];

function* fetchAssessmentReportsHandler(action) {
    const payload = action?.meta;
    const controller = new AbortController();

    yield put(fetchAssessmentReportsRequest());

    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.documentAssessmentReports, {
            signal: controller.signal,
        });

        const t = transformData(data);
        if (payload?.elevatorId) {
            const filtedData = t.filter(tag => {
                return tag.elevator.elevatorId === payload?.elevatorId;
            });
            yield put(fetchAssessmentReportsSuccess(filtedData));
            return;
        }

        yield put(fetchAssessmentReportsSuccess(t));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(fetchAssessmentReportsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

function* fetchAssessmentReportsClearHandler() {
    yield put(resetAssessmentReports());
}

export function* fetchAssessmentReportsSaga() {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchAssessmentReports',
        },
        {
            pattern: fetchAssessmentReports.toString(),
            handler: fetchAssessmentReportsHandler,
        },
        {
            pattern: clearAssessmentReports.toString(),
            handler: fetchAssessmentReportsClearHandler,
        },
    );
}
