import React from 'react';
import { TableActionPanel, TableSort } from 'modules/table';
import { NewElevatorButton } from 'modules/elevator-new';
import { ElevatorField, DEFAULT_SORT } from 'modules/entities/modules/elevators';
import useUserPermissions from 'modules/auth/hooks/useUserPermissions';

const SORT_FIELDS = [
    {
        field: ElevatorField.STATUS,
        labelId: 'page.elevators.table.status',
    },
    {
        field: ElevatorField.FACTORY_NUMBER,
        labelId: 'page.elevators.table.factoryNumber',
    },
    {
        field: ElevatorField.PROPERTY_UNIT_STREET,
        labelId: 'page.elevators.table.street',
    },
    {
        field: ElevatorField.PROPERTY_UNIT_POSTAL_CODE,
        labelId: 'page.elevators.table.postalCode',
    },
    {
        field: ElevatorField.PROPERTY_UNIT_CITY,
        labelId: 'page.elevators.table.city',
    },
    {
        field: ElevatorField.PROPERTY_UNIT_BUSINESS_UNIT,
        labelId: 'page.elevators.table.businessUnit',
    },
    {
        field: ElevatorField.OWNER,
        labelId: 'page.elevators.table.owner',
    },
];

export const ElevatorsActionPanel = () => {
    const { hasEditElevatorsPermission } = useUserPermissions()

    return (
        <TableActionPanel testId="elevators-actions">
            <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
           {hasEditElevatorsPermission && <NewElevatorButton />}
        </TableActionPanel>
    );
};
