import type { TRuleWithTheme } from 'styles/theme';

export const icon: TRuleWithTheme = ({ theme }) => ({
    '&.anticon': {
        padding: '0.125em',
        marginLeft: '0.125em',

        color: theme.colors.primary,

        borderRadius: '50%',
    },
    '&.anticon.ant-tooltip-open': {
        cursor: 'pointer',

        backgroundColor: theme.colors.selectedSecondary,
    },
    '&.anticon > svg': {
        width: '1em',
        height: '1em',
        verticalAlign: '-0.125em',
    },
});

export const tooltip: TRuleWithTheme = ({ theme }) => ({
    '&.ant-tooltip': {
        filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.138565))',
    },

    '&.ant-tooltip .ant-tooltip-inner': {
        padding: '1rem',

        fontSize: '0.75rem',
        lineHeight: 1.33,

        borderRadius: '0.25rem',

        color: theme.colors.textSecondary,
    },

    '&.ant-tooltip .ant-tooltip-arrow-content': {
        borderRadius: '0.125rem',
    },
});
