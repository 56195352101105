import type { TRule } from 'fela';

export const icon: TRule = () => ({
    fontSize: '1rem',
    marginRight: '.5rem',
    '& svg': {
        height: '.875rem',
        width: '.875rem',
    },
});

export const checkbox: TRule = () => ({});
