import React, { useRef, useState } from 'react';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';
import { Icon, IconType } from 'modules/ui';

import * as felaRules from './Select.styles';

export interface SelectProps extends AntSelectProps<string> {
    fullWidth?: boolean;
    floatingLabel?: boolean;
    'data-testid'?: string;
    extend?: RulesExtend<typeof felaRules>;
}

const DEFAULT_OPEN = false;

export const Select = ({
    extend,
    fullWidth = false,
    floatingLabel = false,
    'data-testid': testId,
    ...rest
}: SelectProps) => {
    const [isVisible, setIsVisible] = useState(DEFAULT_OPEN);

    const { styles, theme } = useFelaEnhanced(felaRules, { extend, isVisible, fullWidth });

    const ref = useRef(null);

    return (
        <div
            data-testid={testId}
            className={floatingLabel ? styles.floatingLabelselectContainer : styles.selectContainer}
            ref={ref}
        >
            <AntSelect
                suffixIcon={<Icon type={IconType.CHEVRON_DOWN} className={styles.icon} />}
                dropdownMatchSelectWidth={theme.width.dropdownMenu}
                dropdownClassName={styles.dropdown}
                defaultOpen={DEFAULT_OPEN}
                onDropdownVisibleChange={setIsVisible}
                getPopupContainer={() => ref.current}
                {...rest}
            />
        </div>
    );
};
