import { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = () => ({
    color: 'inherit',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});
