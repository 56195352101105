import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, Input, Form, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './NewIncidentDialogContent.rules';
import { FloatingLabel, Select, useFormSubmission } from 'modules/form';
import { useElevators } from 'modules/entities/modules/elevators';
import { NewIncidentSearchInput } from '../NewIncidentSearch';
import { NewElevatorDialogContext } from 'modules/elevator-new/contexts/NewElevatorDialogContext';
import { DialogState } from 'modules/elevator-new';
import { fields, forms } from '../../constants';
import { ErrorTypes, NewIncidentFormValues } from 'modules/incident-new/types';
import { useNotifications } from 'modules/ui';
import { isFormSubmissionError } from 'modules/auth/utils';
import { NewIncidentAlert } from '../NewIncidentAlert/NewIncidentAlert';
import { useHistory, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';
import config from 'config';

const { TextArea } = Input;

export const NewIncidentDialogForm = ({
    form,
    messageType,
    setMessageType,
    type,
    setType,
    description,
    setDescription,
    setElevator,
    elevator,
}: any) => {
    const { formatMessage } = useIntl();
    const {
        handleSubmit,
        submitting,
        error,
        succeeded,
        clearForm,
        meta,
        response,
    } = useFormSubmission<NewIncidentFormValues>(forms.newIncident);
    const { styles } = useFelaEnhanced(felaRules);
    const containerRef = useRef(null);
    const { navigate: elevatorDialogNavigate } = useContext(NewElevatorDialogContext);
    const { navigate, elevator: elevatorDetail } = useContext(GlobalDialogContext);
    const [contractLength, setContractLength] = useState<boolean>(false);

    const { elevators, loading } = useElevators({ disableSearch: true, disableSorting: true });
    const { pathname } = useLocation();

    const notifications = useNotifications();

    const [locationKeys, setLocationKeys] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (elevatorDetail) {
            form.setFieldsValue({ [fields.incident.elevator]: elevatorDetail.id });
            setElevator(elevatorDetail.id);
            setContractLength(elevatorDetail.maintenanceContractPresent);
        } else if (form) {
            form.resetFields();
        }
    }, [elevatorDetail, form, setElevator]);

    const handleOnCloseModal = useCallback(() => {
        containerRef.current!.focus({
            cursor: 'start',
        });
        clearForm();
        form.resetFields();
        setType(null);
        navigate(null);
        setMessageType(null);
        setDescription(null);
        if (elevatorDetail && pathname.includes('elevator')) {
            form.setFieldsValue({ [fields.incident.elevator]: elevatorDetail.id });
            setElevator(elevatorDetail.id);
            setContractLength(elevatorDetail.maintenanceContractPresent);
        } else {
            form.setFieldsValue({ [fields.incident.elevator]: null });
            setElevator('');
        }
    }, [navigate, setMessageType, clearForm, setType, form, elevatorDetail, pathname, setDescription, setElevator]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);

                    handleOnCloseModal();
                } else {
                    setLocationKeys(keys => [location.key, ...keys]);

                    handleOnCloseModal();
                }
            }
        });
        // eslint-disable-next-line
    }, [locationKeys, history]);

    useEffect(() => {
        if (succeeded && !meta?.caseNumber) {
            notifications.success({
                key: type,
                description:
                    response?.message !== 'REPORTED'
                        ? ''
                        : formatMessage({
                              id: 'incident.create.reported.description',
                          }),
                message:
                    response?.message !== 'REPORTED'
                        ? formatMessage({
                              id: 'incident.create.success',
                          })
                        : formatMessage({
                              id: 'incident.create.reported',
                          }),
            });
            handleOnCloseModal();
            if (pathname === config.routes.incidentsResolved) {
                history.push(config.routes.incidentsActive);
            }
        } else if (succeeded && response?.message !== 'REPORTED') {
            setMessageType(ErrorTypes.SEVERITY);
        }
    }, [
        notifications,
        succeeded,
        type,
        response,
        formatMessage,
        handleOnCloseModal,
        setMessageType,
        history,
        pathname,
        meta,
    ]);

    useEffect(() => {
        if (isFormSubmissionError(error) && !meta?.caseNumber) {
            notifications.error({
                message: formatMessage({
                    id: 'incident.create.failed',
                }),
                description: formatMessage({
                    id: 'incident.create.failed.description',
                }),
            });
            handleOnCloseModal();
        }
    }, [notifications, error, formatMessage, handleOnCloseModal, meta]);

    const handleOnNotFound = e => {
        e?.preventDefault();
        navigate(null);
        elevatorDialogNavigate(DialogState.SELECTION);
    };

    const handleOnFormChange = (form: any) => {
        setMessageType(null);
    };

    const handleOnSubmit = values => {
        if (values.elevator && !contractLength) {
            setMessageType(ErrorTypes.NO_CONTRACT);
            return;
        }
        handleSubmit(values);
    };

    return (
        <div data-testid="new-incident-dialog-selection">
            {!!messageType && (
                <div className={styles.alert}>
                    <NewIncidentAlert type={messageType} setErrorType={setMessageType} onClose={handleOnCloseModal} />
                </div>
            )}
            <div className={styles.header}>
                <Typography.Title level={2} className={styles.title}>
                    <FormattedMessage id="incident.new.dialog.selection.title" />
                </Typography.Title>
            </div>
            <div className={styles.content}>
                <Form
                    className={styles.container}
                    form={form}
                    onFinish={handleOnSubmit}
                    layout="vertical"
                    onValuesChange={handleOnFormChange}
                >
                    <Typography.Paragraph className={styles.description}>
                        <FormattedMessage id="incident.new.dialog.selection.description" />
                    </Typography.Paragraph>
                    <Form.Item name={fields.incident.elevator}>
                        <NewIncidentSearchInput
                            elevators={elevators}
                            elevator={elevatorDetail}
                            onNotFound={handleOnNotFound}
                            onChange={setElevator}
                            setContractLength={setContractLength}
                        />
                    </Form.Item>
                    <FloatingLabel
                        htmlFor="type"
                        floating={Boolean(type)}
                        label={formatMessage({
                            id: 'incident.new.dialog.input.incidentType.title',
                        })}
                    >
                        {({ onFocus, onBlur, ...rest }) => (
                            <Form.Item name={fields.incident.incidentType}>
                                <Select
                                    id="type"
                                    value={type}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={setType}
                                    options={[
                                        {
                                            label: formatMessage({ id: 'incident.new.dialog.incidenttype.breakdown' }),
                                            value: 'breakdown',
                                        },
                                        {
                                            label: formatMessage({ id: 'incident.new.dialog.incidenttype.issue' }),
                                            value: 'issues',
                                        },
                                    ]}
                                    // disabled={disabled}
                                    placeholder={formatMessage({
                                        id: 'incident.new.dialog.input.incidentType.placeholder',
                                    })}
                                    fullWidth
                                    floatingLabel
                                    data-testid="incident-type-input"
                                    {...rest}
                                />
                            </Form.Item>
                        )}
                    </FloatingLabel>
                    <Form.Item name={fields.incident.description}>
                        <TextArea
                            ref={containerRef}
                            className={styles.textArea}
                            placeholder={formatMessage({ id: 'incident.new.dialog.input.description.title' })}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            onChange={({ target }) => {
                                setDescription(target.value);
                            }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <div className={styles.bottomButtons}>
                            <Button
                                size="large"
                                onClick={() => {
                                    handleOnCloseModal();
                                }}
                            >
                                <FormattedMessage id="incident.new.dialog.button.cancel" />
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                onClick={() => {}}
                                disabled={!type || submitting || loading || !elevator || !description}
                            >
                                <FormattedMessage id="incident.new.dialog.button.submit" />
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
