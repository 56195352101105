import { createSelector } from 'reselect';
import { HealthState, selectors as elevatorStateSelectors } from 'modules/entities/modules/elevator-state';
import {
    HealthSectionsKeyValue,
    HealthStateSectionsName,
    HealthStateSubSectionName,
    ParsedHealthState,
    ParsedSection,
} from 'modules/entities/modules/elevators';
import { IconType } from 'modules/ui';
import { healthStateStatusIconMap } from 'modules/elevator-detail/components/IotDataTab/StatusCard/DetailedStatus/DetailedStatusIcon';
import { SectionMeta } from 'modules/elevator-detail/components/IotDataTab/HealthStateCard/Sections/SubSectionDetailStatus/SubSectionDetailStatus';
import { objectKeys } from 'services/utils';
import { healthStatePriorityField } from 'modules/elevator-detail/components/IotDataTab/HealthStateCard/Sections/config';
import moment from 'moment';

export const selectElevatorStateNew = createSelector(elevatorStateSelectors.selectElevatorState, state => state);

const subStateNameKeys = Object.keys(HealthStateSubSectionName);
const sortFn = (a: SectionMeta, b: SectionMeta) => {
    return subStateNameKeys.indexOf(a.name) - subStateNameKeys.indexOf(b.name);
};

export const selectedSections = createSelector(elevatorStateSelectors.selectElevatorState, (states: HealthState) => {
    // const states: HealthState = elevatorTestData;
    const elevatorHealth = states?.elevatorHealth;
    const deviceHealth = states?.deviceHealth;
    const transformed: ParsedHealthState = {
        elevatorHealth: null,
        deviceHealth: null,
    };
    if (elevatorHealth?.sections) {
        const elevatorHealthKeys = objectKeys(elevatorHealth?.sections);
        const pElevatorHealth = [];
        for (let index = 0; index < elevatorHealthKeys.length; index++) {
            const sectionKey = elevatorHealthKeys[index];
            const section = states?.elevatorHealth?.sections[sectionKey];
            // TODO: get different type for section and subsections
            if (!section.enabled) {
                pElevatorHealth.push({
                    enabled: section.enabled,
                    name: sectionKey,
                    isOkState: false,
                    parentState: sectionKey,
                });
            } else {
                const isOkState: boolean =
                    section[`${sectionKey}_OK`]?.active ?? section['TECHNICIAN_NOTONSITE']?.active;

                const subStates: { activeSections: ParsedSection[]; otherSections: ParsedSection[] } = {
                    activeSections: [],
                    otherSections: [],
                };
                const sectionKeys = objectKeys(section);

                for (let index = 0; index < sectionKeys.length; index++) {
                    const key = sectionKeys[index];

                    if (key !== 'timestamp' && key !== 'enabled' && key !== 'probability') {
                        const sec = section[key] as any;
                        if (!!sec && sec?.active === false)
                            // if section is not enabled or has not key data
                            subStates.otherSections.push({
                                name: key,
                                priorityField: 'createdAt',
                                enabled: !!sec,
                                active: sec?.active,
                                iconType: healthStateStatusIconMap[key],
                                createdAt: moment.utc(sec?.timestamp * 1000).toDate(),
                            });

                        if (sec?.active) {
                            subStates.activeSections.push({
                                name: key,
                                active: sec?.active,
                                priorityField:
                                    sectionKey === 'USAGEPROFILE' && isOkState
                                        ? healthStatePriorityField[key]
                                        : healthStatePriorityField[sectionKey],
                                iconType: healthStateStatusIconMap[key],
                                createdAt: moment.utc(sec?.timestamp * 1000).toDate(),
                                // @ts-ignore
                                probability: section?.probability?.toFixed(2) * 100,
                                // @ts-ignore
                                level: section.level,
                                // @ts-ignore
                                lastFloor: section.lastFloor,
                                // @ts-ignore
                                lastTripDoorCloseCount: section.lastTripDoorCloseCount,
                            });
                        }
                    }
                }

                const j = {
                    isOkState,
                    iconType:
                        sectionKey === HealthStateSectionsName.TECHNICIAN && isOkState
                            ? IconType.TECHNICIAN_NOT_ON_SITE
                            : isOkState
                            ? IconType.ELEVATOR_STATUS_SUCCESS
                            : healthStateStatusIconMap[subStates?.activeSections[0]?.name],
                    parentState: sectionKey,
                    name: sectionKey,
                    priorityField:
                        sectionKey === 'USAGEPROFILE' && isOkState
                            ? healthStatePriorityField[subStates?.activeSections[0]?.name]
                            : healthStatePriorityField[sectionKey],
                    subStates: [...subStates.activeSections],
                };
                pElevatorHealth.push(j);
            }
        }
        transformed.elevatorHealth = pElevatorHealth;
    }

    if (deviceHealth?.sections) {
        const deviceHealthKeys = Object.keys(deviceHealth?.sections);
        const pDeviceHealth = [];
        const isOkState: boolean = deviceHealth.overallState === 'RUNNING';
        for (let index = 0; index < deviceHealthKeys.length; index++) {
            const sectionKey = deviceHealthKeys[index];
            const section = states?.deviceHealth?.sections[sectionKey];
            const sectionKeys = Object.keys(section);

            if (section && sectionKeys.length > 0) {
                let iconType = isOkState ? IconType.ELEVATOR_STATUS_SUCCESS : IconType.ELEVATOR_STATUS_INACTIVE;
                if (!isOkState) {
                    if (section['IOTDEVICE_BLACKOUT'] && section['IOTDEVICE_BLACKOUT'][0]?.deviceBlackout) {
                        iconType = healthStateStatusIconMap['IOTDEVICE_BLACKOUT'];
                    } else if (section['IOTDEVICE_OFFLINE'] && section['IOTDEVICE_OFFLINE'][0]?.state !== 'connected') {
                        iconType = healthStateStatusIconMap['IOTDEVICE_OFFLINE'];
                    }
                }

                pDeviceHealth.push({
                    isOkState,
                    iconType,
                    parentState: sectionKey,
                    priorityField: 'createdAt',
                    subStates: sectionKeys.reduce((acc, cur) => {
                        const sec = section[cur];
                        let dt = section[cur][0]['timestamp'] || 0;
                        if (cur === 'IOTDEVICE_BLACKOUT') {
                            dt = section[cur][0]['timestamp'] * 1000;
                        }
                        if ((!!sec && sec[0]?.state !== 'connected' && sec[0]?.deviceBlackout !== false) || isOkState) {
                            acc.push({
                                name: cur,
                                priorityField: 'createdAt',
                                iconType: isOkState ? IconType.ELEVATOR_STATUS_SUCCESS : healthStateStatusIconMap[cur],
                                createdAt: moment.utc(dt).toDate(),
                            });
                        }

                        return acc;
                    }, []),
                });
            }
        }

        transformed.deviceHealth = pDeviceHealth;
    }

    return transformed;
});

function sortHealthState(sections: ParsedSection[]) {
    const sectionNameKeys = Object.keys(HealthStateSectionsName);
    const stateKey = sections.map((s, index) => ({ key: s.parentState, index }));
    const stateKeys = stateKey.sort((a: any, b: any) => {
        return sectionNameKeys.indexOf(a.key.split('_')[0]) - sectionNameKeys.indexOf(b.key.split('_')[0]);
    });

    const unSortedSubStatesByState: HealthSectionsKeyValue[] = [];

    stateKeys.forEach(({ key, index }) => {
        const stateName = key.split('_')[0];
        const subState = sections[index];
        if (!unSortedSubStatesByState[stateName]) {
            unSortedSubStatesByState[stateName] = {};
        }
        unSortedSubStatesByState[stateName] = { ...subState, name: key };
    });
    return unSortedSubStatesByState;
}

export const selectSortHealthState = createSelector(selectedSections, (healthState: ParsedHealthState) => {
    const elevatorHealthSections = healthState?.elevatorHealth || [];
    const deviceHealthSections = healthState?.deviceHealth || [];

    return {
        elevatorHealth: sortHealthState(elevatorHealthSections),
        deviceHealth: sortHealthState(deviceHealthSections),
    };
});

function sortHealtSubSections(subsection: any[]) {
    const j = Object.entries(subsection).reduce((subStatesByState, entry) => {
        const [name, section] = entry;

        if (healthStatePriorityField[name] && Array.isArray(section.subStates)) {
            const sortedSubstates = section.subStates.sort(sortFn);
            subStatesByState[name] = { ...section, subStates: sortedSubstates };
        } else {
            subStatesByState[name] = section;
        }
        return subStatesByState;
    }, []);

    return j;
}

export const selectFilteredSubSectionStates = createSelector(
    selectSortHealthState,
    ({ elevatorHealth, deviceHealth }): { elevatorHealth: ParsedSection[]; deviceHealth: ParsedSection[] } => {
        const elevatorHeal = sortHealtSubSections(elevatorHealth);
        const deviceHeal = sortHealtSubSections(deviceHealth);
        return {
            elevatorHealth: Object.values(elevatorHeal),
            deviceHealth: Object.values(deviceHeal),
        };
    },
);
