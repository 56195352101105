import { basicApiReducer } from '@ackee/redux-utils';

import { fetchAverageTripsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_REQUEST,
        SUCCESS: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_SUCCESS,
        FAILURE: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_FAILURE,
        CANCEL: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_CANCEL,
        RESET: fetchAverageTripsTypes.FETCH_AVERAGE_TRIPS_RESET,
    },
});
