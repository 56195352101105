import React from 'react';

export const AddElevator = () => (
    <svg width="32" height="32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 3a7 7 0 0 0-6.71 9H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V16.92c.33.052.665.079 1 .08a7 7 0 1 0 0-14ZM4 14h7v13H4V14Zm16 13h-7V14h4.26A7.05 7.05 0 0 0 20 16.31V27Zm3-12a5 5 0 1 1 0-10 5 5 0 0 1 0 10ZM9 11h4a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2Zm15-2h1a1 1 0 1 1 0 2h-1v1a1 1 0 0 1-2 0v-1h-1a1 1 0 0 1 0-2h1V8a1 1 0 0 1 2 0v1Z"
        />
    </svg>
);
