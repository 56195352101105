import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';

import { DateRange } from 'modules/form';
import { DataCard, DataSpace } from 'modules/ui';

import { useFloorActivity, useDateRange } from '../../../hooks';

import VerticalBarChart from '../../VerticalBarChart';

import * as felaRules from './DoorMovement.styles';

const BAR_SIZE = 16;
const BAR_CATEGORY_GAP = 14;

const floorsDemo = [
    { floor: '3', trips: 3434, doors: [{ doorNumber: 1, movement: 5269 }] },
    { floor: '2', trips: 2415, doors: [{ doorNumber: 1, movement: 2921 }] },
    { floor: '1', trips: 1889, doors: [{ doorNumber: 1, movement: 2237 }] },
    { floor: '0', trips: 3361, doors: [{ doorNumber: 1, movement: 5156 }] },
];

const chatDataDemo = [
    { floor: '3', trips: 3434, doorMovements: 5269 },
    { floor: '2', trips: 2415, doorMovements: 2921 },
    { floor: '1', trips: 1889, doorMovements: 2237 },
    { floor: '0', trips: 3361, doorMovements: 5156 },
];

// TODO: don't forget to reenable cypress tests in cypress/integration/doorMovement.spec.ts when bringing back the door openings
const DoorMovement: FC<{ isDeactivated?: boolean }> = ({ isDeactivated = false }) => {
    const { styles, theme } = useFelaEnhanced(felaRules);

    const { startTime, endTime, handleDateChange } = useDateRange();
    const {
        totalTrips,
        // doorMovements,
        floors,
        chartData,
        inProgress,
        error,
    } = useFloorActivity({
        startTime,
        endTime,
    });
    let floorsSummary = floors;
    let chartDataSummary = chartData;
    let totalTripsSummary = totalTrips;

    if (isDeactivated) {
        totalTripsSummary = 11099;
        // @ts-ignore
        floorsSummary = floorsDemo;
        // @ts-ignore
        chartDataSummary = chatDataDemo;
    }

    return (
        <DataCard
            style={{ filter: isDeactivated ? 'blur(1px)' : 'none' }}
            title={
                <Typography.Title level={4}>
                    <FormattedMessage id="elevator.doorMovements.titleTrips" />
                </Typography.Title>
            }
            subtitle={<DateRange onChange={handleDateChange} />}
            loading={inProgress}
            error={error}
            empty={totalTripsSummary === 0}
            data-test-id="door-movement"
        >
            <div className={styles.header}>
                {!isDeactivated && (
                    <DataSpace
                        name={<FormattedMessage id="elevator.doorMovements.totalTrips" />}
                        data={totalTripsSummary}
                        testId="totalTrips"
                    />
                )}
                {/*doorMovements.map(doorMovement => (
                    <DataSpace
                        data={doorMovement.movement}
                        key={doorMovement.doorNumber}
                        name={
                            <FormattedMessage
                                id="elevator.doorMovements.doorName"
                                values={{
                                    doorName: doorMovement.doorNumber > 1 ? ` ${doorMovement.doorNumber}` : '',
                                }}
                            />
                        }
                        testId={`doorMovement${doorMovement.doorNumber}`}
                    />
                    ))*/}
            </div>

            {floorsSummary && (
                <VerticalBarChart
                    data={chartDataSummary}
                    width={theme.width.verticalChart}
                    names={{
                        doorMovements: <FormattedMessage id="elevator.doorMovements.tooltip.doorOpenings" />,
                        trips: <FormattedMessage id="elevator.doorMovements.tooltip.trips" />,
                    }}
                    barColors={{ trips: theme.colors.graphSecondaryBarColor, doorMovements: theme.colors.lightBlue }}
                    barDataKeys={[
                        //'doorMovements',
                        'trips',
                    ]}
                    height={
                        theme.height.verticalChartBase +
                        BAR_SIZE * chartDataSummary.length +
                        BAR_CATEGORY_GAP * chartDataSummary.length
                    }
                    yAxisDataKey="floor"
                    customTooltip
                    // legend
                    showGrid={false}
                    barRadius={2}
                    barSize={8}
                    barGap={2}
                    barCategoryGap={BAR_CATEGORY_GAP}
                />
            )}
        </DataCard>
    );
};

export default DoorMovement;
