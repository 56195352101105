import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';
import { useElevatorCoordinates } from 'modules/entities/modules/elevator-coordinates';

import type { Search } from 'constants/index';
import { selectElevatorGroups, selectElevatorsCount } from '../services/selectors';
import { useFetchElevators } from './useFetchElevators';

import { DEFAULT_SORT } from '../constants';

export const useMapElevators = (search: Search[]) => {
    const api = useFetchElevators();

    const elevatorGroups = useSelector(state => selectElevatorGroups(state, { search, sorting: DEFAULT_SORT }));
    const elevatorsCount = useSelector(selectElevatorsCount);
    const { progress: coordinatesProgress } = useElevatorCoordinates();

    useDisplayError(api.error);

    return {
        loading: api.inProgress || coordinatesProgress !== 100,
        elevatorGroups,
        empty: elevatorsCount === 0,
    };
};
