import { all } from 'redux-saga/effects';

import { saga as petrus } from '../../config/petrus';
import loginForm from './loginForm';
import registration from './registration';
import { loginRequestSaga } from './authenticate';
import logout from './logout';
import confirmPasswordForm from './confirmPasswordForm';
import forgottenPasswordEmail from './forgottenPasswordEmailForm';
import forgottenPasswordForm from './forgottenPasswordForm';

export default function* sagas() {
    yield all([
        loginForm(),
        confirmPasswordForm(),
        forgottenPasswordEmail(),
        forgottenPasswordForm(),
        registration(),
        petrus(),
        logout(),
        loginRequestSaga(),
    ]);
}
