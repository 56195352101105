import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useNotifications } from 'modules/ui';
import { useUploadDocument } from 'modules/entities/modules/documents';

const ELEVATOR_UPLOAD_ID = 'elevator-upload-id';

export const useUploadNewElevatorDocument = () => {
    const notifications = useNotifications();

    const notificationsRef = useRef(notifications);

    const { api, uploadDocument, resetUpload } = useUploadDocument(ELEVATOR_UPLOAD_ID);

    useEffect(() => {
        if (api.success) {
            notificationsRef.current.open({
                type: 'success',
                message: <FormattedMessage id="elevator.new.upload.success.title" />,
                description: <FormattedMessage id="elevator.new.upload.success.desc" />,
                duration: 4,
            });
        }
    }, [api.success]);

    useEffect(() => {
        if (api.error) {
            notificationsRef.current.open({
                type: 'error',
                message: <FormattedMessage id="elevator.new.upload.error.title" />,
                description: <FormattedMessage id="elevator.new.upload.error.desc" />,
                duration: 4,
            });
        }
    }, [api.error]);

    return {
        api,
        uploadDocument,
        resetUpload,
    };
};
