import React from 'react';
import { useFela } from 'react-fela';
import { startCase } from 'lodash';

import type { Theme } from 'styles/theme';

import { Icon, IconType } from 'modules/ui';

import * as felaRules from './DocumentStatusIcon.rules';

export interface DocumentStatusIconProps<Status extends string = string> {
    status: Status;
    small?: boolean;
    getColor: (theme: Theme, status: Status) => string;
}

export function DocumentStatusIcon<Status extends string = string>({
    status,
    small,
    getColor,
}: DocumentStatusIconProps<Status>) {
    const { theme } = useFela<Theme>();
    const { css } = useFela({
        small,
        color: getColor(theme, status),
    });

    return (
        <Icon
            type={IconType.ELEVATOR_STATUS}
            data-testid={`status-icon-${status}`}
            className={
                // TODO: make useFela compatible with TRule
                /* @ts-ignore */
                css(felaRules.icon)
            }
            aria-label={startCase(status)}
        />
    );
}
