import React from 'react';

import { Icon, IconType } from 'modules/ui';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './InfoIcon.styles';

interface DocumentInfoIconProps {
    className?: string;
}

export const InfoIcon = ({ className, ...rest }: DocumentInfoIconProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <span className={className}>
            <Icon type={IconType.INFO} className={styles.icon} data-testid="popover-icon" {...rest} />
        </span>
    );
};
