import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';

import { SupportSubjectsType } from '../../types';

export const postSupportRequest = createAction(
    `${EntityKey.SUPPORT_REQUEST}/SEND`,
    ({ subject, description, title }: { subject: SupportSubjectsType; description?: string; title: string }) => ({
        payload: {
            subject,
            description,
            topic: title,
        },
        meta: {
            subject,
        },
    }),
);

export const postSupportRequestRequest = createAction(`${EntityKey.DOCUMENTS}/SEND_REQUEST`, subject => ({
    payload: null,
    meta: {
        subject,
    },
}));

export const postSupportRequestSuccess = createAction(
    `${EntityKey.DOCUMENTS}/SEND_SUCCESS`,
    (subject: string, lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            subject,
            lastSuccessAt,
        },
    }),
);

export const postSupportRequestFailure = createAction(
    `${EntityKey.DOCUMENTS}/SEND_FAILURE`,
    (error: any, subject: string) => ({
        payload: null,
        error,
        meta: {
            subject,
        },
    }),
);

export const postSupportRequestReset = createAction(`${EntityKey.DOCUMENTS}/SEND_RESET`, (subject: string) => ({
    payload: null,
    meta: {
        subject,
    },
}));
