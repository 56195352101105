import { HealthStateSectionsName, HealthStateSubSectionName } from "modules/entities/modules/elevators";

export const healthStatePriorityField = {
    [HealthStateSectionsName.TRIPANALYSIS]: 'createdAt',
    [HealthStateSectionsName.USAGEPROFILE]: 'probability',
    [HealthStateSubSectionName.TRIPANALYSIS_BREAKDOWNEMERGENCYSTOP]: 'createdAt',
    [HealthStateSubSectionName.TRIPPROFILE_ISSUE]: 'probability',
    [HealthStateSectionsName.CABINLIGHT]: 'level',
    [HealthStateSectionsName.IOTDEVICE]: 'createdAt',
    [HealthStateSectionsName.TECHNICIAN]: 'createdAt',
    [HealthStateSectionsName.DOORS]: 'createdAt',
};
