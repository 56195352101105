export const BROWSER_URLS = [
    {
        browser: 'Google Chrome',
        url: 'https://support.google.com/chrome/answer/95414?hl=de&co=GENIE.Platform%3DDesktop',
        key: 'CHROME',
    },

    {
        browser: 'Mozilla Firefox',
        url: 'https://support.mozilla.org/de/kb/firefox-auf-die-letzte-version-aktualisieren',
        key: 'FIREFOX',
    },
    {
        browser: 'Microsoft Edge',
        url:
            'https://support.microsoft.com/de-de/topic/aktualisieren-auf-den-neuen-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2#bkmk_download_install',
        key: 'MICROSOFT_EDGE',
    },
];
