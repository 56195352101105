import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '1rem',
});

export const info: TRuleWithTheme = () => ({
    fontSize: '0.875rem',
});
