import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SearchValue, SortingType } from 'constants/index';

import { Input } from 'modules/form';
import { stringComparatorFactory, TableSearch, TableSearchSelect, useTableSearch } from 'modules/table';
import {
    IncidentSearchFields,
    IncidentStatusFilter,
    RepairPhase,
    TroubleshootingPhase,
} from 'modules/entities/modules/incidents';
import { selectIncidentOrigins, selectIncidentPhases } from 'modules/entities/modules/incidents/services/selectors';

export interface IncidentsSearchProps {
    defaultValue?: SearchValue;
    loading?: boolean;
    isClosed?: boolean;
}

const defaultSearch = {};

export const IncidentsSearch = ({ defaultValue = defaultSearch, loading, isClosed }: IncidentsSearchProps) => {
    const intl = useIntl();
    // const incidentSeverities = useSelector(selectIncidentSeverities);
    const incidentSeverities = [
        { value: 'Eingeschlossene Person', label: 'Eingeschlossene Person' },
        { value: 'Sonstiger Defekt', label: 'Sonstiger Defekt' },
        { value: 'Stillstand des Aufzugs', label: 'Stillstand des Aufzugs' },
    ];
    const incidentOrigins = useSelector(selectIncidentOrigins);
    const incidentPhases = useSelector(selectIncidentPhases);
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue, true);

    const getSubPhaseList = () => {
        const phaseFilter = value[IncidentSearchFields.CASE_STATUS];
        const phaseList = phaseFilter ? phaseFilter.split('|') : [];

        let items = [];
        if (phaseList.length) {
            phaseList.forEach(v => {
                let rest = [];
                if (v === IncidentStatusFilter.TROUBLESHOOTING) {
                    rest = Object.keys(TroubleshootingPhase).map(i => ({
                        value: TroubleshootingPhase[i],
                        label: intl.formatMessage({ id: `page.incidents.filter.${i}` }),
                    }));
                } else if (v === IncidentStatusFilter.REPAIR) {
                    rest = Object.keys(RepairPhase).map(i => ({
                        value: RepairPhase[i],
                        label: intl.formatMessage({ id: `page.incidents.filter.${i}` }),
                    }));
                }
                items = [...items, ...rest];
            });
        } else {
            let repairPhase = [];
            let troubleshootingPhase = [];
            repairPhase = Object.keys(TroubleshootingPhase).map(i => ({
                value: TroubleshootingPhase[i],
                label: intl.formatMessage({ id: `page.incidents.filter.${i}` }),
            }));
            troubleshootingPhase = Object.keys(RepairPhase).map(i => ({
                value: RepairPhase[i],
                label: intl.formatMessage({ id: `page.incidents.filter.${i}` }),
            }));
            items = [...items, ...repairPhase, ...troubleshootingPhase];
        }

        const unique = [...new Map(items.map(item => [item.value, item])).values()].sort(
            stringComparatorFactory({ field: 'label', type: SortingType.ASC }),
        );
        return unique;
    };
    return (
        <TableSearch
            fields={[
                {
                    label: <FormattedMessage id="page.incidents.table.factoryNumber" />,
                    labelFor: 'factoryNumber',
                    renderer: (
                        <Input
                            id="factoryNumber"
                            data-testid="factory-number"
                            value={value[IncidentSearchFields.FACTORY_NUMBER]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [IncidentSearchFields.FACTORY_NUMBER]: target.value,
                                });
                            }}
                        />
                    ),
                },
                // {
                //     label: <FormattedMessage id="page.incidents.table.name" />,
                //     labelFor: 'property-unit-name',
                //     renderer: (
                //         <Input
                //             id="property-unit-name"
                //             data-testid="property-unit-name"
                //             value={value[IncidentSearchFields.NAME]}
                //             placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                //             onChange={({ target }) => {
                //                 handleValue({
                //                     [IncidentSearchFields.NAME]: target.value,
                //                 });
                //             }}
                //         />
                //     ),
                // },
                {
                    label: <FormattedMessage id="page.incidents.table.street" />,
                    labelFor: 'property-unit-street',
                    renderer: (
                        <Input
                            id="property-unit-street"
                            data-testid="property-unit-street"
                            value={value[IncidentSearchFields.PROPERTY_UNIT_STREET]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [IncidentSearchFields.PROPERTY_UNIT_STREET]: target.value,
                                });
                            }}
                        />
                    ),
                },

                {
                    label: <FormattedMessage id="page.elevators.table.city" />,
                    labelFor: 'property-unit-city',
                    renderer: (
                        <Input
                            id="property-unit-city"
                            data-testid="property-unit-city"
                            value={value[IncidentSearchFields.PROPERTY_UNIT_CITY]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [IncidentSearchFields.PROPERTY_UNIT_CITY]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id="page.elevators.table.businessUnit" />,
                    labelFor: 'property-unit-business-unit',
                    renderer: (
                        <Input
                            id="property-unit-business-unit"
                            data-testid="property-unit-business-unit"
                            value={value[IncidentSearchFields.PROPERTY_UNIT_BUSINESS_UNIT]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [IncidentSearchFields.PROPERTY_UNIT_BUSINESS_UNIT]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`page.incidents.table.severity`} />,
                    renderer: (
                        <TableSearchSelect
                            mode="multiple"
                            options={incidentSeverities}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            value={value[IncidentSearchFields.CASE_SEVERITY]}
                            onChange={value => {
                                handleValue({
                                    [IncidentSearchFields.CASE_SEVERITY]: value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`page.incidents.table.origin`} />,
                    renderer: (
                        <TableSearchSelect
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            options={incidentOrigins}
                            value={value[IncidentSearchFields.CASE_ORIGIN]}
                            onChange={value => {
                                handleValue({
                                    [IncidentSearchFields.CASE_ORIGIN]: value,
                                });
                            }}
                        />
                    ),
                },
                ...(isClosed
                    ? []
                    : [
                          {
                              label: <FormattedMessage id={`page.incidents.table.phase`} />,
                              renderer: (
                                  <TableSearchSelect
                                      mode="multiple"
                                      placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                      options={incidentPhases}
                                      value={value[IncidentSearchFields.CASE_STATUS]}
                                      onChange={value => {
                                          handleValue({
                                              [IncidentSearchFields.CASE_STATUS]: value,
                                          });
                                          handleValue({
                                              [IncidentSearchFields.CASE_SUBSTATUS]: '',
                                          });
                                      }}
                                  />
                              ),
                          },
                      ]),
                {
                    label: <FormattedMessage id={`page.incidents.table.status`} />,
                    renderer: (
                        <TableSearchSelect
                            mode="multiple"
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            options={getSubPhaseList()}
                            disabled={loading}
                            value={value[IncidentSearchFields.CASE_SUBSTATUS]}
                            onChange={value => {
                                handleValue({
                                    [IncidentSearchFields.CASE_SUBSTATUS]: value,
                                });
                            }}
                        />
                    ),
                },
            ]}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 5, xxl: 4 }}
        />
    );
};
