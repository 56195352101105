import { call, put, takeEvery } from 'redux-saga/effects';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { UploadFile, ChecktypeDocumentsUploadType } from '../../types';

import { uploadDocument, uploadDocumentRequest, uploadDocumentSuccess, uploadDocumentFailure } from '../actions';

const typeMap = {
    [ChecktypeDocumentsUploadType.CONTRACT]: 'contract',
    [ChecktypeDocumentsUploadType.QUOTATION]: 'quotation',
    [ChecktypeDocumentsUploadType.ZUES]: 'zues',
    [ChecktypeDocumentsUploadType.ZUES_DEFICIENCY]: 'zues_deficiency',
    [ChecktypeDocumentsUploadType.ASSESSMENT_REPORT]: 'create_elevator_zus',
    [ChecktypeDocumentsUploadType.EXCEL]: 'create_elevator_table',
};

function* fileFromUrl({ fileUrl, name }: UploadFile) {
    const response: Response = yield call(fetch, fileUrl);

    const blob: Blob = yield call([response, response.blob]);

    // Edge doesn't support File constructor so instead we're using
    // Blob and filling missing fields so it will look like a File
    // @ts-ignore
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // @ts-ignore
        blob.name = name;
        // @ts-ignore
        blob.lastModifiedDate = new Date();
        return blob as File;
    }

    return new File([blob], name, {
        type: blob.type,
    });
}

function* getUploadData(files: UploadFile[]) {
    const data = new FormData();

    data.append('type', 'portal');

    for (const uploadFile of files) {
        const file: File = yield call(fileFromUrl, uploadFile);

        data.append('files', file);
    }

    return data;
}

function* uploadDocumentHandler(action: ReturnType<typeof uploadDocument>) {
    yield put(uploadDocumentRequest(action.meta.uploadId));

    try {
        const data: FormData = yield call(getUploadData, action.payload.files);

        yield* authApi.post(config.api.uploadDocuments, data, {
            uriParams: {
                service: action.meta.service,
                type: typeMap[action.payload.type] ?? action.payload.type,
            },
        });

        yield put(uploadDocumentSuccess(action.meta.uploadId));
    } catch (error) {
        log.error(error);

        yield put(uploadDocumentFailure(error, action.meta.uploadId));
    }
}

export function* uploadDocumentSaga() {
    yield takeEvery(uploadDocument.toString(), uploadDocumentHandler);
}
