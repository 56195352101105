import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    maxWidth: theme.width.container,
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const title: TRule = () => ({
    '&.ant-typography': {
        fontSize: '1.5rem',
        marginBottom: 0,
    },
});

export const selectContainer: TRule = () => ({
    marginRight: '.5rem',

    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

export const pagination: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
});
