import { TRuleWithTheme } from 'styles/theme';

export const group: TRuleWithTheme = ({ theme }) => ({});

export const container: TRuleWithTheme = ({ theme }) => ({
    '&:not(:last-child)': {
        marginBottom: '1.5rem',
        borderBottom: `1px solid ${theme.colors.dividerColor}`,
    },
    borderRadius: '0.125rem',
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
    zIndex: 2,
    position: 'inherit',
});

export const containerWithShadows: TRuleWithTheme = ({ theme }) => ({
    '&:not(:last-child)': {
        marginBottom: '1.5rem',
        borderBottom: `1px solid ${theme.colors.dividerColor}`,
    },
    borderRadius: '0.125rem',
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
    zIndex: 200,
    position: 'inherit',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.118563)',
});

export const headingDiv: TRuleWithTheme = () => ({
    // margin: '.25rem 0',
    paddingTop: '1.25rem',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingBottom: '1.25rem',
});
export const heading: TRuleWithTheme = () => ({
    fontSize: '20px',
    fontWeight: '600',
});
export const button: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
});
export const reportIncidentButton: TRuleWithTheme = () => ({
    padding: '5px 16px',
    gap: '10px',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
});

export const seeAllButton: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    lineHeight: '22px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 16px',
    gap: '10px',
});

export const buttonContainer: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    fontSize: '12px',
    padding: '0.75rem 1rem',
});
