import React, { FunctionComponent } from 'react';
import { Button, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { Icon, IconType } from 'modules/ui';
import { useLogout } from 'modules/auth';

import * as rules from './UserMenu.styles';

export interface UserMenuProps {}

const UserMenu: FunctionComponent<UserMenuProps> = () => {
    const { styles } = useFelaEnhanced(rules);

    const logout = useLogout();

    return (
        <Menu className={styles.menu}>
            <Menu.Item key="0">
                <Button
                    type="text"
                    className={styles.logout}
                    icon={<Icon type={IconType.LOG_OUT} />}
                    onClick={logout}
                    data-testid="logout-button"
                >
                    <FormattedMessage id="auth.button.logout" />
                </Button>
            </Menu.Item>
        </Menu>
    );
};

export default UserMenu;
