import { createAction } from '@reduxjs/toolkit';
import { EntityKey } from 'constants/index';
import { LoginFormValues } from 'modules/auth/types';

export const loginRequest = createAction(`${EntityKey.LOGIN}/SEND`, ({ email, password }: LoginFormValues) => ({
    payload: {
        email,
        password,
    },
    meta: {
        email,
    },
}));


export const loginRequestRequest = createAction(`${EntityKey.LOGIN}/SEND_REQUEST`, email => ({
    payload: null,
    meta: {
        email,
    },
}));

export const loginRequestSuccess = createAction(
    `${EntityKey.LOGIN}/SEND_SUCCESS`,
    (email: string, lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            email,
            lastSuccessAt,
        },
    }),
);

export const loginRequestFailure = createAction(
    `${EntityKey.LOGIN}/SEND_FAILURE`,
    (error: any, email: string) => ({
        payload: null,
        error,
        meta: {
            email,
        },
    }),
);

export const loginRequestReset = createAction(`${EntityKey.LOGIN}/SEND_RESET`, (email: string) => ({
    payload: null,
    meta: {
        email,
    },
}));
