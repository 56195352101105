import React from 'react';
import type { ReactNode } from 'react';

import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './UploadDescription.rules';

export const UploadDescription = ({ children }: { children: ReactNode }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return <Typography.Paragraph className={styles.description}>{children}</Typography.Paragraph>;
};
