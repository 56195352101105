import type { Invoice } from '../../../types';
import type { ApiAction } from '../../../../../types';

import { fetchInvoicesTypes } from '../../actions';

const initialState: Invoice[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchInvoicesTypes.FETCH_INVOICES_SUCCESS:
            const invoices: Invoice[] = action.payload;

            return invoices;
        case fetchInvoicesTypes.FETCH_INVOICES_RESET:
            return initialState;
        default:
            return state;
    }
};
