import { put } from 'redux-saga/effects';

import * as log from 'config/loglevel';
import { fetchIncidents as actions } from '../../../entities/modules/incidents/services/actions';
import { forms } from '../../constants';
import { actions as formActions, takeFormSubmission } from 'modules/form';
import { NewIncidentFormValues } from 'modules/incident-new/types';
import { authApi } from 'config/antonio';
import config from 'config';
import { createUIErrorMessage } from 'modules/errors';

function* submitIncidentHandler(action: formActions.types.SubmitFormAction<NewIncidentFormValues>) {
    try {
        const res = yield* authApi.post(config.api.incidents, action.payload);

        // useFetchIncidents()

        yield put(actions.fetchIncidentsRequest());
        yield put(formActions.submitFormSuccess(forms.newIncident, res.data, action.meta.meta));
    } catch (error) {
        const customError = { ...error };
        if (error.code === 'NotAuthorizedException') {
            customError.code = 'NotAuthorizedExceptionCreatePassword';
        }
        log.error(customError);

        const uiError = createUIErrorMessage(customError);
        yield put(formActions.submitFormFailure(forms.newIncident, uiError + Date.now(), action.meta.meta));
    }
}

export function* submitIncidentSaga() {
    yield takeFormSubmission(forms.newIncident, submitIncidentHandler);
}
