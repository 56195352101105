import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { RcFile } from 'antd/lib/upload';

import { DropZone, DropZoneFileAction, useUIMessage } from 'modules/ui';

export interface GeneralUploadFileInputProps {
    value: RcFile[];
    onChange: (updateValue: (prevFiles: RcFile[]) => RcFile[]) => void;
    disabled: boolean;
}

const SUPPORTED_FILE_TYPES = ['.pdf', '.png', '.jpeg', '.jpg', '.tiff'];

export const GeneralUploadFileInput = ({ value, onChange, disabled }: GeneralUploadFileInputProps) => {
    const message = useUIMessage();

    return (
        <DropZone
            multiple
            text={<FormattedMessage id="documents.generalUpload.file.text" />}
            supportedFileTypes={SUPPORTED_FILE_TYPES}
            files={value}
            disabled={disabled}
            onFile={(file, action) => {
                switch (action) {
                    case DropZoneFileAction.DROPPED: {
                        onChange(prevFiles => [...prevFiles, file]);
                        return;
                    }
                    case DropZoneFileAction.REMOVED: {
                        onChange(prevFiles => prevFiles.filter(f => f.uid !== file.uid));
                        return;
                    }
                    case DropZoneFileAction.UNSUPPORTED: {
                        message('error', {
                            id: 'general.upload.unsupported',
                            values: {
                                name: file.name,
                            },
                        });
                    }
                }
            }}
        />
    );
};
