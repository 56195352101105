import React from 'react';

export const Protector = () => (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Vector">
            <path
                d="M12 0L0 4V16C0 22.6281 5.37187 28 12 28C18.6281 28 24 22.6281 24 16V4L12 0ZM21.75 16C21.75 21.3844 17.3844 25.75 12 25.75C6.61562 25.75 2.25 21.3844 2.25 16V5.6875L12 2.25L21.75 5.6875V16Z"
                fill="#A6A6A6"
            />
            <path
                d="M7.82499 12.8469C7.72072 12.7421 7.59678 12.659 7.4603 12.6023C7.32381 12.5456 7.17747 12.5164 7.02967 12.5164C6.88188 12.5164 6.73553 12.5456 6.59905 12.6023C6.46257 12.659 6.33863 12.7421 6.23436 12.8469C6.12961 12.9511 6.0465 13.0751 5.98978 13.2116C5.93306 13.348 5.90387 13.4944 5.90387 13.6422C5.90387 13.79 5.93306 13.9363 5.98978 14.0728C6.0465 14.2093 6.12961 14.3332 6.23436 14.4375L10.3437 18.5469C10.4424 18.6457 10.5595 18.7241 10.6885 18.7776C10.8174 18.8311 10.9557 18.8586 11.0953 18.8586C11.2349 18.8586 11.3732 18.8311 11.5021 18.7776C11.6311 18.7241 11.7482 18.6457 11.8469 18.5469L18.8312 11.5625C18.93 11.4639 19.0084 11.3467 19.0619 11.2178C19.1154 11.0888 19.143 10.9506 19.143 10.8109C19.143 10.6713 19.1154 10.5331 19.0619 10.4041C19.0084 10.2752 18.93 10.158 18.8312 10.0594L18.7437 9.97188C18.6451 9.87306 18.528 9.79467 18.399 9.74118C18.27 9.68769 18.1318 9.66016 17.9922 9.66016C17.8526 9.66016 17.7143 9.68769 17.5854 9.74118C17.4564 9.79467 17.3392 9.87306 17.2406 9.97188L11.0937 16.1156L7.82499 12.8469Z"
                fill="#A6A6A6"
            />
        </g>
    </svg>
);
