import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    position: 'relative',

    padding: '1.5rem',

    marginBottom: '-0.75rem',
});

export const title: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        fontSize: '.75rem',
        fontWeight: 'normal',
        color: theme.colors.textSecondary,
        marginTop: '0.35rem',
        marginRight: '1rem',
    },
});
