import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const pickerContainer: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& .ant-picker-focused': {
        '& .anticon-calendar': {
            color: '#f57645 !important',
        },
        '& .anticon-time': {
            color: '#f57645 !important',
        },
    },
});

export const datePicker: TRule<{ theme: Theme }> = ({ theme }) => ({
    '&.ant-picker-range': {
        width: '100%',
        margin: '.75rem 0 .469rem',
        padding: '.25rem .75rem',
        '& .ant-picker-input > input': {
            fontSize: '.875rem',
        },
    },
    '& .ant-picker-active-bar': {
        display: 'none',
    },
    '& .ant-picker-range-separator': {
        marginRight: '.75em',
    },
    '& .ant-picker-clear .anticon': {
        color: 'inherit',
    },
    '& .ant-picker-clear': {
        right: '.75rem',
    },
});

export const dropdown: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& .ant-picker-content td': {
        fontSize: '.875rem',
    },
    '& .ant-picker-content th': {
        fontSize: '.875rem',
        fontWeight: 600,
    },

    '& .ant-picker-cell .ant-picker-cell-inner': {
        lineHeight: '24px',
        height: 24,
        minWidth: 24,
    },

    '& .ant-picker-cell::before': {
        height: 24,
    },

    '& .ant-picker-cell.ant-picker-cell-today': {
        color: theme.colors.primary,
    },

    '& .ant-picker-header-view': {
        fontSize: '.875rem',
        padding: '.25rem 0',
    },
});
