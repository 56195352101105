import React from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import type { Quotation } from 'modules/entities/modules/documents-quotations';
import { QuotationField, DEFAULT_SORT } from 'modules/entities/modules/documents-quotations';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

export interface ActionPanelProps {
    documents: Quotation[];
}

const SORT_FIELDS = [
    QuotationField.QUOTATION_DATE,
    QuotationField.SERVICE_PROVIDER_NAME,
    QuotationField.TOTAL_REDUCTION,
    QuotationField.REDUCED_AMOUNT,
];

export const QuotationsActionPanel = ({ documents }: ActionPanelProps) => {
    return (
        <TableActionPanel testId="quotations-actions">
            <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
            <DocumentsDownloadAll documents={documents} />
        </TableActionPanel>
    );
};
