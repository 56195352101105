import type { TRuleWithTheme } from 'styles/theme';

export const tag: TRuleWithTheme = ({ theme }) => ({
    display: 'block',

    width: 'fit-content',

    marginRight: 'auto',
    marginBottom: '1.25rem',
    marginLeft: 'auto',

    paddingTop: 'calc(0.25rem + 1px)',
    paddingRight: '0.75rem',
    paddingBottom: 'calc(0.25rem - 1px)',
    paddingLeft: '0.75rem',

    lineHeight: 1.5,

    borderRadius: '1rem',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.dividerColor,
});
