import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { useWorkorderDocuments, WorkorderType } from 'modules/entities/modules/workorder-documents';

import { DocumentInfo } from '../DocumentInfo';

import { RiskAssessmentReportsSearchPanel } from './RiskAssessmentReportsSearchPanel';
import { RiskAssessmentReportsActionPanel } from './RiskAssessmentReportsActionPanel';
import { RiskAssessmentReportsTable } from './RiskAssessmentReportsTable';
import { useRouteMatch } from 'react-router-dom';

export const RiskAssessmentReportsTab = () => {
    const intl = useIntl();
    const { loading, documents, defaultSearch } = useWorkorderDocuments(WorkorderType.GBU);
    const match = useRouteMatch('/elevators/:id');

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'documents.riskAssessment.title' })} />
            <DocumentInfo>
                <FormattedMessage id="documents.riskAssessment.info" />
            </DocumentInfo>
            <RiskAssessmentReportsSearchPanel defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            <RiskAssessmentReportsActionPanel documents={documents} />
            <RiskAssessmentReportsTable loading={loading} documents={documents} />
        </>
    );
};
