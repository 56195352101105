import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import { NotFound, errorMessages, HTTPStatusCode } from 'modules/errors';
import { DataCard } from 'modules/ui';

import * as felaRules from './ActiveIncidentsList.style';

import IncidentItem from '../IncidentItem/IncidentItem';
import NoActiveIncident from '../NoActiveIncidents';
import { Incident } from 'modules/entities/modules/incidents';

export interface IncidentsListProps {
    loading: any;
    activeIncidents: Incident[];
    unvalidatedIncidents?: Incident[];
    error: any;
}

const ActiveIncidentsList: FC<IncidentsListProps> = ({ loading, activeIncidents, error, unvalidatedIncidents }) => {
    const { styles } = useFelaEnhanced(felaRules);

    if (error === errorMessages.status[HTTPStatusCode.NotFound]) {
        return <NotFound />;
    }

    const conbinedIncidents = [];
    if (unvalidatedIncidents) {
        conbinedIncidents.push(...unvalidatedIncidents);
    }
    if (activeIncidents) {
        conbinedIncidents.push(...activeIncidents);
    }

    return (
        <section className={styles.container}>
            <DataCard
                error={error}
                customEmptyCard={conbinedIncidents?.length === 0 && <NoActiveIncident size="large" />}
                empty={conbinedIncidents?.length === 0}
                loading={loading}
                className={styles.dataCard}
            >
                {conbinedIncidents.slice(0, 5).map(activeIncident => (
                    <IncidentItem key={activeIncident?.case.dateTimeOpened} incident={activeIncident} />
                ))}
            </DataCard>
        </section>
    );
};

export default ActiveIncidentsList;
