import React, { useMemo } from 'react';

import { ElevatorGroup } from 'modules/entities/modules/elevators';

import { InfoWindow } from '../InfoWindow';
import { getElevatorStateOrder } from 'modules/entities/modules/elevators/services/selectors';
export interface ClusterInfoWindowProps {
    elevatorGroups: ElevatorGroup[];
}

export const ClusterInfoWindow = ({ elevatorGroups }: ClusterInfoWindowProps) => {
    let elevatorGroupsWithState = elevatorGroups.map(elevatorGroup => {
        let overallState = 5; //State Value Device is unknown
        elevatorGroup.elevators.forEach(elevator => {
            let elevatorState = getElevatorStateOrder(elevator);
            if (elevatorState < overallState) {
                overallState = elevatorState;
            }
        });
        return {
            elevators: elevatorGroup.elevators.sort((a, b) => a.factoryNumber - b.factoryNumber),
            ...elevatorGroup,
            overallState: overallState,
        };
    });
    const elevators = useMemo(
        () =>
            elevatorGroupsWithState
                .sort(
                    (a, b) =>
                    a.propertyUnit.address.localeCompare(b.propertyUnit.address)
                )
                .sort((a,b) => a.overallState - b.overallState)
                .reduce((elevators, elevatorGroup) => [...elevators, ...elevatorGroup.elevators], []),
        [elevatorGroupsWithState],
    );

    return <InfoWindow elevators={elevators} />;
};
