import React from 'react';

export const UploadExcel = () => (
    <svg width="32" height="32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m29.71 7.296-4-4a.999.999 0 0 0-1.42 0l-4 4 1.42 1.42 2.29-2.3v9.59h2v-9.59l2.29 2.3 1.42-1.42ZM18 8.006h-3v-2a1 1 0 0 0-.805-.977 1 1 0 0 0-.454.017l-11 3a1 1 0 0 0-.74.96v16a1 1 0 0 0 .74 1l11 3a.747.747 0 0 0 .26 0 1 1 0 0 0 .896-.56 1 1 0 0 0 .104-.44v-2h3a1 1 0 0 0 1-1v-16a1 1 0 0 0-1-1Zm-5 18.69-9-2.45V9.766l9-2.45v19.38Zm2-16.69h2v14h-2v-14Zm-4.268 1.039a1 1 0 0 1 1.23.692 1 1 0 0 1-.091.759l-2.5 4.51 2.5 4.51a1 1 0 0 1-1.374 1.353 1 1 0 0 1-.366-.373l-1.91-3.43-1.35 2.43a1 1 0 0 1-1.36.38 1 1 0 0 1-.38-1.36l1.95-3.51-1.95-3.51a1 1 0 0 1 1.138-1.451 1 1 0 0 1 .602.47l1.35 2.43 1.91-3.43a1 1 0 0 1 .601-.47Z"
        />
    </svg>
);
