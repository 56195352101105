import React, { FC } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';

import * as felaRules from './IncidentItem.rules';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import config from 'config';
import { InfoIcon } from 'modules/maintenance/InfoIcon';

export interface ActiveIncidentProps {
    incident;
}

const IncidentItem: FC<ActiveIncidentProps> = ({ incident }) => {
    const { styles } = useFelaEnhanced(felaRules, { status: incident.case.status });

    const severity = incident?.case.severity;
    const dateTime = incident.case.dateTimeOpened;
    const factoryNumber = incident.factoryNumber;
    const address = incident.propertyUnit;

    const validationStatus = incident?.case?.validationStatus;
    return (
        <div className={styles.container}>
            <Link
                to={
                    incident
                        ? `${config.routes.incidents}?search=factoryNumber%3A${incident?.factoryNumber}&page=1`
                        : ''
                }
            >
                <Row>
                    <Col span={17}>
                        <Row className={styles.rowStyle}>
                            <Col span={10}>
                                <div className={validationStatus !== 'Open' ? styles.severity : styles.severityOpen}>
                                    <FormattedMessage id={`page.home.activeIncident.state.severity.${severity}`} />
                                </div>
                            </Col>
                            <Col offset={1} span={13}>
                                <div
                                    className={
                                        validationStatus !== 'Open' ? styles.factoryNumber : styles.factoryNumberOpen
                                    }
                                >
                                    {factoryNumber}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={10}>
                                <div className={styles.address}>{moment(dateTime).format('DD.MM.YYYY')}</div>
                                <div className={styles.address}>{moment(dateTime).format('HH:mm')}</div>
                            </Col>
                            <Col offset={1} span={13}>
                                <div className={styles.address}>{address.street},</div>
                                <div className={styles.address}>{address.city}</div>
                            </Col>
                        </Row>
                    </Col>

                    <Col className={styles.incidentButton} span={7}>
                        {validationStatus !== 'Open' ? (
                            <Button type="text" className={styles.buttonColor}>
                                <FormattedMessage
                                    id={`page.home.activeIncident.state.status.${incident?.case?.status}`}
                                />
                            </Button>
                        ) : (
                            <div className={styles.validationContainer}>
                                <InfoIcon className={styles.iconContainer} />
                                <div className={styles.validationText}>
                                    <FormattedMessage id={`page.home.activeIncident.state.invalidation`} />
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Link>
        </div>
    );
};

export default IncidentItem;
