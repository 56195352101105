import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input, Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { forms, fields } from '../../config';
import type { RegisterFormValues } from '../../types';

import * as felaRules from './RegistrationForm.rules';
import { ValidateStatus } from 'antd/lib/form/FormItem';

export interface RegistrationProps {
    submitting: boolean;
    onSubmit: (values: RegisterFormValues) => void;
}
const restrictedEmails = [
    '@gmail',
    '@googlemail',
    '@hotmail',
    '@yahoo',
    '@web.de',
    '@gmx',
    '@yahoo',
    '@freenet',
    '@1und1',
    '@hotmail',
];
export const RegistrationForm = ({ submitting, onSubmit }: RegistrationProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const [form] = Form.useForm();
    const [emailDetails, setEmailDetails] = useState<{
        validateStatus?: ValidateStatus;
        errorMsg?: string | null;
    }>();
    const intl = useIntl();
    const blockNonBusinessEmail = () => {
        let email = form.getFieldValue(fields.register.email);
        const nonBusinessEmail = restrictedEmails.some(restrictedEmail => {
            if (email?.toLowerCase().includes(restrictedEmail.toLowerCase())) {
                setEmailDetails({
                    validateStatus: 'error',
                    errorMsg: intl.formatMessage({ id: 'form.validation.businessEmail' }),
                });
                return true;
            }
            return false
        });
        if (nonBusinessEmail === undefined || nonBusinessEmail === false) {
            form.submit();
        }
    };

    return (
        <>
            <Typography.Title level={4} className={styles.title}>
                <FormattedMessage id="auth.title.registration" />
            </Typography.Title>
            <Form<RegisterFormValues> name={forms.register} form={form} layout="vertical" onFinish={onSubmit}>
                <div data-testid="registration-form">
                    <Form.Item
                        className={styles.item}
                        validateStatus={emailDetails?.validateStatus}
                        help={emailDetails?.errorMsg}
                        name={fields.register.email}
                        label={intl.formatMessage({ id: 'auth.businessEmail' })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'form.validation.required.email' }) },
                            {
                                type: 'email',
                                message: intl.formatMessage({ id: 'form.validation.email' }),
                            },
                        ]}
                    >
                        <Input
                            name={fields.register.email}
                            size="large"
                            autoComplete="email"
                            placeholder={intl.formatMessage({ id: 'auth.businessEmail' })}
                            onChange={() => setEmailDetails({})}
                        />
                    </Form.Item>
                    <Button
                        className={styles.button}
                        size="large"
                        type="primary"
                        //htmlType="submit"
                        disabled={submitting}
                        loading={submitting}
                        onClick={blockNonBusinessEmail}
                    >
                        <FormattedMessage id="auth.button.registration" />
                    </Button>
                    <Typography.Paragraph className={styles.terms}>
                        <FormattedMessage
                            id="auth.registration.terms"
                            values={{
                                termsLink: (chunks: any) => (
                                    <Typography.Link
                                        target="_blank"
                                        href="https://www.simplifa.de/app/uploads/2022/12/AGB_Simplifa_Stand_11_2022.pdf"
                                    >
                                        {chunks}
                                    </Typography.Link>
                                ),
                                policyLink: (chunks: any) => (
                                    <Typography.Link
                                        target="_blank"
                                        href="https://www.simplifa.de/datenschutzerklaerung/"
                                    >
                                        {chunks}
                                    </Typography.Link>
                                ),
                            }}
                        />
                    </Typography.Paragraph>
                </div>
            </Form>
        </>
    );
};
