import React, { FC } from 'react';
import DatePicker from 'antd/es/date-picker';
import type { RangePickerProps as AntRangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';

import { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './DateRange.styles';

const { RangePicker } = DatePicker;

const defaultDisabledDate = current => {
    const today = new Date();
    return current && current > moment(today, 'YYYY-MM-DD');
};

export type DateRangeProps = AntRangePickerProps & {
    extend?: RulesExtend<typeof felaRules>;
};

export const DateRange: FC<DateRangeProps> = ({
    format = 'DD.MM.YYYY',
    picker = 'date',
    extend,
    disabledDate = defaultDisabledDate,
    ...rest
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div className={styles.pickerContainer}>
            <RangePicker
                className={styles.datePicker}
                dropdownClassName={styles.dropdown}
                format={format}
                picker={picker}
                data-test-id="daterange-picker"
                disabledDate={disabledDate}
                {...rest}
            />
        </div>
    );
};
