import { SUPPORTED_WORKORDER_STATES } from '../constants';
import { Workorder, WorkOrderState } from '../types';

export const getPropertyUnitAdress = (workorder: Workorder) =>
    `${workorder?.Street__c}, ${
        workorder?.Zip_Code__c
    } ${workorder?.City__c}`;

export const isSupportedState = (state: WorkOrderState) => SUPPORTED_WORKORDER_STATES.includes(state);

export const getEnumKeyByValue = (enumObj: any, value: string) => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
};
