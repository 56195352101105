import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import * as felaRules from './NoActiveIncident.style';
import { Icon, IconType } from 'modules/ui';

export interface IconSize {
    size: 'small' | 'large';
}

const NoActiveIncident: FC<IconSize> = ({ size }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={size === 'small' ? styles.containerSmall : styles.container}>
            <div className={size === 'small' ? styles.deactivatedIconSmall : styles.deactivatedIcon}>
                <Icon type={IconType.PROTECTOR} />
            </div>
            <span className={size === 'small' ? styles.titleSmall : styles.title}>
                <FormattedMessage id="page.workordersMap.inProgress" />
            </span>
        </div>
    );
};

export default NoActiveIncident;
