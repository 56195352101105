import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginBottom: '2rem',
});
export const input: TRuleWithTheme = () => ({
    height: '3rem',
});
export const inputWithValue: TRuleWithTheme = () => ({
    height: '3rem',
    paddingTop: '1rem'
});
