import React, { FC } from 'react';

import { TableFilterPanel } from 'modules/table';
import { Divider } from 'modules/ui';

import StatusFilter from './StatusFilter';
import TableSearch, { TableSearchProps } from './TableSearch';

type FilterPanelProps = {
    defaultValue: TableSearchProps['defaultValue'];
    isElevatorDetail: boolean;
};

const FilterPanel: FC<FilterPanelProps> = ({ defaultValue, isElevatorDetail }) => {
    return (
        <TableFilterPanel>
            <StatusFilter />
            <Divider />
            <TableSearch defaultValue={defaultValue} isElevatorDetail={isElevatorDetail} />
        </TableFilterPanel>
    );
};

export default FilterPanel;
