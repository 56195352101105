import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { useWorkorderDocuments, WorkorderType } from 'modules/entities/modules/workorder-documents';

import { DocumentInfo } from '../DocumentInfo';

import { ConditionReportsTable } from './ConditionReportsTable';
import { ConditionReportsActionPanel } from './ConditionReportsActionPanel';
import { ConditionReportsSearchPanel } from './ConditionReportsSearchPanel';
import { useRouteMatch } from 'react-router-dom';

export const ConditionReportsTab = () => {
    const intl = useIntl();

    const { loading, documents, defaultSearch } = useWorkorderDocuments(WorkorderType.ZB);
    const match = useRouteMatch('/elevators/:id');

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'documents.conditionReport.title' })} />
            <DocumentInfo>
                <FormattedMessage id="documents.conditionReport.info" />
            </DocumentInfo>
            <ConditionReportsSearchPanel defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            <ConditionReportsActionPanel documents={documents} />
            <ConditionReportsTable loading={loading} documents={documents} />
        </>
    );
};
