import React from 'react';
import { useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';

import useContractsListTable from '../../hooks/useContractsListTable';

import { ContractsFilterPanel } from './ContractsFilterPanel';
import { ContractsActionPanel } from './ContractsActionPanel';
import { ContractsTable } from './ContractsTable';
import { useRouteMatch } from 'react-router-dom';

export const ContractsTab = () => {
    const intl = useIntl();

    const { api, contracts, defaultValue } = useContractsListTable();
    let match = useRouteMatch('/elevators/:id');

    return (
        <div data-testid="contracts-tab">
            <SeoHead title={intl.formatMessage({ id: 'documents.contracts.title' })} />
            <ContractsFilterPanel defaultValue={defaultValue} isElevatorDetail={match?.isExact}/>
            <ContractsActionPanel documents={contracts} />
            <ContractsTable loading={api.inProgress} documents={contracts}  />
        </div>
    );
};
