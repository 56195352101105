import { useSelector } from 'react-redux';
import { authUserSelector } from '../services/selectors';

const useRole = () => {
  const user = useSelector(authUserSelector)

  return {
    isGuest: user?.role === 'Guest'
  }
};

export default useRole;
