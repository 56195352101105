import React, { FC, useMemo } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import config from 'config/index';

import NoMatchPage from '../NoMatchPage';
import { routes as publicRoutes } from '../Routes';
import { ElevatorsPage } from 'modules/elevators';
import { MaintenancePage } from 'modules/maintenance';
import { ElevatorDetail, ElevatorHistory } from 'modules/elevator-detail';
import {
  DocumentsPage,
  UploadContractPage,
  UploadQuotationPage,
  UploadZUESPage,
  UploadZUESDeficiencyPage,
  GeneralDocumentUploadPage,
} from 'modules/documents';

import { FakePage, FakeType } from 'modules/fake-pages';
import { HomePage } from 'modules/home';
import { IncidentsPage } from 'modules/incidents';
import { SupportPage } from 'modules/support/components/SupportPage';
import { WorkOrdersPage } from 'modules/workOrders';
import TeamsPage from 'modules/teams';
import useUserPermissions from 'modules/auth/hooks/useUserPermissions';

export interface AuthRoutesProps {}

type RouteType = RouteProps & {
  shouldShow: boolean;
};

const AuthRoutes: FC<AuthRoutesProps> = () => {
  const {
    hasViewElevatorsPermission,
    hasViewDocumentsPermission,
    shouldUploadPriceCheckDocumentsPermissions,
    shouldUploadDeficiencyCheckPermissions,
    shouldUploadQuotationCheckPermissions,
    shouldUploadContractCheckPermissions,
    shouldViewMaintenanceOptimizationPermissions,
    shouldViewOperatorObligationsPermissions,
    shouldViewSupportRequestPermissions,
    shouldViewTeamsPermissions,
    shouldViewWorkOrders,
  } = useUserPermissions();

  const isElevoniqModifi = window.location.search.includes('elevoniq-modifi');

  // If user comes to public route automatically redirect him to the default auth route
  const routes = useMemo(() => {
    const redirectRoutes: Array<RouteType> = [...publicRoutes, { path: config.routes.home }]
      .filter(route => route.path)
      .map(route => ({
        ...route,
        render: () => <Redirect to={config.routes.home} />,
        shouldShow: true,
      }));

    const authRoutes: Array<RouteType> = [
      {
        path: config.routes.home,
        exact: true,
        render: () => <HomePage />,
        shouldShow: true,
      },
      {
        path: config.routes.documents,
        exact: true,
        render: () => <Redirect to={config.routes.invoices} />,
        shouldShow: hasViewDocumentsPermission,
      },
      {
        path: [
          config.routes.invoices,
          config.routes.contracts,
          config.routes.emergencyPlans,
          config.routes.riskAssessments,
          config.routes.conditionReports,
          config.routes.otherDocuments,
          config.routes.quotations,
          config.routes.assessmentReports,
        ],
        exact: true,
        render: () => <DocumentsPage />,
        shouldShow: hasViewDocumentsPermission,
      },
      {
        path: config.routes.documentsUpload,
        exact: true,
        render: () => <GeneralDocumentUploadPage />,
        shouldShow: true,
      },

      {
        path: config.routes.elevators,
        exact: true,
        render: () => <ElevatorsPage />,
        shouldShow: hasViewElevatorsPermission,
      },
      {
        path: [config.routes.incidents, config.routes.incidentsActive, config.routes.incidentsResolved],
        exact: true,
        render: () => <IncidentsPage />,
        shouldShow: true,
      },
      // {
      //     path: config.routes.incident,
      //     exact: true,
      //     render: () => <IncidentPage />,
      // },
      {
        path: [config.routes.support, config.routes.supportOpen, config.routes.supportClosed],
        exact: true,
        render: () => <SupportPage />,
        shouldShow: shouldViewSupportRequestPermissions,
      },
      {
        path: config.routes.elevatorDetail,
        exact: true,
        render: () => <ElevatorDetail />,
        shouldShow: hasViewElevatorsPermission,
      },
      {
        path: config.routes.elevatorHistory,
        exact: true,
        render: () => <ElevatorHistory />,
        shouldShow: hasViewElevatorsPermission,
      },
      {
        path: config.routes.login,
        exact: true,
        render: () => <Redirect to={config.routes.home} />,
        shouldShow: true,
      },
      {
        path: config.routes.registration,
        exact: true,
        render: () => <Redirect to={config.routes.home} />,
        shouldShow: true,
      },
      {
        path: config.routes.uploadContract,
        exact: true,
        render: () => <UploadContractPage />,
        shouldShow: shouldUploadContractCheckPermissions,
      },
      {
        path: config.routes.uploadQuotation,
        exact: true,
        render: () => <UploadQuotationPage />,
        shouldShow: shouldUploadQuotationCheckPermissions,
      },
      {
        path: config.routes.uploadZUES,
        exact: true,
        render: () => <UploadZUESPage />,
        shouldShow: shouldUploadPriceCheckDocumentsPermissions,
      },
      {
        path: config.routes.uploadZUESDeficiency,
        exact: true,
        render: () => <UploadZUESDeficiencyPage />,
        shouldShow: shouldUploadDeficiencyCheckPermissions,
      },
      {
        path: config.routes.operatorObligations,
        exact: true,
        render: () => <FakePage type={FakeType.OPERATOR_OBLIGATION} />,
        shouldShow: shouldViewOperatorObligationsPermissions,
      },
      {
        path: config.routes.maintenanceAnalysis,
        exact: true,
        render: () => <MaintenancePage />,
        shouldShow: shouldViewMaintenanceOptimizationPermissions,
      },
      {
        path: config.routes.workOrders,
        exact: true,
        render: () => {
          return <WorkOrdersPage />;
        },
        shouldShow: isElevoniqModifi || shouldViewWorkOrders,
      },
      {
        path: config.routes.teams,
        exact: true,
        render: () => <TeamsPage />,
        shouldShow: shouldViewTeamsPermissions,
      },
      {
        render: () => <NoMatchPage />,
        shouldShow: true,
      },
    ];

    const routes = authRoutes.concat(redirectRoutes);

    return routes.filter(({ shouldShow }) => shouldShow);
  }, [
    hasViewElevatorsPermission,
    hasViewDocumentsPermission,
    shouldUploadPriceCheckDocumentsPermissions,
    shouldUploadDeficiencyCheckPermissions,
    shouldUploadQuotationCheckPermissions,
    shouldUploadContractCheckPermissions,
    shouldViewMaintenanceOptimizationPermissions,
    shouldViewOperatorObligationsPermissions,
    shouldViewSupportRequestPermissions,
    shouldViewTeamsPermissions,
    shouldViewWorkOrders,
    isElevoniqModifi
  ]);

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route key={String(route.path ?? index)} {...route} />
      ))}
    </Switch>
  );
};

export default AuthRoutes;
