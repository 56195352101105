import React from 'react';
import { Typography } from 'antd';
import type { ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './FakeInfo.rules';

export interface FakeInfoProps {
    title: ReactNode;
    description: ReactNode;
    items: [ReactNode, ReactNode, ReactNode];
}

export const FakeInfo = ({ title, description, items }: FakeInfoProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <Typography.Title level={1} className={styles.title}>
                {title}
            </Typography.Title>
            <Typography.Paragraph className={styles.description}>{description}</Typography.Paragraph>
            <ul className={styles.list}>
                {items.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};
