import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import type { RcFile } from 'antd/lib/upload';

import { GeneralDocumentsUploadType, UploadService, useUploadDocument } from 'modules/entities/modules/documents';

import { GeneralUploadTypeInput } from '../GeneralUploadTypeInput';
import { GeneralUploadFileInput } from '../GeneralUploadFileInput';
import { GeneralUploadButtons } from '../GeneralUploadButtons';
import { useNotifications } from 'modules/ui';
import { useIntl } from 'react-intl';

const defaultFiles = [];

const GENERAL_UPLOAD_ID = 'general-upload';

export const GeneralUpload = () => {
    const notification = useNotifications();
    const intl = useIntl();

    const { api, uploadDocument, resetUpload } = useUploadDocument(GENERAL_UPLOAD_ID);

    const [files, setFiles] = useState<RcFile[]>(defaultFiles);
    const [type, setType] = useState<GeneralDocumentsUploadType>(null);

    useEffect(() => {
        if (api.lastSuccessAt) {
            notification.success({
                message: intl.formatMessage({
                    id: 'documents.generalUpload.success.title',
                }),
                description: intl.formatMessage({
                    id: 'documents.generalUpload.success.description',
                }),
            });
        }
    }, [api.lastSuccessAt, notification, intl]);

    useEffect(() => {
        if (api.error) {
            notification.error({
                message: intl.formatMessage({
                    id: 'documents.generalUpload.error.title',
                }),
                description: intl.formatMessage({
                    id: 'documents.generalUpload.error.description',
                }),
            });
        }
    }, [api.error, notification, intl]);

    useEffect(() => {
        if (api.lastSuccessAt && (type !== null || files !== defaultFiles)) {
            setType(null);
            setFiles(defaultFiles);
        }
    }, [api.lastSuccessAt, files, type]);

    useEffect(() => {
        if (api.lastSuccessAt || api.error) {
            resetUpload();
        }
    }, [api.lastSuccessAt, api.error, resetUpload]);

    return (
        <>
            <GeneralUploadTypeInput value={type} onChange={setType} disabled={api.inProgress} />
            <GeneralUploadFileInput value={files} onChange={setFiles} disabled={api.inProgress} />
            <GeneralUploadButtons
                loading={api.inProgress}
                disabled={isEmpty(files) || !type || api.inProgress}
                onSubmit={() => {
                    uploadDocument({
                        files: files.map(file => ({
                            fileUrl: URL.createObjectURL(file),
                            name: file.name,
                        })),
                        service: UploadService.GENERAL_DOCUMENTS,
                        type,
                    });
                }}
            />
        </>
    );
};
