import { basicApiReducer } from '@ackee/redux-utils';
import { fetchContractsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchContractsTypes.FETCH_CONTRACTS_REQUEST,
        SUCCESS: fetchContractsTypes.FETCH_CONTRACTS_SUCCESS,
        FAILURE: fetchContractsTypes.FETCH_CONTRACTS_FAILURE,
        CANCEL: fetchContractsTypes.FETCH_CONTRACTS_CANCEL,
        RESET: fetchContractsTypes.FETCH_CONTRACTS_RESET,
    },
});
