import React from 'react';

import { QuotationStatus, useQuotationsCountByStatus } from 'modules/entities/modules/documents-quotations';

import { DocumentsStatusFilter } from '../../DocumentsStatusFilter';

import { QuotationStatusIcon } from '../QuotationStatusIcon';

export const QuotationsStatusFilter = () => {
    const statusCount = useQuotationsCountByStatus();

    const statuses = React.useMemo(() => {
        return Object.values(QuotationStatus).map(status => ({
            icon: <QuotationStatusIcon status={status} small />,
            value: status,
            count: statusCount[status],
        }));
    }, [statusCount]);

    return <DocumentsStatusFilter<QuotationStatus> statuses={statuses} />;
};
