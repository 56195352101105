import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const button: TRule = () => ({
    marginRight: 24,
    padding: 0,

    ':hover': {
        background: 'none',
    },
    ':active': {
        background: 'none',
    },
    ':focus': {
        background: 'none',
    },

    '& > span:last-child': {
        display: 'none',
    },

    tablet: {
        marginRight: 32,

        '& > span:last-child': {
            display: 'inline',
        },
    },
});

export const avatar: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& .anticon-user': {
        color: theme.colors.iconActive,
        fontSize: 18,
    },
    tablet: {
        marginRight: '0.5em',
    },
});
