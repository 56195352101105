import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Table, useUrlPagination } from 'modules/table';

import * as felaRules from './IncidentsTable.rules';
import IncidentCard from '../IncidentCard/IncidentCard';
import { Incident } from 'modules/entities/modules/incidents';

export interface IncidentsTableProps {
    isClosed: boolean;
    loading: boolean;
    items: Incident[];
    unvalidatedIncidents: Incident[];
}

export const IncidentsTable = ({ isClosed, loading, items, unvalidatedIncidents }: IncidentsTableProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const pagination = useUrlPagination();

    return (
        <div className={styles.tableContainer}>
            <Table
                loading={loading}
                form={{ syncToUrl: values => values }}
                columns={[
                    {
                        title: <FormattedMessage id="documents.table.invoiceReason" />,
                        key: 'incidentsCards',
                        dataIndex: 'incidentsCards',
                        valueType: 'text',
                        sorter: null,
                        render: (_, record) => <IncidentCard incident={record} isClosed={isClosed} />,
                    },
                ]}
                rowKey="id"
                search={false}
                bordered={false}
                dataSource={[...unvalidatedIncidents, ...items]}
                toolBarRender={false}
                pagination={pagination}
                data-testid="incidents-table"
            />
        </div>
    );
};
