import type { TRuleWithTheme } from 'styles/theme';

export const location = () => ({
    display: 'flex',
    alignItems: 'center',

    '& .anticon': {
        marginRight: '0.5625rem',
    },
});

export const card: TRuleWithTheme = ({ theme }) => ({
    '& .ant-card-body': {
        paddingTop: '.75rem',
        paddingBottom: '.75rem',
    },
});

export const cardHeader = () => ({
    '& .ant-btn-link': {
        padding: 0,
    },

    tablet: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '1rem',
    },
});

export const heading = () => ({
    margin: 0,
    fontWeight: 600,
    marginLeft: '1rem',
    marginRight: '2.2rem',
});

export const infoWrapper = () => ({
    '&.ant-space': {
        flexWrap: 'wrap',

        marginBottom: '1rem',

        tablet: {
            marginBottom: 0,
        },
    },
    '&.ant-space > .ant-space-item': {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',

        tablet: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
});

export const buttonWrapper = () => ({
    '& .ant-space-item:last-of-type': {
        marginLeft: '8px',
    },
});

export const bodyWrapper = () => ({
    tablet: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
