import type { RcFile } from 'antd/lib/upload';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    padding: '2rem',

    background: [
        theme.colors.simplifa1,
        `linear-gradient(180deg, ${theme.colors.simplifa1} 0%, ${theme.colors.simplifa2} 71.5%)`,
    ],

    borderRadius: 2,
});

export const dragger: TRuleWithTheme<{
    file: RcFile;
    draggerDisabled: boolean;
}> = ({ theme, file, draggerDisabled }) => ({
    '&.ant-upload.ant-upload-drag': {
        marginBottom: '1.5rem',

        border: `2px dashed ${theme.colors.white}`,
        borderRadius: 4,

        background: 'transparent',

        extend: {
            condition: file,
            style: {
                border: 'none',
                backgroundColor: `rgba(255, 255, 255, 0.2)`,
            },
        },
    },

    '&.ant-upload.ant-upload-drag .ant-upload': {
        height: '4.5rem',

        padding: '1.5rem',
        paddingLeft: '1.5rem',

        cursor: draggerDisabled ? 'default' : undefined,
    },
});

export const attachment: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',
});

export const docIcon: TRuleWithTheme = ({ theme }) => ({
    marginRight: '0.625rem',
    verticalAlign: 'middle',

    color: theme.colors.white,
});

export const name: TRuleWithTheme = ({ theme }) => ({
    verticalAlign: 'middle',

    color: theme.colors.white,
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    marginRight: '0.5rem',
    verticalAlign: 'middle',

    color: theme.colors.white,
});

export const text: TRuleWithTheme = ({ theme }) => ({
    verticalAlign: 'middle',

    color: theme.colors.white,
});

export const textInner: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,

    textDecoration: 'underline',
});

export const trashButton: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,

    '&.ant-btn-icon-only': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
    },
});

export const trashIcon: TRuleWithTheme = ({ theme }) => ({
    marginRight: '-0.5rem',

    color: theme.colors.white,

    verticalAlign: 'middle',
});
