import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({});
export const priceContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
});
export const smallText: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
});
export const price: TRuleWithTheme = ({ theme }) => ({
    fontWeight: 'bolder',
    margin: '0 .5rem',
    marginLeft: '.5rem',
    marginRight: '.125rem',
});
