import type { TRule } from 'fela';

export const modal: TRule = () => ({
    '> .ant-modal-content': {
        overflow: 'auto',
    },
    '> .ant-modal-content > .ant-modal-footer': {
        display: 'none',
    },
});
