import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { StatusIcon, TableDataSpace, Tables } from 'modules/table';
import { Icon, IconType } from 'modules/ui';

import type { Contract } from 'modules/entities/modules/documents-contracts';

import { DocumentsTable } from '../../DocumentsTable';

import { ContractInfo } from '../ContractInfo';
import { ContractItemInfo } from '../ContractItemInfo';
import { ContractElevators } from '../ContractElevators';
import { ContractValidity } from '../ContractValidity';

import * as felaRules from './ContractsTable.rules';

export interface ContractsTableProps {
    loading: boolean;
    documents: Contract[];
}

export const ContractsTable = ({ loading, documents }: ContractsTableProps) => {
    const { rules } = useFelaEnhanced(felaRules);

    return (
        <DocumentsTable<Contract>
            columns={[
                {
                    title: '',
                    key: 'status',
                    search: false,
                    sorter: null,
                    render: (_, record) => <StatusIcon table={Tables.CONTRACTS} state={record.status} />,
                },
                { title: '', key: 'name', render: (_, record) => <ContractInfo contract={record} /> },
                {
                    title: '',
                    key: 'frameworkAgreement',
                    search: false,
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.contracts.table.frameworkAgreement" />}
                            value={record.frameworkAgreement ? <Icon type={IconType.CHECKMARK} /> : null}
                        />
                    ),
                },
                {
                    title: '',
                    key: 'serviceProvider.name',
                    search: false,
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.contracts.table.serviceProvider.name" />}
                            value={record.serviceProvider.name}
                        />
                    ),
                },

                {
                    title: <FormattedMessage id="documents.contracts.table.validity" />,
                    key: 'validUntil',
                    sorter: null,
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.contracts.table.validity" />}
                            value={<ContractValidity contract={record} />}
                        />
                    ),
                },
                {
                    title: <FormattedMessage id="documents.contracts.table.cancelUntil" />,
                    key: 'cancelUntil',
                    valueType: 'date',
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.contracts.table.cancelUntil" />}
                            value={<FormattedDate value={record.cancelUntil} />}
                        />
                    ),
                },
                {
                    title: <FormattedMessage id="documents.contracts.table.elevators" />,
                    key: 'elevators',
                    dataIndex: 'elevators',
                    valueType: 'text',
                    render: (_, record) => (
                        <TableDataSpace
                            title={<FormattedMessage id="documents.contracts.table.elevators" />}
                            value={<ContractElevators contract={record} />}
                        />
                    ),
                },
                {
                    title: '',
                    key: 'netPrice',
                    dataIndex: 'netPrice',
                    valueType: 'text',
                    sorter: null,
                    render: (_, record) => <ContractItemInfo contract={record} />,
                },
            ]}
            loading={loading}
            dataSource={documents}
            data-testid="contract-table"
            extend={{
                table: rules.table,
            }}
        />
    );
};
