import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { generatePath } from 'react-router';

import * as Log from 'config/loglevel';
import config from 'config';
import { actions as formActions, takeFormSubmission } from 'modules/form';
import { createUIErrorMessage } from 'modules/errors';

import type { ForgottenPasswordEmailValues } from '../../types';
import { forms } from '../../config';
import { ForgotPasswordSteps } from '../../types';
import { FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY } from '../../constants';

function* handleForgottenPasswordEmailForm(action: formActions.types.SubmitFormAction<ForgottenPasswordEmailValues>) {
    const { email } = action.payload;

    try {
        yield Auth.forgotPassword(email);

        yield put(formActions.submitFormSuccess(forms.forgottenPasswordEmail));
        localStorage.setItem(FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY, email);
        yield put(push(generatePath(config.routes.forgotPassword, { step: ForgotPasswordSteps.PASSWORD })));
    } catch (error) {
        Log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(formActions.submitFormFailure(forms.forgottenPasswordEmail, uiError));
    }
}

export default function* forgottenPasswordEmail() {
    yield takeFormSubmission(forms.forgottenPasswordEmail, handleForgottenPasswordEmailForm);
}
