import type { TRule } from 'fela';

import { Theme, TRuleWithTheme } from 'styles/theme';

export const layout: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& .ant-pro-sider-menu-logo': {
        paddingTop: '2rem',
        display:'flex',
        justifyContent:'center',

        '& > a': {
            height: theme.height.logo,
        },
        '& > has(> div)': {
            background: 'red',
        },
        '& h1':{
            display:'none'
        },
    },

    '& .ant-layout-sider-collapsed .ant-pro-sider-menu-logo': {
        padding: '0.75rem',
    },

    '& .ant-menu-submenu-title > span': {
        display: 'inline-flex',
        alignItems: 'center',
    },

    '& .ant-menu-submenu-selected': {
        color: theme.colors.text,
    },

    '& .ant-menu-sub.ant-menu-inline': {
        background: 'none',
    },

    '& .ant-pro-global-header-trigger': {
        tablet: {
            display: 'none',
        },
    },

    '& .ant-pro-basicLayout-has-header': {
        marginTop: '2.5rem',
    },
});

export const layoutWhite: TRule<{ theme: Theme }> = ({ theme }) => ({
    '& section': {
        background: 'white',
    },
    '& .ant-pro-global-header-trigger': {
        tablet: {
            display: 'none',
        },
    },
});

export const menu: TRuleWithTheme = () => ({
    '&.ant-menu-inline-collapsed .ant-menu-item .anticon': {
        fontSize: 'unset',
        lineHeight: 'unset',
    },

    '&.ant-menu-inline-collapsed .ant-menu-item .anticon + span': {
        display: 'none',
    },

    '& .ant-menu-item-selected a': {
        color: 'initial',
    },

    '& .ant-menu-item': {
        boxSizing: 'border-box',

        height: 'auto',
        minHeight: '48px',

        paddingTop: '0.75rem',
        paddingLeft: '1rem',
        paddingBottom: '0.75rem',
        paddingRight: '1.25rem',

        fontSize: '1rem',
        lineHeight: 1.5,

        whiteSpace: 'break-spaces',
    },

    '& .ant-menu-submenu-arrow': {
        display: 'none',
    },
});

export const feedbackButton: TRule<{ theme: Theme }> = ({ theme }) => ({
    marginRight: '2rem',
});
export const rightContent: TRule<{ theme: Theme }> = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
});
