import { actionChannel, call, put, select, take } from 'redux-saga/effects';
import { Status } from '@googlemaps/react-wrapper';

import { error } from 'config/loglevel';

import { geocode } from 'modules/elevators-map/utils';
import { mapInitializationStatus } from 'modules/map/services/slices';
import { mapSelector } from 'modules/map/services/selectors';

import { PropertyUnitCoordinates } from '../types';
import { coordinatesSelector } from '../selectors';
import { finishGeocoding, geocodeCoordinates, setCoordinates, setProgress } from '../slices';

function* geocodeCoordinatesHandler(action: ReturnType<typeof geocodeCoordinates>) {
    const presentCoordinates = yield select(coordinatesSelector);

    const addresses = action.payload.filter(address => !presentCoordinates.hasOwnProperty(address));

    console.log(addresses)
    const { initializationStatus } = yield select(mapSelector);

    if (initializationStatus === Status.LOADING) {
        yield take(action => action.type === mapInitializationStatus.toString() && action.payload === Status.SUCCESS);
    }

    const geocoder = new google.maps.Geocoder();

    let count = 0;

    for (const address of addresses) {
        const result = yield call(geocode, geocoder, address);
        const coordinates: PropertyUnitCoordinates = {
            [address]: result,
        };
        yield put(setCoordinates(coordinates));
        yield put(setProgress({ done: count++, all: addresses.length }));
    }

    yield put(finishGeocoding());
}

export function* coordinatesSaga() {
    const channel = yield actionChannel(geocodeCoordinates.toString());

    try {
        while (true) {
            const action = yield take(channel);

            yield geocodeCoordinatesHandler(action);
        }
    } catch (e) {
        error(e);
    }
}
