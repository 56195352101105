import { createAction } from '@reduxjs/toolkit';

export function createFetchSuccessAction<PayloadType, ActionType extends string = string>(actionType: ActionType) {
    return createAction(
        actionType,
        (
            payload: PayloadType[],
            {
                lastSuccessAt = new Date().toISOString(),
            }: {
                lastSuccessAt?: string;
            } = {},
        ) => ({
            payload,
            meta: {
                lastSuccessAt,
            },
        }),
    );
}

export function createFetchFailureAction<ErrorType, ActionType extends string = string>(actionType: ActionType) {
    return createAction(actionType, (error: ErrorType) => ({
        payload: null,
        error,
    }));
}
