import React from 'react';

const CheckmarkBold = props => (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="currentColor"  xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.0034 0.53828L5.25179 6.57832L2.9966 4.19891C2.6674 3.85426 2.22092 3.66063 1.75537 3.66063C1.28981 3.66063 0.843329 3.85426 0.514135 4.19891C0.18494 4.54357 0 5.01102 0 5.49844C0 5.98585 0.18494 6.45331 0.514135 6.79796L4.01056 10.4586C4.17308 10.6301 4.36643 10.7663 4.57947 10.8592C4.7925 10.9522 5.021 11 5.25179 11C5.48257 11 5.71107 10.9522 5.92411 10.8592C6.13715 10.7663 6.3305 10.6301 6.49302 10.4586L13.4859 3.13733C13.8151 2.79267 14 2.32522 14 1.8378C14 1.35039 13.8151 0.882935 13.4859 0.53828C13.1567 0.193625 12.7102 0 12.2446 0C11.7791 0 11.3326 0.193625 11.0034 0.53828Z"
            // fill="#878787"
        />
    </svg>
);

export default CheckmarkBold;
