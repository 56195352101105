import React from 'react';

const Envelope = () => (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.66671 9.99984V2.6965L6.59071 6.52584C6.71071 6.61984 6.85537 6.6665 7.00004 6.6665C7.14471 6.6665 7.28937 6.61984 7.40937 6.52584L12.3334 2.6965V9.99984H1.66671ZM11.0574 1.99984L7.00004 5.15517L2.94271 1.99984H11.0574ZM13 0.666504H1.00004C0.631374 0.666504 0.333374 0.964504 0.333374 1.33317V10.6665C0.333374 11.0352 0.631374 11.3332 1.00004 11.3332H13C13.3687 11.3332 13.6667 11.0352 13.6667 10.6665V1.33317C13.6667 0.964504 13.3687 0.666504 13 0.666504V0.666504Z"
            fill="#A6A6A6"
        />
    </svg>
);

export default Envelope;
