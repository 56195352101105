import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';

import { useUIMessage } from 'modules/ui';
import { useFormSubmission, displayFieldSpecificError } from 'modules/form';
import { usePreviousLocation } from 'hooks';

import { fields, forms } from '../../config';
import { ConfirmPasswordFormValues } from '../../types';
import AuthForm, { SubmitAuthFormButton } from '../AuthForm';
import { isFormSubmissionError } from '../../utils';
import { FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY } from '../../constants';

type ResetPasswordFormProp = {};

const ForgottenPasswordForm: FC<ResetPasswordFormProp> = () => {
    const { formatMessage } = useIntl();
    const { handleSubmit, error, submitting, succeeded } = useFormSubmission<ConfirmPasswordFormValues>(
        forms.forgottenPassword,
    );
    const message = useUIMessage();
    const history = useHistory();
    const previousLocation = usePreviousLocation();
    const storageEmail = React.useMemo(() => localStorage.getItem(FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY), []);

    useEffect(() => {
        if (succeeded) {
            message('success', 'auth.forgotPassword.success');
        }
    }, [succeeded, message]);

    useEffect(() => {
        if (!storageEmail) {
            history.push(previousLocation);
        }

        const removeLocalStorageEmail = () => localStorage.removeItem(FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY);

        window.addEventListener('beforeunload', removeLocalStorageEmail);
        return () => {
            window.removeEventListener('beforeunload', removeLocalStorageEmail);
        };
    }, [storageEmail, history, previousLocation]);

    return (
        <AuthForm
            name={forms.forgottenPassword}
            layout="vertical"
            title={formatMessage({ id: 'auth.forgotPasswordTitle' })}
            onFinish={handleSubmit}
        >
            <Form.Item
                name={fields.forgottenPassword.email}
                validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                help={isFormSubmissionError(error) ? formatMessage({ id: error }) : undefined}
                hidden
                initialValue={storageEmail}
            >
                <Input type="text" size="large" placeholder={formatMessage({ id: 'auth.code' })} />
            </Form.Item>
            <Form.Item
                name={fields.forgottenPassword.code}
                label={formatMessage({ id: 'auth.code' })}
                validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                help={
                    isFormSubmissionError(error)
                        ? displayFieldSpecificError(error, fields.forgottenPassword.code, formatMessage)
                        : undefined
                }
            >
                <Input type="text" size="large" placeholder={formatMessage({ id: 'auth.code' })} />
            </Form.Item>
            <Form.Item
                name={fields.forgottenPassword.newPassword}
                label={formatMessage({ id: 'auth.newPassword' })}
                rules={[
                    { required: true, message: formatMessage({ id: 'form.validation.required.newPassword' }) },
                    {
                        pattern: new RegExp(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/),
                        message: formatMessage({ id: 'form.validation.password.specialCharacters' }),
                    },
                    {
                        pattern: /[0-9]/,
                        message: formatMessage({ id: 'form.validation.password.numericValue' }),
                    },
                    { min: 8, message: formatMessage({ id: 'form.validation.password.minLength' }) },
                    {
                        pattern: /[A-Z]/,
                        message: formatMessage({ id: 'form.validation.password.uppercaseLetter' }),
                    },
                    {
                        pattern: /[a-z]/,
                        message: formatMessage({ id: 'form.validation.password.lowercaseLetter' }),
                    },
                ]}
                validateStatus={isFormSubmissionError(error) ? 'error' : undefined}
                help={
                    isFormSubmissionError(error)
                        ? displayFieldSpecificError(error, fields.forgottenPassword.newPassword, formatMessage)
                        : undefined
                }
            >
                <Input.Password
                    type="password"
                    size="large"
                    autoComplete="new-password"
                    placeholder={formatMessage({ id: 'auth.newPassword' })}
                />
            </Form.Item>
            <Form.Item noStyle>
                <SubmitAuthFormButton
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={submitting}
                    loading={submitting}
                >
                    {formatMessage({ id: 'general.submit' })}
                </SubmitAuthFormButton>
            </Form.Item>
        </AuthForm>
    );
};

export default ForgottenPasswordForm;
