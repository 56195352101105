import { basicApiReducer } from '@ackee/redux-utils';
import { fetchUsersRolesAndPermissionsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_REQUEST,
        SUCCESS: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_SUCCESS,
        FAILURE: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_FAILURE,
        CANCEL: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_CANCEL,
        RESET: fetchUsersRolesAndPermissionsTypes.FETCH_USERS_ROLES_PERMISSIONS_RESET,
    },
});
