import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

import { ElevatorDetail } from 'modules/entities/modules/elevators';
import { DialogState } from 'constants/common';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

interface NewIncidentButtonProps {
    elevator?: ElevatorDetail
}
export const NewIncidentButton = ({elevator = null}: NewIncidentButtonProps) => {
    const { navigate } = useContext(GlobalDialogContext);

    return (
        <Button
            type="primary"
            size="large"
            onClick={() => {
                navigate(DialogState.CREATE_INCIDENT, elevator);
            }}
        >
            <FormattedMessage id="incident.new.trigger" />
        </Button>
    );
};
