import { useSelector } from 'react-redux';

import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { useDisplayError } from 'modules/errors';
import { useFetchContracts, selectors, DEFAULT_SORT } from 'modules/entities/modules/documents-contracts';

const useContractsListTable = () => {
    const api = useFetchContracts();
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams();
    const contracts = useSelector(state => selectors.selectFilteredContracts(state, sorting ?? DEFAULT_SORT, search));

    useDisplayError(api.error);

    return { api, contracts, defaultValue };
};

export default useContractsListTable;
