import { MODULE_PREFIX } from '../../constants';
import type { FormSubmissionError } from '../../types';

export const REGISTER_FORM = `${MODULE_PREFIX}/REGISTER_FORM`;
export const UNREGISTER_FORM = `${MODULE_PREFIX}/UNREGISTER_FORM`;

export const SUBMIT_FORM = `${MODULE_PREFIX}/SUBMIT_FORM`;
export const SUBMIT_FORM_SUCCESS = `${MODULE_PREFIX}/SUBMIT_FORM_SUCCESS`;
export const SUBMIT_FORM_FAILURE = `${MODULE_PREFIX}/SUBMIT_FORM_FAILURE`;

interface FormAction<FormActionPayload = undefined> {
    type: string;
    meta: {
        form: string;
        meta?: any;
    };
    payload?: FormActionPayload;
}

export type RegisterFormAction = FormAction;
export type UnregisterFormAction = FormAction;

export type SubmitFormAction<FormValues = {}> = FormAction<FormValues>;
export type SubmitFormSuccesAction = FormAction;
export type SubmitFormFailureAction = FormAction<FormSubmissionError>;

export type FormActionTypes =
    | RegisterFormAction
    | UnregisterFormAction
    | SubmitFormFailureAction
    | SubmitFormSuccesAction
    | SubmitFormFailureAction;
