import type { TRule } from 'fela';

export const card: TRule = () => ({
    '& .ant-card-body': {
        paddingBottom: '1rem',
    },
});

export const blur: TRule = () => ({
    filter: 'blur(1px)',
});
