import { Form, FormProps, Image, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import notificationSvg from 'assets/images/notification.svg';
import * as felaRules from './Notifications.styles';
import { useFelaEnhanced } from 'hooks';
import Notification from '../Notification/Notification';
import TeamDialogFooter from '../TeamDialogFooter';
import { useIntl } from 'react-intl';
import { useEditNotification } from 'modules/entities/modules/teams/hooks';
import { useSetUser } from 'modules/entities/modules/teams/hooks/useSetUser';
import { useNotifications } from 'modules/ui';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';

const Notifications = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const { api, handleSubmit } = useEditNotification();
    const { api: user } = useSetUser();
    const [form] = Form.useForm();
    const { setState } = useTeamDialog();
    const notifications = useNotifications();
    const existingNotificationsArray = user.notifications ? user.notifications.split(";") : [];
    const existingNotificationsObject = existingNotificationsArray.reduce(
        (arr, curr) => ({
            ...arr,
            [curr]: true
        }), {})
    const notificationsArray = [
        'Entrapment',
        'Breakdown',
        'Issues',
        'Technician Onsite',
        'Alarm Device Offline',
        'Invoices',
        'New Assessment Report with Anomalies',
        'New operational Inspection with anomalies',
        'Maintenance Report'
    ]

    useEffect(() => {
        let main = 'invite.edit.notifications.request.success.title';
        let description = 'invite.edit.notifications.request.success.description';

        if (api.success) {
            notifications.success({
                message: intl.formatMessage({
                    id: main,
                }),
                description: intl.formatMessage({
                    id: description,
                }),
            });

            setState(null);
        }

        if (api.error) {
            notifications.error({
                message: intl.formatMessage({
                    id: api.error,
                }),
            });
        }
    }, [api, intl, notifications, setState, form]);

    const elevatorStatesNotifications = useMemo(
        () => {
            const newNotifications = notificationsArray.map(notification => 
                ({
                    title: notification,
                    id: notification,
                    defaultChecked: existingNotificationsArray.includes(notification),
                })
            )
            return newNotifications;
        },
        [notificationsArray, existingNotificationsArray],
    );

    const onFinish: FormProps<any>['onFinish'] = values => {
        const newNotifications = Object.keys(values)?.filter(key => values[key]) || []
        handleSubmit({ notifications: newNotifications, userId: user.userId, email: user.email });
    };

    return (
        <div className={styles.notifications}>
            <div className={styles.notificationsHeading}>
                <Image src={notificationSvg} alt="user" preview={false} />
                <Typography.Title level={4} className={styles.title}>
                    {intl.formatMessage(
                        { id: 'teams.notifications' },  
                        { name: user.name })
                    }
                </Typography.Title>
            </div>
            <Typography.Text className={styles.text}>
                {intl.formatMessage({ id: 'teams.notifications.selection' })}
            </Typography.Text>
                <Form onFinish={onFinish} initialValues={existingNotificationsObject}>
                     <div className={styles.notificationOptions}>
                        {elevatorStatesNotifications.map(notification => (
                            <Notification {...notification} key={notification.id} />
                        ))}
                    </div>
                    <TeamDialogFooter />
                </Form>
        </div>
    );
};

export default Notifications;
