import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const text: TRuleWithTheme = () => ({
    marginRight: '.75rem',
});
