import * as Petrus from '@ackee/petrus';
import { put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { routingSelector } from '@ackee/chris';

import { actions as formActions, takeFormSubmission } from 'modules/form';
import { createUIErrorMessage } from 'modules/errors';
import config from 'config';
import * as Log from 'config/loglevel';

import { LoginFormValues } from '../../types';
import { forms } from '../../config';

function* redirectBack() {
    const location = yield select(routingSelector);
    const returnLocation = location.state?.from
        ? {
              pathname: location.state.from.pathname,
              search: location.state.from.search,
          }
        : {
              pathname: config.routes.home,
          };

    yield put(push(returnLocation));
}

function* handleLoginForm(action: formActions.types.SubmitFormAction<LoginFormValues>) {
    yield put(Petrus.loginRequest(action.payload));

    const result = yield take([Petrus.LOGIN_SUCCESS, Petrus.LOGIN_FAILURE]);

    if (result.type === Petrus.LOGIN_SUCCESS) {
        yield put(formActions.submitFormSuccess(forms.login));
        yield redirectBack();
    } else {
        Log.error(result.error);

        const uiError = createUIErrorMessage(result.error);
        yield put(formActions.submitFormFailure(forms.login, uiError));
    }
}

export default function* loginForm() {
    yield takeFormSubmission(forms.login, handleLoginForm);
}
