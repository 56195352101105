import { ElevatorTotalTripsAggregation } from '../../../entities/modules/elevators';
import { TotalTrips } from '../../../entities/modules/trips';
import { FormatDateOptions } from '@formatjs/intl/src/types';

const generateTotalTripsTooltip = (
    trip: TotalTrips,
    aggregationBy: ElevatorTotalTripsAggregation,
    formatDate: (value: Parameters<Intl.DateTimeFormat['format']>[0] | string, opts?: FormatDateOptions) => string,
) => {
    switch (aggregationBy) {
        case ElevatorTotalTripsAggregation.WEEK:
            const endOfWeek = new Date(trip.date).setDate(new Date(trip.date).getDate() + 6);
            return `${formatDate(trip.date, { day: 'numeric', month: 'numeric' })}—${formatDate(endOfWeek)}`;
        case ElevatorTotalTripsAggregation.YEAR:
            return undefined;
        case ElevatorTotalTripsAggregation.MONTH:
            return formatDate(trip.date, { month: 'long', year: 'numeric' });
        default:
            return formatDate(trip.date);
    }
};

export default generateTotalTripsTooltip;
