const measureTicsText = (data: Array<Record<string, string>>, xAxisDataKey: string): number => {
    let highestWidth = 0;

    data.forEach(dataItem => {
        const ctx = document.createElement('canvas').getContext('2d')!;
        ctx.font = '14px "WorkSans"';
        const newWidth = ctx.measureText(dataItem[xAxisDataKey]).width;
        if (newWidth > highestWidth) highestWidth = newWidth;
    });

    return highestWidth;
};

export default measureTicsText;
