import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './ContactDataCard.styles';
import { Card } from 'antd';
import { Contact } from 'modules/entities/modules/elevators';
import { FormattedMessage } from 'react-intl';
import { Icon, IconType } from 'modules/ui';

type ContactDataCardProps = {
    contact: Contact;
    factoryNumber: number;
};

const ContactDataCard: FC<ContactDataCardProps> = ({ contact, factoryNumber }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <Card className={styles.container}>
            <span className={styles.roleName}>
                <FormattedMessage id={`elevator.contacts.${contact.type}`} />
            </span>
            <p className={styles.name}>{(contact.firstName ? contact.firstName + ' ' : '') + contact.lastName}</p>
            {contact.phone && (
                <div className={styles.contactItem}>
                    <Icon type={IconType.PHONE_BOLD} className={styles.linkIcon} />
                    <a href={`tel:${contact.phone}`} className={styles.link}>
                        {contact.phone}
                    </a>
                </div>
            )}

            {contact.email && (
                <div className={styles.contactItem}>
                    <Icon type={IconType.ENVELOPE} className={styles.linkIcon} />
                    <a href={`mailto:${contact.email}?subject=${factoryNumber}`} className={styles.link}>
                        {contact.email}
                    </a>
                </div>
            )}
        </Card>
    );
};

export default ContactDataCard;
