import { put, takeEvery } from 'redux-saga/effects';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { updateIncident, updateIncidentRequest, updateIncidentSuccess, updateIncidentFailure } from '../actions';

function* updateIncidentHandler(action: ReturnType<typeof updateIncident>) {
    yield put(updateIncidentRequest(action.meta.caseNumber));

    try {
        yield* authApi.post(config.api.incidents, action.payload);

        yield put(updateIncidentSuccess(action.meta.caseNumber));
    } catch (error) {
        log.error(error);

        yield put(updateIncidentFailure(error, action.meta.caseNumber));
    }
}

export function* updateIncidentSaga() {
    yield takeEvery(updateIncident.toString(), updateIncidentHandler);
}
