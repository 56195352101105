import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import type { ProColumns } from '@ant-design/pro-table';

import { Tables, StatusIcon, TableDataSpace } from 'modules/table';
import type { OtherDocument } from 'modules/entities/modules/other-documents';

import OtherDocItemInfo from '../OtherDocumentItemInfo';
import { DocumentElevator } from '../../DocumentElevator';

const defaultColumns: ProColumns<OtherDocument>[] = [
    {
        title: '',
        key: 'status',
        search: false,
        render: (_, record) => <StatusIcon table={Tables.OTHER_DOCUMENTS} state={record.status} />,
    },
    {
        title: '',
        key: 'items.elevator.elevatorId',
        dataIndex: 'items',
        search: false,
        render: (_, record) =>
            record.elevators
                .filter(elevator => elevator.elevatorId && elevator.factoryNumber)
                .map(elevator => <DocumentElevator key={elevator.elevatorId} elevator={elevator} />),
    },
    {
        title: '',
        key: 'documentType',
        search: false,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.documentType" />}
                value={record.documentType}
            />
        ),
    },

    {
        title: '',
        key: 'documentDate',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.documentDate" />}
                value={<FormattedDate value={record.documentDate} />}
            />
        ),
    },
    {
        title: '',
        key: 'sendBy',
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.sendBy" />}
                value={record.sendBy}
            />
        ),
    },
    {
        title: '',
        key: 'netPrice',
        dataIndex: 'netPrice',
        valueType: 'text',
        render: (_, record) => <OtherDocItemInfo document={record} />,
    },
];

export const elevatorDetailColumns: ProColumns<OtherDocument>[] = [
    {
        title: '',
        key: 'status',
        search: false,
        render: (_, record) => <StatusIcon table={Tables.OTHER_DOCUMENTS} state={record.status} />,
    },
    {
        title: '',
        key: 'documentType',
        search: false,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.documentType" />}
                value={record.documentType}
            />
        ),
    },

    {
        title: '',
        key: 'documentDate',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.documentDate" />}
                value={<FormattedDate value={record.documentDate} />}
            />
        ),
    },
    {
        title: '',
        key: 'sendBy',
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.otherDocuments.table.sendBy" />}
                value={record.sendBy}
            />
        ),
    },
    {
        title: '',
        key: 'netPrice',
        dataIndex: 'netPrice',
        valueType: 'text',
        render: (_, record) => <OtherDocItemInfo document={record} />,
    },
];

export default defaultColumns;
