import React from 'react';

const Offline = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="ic/24/offline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M18.0011,17.0002 L8.4181,17.0002 L15.9561,9.4602 C16.5281,10.1992 16.9031,11.1002 16.9831,12.0842 C17.0261,12.6062 17.4641,13.0022 17.9801,13.0022 C17.9891,13.0022 18.0001,13.0012 18.0101,13.0012 C19.1071,13.0062 20.0001,13.9002 20.0001,15.0002 C20.0001,16.1032 19.1041,17.0002 18.0011,17.0002 M4.0061,15.0002 C4.0061,13.8972 4.9021,13.0002 6.0051,13.0002 C6.4811,13.0002 6.8611,12.6602 6.9621,12.2132 C6.9871,12.1392 7.0221,12.0692 7.0311,11.9872 C7.2891,9.4302 9.4281,7.5002 12.0031,7.5002 C12.8821,7.5002 13.7061,7.7482 14.4321,8.1572 L5.6291,16.9622 C4.7061,16.7852 4.0061,15.9742 4.0061,15.0002 M18.8541,11.0962 C18.6171,9.9502 18.0981,8.9092 17.3741,8.0422 L21.9801,3.4352 L20.5871,2.0002 L15.8891,6.7002 C14.7691,5.9452 13.4311,5.5002 12.0031,5.5002 C8.6331,5.5002 5.7981,7.8622 5.1471,11.0982 C3.3541,11.4932 2.0061,13.0892 2.0061,15.0002 C2.0061,16.5122 2.8571,17.8132 4.0981,18.4932 L2.0001,20.5922 L3.4191,22.0002 L6.4191,19.0002 L18.0011,19.0002 C20.2061,19.0002 22.0001,17.2062 22.0001,15.0002 C22.0001,13.0872 20.6491,11.4882 18.8541,11.0962" id="Fill-1" fill="currentColor"></path>
        </g>
    </svg>
);

export default Offline;
