import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { Elevator, ElevatorState } from 'modules/entities/modules/elevators';

const TODAY_OFFSET = 0;
const YESTERDAY_OFFSET = -1;

const USEABLE_OFFSETS = [TODAY_OFFSET, YESTERDAY_OFFSET];

type GroupedElevatorStates = Record<
    string,
    {
        formattedDate: string;
        state: ElevatorState;
    }[]
>;

export const useGroupedElevatorStates = (states: Elevator['state'][]) => {
    const intl = useIntl();

    return useMemo(() => {
        const today = new Date();
        const yesteday = new Date(new Date().setDate(today.getDate() - 1));

        const relativeOffsets = {
            [intl.formatDate(today, {
                timeZone: 'Europe/Berlin',
            })]: TODAY_OFFSET,
            [intl.formatDate(yesteday, {
                timeZone: 'Europe/Berlin',
            })]: YESTERDAY_OFFSET,
        };

        return states.reduce<GroupedElevatorStates>((grouped, state) => {
            const dateString = intl.formatDate(state.createdAt, {
                timeZone: 'Europe/Berlin',
            });

            const day = USEABLE_OFFSETS.includes(relativeOffsets[dateString])
                ? intl.formatRelativeTime(relativeOffsets[dateString], 'days', {
                      numeric: 'auto',
                  })
                : dateString;

            if (!grouped[day]) {
                grouped[day] = [];
            }

            grouped[day].push({
                formattedDate: intl.formatTime(state.createdAt, {
                    timeZone: 'Europe/Berlin',
                }),
                state: state.state,
            });

            return grouped;
        }, {});
    }, [states, intl]);
};
