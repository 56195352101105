import { useSelector } from 'react-redux';
import { useFetchUsers } from './useFetchUsers';
import { selectSortedFetchUsers } from '../services/selector';
import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { DEFAULT_SORT } from '../constants';

export const useUsers = () => {
    const api = useFetchUsers();
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const users = useSelector(state =>
        selectSortedFetchUsers(state, {
            sorting,
            search
        }),
    );

    return {
        loading: api.inProgress,
        users,
        defaultSearch: defaultValue
    };
};
