import React, { FC, useEffect, useRef } from 'react';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import * as felaRules from './CompleteIncident.rules';

import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, IconType } from 'modules/ui';
import { Select, FloatingLabel, DatePicker } from 'modules/form';
import { MarkIncidentCompleteOptions, useIncidentPicklist } from 'modules/entities/modules/incidents';
import { COMPLETION_ACTION } from 'modules/incidents/constants';

export interface Props {
    handleSubmit: any;
    meta: { status: string; elevatorId: string; caseNumber: string; severity: string };
    setHideMessage: any;
    navigate: any;
    setComponents: any;
    selectedComponentGroup: string;
    selectedDate: any;
    setResolvedOn: any;
    selectedTime: any;
    setSubStatus: any;
    setShowAlert: any;
    subStatus: any;
    setSelectedComponentGroup: any;
    setSelectedComponent: any;
    selectedComponent: any;
    components: any;
    setSelectedTime: any;
    resolvedOn: any;
    showAlert: any;
    setSelectedDate: any;
}

export const CompleteIncident: FC<Props> = ({
    handleSubmit,
    meta,
    setHideMessage,
    navigate,
    setComponents,
    selectedComponentGroup,
    selectedDate,
    selectedTime,
    setResolvedOn,
    setSubStatus,
    setShowAlert,
    components,
    subStatus,
    setSelectedComponentGroup,
    setSelectedComponent,
    selectedComponent,
    setSelectedTime,
    resolvedOn,
    showAlert,
    setSelectedDate,
}) => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const { picklist } = useIncidentPicklist();
    const timePickerRef = useRef<any>();

    useEffect(() => {
        if (selectedComponentGroup && picklist?.componentDependentPicklist) {
            setComponents(picklist?.componentDependentPicklist[selectedComponentGroup]);
        }
    }, [selectedComponentGroup, picklist, setComponents]);

    useEffect(() => {
        if (selectedDate && selectedTime) {
            const combinedDateTime = selectedDate
                .set({
                    hour: selectedTime.hour(),
                    minute: selectedTime.minute(),
                    second: selectedTime.second(),
                })
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            setResolvedOn(combinedDateTime);
        }
    }, [selectedDate, selectedTime, setResolvedOn]);

    const categories = Object.keys(MarkIncidentCompleteOptions).map(i => ({
        value: MarkIncidentCompleteOptions[i],
        label: intl.formatMessage({ id: `page.incidents.table.completion.dialog.select.option.${i}` }),
    }));

    const onSelect = (v: string) => {
        setSubStatus(v);
        if (v === MarkIncidentCompleteOptions.ErfolgreicheEntstörung && meta.status === 'Reparatur') {
            setShowAlert(true);
        } else if (v === MarkIncidentCompleteOptions.ErfolgreicheReparatur && meta.status === 'Entstörung') {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    };

    return (
        <div data-testid="new-incident-dialog-selection">
            <div className={styles.header}>
                <Typography.Title level={2} className={styles.title}>
                    <FormattedMessage id="page.incidents.table.completion.dialog.title" />
                </Typography.Title>
            </div>
            <div className={styles.content}>
                <Typography.Paragraph className={styles.description}>
                    <FormattedMessage id="page.incidents.table.completion.dialog.message" />
                </Typography.Paragraph>

                <div>
                    <FloatingLabel
                        htmlFor="type"
                        floating={Boolean(subStatus)}
                        label={intl.formatMessage({
                            id: Boolean(subStatus)
                                ? 'page.incidents.table.completion.dialog.selected.title'
                                : 'page.incidents.table.completion.dialog.select.title',
                        })}
                    >
                        {({ onFocus, onBlur }) => (
                            <Select
                                id="type"
                                value={subStatus}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onChange={onSelect}
                                options={categories}
                                // disabled={disabled}
                                placeholder={intl.formatMessage({
                                    id: 'page.incidents.table.completion.dialog.select.title',
                                })}
                                fullWidth
                                floatingLabel
                            />
                        )}
                    </FloatingLabel>
                </div>
                <div className={styles.rowSpace}>
                    <div className={styles.componentRow}>
                        <FloatingLabel
                            htmlFor="type"
                            floating={Boolean(selectedComponentGroup)}
                            label={intl.formatMessage({
                                id: 'page.incidents.table.completion.dialog.select.componentGroup',
                            })}
                        >
                            {({ onFocus, onBlur }) => (
                                <Select
                                    id="type"
                                    value={selectedComponentGroup}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={v => {
                                        setSelectedComponentGroup(v);
                                        setSelectedComponent(null);
                                    }}
                                    options={
                                        picklist?.componentDependentPicklist
                                            ? Object.keys(picklist?.componentDependentPicklist).map(i => ({
                                                  value: i,
                                                  label: i,
                                              }))
                                            : []
                                    }
                                    // disabled={disabled}
                                    placeholder={intl.formatMessage({
                                        id: 'page.incidents.table.completion.dialog.select.componentGroup',
                                    })}
                                    fullWidth
                                    floatingLabel
                                />
                            )}
                        </FloatingLabel>
                        <FloatingLabel
                            htmlFor="type"
                            floating={Boolean(selectedComponent)}
                            label={intl.formatMessage({
                                id: 'page.incidents.table.completion.dialog.select.component',
                            })}
                        >
                            {({ onFocus, onBlur }) => (
                                <Select
                                    id="type"
                                    value={selectedComponent}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={setSelectedComponent}
                                    options={
                                        components
                                            ? components.map(i => ({
                                                  value: i,
                                                  label: i,
                                              }))
                                            : []
                                    }
                                    disabled={!selectedComponentGroup}
                                    placeholder={intl.formatMessage({
                                        id: 'page.incidents.table.completion.dialog.select.component',
                                    })}
                                    fullWidth
                                    floatingLabel
                                />
                            )}
                        </FloatingLabel>
                    </div>
                </div>
                <div className={styles.rowSpace}>
                    <div className={styles.componentRow}>
                        <div className={styles.componentRow}>
                            <div>
                                <DatePicker
                                    onChange={setSelectedDate}
                                    value={selectedDate}
                                    format={'DD.MM.YYYY'}
                                    allowClear={false}
                                    style={{
                                        paddingTop: 10.3,
                                        paddingBottom: 10.3,
                                    }}
                                    dropdownClassName={styles.dropdown}
                                    placeholder={intl.formatMessage({
                                        id: 'page.incidents.table.completion.dialog.select.date',
                                    })}
                                />
                            </div>
                            <div>
                                <DatePicker
                                    ref={timePickerRef}
                                    onChange={v => {
                                        if (timePickerRef?.current) timePickerRef?.current?.blur();
                                        setSelectedTime(v);
                                    }}
                                    value={selectedTime}
                                    picker="time"
                                    allowClear={false}
                                    style={{
                                        paddingTop: 10.3,
                                        paddingBottom: 10.3,
                                    }}
                                    // popupStyle={{

                                    // }}
                                    format={'HH:mm'}
                                    showNow={false}
                                    onOpenChange={open => {
                                        if (!open) {
                                            if (timePickerRef?.current) timePickerRef?.current?.blur();
                                        }
                                    }}
                                    showSecond={false}
                                    dropdownClassName={'incidents-time-picker'}
                                    placeholder={intl.formatMessage({
                                        id: 'page.incidents.table.completion.dialog.select.time',
                                    })}
                                />
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>

                {/* Footer */}
                <div className={styles.checkboxRow}>
                    <div>
                        {!!showAlert && (
                            <div className={styles.alertContainer}>
                                <Icon className={styles.alertIcon} type={IconType.AlertWarning} />
                                <div className={styles.alertMessage}>
                                    <FormattedMessage id="page.incidents.table.cancellation.dialog.alert" />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.bottomButtons}>
                        <Button
                            size="large"
                            onClick={() => {
                                navigate(null);
                                setSubStatus(null);
                                setShowAlert(false);
                                setComponents([]);
                                setSelectedComponentGroup(null);
                                setSelectedComponent(null);
                                setSelectedDate(null);
                                setResolvedOn(null);
                                setSelectedTime(null);
                                setSelectedTime(null);
                                setSelectedComponentGroup(null);
                            }}
                        >
                            <FormattedMessage id="page.incidents.table.validation.dialog.cancel" />
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                            disabled={
                                !selectedComponentGroup ||
                                !selectedComponent ||
                                !selectedDate ||
                                !selectedTime ||
                                !resolvedOn ||
                                !subStatus
                            }
                            onClick={() => {
                                const requestType = COMPLETION_ACTION;
                                handleSubmit(
                                    {
                                        elevator: meta.elevatorId,
                                        resolvedOn: resolvedOn,
                                        componentGroup: selectedComponentGroup,
                                        component: selectedComponent,
                                        subStatus,
                                        approvalStatus: COMPLETION_ACTION,
                                        severity: meta.severity,
                                    },
                                    {
                                        requestType,
                                        caseNumber: meta.caseNumber,
                                    },
                                );

                                setHideMessage(false);
                                navigate(null);
                                setSubStatus(null);
                                setShowAlert(false);
                                setComponents([]);
                                setSelectedComponentGroup(null);
                                setSelectedComponent(null);
                                setSelectedDate(null);
                                setResolvedOn(null);
                                setSelectedTime(null);
                                setSelectedTime(null);
                                setSelectedComponentGroup(null);
                                // clearForm();
                            }}
                        >
                            <FormattedMessage id="incident.new.dialog.button.submit" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
