import React from 'react';

const PowerOutage = () => (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ic/24/power_outrage" transform="translate(-1.000000, -1.000000)" fill="currentColor">
                <path
                    d="M11.0934,19.4808 L11.8964,13.3098 L13.8114,15.2248 L11.0934,19.4808 Z M7.7904,12.0008 L8.9734,10.3878 L10.5864,12.0008 L7.7904,12.0008 Z M13.3214,4.4598 L12.4614,9.8428 C12.4154,10.1318 12.4974,10.4268 12.6884,10.6488 C12.8774,10.8718 13.1564,11.0008 13.4484,11.0008 L16.5064,11.0018 L14.9124,13.4988 L10.1704,8.7558 L13.3214,4.4598 Z M22.9994,21.5858 L16.3614,14.9468 L18.5054,11.5868 C18.5624,11.4988 18.6044,11.4018 18.6304,11.2998 C18.7214,10.9498 18.7724,10.5498 18.5354,9.9948 C18.5114,9.9398 18.4834,9.8868 18.4494,9.8358 C18.0964,9.3028 17.6554,9.0448 16.9514,9.0008 L14.6214,9.0008 L15.3964,4.1488 C15.5374,3.3038 15.0844,2.4798 14.2984,2.1478 C13.5054,1.8078 12.5974,2.0598 12.0904,2.7548 L8.7394,7.3248 L2.4134,0.9998 L0.9994,2.4138 L7.5424,8.9568 L5.9044,11.1918 C5.4894,11.7548 5.4294,12.4918 5.7464,13.1178 C6.0444,13.7038 6.5984,14.0008 7.3904,14.0008 L9.7894,14.0008 L9.0204,19.9128 C8.9054,20.7628 9.4084,21.5938 10.2184,21.8898 C10.4214,21.9628 10.6324,22.0008 10.8474,22.0008 C11.4814,22.0008 12.0634,21.6808 12.4014,21.1488 L15.2584,16.6728 L21.5854,23.0008 L22.9994,21.5858 Z"
                    id="Fill-1"
                />
            </g>
        </g>
    </svg>
);

export default PowerOutage;
