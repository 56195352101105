import { isEmpty } from 'lodash';

// To show all workorders in view on initial render
export const fitBounds = (map: google.maps.Map, markers: google.maps.Marker[]) => {
    if (isEmpty(markers)) {
        return;
    }

    const bounds = new google.maps.LatLngBounds();

    markers.forEach(marker => {
        const position = marker.getPosition();
        // Check if position is valid
        if (position instanceof google.maps.LatLng) {
            bounds.extend(position);
        } else {
            console.warn('Invalid marker position:', position);
        }
    });

   // Only fit bounds if there are valid positions
   if (bounds.isEmpty()) {
    console.warn('No valid marker positions to fit bounds.');
} else {
    map.fitBounds(bounds);
}
};
