import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { UploadFile, UploadService, GeneralDocumentsUploadType, ChecktypeDocumentsUploadType } from '../types';

import { uploadDocument, uploadDocumentReset } from '../services/actions';
import { selectUploadDocumentApi } from '../services/selectors';

export const useUploadDocument = (uploadId?: string) => {
    const dispatch = useDispatch();

    return {
        api: useSelector(state =>
            selectUploadDocumentApi(state, {
                uploadId,
            }),
        ),
        uploadDocument: useCallback(
            ({
                files,
                type,
                service,
            }: {
                files: UploadFile[];
                service?: UploadService;
                type: ChecktypeDocumentsUploadType | GeneralDocumentsUploadType;
            }) => {
                dispatch(
                    uploadDocument({
                        files,
                        type,
                        service,
                        uploadId,
                    }),
                );
            },
            [dispatch, uploadId],
        ),
        resetUpload: useCallback(() => {
            dispatch(uploadDocumentReset(uploadId));
        }, [dispatch, uploadId]),
    };
};
