import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import config from 'config';
import { Contract } from 'modules/entities/modules/documents-contracts';
import { Paragraph } from 'modules/ui';

import ItemActions from '../../ItemActions';

import * as felaRules from './ContractItemInfo.styles';

export interface ContractItemInfoProps {
    contract: Contract;
}

export const ContractItemInfo = ({ contract }: ContractItemInfoProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            <ItemActions id={contract.id} file={contract.file} />
            <div className={styles.priceContainer}>
                <FormattedMessage
                    id="documents.contracts.table.info.price"
                    values={{
                        span: (...chunks: ReactNode[]) => (
                            <Paragraph faded size="small">
                                {chunks}
                            </Paragraph>
                        ),
                        price: (
                            <Paragraph extend={{ paragraph: rules.price }}>
                                <FormattedNumber
                                    value={contract.netPrice}
                                    // eslint-disable-next-line react/style-prop-object
                                    style="currency"
                                    currency={config.currency}
                                />
                            </Paragraph>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
