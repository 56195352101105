import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { IconType } from 'modules/ui';

import { DialogState } from '../../constants';

import { NewElevatorDialogContent } from '../NewElevatorDialogContent';
import { NewElevatorDialogUploadSelectionItem } from '../NewElevatorDialogUploadSelectionItem';
import { NewElevatorUploadButtons } from '../NewElevatorUploadButtons';

import * as felaRules from './NewElevatorDialogUploadManual.rules';

export const NewElevatorDialogUploadManual = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <NewElevatorDialogContent
            goBack
            title={<FormattedMessage id="elevator.new.dialog.manual.title" />}
            data-testid="new-elevator-dialog-manual"
        >
            <Typography.Paragraph className={styles.description}>
                <FormattedMessage id="elevator.new.dialog.manual.description" />
            </Typography.Paragraph>
            <div className={styles.navigation}>
                <NewElevatorDialogUploadSelectionItem
                    state={DialogState.CREATE_EXCEL}
                    iconType={IconType.UPLOAD_EXCEL}
                    title={<FormattedMessage id="elevator.new.dialog.selection.excel.title" />}
                    description={<FormattedMessage id="elevator.new.dialog.selection.excel.description" />}
                />
                <NewElevatorDialogUploadSelectionItem
                    state={DialogState.CREATE_ASSESSMENT_REPORT}
                    iconType={IconType.REPORT_UPLOAD}
                    title={<FormattedMessage id="elevator.new.dialog.selection.assessment-report.title" />}
                    description={<FormattedMessage id="elevator.new.dialog.selection.assessment-report.description" />}
                />
            </div>
            <NewElevatorUploadButtons />
        </NewElevatorDialogContent>
    );
};
