export enum FakeType {
    OPERATOR_OBLIGATION = 'operatorObligations',
    MAINTENANCE_ANALYSIS = 'maintenanceAnalysis',
}

export const forms = {
    [FakeType.OPERATOR_OBLIGATION]: 'operator',
    [FakeType.MAINTENANCE_ANALYSIS]: 'maintenance',
} as const;

export type FormKeys = typeof forms[keyof typeof forms];
