import React from 'react';

const Info = props => (
    <svg fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 13.333A5.34 5.34 0 0 1 2.668 8a5.34 5.34 0 0 1 5.334-5.334A5.34 5.34 0 0 1 13.334 8a5.34 5.34 0 0 1-5.333 5.333Zm0-12A6.674 6.674 0 0 0 1.335 8a6.674 6.674 0 0 0 6.667 6.666A6.674 6.674 0 0 0 14.667 8a6.674 6.674 0 0 0-6.666-6.667Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.453 4.796a.697.697 0 0 0-.207.502c0 .191.07.357.209.495A.69.69 0 0 0 7.948 6c.194 0 .363-.07.502-.208a.7.7 0 0 0 0-.997.707.707 0 0 0-.997.001Z"
        />
        <rect x="7.334" y="6.667" width="1.333" height="4.667" rx=".667" />
    </svg>
);

export default Info;
