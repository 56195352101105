import { Contract } from '../../../types';
import { ApiAction } from '../../../../../types';
import { fetchContractsTypes } from '../../actions';

const initialState: Contract[] = [];

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchContractsTypes.FETCH_CONTRACTS_SUCCESS:
            return action.payload;
        case fetchContractsTypes.FETCH_CONTRACTS_RESET:
            return initialState;
        default:
            return state;
    }
};
