import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';
import { useElevatorCoordinates } from 'modules/entities/modules/elevator-coordinates';

import type { Search } from 'constants/index';
import { selectWorkordersCount, selecWorkorderGroups } from '../services/selectors';
import { useFetchWorkorders } from './useFetchWorkorders';

import { DEFAULT_SORT } from '../constants';

export const useMapWorkorders = (search: Search[]) => {
    const api = useFetchWorkorders();

    let workorderGroups = useSelector(state => selecWorkorderGroups(state, { search, sorting: DEFAULT_SORT }));
    const workordersCount = useSelector(selectWorkordersCount);
    const { progress: coordinatesProgress } = useElevatorCoordinates();
    useDisplayError(api.error);
    return {
        loading: api.inProgress || coordinatesProgress !== 100,
        workorderGroups,
        empty: workordersCount === 0,
    };
};
