import React from 'react';
import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './FakeCTA.rules';
import { FakeType } from 'modules/fake-pages/constants';

export interface FakeCTAProps {
    onClick: () => void;
    type?: string;
    submitting: boolean;
    description?: string;
}

export const FakeCTA = ({ onClick, submitting, type }: FakeCTAProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.cta}>
            <Typography.Title
                level={2}
                className={type === FakeType.MAINTENANCE_ANALYSIS ? styles.ctaFullTitle : styles.ctaTitle}
            >
                <p>
                    {type === FakeType.MAINTENANCE_ANALYSIS ? (
                        <FormattedMessage id="maintenance.empty.description" values={{ br: <br /> }} />
                    ) : (
                        <FormattedMessage id="page.fakePages.cta" />
                    )}
                </p>
            </Typography.Title>
            <Button size="large" onClick={onClick} loading={submitting} disabled={submitting}>
                {type === FakeType.MAINTENANCE_ANALYSIS ? (
                    <FormattedMessage id="maintenance.empty.cta" />
                ) : (
                    <FormattedMessage id="page.fakePages.button" />
                )}
            </Button>
        </div>
    );
};
