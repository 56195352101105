import type { TRuleWithTheme } from 'styles/theme';

import type { DocumentStatusIconProps } from './DocumentStatusIcon';

export const icon: TRuleWithTheme<{
    small: DocumentStatusIconProps['small'];
    color: string;
}> = ({ theme, small = false, color }) => ({
    color: color ?? theme.colors.icon,
    extend: {
        condition: small,
        style: {
            marginRight: '0.5rem',

            '> svg': {
                height: '1em',
                width: '1em',
            },
        },
    },
});
