import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'antd';

import { SeoHead } from 'modules/seo';

import { WorkOrdersPageMap } from '../WorkOrdersMap';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './WorkOrdersPage.rules';
import { useAirtimeScript } from 'modules/analytics';
import config from 'config/config';
import { useBrowserComptabilityScript } from 'modules/analytics/hooks/useBrowserCompatabilityScript';
import useHotjarScript from 'modules/analytics/hooks/useHotjarScript';
import ScheduleRoute from '../ScheduleRoute/ScheduleRoute';

export const WorkOrdersPage = () => {
    const intl = useIntl();
    const { styles } = useFelaEnhanced(felaRules);
    useAirtimeScript(config.airtime.url, config.airtime.token);
    useBrowserComptabilityScript();
    useHotjarScript();
    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'page.workorders.title' })} />
            <Row gutter={[4, 4]}>
                <Col span={24} md={19}>
                    <WorkOrdersPageMap />
                </Col>
                <Col span={24} md={5}>
                    <div className={styles.divider}>
                        <ScheduleRoute />
                    </div>
                </Col>
            </Row>
        </div>
    );
};
