// @ts-ignore
import { locals as colors } from './colors.less';
// @ts-ignore
import { locals as shadows } from './shadows.less';
// @ts-ignore
import { locals as borders } from './borders.less';

import type { TRule } from 'fela';

const theme = {
    colors,
    shadows,
    borderRadius: borders.radius,
    rowGutter: {
        x: 24,
        y: 24,
    },
    height: {
        logo: 38,
        logoLarge: 48,
        chart: 280,
        verticalChartBase: 100,
        authPage: 545,
    },
    chart: {
        xAxisAngle: -45,
        xAxisHeight: 60,
        xAxisDx: -7.5,
        xAxisDy: 8,
    },
    width: {
        asideNav: 212,
        drawer: 308,
        chartYAxis: 20,
        chartYAxisWider: 35,
        chart: 258,
        verticalChart: 300,
        container: 1050,
        dropdownMenu: 245,
        select: 180,
    },
    spacing: {
        unit: '1rem',
    },
    transition: {
        duration: '0.3s',
    },
};

export type Theme = typeof theme;

export interface ThemeProps {
    theme: Theme;
}

export type TRuleWithTheme<Props = {}> = TRule<ThemeProps & Props>;

export type RulesExtend<TExtandalbeRules, TProps = {}> = Partial<
    Record<keyof TExtandalbeRules, TRuleWithTheme<TProps>>
>;
export default theme;
