import type { TRuleWithTheme } from 'styles/theme';

export const success: TRuleWithTheme = () => ({
    paddingTop: '2.875rem',
    paddingRight: '2rem',
    paddingBottom: '2.875rem',
    paddingLeft: '2rem',

    textAlign: 'center',
});

export const successIcon: TRuleWithTheme = ({ theme }) => ({
    '&.anticon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: 52,
        height: 52,

        marginRight: 'auto',
        marginBottom: '0.25em',
        marginLeft: 'auto',

        background: theme.colors.white,

        borderRadius: '50%',
    },
});

export const successTitle: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: 0,

        color: theme.colors.white,

        fontSize: '1.5rem',
        lineHeight: '1.33em',
    },
});

export const successMessage: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: 0,

        color: theme.colors.white,

        fontSize: '0.875rem',
        lineHeight: '1.43em',

        opacity: 0.7,
    },
});
