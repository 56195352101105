export const forms = {
    newIncident: 'newIncident',
};

export const fields = {
    incident: {
        elevator: 'elevator',
        incidentType: 'incidentType',
        description: 'description',
    },
};


