import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import * as felaRules from './NewElevatorButton.rules';
import { useFelaEnhanced } from 'hooks';

import { NewElevatorDialogContext } from '../../contexts/NewElevatorDialogContext';

import { DialogState } from '../../constants';
export interface TableActionPanelProps {
    testId?: string;
}
interface NewElevatorButtonProps {
    avoidFlexBasis?: boolean;
}
export const NewElevatorButton = ({ avoidFlexBasis }: NewElevatorButtonProps) => {
    const { styles } = useFelaEnhanced({ ...felaRules });
    const { navigate } = useContext(NewElevatorDialogContext);

    return (
        <Button
            type="primary"
            size="large"
            className={avoidFlexBasis ? null : styles.button}
            onClick={() => {
                navigate(DialogState.SELECTION);
            }}
        >
            <FormattedMessage id="elevator.new.trigger" />
        </Button>
    );
};
