import { DocumentElevator, DocumentFile, DocumentServiceProvider } from '../documents';

export enum AssessmentReportStatus {
    DATA_CAPTURE = 'data_capture',
    IN_PROGRESS = 'in_progress',
    DONE = 'done',
}

export interface AssessmentReport {
    id: string;
    status: AssessmentReportStatus;
    assessmentDate: string;
    assessmentType: string;
    result: string;
    noTotalDeficiencies: number;
    noDangerousDeficiencies: number;
    noExtensiveDeficiencies: number;
    noMinorDeficiencies: number;
    dueDate?: string;
    nextAssessment: string;
    serviceProvider: DocumentServiceProvider;
    elevator: DocumentElevator;
    file?: DocumentFile;
}

export enum AssessmentReportField {
    STATUS = 'status',
    ASSESSMENT_REPORT_DATE = 'assessmentDate',
    ASSESSMENT_REPORT_DATE_FROM = 'assessmentDate.from',
    ASSESSMENT_REPORT_DATE_TO = 'assessmentDate.to',
    ASSESSMENT_REPORT_TYPE = 'assessmentType',
    SERVICE_PROVIDER_NAME = 'serviceProvider.name',
    RESULT = 'result',
    NO_TOTAL_DEFICIENCIES = 'noTotalDeficiencies',
    DUE_DATE = 'dueDate',
    NEXT_ASSESSMENT = 'nextAssessment',
    ELEVATOR_FACTORY_NUMNER = 'elevator.factoryNumber',
    ELEVATOR_PROPERTY_UNIT_CITY = 'elevator.propertyUnit.city',
    ELEVATOR_PROPERTY_UNIT_STREET = 'elevator.propertyUnit.street',
    ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT = 'elevator.propertyUnit.businessUnit',
}
