import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { useNotifications } from 'modules/ui';
import { useFormSubmission } from 'modules/form';

import { forms } from '../../config';
import type { RegisterFormValues } from '../../types';

import { isFormSubmissionError } from '../../utils';
import { useRegistrationStatus } from '../../hooks/useRegistrationStatus';

import { RegistrationForm } from '../RegistrationForm';
import { RegistrationMessage } from '../RegistrationMessage';

import * as felaRules from './Registration.rules';

export const Registration = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    const { handleSubmit, submitting, error, succeeded } = useFormSubmission<RegisterFormValues>(forms.register);

    const status = useRegistrationStatus();

    const notifications = useNotifications();

    useEffect(() => {
        if (isFormSubmissionError(error)) {
            notifications.error({
                message: intl.formatMessage({
                    id: 'auth.registrationFailed.message',
                }),
                description: intl.formatMessage(
                    {
                        id: 'auth.registrationFailed.description',
                    },
                    {
                        email: email => <a href={`mailto:${email}`}>{email}</a>,
                    },
                ),
            });
        }
    }, [notifications, error, intl]);

    return (
        <div className={styles.container}>
            {succeeded ? (
                <RegistrationMessage status={status} />
            ) : (
                <RegistrationForm submitting={submitting} onSubmit={handleSubmit} />
            )}
        </div>
    );
};
