import { basicApiReducer } from '@ackee/redux-utils';
import { fetchElevatorsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchElevatorsTypes.FETCH_ELEVATORS_REQUEST,
        SUCCESS: fetchElevatorsTypes.FETCH_ELEVATORS_SUCCESS,
        FAILURE: fetchElevatorsTypes.FETCH_ELEVATORS_FAILURE,
        CANCEL: fetchElevatorsTypes.FETCH_ELEVATORS_CANCEL,
        RESET: fetchElevatorsTypes.FETCH_ELEVATORS_RESET,
    },
});
