import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { TableDataSpace } from 'modules/table';

import { Workorder } from 'modules/entities/modules/workorder-documents';

import { DocumentElevator } from '../../DocumentElevator';

import ItemActions from '../../ItemActions';
import { DocumentsTable } from '../../DocumentsTable';
import { useRouteMatch } from 'react-router-dom';

export interface EmergencyRescuePlansTableProps {
    loading: boolean;
    documents: Workorder[];
}

export const EmergencyRescuePlansTable = ({ loading, documents }: EmergencyRescuePlansTableProps) => {
    const match = useRouteMatch('/elevators/:id');
    return(
    <DocumentsTable<Workorder>
        columns={[
            ...[
                match?.isExact
                    ? {}
                    : {
                          key: 'elevators.elevatorId',
                          dataIndex: 'elevators',
                          width: '50%',
                          render: (_, record) => <DocumentElevator elevator={record.elevator} />,
                      },
            ],
            {
                key: 'inspectionDate',
                width: match?.isExact? '70%': '20%',
                render: (_, record) => (
                    <TableDataSpace
                        title={<FormattedMessage id="documents.workOrder.table.inspectionDate" />}
                        value={<FormattedDate value={record.inspectionDate} />}
                    />
                ),
            },
            {
                key: 'id',
                width: '30%',
                render: (_, record) => <ItemActions id={record.id} file={record.file} />,
            },
        ]}
        loading={loading}
        dataSource={documents}
        data-testid="emergency-rescue-plans-table"
    />
)}
