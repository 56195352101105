import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { SupportSubjectsType } from '../types';

import { postSupportRequest, postSupportRequestReset } from '../services/actions';
import { selectSupportRequestApi } from '../services/selectors';

export const useSupportRequest = () => {
    const dispatch = useDispatch();

    const resetRequest = useCallback(
        (subject: string) => {
            dispatch(postSupportRequestReset(subject));
        },
        [dispatch],
    );

    return {
        api: useSelector(state => selectSupportRequestApi(state, { subject: 'support' })),
        resetRequest,
        sendSupportRequest: useCallback(
            ({
                subject,
                description,
                title,
            }: {
                subject: SupportSubjectsType;
                description?: string;
                title: string;
            }) => {
                dispatch(
                    postSupportRequest({
                        subject,
                        description,
                        title,
                    }),
                );
            },
            [dispatch],
        ),
    };
};
