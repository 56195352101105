import { TRuleWithTheme } from 'styles/theme';

export const tabs: TRuleWithTheme = () => ({
    paddingBottom: '0.78125rem',
    '& .ant-tabs-tab': {
        paddingBottom: '4px',
    },
});

export const noPadding: TRuleWithTheme = () => ({
    paddingTop: '0rem',
    '& .ant-tabs-nav': {
        margin: '0em 0px 1.5em;',
    },
});
