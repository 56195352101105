import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';

import { UploadService, GeneralDocumentsUploadType, ChecktypeDocumentsUploadType } from '../../types';
import type { UploadFile } from '../../types';

export const uploadDocument = createAction(
    `${EntityKey.DOCUMENTS}/UPLOAD_DOCUMENT`,
    ({
        files,
        type,
        uploadId,
        service = UploadService.CHECKTYPE_DOCUMENTS,
    }: {
        files: UploadFile[];
        type: GeneralDocumentsUploadType | ChecktypeDocumentsUploadType;
        service?: UploadService;
        uploadId: string;
    }) => ({
        payload: {
            type,
            files,
        },
        meta: {
            service,
            uploadId,
        },
    }),
);

export const uploadDocumentRequest = createAction(`${EntityKey.DOCUMENTS}/UPLOAD_DOCUMENT_REQUEST`, uploadId => ({
    payload: null,
    meta: {
        uploadId,
    },
}));

export const uploadDocumentSuccess = createAction(
    `${EntityKey.DOCUMENTS}/UPLOAD_DOCUMENT_SUCCESS`,
    (uploadId: string, lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            uploadId,
            lastSuccessAt,
        },
    }),
);

export const uploadDocumentFailure = createAction(
    `${EntityKey.DOCUMENTS}/UPLOAD_DOCUMENT_FAILURE`,
    (error: any, uploadId: string) => ({
        payload: null,
        error,
        meta: {
            uploadId,
        },
    }),
);

export const uploadDocumentReset = createAction(`${EntityKey.DOCUMENTS}/UPLOAD_DOCUMENT_RESET`, (uploadId: string) => ({
    payload: null,
    meta: {
        uploadId,
    },
}));
