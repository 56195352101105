import { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';


export const aLink: TRuleWithTheme = ({ theme }) => ({
    textDecoration: 'underline',
    color: theme.colors.graphSecondaryBarColor
});
export const wrapper: TRuleWithTheme = ({ theme }) => ({
    borderRadius:'0.125rem',
    paddingLeft:'2rem',
    paddingTop:'1.5rem',
    paddingBottom:'1.5rem',
    marginBottom:'1rem',
    backgroundColor:theme.colors.selectedSecondary
});
export const buttonContainer: TRule = () => ({
    marginTop: '1rem',
});
export const header: TRule = () => ({
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight:'1.375rem',
    marginBottom: '0.45rem',
});
export const textBody: TRule = () => ({
    fontWeight: 500,
    fontSize:'0.875rem',
    lineHeight:'1.375rem',
});

export const image: TRule = () => ({
    position: 'absolute',
    top: '0',
    right: '0',
    height: 'auto',
    paddingRight: '1.875rem'
});