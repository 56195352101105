import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch, TableFilterPanel, useTableSearch, TableSearchSelect } from 'modules/table';
import {
    useWorkorderInspectionRecommendationOptions,
    WorkorderField,
} from 'modules/entities/modules/workorder-documents';

export interface ConditionReportsSearchPanel {
    defaultValue?: SearchValue;
    isElevatorDetail: boolean;
}

const defaultSearch = {};

export const ConditionReportsSearchPanel = ({
    defaultValue = defaultSearch,
    isElevatorDetail,
}: ConditionReportsSearchPanel) => {
    const intl = useIntl();
    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const inspectionRecommendationOptions = useWorkorderInspectionRecommendationOptions();

    return (
        <TableFilterPanel>
            <TableSearch
                fields={[
                    ...(isElevatorDetail
                        ? []
                        : [
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.factoryNumber`} />,
                                  labelFor: 'factoryNumber',
                                  renderer: (
                                      <Input
                                          id="factoryNumber"
                                          data-testid="factory-number"
                                          value={value[WorkorderField.ELEVATOR_FACTORY_NUMNER]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_FACTORY_NUMNER]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.street`} />,
                                  labelFor: 'street',
                                  renderer: (
                                      <Input
                                          id="street"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_STREET]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_STREET]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.city`} />,
                                  labelFor: 'city',
                                  renderer: (
                                      <Input
                                          id="city"
                                          data-testid="city"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_CITY]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_CITY]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                              {
                                  label: <FormattedMessage id={`documents.workOrder.search.businessUnit`} />,
                                  labelFor: 'businessUnit',
                                  renderer: (
                                      <Input
                                          id="businessUnit"
                                          value={value[WorkorderField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]}
                                          placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                                          onChange={({ target }) => {
                                              handleValue({
                                                  [WorkorderField.ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT]: target.value,
                                              });
                                          }}
                                      />
                                  ),
                              },
                          ]),

                    {
                        label: <FormattedMessage id={`documents.workOrder.search.inspectionRecommendation`} />,
                        labelFor: 'inspectionRecommendation',
                        renderer: (
                            <TableSearchSelect
                                id="inspectionRecommendation"
                                mode="multiple"
                                placeholder={intl.formatMessage({ id: 'table.choose.placeholder' })}
                                options={inspectionRecommendationOptions}
                                value={value[WorkorderField.INSPECTION_RECOMMENDATION]}
                                disabled={isEmpty(inspectionRecommendationOptions)}
                                onChange={value => {
                                    handleValue({
                                        [WorkorderField.INSPECTION_RECOMMENDATION]: value,
                                    });
                                }}
                            />
                        ),
                    },
                ]}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                rowGutter={[16, 16]}
                colSizes={{ sm: 24, md: 12, lg: 8, xxl: 3 }}
            />
        </TableFilterPanel>
    );
};
