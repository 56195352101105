import React from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import { Workorder, WorkorderField, DEFAULT_SORT } from 'modules/entities/modules/workorder-documents';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

export interface ActionPanelProps {
    documents: Workorder[];
}

const SORT_FIELDS = [WorkorderField.INSPECTION_DATE];

export const ConditionReportsActionPanel = ({ documents }: ActionPanelProps) => {
    return (
        <TableActionPanel>
            <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
            <DocumentsDownloadAll documents={documents} />
        </TableActionPanel>
    );
};
