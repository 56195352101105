import { ReactNode } from 'react';
import { FIELD_SPECIFIC_ERRORS } from '../constants';

const displayFieldSpecificError = (error: string, field: string, formatMessage: ({ id: string }) => string): ReactNode => {
    if (FIELD_SPECIFIC_ERRORS[error]) {
        if (FIELD_SPECIFIC_ERRORS[error] === field) {
            return formatMessage({ id: error });
        }
    } else {
        return formatMessage({ id: error })
    }

}

export default displayFieldSpecificError
