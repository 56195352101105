import { PureComponent } from 'react';
import AwsAmplify from 'aws-amplify';
import config from 'config';

export default class Amplify extends PureComponent {
    constructor(props) {
        super(props);

        AwsAmplify.configure(config.aws.amplify);
    }

    render() {
        return this.props.children;
    }
}
