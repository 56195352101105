import type { CSSObject, TRule } from 'fela';

import { Theme } from 'styles/theme';

import { ElevatorState } from 'modules/entities/modules/elevators';

export const iconWrapper = () => ({});

export const statusName = () => ({
    color: 'inherit',
});

const getColors = (state: ElevatorState = null, { colors }: Theme): CSSObject => {
    switch (state) {
        case ElevatorState.RUNNING:
            return {
                backgroundColor: colors.bgStatusSuccess,
                borderColor: colors.outlineStatusSuccess,
            };
        case ElevatorState.POTENTIAL_ISSUES:
            return {
                backgroundColor: colors.bgStatusWarning,
                borderColor: colors.outlineStatusWarning,
            };
        case ElevatorState.OUT_OF_ORDER:
            return {
                backgroundColor: colors.bgStatusHighAlert,
                borderColor: colors.outlineStatusHighAlert,
            };
        case ElevatorState.TECHNICIAN_ON_SITE:
            return {
                backgroundColor: colors.bgStatusInfo,
                borderColor: colors.outlineStatusInfo,
            };
        default:
            return {
                backgroundColor: colors.bgStatusDefault,
                borderColor: colors.outlineStatusDefault,
            };
    }
};

export const alert: TRule<{ theme: Theme; state?: ElevatorState }> = ({ theme, state }) => {
    const { backgroundColor, borderColor } = getColors(state, theme);

    return {
        '&.ant-alert': {
            backgroundColor,
            borderColor,

            paddingLeft: '.85em',
            marginBottom: '1.375rem',

            '& .ant-alert-content': {
                marginLeft: '.9em',

                '& .ant-alert-message': {
                    fontWeight: 600,
                    fontSize: '1rem',
                },
            },

            '& .anticon': {
                marginTop: 2,
            },

            '& .ant-alert-description': {
                color: theme.colors.overallStatusDescripitonColor,
                fontSize: '.875rem',
            },
        },
    };
};
