import React, { FC } from 'react';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { createRenderer } from 'fela';
import { theme } from 'styles';

import { applyStaticCSS, applyFonts } from '../utilities';
import * as Config from '../config';

const Fela: FC = ({ children }) => {
    const renderer = createRenderer(Config.rendererConfig);

    React.useEffect(() => {
        applyStaticCSS(renderer, Config.staticCSS);
        applyFonts(renderer, Config.fonts);

        return () => {
            renderer.clear();
        };
    }, [renderer]);

    return (
        <RendererProvider renderer={renderer}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </RendererProvider>
    );
};

export default Fela;
