export const container = () => ({
    '> #elevator-detail-tabs': {
        '> .ant-tabs-tab-btn': {
            // color: 'red',
            fontSize: '0.9rem'
        },
        '> .ant-tabs-nav': {
            // borderBottom: 'solid 1px black',
            '> .ant-tabs-nav-wrap': {
                // marginBottom: '-1px',
                '> .ant-tabs-nav-list': {
                    // borderBottom: 'solid 1px black',
                    '> .ant-tabs-tab-active': {
                        // marginBottom: '-1px',
                        // borderBottom: '#f0f2f5',
                        // borderLeft: '1px solid black',
                        // borderRight: '1px solid black',
                    },
                    '> .ant-tabs-tab': {
                        '> .ant-tabs-tab-btn': {
                            // color: 'red',
                            fontSize: '0.9rem'
                        },
                    },
                },
            },
        },
    },
});

export const tabsWrapper = () => ({
    '& .ant-tabs-nav': {
        margin: '0.9em 0 0.7em',
    },
    
});
