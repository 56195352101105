import { reducer as formReducer } from 'modules/form';
import { reducer as mapReducer } from 'modules/map';
import { reducer as auth, petrusReducer as petrus } from 'modules/auth';

import api from './api';
import entities from './entities';

// NOTE:
// Do not use combineReducers() here,
// export reducers as plain object, not as a function.
const reducers = {
    petrus,
    auth,
    api,
    entities,
    form: formReducer,
    map: mapReducer,
};

export default reducers;
