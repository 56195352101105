import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import type { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch, TableSearchSelect, useTableSearch } from 'modules/table';
import { ElevatorField } from 'modules/entities/modules/elevators';

export interface ElevatorSearchProps {
    defaultValue?: SearchValue;
    availableHardwareOptions?: string[];
}

const defaultSearch = {};

export const ElevatorSearch = ({
    defaultValue = defaultSearch,
    availableHardwareOptions = [],
}: ElevatorSearchProps) => {
    const intl = useIntl();

    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue, true);

    function convertToHardwareOptions(array) {
        const hardwareMap = {
            IOT: 'page.elevators.table.hardware.iot',
            Smart_Flap: 'page.elevators.table.hardware.smartflap',
            // Add other mappings as needed
        };

        if (Array.isArray(array) && array.length > 0) {
            return array.map(item => {
                return {
                    value: item,
                    label: intl.formatMessage({ id: hardwareMap[item] || 'unknown' }),
                };
            });
        } else {
            return [];
        }
    }

    const hardwareOptions = convertToHardwareOptions(availableHardwareOptions);

    return (
        <TableSearch
            fields={[
                {
                    label: <FormattedMessage id="page.elevators.table.factoryNumber" />,
                    labelFor: 'factoryNumber',
                    renderer: (
                        <Input
                            id="factoryNumber"
                            data-testid="factory-number"
                            value={value[ElevatorField.FACTORY_NUMBER]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [ElevatorField.FACTORY_NUMBER]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id="page.elevators.table.street" />,
                    labelFor: 'property-unit-street',
                    renderer: (
                        <Input
                            id="property-unit-street"
                            data-testid="property-unit-street"
                            value={value[ElevatorField.PROPERTY_UNIT_STREET]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [ElevatorField.PROPERTY_UNIT_STREET]: target.value,
                                });
                            }}
                        />
                    ),
                },
                // {
                //     label: <FormattedMessage id="page.elevators.table.postalCode" />,
                //     labelFor: 'property-unit-postal-code',
                //     renderer: (
                //         <Input
                //             id="property-unit-postal-code"
                //             data-testid="property-unit-postal-code"
                //             value={value[ElevatorField.PROPERTY_UNIT_POSTAL_CODE]}
                //             placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                //             onChange={({ target }) => {
                //                 handleValue({
                //                     [ElevatorField.PROPERTY_UNIT_POSTAL_CODE]: target.value,
                //                 });
                //             }}
                //         />
                //     ),
                // },
                {
                    label: <FormattedMessage id="page.elevators.table.city" />,
                    labelFor: 'property-unit-city',
                    renderer: (
                        <Input
                            id="property-unit-city"
                            data-testid="property-unit-city"
                            value={value[ElevatorField.PROPERTY_UNIT_CITY]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [ElevatorField.PROPERTY_UNIT_CITY]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id="page.elevators.table.businessUnit" />,
                    labelFor: 'property-unit-business-unit',
                    renderer: (
                        <Input
                            id="property-unit-business-unit"
                            data-testid="property-unit-business-unit"
                            value={value[ElevatorField.PROPERTY_UNIT_BUSINESS_UNIT]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [ElevatorField.PROPERTY_UNIT_BUSINESS_UNIT]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id="page.elevators.table.owner" />,
                    labelFor: 'owner',
                    renderer: (
                        <Input
                            id="owner"
                            data-testid="owner"
                            value={value[ElevatorField.OWNER]}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            onChange={({ target }) => {
                                handleValue({
                                    [ElevatorField.OWNER]: target.value,
                                });
                            }}
                        />
                    ),
                },
                {
                    label: <FormattedMessage id={`page.elevators.table.hardware`} />,
                    newline: true,
                    renderer: (
                        <TableSearchSelect
                            mode="multiple"
                            options={hardwareOptions}
                            placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
                            value={value[ElevatorField.HARDWARE]}
                            onChange={value => {
                                handleValue({
                                    [ElevatorField.HARDWARE]: value,
                                });
                            }}
                        />
                    ),
                },
            ]}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            // colSizes={{ sm: 24, md: 12, lg: 8, xxl: 4 }}

            colSizes={{ sm: 24, md: 12, lg: 5, xxl: 5 }}
        />
    );
};
