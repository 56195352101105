import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Empty } from 'antd';

import SubSectionDetailStatus from './SubSectionDetailStatus';
import DetailedStatusIcon from './DetailedStatusIcon';
import { useIotConfig } from 'modules/entities/modules/config/hooks';
import { HealthStateSectionsName, HEALTH_STATE_TYPES, ParsedSection } from 'modules/entities/modules/elevators';
type SectionsProps = {
    type: HEALTH_STATE_TYPES;
    sections: ParsedSection[];
};
const Sections: FC<SectionsProps> = ({ sections }) => {
    const iot_config = useIotConfig();
    const configuration = Object.values(iot_config)[0]?.elevators?.iot?.elevatorStatus;
    const filteredSections = configuration
        ? sections.filter(sec => {
              return (configuration[sec.name] !== undefined && configuration[sec.name] === true && sec.name !== HealthStateSectionsName.CABINLIGHT) || configuration[sec.name] === undefined;
          })
        : sections;

    return (
        <>
            {!!filteredSections && filteredSections.length > 0 ? (
                filteredSections.map(parsedSection => {
                    if (parsedSection?.subStates?.length > 0) {
                        return (
                            <SubSectionDetailStatus
                                key={parsedSection?.parentState}
                                subSection={parsedSection}
                                title={<FormattedMessage id={`healthstate.state.${parsedSection?.parentState}`} />}
                                icon={<DetailedStatusIcon type={parsedSection.iconType} />}
                            />
                        );
                    } else {
                        return <div />;
                    }
                })
            ) : (
                <Empty />
            )}
        </>
    );
};

export default Sections;
