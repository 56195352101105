import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';
import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';

import { selectFilteredQuotations } from '../services/selectors';

import { useFetchQuotations } from '../hooks/useFetchQuotations';
import { DEFAULT_SORT } from '../constants';

export const useQuotations = () => {
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const api = useFetchQuotations();

    const quotations = useSelector(state => selectFilteredQuotations(state, { sorting, search }));

    useDisplayError(api.error);

    return {
        loading: api.inProgress,
        documents: quotations,
        defaultSearch: defaultValue,
    };
};
