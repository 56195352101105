import { useSelector } from 'react-redux';

import { selectPicklist } from '../services/selectors';

export const useIncidentPicklist = () => {
    const picklist = useSelector(state => selectPicklist(state));

    return {
        picklist,
    };
};
