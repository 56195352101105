import React, { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './DeactivateDialogContainer.rules';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'modules/ui';
import bg from 'assets/images/no_device_background.png'
export interface IDeactivateDialogContainer {
    visible?: boolean;
}

const ELEVATOR_LIVE_LINK = 'https://www.simplifa.de/produkte/elevator-live/';
export const DeactivateDialogContainer: FC<IDeactivateDialogContainer> = ({ visible = false }) => {
    const { styles } = useFelaEnhanced(felaRules);

    const { formatMessage } = useIntl();
    return (
             <div className={styles.wrapper}>
                <div className={styles.header}>
                    <FormattedMessage id={`elevator.iot.notInstalled.NO_DEVICE_ORDERED.header`} />
                </div>
                <div className={styles.textBody}>
                    <FormattedMessage
                        id={`elevator.iot.notInstalled.NO_DEVICE_ORDERED`}
                        values={{
                            link: (chunks: ReactNode[] | ReactNode) => (
                                <a
                                    className={styles.aLink}
                                    href={ELEVATOR_LIVE_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        href="https://www.simplifa.de/kontakt/"
                        target="_blank"
                    >
                        <FormattedMessage id={`elevator.iot.notInstalled.NO_DEVICE_ORDERED.button`} />
                    </Button>
                </div>
                <img
                    src={bg}
                    alt={formatMessage({ id: 'elevator.iot.notInstalled.illustration' })}
                    className={styles.image}
                />
                </div>
    );
};
