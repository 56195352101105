import { chunk } from 'lodash';

/**
 * Splits received array into pages of given size aligned to the end
 */
export default <T extends unknown>(array: Array<T>, size: number): T[][] => {
    const reminder = array.length % size;

    return reminder ? [array.slice(0, reminder), ...chunk(array.slice(reminder), size)] : chunk(array, size);
};
