import { SortingType } from 'constants/index';

import { WorkorderField, WorkOrderState } from './types';

export const DEFAULT_SORT = {
    field: WorkorderField.STATUS,
    type: SortingType.ASC,
};

export const SUPPORTED_WORKORDER_STATES = [
    WorkOrderState.NEW,
    WorkOrderState.IN_CLARIFICATION,
    WorkOrderState.NOT_RELEVANT,
    WorkOrderState.RUNNING,
    WorkOrderState.WAITING_FOR_ASSISTANT,
    WorkOrderState.WAITING_FOR_TECHNICIAN,
    WorkOrderState.TECHNICIAN_REVIEW_REQUIRED,
    WorkOrderState.FINISHED,
    WorkOrderState.REJECTED,
    WorkOrderState.TECHNICAL_Q_CHECK_DONE,
    WorkOrderState.IN_REVIEW,
    WorkOrderState.FULFILLED,
    WorkOrderState.UNKNOWN,
];
