import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from 'antd';

import { SeoHead } from 'modules/seo';
import * as felaRules from './MaintenancePage.rules';
import { useFelaEnhanced } from 'hooks';
import { MaintenanceTable } from '../MaintenanceTable';
import { useMaintenances } from 'modules/entities/modules/maintenances';
import { FakePage, FakeType } from 'modules/fake-pages';
import FilterPanel from '../FilterPanel';
import ActionPanel from '../ActionPanel';
import { MaintenanceInfo } from '../MaintenanceInfo';
import config from 'config';

export const MaintenancePage = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();
    const { maintenances, loading, defaultSearch, maintenancesCount } = useMaintenances();

    if (!loading && (!maintenancesCount || false)) {
        return <FakePage type={FakeType.MAINTENANCE_ANALYSIS} />;
    }
    return (
        <div className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: 'page.maintenance.title' })} />
            <div className={styles.header}>
                <Typography.Title level={4} className={styles.title}>
                    <FormattedMessage id="page.maintenance.title" />
                </Typography.Title>
            </div>
            <MaintenanceInfo>
                <FormattedMessage
                    id="page.maintenance.info"
                    values={{
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        link: (chunks: ReactNode[] | ReactNode) => (
                            <a href={config.routes.support} target="_blank" rel="noopener noreferrer">
                                {chunks}
                            </a>
                        ),
                        br: <br />,
                    }}
                />
            </MaintenanceInfo>
            <FilterPanel defaultSearch={defaultSearch} />
            <ActionPanel />
            <MaintenanceTable elevators={maintenances} loading={loading} />
        </div>
    );
};
