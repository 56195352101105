export interface IoTDevice {
    installation: string;
    id: string;
}

export interface Contract {
    startDate: string;
    endDate: string;
    contractNumber: string;
    active: boolean;
}

export interface Contact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    type: ContactType;
}

export enum ElevatorState {
    RUNNING = 'RUNNING',
    POTENTIAL_ISSUES = 'POTENTIAL_ISSUES',
    OUT_OF_ORDER_TRIP_PROFILE = 'OUT_OF_ORDER_TRIP_PROFILE',
    OUT_OF_ORDER = 'OUT_OF_ORDER',
    OUT_OF_ORDER_ALARM_DEVICE = 'OUT_OF_ORDER_ALARM_DEVICE',
    TECHNICIAN_ON_SITE = 'TECHNICIAN_ON_SITE',
    DEVICE_OFFLINE = 'DEVICE_OFFLINE',
}

export enum ElevatorStateGroup {
    UNKNOWN = 'UNKNOWN',
}

export enum ContactType {
    SERVICE_COMPANY = 'serviceCompany',
    LIFT_GUARD = 'liftGuard',
    ASSET_MANAGER = 'assetManager',
    PROPERTY_MANAGER = 'propertyManager',
    PROPERTY_ADMINISTRATION = 'propertyAdministration',
    FACILITY_MANAGER = 'facilityManager',
    ACCOUNT_MANAGER = 'accountManager',
    OBJECT_SUPERVISOR = 'objectSupervisor',
    JANITOR = 'janitor',
}

export enum ElevatorStateName {
    TECHNICIAN = 'TECHNICIAN',
    CABINLIGHT = 'CABINLIGHT',
    OTHER = 'OTHER',
    TRIPPROFILE = 'TRIPPROFILE',
    IOTDEVICE = 'IOTDEVICE',
}

export enum ElevatorSubStateName {
    TECHNICIAN_ONSITE = 'TECHNICIAN_ONSITE',
    TECHNICIAN_NOTONSITE = 'TECHNICIAN_NOTONSITE',
    CABINLIGHT_SENSOR_NOT_INSTALLABLE = 'CABINLIGHT_SENSOR_NOT_INSTALLABLE',
    CABINLIGHT_OFF = 'CABINLIGHT_OFF',
    CABINLIGHT_LOW = 'CABINLIGHT_LOW',
    TRIPPROFILE_ISSUE = 'TRIPPROFILE_ISSUE',
    TRIPPROFILE_BREAKDOWNEMERGENCYSTOP = 'TRIPPROFILE_BREAKDOWNEMERGENCYSTOP',
    TRIPPROFILE_TEMPORARYEMERGENCYSTOPS = 'TRIPPROFILE_TEMPORARYEMERGENCYSTOPS',
    IOTDEVICE_BLACKOUT = 'IOTDEVICE_BLACKOUT',
    IOTDEVICE_OFFLINE = 'IOTDEVICE_OFFLINE',
}

export enum ElevatorTotalTripsAggregation {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum MeasureName {
    FLOOR = 'floor',
}

export interface Elevator {
    id: string;
    factoryNumber: number;
    maintenancePerYear: number;
    recommendedMaintenancePerYear: number;
    owner: string;
    recommendationForMaintenanceInterval: string;
    internalCustomerName: string;
    maintenanceContractPresent: boolean;
    propertyUnit: {
        businessUnit: string;
        street: string;
        postalCode: string;
        city: string;
    };
    devices: IoTDevice[];
    contracts: Contract[];
    state: {
        elevatorId: Elevator['id'];
        state: ElevatorState;
        createdAt: string;
        subStates?: ElevatorSubStatesKeyValue[];
    };
}

export interface ElevatorGroup {
    // TODO: Change after coordinates are in the Elevator type
    propertyUnit: Elevator['propertyUnit'] &
        google.maps.LatLngLiteral & {
            address: string;
        };
    elevators: Elevator[];
}
export interface CurrentFloor {
    floor: number;
    time: string;
}

export interface ElevatorDetail extends Elevator {
    manufacturer: string;
    driveType: string;
    type: string;
    manufactureYear: string;
    modernizationDate: string;
    modernizationDegree: string;
    stops: number;
    doorDrives: number;
    maxPayload: number;
    iotLiveSince: string;
    portalUrl: string;
    contacts: Contact[];
    currentFloor: CurrentFloor;
    floorMapping: FloorMapping[];
    alarmDeviceManufacturer: string;
    alarmDeviceModel: string;
    brakeCatchingDeviceManufacturer: string;
    brakeCatchingDeviceModel: string;
    cabinDoorDriveManufacturer: string;
    cabinDoorDriveModel: string;
    carBufferManufacturer: string;
    carBufferModel: string;
    carDoorWidth: string;
    carDoorHeight: string;
    controlGroup: string;
    controlManufacturer: string;
    controlModel: string;
    counterWeightBufferManufacturer: string;
    counterWeightBufferModel: string;
    diameterRopes: string;
    diameterOverspeedGovernorRope: string;
    enginePosition: string;
    installationRegulation: string;
    frequencyInverterManufacturer: string;
    frequencyInverterModel: string;
    liftHeight: number;
    liftMachineManufacturer: string;
    liftMachineModel: string;
    lowerCrossingPath: string;
    lowerHeightProtectiveArea: string;
    overspeedGovernorManufacturer: string;
    overspeedGovernorModel: string;
    ropeRatio: string;
    shaftCopyingManufacturer: string;
    shaftCopyingModel: string;
    shaftDoorManufacturer: string;
    shaftDoorModel: string;
    speed: string;
    staticHead: string;
    upperCrossingPath: string;
    upperHeightProtectiveArea: string;
    shaftTemperature: number;
}

export interface FloorMapping {
    floor: number;
    label: string;
}

export interface ElevatorFloorHistory {
    measure_name: MeasureName;
    measure_value: string;
    time: string;
}

export type ElevatorSubStatesKeyValue = Record<ElevatorSubStateName, ElevatorSubStates[]>;

export interface ElevatorSubStates {
    name: ElevatorSubStateName;
    state: number;
    createdAt: string;
    timestamp: number;
    level?: number;
    probability?: number;
}

export enum ElevatorMaintenanceField {
    FACTORY_NUMBER = 'factoryNumber',
    RECOMMENDED_MAINTENANCE = 'recommendedMaintenancePerYear',
    MAINTENANCE_PER_YEAR = 'maintenancePerYear',
    SIMPLIFA_RECOMMENDATION = 'recommendationForMaintenanceInterval',
}
