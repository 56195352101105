import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './SupportRequestPage.rules';
import { SubjectInput } from '../SubjectInput';
import { SupportSubjectsType } from 'modules/entities/modules/support';
import { SupportButtons } from '../SupportButtons';
import { DescriptionInput } from '../DescriptionInput';
import { useSupportRequest } from 'modules/entities/modules/support/hooks/useSupportRequest';
import { useNotifications } from 'modules/ui';
import { TitleInput } from '../TitleInput';
import { Typography } from 'antd';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';
import config from 'config';
import { DialogState, SupportDialogType } from 'constants/common';
import { useGlobalDialog } from 'hooks/useGlobalDialog';

export interface SupportTableProps {
    subject: SupportSubjectsType;
    setSubject: (s: any) => void;
    title: string;
    setTitle: (string) => void;
    description: string;
    setDescription: (string) => void;
}

const titleMessages = {
    [SupportDialogType.SUPPORT]: 'support.request.title',
    [SupportDialogType.FEEDBACK]: 'support.feedback.title',
};

const descriptionMessages = {
    [SupportDialogType.SUPPORT]: 'support.request.description',
    [SupportDialogType.FEEDBACK]: 'support.feedback.description',
};

const errorTitleMessages = {
    [SupportDialogType.SUPPORT]: 'support.request.error.title',
    [SupportDialogType.FEEDBACK]: 'support.feedback.error.main',
};

const errorDescriptionMessages = {
    [SupportDialogType.SUPPORT]: 'support.request.error.description',
    [SupportDialogType.FEEDBACK]: 'support.feedback.error.description',
};

export const SupportRequestPage = ({
    subject,
    setSubject,
    title,
    setTitle,
    setDescription,
    description,
}: SupportTableProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const history = useHistory();
    const notification = useNotifications();
    const { navigate } = useContext(GlobalDialogContext);
    const { state } = useGlobalDialog();
    const { api, sendSupportRequest, resetRequest } = useSupportRequest();
    const { pathname } = useLocation();
    const [showCategory, setShowCategory] = useState(true);
    const [showTitle, setShowTitle] = useState(true);
    

    const { meta } = useContext(GlobalDialogContext);

    const intl = useIntl();

    const handleOnCloseModal = useCallback(() => {
        navigate(null);
        setTitle(null);
        setDescription(null);
        setSubject(null);
    }, [navigate, setDescription, setTitle, setSubject]);

    useEffect(() => {
        if (meta?.type === SupportDialogType.FEEDBACK) {
            setShowCategory(false);
            setShowTitle(false);
            setSubject('Feedback zum Elevator HUB (Portal)');
            setTitle('User Feedback from HUB Button');
        } else if (state === DialogState.CREATE_SUPPORT) {
            setShowCategory(true);
            setShowTitle(true);
        }
    }, [meta, setSubject, setShowTitle, setShowCategory, setTitle, state]);

    useEffect(() => {
        let main = 'support.request.success.title';
        let description = 'support.request.success.description';
        if (meta?.type === SupportDialogType.FEEDBACK) {
            description = 'support.feedback.success.description';
            main = 'support.feedback.success.main';
        }
        if (api.lastSuccessAt) {
            notification.success({
                message: intl.formatMessage({
                    id: main,
                }),
                description: intl.formatMessage({
                    id: description,
                }),
            });
            if (pathname === config.routes.supportClosed) {
                history.push(config.routes.supportOpen);
            };
            handleOnCloseModal();
            resetRequest(subject);
            if (pathname === config.routes.supportClosed) {
                history.push(config.routes.supportOpen);
            }
        }
    }, [api.lastSuccessAt, notification, intl, history, resetRequest, subject, handleOnCloseModal, pathname, meta]);

    useEffect(() => {
        const errorTitle = errorTitleMessages[meta?.type] || 'support.request.error.title';
        const errorDescription = errorDescriptionMessages[meta?.type] || 'support.request.error.description';

        if (api.error) {
            notification.error({
                message: intl.formatMessage({ id: errorTitle }),
                description: intl.formatMessage({ id: errorDescription }),
            });
            resetRequest(subject);
        }
    }, [api.error, notification, intl, meta, resetRequest, subject]);
    return (
        <div>
            <div className={styles.header}>
                <Typography.Title level={2} className={styles.title}>
                    {titleMessages[meta?.type] && (
                        <FormattedMessage id={titleMessages[meta?.type] || 'support.request.title'} />
                    )}
                </Typography.Title>
            </div>

            <div>
                <Typography.Paragraph className={styles.description}>
                    {descriptionMessages[meta?.type] && (
                        <FormattedMessage id={descriptionMessages[meta?.type] || 'support.request.description'} />
                    )}
                </Typography.Paragraph>
            </div>

            {!!showCategory && state === DialogState.CREATE_SUPPORT && (
                <SubjectInput value={subject} onChange={setSubject} disabled={api.inProgress} />
            )}
            {!!showTitle && state === DialogState.CREATE_SUPPORT && (
                <TitleInput
                    value={title}
                    onChange={({ target }) => {
                        setTitle(target.value);
                    }}
                    disabled={api.inProgress}
                />
            )}
            <div>
                <DescriptionInput
                    value={description}
                    onChange={setDescription}
                    type={
                        !showCategory && state === DialogState.CREATE_SUPPORT
                            ? SupportDialogType.FEEDBACK
                            : state === DialogState.CREATE_SUPPORT
                            ? SupportDialogType.SUPPORT
                            : null
                    }
                    disabled={api.inProgress}
                />
            </div>
            <div className={styles.buttons}>
                <SupportButtons
                    loading={api.inProgress}
                    type={
                        !showCategory && state === DialogState.CREATE_SUPPORT
                            ? SupportDialogType.FEEDBACK
                            : state === DialogState.CREATE_SUPPORT
                            ? SupportDialogType.SUPPORT
                            : SupportDialogType.SUPPORT
                    }
                    disabled={!subject || !description || !title}
                    onCancel={() => {
                        navigate(null);
                        setTitle(null);
                        setDescription(null);
                        setSubject(null);
                    }}
                    onSubmit={() => {
                        sendSupportRequest({
                            description,
                            subject,
                            title,
                        });
                    }}
                />
            </div>
        </div>
    );
};
