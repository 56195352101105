import { combineReducers } from 'redux';

import { ApiReducerKey } from 'constants/index';

import list from './list';
import single from './single';
import active from './active';
import resolved from './resolved';
import picklist from './picklist';
import rejected from './rejected';
import unvalidated from './unvalidated';
export default combineReducers({
    [ApiReducerKey.LIST]: list,
    [ApiReducerKey.SINGLE]: single,
    [ApiReducerKey.ACTIVE]: active,
    [ApiReducerKey.RESOLVED]: resolved,
    [ApiReducerKey.PICKLIST]: picklist,
    [ApiReducerKey.UNVALIDATED]: unvalidated,
    [ApiReducerKey.REJECTED]: rejected,
});
