import React from 'react';
import { Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './SupportButtons.rules';
import { Icon, IconType } from 'modules/ui';
import { SupportDialogType } from 'constants/common';

export interface GeneralUploadButtonsProps {
    loading: boolean;
    disabled: boolean;
    type?: SupportDialogType;
    onSubmit: () => void;
    onCancel: () => void;
}

const cancel = {
    [SupportDialogType.SUPPORT]: 'support.button.cancel',
    [SupportDialogType.FEEDBACK]: 'support.feedback.button.cancel',
};

const submit = {
    [SupportDialogType.SUPPORT]: 'support.button.submit',
    [SupportDialogType.FEEDBACK]: 'support.feedback.button.submit',
};

export const SupportButtons = ({
    disabled,
    loading,
    onSubmit,
    onCancel,
    type = SupportDialogType.SUPPORT,
}: GeneralUploadButtonsProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();

    return (
        <div className={styles.container}>
            {type !== SupportDialogType.FEEDBACK && (
                <div className={styles.info}>
                    <Icon type={IconType.INFO} /> <FormattedMessage id="support.request.info" />
                </div>
            )}
            <Button size="large" onClick={onCancel}>
                <FormattedMessage id={cancel[type]} />
            </Button>
            {!!disabled ? (
                <Tooltip
                    placement="topLeft"
                    title={intl.formatMessage({
                        id: 'support.request.validation',
                    })}
                    arrowPointAtCenter
                >
                    <Button disabled={disabled} loading={loading} type="primary" size="large" onClick={onSubmit}>
                        <FormattedMessage id={submit[type]} />
                    </Button>
                </Tooltip>
            ) : (
                <Button disabled={disabled || loading} loading={loading} type="primary" size="large" onClick={onSubmit}>
                    <FormattedMessage id={submit[type]} />
                </Button>
            )}
        </div>
    );
};
