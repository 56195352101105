import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from 'antd';
import { useFelaEnhanced } from 'hooks';
import { generatePath, Link } from 'react-router-dom';

import { ErrorTypes } from 'modules/incident-new/types';
import { Icon, IconType } from 'modules/ui';
import * as felaRules from './NewIncidentAlert.rules';
import config from 'config';

export interface NewIncidentAlertProps {
    type: ErrorTypes;
    onClose: () => void;
    setErrorType: (value: ErrorTypes) => void;
}
export const NewIncidentAlert = ({ type, onClose, setErrorType }: NewIncidentAlertProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();

    const renderMessage = () => {
        if (type === ErrorTypes.NO_CONTRACT)
            return (
                <div>
                    <FormattedMessage
                        id="incident.new.dialog.contract.error"
                        values={{
                            link: (chunks: any) => (
                                <Link to={generatePath(config.routes.documentsUpload)} onClick={onClose}>
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </div>
            );

        if (type === ErrorTypes.SEVERITY) return <div>{formatMessage({ id: 'incident.create.failed.severity' })}</div>;
    };

    return (
        <div className={styles.container}>
            <Alert
                type="error"
                showIcon
                closable
                onClose={() => setErrorType(null)}
                message={renderMessage()}
                icon={<Icon type={IconType.AlertWarning} />}
            />
        </div>
    );
};
