export type NewUsers = Array<{
    email: string;
    role: string;
}>;

export const CHANGE_ROLE_STATE = 'teams/change-role';
export const DEACTIVATE_USER_STATE = 'teams/deactivate-user';
export const EDIT_NOTIFICATION_STATE = 'teams/edit-notification-user';
export const INVITE_USERS_STATE = 'teams/invite-users';
export const USER_STATE = 'teams/user';
export const INVITE_USERS_SELECTOR_ID = 'invite-users';
export const CHANGE_ROLE_SELECTOR_ID = 'change-role';
export const EDIT_NOTIFICATION_SELECTOR_ID = 'edit-notification';
export const DEACTIVATE_USER_SELECTOR_ID = 'deactivate-user';

export type Users = Array<{
    id: string;
    photo: string;
    name: string;
    email: string;
    role: string;
    elevators: number;
}>;
