import { ThemeProps, TRuleWithTheme } from 'styles/theme';
import { combineRules, TRule } from 'fela';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'flex-start',

    marginBottom: '1rem',

    ':last-child': {
        marginBottom: 0,
    },
});

export const elevator: TRuleWithTheme = () => ({
    flexBasis: '50%',
});

export const businessUnit: TRuleWithTheme = () => ({
    flexBasis: '50%',
    marginLeft: '1rem',
});

const paragraph: TRule = () => ({
    lineHeight: '1.5rem',
    fontWeight: 500,

    '&.ant-typography': {
        marginBottom: '0.25rem',
    },
});

export const factoryNumber: TRule = combineRules<ThemeProps, ThemeProps>(paragraph, () => ({
    fontWeight: 700,
    textDecorationLine:"underline",
    textDecorationThickness: '0.1rem',
    ':hover': {
        color: '#f57645',
    },
}));

export const street = paragraph;

export const city: TRule = combineRules<ThemeProps, ThemeProps>(paragraph, ({ theme }) => ({
    color: theme.colors.text,

    '&.ant-typography': {
        marginBottom: 0,
    },
}));
