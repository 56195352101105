import { IncidentStatus } from 'modules/entities/modules/incidents';
import { TRule } from 'fela';
import { Theme, TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const incidentButton: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& .ant-btn': {
        margin: '0px',
        height: '1.375rem',
        fontSize: '0.75rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1px 6px',
        gap: '0.125rem',
        flexDirection: 'row',
    },
});

export const address: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '500',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.colors.textSecondary,
});
export const factoryNumber: TRuleWithTheme = () => ({
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
});
export const severity: TRuleWithTheme = () => ({
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    marginBottom: '0.25rem',
});
export const severityOpen: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    marginBottom: '0.25rem',
    color: theme.colors.textSecondary,
});

export const dataCard: TRuleWithTheme = () => ({
    borderRadius: 0,
    borderRight: 0,
    borderLeft: 0,
    '& .ant-card-body': {
        padding: '16px',
    },
});

export const validationContainer: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: '0rem !important',
    // marginTop: '1.33rem',
});

export const iconContainer: TRule = () => ({
    height: '1.50rem',
    marginRight: '0.58rem',
    // lineHeight: n
    '& .anticon': {
        '& svg': {
            width: '1.28rem !important',
        },
    },
});

export const validationText: TRuleWithTheme = ({ theme }) => ({
    fontSize: '.88rem',
    color: theme.colors.primary,
    fontWeight: 600,
});
export const buttonColor: TRule<{ activeIncidentProps?: any; theme: Theme }> = ({ theme, activeIncidentProps }) => {
    let backgroundColor;
    let borderColor;
    let fontSize;
    switch (activeIncidentProps?.case?.status) {
        case IncidentStatus.Neu:
            backgroundColor = theme.colors.bgStatusWarning;
            borderColor = theme.colors.outlineStatusWarning;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Reparatur:
            backgroundColor = theme.colors.bgStatusHighAlert;
            borderColor = theme.colors.outlineStatusHighAlert;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Störungsbeseitigung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Entstörung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Erledigt:
            backgroundColor = theme.colors.bgStatusSuccess;
            borderColor = theme.colors.outlineStatusSuccess;
            fontSize = '0.75rem';
            break;
        default:
            backgroundColor = theme.colors.white;
            borderColor = theme.colors.white;
    }

    return { backgroundColor, borderColor, fontSize, '&:hover': { backgroundColor: backgroundColor } };
};
