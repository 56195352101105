export enum UrlParam {
    PAGE = 'page',
    FILTER = 'filter',
    SORT = 'sort',
    SEARCH = 'search',
    TEMPORAl_PASSWORD = 'confirmation',
    USER_EMAIL = 'email',
    PAGE_TOKEN_KEY = 'pageToken',
    TAB = 'tab',
    CODE = 'code',
    PAGE_SIZE = 'page_size',
}

export const URL_ARRAY_SEPARATOR = '|';
