import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { ParagraphProps as AntParagraphProps } from 'antd/es/typography/Paragraph';

import * as felaRules from './Paragraph.styles';
import { Typography } from 'antd';
import { RulesExtend } from '../../../../styles/theme';

interface ParagraphProps extends AntParagraphProps {
    size?: 'xsmall' | 'small' | 'medium' | 'large';
    extend?: RulesExtend<typeof felaRules>;
    faded?: boolean;
    noMargin?: boolean;
}

const Paragraph: FC<ParagraphProps> = ({ size = 'medium', children, extend, noMargin = false, faded }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, size, faded, noMargin });
    return <Typography.Paragraph className={styles.paragraph}>{children}</Typography.Paragraph>;
};

export default Paragraph;
