import type { TRuleWithTheme } from 'styles/theme';

export const modal: TRuleWithTheme = () => ({
    '& .ant-modal-content': {
        padding: '1.5rem',
    },

    '& .ant-modal-header': {
        padding: 0,

        borderBottom: 'none',
    },

    '& .ant-modal-body': {
        padding: 0,
    },

    '& .ant-modal-footer': {
        padding: 0,

        borderTop: 'none',
    },

    '& .ant-modal-close-x': {
        width: '2.5rem',
        height: '2.5rem',

        lineHeight: '2.5rem',
    },
});

export const closeIcon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.iconActive,
});
