import React from 'react';

const ChevronRight = () => {
    return (
        <svg width="12px" height="22px" viewBox="0 0 12 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="elevator_detail" transform="translate(-1269.000000, -506.000000)" fill="currentColor">
                    <g
                        id="ic/24/back-copy"
                        transform="translate(1275.000000, 517.000000) rotate(-180.000000) translate(-1275.000000, -517.000000) translate(1269.000000, 506.000000)"
                    >
                        <path
                            d="M10.293,21.4141 L0.293,11.4141 C-0.098,11.0231 -0.098,10.3911 0.293,10.0001 L10.293,0.0001 L11.707,1.4141 L2.414,10.7071 L11.707,20.0001 L10.293,21.4141 Z"
                            id="Fill-1"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ChevronRight;
