import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.INCIDENTS,
});

export const fetchIncidentsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_INCIDENTS_' }),
});

export const fetchIncidentTypes = strictObjectAccess({ ...apiRequestType({ typePrefix: 'FETCH_INCIDENT_' }) });
