import React from 'react';
import { useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { Divider } from 'modules/ui';
import { TableFilterPanel } from 'modules/table';
import { useQuotations } from 'modules/entities/modules/documents-quotations';

import { QuotationsTable } from './QuotationsTable';
import { QuotationsActionPanel } from './QuotationsActionPanel';
import { QuotationsSearch } from './QuotationsSearch';
import { QuotationsStatusFilter } from './QuotationsStatusFilter';
import { useRouteMatch } from 'react-router-dom';

export const QuotationsTab = () => {
    const intl = useIntl();
    const { loading, documents, defaultSearch } = useQuotations();
    const match = useRouteMatch('/elevators/:id');
    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'documents.quotations.title' })} />
            <TableFilterPanel>
                <QuotationsStatusFilter />
                <Divider />
                <QuotationsSearch defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            </TableFilterPanel>
            <QuotationsActionPanel documents={documents} />
            <QuotationsTable loading={loading} documents={documents} />
        </>
    );
};
