import type { TRule } from 'fela';
import { Theme } from 'styles/theme';
import { StatusColor } from '../../../types';

export const icon: TRule<{ theme: Theme; color: StatusColor }> = ({ theme, color }) => {
    let finalColor;
    switch (color) {
        case StatusColor.SUCCESS:
            finalColor = theme.colors.statusSuccess;
            break;
        case StatusColor.WARNING:
            finalColor = theme.colors.statusWarning;
            break;
        case StatusColor.LOW_ALERT:
            finalColor = theme.colors.statusLowAlert;
            break;
        case StatusColor.HIGH_ALERT:
            finalColor = theme.colors.statusHighAlert;
            break;
        case StatusColor.INACTIVE:
            finalColor = theme.colors.statusInactive;
            break;
        case StatusColor.INFO:
            finalColor = theme.colors.statusInfo;
            break;
        default:
            finalColor = 'inherit';
            break;
    }

    return { color: finalColor };
};
