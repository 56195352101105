import type { TRuleWithTheme } from 'styles/theme';

export const tableContainer: TRuleWithTheme = () => ({
    '& .ant-table-cell': {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    '& Table': {
        borderCollapse: 'separate',
    },
    '& .ant-table': {
        backgroundColor: 'transparent',
        
    },
    '& .ant-pagination': {
        paddingTop: '2rem !important',
    },
    '& .ant-card': {
        backgroundColor: 'transparent',
    },
    '& tr::after': {
        height: '0px !important',
    },
    '& .ant-table-tbody > tr:hover': {
        backgroundColor: 'transparent !important',
    },
    '& .ant-table-tbody > td:hover': {
        backgroundColor: 'transparent !important',
    },
    '& .ant-table-tbody > tr.ant-table-row:hover > td': {
        backgroundColor: 'transparent !important',
    },
});
