import React, { FC, ReactElement, ReactNode, ReactText } from 'react';
import { FormattedNumber } from 'react-intl';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './DataSpace.styles';

type DataSpaceProps = {
    name: string | ReactNode;
    labelFor?: string;
    data?: string | number | ReactText | ReactElement;
    extend?: RulesExtend<typeof felaRules>;
    testId?: string;
    isDataText?: boolean;
    isDataBold?: boolean;
};

const DataSpace: FC<DataSpaceProps> = ({ name, labelFor, data, extend, testId, isDataText = true , isDataBold = true,}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, isDataBold });

    const Label = labelFor ? 'label' : 'span';

    return (
        <div className={styles.container}>
            <Label htmlFor={labelFor} className={styles.name}>
                {name}
            </Label>
            {isDataText ? (
                <p className={styles.data} data-test-id={testId}>
                    {typeof data === 'number' ? <FormattedNumber value={data} /> : data ?? '-'}
                </p>
            ) : (
                <div className={styles.data}>{data}</div>
            )}
        </div>
    );
};

export default DataSpace;
