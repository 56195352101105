import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
});
export const name: TRuleWithTheme = ({ theme }) => ({
    fontWeight: 'bold',
    lineHeight: '1.5rem',
});
export const type: TRuleWithTheme = ({ theme }) => ({
    marginTop: '.25rem',
    lineHeight: '1.5rem',
});
