import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import moment from 'moment';
import { useFelaEnhanced } from 'hooks';

import config from 'config/config';

import { ElevatorStatusIcon } from 'modules/elevators';
import { Elevator, ElevatorState, getPropertyUnitAdress } from 'modules/entities/modules/elevators';
import { Icon, IconType } from 'modules/ui';

import { InfoWindowTag } from '../InfoWindowTag';

import * as felaRules from './InfoWindow.rules';
import { EXPECTED_MATURED_DAYS } from 'constants/elevator';

export interface InfoWindowProps {
    elevators: Elevator[];
}

export const InfoWindow = ({ elevators }: InfoWindowProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <FormattedMessage id="elevators.map.info-window.title" />
            </div>
            {elevators.map(elevator => {
                const { id, propertyUnit, state } = elevator;
                const address = getPropertyUnitAdress(propertyUnit);
                let isInLearningPhase = false;
                if (elevator?.devices && elevator?.devices[0] && elevator?.devices[0].installation && state?.state) {
                    const dateDiff = elevator?.devices[0]?.installation
                        ? moment().diff(moment(elevator.devices[0].installation), 'days')
                        : 0;
                        
                    if (!!elevator?.devices[0]?.installation && dateDiff < EXPECTED_MATURED_DAYS) {
                        state.state = ElevatorState.UNKNOWN;
                        isInLearningPhase = true;
                    }
                }

                return (
                    <Link
                        to={generatePath(config.routes.elevatorDetail, { id })}
                        className={styles.row}
                        key={elevator.id}
                    >
                        <ElevatorStatusIcon state={elevator.state?.state} />
                        <div className={styles.status} />
                        <div className={styles.elevatorInfo}>
                            <div className={styles.id}>{elevator.factoryNumber}</div>
                            <div className={styles.owner}>{elevator.owner}</div>
                            <div className={styles.address}>{address}</div>
                            <div>
                                <InfoWindowTag state={state} isInLearningPhase={isInLearningPhase} />
                            </div>
                        </div>
                        <Icon type={IconType.CHEVRON_RIGHT} />
                    </Link>
                );
            })}
        </div>
    );
};
