import React from 'react';

const ChevronDown = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" fill="currentColor" {...props}>
            <path
                d="M5.998 7a1 1 0 01-.708-.293L0 1.424 1.405 0l4.593 4.586L10.59.001 12 1.42 6.706 6.707A1 1 0 015.998 7"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default ChevronDown;
