import type { TRuleWithTheme } from 'styles/theme';

export const table: TRuleWithTheme = () => ({
    '& .ant-table.ant-table-middle .ant-table-tbody > tr': {
        '& > td:first-of-type': { width: '8%' },
        '& > td:last-of-type': { textAlign: 'right' },
    },
    '& .ant-table.ant-table-middle .ant-table-thead > tr': {
        '& > th:first-of-type': { width: '8%' },
        '& > th:last-of-type': { width: '5%' },
    },

    '& .ant-table.ant-table-middle .ant-table-thead > tr > .owner': {
         minWidth: '200px'
    },
    '& .ant-table.ant-table-middle .ant-table-thead > tr > .hardware': {
        minWidth: '270px'
   },
});

export const hardwareContainer: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const hardwareActive: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
export const hardwareDisabled: TRuleWithTheme = ({ theme }) => ({
    color: '#CBCBCB',
    minWidth: '7.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const hardwareCheckBox: TRuleWithTheme = ({ theme }) => ({
    marginLeft: '0.38rem',
    marginRight: '1.29rem',

    display: 'flex',
    alignItems: 'center',
});
