import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Result from 'antd/es/result';
import Button from 'antd/es/button';

import config from 'config/config';

import * as felaRules from './NotFound.rules';
import { useFelaEnhanced } from 'hooks';

function NotFound() {
    const { styles } = useFelaEnhanced(felaRules);

    const history = useHistory();

    return (
        <Result
            status="404"
            title={<FormattedMessage id="not-found.title" />}
            subTitle={<FormattedMessage id="not-found.subtitle" />}
            extra={
                <Button
                    type="primary"
                    onClick={() => {
                        history.push(config.routes.home);
                    }}
                >
                    <FormattedMessage id="not-found.link" />
                </Button>
            }
            className={styles.container}
        />
    );
}

export default NotFound;
