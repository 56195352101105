import { createSelector } from 'reselect';
import { entitiesSelector, apiSelector } from '@ackee/petrus';

import { PetrusState, User } from '../../types';

import type { AuthState } from '../reducers';
import { EntityKey } from 'constants/index';

export const authStateSelector = (state: any): AuthState => state.auth;

export const authUserSelector = createSelector(entitiesSelector, (auth: PetrusState): User => auth.user);

export const userEmailSelector = createSelector(authUserSelector, user => (user ? user.email : ''));

export const logoutApiSelector = state => apiSelector(state, 'logout');

export const loginAuthenticatorSelector = (state): any => state.auth[EntityKey.LOGIN];

export const registrationStatusSelector = createSelector(
    [authStateSelector],
    ({ registration }) => registration.status,
);
