import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { CheckboxProps } from 'antd';
import { FormattedMessage } from 'react-intl';

import { InvoiceState } from 'modules/entities/modules/documents-invoices';
import { ButtonCheckbox, Icon, IconType } from 'modules/ui';
import { StatusIcon, Tables } from 'modules/table';
import * as felaRules from './StatusCheckbox.styles';

interface StatusCheckboxProps extends CheckboxProps {
    state: InvoiceState;
    count?: number;
}

const StatusCheckbox: FC<StatusCheckboxProps> = ({ state, count, ...rest }) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <ButtonCheckbox value={state} count={count} extend={{ checkbox: rules.checkbox }} {...rest}>
            {state === InvoiceState.SHOW_ALL ? (
                <Icon type={IconType.ELEVATOR} className={styles.icon} />
            ) : (
                <StatusIcon table={Tables.INVOICES} state={state} size={StatusIcon.Size.SMALL} />
            )}
            <FormattedMessage id={`documents.table.status.${state}`} />{' '}
        </ButtonCheckbox>
    );
};

export default StatusCheckbox;
