import React, { FC } from 'react';
import { Row, Col, Divider } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import { Button, DataCard } from 'modules/ui';
import NoActiveIncident from 'modules/active-incidents/components/NoActiveIncidents';
import { Incident } from 'modules/entities/modules/incidents';
import { errorMessages, HTTPStatusCode, NotFound } from 'modules/errors';
import { InfoIcon } from 'modules/maintenance/InfoIcon';
import config from 'config';

import * as felaRules from './ActiveIncidentWidget.styles';

interface ActiveIncidentWidgetProps {
    inProgress: boolean;
    error: any;
    activeIncident: Incident[];
}

const ActiveIncidentWidget: FC<ActiveIncidentWidgetProps> = ({ inProgress, error, activeIncident }) => {
    const [activeIncidentProps] = activeIncident;
    const { styles } = useFelaEnhanced(felaRules, { activeIncidentProps });

    const validationStatus = activeIncidentProps?.case?.validationStatus;

    if (error === errorMessages.status[HTTPStatusCode.NotFound]) {
        return <NotFound />;
    }

    const hasActiveIncident = activeIncident?.length > 0 && activeIncidentProps?.case;

    const linkTo =
        activeIncident && hasActiveIncident
            ? `${config.routes.incidents}?search=factoryNumber%3A${activeIncidentProps?.factoryNumber}&page=1`
            : '';

    return (
        <>
            {activeIncident && hasActiveIncident ? (
                <Link to={linkTo}>
                    <DataCard
                        customEmptyCard={!hasActiveIncident && <NoActiveIncident size="small" />}
                        error={error}
                        empty={!hasActiveIncident}
                        loading={inProgress}
                        className={styles.dataCard}
                        onClick={() => {}}
                    >
                        {hasActiveIncident && (
                            <Row>
                                <Col span={12}>
                                    <div
                                        className={validationStatus !== 'Open' ? styles.severity : styles.severityOpen}
                                    >
                                        <FormattedMessage
                                            id={`page.home.activeIncident.state.severity.${activeIncidentProps?.case?.severity}`}
                                        />
                                    </div>
                                    <div className={styles.address}>
                                        {moment(activeIncidentProps?.case?.dateTimeOpened).format('DD.MM.YYYY')}
                                    </div>
                                    <div className={styles.address}>
                                        {moment(activeIncidentProps?.case?.dateTimeOpened).format('HH:mm')}
                                    </div>
                                </Col>
                                <Divider />
                                <Col className={styles.incidentButton} span={12}>
                                    {validationStatus !== 'Open' ? (
                                        <Button type="text" className={styles.buttonColor}>
                                            <FormattedMessage
                                                id={`page.home.activeIncident.state.status.${activeIncidentProps?.case?.status}`}
                                            />
                                        </Button>
                                    ) : (
                                        <div className={styles.validationContainer}>
                                            <InfoIcon className={styles.iconContainer} />
                                            <div className={styles.validationText}>
                                                <FormattedMessage id={`page.home.activeIncident.state.invalidation`} />
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </DataCard>
                </Link>
            ) : (
                <DataCard
                    customEmptyCard={!hasActiveIncident && <NoActiveIncident size="small" />}
                    error={error}
                    empty={!hasActiveIncident}
                    loading={inProgress}
                    className={styles.dataCard}
                    onClick={() => {}}
                >
                    {hasActiveIncident && (
                        <Row>
                            <Col span={12}>
                                <div className={validationStatus !== 'Open' ? styles.severity : styles.severityOpen}>
                                    <FormattedMessage
                                        id={`page.home.activeIncident.state.severity.${activeIncidentProps?.case?.severity}`}
                                    />
                                </div>
                                <div className={styles.address}>
                                    {moment(activeIncidentProps?.case?.dateTimeOpened).format('DD.MM.YYYY')}
                                </div>
                                <div className={styles.address}>
                                    {moment(activeIncidentProps?.case?.dateTimeOpened).format('HH:mm')}
                                </div>
                            </Col>
                            <Divider />
                            <Col className={styles.incidentButton} span={12}>
                                {validationStatus !== 'Open' ? (
                                    <Button type="text" className={styles.buttonColor}>
                                        <FormattedMessage
                                            id={`page.home.activeIncident.state.status.${activeIncidentProps?.case?.status}`}
                                        />
                                    </Button>
                                ) : (
                                    <div className={styles.validationContainer}>
                                        <InfoIcon className={styles.iconContainer} />
                                        <div className={styles.validationText}>
                                            <FormattedMessage id={`page.home.activeIncident.state.invalidation`} />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                </DataCard>
            )}
        </>
    );
};

export default ActiveIncidentWidget;
