import React from 'react';

const GreenCheck = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="currentColor">
            <path
                d="M4.71 7.3L1.42 4L0 5.42L4 9.42C4.09296 9.51373 4.20356 9.58812 4.32542 9.63889C4.44728 9.68966 4.57799 9.7158 4.71 9.7158C4.84201 9.7158 4.97272 9.68966 5.09458 9.63889C5.21644 9.58812 5.32704 9.51373 5.42 9.42L13.42 1.42L12 0L4.71 7.3Z"
                fill="#01BE19"
            />
        </svg>
    );
};

export default GreenCheck;
