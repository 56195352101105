import type { FC } from 'react';
import { Text } from 'recharts';
import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './YAxisLabel.styles';

type YAxisLabelProps = {
    textId: string;
    dx?: number;
    dy?: number;
};

const YAxisLabel: FC<YAxisLabelProps> = ({ textId, dx = 0, dy = 0 }) => {
    const { styles, theme } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();
    return (
        <Text
            x={0}
            y={0}
            dx={dx}
            dy={dy}
            textAnchor="start"
            width={180}
            className={styles.label}
            fill={theme.colors.textSecondary}
        >
            {formatMessage({ id: textId })}
        </Text>
    );
};

export default YAxisLabel;
