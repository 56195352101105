import { TRuleWithTheme } from 'styles/theme';

export const overlay: TRuleWithTheme = ({ theme }) => ({
    '& .ant-popover-inner': {
        boxShadow: theme.shadows.dropdownMenu,
    },

    '& .ant-popover-inner-content': {
        padding: 0,
    },

    '& .ant-popover-arrow': {
        display: 'none',
    },
});
