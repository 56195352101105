import React, { FC, ReactNode } from 'react';
import { Spin as AntdSpin } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './Loader.styles';

interface Props {
    show: boolean;
    title?: ReactNode;
    extend?: RulesExtend<typeof felaRules>;
}

const Loader: FC<Props> = ({ extend, title, children = null, show }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return show ? (
        <div className={styles.loader}>
            <AntdSpin />
            {title && <p className={styles.text}>{title}</p>}
        </div>
    ) : (
        <>{children}</>
    );
};

export default Loader;
