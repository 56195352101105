import React from 'react';

// This component is temporary and will be removed
// TODO: remove me
export const OperatorObligationsTracking = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m35.75 11.017-15.5-8.95a.5.5 0 0 0-.5 0l-15.5 9a.5.5 0 0 0-.25.43v17.9a.5.5 0 0 0 .25.43l15.5 9a.48.48 0 0 0 .5 0l15.5-8.95a.5.5 0 0 0 .25-.43v-18a.5.5 0 0 0-.25-.43Zm-.75 18-15 8.7-15-8.66v-17.32l15-8.66 15 8.66v17.28Z"
            fill="#fff"
        />
        <path
            d="M22.5 15.895c-.28.003-.553.082-.79.23l-2.8-2.23a1.498 1.498 0 0 0-1.739-1.977 1.501 1.501 0 0 0-1.021 2.117l-3 3a1.52 1.52 0 1 0 .71.71l3-3a1.46 1.46 0 0 0 1.43-.08l2.8 2.23a1.633 1.633 0 0 0-.09.49 1.5 1.5 0 1 0 2.85-.64l3-3a1.52 1.52 0 1 0-.71-.71l-3 3c-.2-.093-.419-.14-.64-.14Zm-10 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm4.5-5.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm5.5 4.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm5-6a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1ZM12.5 26.895a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 .5.5Z"
            fill="#fff"
        />
        <path
            d="M18 26.395v-10a.5.5 0 0 0-1 0v10a.5.5 0 0 0 1 0ZM23 26.395v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0ZM27.5 14.895a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-.5-.5ZM27.5 27.895h-15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1Z"
            fill="#fff"
        />
    </svg>
);
