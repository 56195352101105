export const forms = {
    updateIncident: 'newIncident',
};

export const VALIDATE_INCIDENT_DIALOG = 'VALIDATE_INCIDENT_DIALOG';
export const INCIDENT_DIALOG_CANCEL = 'INCIDENT_DIALOG_CANCEL';
export const SHOW_VALIDATE_INCIDENT_DIALOG = 'show';
export const HIDE_VALIDATE_INCIDENT_DIALOG = 'hide';
export const CANCELLATION_ACTION = 'cancellation';
export const CONFIRMATION_ACTION = 'validation';
export const COMPLETION_ACTION = 'completion';
