import { IncidentStatus } from '../../../entities/modules/incidents/types';
import { TRule } from 'fela';
import { Theme, TRuleWithTheme } from 'styles/theme';

export const group: TRuleWithTheme = ({ theme }) => ({});

export const container: TRuleWithTheme = ({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.colors.dividerColor}`,
    },
    paddingTop: '1rem',
    paddingBottom: '0.875rem',
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    // ':hover': {
    //     background: '#FBFBFB',
    // },
});
export const rowStyle: TRuleWithTheme = () => ({
    paddingBottom: '0.125rem',
});
export const button: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0.5rem',
});
export const address: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '500',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.colors.textSecondary,
});
export const factoryNumber: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '501',
    fontSize: '0.875rem',
    color: theme.colors.text,
    lineHeight: 1,
});

export const factoryNumberOpen: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '501',
    fontSize: '0.875rem',
    color: theme.colors.textSecondary,
    lineHeight: 1,
});
export const severity: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '600',
    fontSize: '0.75rem',
    color: theme.colors.text,
    lineHeight: 1,
});

export const severityOpen: TRuleWithTheme = ({ theme }) => ({
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: 1,
    color: theme.colors.textSecondary,
});

export const incidentButton: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // marginTop: '1rem',
    '& .ant-btn': {
        margin: '0px',
        height: '1.375rem',
        fontSize: '0.75rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.063rem 0.375rem',
        gap: '0.125rem',
        flexDirection: 'row',
    },
});
export const buttonColor: TRule<{ status?: any; theme: Theme }> = ({ theme, status }) => {
    let backgroundColor;
    let borderColor;
    let fontSize;
    switch (status) {
        case IncidentStatus.Neu:
            backgroundColor = theme.colors.bgStatusWarning;
            borderColor = theme.colors.outlineStatusWarning;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Reparatur:
            backgroundColor = theme.colors.bgStatusHighAlert;
            borderColor = theme.colors.outlineStatusHighAlert;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Störungsbeseitigung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Entstörung:
            backgroundColor = theme.colors.bgStatusInfo;
            borderColor = theme.colors.outlineStatusInfo;
            fontSize = '0.75rem';
            break;
        case IncidentStatus.Erledigt:
            backgroundColor = theme.colors.bgStatusSuccess;
            borderColor = theme.colors.outlineStatusSuccess;
            fontSize = '0.75rem';
            break;
        default:
            backgroundColor = theme.colors.white;
            borderColor = theme.colors.white;
    }

    return { backgroundColor, borderColor, fontSize, '&:hover': { backgroundColor: backgroundColor } };
};

export const validationContainer: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: '0rem !important',
    // marginTop: '1.33rem',
});

export const iconContainer: TRule = () => ({
    height: '1.50rem',
    marginRight: '0.58rem',
    // lineHeight: n
    '& .anticon': {
        '& svg': {
            width: '1.28rem !important',
        },
    },
});

export const validationText: TRuleWithTheme = ({ theme }) => ({
    fontSize: '.88rem',
    color: theme.colors.primary,
    fontWeight: 600,
});
