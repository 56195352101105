import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    paddingTop: '9.5rem',
    paddingBottom: '12.88rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
});

export const containerSmall: TRuleWithTheme = () => ({
    paddingTop: '3.75rem',
    paddingBottom: '3.75rem',
    alignItems: 'center',

    // justifyContent: 'center',
    marginLeft: '1.13rem',
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: '1rem',
    color: '#262626',
});

export const deactivatedIcon: TRuleWithTheme = ({ theme }) => ({
    height: '28px',
});
export const deactivatedIconSmall: TRuleWithTheme = ({ theme }) => ({
    height: '28px',
    marginRight: '0.94rem',
    // paddingLeft: '10px',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,
});

export const title: TRuleWithTheme = () => ({
    lineHeight: '1.25rem',
    padding: '0.3rem',
    textAlign: 'center',
    '&.ant-typography': {
        marginBottom: '0.1rem',
    },
    marginTop: '10.2px',

    color: '#262626',
    fontSize: '18px',
});

export const titleSmall: TRuleWithTheme = () => ({
    lineHeight: '1.25rem',
    textAlign: 'initial',
    '&.ant-typography': {
        marginBottom: '0.1rem',
    },

    color: '#262626',

    fontSize: '15px',
});
