import React from 'react';
import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import config from 'config';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import type { RegistrationStatus } from '../../types';

import * as felaRules from './RegistrationMessage.rules';

export interface RegistrationMessageProps {
    status: RegistrationStatus;
    extend?: RulesExtend<typeof felaRules>;
}

export const RegistrationMessage = ({ status, extend }: RegistrationMessageProps) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });

    return (
        <div data-testid="registration-success">
            <Typography.Title level={4} className={styles.title}>
                <FormattedMessage id="auth.title.registrationSuccess" />
            </Typography.Title>
            <Typography.Paragraph className={styles.text}>
                <FormattedMessage id={`auth.registrationSuccess.${status}`} />
            </Typography.Paragraph>
            <Link to={config.routes.home}>
                <Button size="large" type="primary" className={styles.button}>
                    <FormattedMessage id="auth.button.registrationSuccess" />
                </Button>
            </Link>
        </div>
    );
};
