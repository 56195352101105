import React, { useMemo } from 'react';
import type { ReactNode } from 'react';

import { URLStatusFilter } from 'modules/table';

export interface DocumentsStatusFilterProps<Status extends string = string> {
    statuses: {
        icon: ReactNode;
        count: number;
        value: Status;
    }[];
}

export function DocumentsStatusFilter<Status extends string = string>({
    statuses,
}: DocumentsStatusFilterProps<Status>) {
    const mappedStatuses = useMemo(
        () =>
            statuses.map(status => ({
                ...status,
                messageId: `documents.table.status.${status.value}`,
            })),
        [statuses],
    );

    return (
        <URLStatusFilter<Status>
            titleMessageId="documents.table.status"
            allMessageId="documents.table.status.show_all"
            statuses={mappedStatuses}
        />
    );
}
