import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';

import * as felaRules from './GeneralUploadButtons.rules';

export interface GeneralUploadButtonsProps {
    loading: boolean;
    disabled: boolean;
    onSubmit: () => void;
}

export const GeneralUploadButtons = ({ disabled, loading, onSubmit }: GeneralUploadButtonsProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const { goBack } = useHistory();

    return (
        <div className={styles.container}>
            <Button
                size="large"
                onClick={() => {
                    goBack();
                }}
            >
                <FormattedMessage id="documents.generalUpload.cancel" />
            </Button>
            <Button disabled={disabled} loading={loading} type="primary" size="large" onClick={onSubmit}>
                <FormattedMessage id="documents.generalUpload.submit" />
            </Button>
        </div>
    );
};
