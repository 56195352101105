import React, { FC, ReactNode } from 'react';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './TableDataSpace.styles';

type InvoiceElevatorBusinessUnitProps = {
    title: ReactNode;
    value: ReactNode | number;
    extend?: RulesExtend<typeof felaRules>;
};

const TableDataSpace: FC<InvoiceElevatorBusinessUnitProps> = ({ title, value, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};

export default TableDataSpace;
