import React, { FC, useEffect, useState } from 'react';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './IncidentReports.styles';
import { ElevatorState, Incident } from 'modules/entities/modules/incidents';
import IncidentReport from '../IncidentReport/IncidentReport';
import { Icon, IconType } from 'modules/ui';
import { FormattedMessage } from 'react-intl';

const IncidentReports: FC<{ incident: Incident }> = ({ incident }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const [showReports, setShowReports] = useState(false);
    const [reports, setReports] = useState<ElevatorState[]>([]);
    useEffect(() => {
        const sortedReports = incident?.case?.elevatorStates?.sort(function (x, y) {
            return new Date(y.timeStamp).valueOf() - new Date(x.timeStamp).valueOf();
        });
        setReports(sortedReports);
    }, [incident]);
    return (
        <div className={styles.container}>
            <div className={styles.cta}>
                <a
                    href="#show"
                    onClick={e => {
                        e?.preventDefault();
                        const k = showReports;
                        setShowReports(!k);
                    }}
                >
                    <FormattedMessage id={`page.incidents.seeReports`} />{' '}
                    <span className={styles.ctaCount}>({reports?.length})</span>
                    <Icon type={showReports ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN} className={styles.ctaIcon} />
                </a>
            </div>
            {!!showReports && reports?.length > 0 && (
                <div className={styles.reportContainer}>
                    <IncidentReport reports={reports} />
                </div>
            )}
        </div>
    );
};

export default IncidentReports;
