import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    marginBottom: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 500,
});

export const report: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    paddingBottom: '1.5rem',
    borderTop: 'solid 1px #EBEBEB',
});
export const header: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    // paddingTop: '1rem',
    // paddingBottom: '1.5rem',
    // borderTop: 'solid 1px #EBEBEB',
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: theme.colors.text,
    },
});

export const cell: TRule = () => ({
    width: '148px',
    marginRight: '2rem',
});
export const cellDescription: TRule = () => ({
    width: '348px',
    marginRight: '2rem',
});
export const cellMedium: TRule = () => ({
    width: '200px',
    marginRight: '2rem',
});
export const body: TRule = () => ({
    marginTop: '1.5rem',
});

export const container: TRule = () => ({
    
});
