import type { TRule } from 'fela';

export const button: TRule = () => ({
    padding: '5px 12px',
    height: 'auto',
    width: 'auto',
});

export const footer: TRule = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 0',
});


export const buttons: TRule = () => ({
    display: 'flex',
    gap: '20px'
});
