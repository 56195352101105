import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '@googlemaps/react-wrapper';

import { MapState } from '../types';
import { MODULE_PREFIX } from '../constants';

const initialState: MapState = {
    initializationStatus: Status.LOADING,
};

const mapSlice = createSlice({
    name: MODULE_PREFIX,
    initialState,
    reducers: {
        mapInitializationStatus(state, action: PayloadAction<Status>) {
            state.initializationStatus = action.payload;
        },
    },
});

export const { mapInitializationStatus } = mapSlice.actions;
export const { reducer } = mapSlice;
