import { useEffect } from 'react';
import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

import { fetchElevatorState as actions } from '../services/actions';
import { selectElevatorStateApi } from '../services/selectors';
import type { Elevator } from '../../elevators';
import { authUserSelector } from 'modules/auth';
// import { DemoAccounts } from 'constants/demoAccounts';

const useFetchElevatorState = (): ApiReducerState => {
    const { id } = useParams<{ id: Elevator['id'] }>();
    const api: ApiReducerState = useSelector(selectElevatorStateApi);
    const user = useSelector(authUserSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchElevatorStateRequest(id));

        return () => {
            batch(() => {
                dispatch(actions.fetchElevatorStateCancel(id));
                dispatch(actions.fetchElevatorStateReset(id));
            });
        };
    }, [dispatch, id, user]);

    return api;
};

export default useFetchElevatorState;
