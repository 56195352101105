import { combineRules } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 699,

    margin: 'auto',
});

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    background: [
        theme.colors.simplifa1,
        `linear-gradient(180deg, ${theme.colors.simplifa1} 0%, ${theme.colors.simplifa2} 71.5%)`,
    ],

    borderRadius: 2,
});

export const message: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        padding: '2rem',

        color: theme.colors.text,
        backgroundColor: theme.colors.white,

        borderRadius: 2,
    },
});

const icon: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '4rem',
    height: '4rem',

    marginBottom: '0.75rem',

    background: [
        theme.colors.simplifa2,
        `linear-gradient(270deg, ${theme.colors.simplifa1} 0%, ${theme.colors.simplifa2} 100%)`,
    ],
    color: theme.colors.white,

    borderRadius: '2rem',
});

export const costSave = combineRules(icon, () => ({
    '> svg': {
        position: 'relative',
        left: 2,
    },
}));
export const management = combineRules(icon, () => ({
    '> svg': {
        position: 'relative',
        top: -2,
    },
}));

export const thumbsUp = icon;

export const overview = icon;

export const tracking = icon;

export const date = icon;
