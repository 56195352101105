import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { SORT_DELIMITER, Sorting, UrlParam } from 'constants/index';

function useOrderSearchParams(): Sorting | null {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        const [field, type] = params.get(UrlParam.SORT)?.split(SORT_DELIMITER) || [];

        if (!field || !type) {
            return null;
        }

        return {
            field,
            type,
        } as Sorting;
    }, [search]);
}

export default useOrderSearchParams;
