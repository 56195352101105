import { noop } from 'lodash';
import { put } from 'redux-saga/effects';

import takeRequest from 'services/sagas/takeRequest';
import config from 'config';
import { authApi } from 'config/antonio';
import { createUIErrorMessage } from 'modules/errors';

import { fetchElevatorState as actions, fetchElevatorStateTypes } from '../actions';
import type { HealthState } from '../../types';

function* fetchElevatorState(action) {
    const { id } = action.meta;

    try {
        const { data } = yield* authApi.get<HealthState>(config.api.elevatorState, {
            uriParams: { id },
        });
        yield put(actions.fetchElevatorStateSuccess(id, data));
    } catch (error) {
        const uiError = createUIErrorMessage(error);
        yield put(actions.fetchElevatorStateFailure(id, uiError));
    }
}

export default function* () {
    yield takeRequest(
        {
            requestIdSelector: action => action.meta.id,
        },
        { pattern: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_REQUEST, handler: fetchElevatorState },
        { pattern: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_CANCEL, handler: noop },
    );
}
