import React from 'react';
import type { ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './UploadPage.rules';

export interface UploadPageProps {
    children: ReactNode;
}

export const UploadPage = ({ children }: UploadPageProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return <div className={styles.container}>{children}</div>;
};
