import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocales } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumbers } from '@formatjs/intl-numberformat/should-polyfill';

const pluralLocalesData = {
    en: () => import(`@formatjs/intl-pluralrules/locale-data/en`),
    de: () => import(`@formatjs/intl-pluralrules/locale-data/de`),
};

const numbersLocalesData = {
    en: () => import(`@formatjs/intl-numberformat/locale-data/en`),
    de: () => import(`@formatjs/intl-numberformat/locale-data/de`),
};

const loadPolyfills = async () => {
    const [uiLanguage] = navigator.language?.split('-');

    if (!window.Intl) {
        await import('intl');
    }

    if (shouldPolyfillCanonicalLocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }

    if (shouldPolyfillLocales()) {
        await import('@formatjs/intl-locale/polyfill');
    }

    if (shouldPolyfillPluralRules()) {
        await import('@formatjs/intl-pluralrules/polyfill');

        if (uiLanguage) {
            await pluralLocalesData[uiLanguage]();
        }
    }

    if (shouldPolyfillNumbers()) {
        await import('@formatjs/intl-numberformat/polyfill');

        if (uiLanguage) {
            await numbersLocalesData[uiLanguage]();
        }
    }
};

export default loadPolyfills;
