import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './IncidentReport.styles';
import { ElevatorState } from 'modules/entities/modules/incidents';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const IncidentReport: FC<{ reports: ElevatorState[] }> = ({ reports }) => {
    const { styles } = useFelaEnhanced(felaRules);
    return (
        <div className={styles.container}>
            {reports?.map(report => (
                <div className={styles.report}>
                    <div className={styles.header}>
                        <div className={styles.cell}>
                            <span className={styles.title}>
                                <FormattedMessage id={`page.incidents.created`} />
                            </span>
                            <span className={styles.value}>{moment(report.timeStamp).format('DD.MM.YYYY, HH:mm')}</span>
                        </div>
                        <div className={styles.cell}>
                            <div className={styles.title}>
                                <FormattedMessage id={`page.incidents.table.status`} />
                            </div>
                            <div className={styles.value}>{report.status}</div>
                        </div>

                        <div className={styles.cellDescription}>
                            <div className={styles.title}>
                                <FormattedMessage id={`page.incidents.description`} />
                            </div>
                            <div className={styles.value}>{report.description}</div>
                        </div>
                        <div className={styles.cell}>
                            <span className={styles.title}>
                                <FormattedMessage id={`page.incidents.table.origin`} />
                            </span>
                            <span className={styles.value}>{report.origin}</span>
                        </div>
                        <div className={styles.cellMedium}>
                            <span className={styles.title}>
                                <FormattedMessage id={`page.incidents.table.resolvedOn`} />
                            </span>
                            <span className={styles.value}>
                                {report.resolvedOn ? (
                                    moment(report.resolvedOn).format('DD.MM.YYYY, HH:mm')
                                ) : (
                                    <FormattedMessage id={`page.incidents.resolvedOn.Undefined`} />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default IncidentReport;
