import { basicApiReducer } from '@ackee/redux-utils';
import { fetchUsersTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchUsersTypes.FETCH_USERS_REQUEST,
        SUCCESS: fetchUsersTypes.FETCH_USERS_SUCCESS,
        FAILURE: fetchUsersTypes.FETCH_USERS_FAILURE,
        CANCEL: fetchUsersTypes.FETCH_USERS_CANCEL,
        RESET: fetchUsersTypes.FETCH_USERS_RESET,
    },
});
