import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

import { CLOSE_ICON } from 'modules/elevators-map/types';

export const map: TRuleWithTheme = () => ({
    height: 588,

    borderRadius: '0.125rem',

    // InfoWindow container
    '& .gm-style': {
        '& .gm-style-iw-c': {
            borderRadius: 2,
            boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.138565)',
            padding: 0,
            transform: 'translate3d(-50%,-87.5%,0)',          

            '& .gm-style-iw-d': {
                padding: 0,
                overflow: 'auto !important',
            },
        },

        // InfoWindow position relative to marker/cluster
        '& .gm-style-iw-t': {
            bottom: '50px !important',
            right: '0px !important',

            '::after': {
                display: 'none',
            },
        },
        // InfoWindow by default has downward pointing marker, so making its visibility hidden
        '& .gm-style-iw-tc': {
            visibility: 'hidden',
        
        }        
    },

    // InfoWindow close button
    '& button.gm-ui-hover-effect': {
        top: '0 !important',
        right: '0 !important',
        height: '40px !important',
        width: '40px !important',

        '& > span': {
            width: '16px !important',
            height: '16px !important',
            maskImage: `url(${CLOSE_ICON}) !important`,
            marginTop: '12px !important',
            marginBottom: '12px !important',
            marginLeft: '12px !important',
            marginRight: '12px !important',
        },
    },
});

export const label: TRuleWithTheme = ({ theme }) => ({
    lineHeight: 1.43,
    fontWeight: 500,
    fontSize: '0.875rem',
    position: 'relative',
});

export const markerLabel: TRuleWithTheme = combineRules(label, ({ theme }) => ({
    top: -4,
    background: theme.colors.white,
    borderRadius: 50,
    width: 20,
    height: 20,
}));

export const emptyMarkerLabel: TRuleWithTheme = combineRules(markerLabel, () => ({
    display: 'none',
}));

export const clusterLabel: TRuleWithTheme = combineRules(label, ({ theme }) => ({
    top: 0,
}));
