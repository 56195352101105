import { cancelled, put } from 'redux-saga/effects';
import { snakeCase, upperFirst } from 'lodash';

import config from 'config';
import * as log from 'config/loglevel';
import { authApi } from 'config/antonio';

import { takeRequest } from 'services/sagas/takeRequest';

import { createUIErrorMessage } from 'modules/errors';

import type { Quotation } from '../../types';

import {
    fetchQuotations,
    fetchQuotationsRequest,
    fetchQuotationsSuccess,
    fetchQuotationsFailure,
    clearQuotations,
    resetQuotations,
} from '../actions';

const REASON_SEPARATOR = ';';

type ResponseData = { [key: string]: any }[];

const transformData = (data: ResponseData) =>
    data.map(doc => {
        doc.id = doc.quotationId;
        doc.status = snakeCase(doc.status);
        doc.reason = doc.reason ? doc.reason.split(REASON_SEPARATOR).map(reason => upperFirst(reason)) : [];

        delete doc.quotationId;

        return doc;
    }) as Quotation[];

function* fetchQuotationsHandler(action) {
    const payload = action?.meta;
    const controller = new AbortController();

    yield put(fetchQuotationsRequest());

    try {
        const { data } = yield* authApi.get<ResponseData>(config.api.documentQuotations, {
            signal: controller.signal,
        });

        const t = transformData(data);
        if (payload?.elevatorId) {
            const filtedData = t.filter(tag => {
                return tag.elevators.some(item => {
                    return item.elevatorId === payload?.elevatorId;
                });
            });
            yield put(fetchQuotationsSuccess(filtedData));
            return;
        }

        yield put(fetchQuotationsSuccess(t));
    } catch (error) {
        log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(fetchQuotationsFailure(uiError));
    } finally {
        if (yield cancelled()) {
            controller.abort();
        }
    }
}

function* fetchQuotationsClearHandler() {
    yield put(resetQuotations());
}

export function* fetchQuotationsSaga() {
    yield takeRequest(
        {
            requestIdSelector: () => 'fetchQuotations',
        },
        {
            pattern: fetchQuotations.toString(),
            handler: fetchQuotationsHandler,
        },
        {
            pattern: clearQuotations.toString(),
            handler: fetchQuotationsClearHandler,
        },
    );
}
