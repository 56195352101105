import React, { FC, ReactElement } from 'react';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './StatusFilterContainer.styles';

type StatusFilterContainer = {
    title?: ReactElement;
    extend?: RulesExtend<typeof felaRules>;
};

const StatusFilterContainer: FC<StatusFilterContainer> = ({ title, children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return (
        <div className={styles.container}>
            <Typography.Title level={5} className={styles.title}>
                {title}
            </Typography.Title>
            {children}
        </div>
    );
};

export default StatusFilterContainer;
