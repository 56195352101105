import type { TRule } from 'fela';

import { ElevatorState } from 'modules/entities/modules/elevators';
import { Theme } from 'styles/theme';

export const icon: TRule<{ state?: ElevatorState; theme: Theme }> = ({ theme, state }) => {
    let color;

    switch (state) {
        case ElevatorState.OUT_OF_ORDER:
            color = theme.colors.statusHighAlert;
            break;
        case ElevatorState.RUNNING:
            color = theme.colors.statusSuccess;
            break;
        case ElevatorState.POTENTIAL_ISSUES:
            color = theme.colors.statusWarning;
            break;
        case ElevatorState.TECHNICIAN_ON_SITE:
            color = theme.colors.statusInfo;
            break;
        default:
            color = theme.colors.icon;
    }

    return { color, fontSize: '2rem', padding: 0 };
};
