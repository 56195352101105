/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateIoTElevatorState = /* GraphQL */ `
  subscription OnCreateIoTElevatorState($elevatorId: String) {
    onCreateIoTElevatorState(elevatorId: $elevatorId) {
      elevatorId
      state
      timestamp
    }
  }
`;
export const onDeleteIoTElevatorState = /* GraphQL */ `
  subscription OnDeleteIoTElevatorState($elevatorId: String, $timestamp: Int) {
    onDeleteIoTElevatorState(elevatorId: $elevatorId, timestamp: $timestamp) {
      elevatorId
      state
      timestamp
    }
  }
`;
export const onFloorHistoryPublish = /* GraphQL */ `
  subscription OnFloorHistoryPublish($elevatorId: String) {
    onFloorHistoryPublish(elevatorId: $elevatorId) {
      elevatorId
      host
      measure_name
      measure_value
      time
    }
  }
`;
export const onIoTElevatorStatePublish = /* GraphQL */ `
  subscription OnIoTElevatorStatePublish($elevatorId: String) {
    onIoTElevatorStatePublish(elevatorId: $elevatorId) {
      elevatorId
      state
      timestamp
    }
  }
`;
export const onUpdateIoTElevatorState = /* GraphQL */ `
  subscription OnUpdateIoTElevatorState($elevatorId: String, $timestamp: Int) {
    onUpdateIoTElevatorState(elevatorId: $elevatorId, timestamp: $timestamp) {
      elevatorId
      state
      timestamp
    }
  }
`;
