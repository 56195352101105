import { useSelector } from 'react-redux';

import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';
import { useDisplayError } from 'modules/errors';

import {
    selectPicklist,
    selectSortedActiveIncidents,
    selectSortedResolvedIncidents,
    selectSortedUnvalidatedIncidents,
    selectUnfilteredUnvalidatedIncidents,
} from '../services/selectors';

import { useFetchIncidents } from '../hooks/useFetchIncidents';
import { DEFAULT_SORT } from '../constants';

export const useIncidents = () => {
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const api = useFetchIncidents();

    const activeIncidents = useSelector(state => selectSortedActiveIncidents(state, { search, sorting }));
    const resolvedIncidents = useSelector(state => selectSortedResolvedIncidents(state, { search, sorting }));
    const unvalidatedIncidents = useSelector(state => selectSortedUnvalidatedIncidents(state, { search, sorting }));
    const unFilteredUnvalidatedIncidents = useSelector(state =>
        selectUnfilteredUnvalidatedIncidents(state, { search, sorting }),
    );
    const rejectedIncidents = useSelector(state => selectSortedResolvedIncidents(state, { search, sorting }));
    const picklist = useSelector(state => selectPicklist(state));

    useDisplayError(api.error);

    return {
        loading: api.inProgress,
        error: api.error,
        activeIncidents,
        resolvedIncidents,
        defaultSearch: defaultValue,
        rejectedIncidents,
        unvalidatedIncidents,
        unFilteredUnvalidatedIncidents,
        picklist,
    };
};
