import React, { FC } from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import type { Sorting } from 'constants/index';
import { SortingType } from 'constants/index';


type ActionPanelProps = {
};

export enum MaintenanceSortFields {
    MAINTENANCE_PER_YEAR = 'maintenancePerYear',
    RECOMMENDED = 'recommendedMaintenancePerYear',
    SIMPLIFA_RECOMMENDED = 'recommendationForMaintenanceInterval',
}

const SORT_FIELDS = [
    {
        field: MaintenanceSortFields.MAINTENANCE_PER_YEAR,
        labelId: 'maintenance.table.contractedAnnualMaintenances',
    },
    {
        field: MaintenanceSortFields.RECOMMENDED,
        labelId: 'maintenance.table.recommendedMaintenancePerYear',
    },
    {
        field: MaintenanceSortFields.SIMPLIFA_RECOMMENDED,
        labelId: 'maintenance.table.simplifaRecommendation',
    }
];

export const defaultSort: Sorting = { field: MaintenanceSortFields.SIMPLIFA_RECOMMENDED, type: SortingType.DESC };

const ActionPanel: FC<ActionPanelProps> = () => (
    <TableActionPanel>
        <TableSort fields={SORT_FIELDS} defaultField={defaultSort.field} defaultType={defaultSort.type} />
    </TableActionPanel>
);

export default ActionPanel;
