import React from 'react';
import { useHistory } from 'react-router-dom';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { UrlParam } from 'constants/index';
import { manipulateQueryString, omitQueryString } from 'services/utils';
import { useSearchParams } from 'services/hooks';

import { StatusFilter, SHOW_ALL } from '../StatusFilter';
import type { StatusFilterProps } from '../StatusFilter';

export interface URLStatusFilterProps<Status extends string = string>
    extends Omit<StatusFilterProps<Status>, 'value' | 'onChange'> {}

export function URLStatusFilter<Status extends string = string>({
    statuses,
    titleMessageId,
    allMessageId,
    allIcon = null,
}: URLStatusFilterProps<Status>) {
    const history = useHistory();

    const filter = useSearchParams(UrlParam.FILTER);

    const value = typeof filter === 'string' ? filter.split(',') : undefined;

    const handleChange = (e: CheckboxChangeEvent) => {
        const { value } = e.target;
        const search =
            value === SHOW_ALL
                ? omitQueryString(UrlParam.FILTER, history.location.search)
                : manipulateQueryString(value, UrlParam.FILTER, history.location.search, true);

        history.push({ search });
    };

    return (
        <StatusFilter<Status>
            titleMessageId={titleMessageId}
            allIcon={allIcon}
            allMessageId={allMessageId}
            statuses={statuses}
            value={value}
            onChange={handleChange}
        />
    );
}
