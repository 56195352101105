import React, { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import type { LegendProps as ReLegendProps } from 'recharts';

import * as felaRules from './Legend.styles';
import LegendItem from './LegendItem';

interface LegendProps extends ReLegendProps {
    legendNames?: { [key: string]: string | ReactNode };
}

const Legend: FC<LegendProps> = ({ payload, legendNames }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <ul className={styles.container}>
            {payload.map(entry => (
                <LegendItem key={entry.value} dotColor={entry.color}>
                    {legendNames[entry.value]}
                </LegendItem>
            ))}
        </ul>
    );
};

export default Legend;
