import { basicApiReducer } from '@ackee/redux-utils/es';

import {
    loginRequestFailure,
    loginRequestRequest,
    loginRequestSuccess,
    loginRequestReset,
} from '../action';

export const loginApiReducer = basicApiReducer({
    actionTypes: {
        REQUEST: loginRequestRequest.toString(),
        SUCCESS: loginRequestSuccess.toString(),
        FAILURE: loginRequestFailure.toString(),
        RESET: loginRequestReset.toString(),
    },
});


