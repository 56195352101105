import { createAction } from '@reduxjs/toolkit';

import { CHANGE_ROLE_STATE } from '../../types';

export const changeRoleRequest = createAction(`${CHANGE_ROLE_STATE}/SEND`, ({ role, email, userId }) => ({
    payload: { role, userId },
    meta: {
        email,
    },
}));

export const changeRoleRequestSuccess = createAction(
    `${CHANGE_ROLE_STATE}/SEND_SUCCESS`,
    (lastSuccessAt: string = new Date().toISOString()) => ({
        payload: null,
        meta: {
            lastSuccessAt,
        },
    }),
);

export const changeRoleRequestFailure = createAction(`${CHANGE_ROLE_STATE}/SEND_FAILURE`, (error: any) => ({
    payload: null,
    error,
    meta: {},
}));

export const changeRoleRequestReset = createAction(`${CHANGE_ROLE_STATE}/SEND_RESET`, () => ({
    payload: null,
    meta: {},
}));
