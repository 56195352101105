import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';

import * as itemStyles from './NavItem.styles';

interface Props {
    to: string;
}

const NavItem: FC<Props> = ({ to, children }) => {
    const { styles } = useFelaEnhanced(itemStyles);

    return (
        <Link to={to} className={styles.navItem}>
            {children}
        </Link>
    );
};

export default NavItem;
