import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import accept from 'attr-accept';

import { Button, Typography, Upload } from 'antd';

import { noop } from 'lodash';
import type { RcFile } from 'antd/lib/upload';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType, useUIMessage } from 'modules/ui';

import type { ChecktypeDocumentsUploadType } from 'modules/entities/modules/documents';

import { useDocumentUpload } from '../../hooks/useDocumentUpload';

import * as felaRules from './UploadDocument.rules';

const { Dragger } = Upload;

const SUPPORTED_FILE_TYPES = ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg', '.exif', '.heic'];

export interface UploadDocumentProps {
    type: ChecktypeDocumentsUploadType;
}

export const UploadDocument = ({ type }: UploadDocumentProps) => {
    const intl = useIntl();

    const message = useUIMessage();

    const [file, setFile] = useState<RcFile>(null);

    const { api, uploadDocument } = useDocumentUpload(file?.uid);

    const draggerDisabled = Boolean(file) || api.inProgress;

    const { styles } = useFelaEnhanced(felaRules, {
        file,
        draggerDisabled,
    });

    const fileRef = useRef(file);

    useEffect(() => {
        fileRef.current = file;
    }, [file]);

    useEffect(() => {
        if (fileRef.current !== null) {
            setFile(null);
        }
    }, [api.lastSuccessAt]);

    return (
        <div className={styles.container}>
            <Dragger
                className={styles.dragger}
                name="file"
                showUploadList={false}
                disabled={Boolean(file) || api.inProgress}
                customRequest={noop}
                onChange={data => {
                    const originalFile = data.file.originFileObj;

                    if (accept(originalFile, SUPPORTED_FILE_TYPES)) {
                        setFile(originalFile);
                    } else {
                        message('error', 'documents.upload.unsupported');
                    }
                }}
            >
                {file ? (
                    <div className={styles.attachment}>
                        <div>
                            <Icon type={IconType.DOCUMENT_SMALL} className={styles.docIcon} />
                            <Typography.Text className={styles.name}>{file.name}</Typography.Text>
                        </div>
                        <Button
                            aria-label={intl.formatMessage({
                                id: 'general.upload.remove',
                            })}
                            disabled={api.inProgress}
                            icon={<Icon type={IconType.TRASH} className={styles.trashIcon} />}
                            className={styles.trashButton}
                            onClick={() => {
                                setFile(null);
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <Icon type={IconType.DRAG_N_DROP} className={styles.icon} />
                        <Typography.Text className={styles.text}>
                            <FormattedMessage
                                id="documents.upload.dragDrop"
                                values={{
                                    span: (chunks: ReactNode[]) => <span className={styles.textInner}>{chunks}</span>,
                                }}
                            />
                        </Typography.Text>
                    </>
                )}
            </Dragger>
            <Button
                size="large"
                loading={api.inProgress}
                disabled={!Boolean(file) || api.inProgress}
                onClick={() => {
                    uploadDocument({
                        files: [{ fileUrl: URL.createObjectURL(file), name: file.name }],
                        type,
                    });
                }}
            >
                <FormattedMessage id={`documents.upload.${type}.button`} />
            </Button>
        </div>
    );
};
