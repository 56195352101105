import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { ApiReducerKey, EntityKey } from '../../../../../constants';
import type { HealthState } from '../types';

export const selectElevatorStateApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.ELEVATOR_STATE, ApiReducerKey.DETAIL);

export const selectElevatorState = (state): HealthState =>
    state.entities[EntityKey.ELEVATOR_STATE][ApiReducerKey.DETAIL];
