import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useQueryParams } from 'hooks';
import type { Elevator } from 'modules/entities/modules/elevators';

import { UrlParam } from 'constants/index';

import { fetchElevatorStates as actions } from '../services/actions';
import { selectElevatorStatesApi, selectElevatorStatesEntityList } from '../services/selectors';

export const useElevatorStates = (
    id: Elevator['id'],
    params: {
        enableShareToken?: boolean;
        pageSize?: number;
    } = {},
) => {
    const { pageSize = null, enableShareToken = false } = params;

    const dispatch = useDispatch();
    const queryParams = useQueryParams();

    const pageToken = enableShareToken ? queryParams.get(UrlParam.PAGE_TOKEN_KEY) : null;

    const api = useSelector(selectElevatorStatesApi);
    const data = useSelector(selectElevatorStatesEntityList);

    useEffect(() => {
        dispatch(
            actions.fetchElevatorStates({
                id,
                pageSize,
                pageToken,
                enableShareToken,
            }),
        );
    }, [id, pageSize, pageToken, enableShareToken, dispatch]);

    useEffect(
        () => () => {
            dispatch(actions.clearElevatorStates());
        },
        // Only trigger on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return { api, data };
};
