import React, { useContext, useEffect, useRef, useState } from 'react';
import { Typography, Modal, Checkbox } from 'antd';

import { useFelaEnhanced } from 'hooks';
import * as felaRules from './ValidateConfirmDialog.rules';
import {
    forms,
    CANCELLATION_ACTION,
    CONFIRMATION_ACTION,
    HIDE_VALIDATE_INCIDENT_DIALOG,
    VALIDATE_INCIDENT_DIALOG,
    INCIDENT_DIALOG_CANCEL,
} from '../../constants';
import { FormattedMessage } from 'react-intl';
import { useGlobalDialog } from 'hooks/useGlobalDialog';
import { DialogState } from 'constants/common';
import { Button } from 'modules/ui';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';
import { useFormSubmission } from 'modules/form';
import { CompleteIncident } from '../CompleteIncident';

export interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

export const ValidateConfirmDialog = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const containerRef = useRef();
    const { state, navigate } = useGlobalDialog();
    const { meta } = useContext(GlobalDialogContext);
    const { handleSubmit } = useFormSubmission<any>(forms.updateIncident);
    const [hideMessage, setHideMessage] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [selectedComponentGroup, setSelectedComponentGroup] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [components, setComponents] = useState([]);
    const [subStatus, setSubStatus] = useState(null);
    const [resolvedOn, setResolvedOn] = useState(null);

    useEffect(() => {
        if (
            state === DialogState.VALIDATE_CONFIRM ||
            state === DialogState.CANCEL_CONFIRM ||
            state === DialogState.COMPLETE_CONFIRM
        ) {
            document.body.style.overflow = 'hidden';
        }
    }, [state]);

    const renderTitle = () => {
        if (state === DialogState.VALIDATE_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.validation.dialog.title" />;
        } else if (state === DialogState.CANCEL_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.cancellation.dialog.title" />;
        } else if (state === DialogState.COMPLETE_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.completion.dialog.title" />;
        }
    };
    const renderMessage = () => {
        if (state === DialogState.VALIDATE_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.validation.dialog.message" />;
        } else if (state === DialogState.CANCEL_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.cancellation.dialog.message" />;
        } else if (state === DialogState.COMPLETE_CONFIRM) {
            return <FormattedMessage id="page.incidents.table.completion.dialog.message" />;
        }
    };

    return (
        <div ref={containerRef}>
            <Modal
                title={null}
                footer={null}
                closable={false}
                maskClosable={false}
                visible={
                    state === DialogState.VALIDATE_CONFIRM ||
                    state === DialogState.CANCEL_CONFIRM ||
                    state === DialogState.COMPLETE_CONFIRM
                }
                getContainer={() => containerRef.current}
                className={styles.modal}
                width={738}
                afterClose={() => {
                    document.body.style.overflow = 'unset';
                }}
                onCancel={() => {
                    setShowAlert(false);
                    setComponents([]);
                    setSelectedComponentGroup(null);
                    setSelectedComponent(null);
                    setSelectedDate(null);
                    setResolvedOn(null);
                    setSelectedTime(null);
                    setSelectedTime(null);
                    setSubStatus(null);
                    setSelectedComponentGroup(null);
                    navigate(null);
                }}
            >
                {state === DialogState.COMPLETE_CONFIRM ? (
                    <CompleteIncident
                        meta={meta}
                        navigate={navigate}
                        setHideMessage={setHideMessage}
                        handleSubmit={handleSubmit}
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                        setResolvedOn={setResolvedOn}
                        resolvedOn={resolvedOn}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        setSelectedComponentGroup={setSelectedComponentGroup}
                        selectedComponentGroup={selectedComponentGroup}
                        setSelectedComponent={setSelectedComponent}
                        selectedComponent={selectedComponent}
                        setSelectedTime={setSelectedTime}
                        selectedTime={selectedTime}
                        setComponents={setComponents}
                        components={components}
                        setSubStatus={setSubStatus}
                        subStatus={subStatus}
                    />
                ) : (
                    <div data-testid="new-incident-dialog-selection">
                        <div className={styles.header}>
                            <Typography.Title level={2} className={styles.title}>
                                {renderTitle()}
                            </Typography.Title>
                        </div>
                        <div className={styles.content}>
                            <Typography.Paragraph className={styles.description}>
                                {renderMessage()}
                            </Typography.Paragraph>

                            <div className={styles.checkboxRow}>
                                <Checkbox
                                    // className={styles.checkbox}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            setHideMessage(true);
                                        } else {
                                            setHideMessage(false);
                                        }
                                    }}
                                    checked={hideMessage}
                                >
                                    <div className={styles.checkoutText}>
                                        {state === DialogState.VALIDATE_CONFIRM ? (
                                            <FormattedMessage id="page.incidents.table.validation.dialog.option" />
                                        ) : (
                                            <FormattedMessage id="page.incidents.table.cancellation.dialog.option" />
                                        )}
                                    </div>
                                </Checkbox>

                                <div className={styles.bottomButtons}>
                                    <Button
                                        size="large"
                                        onClick={() => {
                                            navigate(null);
                                        }}
                                    >
                                        <FormattedMessage id="page.incidents.table.validation.dialog.cancel" />
                                    </Button>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                            const requestType =
                                                state === DialogState.VALIDATE_CONFIRM
                                                    ? CONFIRMATION_ACTION
                                                    : CANCELLATION_ACTION;

                                            handleSubmit(
                                                {
                                                    elevator: meta.elevatorId,
                                                    approvalStatus:
                                                        state === DialogState.VALIDATE_CONFIRM ? 'Approve' : 'Reject',
                                                },
                                                {
                                                    requestType,

                                                    caseNumber: meta.caseNumber,
                                                },
                                            );
                                            if (hideMessage) {
                                                localStorage.setItem(
                                                    state === DialogState.VALIDATE_CONFIRM
                                                        ? VALIDATE_INCIDENT_DIALOG
                                                        : INCIDENT_DIALOG_CANCEL,
                                                    HIDE_VALIDATE_INCIDENT_DIALOG,
                                                );
                                            }
                                            setHideMessage(false);
                                            navigate(null);
                                            // clearForm();
                                        }}
                                    >
                                        <FormattedMessage id="incident.new.dialog.button.submit" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};
