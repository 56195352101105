import { useEffect } from 'react';
import config from 'config';

const useHotjarTracking = () => {
  useEffect(() => {
    const hj = (window as any).hj || function(){(hj.q = hj.q || []).push(arguments)};
    hj('tagRecording', []);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${config.hotjar.id},hjsv:${config.hotjar.version}};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(script);
  }, []);
};

export default useHotjarTracking;
