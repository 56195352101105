import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import { Tag as AntdTag, TagProps } from 'antd';

import * as felaRules from './Tag.styles';

const Tag: FC<TagProps> = ({ children, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <AntdTag className={styles.tag} {...rest}>
            {children}
        </AntdTag>
    );
};

export default Tag;
