import { ListReducerState, ApiAction } from '../../../../../types';
import { createByIds, createIds } from '../../../../../services/utils';

import { Incident } from '../../../types';
import { fetchIncidentsTypes } from '../../actions';

const initialState: ListReducerState<Incident, Incident['elevatorId']> = {
    byIds: {},
    ids: [],
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchIncidentsTypes.FETCH_INCIDENTS_SUCCESS && action.payload?.unvalidated: {
            const incidents: { rejected: Incident[] } = action.payload;
            // TODO: performance update
            return {
                byIds: createByIds(incidents.rejected),
                ids: createIds(incidents.rejected),
            };
        }

        case fetchIncidentsTypes.FETCH_INCIDENTS_RESET:
            return initialState;

        default:
            return state;
    }
};
