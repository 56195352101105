import { useSelector } from 'react-redux';

import { useDisplayError } from 'modules/errors';
import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';

import { WorkorderType } from '../types';
import { selectFilteredWorkorders } from '../services/selectors';

import { useFetchWorkorders } from '../hooks/useFetchWorkorders';

import { DEFAULT_SORT } from '../constants';

export const useWorkorderDocuments = (type: WorkorderType) => {
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;
    const { search, defaultValue } = useSearchSearchParams();
    
    const api = useFetchWorkorders(type);

    const workorders = useSelector(state =>
        selectFilteredWorkorders(state, {
            sorting,
            search,
        }),
    );

    useDisplayError(api.error);

    return { loading: api.inProgress, defaultSearch: defaultValue, documents: workorders };
};
