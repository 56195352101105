import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Popover, PopoverContainer } from 'modules/ui';

import type { Contract } from 'modules/entities/modules/documents-contracts';

import { DocumentInfoIcon } from '../../DocumentInfoIcon';

export interface ContractValidityProps {
    contract: Contract;
}

export const ContractValidity = ({ contract }: ContractValidityProps) => (
    <>
        <FormattedDate value={contract.startDate} />
        {' - '}
        <FormattedDate value={contract.validUntil} />
        <Popover
            content={
                <PopoverContainer>
                    {contract.noticePeriod ? (
                        <PopoverContainer.Item
                            title={<FormattedMessage id="documents.contracts.table.cancelationPeriod" />}
                            text={
                                <FormattedMessage
                                    id="documents.contracts.table.nMonths"
                                    values={{ number: contract.noticePeriod }}
                                />
                            }
                        />
                    ) : null}
                    {contract.extensionPeriod ? (
                        <PopoverContainer.Item
                            title={<FormattedMessage id="documents.contracts.table.prolongationPeriod" />}
                            text={
                                <FormattedMessage
                                    id="documents.contracts.table.nMonths"
                                    values={{ number: contract.extensionPeriod }}
                                />
                            }
                        />
                    ) : null}
                </PopoverContainer>
            }
            trigger="hover"
        >
            <DocumentInfoIcon />
        </Popover>
    </>
);
