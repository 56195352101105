import { basicApiReducer } from '@ackee/redux-utils';

import { downloadDocumentsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_REQUEST,
        SUCCESS: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_SUCCESS,
        FAILURE: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_FAILURE,
        CANCEL: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_CANCEL,
        RESET: downloadDocumentsTypes.DOWNLOAD_DOCUMENTS_RESET,
    },
});
