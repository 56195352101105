import { useEffect, useState } from 'react';

import {
    DocumentDownloadType,
    DownloadableDocument,
    useDisplayDownloadError,
    useDownloadDocuments,
} from 'modules/entities/modules/documents';

export const useViewDocument = () => {
    const { downloadDocuments, api: downloadApi } = useDownloadDocuments();

    const [rowKey, setRowKey] = useState(null);

    useDisplayDownloadError();

    useEffect(() => {
        if (rowKey && !downloadApi.inProgress) {
            setRowKey(null);
        }
    }, [rowKey, downloadApi.inProgress]);

    const openDocument = ({ id, file }: DownloadableDocument) => {
        setRowKey(id);
        downloadDocuments([file], DocumentDownloadType.OPEN);
    };

    return {
        opening: downloadApi.inProgress,
        rowKey,
        openDocument,
    };
};
