import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    marginBottom: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 500,
});

export const topContainer: TRuleWithTheme = () => ({
    paddingTop: '0.88rem',
    paddingBottom: '0.88rem',
    display: 'flex',
    justifyContent: 'space-between',
});
export const topContainerWithMoreSpace: TRuleWithTheme = () => ({
    paddingTop: '1.88rem',
    paddingBottom: '0.88rem',
    display: 'flex',
    justifyContent: 'space-between',
});
export const widthFull: TRuleWithTheme = () => ({
    width: '100%',
});
export const validationContainer: TRuleWithTheme = () => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontSize: '1rem',

    fontWeight: 500,

    '& .anticon': {
        color: `${theme.colors.textSecondary} !important`,
    },
});
export const company: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '12.5rem',
});

export const validationLayerBackground: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.selectedSecondary,
    height: '3.75rem',
    position: 'absolute',
    width: '100%',
    opacity: 0.5,
});

export const container: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    paddingBottom: '0.88rem',

    minHeight: '10.44rem',
    // ':last-of-type': {
    //     marginBottom: 0,
    // },
    '&:hover': {
        cursor: 'auto',
    },
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: '#262626',
    background: '#fff',
    borderRadius: '2px',
});
export const containerBordered: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    paddingBottom: '0.88rem',

    minHeight: '10.44rem',
    // ':last-of-type': {
    //     marginBottom: 0,
    // },
    '&:hover': {
        cursor: 'auto',
    },
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: '#262626',
    background: '#fff',
    borderRadius: '2px',
    border: '1px solid #CBCBCB',
});
export const subStatus: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
});
export const subStatusChange: TRule = () => ({
    marginLeft: '0.5rem',
    '& .ant-space-item': {
        textDecoration: 'underline',
    },
});
export const row: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '3.13rem',
});
export const rowtop: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '2.44rem',

    // paddingBottom: '6.13rem',
});

export const rowtopWithMoreSpace: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '0.31rem',

    // paddingBottom: '6.13rem',
});
export const rowBottom: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '1.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
});
export const divider: TRule = () => ({
    borderTop: 'solid 1px #EBEBEB',
    position: 'relative',
});
export const topSelect: TRule = () => ({
    marginBottom: '1rem',
    paddingLeft: '1.5rem',
});

export const menu: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,
    margin: 0,

    ':hover': {
        background: 'none',
    },
    ':active': {
        background: 'none',
    },
    ':focus': {
        background: 'none',
    },
    '& .anticon': {
        marginRight: '1.25em',
    },
});
export const cell: TRule = () => ({
    // width: '148px',
    marginRight: '2rem',
});

export const factoryNumber: TRule = () => ({
    textDecorationLine: 'underline',
    textDecorationThickness: '0.1rem',
    ':hover': {
        color: '#f57645',
    },
});
