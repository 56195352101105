import React from 'react';

export const ZuesPriceCheck = () => (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m9.2416 4.3494-3.526 4.114-0.955-1.114c-0.361-0.421-0.992-0.467-1.411-0.109-0.42 0.359-0.468 0.991-0.109 1.41l1.714 2c0.016 0.019 0.041 0.026 0.059 0.045 0.02 0.019 0.029 0.045 0.05 0.063 0.049 0.042 0.107 0.064 0.161 0.095 0.038 0.021 0.073 0.049 0.114 0.065 0.121 0.05 0.248 0.081 0.376 0.081 0.129 0 0.256-0.031 0.377-0.081 0.039-0.015 0.071-0.042 0.108-0.062 0.056-0.031 0.114-0.054 0.165-0.097 0.023-0.019 0.032-0.047 0.053-0.067 0.016-0.018 0.04-0.024 0.055-0.042l4.287-5c0.36-0.419 0.311-1.05-0.109-1.409-0.418-0.359-1.05-0.313-1.409 0.108z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="m9.2416 13.35-3.526 4.114-0.955-1.114c-0.361-0.42-0.992-0.467-1.411-0.109-0.42 0.359-0.468 0.991-0.109 1.41l1.714 2c0.016 0.019 0.041 0.026 0.059 0.045 0.02 0.019 0.029 0.045 0.05 0.063 0.049 0.042 0.107 0.064 0.161 0.095 0.038 0.021 0.073 0.049 0.114 0.065 0.121 0.05 0.248 0.081 0.376 0.081 0.129 0 0.256-0.031 0.377-0.081 0.039-0.015 0.071-0.042 0.108-0.062 0.056-0.031 0.114-0.054 0.165-0.097 0.023-0.019 0.032-0.047 0.053-0.067 0.016-0.018 0.04-0.024 0.055-0.042l4.287-5c0.36-0.419 0.311-1.05-0.109-1.409-0.418-0.36-1.05-0.313-1.409 0.108z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="m13 10h7c0.553 0 1-0.447 1-1s-0.447-1-1-1h-7c-0.553 0-1 0.447-1 1s0.447 1 1 1z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="m20 17h-7c-0.553 0-1 0.447-1 1s0.447 1 1 1h7c0.553 0 1-0.447 1-1s-0.447-1-1-1z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
    </svg>
);
