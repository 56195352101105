import type { CognitoUser } from '@aws-amplify/auth';
import type { User } from '../../../types';

const mapUserData = (user: CognitoUser): Promise<User> =>
    new Promise((resolve, reject) => {
        user.getUserAttributes((error, attributes) => {
            if (!error) {
                const plainAttributes = attributes.reduce((attributesObj, attribute) => {
                    const attributeName = attribute.getName();
                    attributesObj[
                        attributeName === 'custom:user_id' ? 'customUserId' : attributeName
                    ] = attribute.getValue();

                    return attributesObj;
                }, {});

                resolve({
                    username: user.getUsername(),
                    ...plainAttributes,
                });
            } else {
                reject(error);
            }
        });
    });

export default mapUserData;
