import { ApiAction } from '../../../../../types';

import { fetchUsersTypes } from '../../actions';

const initialState = {
    users: [],
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchUsersTypes.FETCH_USERS_SUCCESS: {
            return {
                users: action.payload,
            };
        }

        case fetchUsersTypes.FETCH_USERS_RESET:
            return initialState;

        default:
            return state;
    }
};
