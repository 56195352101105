import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginLeft: '2.5rem',
});

export const info: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',

        color: theme.colors.textSecondary,
    },
    '&.ant-typography > ul': {
        marginTop: '0.5em',
        marginBottom: '1.5em',

        listStyleType: 'disc',
    },
    '&.ant-typography > ul > li': {
        marginBottom: '0.25em',

        color: theme.colors.typographyListButtletColor,
    },

    '&.ant-typography > a': {
        textDecoration: 'underline',

        ':hover': {
            textDecoration: 'none',

            color: theme.colors.primary,
        },
    },
});

export const listItemInner: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
});

export const tooltipTitle: TRuleWithTheme = () => ({
    display: 'block',

    marginBottom: '0.57em',

    fontSize: '1.167em',
    fontWeight: 600,
});
