import { TRuleWithTheme } from 'styles/theme';

export const checkbox: TRuleWithTheme = ({ theme }) => ({
    '&.ant-checkbox-wrapper': {
        border: `1px solid ${theme.colors.borderColor}`,
        borderRadius: '.875rem',
        padding: '.125rem .8125rem',
        marginRight: '.5rem',
        marginBottom: '.75rem',

        backgroundColor: theme.colors.white,

        '&:hover': {
            backgroundColor: theme.colors.onHover,
            borderColor: theme.colors.onHover,
        },

        '&.ant-checkbox-wrapper-checked': {
            backgroundColor: theme.colors.selectedSecondary,
            borderColor: theme.colors.onHover,

            '& .ant-checkbox-checked::after': {
                display: 'none',
            },
        },

        '& .ant-checkbox-inner': {
            display: 'none',
        },

        '& input.ant-checkbox': {
            display: 'none',
        },

        '& + .ant-checkbox-wrapper': {
            marginLeft: '0',
        },

        '& .ant-checkbox + span': {
            padding: '0',
            fontSize: '.875rem',
        },
    },
});

export const count: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
});
