import React, { useRef } from 'react';
import { Modal } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';

import { DialogState } from '../../constants';

import { useNewElevatorDialog } from '../../hooks/useNewElevatorDialog';

import { NewElevatorDialogUploadSelection } from '../NewElevatorDialogUploadSelection';
import { NewElevatorDialogUploadExcel } from '../NewElevatorDialogUploadExcel';
import { NewElevatorDialogUploadAssessmentReport } from '../NewElevatorDialogUploadAssessmentReport';
import { NewElevatorDialogUploadManual } from '../NewElevatorDialogUploadManual';

import * as felaRules from './NewElevatorDialog.rules';

const closableStates = [DialogState.SELECTION, DialogState.CREATE_MANUAL];

export const NewElevatorDialog = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const containerRef = useRef();

    const {
        state,
        navigate,
        upload: { api },
    } = useNewElevatorDialog();

    return (
        <div ref={containerRef}>
            <Modal
                title={null}
                footer={null}
                closeIcon={<Icon type={IconType.CANCEL} className={styles.closeIcon} />}
                closable={closableStates.includes(state) && !api.inProgress}
                visible={Boolean(state)}
                getContainer={() => containerRef.current}
                className={styles.modal}
                width={738}
                onCancel={
                    api.inProgress
                        ? undefined
                        : () => {
                              navigate(null);
                          }
                }
            >
                {state === DialogState.SELECTION ? <NewElevatorDialogUploadSelection /> : null}
                {state === DialogState.CREATE_EXCEL ? <NewElevatorDialogUploadExcel /> : null}
                {state === DialogState.CREATE_ASSESSMENT_REPORT ? <NewElevatorDialogUploadAssessmentReport /> : null}
                {state === DialogState.CREATE_MANUAL ? <NewElevatorDialogUploadManual /> : null}
            </Modal>
        </div>
    );
};
