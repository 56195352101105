import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';
import { Elevator, ElevatorState } from 'modules/entities/modules/elevators';

import * as felaRules from './InfoWindowTag.rules';

export interface InfoWindowTagProps {
    state: Elevator['state'] | null;
    isInLearningPhase: boolean;
}

const getIcon = (state: InfoWindowTagProps['state'], isInLearningPhase: boolean) => {
    if (isInLearningPhase) {
        return IconType.BOOK;
    }
    if (!state) {
        return IconType.NO_IOT;
    } else if (state.state === ElevatorState.DEVICE_OFFLINE) {
        return IconType.DEVICE_OFFLINE;
    }
    return;
};

export const InfoWindowTag = ({ state, isInLearningPhase }: InfoWindowTagProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    const icon = getIcon(state, isInLearningPhase);

    const stateLabel = isInLearningPhase ? 'LEARNING_PHASE' : state?.state;

    if (icon) {
        const label = intl.formatMessage({
            id: `elevators.map.info-window.tag.${stateLabel ?? 'NO_IOT'}`,
        });

        return (
            <span className={styles.tag}>
                <Icon type={icon} className={styles.icon} aria-label={label} />
                {label}
            </span>
        );
    }

    return null;
};
