import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { manipulateQueryString, omitQueryString } from 'services/utils';
import { SearchValue, UrlParam } from 'constants/index';

const useTableSearch = (defaultValue: SearchValue, updatePage: boolean = false) => {
    const [value, setValue] = useState(defaultValue);

    const handleValue = (value: SearchValue) => {
        setValue(state => ({ ...state, ...value }));
    };

    const history = useHistory();
    const location = useLocation();

    const handleSubmit = e => {
        e.preventDefault();
        const keys = Object.keys(value);

        const queryStringValues = keys
            .filter(key => value[key] || value[key]?.length > 0)
            .map(key => `${key}:${value[key]}`);

        if (keys?.length > 0) {
            let search = manipulateQueryString(queryStringValues.join(','), UrlParam.SEARCH, location.search);
            if (updatePage) {
                search = manipulateQueryString(1, UrlParam.PAGE, search);
            }
            history.push({
                search,
            });
        }
    };

    const handleReset = () => {
        if (!isEmpty(value)) {
            setValue({});
            let search = omitQueryString(UrlParam.SEARCH, location.search);
            if (updatePage) {
                search = manipulateQueryString(1, UrlParam.PAGE, search);
            }
            history.push({ search });
        }
    };

    return {
        value,
        handleValue,
        handleSubmit,
        handleReset,
    };
};

export default useTableSearch;
