import React from 'react';

const Clock = props => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.99968 0.333344C5.68114 0.333344 4.3922 0.724337 3.29588 1.45688C2.19955 2.18942 1.34506 3.23061 0.840481 4.44879C0.335896 5.66696 0.203874 7.00741 0.461109 8.30061C0.718344 9.59382 1.35328 10.7817 2.28563 11.7141C3.21798 12.6464 4.40587 13.2813 5.69908 13.5386C6.99228 13.7958 8.33273 13.6638 9.5509 13.1592C10.7691 12.6546 11.8103 11.8001 12.5428 10.7038C13.2754 9.60748 13.6663 8.31855 13.6663 7.00001C13.6663 6.12453 13.4939 5.25762 13.1589 4.44879C12.8238 3.63995 12.3328 2.90502 11.7137 2.28596C11.0947 1.66691 10.3597 1.17584 9.5509 0.840813C8.74206 0.505782 7.87516 0.333344 6.99968 0.333344ZM6.99968 12.3333C5.94484 12.3333 4.9137 12.0205 4.03664 11.4345C3.15957 10.8485 2.47599 10.0155 2.07232 9.04099C1.66865 8.06645 1.56303 6.99409 1.76882 5.95953C1.97461 4.92496 2.48256 3.97465 3.22844 3.22877C3.97432 2.48289 4.92463 1.97494 5.9592 1.76916C6.99376 1.56337 8.06612 1.66899 9.04066 2.07265C10.0152 2.47632 10.8481 3.15991 11.4342 4.03697C12.0202 4.91403 12.333 5.94518 12.333 7.00001C12.333 8.4145 11.7711 9.77105 10.7709 10.7712C9.77072 11.7714 8.41417 12.3333 6.99968 12.3333ZM6.99968 3.00001C6.82287 3.00001 6.6533 3.07025 6.52827 3.19527C6.40325 3.3203 6.33301 3.48987 6.33301 3.66668V6.33334H4.99968C4.82287 6.33334 4.6533 6.40358 4.52827 6.52861C4.40325 6.65363 4.33301 6.8232 4.33301 7.00001C4.33301 7.17682 4.40325 7.34639 4.52827 7.47141C4.6533 7.59644 4.82287 7.66668 4.99968 7.66668H6.99968C7.17649 7.66668 7.34606 7.59644 7.47108 7.47141C7.59611 7.34639 7.66634 7.17682 7.66634 7.00001V3.66668C7.66634 3.48987 7.59611 3.3203 7.47108 3.19527C7.34606 3.07025 7.17649 3.00001 6.99968 3.00001Z"
                fill="#A6A6A6"
            />
        </svg>
    );
};

export default Clock;
