import { basicApiReducer } from '@ackee/redux-utils';
import { fetchIncidentTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchIncidentTypes.FETCH_INCIDENT_REQUEST,
        SUCCESS: fetchIncidentTypes.FETCH_INCIDENT_SUCCESS,
        FAILURE: fetchIncidentTypes.FETCH_INCIDENT_FAILURE,
        CANCEL: fetchIncidentTypes.FETCH_INCIDENT_CANCEL,
        RESET: fetchIncidentTypes.FETCH_INCIDENT_RESET,
    },
});
