import { basicApiReducer } from '@ackee/redux-utils';
import { fetchSupportsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchSupportsTypes.FETCH_SUPPORTS_REQUEST,
        SUCCESS: fetchSupportsTypes.FETCH_SUPPORTS_SUCCESS,
        FAILURE: fetchSupportsTypes.FETCH_SUPPORTS_FAILURE,
        CANCEL: fetchSupportsTypes.FETCH_SUPPORTS_CANCEL,
        RESET: fetchSupportsTypes.FETCH_SUPPORTS_RESET,
    },
});
