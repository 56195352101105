import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import config from 'config/index';

import { Invoice } from 'modules/entities/modules/documents-invoices';

import ItemActions from '../../ItemActions';

import * as felaRules from './InvoiceItemInfo.styles';

type InvoiceItemInfoProps = {
    invoice: Invoice;
};

const InvoiceItemInfo: FC<InvoiceItemInfoProps> = ({ invoice }) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ItemActions id={invoice.id} file={invoice.file} />

                <span className={styles.price}>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={invoice.netTotal} style="currency" currency={config.currency} />
                </span>
            </div>
        </div>
    );
};

export default InvoiceItemInfo;
