import type { ApiReducerState } from '@ackee/redux-utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchIncident as actions } from '../services/actions';

import { selectIncidentApi } from '../services/selectors';

export const useFetchIncident = (): ApiReducerState => {
    const { id } = useParams<{ id: string }>();
    const api = useSelector(selectIncidentApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchIncidentRequest(id));

        return () => {
            dispatch(actions.fetchIncidentCancel());
            dispatch(actions.fetchIncidentReset());
        };
    }, [dispatch, id]);

    return api;
};
