import type { TRuleWithTheme } from 'styles/theme';

export const tag: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    paddingTop: '0.31rem',
    paddingRight: '0.56rem',
    paddingBottom: '0.31rem',
    paddingLeft: '0.56rem',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.outlineGrey,
    borderRadius: '0.125rem',

    backgroundColor: theme.colors.white,

    color: theme.colors.text,

    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.33,
});

export const icon: TRuleWithTheme = () => ({
    marginRight: '0.46rem',

    '> svg': {
        height: '1.25em',
        width: '1.25em',
    },
});
