import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    maxWidth: 738,

    padding: '1.5rem',

    '& .ant-layout': {
        background: 'red !important',
        backgroundColor: 'red !important',
    },

    backgroundColor: theme.colors.white,
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '0.5rem',

        fontSize: '1.5rem',
        lineHeight: 1.33,
    },
});
export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: '1.5rem',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: 1.5,
        color: '#8F8F8F',
    },
});
export const buttons: TRuleWithTheme = ({ theme }) => ({
    marginTop: '4rem',
    marginBottom: '2rem',
});

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});
