import React from 'react';

const ElevatorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M13 19V8h6l.002 11H13zm-8 0h6V8H5v11zM19 6H5c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zM9 5h6a1 1 0 100-2H9a1 1 0 100 2" />
        </g>
    </svg>
);

export default ElevatorIcon;
