import { useSelector } from 'react-redux';
import { selectTotalTrips } from '../services/selectors';
import { useFetchTotalTrips } from 'modules/entities/modules/trips';
import { ElevatorTotalTripsAggregation } from '../../entities/modules/elevators';

const useTotalTrips = ({
    startTime,
    endTime,
    granularity,
}: {
    startTime: string;
    endTime: string;
    granularity: ElevatorTotalTripsAggregation;
}) => {
    const api = useFetchTotalTrips(startTime, endTime, granularity);
    const trips = useSelector(state => selectTotalTrips(state, granularity));

    return { trips, ...api };
};

export default useTotalTrips;
