import { basicApiReducer } from '@ackee/redux-utils';
import { fetchIncidentsTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchIncidentsTypes.FETCH_INCIDENTS_REQUEST,
        SUCCESS: fetchIncidentsTypes.FETCH_INCIDENTS_SUCCESS,
        FAILURE: fetchIncidentsTypes.FETCH_INCIDENTS_FAILURE,
        CANCEL: fetchIncidentsTypes.FETCH_INCIDENTS_CANCEL,
        RESET: fetchIncidentsTypes.FETCH_INCIDENTS_RESET,
    },
});
