import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';
import { SeoHead } from 'modules/seo';

import { FakeType } from '../../constants';
import { useFakeSubscribe } from '../../hooks/useFakeSubscribe';

import { FakeCTA } from '../FakeCTA';
import { FakeSuccess } from '../FakeSuccess';
import { FakeInfo } from '../FakeInfo';

import * as felaRules from './FakePage.rules';

export interface FakePageProps {
    type: FakeType;
}

export const FakePage = ({ type }: FakePageProps) => {
    const intl = useIntl();

    const { styles } = useFelaEnhanced(felaRules);

    const { submitting, succeeded, handleClick } = useFakeSubscribe(type);

    const items: Record<FakeType, [ReactNode, ReactNode, ReactNode]> = useMemo(
        () => ({
            [FakeType.MAINTENANCE_ANALYSIS]: [
                <>
                    <Icon type={IconType.MAINTENANCE_ANALYSIS_COST_SAVE} className={styles.costSave} />
                    <FormattedMessage id={`page.fakePages.${type}.item-1`} />
                </>,
                <>
                    <Icon type={IconType.MAINTENANCE_ANALYSIS_MANAGEMENT} className={styles.management} />
                    <FormattedMessage id={`page.fakePages.${type}.item-2`} />
                </>,
                <>
                    <Icon type={IconType.MAINTENANCE_ANALYSIS_THUMBS_UP} className={styles.thumbsUp} />
                    <FormattedMessage id={`page.fakePages.${type}.item-3`} />
                </>,
            ],
            [FakeType.OPERATOR_OBLIGATION]: [
                <>
                    <Icon type={IconType.OPERATOR_OBLIGATIONS_OVERVIEW} className={styles.overview} />
                    <FormattedMessage id={`page.fakePages.${type}.item-1`} />
                </>,
                <>
                    <Icon type={IconType.OPERATOR_OBLIGATIONS_TRACKING} className={styles.tracking} />
                    <FormattedMessage id={`page.fakePages.${type}.item-2`} />
                </>,
                <>
                    <Icon type={IconType.OPERATOR_OBLIGATIONS_DATE} className={styles.date} />
                    <FormattedMessage id={`page.fakePages.${type}.item-3`} />
                </>,
            ],
        }),
        [styles.costSave, styles.management, styles.thumbsUp, styles.overview, styles.tracking, styles.date, type],
    );

    return (
        <div data-testid="fake-page" className={styles.container}>
            <SeoHead title={intl.formatMessage({ id: `page.fakePages.${type}.seoTitle` })} />
            <FakeInfo
                title={<FormattedMessage id={`page.fakePages.${type}.title`} />}
                description={<FormattedMessage id={`page.fakePages.${type}.description`} />}
                items={items[type]}
            />
            <div className={styles.wrapper}>
                {succeeded ? (
                    <FakeSuccess />
                ) : (
                    <FakeCTA
                        submitting={submitting}
                        type={type}
                        onClick={
                            type === FakeType.MAINTENANCE_ANALYSIS
                                ? () => {
                                      window.location.replace('https://www.simplifa.de/produkte/elevator-live/');
                                  }
                                : handleClick
                        }
                       
                    />
                )}
            </div>
            <Typography.Paragraph className={styles.message}>
                <FormattedMessage
                    id={`page.fakePages.${type}.message`}
                    values={{
                        ul: (children: any) => <ul>{children}</ul>,
                        li: (children: any) => <li>{children}</li>,
                    }}
                />
            </Typography.Paragraph>
        </div>
    );
};
