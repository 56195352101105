import React, { FC, useContext } from 'react';
import { Row, Col } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useIotConfig } from 'modules/entities/modules/config/hooks';
import { useFelaEnhanced } from 'hooks';
import { Button } from 'modules/ui';
import config from 'config';
import { ElevatorDetail } from 'modules/entities/modules/elevators';
import { DialogState } from 'constants/common';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';
import { errorMessages, HTTPStatusCode, NotFound } from 'modules/errors';

import ActiveIncidentWidget from '../ActiveIncidentWidget';
import * as felaRules from './ActiveIncident.styles';
import useIncidentWidget from 'modules/elevator-detail/hooks/useIncidentWidget';
import useRole from 'modules/auth/hooks/useRole';

const ActiveIncident: FC<{ elevator: ElevatorDetail; withShadows?: boolean }> = ({ elevator, withShadows = false }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { activeIncident, error, inProgress } = useIncidentWidget();
    const iotConfig = useIotConfig();
    const { isGuest } = useRole()
    const { navigate } = useContext(GlobalDialogContext);
    const { formatMessage } = useIntl();

    if (error === errorMessages.status[HTTPStatusCode.NotFound]) {
        return <NotFound />;
    }

    const handleCreateIncident = () => {
        navigate(DialogState.CREATE_INCIDENT, elevator);
    };

    return (
        <div className={withShadows ? styles.containerWithShadows : styles.container}>
            <Row className={styles.headingDiv}>
                <Col span={12}>
                    <span className={styles.heading}>
                        <FormattedMessage id="page.elevatorDetail.tabs.iotData.activeIncidents.heading" />
                    </span>
                </Col>
               {!isGuest && <Col className={styles.button} span={12}>
                    <Button
                        className={styles.reportIncidentButton}
                        type="primary"
                        size="middle"
                        onClick={handleCreateIncident}
                    >
                        {formatMessage({ id: 'incident.new.trigger' })}
                    </Button>
                </Col>}
            </Row>

            <ActiveIncidentWidget inProgress={inProgress} error={error} activeIncident={activeIncident} />

            <div className={styles.buttonContainer}>
                <Link to={config.routes.incidents}>
                    <Button
                        disabled={!Object.values(iotConfig)[0]?.incidentsPerElevator}
                        className={styles.seeAllButton}
                        onClick={() => {}}
                    >
                        <FormattedMessage id="elevator.history.seeAll" />
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default ActiveIncident;
