import React, { FC, ReactNode } from 'react';
import {
    Bar,
    BarChart as ReBarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as ReTooltip,
    Cell,
    Legend as ReLegend,
} from 'recharts';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import Legend from './Legend';
import Tooltip from './Tooltip';
import * as felaRules from './BarChart.styles';

export interface BarChartProps {
    data: any[];
    width: number;
    height: number;
    layout?: 'vertical' | 'horizontal';
    barDataKeys?: string[];
    barCategoryGap?: string | number;
    customTooltip?: boolean;
    minTickGap?: number;
    axisDataKey?: string;
    yAxisDataKey?: string;
    names?: { [key: string]: string | ReactNode };
    legend?: boolean;
    showGrid?: boolean;
    barColors?: { [key: string]: string };
    barRadius?: number;
    barSize?: number;
    barGap?: number;
    axes: ReactNode[];
    extend?: RulesExtend<typeof felaRules>;
}

const BarChart: FC<BarChartProps> = ({
    data,
    width,
    height,
    layout = 'horizontal',
    barDataKeys = ['value'],
    axisDataKey = 'name',
    barCategoryGap = '20%',
    customTooltip = false,
    names,
    legend = false,
    showGrid = true,
    barColors = {},
    barRadius = 0,
    barSize,
    barGap,
    axes,
    extend,
}) => {
    const { styles, theme } = useFelaEnhanced(felaRules, { extend });

    return (
        <ResponsiveContainer width="100%" height={height} className={styles.container}>
            <ReBarChart
                width={width}
                height={height}
                data={data}
                barCategoryGap={barCategoryGap}
                layout={layout}
                barSize={barSize}
                barGap={barGap}
            >
                {showGrid && <CartesianGrid stroke={theme.colors.graphInfoColor} />}
                {axes}
                <ReTooltip content={customTooltip ? <Tooltip names={names} /> : null} />
                {barDataKeys.map(dataKey => (
                    <Bar
                        key={dataKey}
                        dataKey={dataKey}
                        fill={barColors[dataKey] ?? theme.colors.lightBlue}
                        radius={barRadius}
                    >
                        {data.map((entry, index) => (
                            <Cell key={String(index)} id={`${entry[axisDataKey]}:${entry[dataKey]}`} />
                        ))}
                    </Bar>
                ))}
                {legend && names && <ReLegend content={<Legend legendNames={names} />} />}
            </ReBarChart>
        </ResponsiveContainer>
    );
};

export default BarChart;
