import React, { FC } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Typography } from 'antd';

import config from 'config';
import { DataCard, Button } from 'modules/ui';
import { useElevatorStates } from 'modules/entities/modules/elevator-states';

import * as felaRules from './History.styles';
import { HISTORY_WIDGET_PAGE_SIZE } from '../../../config';
import ElevatorHistoryList from '../../ElevatorHistoryList';

const History: FC = () => {
    const { id } = useParams<{ id: string }>();
    const {
        api: { inProgress, error },
        data,
    } = useElevatorStates(id, { pageSize: HISTORY_WIDGET_PAGE_SIZE });

    return (
        <Col xs={24} lg={12} xl={24}>
            <DataCard
                title={
                    <Typography.Title level={4}>
                        <FormattedMessage id="elevator.history.title" />
                    </Typography.Title>
                }
                loading={inProgress}
                footer={
                    data?.length > 0 && (
                        <Button>
                            <Link to={generatePath(config.routes.elevatorHistory, { id })}>
                                <FormattedMessage id="elevator.history.seeAll" />
                            </Link>
                        </Button>
                    )
                }
                error={error}
                extend={{ footer: felaRules.footer }}
                empty={data?.length === 0}
            >
                <ElevatorHistoryList elevatorStates={data} />
            </DataCard>
        </Col>
    );
};

export default History;
