import React, { FC, ReactElement } from 'react';
import { Card, CardProps, Alert, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';

import { Icon, IconType } from '../Icon';

import * as felaRules from './DataCard.styles';

interface DataCardProps extends CardProps {
    subtitle?: ReactElement;
    footer?: ReactElement;
    backLink?: string;
    error?: string;
    empty?: boolean;
    spacingSize?: 'medium' | 'large';
    customEmptyCard?: ReactElement;
    extend?: RulesExtend<typeof felaRules>;
}

const DataCard: FC<DataCardProps> = ({
    children,
    footer,
    title,
    subtitle,
    backLink,
    error,
    extend,
    empty = false,
    customEmptyCard,
    spacingSize = 'medium',
    ...rest
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, spacingSize });

    const _empty = empty || isEmpty(children);

    return (
        <Card
            title={
                title ? (
                    <>
                        <div className={styles.heading}>
                            {backLink && (
                                <Link to={backLink} className={styles.backLink}>
                                    <Icon type={IconType.CHEVRON_LEFT} />
                                </Link>
                            )}
                            {title}
                        </div>
                        {subtitle}
                    </>
                ) : null
            }
            className={styles.card}
            {...rest}
        >
            {error && <Alert type="error" message={<FormattedMessage id={error} />} />}
            {!error && _empty && (customEmptyCard ? customEmptyCard : <Empty />)}
            {!error && !_empty && children}
            {footer && <div className={styles.footer}>{footer}</div>}
        </Card>
    );
};

export default DataCard;
