import { apiSelector, ApiReducerState } from '@ackee/redux-utils';
import { ApiReducerKey, EntityKey } from '../../../../../constants';
import { AverageTrips, TotalTrips } from '../types';

export const selectAverageTripsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TRIPS, ApiReducerKey.AVERAGE_LIST);

export const selectTotalTripsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.TRIPS, ApiReducerKey.TOTAL_LIST);

export const selectAverageTrips = (state): AverageTrips[] =>
    state.entities[EntityKey.TRIPS][ApiReducerKey.AVERAGE_LIST];
export const selectTotalTrips = (state): TotalTrips[] => state.entities[EntityKey.TRIPS][ApiReducerKey.TOTAL_LIST];
