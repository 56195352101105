import { Support } from 'modules/entities/modules/support/types';
import { createByIds, createIds } from 'modules/entities/services/utils';
import { ApiAction, ListReducerState } from 'modules/entities/types';
import { fetchSupportsTypes } from '../../actions';

const initialState: {
    categories: string[];
    openSupports: ListReducerState<Support, Support['caseNumber']>;
    closedSupports: ListReducerState<Support, Support['caseNumber']>;
} = {
    categories: [],
    openSupports: { byIds: {}, ids: [] },
    closedSupports: { byIds: {}, ids: [] },
};

export default (state = initialState, action: ApiAction) => {
    switch (action.type) {
        case fetchSupportsTypes.FETCH_SUPPORTS_SUCCESS: {
            const op: Support[] = action.payload.openSupports;
            const cp: Support[] = action.payload.closedSupports;
            const categories = action.payload.categories;
            const openSupports = {
                byIds: createByIds(op),
                ids: createIds(op),
            };

            const closedSupports = {
                byIds: createByIds(cp),
                ids: createIds(cp),
            };
            return {
                openSupports,
                closedSupports,
                categories,
            };
        }

        case fetchSupportsTypes.FETCH_SUPPORTS_RESET:
            return initialState;

        default:
            return state;
    }
};
