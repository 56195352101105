import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import * as felaRules from './IncidentCardItem.styles';
import PhaseTag from '../IncidentPhaseTag/PhaseTag';

interface Props {
    label?: string;
    id?: string;
    value?: string | number;
    link?: { link?: string; id?: string };
}

const IncidentCardItem: FC<Props> = ({ label, value, link, id }) => {
    const { styles } = useFelaEnhanced(felaRules);
    let content;
    if (link) {
        content = (
            <Link to={generatePath(link.link, { id: link.id })}>
                <span className={`${styles.value} ${styles.factoryNumber}`}>{value}</span>
            </Link>
        );
    } else {
        content = <span className={styles.value}>{value}</span>;
    }
    return (
        <div className={styles.cell}>
            <div className={styles.title}>
                <FormattedMessage id={id ? id : `page.incidents.table.${label}`} />
            </div>

            {label === 'phase' ? <PhaseTag status={value} /> : content}
        </div>
    );
};

export default IncidentCardItem;
