// import regular from 'assets/fonts/AvenirNext/AvenirNext-Regular.ttf';
// import demiBold from 'assets/fonts/AvenirNext/AvenirNext-DemiBold.ttf';
import regular from 'assets/fonts/WorkSans/WorkSans-Regular.ttf';
import semiBold from 'assets/fonts/WorkSans/WorkSans-SemiBold.ttf';

export default [
    {
        name: 'WorkSans',
        files: [regular],
        options: {
            fontStyle: 'regular',
            fontWeight: 500,
            fontDisplay: 'swap',
        },
    },
    {
        name: 'WorkSans',
        files: [semiBold],
        options: {
            fontStyle: 'regular',
            fontWeight: 600,
            fontDisplay: 'swap',
        },
    },
];
