import type { TRuleWithTheme } from 'styles/theme';
import bg from 'assets/images/auth_background.svg';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${bg})`,
    backgroundRepeat:'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
});

export const logo: TRuleWithTheme = () => ({
    position: 'fixed',
    top: '10px',
    height: 165,
    left: '1rem',
});

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    padding: '2rem',

    marginLeft: '1rem',
    marginRight: '1rem',

    maxWidth: 400,
    width: '100%',

    background: theme.colors.white,

    borderRadius: 2,

    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.0576257))',
});
