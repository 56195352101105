import React from 'react';

const Book = () => (
    // <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path
    //         d="M11 4.4897V19.4897M6.75 7.4897H4.5M7.5 10.4897H4.5M21 15.7397V3.6697C21 2.4697 20.02 1.5797 18.83 1.6797H18.77C16.67 1.8597 13.48 2.9297 11.7 4.0497L11.53 4.1597C11.24 4.3397 10.76 4.3397 10.47 4.1597L10.22 4.0097C8.44 2.8997 5.26 1.8397 3.16 1.6697C1.97 1.5697 1 2.4697 1 3.6597V15.7397C1 16.6997 1.78 17.5997 2.74 17.7197L3.03 17.7597C5.2 18.0497 8.55 19.1497 10.47 20.1997L10.51 20.2197C10.78 20.3697 11.21 20.3697 11.47 20.2197C13.39 19.1597 16.75 18.0497 18.93 17.7597L19.26 17.7197C20.22 17.5997 21 16.6997 21 15.7397Z"
    //         stroke="#A6A6A6"
    //         stroke-width="1.5"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //     />
    // </svg>
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27 1.14064e-06H19.85C18.3156 1.14064e-06 16.8156 0.440626 15.525 1.27188L14 2.25L12.475 1.27188C11.1857 0.440786 9.68398 -0.000819127 8.15 1.14064e-06H1C0.446875 1.14064e-06 0 0.446876 0 1V18.75C0 19.3031 0.446875 19.75 1 19.75H8.15C9.68437 19.75 11.1844 20.1906 12.475 21.0219L13.8625 21.9156C13.9031 21.9406 13.95 21.9563 13.9969 21.9563C14.0437 21.9563 14.0906 21.9438 14.1313 21.9156L15.5187 21.0219C16.8125 20.1906 18.3156 19.75 19.85 19.75H27C27.5531 19.75 28 19.3031 28 18.75V1C28 0.446876 27.5531 1.14064e-06 27 1.14064e-06ZM8.15 17.5H2.25V2.25H8.15C9.25625 2.25 10.3312 2.56563 11.2594 3.1625L12.7844 4.14063L13 4.28125V18.7188C11.5125 17.9188 9.85 17.5 8.15 17.5ZM25.75 17.5H19.85C18.15 17.5 16.4875 17.9188 15 18.7188V4.28125L15.2156 4.14063L16.7406 3.1625C17.6688 2.56563 18.7437 2.25 19.85 2.25H25.75V17.5ZM10.4031 6.25H4.59688C4.475 6.25 4.375 6.35625 4.375 6.48438V7.89063C4.375 8.01875 4.475 8.125 4.59688 8.125H10.4C10.5219 8.125 10.6219 8.01875 10.6219 7.89063V6.48438C10.625 6.35625 10.525 6.25 10.4031 6.25ZM17.375 6.48438V7.89063C17.375 8.01875 17.475 8.125 17.5969 8.125H23.4C23.5219 8.125 23.6219 8.01875 23.6219 7.89063V6.48438C23.6219 6.35625 23.5219 6.25 23.4 6.25H17.5969C17.475 6.25 17.375 6.35625 17.375 6.48438ZM10.4031 10.625H4.59688C4.475 10.625 4.375 10.7313 4.375 10.8594V12.2656C4.375 12.3938 4.475 12.5 4.59688 12.5H10.4C10.5219 12.5 10.6219 12.3938 10.6219 12.2656V10.8594C10.625 10.7313 10.525 10.625 10.4031 10.625ZM23.4031 10.625H17.5969C17.475 10.625 17.375 10.7313 17.375 10.8594V12.2656C17.375 12.3938 17.475 12.5 17.5969 12.5H23.4C23.5219 12.5 23.6219 12.3938 23.6219 12.2656V10.8594C23.625 10.7313 23.525 10.625 23.4031 10.625Z"
            fill="#A6A6A6"
        />
    </svg>
);

export default Book;
