import React, { useMemo, useState } from 'react';
import { MapStatusFilter } from '../ElevatorMapStatusFilter';
import { MapWrapper } from '../MapWrapper';
import { Map } from '../Map';
import { useMapWorkorders } from 'modules/entities/modules/workorders';
import { WorkorderField } from 'modules/entities/modules/workorders';

export const WorkOrdersMap = () => {
    const [filter, setFilter] = useState<string | null>(null);

    const search = useMemo(() => (filter ? [{ field: WorkorderField.STATUS, query: filter }] : []), [filter]);

    const { workorderGroups, loading, empty } = useMapWorkorders(search);
    return (
        <MapWrapper loading={loading} empty={empty}>
            {loading || empty ? null : <MapStatusFilter value={filter} onChange={setFilter} />}
            <Map workorderGroups={workorderGroups} />
        </MapWrapper>
    );
};
