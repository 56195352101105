import type { TRuleWithTheme } from 'styles/theme';

export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        color: theme.colors.textSecondary,

        fontSize: '1rem',
        lineHeight: 1.5,

        marginTop: '0.25rem',
        marginBottom: '1.5rem',
    },
});

export const navigation: TRuleWithTheme = () => ({
    display: 'grid',

    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',

    marginBottom: '0.5rem',
});
