import { createStore } from 'redux';
import { END } from 'redux-saga';
import { persistStore } from 'redux-persist';
import * as Consts from 'constants/index';

import rootReducer from '../services/reducer';
import rootSaga from '../services/saga';

import configureEnhancer from './enhancer';

export default function configureStore() {
    const initialState = {};
    const { enhancer, sagaMiddleware } = configureEnhancer();

    const store: any = createStore(rootReducer, initialState, enhancer as any);
    const persistor = persistStore(store);

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    if (!Consts.isServerEnv) {
        store.runSaga(rootSaga);
    }

    return { store, persistor };
}
