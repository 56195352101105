import { Cluster } from '@googlemaps/markerclusterer';
import { WORKORDER_STATE_BY_MARKER_ICON, MarkerIcon } from '../types';
import { WorkorderGroup, WorkOrderState, WorkorderStateGroup } from 'modules/entities/modules/workorders';

const statesHierarchy = [
    WorkOrderState.TECHNICIAN_REVIEW_REQUIRED,
    WorkOrderState.WAITING_FOR_TECHNICIAN,
    WorkOrderState.WAITING_FOR_ASSISTANT,
    WorkOrderState.IN_CLARIFICATION,
    WorkOrderState.IN_REVIEW,
    WorkOrderState.NEW,
    WorkOrderState.RUNNING,
    WorkOrderState.FULFILLED,
    WorkOrderState.FINISHED,
    WorkOrderState.NOT_RELEVANT,
    WorkOrderState.REJECTED,
    WorkOrderState.TECHNICAL_Q_CHECK_DONE,
];

const anyWorkorderHasState = (workorderGroup: WorkorderGroup, state: WorkOrderState) => {
    const { workorders } = workorderGroup;
    return Boolean(workorders.find(workorder => workorder.Status__c === state));
};

/* If workorders which are grouped together have a different status,
the state displayed on the pin needle is shown according to the following hierarchy:
    > not technician review required  > waiting for technician > waiting for assistant 
    > in clarification > In Review > new > running > fullfiled
    > not relevant > rejected > technical check done
 */
export const getWorkorderGroupState = (workorderGroup: WorkorderGroup): WorkOrderState | WorkorderStateGroup => {
    return statesHierarchy.find(state => anyWorkorderHasState(workorderGroup, state)) ?? WorkorderStateGroup.UNKNOWN;
};

const anyMarkerHasState = (markerIcons: MarkerIcon[], state: WorkOrderState) => {
    return Boolean(markerIcons.find(markerIcon => WORKORDER_STATE_BY_MARKER_ICON[markerIcon] === state));
};

// Same logic for clusters as in `getWorkorderGroupState`
export const getWorkorderClusterState = (cluster: Cluster): WorkOrderState | WorkorderStateGroup => {
    const markerIcons = cluster.markers.map(marker => marker.getIcon() as MarkerIcon);

    return statesHierarchy.find(state => anyMarkerHasState(markerIcons, state)) ?? WorkorderStateGroup.UNKNOWN;
};
