


export enum ErrorTypes {
    NO_CONTRACT = 'NO_CONTRACT',
    SEVERITY = 'SEVERITY',
}

export interface NewIncidentFormValues {
    elevatorId: string;
    elevator?: string;
    description: string;
    incidentType: string;
    status?: string;
    subStatus?: string;
}
