import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { LoginFormValues } from '../types';
import { loginRequest } from '../services/action/authentication';
import { loginAuthenticatorSelector } from '../services/selectors';

export const useUserAuthentication = () => {
    const api = useSelector(loginAuthenticatorSelector);

    const dispatch = useDispatch();

    const login = useCallback(
        ({ email, password }: LoginFormValues) => {
            dispatch(
                loginRequest({
                    email,
                    password,
                }),
            );
        },
        [dispatch],
    );

    return {
        api,
        login,
    };
};
