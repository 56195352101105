import type { TRule } from 'fela';

export const container: TRule<{ inProgress: boolean }> = ({ inProgress }) => ({
    position: 'relative',
    height: inProgress ? 100 : 'auto',
    marginTop: '25px'
});

export const deviceIotContainerBlur: TRule<{ inProgress: boolean }> = ({ inProgress }) => ({
    position: 'absolute',
    flex: 1,
    backgroundColor: '#f0f2f5',
    zIndex: 100,
    width: '100%',
    height: '100%',
    opacity: 0.9,
});

export const loader: TRule = () => ({
    backgroundColor: 'transparent',
});

export const statusCardCol: TRule = () => ({
    '@media (max-width: 1600px)': {
        '& .ant-card': {
            height: '100%',
        },
    },
});
