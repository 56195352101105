import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import * as felaRules from './SupportCard.styles';

import SupportProgressTag from '../SupportProgressTag';
import { Support } from 'modules/entities/modules/support';
import IncidentCardItem from 'modules/incidents/components/IncidentCardItem/IncidentCardItem';

const COLORS = {
    BLUE: 'blue',
    RED: 'red',
    YELLOW: 'yellow',
    PURPLE: 'purple',
    GREEN: 'green',
};

const SupportCard: FC<{ request: Support; isClosed: boolean }> = ({ request, isClosed }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const intl = useIntl();

    function renderTag() {
        let color = COLORS.BLUE;

        if (
            request?.status?.toLocaleLowerCase().includes('neu') ||
            request?.status?.toLocaleLowerCase().includes('new')
        ) {
            color = COLORS.BLUE;
        }
        if (
            request?.status?.toLocaleLowerCase().includes('kunde') ||
            request?.status?.toLocaleLowerCase().includes('customer') ||
            request?.status?.toLocaleLowerCase().includes('progress') ||
            request?.status?.toLocaleLowerCase().includes('warten')
        ) {
            color = COLORS.YELLOW;
        }

        if (request?.status?.toLocaleLowerCase().includes('simplifa')) {
            color = COLORS.PURPLE;
        }

        if (
            request?.status?.toLocaleLowerCase().includes('geschlossen') ||
            request?.status?.toLocaleLowerCase().includes('done')
        ) {
            color = COLORS.GREEN;
        }
        return (
            <div className={styles.tag}>
                <SupportProgressTag color={color}>{request.status}</SupportProgressTag>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.topSelect}>
                {renderTag()}
                <span className={styles.requestTitle}>{request.subject}</span>
            </div>
            <div className={styles.rowtop}>
                <IncidentCardItem id="page.requests.table.requestNumber" value={request.id} />
                <IncidentCardItem id="page.requests.table.creator" value={request.contactName} />
                <IncidentCardItem id="page.requests.table.category" value={request.category} />
                <IncidentCardItem id="page.requests.table.origin" value={request.caseOrigin} />
                <IncidentCardItem
                    id="page.requests.table.opened"
                    value={moment(request?.dateTimeOpened).format('DD.MM.YYYY, HH:mm')}
                />
                <IncidentCardItem
                    id="page.requests.table.daysOpen"
                    value={`${request.caseAge} ${intl.formatMessage({
                        id:
                            request.caseAge > 0 || request.caseAge < 0
                                ? 'page.requests.table.days'
                                : 'page.requests.table.day',
                    })}`}
                />

                {!!isClosed && (
                    <IncidentCardItem
                        id="page.requests.table.closed"
                        value={moment(request?.closedDate).format('DD.MM.YYYY, HH:mm')}
                    />
                )}
            </div>
            <div className={styles.rowBottom}>
                <div className={styles.cellFlex}>
                    <span className={styles.title}>
                        <FormattedMessage id="page.requests.table.description" />
                    </span>
                    <span className={styles.desc}>{request.description}</span>
                </div>
            </div>
        </div>
    );
};

export default SupportCard;
