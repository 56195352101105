import { Cluster } from '@googlemaps/markerclusterer';
import type { ElevatorGroup } from 'modules/entities/modules/elevators';
import { ElevatorState, ElevatorStateGroup } from 'modules/entities/modules/elevators/types';
import { ELEVATOR_STATE_BY_MARKER_ICON, MarkerIcon } from '../types';

const statesHierarchy = [
    ElevatorState.OUT_OF_ORDER,
    ElevatorState.POTENTIAL_ISSUES,
    ElevatorState.TECHNICIAN_ON_SITE,
    ElevatorState.RUNNING,
];

const anyElevatorHasState = (elevatorGroup: ElevatorGroup, state: ElevatorState) => {
    const { elevators } = elevatorGroup;

    return Boolean(elevators.find(elevator => elevator.state?.state === state));
};

// If elevators which are grouped together have a different status,
// the state displayed on the pin needle is shown according to the following hierarchy:
// not operational > has issues > technician on site > operational > unknown
export const getElevatorGroupState = (elevatorGroup: ElevatorGroup): ElevatorState | ElevatorStateGroup => {
    return statesHierarchy.find(state => anyElevatorHasState(elevatorGroup, state)) ?? ElevatorStateGroup.UNKNOWN;
};

const anyMarkerHasState = (markerIcons: MarkerIcon[], state: ElevatorState) => {
    return Boolean(markerIcons.find(markerIcon => ELEVATOR_STATE_BY_MARKER_ICON[markerIcon] === state));
};

// Same logic for clusters as in `getElevatorGroupState`
export const getElevatorClusterState = (cluster: Cluster): ElevatorState | ElevatorStateGroup => {
    const markerIcons = cluster.markers.map(marker => marker.getIcon() as MarkerIcon);

    return statesHierarchy.find(state => anyMarkerHasState(markerIcons, state)) ?? ElevatorStateGroup.UNKNOWN;
};
