import type { TRuleWithTheme } from 'styles/theme';

export const table: TRuleWithTheme = () => ({
    '& td': {
        '&:hover': {
            cursor: 'auto',
        },
    },
    '& tr': {
        cursor: 'auto',
        '&:hover': {
            cursor: 'auto !important',
            backgroundColor: 'white !important'
        },
    },

    
    '& .ant-table-tbody > tr.ant-table-row:hover > td': {
        backgroundColor: 'white !important',
        '& > hover': { backgroundColor: 'white !important' },
    },
});
