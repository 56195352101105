import React, { FC } from 'react';

interface Props {
    className?: string;
}

const LogoWhite: FC<Props> = ({ className }) => (
    <svg
        width="136"
        className={className}
        height="48"
        viewBox="0 0 136 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 15.5288V0.231774H11.0788V2.36409H2.47998V6.7678H9.20142V8.85376H2.47998V13.3965H11.4033V15.5288H0Z"
            fill="#3D0464"
        />
        <path d="M20.3438 13.3965H28.7803V15.5288H17.8638V0.231774H20.3438V13.3965Z" fill="#3D0464" />
        <path
            d="M34.8675 15.5288V0.231774H45.9463V2.36409H37.3475V6.7678H44.0689V8.85376H37.3475V13.3965H46.2708V15.5288H34.8675Z"
            fill="#3D0464"
        />
        <path
            d="M65.2239 0.231774L59.5454 15.5288H56.6482L50.9698 0.231774H53.612L57.1813 10.4066L58.1084 13.4429L59.0355 10.4298L62.6048 0.231774H65.2239Z"
            fill="#3D0464"
        />
        <path
            d="M82.3683 15.5288H79.7492L78.289 11.5192H71.8689L70.4087 15.5288H67.8592L73.6304 0.231774H76.5971L82.3683 15.5288ZM72.5642 9.50273H77.5937L75.0906 2.36409L72.5642 9.50273Z"
            fill="#3D0464"
        />
        <path d="M97.5529 0.231774V2.36409H92.3148V15.5288H89.8348V2.36409H84.5967V0.231774H97.5529Z" fill="#3D0464" />
        <path
            d="M109.459 0C110.943 0 112.225 0.316757 113.307 0.950273C114.388 1.58379 115.223 2.48771 115.81 3.66203C116.397 4.83635 116.691 6.24244 116.691 7.88031C116.691 9.51818 116.397 10.9243 115.81 12.0986C115.223 13.2729 114.388 14.1768 113.307 14.8103C112.225 15.4439 110.943 15.7606 109.459 15.7606C107.991 15.7606 106.717 15.4439 105.635 14.8103C104.553 14.1768 103.719 13.2729 103.132 12.0986C102.545 10.9243 102.251 9.51818 102.251 7.88031C102.251 6.24244 102.545 4.83635 103.132 3.66203C103.719 2.48771 104.553 1.58379 105.635 0.950273C106.717 0.316757 107.991 0 109.459 0ZM109.459 2.1555C108.501 2.1555 107.675 2.37954 106.979 2.82764C106.299 3.27574 105.774 3.9247 105.403 4.77454C105.032 5.62438 104.847 6.65964 104.847 7.88031C104.847 9.08554 105.032 10.1208 105.403 10.9861C105.774 11.8359 106.299 12.4849 106.979 12.933C107.675 13.3811 108.501 13.6051 109.459 13.6051C110.433 13.6051 111.259 13.3811 111.939 12.933C112.634 12.4849 113.168 11.8359 113.538 10.9861C113.909 10.1208 114.095 9.08554 114.095 7.88031C114.095 6.65964 113.909 5.62438 113.538 4.77454C113.168 3.9247 112.634 3.27574 111.939 2.82764C111.259 2.37954 110.433 2.1555 109.459 2.1555Z"
            fill="#3D0464"
        />
        <path
            d="M129.742 0.231774C131.38 0.231774 132.678 0.64124 133.636 1.46017C134.609 2.27911 135.096 3.3839 135.096 4.77454C135.096 6.21154 134.609 7.32405 133.636 8.11208C132.678 8.88466 131.38 9.27095 129.742 9.27095L129.51 9.41002H125.964V15.5288H123.507V0.231774H129.742ZM129.557 7.34723C130.561 7.34723 131.303 7.14636 131.782 6.74462C132.276 6.32743 132.523 5.69391 132.523 4.84407C132.523 4.00969 132.276 3.3839 131.782 2.9667C131.303 2.54951 130.561 2.34092 129.557 2.34092H125.964V7.34723H129.557ZM131.017 7.85713L136 15.5288H133.149L128.885 8.83058L131.017 7.85713Z"
            fill="#3D0464"
        />
        <path
            d="M55.551 27.8129V42.425H53.1599V27.8129H55.551ZM45.9424 27.8129V42.425H43.5735V27.8129H45.9424ZM54.3555 34.0119V36.0488H44.769V34.0119H54.3555Z"
            fill="#3D0464"
        />
        <path
            d="M74.5098 27.8129V36.8237C74.5098 38.7572 74.0005 40.211 72.9821 41.1852C71.9637 42.1593 70.4803 42.6464 68.5321 42.6464C66.6133 42.6464 65.1373 42.1593 64.1042 41.1852C63.0857 40.211 62.5765 38.7572 62.5765 36.8237V27.8129H64.9455V36.5359C64.9455 37.9085 65.2406 38.9269 65.831 39.5911C66.4214 40.2553 67.3218 40.5874 68.5321 40.5874C69.7571 40.5874 70.6648 40.2553 71.2552 39.5911C71.8456 38.9269 72.1408 37.9085 72.1408 36.5359V27.8129H74.5098Z"
            fill="#3D0464"
        />
        <path
            d="M81.5498 42.425V27.8129H87.3061C89.0625 27.8129 90.3466 28.1671 91.1584 28.8756C91.9702 29.584 92.3761 30.5656 92.3761 31.8201C92.3761 32.5876 92.1547 33.274 91.7119 33.8791C91.2691 34.4695 90.6197 34.8459 89.7636 35.0082V35.0304C90.7082 35.178 91.4388 35.5691 91.9554 36.2038C92.4868 36.8237 92.7524 37.5469 92.7524 38.3734C92.7524 39.628 92.2949 40.6169 91.3798 41.3402C90.4647 42.0634 89.1363 42.425 87.3947 42.425H81.5498ZM83.8745 40.4546H87.5054C88.4352 40.4546 89.1289 40.2627 89.5865 39.8789C90.0588 39.4952 90.2949 38.9565 90.2949 38.2627C90.2949 37.569 90.0588 37.0303 89.5865 36.6466C89.1289 36.2628 88.4352 36.0709 87.5054 36.0709H83.8745V40.4546ZM83.8745 34.1005H87.129C88.9887 34.1005 89.9186 33.3847 89.9186 31.953C89.9186 30.5213 88.9887 29.8054 87.129 29.8054H83.8745V34.1005Z"
            fill="#3D0464"
        />
    </svg>
);

export default LogoWhite;
