/* eslint-disable camelcase */
import React from 'react';
const config = {
    api: {
        base: 'https://api-customer.simplifa.de/api',
    },
    devTools: true,
    mapApiKey: 'AIzaSyDyORr2sgX6yZgYBeyZ2fLOB6mqwJAHNgM',
    aws: {
        amplify: {
            Auth: {
                userPoolId: 'eu-central-1_IesgqwbgO',
                userPoolWebClientId: '4q188ki8i8onft7hdhqe6fq51q',
            },
            aws_appsync_graphqlEndpoint:
                'https://475qin7ltzfffoekr2tkxsjkly.appsync-api.eu-central-1.amazonaws.com/graphql',
            aws_appsync_region: 'eu-central-1',
            aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        },
    },
    analytics: {
        trackingId: 'G-S7DJ33YVWW',
    },
    cookieBot: (
        <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="19b3de6d-d741-4569-85a4-6d2c2c28c835"
            data-blockingmode="auto"
            type="text/javascript"
        ></script>
    ),
    hotjar: {
        id: 3458947,
        version: 6,
    },
    salesforce: 'https://elevoniq.lightning.force.com',
};

export default config;
