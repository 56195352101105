import { createAction } from '@reduxjs/toolkit';
import { USER_STATE } from '../../types';

export const setUserRequest = createAction(`${USER_STATE}/SEND`, ({ user }) => ({
    payload: { user },
    meta: {
        email: user.email,
    },
}));


export const setUserReset = createAction(`${USER_STATE}/SEND_RESET`, () => ({
    payload: null,
    meta: {},
}));
