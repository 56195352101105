import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Select, FloatingLabel } from 'modules/form';
import { SupportSubjectsType } from 'modules/entities/modules/support';

import * as felaRules from './SubjectInput.rules';
import { useSelector } from 'react-redux';
import { selectSupportCategories } from 'modules/entities/modules/support/services/selectors';
export interface SubjectInputProps {
    value: SupportSubjectsType | null;
    onChange: (value: SupportSubjectsType) => void;
    disabled: boolean;
}

export const SubjectInput = ({ value = null, onChange, disabled }: SubjectInputProps) => {
    const intl = useIntl();

    const { styles } = useFelaEnhanced(felaRules);

    const categories = useSelector(selectSupportCategories);

    return (
        <div className={styles.container}>
            <FloatingLabel
                htmlFor="type"
                floating={Boolean(value)}
                label={intl.formatMessage({
                    id: 'support.request.placeholder.subject',
                })}
            >
                {({ onFocus, onBlur }) => (
                    <Select
                        id="type"
                        value={value}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        options={categories}
                        disabled={disabled}
                        placeholder={intl.formatMessage({
                            id: 'support.request.placeholder.subject',
                        })}
                        fullWidth
                        floatingLabel
                    />
                )}
            </FloatingLabel>
        </div>
    );
};
