import { basicApiReducer } from '@ackee/redux-utils';

import { fetchElevatorStateTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_REQUEST,
        CANCEL: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_CANCEL,
        SUCCESS: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_SUCCESS,
        FAILURE: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_FAILURE,
        RESET: fetchElevatorStateTypes.FETCH_ELEVATOR_STATE_RESET,
    },
});
