import { ApiAction } from '../../../../../types';
import { RESET_SELECT_DOWNLOAD, SELECT_DOWNLOAD, UNSELECT_DOWNLOAD } from '../../actions';

export default function (state = [], action: ApiAction): string[] {
    switch (action.type) {
        case SELECT_DOWNLOAD:
            const newValue = [...state, ...action.payload];
            const finalValue = new Set(newValue);

            return Array.from(finalValue);
        case UNSELECT_DOWNLOAD:
            return state.filter(item => item !== action.payload);
        case RESET_SELECT_DOWNLOAD:
            return [];
        default:
            return state;
    }
}
