import { apiSelector } from '@ackee/redux-utils/es';
import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { generateUTCDate, compareValueByType } from 'services/utils';

import { ApiReducerKey, EntityKey, SortingType, Sorting, Search, URL_ARRAY_SEPARATOR } from 'constants/index';
import { dateComparatorFactory, defaultCompare, numberComparatorFactory } from 'modules/table';

import { Workorder, WorkorderField } from '../types';

export const selectWorkordersApi = (state: {}): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_WORKORDER, ApiReducerKey.LIST);

export const selectWorkordersEntityList = (state): Workorder[] =>
    state.entities[EntityKey.DOCUMENT_WORKORDER][ApiReducerKey.LIST];

export const selectWorkorders = createSelector([selectWorkordersEntityList], docs =>
    docs.map(doc => ({
        ...doc,
        file: doc.file ?? null,
    })),
);

export const selectWorkorderHighestRiskOptions = createSelector([selectWorkorders], docs =>
    Array.from(new Set(docs.map(doc => doc.highestRisk)))
        .map(highestRisk => ({ value: highestRisk, label: highestRisk }))
        .filter(Boolean)
        .sort(numberComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

export const selectWorkorderInspectionRecommendationOptions = createSelector([selectWorkorders], docs =>
    Array.from(new Set(docs.flatMap(doc => doc.inspectionRecommendation)))
        .map(inspectionRecommendation => ({ value: inspectionRecommendation, label: inspectionRecommendation }))
        .filter(Boolean)
        .sort(numberComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

const sortWorkorderFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case WorkorderField.HIGHEST_RISK: {
            return numberComparatorFactory(sorting);
        }
        case WorkorderField.INSPECTION_DATE: {
            return dateComparatorFactory(sorting);
        }
        default: {
            return defaultCompare;
        }
    }
};

export const selectSortedWorkorder = createSelector(
    [
        selectWorkorders,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (workorders, sorting) => [...workorders].sort(sortWorkorderFactory(sorting)),
);

export const selectFilteredWorkorders = createSelector(
    [
        selectSortedWorkorder,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (docs, search) =>
        docs.filter(doc =>
            search.every(({ field, query }) => {
                if (field.startsWith(WorkorderField.INSPECTION_DATE)) {
                    const foundItemTime = generateUTCDate(new Date(doc.inspectionDate)).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === WorkorderField.INSPECTION_DATE_FROM) {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === WorkorderField.INSPECTION_DATE_TO) {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                if (field === WorkorderField.INSPECTION_RECOMMENDATION) {
                    const values = query.split(URL_ARRAY_SEPARATOR);
                    return values.some(value =>
                        compareValueByType({
                            value: doc.inspectionRecommendation,
                            query,
                        }),
                    );
                }

                if (field === WorkorderField.HIGHEST_RISK) {
                    const values = query.split(URL_ARRAY_SEPARATOR);
                    return values.some(value =>
                        compareValueByType({
                            value: doc.highestRisk,
                            query,
                        }),
                    );
                }

                return compareValueByType({
                    value: get(doc, field),
                    query,
                });
            }),
        ),
);
