import type { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const title: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.textSecondary,
    marginBottom: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: 500,
});

export const value: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.5rem',
    fontWeight: 500,

    '& .anticon': {
        color: theme.colors.text,
    },
});

export const container: TRule = () => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.25rem',
    paddingTop: '2.125rem',
    ':last-of-type': {
        marginBottom: 0,
    },
});
