import React, { useEffect, useState, FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { Loader } from 'modules/ui';

import { RowLoaderDimensions } from '../../types';

import * as felaRules from './RowLoader.styles';

type RowLoaderProps = {
    show?: boolean;
    rowKey?: string;
};

const RowLoader: FC<RowLoaderProps> = ({ show = false, rowKey }) => {
    const [dimensions, setDimensions] = useState<RowLoaderDimensions>(null);

    const { styles, rules } = useFelaEnhanced(felaRules, { dimensions, show });

    useEffect(() => {
        if (rowKey) {
            const element: HTMLElement = document.querySelector(`[data-row-key="${rowKey}"]`);

            setDimensions({
                y: element.offsetTop,
                height: element.offsetHeight,
                width: element.offsetWidth,
            });
        } else {
            setDimensions(null);
        }
    }, [rowKey]);

    return (
        <div className={styles.loaderContainer}>
            <Loader show={show && Boolean(rowKey)} extend={{ loader: rules.loader }} />
        </div>
    );
};

export default RowLoader;
