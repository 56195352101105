import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    zIndex: 2,
    paddingLeft: '1.63rem',
    paddingRight: '1.12rem',
});

export const info: TRuleWithTheme = () => ({
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    '& .anticon': {
        color: '#A6A6A6 !important',
    },
});
export const icon: TRuleWithTheme = () => ({
    fontSize: '1.26rem',
});
export const text: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1rem',
    color: theme.colors.primary,
    // marginTop: '1px',
    marginLeft: '0.70rem',
    fontWeight: 600,
});
export const button: TRuleWithTheme = ({ theme }) => ({
    height: '32px',
    fontSize: '0.88rem',
    padding: '5px 16px',
    marginRight: '0.63rem',
    minWidth: '5.5rem',
});
export const buttonLoading: TRuleWithTheme = ({ theme }) => ({
    height: '32px',
    fontSize: '0.88rem',
    padding: '5px 16px',
    marginRight: '0.63rem',
    minWidth: '5.5rem',
    backgroundColor: '#F39C70',
    background: '#F39C70 !important',
    borderColor: '#F39C70 !important',
    borderRightColor: '#F39C70 !important',
    borderLeftColor: '#F39C70 !important',
    borderTopColor: '#F39C70 !important',
    borderBottomColor: '#F39C70 !important',
    ':hover': {
        backgroundColor: '#F39C70 !important',
        background: '#F39C70 !important',
        borderColor: '#F39C70 !important',
    },
    '> .ant-btn-primary': {
        backgroundColor: '#F39C70 !important',
        background: '#F39C70 !important',
        borderColor: '#F39C70 !important',
    },
});
export const buttonReject: TRuleWithTheme = ({ theme }) => ({
    color: '#939393',
    height: '32px',
    fontSize: '0.88rem',
    padding: '5px 16px',
    marginRight: '0.63rem',
    minWidth: '5.5rem',
});
export const buttonContainer: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
});
