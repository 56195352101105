import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    width: '100%',
    height: '100%',

    backgroundColor: 'rgba(0, 0, 0, 0.5)',

    borderRadius: '0.125rem',
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.white,

    marginBottom: '0.5rem',
});
export const title: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: '0.125rem',

        fontSize: '1.25rem',
        fontWeight: 600,

        lineHeight: 1.4,

        color: theme.colors.white,
    },
});
export const paragraph: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        marginBottom: '1.5rem',

        fontSize: '1rem',
        fontWeight: 500,

        lineHeight: 1.5,

        color: theme.colors.white,
    },
});
