import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    postSupportRequestFailure,
    postSupportRequestRequest,
    postSupportRequestSuccess,
    postSupportRequestReset,
} from '../../actions';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: postSupportRequestRequest.toString(),
        SUCCESS: postSupportRequestSuccess.toString(),
        FAILURE: postSupportRequestFailure.toString(),
        RESET: postSupportRequestReset.toString(),
    },
});

export const supportApiReducer = containerReducer({
    childReducer,
    actionTypes: [
        postSupportRequestRequest.toString(),
        postSupportRequestSuccess.toString(),
        postSupportRequestFailure.toString(),
        postSupportRequestReset.toString(),
    ],
    selectors: {
        itemId: () => 'support',
    },
});
