import { TRuleWithTheme } from 'styles/theme';

export const tabs: TRuleWithTheme = () => ({
    paddingTop: '0.78125rem',
});

export const tab: TRuleWithTheme = () => ({
    '& span': {
        marginLeft: '10px',
        color: '#CBCBCB !important',
    },
});

export const noPadding: TRuleWithTheme = () => ({
    paddingTop: '0rem',
    '& .ant-tabs-nav': {
        margin: '0em 0px 1.5em;',
    },
});

export const container = () => ({
    '> #documents-tabs': {
        '& .ant-tabs-tab + .ant-tabs-tab': {
            margin: '0 0 0 10px',
        },
        '> .ant-tabs-tab-btn': {
            fontSize: '0.9rem',
        },
        '> .ant-tabs-nav': {
            '> .ant-tabs-nav-wrap': {
                '> .ant-tabs-nav-list': {
                    '> .ant-tabs-tab-active': {
                        padding: '0px',
                        background: 'transparent',
                        borderBottom: 'transparent',
                    },
                    '> .ant-tabs-tab': {
                        padding: '0px',
                        paddingTop: '12px',
                        paddingBottom: '10px',
                        background: 'transparent',
                        '> .ant-tabs-tab-btn': {
                            fontSize: '0.9rem',
                        },
                    },
                },
            },
        },
    },
});
