import React, { FC, ReactNode } from 'react';
import { FormattedTime } from 'react-intl';
import { Alert } from 'antd';

import { useFelaEnhanced } from 'hooks';
import type { RulesExtend } from 'styles/theme';
import { Paragraph } from 'modules/ui';

import * as felaRules from './DetailedStatusAlert.styles';

export interface DetailedStatusAlertProps {
    date?: string;
    formattedDate?: string;
    title: ReactNode;
    icon: ReactNode;
    layout?: 'vertical' | 'horizontal';
    extend?: RulesExtend<typeof felaRules>;
    descriptions?: ReactNode[];
}

const DetailedStatusAlert: FC<DetailedStatusAlertProps> = ({
    date,
    formattedDate,
    title,
    icon,
    layout = 'vertical',
    extend,
    descriptions,
}) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, layout });

    return (
        <Alert
            type="info"
            className={styles.alert}
            showIcon
            message={
                <>
                    {(date || formattedDate) && (
                        <p className={styles.date}>
                            {formattedDate ?? <FormattedTime value={date} timeZone="Europe/Berlin" />}
                        </p>
                    )}
                    <h3 className={styles.title}>{title}</h3>
                    {descriptions && (
                        <div className={styles.descriptionWrapper}>
                            {descriptions.map((description, index) => (
                                <Paragraph key={index} size="small" faded>
                                    {description}
                                </Paragraph>
                            ))}
                        </div>
                    )}
                </>
            }
            icon={icon}
        />
    );
};

export default DetailedStatusAlert;
