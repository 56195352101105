import { DocumentElevator, DocumentFile } from '../documents/types';

export enum OtherDocumentState {
    SHOW_ALL = 'show_all',
    NEW = 'new',
    ONGOING = 'ongoing',
    DONE = 'done',
}

export interface OtherDocument {
    documentType: string;
    documentDate: string;
    status: OtherDocumentState;
    sendBy: string;
    id: string;
    name: string;
    elevators: DocumentElevator[];
    file?: DocumentFile;
}
