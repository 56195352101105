import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchIncidents as actions } from '../services/actions';
import { selectIncidentsApi } from '../services/selectors';

export const useFetchIncidents = () => {
    const api = useSelector(selectIncidentsApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchIncidentsRequest());

        return () => {
            batch(() => {
                dispatch(actions.fetchIncidentsCancel());
                dispatch(actions.fetchIncidentsReset());
            });
        };
    }, [dispatch]);

    return api;
};
