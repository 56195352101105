import React from 'react';

const ChevronLeft = () => (
    <svg width="12px" height="22px" viewBox="0 0 12 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="elevator_detail" transform="translate(-264.000000, -101.000000)" fill="currentColor">
                <g id="Fill-1" transform="translate(260.000000, 100.000000)">
                    <path d="M14.293,22.4141 L4.293,12.4141 C3.902,12.0231 3.902,11.3911 4.293,11.0001 L14.293,1.0001 L15.707,2.4141 L6.414,11.7071 L15.707,21.0001 L14.293,22.4141 Z" />
                </g>
            </g>
        </g>
    </svg>
);
export default ChevronLeft;
