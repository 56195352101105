import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';

import { MARKER_TYPES } from '../types';
import { getWorkorderClusterState } from './colorPriority';
import { createInfoWindow } from './infoWindow';
import { WorkorderGroup } from 'modules/entities/modules/workorders';

interface MarkerLabel extends Omit<google.maps.MarkerLabel, 'text' | 'color'> {}

const renderCluster = (map: google.maps.Map, label: MarkerLabel) => (cluster: Cluster): google.maps.Marker => {
    // Get workorders sum by markers label text
    const workordersSum = cluster.markers.reduce((sum, marker) => sum + Number(marker.getLabel().text), 0);

    const elevatorClusterState = getWorkorderClusterState(cluster);
    return new google.maps.Marker({
        position: cluster.position,
        map,
        label: {
            ...label,
            text: workordersSum.toString(),
            color: MARKER_TYPES[elevatorClusterState].labelColor,
            fontFamily: 'WorkSans',
        },
        icon: MARKER_TYPES[elevatorClusterState].clusterIcon,
    });
};

export const createCluster = (
    map: google.maps.Map,
    markers: google.maps.Marker[],
    getInfoWindowOptions: (props: WorkorderGroup[]) => google.maps.InfoWindowOptions,
    label: MarkerLabel,
) => {
    const markerCluster = new MarkerClusterer({
        map,
        markers,
        renderer: { render: renderCluster(map, label) },
    });

    const { onClusterClick } = createInfoWindow(map, markerCluster, { cluster: getInfoWindowOptions });

    markerCluster.onClusterClick = onClusterClick;

    return markerCluster;
};
