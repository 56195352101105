import React from 'react';

const ArrowDown = props => {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 1.75242L7.707 8.00731C7.316 8.396 6.684 8.396 6.293 8.00731L7.80651e-08 1.75242L1.413 0.345765L7 5.89882L12.586 0.346759L14 1.75242Z"
            />
        </svg>
    );
};

export default ArrowDown;
