import React, { FC } from 'react';

import { SortingType } from 'constants/index';

import { TableActionPanel, TableSort } from 'modules/table';
import { OtherDocument } from 'modules/entities/modules/other-documents';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

import { Fields } from '../types';
import { sortFields } from '../constants';

type ActionPanelProps = {
    documents: OtherDocument[];
};

const ActionPanel: FC<ActionPanelProps> = ({ documents }) => (
    <TableActionPanel>
        <TableSort fields={sortFields} defaultField={Fields.DATE} defaultType={SortingType.DESC} />
        <DocumentsDownloadAll documents={documents} />
    </TableActionPanel>
);

export default ActionPanel;
