import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    minHeight: '140px',
    '& label': {
        width: '99%',
        margin: '0.4px',
        top: 1,
        left: 1,
        paddingTop: '0.375rem',
        paddingBottom: '0.375rem',
        paddingLeft: '0.75rem',
        background: 'white',
    },
});

export const info: TRuleWithTheme = () => ({
    marginTop: '1.5rem',

    fontSize: '0.875rem',
    lineHeight: 1.43,
});

export const notice: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'flex-start',

    marginTop: '1rem',

    fontSize: '0.875rem',
    lineHeight: 1.43,
});

export const cta: TRuleWithTheme = () => ({
    fontSize: 'inherit',
    lineHeight: 'inherit',

    height: 'auto',

    padding: 0,
    border: 'none',

    '> span': {
        textDecoration: 'underline',

        ':hover': {
            textDecoration: 'none',
        },
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    flexShrink: 0,

    width: '1em',
    height: '1em',

    marginTop: '0.125rem',
    marginRight: '0.5em',

    fontSize: '1rem',
});

export const input: TRuleWithTheme = () => ({
    height: '3rem',
    paddingBottom: '1rem',
    '& textarea': {
        height: '3rem',
        padding: '0.75rem',
    },
    ':after': {
        fontSize: '0.625rem',
        paddingRight: '12px',
        marginTop: '2px',
        padding: '0.75rem',
    },
});
export const inputWithValue: TRuleWithTheme = () => ({
    '& textarea': {
        height: '3rem',
        paddingTop: '1.5rem',
    },
    ':after': {
        padding: '0.75rem',
        fontSize: '0.625rem',
        paddingRight: '12px',
        marginTop: '2px',
    },
});
