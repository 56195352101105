import { basicApiReducer } from '@ackee/redux-utils';
import { fetchElevatorTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchElevatorTypes.FETCH_ELEVATOR_REQUEST,
        SUCCESS: fetchElevatorTypes.FETCH_ELEVATOR_SUCCESS,
        FAILURE: fetchElevatorTypes.FETCH_ELEVATOR_FAILURE,
        CANCEL: fetchElevatorTypes.FETCH_ELEVATOR_CANCEL,
        RESET: fetchElevatorTypes.FETCH_ELEVATOR_RESET,
    },
});
