import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';

import config from 'config/index';

import { useFelaEnhanced } from 'hooks';
import { useResetSelectedDocuments } from 'modules/entities/modules/documents';

import { AvailableSupportTabs } from './types';

import * as felaRules from './SupportTabs.styles';
import InvoicesListTab from 'modules/documents/components/InvoicesListTab';
import { ContractsTab } from 'modules/documents/components/ContractsTab';
import { SupportTable } from '../SupportTable';
import FilterPanel from '../FilterPanel';
import { Support } from 'modules/entities/modules/support';
import { SearchValue } from 'constants/index';

const { TabPane } = Tabs;

const tabPanes = {
    [AvailableSupportTabs.OPEN]: {
        name: 'page.requests.tab.open',
        tab: <InvoicesListTab />,
    },
    [AvailableSupportTabs.CLOSED]: {
        name: 'page.requests.tab.closed',
        tab: <ContractsTab />,
    },
};

const pathnameToTab = {
    [config.routes.supportOpen]: AvailableSupportTabs.OPEN,
    [config.routes.supportClosed]: AvailableSupportTabs.CLOSED,
};

const tabToPathname = {
    [AvailableSupportTabs.OPEN]: config.routes.supportOpen,
    [AvailableSupportTabs.CLOSED]: config.routes.supportClosed,
};

export interface SupportTabsProps {
    defaultSearch: SearchValue;
    openSupports: Support[];
    closedSupports: Support[];
    loading: boolean;
}

const SupportTabs: FC<SupportTabsProps> = ({defaultSearch, openSupports, closedSupports, loading}) => {
    const { pathname } = useLocation();
    
    const [internalRouting] = useState('');

    const history = useHistory();
    const resetDownloadSelect = useResetSelectedDocuments();

    const { styles } = useFelaEnhanced(felaRules);

    const onTabChange = (tab: string) => {
        resetDownloadSelect();
        history.push(tabToPathname[tab]);
    };

    let activeKey = pathnameToTab[pathname] ?? AvailableSupportTabs.OPEN;
    if (internalRouting) {
        activeKey = pathnameToTab[internalRouting] ?? AvailableSupportTabs.OPEN;
    }

    return (
        <>
            <Tabs activeKey={activeKey} className={styles.tabs} onChange={onTabChange} destroyInactiveTabPane>
                {Object.keys(tabPanes).map(pane => (
                    <TabPane tab={<FormattedMessage id={tabPanes[pane].name} />} key={pane} />
                ))}
            </Tabs>
            <FilterPanel defaultSearch={defaultSearch} isClosed={activeKey === AvailableSupportTabs.CLOSED}/>
            <SupportTable
                requests={activeKey === AvailableSupportTabs.OPEN ? openSupports : closedSupports}
                loading={loading}
                isClosed={activeKey === AvailableSupportTabs.CLOSED}
            />
        </>
    );
};

export default SupportTabs;
