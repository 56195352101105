import React from 'react';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';
import { URLStatusFilter } from 'modules/table';
import type { URLStatusFilterProps } from 'modules/table';
import { ElevatorState, ElevatorStateGroup, useElevatorsCountByState } from 'modules/entities/modules/elevators';

import { ElevatorStatusIcon } from '../ElevatorStatusIcon';

import * as felaRules from './ElevatorStatusFilter.styles';

const filters = [
    ElevatorState.RUNNING,
    ElevatorState.POTENTIAL_ISSUES,
    ElevatorState.OUT_OF_ORDER,
    ElevatorState.TECHNICIAN_ON_SITE,
];

type StatusFilter = URLStatusFilterProps<ElevatorState | ElevatorStateGroup>['statuses'][number];

export const ElevatorStatusFilter = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const countByState = useElevatorsCountByState();

    const states = filters.map<StatusFilter>(state => ({
        icon: <ElevatorStatusIcon state={state} extend={{ icon: rules.icon }} />,
        value: state,
        count: countByState[state],
        messageId: `page.elevators.filter.${state}`,
    }));

    states.push({
        icon: <ElevatorStatusIcon extend={{ icon: rules.icon }} />,
        value: ElevatorStateGroup.UNKNOWN,
        count: countByState[ElevatorStateGroup.UNKNOWN],
        messageId: `page.elevators.filter.${ElevatorStateGroup.UNKNOWN}`,
    });

    return (
        <URLStatusFilter<ElevatorState | ElevatorStateGroup>
            titleMessageId="page.elevators.filter.title"
            allMessageId="page.elevators.filter.SHOW_ALL"
            allIcon={<Icon type={IconType.ELEVATOR} className={styles.icon} />}
            statuses={states}
        />
    );
};
