import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

import { ButtonCheckbox } from 'modules/ui';
import { StatusFilterContainer, stateComparatorFactory, StatusIcon, Tables } from 'modules/table';
import { ContractState } from 'modules/entities/modules/documents-contracts';
import { SortingType } from 'constants/index';

import useContractListStatusFilter from '../../../../hooks/useContractListStatusFilter';

type StatusFilterProps = {};

const StatusFilter: FC<StatusFilterProps> = () => {
    const { onCheckboxChange, countByState, filterParams } = useContractListStatusFilter();

    const stateKeys = React.useMemo(
        () => Object.values(ContractState).sort(stateComparatorFactory({ type: SortingType.ASC }, Tables.CONTRACTS)),
        [],
    );

    return (
        <StatusFilterContainer title={<FormattedMessage id="documents.table.status" />}>
            <Checkbox.Group
                value={
                    // @ts-ignore
                    filterParams? filterParams.split(',') : [ContractState.CANCELATION_5_MONTHS, ContractState.ACTIVE, ContractState.CANCELED]
                }
                data-test-id="invoice-status-filter-checkboxgroup"
            >
                {stateKeys.map((state: ContractState) => (
                    <ButtonCheckbox value={state} key={state} count={countByState[state]} onChange={onCheckboxChange}>
                        {state !== ContractState.SHOW_ALL ? (
                            <StatusIcon table={Tables.CONTRACTS} state={state} size={StatusIcon.Size.SMALL} />
                        ) : null}
                        <FormattedMessage id={`documents.table.status.${state}`} />{' '}
                    </ButtonCheckbox>
                ))}
            </Checkbox.Group>
        </StatusFilterContainer>
    );
};

export default StatusFilter;
