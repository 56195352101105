import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';

import config from 'config/index';

import { useFelaEnhanced } from 'hooks';
import { useResetSelectedDocuments } from 'modules/entities/modules/documents';

import { AvailableDocumentTabs } from './types';

import InvoicesListTab from '../InvoicesListTab';
import { ContractsTab } from '../ContractsTab';
import { RiskAssessmentReportsTab } from '../RiskAssessmentReportsTab';
import { EmergencyRescuePlansTab } from '../EmergencyRescuePlansTab';
import { ConditionReportsTab } from '../ConditionReportsTab';
import { QuotationsTab } from '../QuotationsTab';
import OtherDocumentsTab from '../OtherDocumentsTab';

import * as felaRules from './DocumentTabs.styles';
import { AssessmenReportsTab } from '../AssessmentReportsTab';
import { replaceQueryString } from 'services/utils';
import { ContractState } from 'modules/entities/modules/documents-contracts';
import { UrlParam } from 'constants/index';
import useUserPermissions from 'modules/auth/hooks/useUserPermissions';

const { TabPane } = Tabs;

const pathnameToTab = {
  [config.routes.invoices]: AvailableDocumentTabs.INVOICES,
  [config.routes.contracts]: AvailableDocumentTabs.CONTRACTS,
  [config.routes.emergencyPlans]: AvailableDocumentTabs.EMERGENCY_PLANS,
  [config.routes.riskAssessments]: AvailableDocumentTabs.RISK_ASSESSMENTS,
  [config.routes.conditionReports]: AvailableDocumentTabs.CONDITION_REPORTS,
  [config.routes.otherDocuments]: AvailableDocumentTabs.OTHER_DOCUMENT,
  [config.routes.quotations]: AvailableDocumentTabs.QUOTATIONS,
  [config.routes.assessmentReports]: AvailableDocumentTabs.ASSESSMENT_REPORTS,
};

const tabToPathname = {
  [AvailableDocumentTabs.INVOICES]: config.routes.invoices,
  [AvailableDocumentTabs.CONTRACTS]: config.routes.contracts,
  [AvailableDocumentTabs.EMERGENCY_PLANS]: config.routes.emergencyPlans,
  [AvailableDocumentTabs.RISK_ASSESSMENTS]: config.routes.riskAssessments,
  [AvailableDocumentTabs.CONDITION_REPORTS]: config.routes.conditionReports,
  [AvailableDocumentTabs.OTHER_DOCUMENT]: config.routes.otherDocuments,
  [AvailableDocumentTabs.QUOTATIONS]: config.routes.quotations,
  [AvailableDocumentTabs.ASSESSMENT_REPORTS]: config.routes.assessmentReports,
};

const DocumentTabs: FC = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch('/elevators/:id');
  const location = useLocation();
  const [internalRouting, setInternalRouting] = useState('');

  const history = useHistory();
  const resetDownloadSelect = useResetSelectedDocuments();
  const permissions = useUserPermissions();
  const { styles } = useFelaEnhanced(felaRules);

  const tabPanes = useMemo(
    () => ({
      [AvailableDocumentTabs.INVOICES]: {
        name: 'documents.tabs.invoices',
        tab: <InvoicesListTab />,
        shouldShow: permissions.shouldViewInvoices,
      },
      [AvailableDocumentTabs.CONTRACTS]: {
        name: 'documents.tabs.contracts',
        tab: <ContractsTab />,
        shouldShow: permissions.shouldViewContracts,
      },
      [AvailableDocumentTabs.EMERGENCY_PLANS]: {
        name: 'documents.tabs.emergencyPlans',
        tab: <EmergencyRescuePlansTab />,
        shouldShow: permissions.shouldViewEmergencyRescuePlan,
      },
      [AvailableDocumentTabs.RISK_ASSESSMENTS]: {
        name: 'documents.tabs.riskAssessments',
        tab: <RiskAssessmentReportsTab />,
        shouldShow: permissions.shouldViewRiskAssessmentReports,
      },
      [AvailableDocumentTabs.CONDITION_REPORTS]: {
        name: 'documents.tabs.conditionReports',
        tab: <ConditionReportsTab />,
        shouldShow: permissions.shouldViewConditionReports,
      },
      [AvailableDocumentTabs.OTHER_DOCUMENT]: {
        name: 'documents.tabs.otherDocuments',
        tab: <OtherDocumentsTab />,
        shouldShow: permissions.shouldViewOtherDocuments,
      },
      [AvailableDocumentTabs.QUOTATIONS]: {
        name: 'documents.tabs.quotations',
        tab: <QuotationsTab />,
        shouldShow: permissions.shouldViewQuotations,
      },
      [AvailableDocumentTabs.ASSESSMENT_REPORTS]: {
        name: 'documents.tabs.assessmentReports',
        tab: <AssessmenReportsTab />,
        shouldShow: permissions.shouldViewAssessmentReports,
      },
    }),
    [permissions],
  );

  const onTabChange = (tab: string) => {
    resetDownloadSelect();
    if (match?.isExact) {
      setInternalRouting(tabToPathname[tab]);

      if (tabToPathname[tab] === tabToPathname[AvailableDocumentTabs.CONTRACTS]) {
        history.replace({
          search: replaceQueryString(
            [ContractState.CANCELATION_5_MONTHS, ContractState.CANCELED, ContractState.ACTIVE],
            UrlParam.FILTER,
            location.search,
            true,
          ),
        });
      } else {
        history.replace({
          search: replaceQueryString([], UrlParam.FILTER, location.search, true),
        });
      }

      return;
    }

    history.push(tabToPathname[tab]);
  };

  const activeKey = useMemo(() => {
    const firstTabPanesKey = Object.keys(tabPanes)[0]
    let activeKey = pathnameToTab[pathname] ?? firstTabPanesKey;
    if (internalRouting) {
      activeKey = pathnameToTab[internalRouting] ?? firstTabPanesKey;
    }

    return activeKey
  }, [tabPanes, internalRouting, pathname]);

  return (
    <div className={match?.isExact ? styles.container : ''}>
      <Tabs
        activeKey={activeKey}
        className={match?.isExact ? styles.noPadding : styles.tabs}
        onChange={onTabChange}
        destroyInactiveTabPane
        id="documents-tabs"
        type={match?.isExact ? 'card' : 'line'}
      >
        {Object.keys(tabPanes)
          .filter(pane => tabPanes[pane].shouldShow)
          .map((pane, index) => (
            <TabPane
              tab={
                <div className={match?.isExact ? styles.tab : ''}>
                  <FormattedMessage id={tabPanes[pane].name} />
                  {match?.isExact && index < Object.keys(tabPanes).length - 1 && <span>/</span>}
                </div>
              }
              key={pane}
            />
          ))}
      </Tabs>
      {tabPanes[activeKey].tab}
    </div>
  );
};

export default DocumentTabs;
