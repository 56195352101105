import React, { FC } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import * as felaRules from './TableSearch.styles';
import { SearchValue } from 'constants/index';

import { Input } from 'modules/form';
import { TableSearch as Search, SearchField, useTableSearch, TableSearchSelect } from 'modules/table';

import { selectSupportStatus } from 'modules/entities/modules/support/services/selectors';
import { SupportFilterFields } from 'modules/entities/modules/support';
import { Icon, IconType } from 'modules/ui';
import { useFelaEnhanced } from 'hooks';

export type TableSearchProps = {
    defaultValue: SearchValue;
    isClosed?: boolean;
};

const TableSearch: FC<TableSearchProps> = ({ defaultValue, isClosed }) => {
    const { styles } = useFelaEnhanced(felaRules);
    const calculatedStatuses = useSelector(selectSupportStatus('status', isClosed));
    const calculatedCreators = useSelector(selectSupportStatus('contactName', isClosed));
    const calculatedTopics = useSelector(selectSupportStatus('category', isClosed));
    const { formatMessage } = useIntl();

    const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultValue);

    const fields: SearchField[] = [
        {
            label: <FormattedMessage id={`page.requests.table.search`} />,
            renderer: (
                <Input
                    data-testid="search"
                    value={value['search']}
                    prefix={<Icon type={IconType.SEARCH} className={styles.search} />}
                    placeholder={formatMessage({ id: 'page.requests.table.search' })}
                    onChange={({ target }) => {
                        handleValue({
                            search: target.value,
                        });
                    }}
                />
            ),
        },
        ...(!isClosed
            ? [
                  {
                      label: <FormattedMessage id={`page.requests.table.status`} />,
                      renderer: (
                          <TableSearchSelect
                              mode="multiple"
                              placeholder={formatMessage({ id: 'table.search.placeholder' })}
                              options={calculatedStatuses}
                              value={value[SupportFilterFields.STATUS]}
                              onChange={value => {
                                  handleValue({
                                      [SupportFilterFields.STATUS]: value,
                                  });
                              }}
                          />
                      ),
                  },
              ]
            : []),
        {
            label: <FormattedMessage id={`page.requests.table.creator`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={calculatedCreators}
                    value={value[SupportFilterFields.CREATOR]}
                    onChange={value => {
                        handleValue({
                            [SupportFilterFields.CREATOR]: value,
                        });
                    }}
                />
            ),
        },
        {
            label: <FormattedMessage id={`page.requests.table.category`} />,
            renderer: (
                <TableSearchSelect
                    mode="multiple"
                    placeholder={formatMessage({ id: 'table.search.placeholder' })}
                    options={calculatedTopics}
                    value={value[SupportFilterFields.CATEGORY]}
                    onChange={value => {
                        handleValue({
                            [SupportFilterFields.CATEGORY]: value,
                        });
                    }}
                />
            ),
        },
    ];

    return (
        <Search
            fields={fields}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            rowGutter={[16, 16]}
            colSizes={{ sm: 24, md: 12, lg: 5, xxl: 5 }}
        />
    );
};

export default TableSearch;
