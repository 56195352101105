import { apiSelector } from '@ackee/redux-utils/es';
import type { ApiReducerState } from '@ackee/redux-utils/es/config';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ApiReducerKey, EntityKey, SortingType, URL_ARRAY_SEPARATOR } from 'constants/index';
import type { Sorting, Search } from 'constants/index';

import { generateUTCDate, compareValueByType } from 'services/utils';

import { dateComparatorFactory, defaultCompare, numberComparatorFactory, stringComparatorFactory } from 'modules/table';

import type { AssessmentReport } from '../types';
import { AssessmentReportField, AssessmentReportStatus } from '../types';

export const selectAssessmentReportsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_ASSESSMENT_REPORTS, ApiReducerKey.LIST);

export const selectAssessmentReportsEntityList = (state): AssessmentReport[] =>
    state.entities[EntityKey.DOCUMENT_ASSESSMENT_REPORTS][ApiReducerKey.LIST];

export const selectAssessmentReports = createSelector([selectAssessmentReportsEntityList], docs =>
    docs.map(doc => ({
        ...doc,
        file: doc.file ?? null,
    })),
);

export const selectAssessmentReportsCountByStatus = createSelector([selectAssessmentReports], docs => {
    console.log(docs,'docs')
    return Object.keys(AssessmentReportStatus).reduce<Record<AssessmentReportStatus, number>>((counts, statusKey) => {
        counts[AssessmentReportStatus[statusKey]] = docs.filter(
            ({ status }) => status === AssessmentReportStatus[statusKey],
        ).length;
        return counts;
    }, {} as Record<AssessmentReportStatus, number>);
});

export const selectAssessmentTypeOptions = createSelector([selectAssessmentReports], docs =>
    Array.from(new Set(docs.map(doc => doc.assessmentType)))
        .filter(Boolean)
        .map(assessmentType => ({ value: assessmentType, label: assessmentType }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

export const selectAssessmentResultOptions = createSelector([selectAssessmentReports], docs =>
    Array.from(new Set(docs.map(doc => doc.result)))
        .filter(Boolean)
        .map(result => ({ value: result, label: result }))
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.DESC })),
);

const sortAssessmentReportsFactory = (sorting?: Sorting) => {
    switch (sorting?.field) {
        case AssessmentReportField.ASSESSMENT_REPORT_DATE:
        case AssessmentReportField.DUE_DATE:
        case AssessmentReportField.NEXT_ASSESSMENT: {
            return dateComparatorFactory(sorting);
        }
        case AssessmentReportField.RESULT:
        case AssessmentReportField.SERVICE_PROVIDER_NAME: {
            return stringComparatorFactory(sorting, '');
        }
        case AssessmentReportField.NO_TOTAL_DEFICIENCIES: {
            return numberComparatorFactory(sorting);
        }
        default: {
            return defaultCompare;
        }
    }
};

export const selectSortedAssessmentReports = createSelector(
    [
        selectAssessmentReports,
        (
            _,
            {
                sorting,
            }: {
                sorting: Sorting;
            },
        ) => sorting,
    ],
    (docs, sorting) => [...docs].sort(sortAssessmentReportsFactory(sorting)),
);

export const selectFilteredAssessmentReports = createSelector(
    [
        selectSortedAssessmentReports,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (docs, search) =>
        docs.filter(doc =>
            search.every(({ field, query }) => {
                if (field.startsWith(AssessmentReportField.ASSESSMENT_REPORT_DATE)) {
                    const foundItemTime = generateUTCDate(new Date(doc.assessmentDate)).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === AssessmentReportField.ASSESSMENT_REPORT_DATE_FROM) {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === AssessmentReportField.ASSESSMENT_REPORT_DATE_TO) {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                if (field === AssessmentReportField.ASSESSMENT_REPORT_TYPE) {
                    const queries = query.split(URL_ARRAY_SEPARATOR);
                    return queries.some(subQuery =>
                        compareValueByType({
                            value: doc.assessmentType,
                            query: subQuery,
                            strict: true,
                        }),
                    );
                }

                if (field === AssessmentReportField.RESULT) {
                    const queries = query.split(URL_ARRAY_SEPARATOR);
                    return queries.some(subQuery =>
                        compareValueByType({
                            value: doc.result,
                            query: subQuery,
                            strict: true,
                        }),
                    );
                }

                if (field === AssessmentReportField.STATUS) {
                    return doc.status && query.includes(doc.status);
                }

                return compareValueByType({
                    value: get(doc, field),
                    query,
                });
            }),
        ),
);
