import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    width: '100%',
    '> .ant-select-selector': {
        height: '3.68rem !important',
    },

    '> .ant-select-clear': {
        opacity: 1,
    },
    '& .ant-select-selection-search': {
        display: 'flex',
        alignItems: 'center',
    },
});
export const containerStatic: TRuleWithTheme = () => ({
    width: '100%',
    '> .ant-select-selector': {
        height: '3.68rem !important',
    },

    '> .ant-select-clear': {
        opacity: 1,
    },
    '& .ant-select-selection-search': {
        display: 'flex',
        alignItems: 'center',
    },
    paddingLeft: '0.75rem',
    borderColor: 'rgb(217, 217, 217)',
    borderStyle: 'solid',
    borderWidth: '1px'
});


export const containerSmall: TRuleWithTheme = () => ({
    width: '100%',
    '> .ant-select-selector': {
        height: '3rem !important',
    },

    '> .ant-select-clear': {
        opacity: 1,
    },
    '& .ant-select-selection-search': {
        display: 'flex',
        alignItems: 'center',
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    width: '0.9em',
    height: '0.69em',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.56rem',

    fontSize: '1.5rem',
});
export const description: TRuleWithTheme = ({ theme }) => ({
    color: '#8F8F8F',
    fontSize: '0.7rem',
    lineHeight: '1rem',
    marginBottom: '0.63rem',
});
export const close: TRuleWithTheme = ({ theme }) => ({
    color: '#8F8F8F',
    fontWeight: 'bold',
    textDecoration: 'underline',
});
