import React from 'react';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Status } from '@googlemaps/react-wrapper';

import { useFelaEnhanced } from 'hooks';
import { mapSelector } from 'modules/map/services/selectors';

import { EmptyElevatorsMap } from '../EmptyElevatorsMap';
import { ElevatorsMapLoader } from '../ElevatorsMapLoader';

import * as felaRules from './ElevatorsMapWrapper.rules';

export interface ElevatorsMapWrapperProps {
    loading: boolean;
    empty: boolean;
    children: ReactNode;
}

export const ElevatorsMapWrapper = ({ loading, empty, children }: ElevatorsMapWrapperProps) => {
    const { initializationStatus } = useSelector(mapSelector);

    const { styles } = useFelaEnhanced(felaRules);

    const showLoader = initializationStatus === Status.LOADING || loading;

    return (
        <div className={styles.container}>
            {initializationStatus === Status.SUCCESS ? children : null}

            {showLoader ? <ElevatorsMapLoader /> : null}
            {!showLoader && empty ? <EmptyElevatorsMap /> : null}
        </div>
    );
};
