import React, { FC, ReactNode } from 'react';
import { Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './PopoverItem.styles';

type PopoverProps = {
    title?: ReactNode;
    text?: ReactNode;
    secondaryText?: ReactNode;
    size?: 'medium' | 'small';
    extend?: RulesExtend<typeof felaRules>;
};

const PopoverItem: FC<PopoverProps> = ({ title, size = 'medium', text, secondaryText, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { size, extend });
    return (
        <div className={styles.popoverItem} data-testid="popover-item">
            {title ? <Typography.Text className={styles.title}>{title}</Typography.Text> : null}
            {text ? <Typography.Text className={styles.text}>{text}</Typography.Text> : null}
            {secondaryText ? <Typography.Text className={styles.secondaryText}>{secondaryText}</Typography.Text> : null}
        </div>
    );
};

export default PopoverItem;
