import { basicApiReducer } from '@ackee/redux-utils';
import { fetchInvoicesTypes } from '../../actions';

export default basicApiReducer({
    actionTypes: {
        REQUEST: fetchInvoicesTypes.FETCH_INVOICES_REQUEST,
        SUCCESS: fetchInvoicesTypes.FETCH_INVOICES_SUCCESS,
        FAILURE: fetchInvoicesTypes.FETCH_INVOICES_FAILURE,
        CANCEL: fetchInvoicesTypes.FETCH_INVOICES_CANCEL,
        RESET: fetchInvoicesTypes.FETCH_INVOICES_RESET,
    },
});
