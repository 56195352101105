import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { DividerName } from 'modules/ui';
import { ElevatorStatusIcon } from 'modules/elevators';
import { Elevator, isSupportedState } from 'modules/entities/modules/elevators';

import { useGroupedElevatorStates } from '../../hooks/useGroupedElevatorStates';

import DetailedStatusAlert from '../IotDataTab/StatusCard/DetailedStatus/DetailedStatusAlert';

import * as felaRules from './ElevatorHistoryList.styles';

export interface ElevatorHistoryListProps {
    elevatorStates: Elevator['state'][];
    layout?: 'vertical' | 'horizontal';
}

const ElevatorHistoryList = ({ elevatorStates, layout = 'vertical' }: ElevatorHistoryListProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const groupedStates = useGroupedElevatorStates(elevatorStates);

    return (
        <>
            {Object.keys(groupedStates).map(day => (
                <div key={day} className={styles.group} data-testid="group">
                    <DividerName>{day}</DividerName>

                    {groupedStates[day].map(state => {
                        const isUnknown = !isSupportedState(state.state);
                        return (
                            <DetailedStatusAlert
                                key={state.formattedDate}
                                formattedDate={state.formattedDate}
                                title={
                                    <FormattedMessage
                                        id={
                                            isUnknown
                                                ? 'elevator.status-group.UNKNOWN'
                                                : `elevator.status.${state.state}`
                                        }
                                    />
                                }
                                icon={<ElevatorStatusIcon state={state.state} />}
                                layout={layout}
                            />
                        );
                    })}
                </div>
            ))}
        </>
    );
};

export default ElevatorHistoryList;
