import React, { FC, useEffect, useState } from 'react';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { Typography } from 'antd';
import type { Moment } from 'moment';

import { Button, DataCard, Icon, IconType } from 'modules/ui';
import { DateRange } from 'modules/form';
import { ElevatorTotalTripsAggregation } from 'modules/entities/modules/elevators';
import { useDateRange, useTotalTrips } from '../../../hooks';
import { generateTotalTripsTooltip, generateTotalTripsName } from '../../../services/utils';

import HorizontalBarChart from '../../HorizontalBarChart';
import * as felaRules from './TotalTrips.styles';

const demoData = [
    [
        { date: '2022-04-01T00:00:00.000Z', value: 106 },
        { date: '2022-04-02T00:00:00.000Z', value: 165 },
        { date: '2022-04-03T00:00:00.000Z', value: 88 },
        { date: '2022-04-04T00:00:00.000Z', value: 111 },
        { date: '2022-04-05T00:00:00.000Z', value: 146 },
        { date: '2022-04-06T00:00:00.000Z', value: 135 },
        { date: '2022-04-07T00:00:00.000Z', value: 158 },
        { date: '2022-04-08T00:00:00.000Z', value: 123 },
        { date: '2022-04-09T00:00:00.000Z', value: 145 },
        { date: '2022-04-10T00:00:00.000Z', value: 73 },
        { date: '2022-04-11T00:00:00.000Z', value: 144 },
        { date: '2022-04-12T00:00:00.000Z', value: 170 },
        { date: '2022-04-13T00:00:00.000Z', value: 112 },
        { date: '2022-04-14T00:00:00.000Z', value: 148 },
        { date: '2022-04-15T00:00:00.000Z', value: 69 },
        { date: '2022-04-16T00:00:00.000Z', value: 96 },
        { date: '2022-04-17T00:00:00.000Z', value: 47 },
        { date: '2022-04-18T00:00:00.000Z', value: 74 },
        { date: '2022-04-19T00:00:00.000Z', value: 113 },
    ],
];

const TotalTrips: FC<{ isDeactivated?: boolean }> = ({ isDeactivated }) => {
    const [aggregationBy, setAggregationBy] = useState<ElevatorTotalTripsAggregation>(
        ElevatorTotalTripsAggregation.DAY,
    );

    const { startTime, endTime, handleDateChange } = useDateRange();
    const { trips, inProgress, error } = useTotalTrips({ startTime, endTime, granularity: aggregationBy });

    let tripsSummary = trips;

    if (isDeactivated) {
        // @ts-ignore
        tripsSummary = demoData;
    }
    const pageTotal = tripsSummary.length;

    const { formatDate } = useIntl();

    const [page, setPage] = useState(0);
    const { styles, theme, rules } = useFelaEnhanced(felaRules, { aggregationBy, page, pageTotal });

    useEffect(() => {
        setPage(pageTotal - 1);
    }, [pageTotal]);

    const handleDateChangeWithPage = (dates: [Moment, Moment]) => {
        if (!dates && page > 0) {
            setPage(0);
        }
        handleDateChange(dates);
    };

    return (
        <DataCard
            style={{ filter: isDeactivated ? 'blur(1px)' : 'none' }}
            title={
                <Typography.Title level={4}>
                    <FormattedMessage id="page.elevatorDetail.tabs.iotData.totalTrips.heading" />
                </Typography.Title>
            }
            extend={{ card: rules.newCard }}
            loading={inProgress}
            error={error}
            empty={tripsSummary.length === 0}
            data-test-id="total-trips"
            extra={
                <div className={styles.headerWrapper}>
                    {Object.keys(ElevatorTotalTripsAggregation).map(aggregation => (
                        <Button
                            key={ElevatorTotalTripsAggregation[aggregation]}
                            type="link"
                            extend={{ button: rules.link }}
                            data-test-id={`button-aggregation-${ElevatorTotalTripsAggregation[aggregation]}`}
                            active={aggregationBy === ElevatorTotalTripsAggregation[aggregation]}
                            onClick={() => {
                                setAggregationBy(ElevatorTotalTripsAggregation[aggregation]);
                                if (page > 0) setPage(0);
                            }}
                        >
                            <FormattedMessage id={`totalTrips.aggregation.${aggregation}`} />
                        </Button>
                    ))}

                    <DateRange
                        defaultValue={
                            startTime && endTime ? [moment(startTime), moment(endTime)] : [undefined, undefined]
                        }
                        onChange={handleDateChangeWithPage}
                    />
                </div>
            }
        >
            <Button
                type="link"
                onClick={() => setPage(prevState => prevState - 1)}
                disabled={page === 0}
                extend={{ button: rules.chevronLeftButton }}
                data-test-id="button-chart-previous-page"
            >
                <Icon type={IconType.CHEVRON_LEFT} className={styles.chevronLeft} />
            </Button>
            <Button
                type="link"
                onClick={() => setPage(prevState => prevState + 1)}
                disabled={page + 2 > pageTotal}
                extend={{ button: rules.chevronRightButton }}
                data-test-id="button-chart-next-page"
            >
                <Icon type={IconType.CHEVRON_RIGHT_BIG} className={styles.chevronRight} />
            </Button>

            <HorizontalBarChart
                data={(tripsSummary[page] ?? []).map(trip => ({
                    ...trip,
                    name: generateTotalTripsName(trip, aggregationBy, formatDate),
                    tooltipValue: generateTotalTripsTooltip(trip, aggregationBy, formatDate),
                }))}
                width={theme.width.chart}
                height={theme.height.chart}
                barCategoryGap={aggregationBy === ElevatorTotalTripsAggregation.DAY ? 2.5 : 5}
                minTickGap={-5}
                angle={aggregationBy === ElevatorTotalTripsAggregation.DAY ? theme.chart.xAxisAngle : null}
                dx={aggregationBy === ElevatorTotalTripsAggregation.DAY ? theme.chart.xAxisDx : null}
                dy={aggregationBy === ElevatorTotalTripsAggregation.DAY ? theme.chart.xAxisDy : null}
                customTooltip
                names={{ value: <FormattedMessage id="totalTrips.tooltip.name" /> }}
                widerYAxis
            />
        </DataCard>
    );
};

export default TotalTrips;
