import React, { FC } from 'react';
import { Skeleton } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { DataCard } from 'modules/ui';

import * as felaRules from './TechnicalDataLoader.styles';
import { card } from '../TechnicalDataTab.styles';

const TechnicalDataLoader: FC = () => {
    const { styles, rules } = useFelaEnhanced({ ...felaRules, card });
    return (
        <>
            <DataCard extend={{ card: rules.card }} title={<Skeleton className={styles.headerSkeleton} />}>
                <Skeleton className={styles.bodySkeleton} />
            </DataCard>
            <DataCard extend={{ card: rules.card }} title={<Skeleton className={styles.headerSkeleton} />}>
                <Skeleton className={styles.bodySkeleton} />
            </DataCard>
            <DataCard extend={{ card: rules.card }} title={<Skeleton className={styles.headerSkeleton} />}>
                <Skeleton className={styles.bodySkeleton} />
            </DataCard>
            <DataCard extend={{ card: rules.card }} title={<Skeleton className={styles.headerSkeleton} />}>
                <Skeleton className={styles.bodySkeleton} />
            </DataCard>
            <DataCard extend={{ card: rules.card }} title={<Skeleton className={styles.headerSkeleton} />}>
                <Skeleton className={styles.bodySkeleton} />
            </DataCard>
        </>
    );
};

export default TechnicalDataLoader;
