import React from 'react';

export const OperatorObligations = () => (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 10V9H15V10C15 11.654 13.654 13 12 13C10.346 13 9 11.654 9 10ZM11 4.142V7H8.142C8.506 5.601 9.601 4.506 11 4.142ZM15.858 7H13V4.142C14.399 4.506 15.494 5.601 15.858 7ZM15.305 13.723C16.336 12.807 17 11.485 17 10V9H18C18.553 9 19 8.553 19 8C19 7.447 18.553 7 18 7H17.91C17.432 4.167 14.967 2 12 2C9.033 2 6.568 4.167 6.09 7H6C5.447 7 5 7.447 5 8C5 8.553 5.447 9 6 9H7V10C7 11.485 7.664 12.807 8.695 13.723C5.931 14.984 4 17.768 4 21V22H6V21C6 17.691 8.691 15 12 15C15.309 15 18 17.691 18 21V22H20V21C20 17.768 18.069 14.984 15.305 13.723Z"
        />
    </svg>
);
