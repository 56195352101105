import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderTopLeftRadius: '0.125rem',
    borderTopRightRadius: '0.125rem',
    backgroundColor: theme.colors.white,
});

export const portfolio: TRuleWithTheme = () => ({
    marginRight: '1rem',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1,
});

export const count: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',
    fontWeight: 500,
    color: theme.colors.textSecondary,
});

export const seeAllButton: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.313rem 1rem',
    gap: '0.625rem',
});

export const buttonContainer: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    fontSize: '0.75rem',
    padding: '0.75rem 1rem',
});
