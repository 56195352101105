import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/actions';
import { selectUser } from '../services/selector';
import { useEffect } from 'react';

export const useSetUser = () => {
    const dispatch = useDispatch();

    const handleSetUser = (user) => {
        dispatch(
            actions.setUserRequest({
                user,
            }),
        );
    };

    useEffect(() => {
        return () => {
            actions.setUserReset();
        };
    }, []);

    return {
        handleSetUser,
        api: useSelector(state => selectUser(state)),
    };
};
