import type { TRuleWithTheme } from 'styles/theme';
import bg from 'assets/images/auth_background.svg';

export const container: TRuleWithTheme = ({ theme }) => ({
    padding: '1.875rem',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    '&.ant-typography': {
        maxWidth: 411,
        marginRight: 'auto',
        color: theme.colors.white,
        fontSize: '1.5rem',
        lineHeight: '1.33em',
        marginBottom: '1em',
    },
});
export const logoContainer: TRuleWithTheme = () => ({
    marginTop: '40px',
});
export const heading: TRuleWithTheme = () => ({
    display: 'block',
    fontSize: '2.25rem',
    color: 'white',
    padding: '1.25rem',
});
export const logo: TRuleWithTheme = () => ({
    height: 65,
});
export const icon = () => ({
    width: '10',
    height: '10',
    marginRight: '1rem',
    marginLeft: '1.25rem',
    verticalAlign: '-0.125em',
    color: 'white',
});
export const urlLink: TRuleWithTheme = ({ theme }) => ({
    marginLeft: '1.25rem',
    color: theme.colors.white,
    verticalAlign: '0.2rem'
});

export const browserList = () => ({
    marginTop: '1.563rem',
});
export const browserListIcons = () => ({
    padding: '0.5rem',
});
export const browserIcons = () => ({
    width: '10',
    height: '10',
    marginRight: '1rem',
    color: 'white',
});
export const subHeading = () => ({
    display: 'block',
    fontSize: '1.25rem',
    color: 'white',
    marginLeft: '1.25rem',
    marginTop: '1.25rem',
    width: '60%',
});

export const detail2 = () => ({
    marginTop: '1.25rem',
});
