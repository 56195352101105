import { ApiReducerKey } from 'constants/index';
import { combineReducers } from 'redux';
import single from './single';

import list from './list';
import { updateApiReducer } from './update';

export default combineReducers({
    [ApiReducerKey.LIST]: list,
    [ApiReducerKey.UPDATE]: updateApiReducer,
    [ApiReducerKey.SINGLE]: single,
});
