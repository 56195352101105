import React, { FC, useRef } from 'react';
import type { ActionType } from '@ant-design/pro-table';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { SeoHead } from 'modules/seo';
import { RowLoader, Table, useDefaultTableConfig } from 'modules/table';
import type { OtherDocument } from 'modules/entities/modules/other-documents';

import FilterPanel from './FilterPanel';
import ActionPanel from './ActionPanel';

import { useOtherDocumentsTable } from '../../hooks/useOtherDocumentsTable';
import { useViewDocument } from '../../hooks/useViewDocument';

import { columns } from './config';

import * as felaRules from './OtherDocumentsTab.styles';
import { useRouteMatch } from 'react-router-dom';
import { elevatorDetailColumns } from './config/colummns';

interface OtherDocumentsTab {}

const OtherDocumentsTab: FC<OtherDocumentsTab> = () => {
    const { rules } = useFelaEnhanced(felaRules);
    const { api, documents, defaultSearch } = useOtherDocumentsTable();
    const match = useRouteMatch('/elevators/:id');

    const intl = useIntl();

    const ref = useRef<ActionType>();
    const formRef = useRef();

    const defaultPaginationConfig = useDefaultTableConfig();

    const { opening, openDocument, rowKey } = useViewDocument();

    return (
        <div data-testid="other-documents-tab">
            <SeoHead title={intl.formatMessage({ id: 'documents.otherDocuments.title' })} />

            <FilterPanel defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            <ActionPanel documents={documents} />
            <Table<OtherDocument>
                loading={api.inProgress}
                columns={match?.isExact ? elevatorDetailColumns : columns}
                actionRef={ref}
                formRef={formRef}
                dataSource={documents}
                extend={{ table: match?.isExact ? rules.tableElevatorDetailOtherDocuments : rules.table }}
                form={{ syncToUrl: values => values }}
                rowKey="id"
                toolBarRender={false}
                search={false}
                pagination={defaultPaginationConfig}
                onRow={doc => ({
                    style: {
                        cursor: doc.file ? 'pointer' : 'default',
                    },
                    onClick: e => {
                        e.stopPropagation();
                        if (doc.file) {
                            openDocument({
                                id: doc.id,
                                file: doc.file,
                            });
                        }
                    },
                })}
                data-testid="other-documents-table"
                footer={() => <RowLoader show={opening} rowKey={rowKey} />}
            />
        </div>
    );
};

export default OtherDocumentsTab;
