import type { TRuleWithTheme } from 'styles/theme';

export const closeIcon: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.iconActive,
});

export const bottomButtons: TRuleWithTheme = ({ theme }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',

    columnGap: '1rem',
});

export const header: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '-0.19rem',
});
export const alert: TRuleWithTheme = () => ({
    marginBottom: '1.94rem',
});

export const title: TRuleWithTheme = () => ({
    '&.ant-typography': {
        fontSize: '1.56rem',
        lineHeight: 1.33,

        margin: 0,
    },
});

export const description: TRuleWithTheme = ({ theme }) => ({
    '&.ant-typography': {
        color: theme.colors.textSecondary,

        fontSize: '0.88rem',
        lineHeight: 1.5,

        marginTop: '0.25rem',
        marginBottom: '1.5rem',
    },
});
export const componentRow: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& div': {
        flex: 1,
    },
    '> div:first-child': {
        paddingRight: '0.625rem',
        minWidth: '50%',
        // paddingRight: '1.6rem',
    },
    '> div:last-child': {
        paddingLeft: '0.625rem',
        minWidth: '50%',
        // paddingLeft: '1.6rem',
        '> label': {
            left: '1.375rem',
        },
    },
});

export const rowSpace: TRuleWithTheme = ({ theme }) => ({
    marginTop: '2rem',
});
export const timePicker: TRuleWithTheme = ({ theme }) => ({
    background: 'red !important',
    '& .ant-picker-time-panel-column ': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export const componentRowSeperator: TRuleWithTheme = ({ theme }) => ({
    maxWidth: '1.25rem',
});

export const content: TRuleWithTheme<{
    goBack: boolean;
}> = ({ goBack }) => ({
    marginLeft: goBack ? '2.5rem' : 0,
    marginTop: '0.5rem',
    fontSize: '0.88rem',
});

export const checkboxRow = () => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '3.38rem',
    marginBottom: '2rem',
    fontSize: '0.75rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& label': {
        fontSize: '0.75rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    '& label:last-child': {
        width: '300px',
    },
    '& .ant-checkbox': {
        marginTop: '4px',
        height: '15.998px',
    },
});
export const checkoutText = () => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '1.5px',
});
export const alertContainer = () => ({
    display: 'flex',
    flexDirection: 'row',
    width: '18.6875rem',
});
export const alertIcon = () => ({
    marginRight: '0.5rem',
    paddingTop: '0.2rem',
});
export const alertMessage: TRuleWithTheme = ({ theme }) => ({
    width: '18.6875rem',
    fontSize: '0.75rem',
    color: theme.colors.text,
    lineHeight: '131.8%',
});

export const dropdown: TRuleWithTheme = ({ theme }) => ({
    '& .ant-picker-content td': {
        fontSize: '.875rem',
    },
    '& .ant-picker-content th': {
        fontSize: '.875rem',
        fontWeight: 600,
    },

    '& .ant-picker-cell .ant-picker-cell-inner': {
        lineHeight: '24px',
        height: 24,
        minWidth: 24,
    },

    '& .ant-picker-cell::before': {
        height: 24,
    },


    

    '& .ant-picker-cell.ant-picker-cell-today': {
        color: theme.colors.primary,
    },

    '& .ant-picker-header-view': {
        fontSize: '.875rem',
        padding: '.25rem 0',
    },
});
