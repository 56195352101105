import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { Elevator } from 'modules/entities/modules/elevators';
import { DialogStateValue } from 'modules/entities';
import { GlobalDialogContext } from 'contexts/GlobalDialogContext';

interface GlobalDialogProviderProps {
    children: ReactNode;
}

export const GlobalDialogProvider = ({ children }: GlobalDialogProviderProps) => {
    const [state, setState] = useState<DialogStateValue>(null);
    const [lastDialogType, setLastDialogType] = useState<DialogStateValue>(null);
    const [elevator, setElevator] = useState<Elevator>(null);
    const [meta, setMeta] = useState<string>(null);
    const context = useMemo(
        () => ({
            state,
            elevator,
            meta,
            lastDialogType,
            navigate: (state: DialogStateValue, elevator: Elevator, meta) => {
                setState(state);
                setElevator(elevator);
                setMeta(meta);
                setLastDialogType(state);
            },
        }),
        [state, setState, elevator, meta, lastDialogType],
    );

    return <GlobalDialogContext.Provider value={context}>{children}</GlobalDialogContext.Provider>;
};
