import type { TRule } from 'fela';
import type { Theme } from 'styles/theme';

export const dividerName: TRule<{ theme: Theme }> = ({ theme: { colors } }) => ({
    color: colors.textSecondary,
    marginTop: '0.5em',
    fontSize: '.75rem',
    marginBottom: '.5em',
    textTransform: 'capitalize',
});
