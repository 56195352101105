import { combineReducers } from 'redux';

import { registration } from './registration';
import { loginApiReducer } from './loginRequest';
import { ApiReducerKey } from 'constants/index';

export const reducer = combineReducers({
    registration,
    [ApiReducerKey.LOGIN]: loginApiReducer,
});

export type AuthState = ReturnType<typeof reducer>
