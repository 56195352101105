import { combineReducers } from 'redux';

import { ApiReducerKey } from 'constants/index';

import { inviteUsersReducer } from './inviteUsersRequest';
import { deactivateActivateUserReducer } from './deactivateActivateUserRequest'
import { editNotificationReducer } from './editNotificationRequest';
import { changeRoleReducer } from './changeRoleRequest';
import fetchUsersRolesPermissions from './fetchUsersRolesPermissions';
import fetchUsers from './fetchUsers';

export default combineReducers({
    [ApiReducerKey.CREATE_USERS]: inviteUsersReducer,
    [ApiReducerKey.DEACTIVATE_USER]: deactivateActivateUserReducer,
    [ApiReducerKey.EDIT_NOTIFICATION]: editNotificationReducer,
    [ApiReducerKey.CHANGE_ROLE]: changeRoleReducer,
    [ApiReducerKey.USERS]:  fetchUsers,
    [ApiReducerKey.ROLES]: fetchUsersRolesPermissions,
});
