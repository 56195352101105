import React, { FC } from 'react';
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import config from 'config';

import { LoginPage, CreatePasswordPage, ResetPasswordPage, RegistrationPage } from 'modules/auth';

export const routes: Array<RouteProps> = [
    {
        path: config.routes.login,
        exact: true,
        render: () => <LoginPage />,
    },
    {
        path: config.routes.registration,
        exact: true,
        render: () => <RegistrationPage />,
    },
    {
        path: config.routes.createPassword,
        exact: true,
        render: () => <CreatePasswordPage />,
    },
    {
        path: config.routes.forgotPassword,
        exact: true,
        render: () => <ResetPasswordPage />,
    },
    {
        render: ({ location }) => <Redirect to={{ pathname: config.routes.login, state: { from: location } }} />,
    },
];

export interface RoutesProps {}

const Routes: FC<RoutesProps> = () => (
    <Switch>
        {routes.map((route, index) => (
            <Route key={String(route.path ?? index)} {...route} />
        ))}
    </Switch>
);

export default Routes;
