import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import { StatusColor } from '../../../types';
import * as felaRules from './Status.styles';

interface StatusIconProps {
    color: StatusColor;
}

export const StatusIcon: FC<StatusIconProps> = ({ color }) => {
    const { styles } = useFelaEnhanced(felaRules, { color });

    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.icon}
        >
            <g id="ic/24/elevator_status/status01" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="Oval-Copy-3" fill="currentColor" cx="12" cy="12" r="8" />
                <circle id="Oval-Copy-4" stroke="#CBCBCB" cx="12" cy="12" r="9.5" />
            </g>
        </svg>
    );
};

export default StatusIcon;
