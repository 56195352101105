import React, { FC } from 'react';

import { Divider } from 'modules/ui';
import { TableFilterPanel } from 'modules/table';

import TableSearch, { TableSearchProps } from './TableSearch';
import StatusFilter from './StatusFilter';

type FilterPanelProps = {
    defaultSearch: TableSearchProps['defaultValue'];
    isElevatorDetail: boolean;
};

const FilterPanel: FC<FilterPanelProps> = ({ defaultSearch, isElevatorDetail }) => (
    <TableFilterPanel>
        <StatusFilter  isElevatorDetail={isElevatorDetail}/>
        <Divider />
        <TableSearch defaultValue={defaultSearch} isElevatorDetail={isElevatorDetail} />
    </TableFilterPanel>
);

export default FilterPanel;
