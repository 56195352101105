import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '1.25rem',
    paddingRight: '0.625rem',
    paddingBottom: '1.5rem',
    paddingLeft: '0.625rem',

    backgroundColor: theme.colors.white,

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.outlineGrey,
    borderRadius: 2,

    cursor: 'pointer',

    ':hover': {
        outline: 'none',
        borderColor: theme.colors.primary,
    },

    ':focus': {
        outline: 'none',
        borderColor: theme.colors.primary,
    },

    ':focus-visible': {
        outline: 'none',
        borderColor: theme.colors.primary,
    },
});

export const icon: TRuleWithTheme = () => ({
    display: 'block',

    marginBottom: '0.75rem',
});

export const title: TRuleWithTheme = () => ({
    display: 'block',

    marginBottom: '0.25rem',

    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.375,
});

export const desc: TRuleWithTheme = () => ({
    display: 'block',

    fontSize: '0.875rem',
    lineHeight: 1.43,
});
