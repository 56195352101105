import { TRuleWithTheme } from 'styles/theme';

export const inspectionRecommendations: TRuleWithTheme = () => ({
    flexDirection: 'column',
    alignItems: 'flex-start',
});

export const inspectionRecommendation: TRuleWithTheme = () => ({
    '&.ant-typography': {
        marginBottom: '0.5rem',
    },
});
