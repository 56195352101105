import { combineReducers } from 'redux';

import { ApiReducerKey } from 'constants/index';

import { supportApiReducer } from './postSupportRequest';
import list from './list';



export default combineReducers({
    [ApiReducerKey.SUPPORT_REQUEST]: supportApiReducer,
    [ApiReducerKey.LIST]: list
});
