import React, { ReactNode } from 'react';
import { Alert } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';

import * as felaRules from './IncidentInfo.rules';

export interface IncidentInfoProps {
    children: ReactNode;
}

export const IncidentInfo = ({ children }: IncidentInfoProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <Alert
            className={styles.alert}
            icon={<Icon type={IconType.INFO} className={styles.icon} />}
            showIcon
            message={children}
            type="info"
        />
    );
};
