import * as types from '../actions/types';
import type { FormState, FormReducerState } from '../../types';

const initialState: FormReducerState = {};

const initialFormState: FormState = {
    submitting: false,
    succeeded: null,
    error: null,
    response: null,
    meta: null,
};

export default (state = initialState, action: types.FormActionTypes): FormReducerState => {
    switch (action.type) {
        case types.REGISTER_FORM: {
            return {
                ...state,
                [action.meta.form]: initialFormState,
            };
        }

        case types.UNREGISTER_FORM: {
            return {
                ...state,
                [action.meta.form]: undefined,
            };
        }

        case types.SUBMIT_FORM:
            return {
                ...state,
                [action.meta.form]: {
                    ...state[action.meta.form],
                    submitting: true,
                    meta: action.meta.meta,
                },
            };

        case types.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                [action.meta.form]: {
                    ...state[action.meta.form],
                    submitting: false,
                    succeeded: true,
                    error: null,
                    response: action.payload,
                    meta: action.meta.meta,
                },
            };

        case types.SUBMIT_FORM_FAILURE:
            return {
                ...state,
                [action.meta.form]: {
                    ...state[action.meta.form],
                    submitting: false,
                    succeeded: false,
                    error: action.payload,
                    meta: action.meta.meta,
                },
            };

        default:
            return state;
    }
};
