import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    padding: '5rem',

    textAlign: 'center',

    width: '100%',
    height: '100%',

    backgroundColor: 'rgba(255, 255, 255, 0.9)',

    borderRadius: '0.125rem',
});

export const message: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1rem',
    marginBottom: '1rem',
});
