import type { TablePaginationConfig } from 'antd/es/table/interface';
import config from 'config/index';
import { PAGE_SIZE_STORAGE_KEY, pageSizeOptions } from '../constants';

const useDefaultTableConfig = () => {
    const defaultPageSize = sessionStorage.getItem(PAGE_SIZE_STORAGE_KEY);

    const defaultPaginationConfig: TablePaginationConfig = {
        pageSize: defaultPageSize ? parseInt(defaultPageSize) : config.tables.defaultPageSize,
        onShowSizeChange: (current, pageSize) => {
            sessionStorage.setItem(PAGE_SIZE_STORAGE_KEY, pageSize.toString());
        },
        pageSizeOptions,
        size: 'default',
    };

    return defaultPaginationConfig;
};

export default useDefaultTableConfig;
