import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.ELEVATOR_STATES,
});

export const fetchElevatorStatesTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ELEVATOR_STATES_' }),
});

