import React from 'react';

const CloseBoldIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 1.41L8.59 0L5 3.59L1.42 0.02L0.01 1.43L3.58 5L0 8.59L1.41 10L5 6.42L8.58 10L9.99 8.58L6.41 5L10 1.41Z"
            fill="#231F20"
        />
    </svg>
);
export default CloseBoldIcon;
