import React from 'react';
import { Col, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { DataCard } from 'modules/ui';
import { useElevator, useElevatorDetailApi } from 'modules/entities/modules/elevators';

import { TemparatureGraph } from './TemparatureGraph';
import { TemperatureTag } from './TemperatureTag';

export const ShaftTemperature = ({ isDeactivated }: { isDeactivated?: boolean }) => {
    const api = useElevatorDetailApi();

    let elevator = useElevator();

    if (isDeactivated) {
        // @ts-ignore
        elevator = {
            shaftTemperature: 16.06,
        };

        return <div />;
    }

    if (api.success && !elevator.shaftTemperature) {
        return null;
    }

    if (elevator?.shaftTemperature > 45 || elevator?.shaftTemperature < 10) {
        return <div />;
    }

    return (
        <Col xs={24} lg={12} xl={24}>
            <DataCard
                style={{ filter: isDeactivated ? 'blur(1px)' : 'none' }}
                title={
                    <Typography.Title level={4}>
                        <FormattedMessage id="page.elevatorDetail.tabs.iotData.shaftTemperature.heading" />
                    </Typography.Title>
                }
                loading={api.inProgress}
                error={api.error}
            >
                <TemparatureGraph temperature={elevator?.shaftTemperature} />
                {!isDeactivated && <TemperatureTag temperature={elevator?.shaftTemperature} />}
            </DataCard>
        </Col>
    );
};
