import type { TRule } from 'fela';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    padding: '.5rem .75rem',
    textAlign: 'center',
    boxShadow: `0 6px 16px 0 ${theme.colors.backdrop}`,
    borderRadius: theme.borderRadius,
});
export const label: TRuleWithTheme = ({ theme }) => ({
    fontSize: '.75rem',
    color: theme.colors.textSecondary,
    margin: '0',
});
export const totalTrips: TRuleWithTheme = ({ theme }) => ({
    textAlign: 'center',
    fontSize: '.625rem',
    color: theme.colors.textSecondary,
    margin: '0',
});
export const line: TRuleWithTheme = ({ theme }) => ({
    height: 0,
    border: 0,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.dividerColor,
    marginLeft: '-.75rem',
    marginRight: '-.75rem',
});
export const description: TRule = () => ({
    fontSize: '1rem',
    marginBottom: '0',
});
export const dataWrapper: TRule = () => ({
    marginBottom: '.25rem',

    ':last-of-type': {
        marginBottom: 0,
    },
});
