import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/actions';
import { selectdeactivateActivateUserRequestApi } from '../services/selector';
import { useEffect } from 'react';

export const useDeactivateActivateUser = () => {
    const dispatch = useDispatch();
    const api = useSelector(state => selectdeactivateActivateUserRequestApi(state))

    const handleSubmit = (user) => {
        dispatch(
            actions.deactivateActivateUserRequest({ userId: user.userId, isActive: !user.isActive, email: user.email }),
        );
    };

    useEffect(() => {
        if (api.success) {
            dispatch(actions.fetchUsers.fetchUsersRequest())
            dispatch(actions.deactivateActivateUserRequestReset())
        }
        if (api.error) {
            dispatch(actions.deactivateActivateUserRequestReset())
        }
    }, [api, dispatch])

    useEffect(() => {
        return () => {
            actions.deactivateActivateUserRequestReset();
        };
    }, []);

    return {
        handleSubmit,
        api,
    };
};
