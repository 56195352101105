import type { TRule } from 'fela';

export const menu: TRule = () => ({
    maxWidth: 140,
    marginLeft: 'auto',

    '& .ant-dropdown-menu-item': {
        padding: '0.75rem 1rem',
    },
});

export const logout: TRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,
    margin: 0,

    ':hover': {
        background: 'none',
    },
    ':active': {
        background: 'none',
    },
    ':focus': {
        background: 'none',
    },
    '& .anticon': {
        marginRight: '1.25em',
    },
});
