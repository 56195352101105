import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '@ackee/petrus';

import { useUIMessage } from 'modules/ui';

import { logoutApiSelector } from '../services/selectors';

export const useLogout = () => {
    const dispatch = useDispatch();

    return () => dispatch(logoutRequest());
};

export const useLogoutMessage = () => {
    const message = useUIMessage();
    const { success, error } = useSelector(logoutApiSelector);

    useEffect(() => {
        if (success) {
            message('info', 'auth.logout.success');
        }
    }, [success, message]);

    useEffect(() => {
        if (error) {
            message('error', 'auth.logout.error');
        }
    }, [error, message]);
};
