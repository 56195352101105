import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './OverallStatusAlert.styles';
import { Alert } from 'antd';
import { ElevatorStatusIcon } from '../ElevatorStatusIcon';
import { ElevatorState, isSupportedState } from 'modules/entities/modules/elevators';
import { Paragraph } from 'modules/ui';

type OverallStatusAlertProps = {
    state?: ElevatorState;
    descriptions?: ReactNode[];
};

const OverallStatusAlert: FC<OverallStatusAlertProps> = ({ state, descriptions }) => {
    const { styles } = useFelaEnhanced(felaRules, { state });

    const isUnknown = !isSupportedState(state);

    return (
        <Alert
            type="info"
            className={styles.alert}
            showIcon
            message={
                <div>
                    <FormattedMessage id={isUnknown ? 'elevator.status-group.UNKNOWN' : `elevator.status.${state}`} />
                    {/* <h3 className={styles.title}>{title}</h3> */}
                    {descriptions && (
                        <div className={styles.descriptionWrapper}>
                            {descriptions.map((description, index) => (
                                <Paragraph key={index} size="small" faded>
                                   <div>{description}</div> 
                                </Paragraph>
                            ))}
                        </div>
                    )}
                </div>
            }
            icon={<ElevatorStatusIcon state={state} />}
        />
    );
};

export default OverallStatusAlert;
