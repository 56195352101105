import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    uploadDocumentFailure,
    uploadDocumentRequest,
    uploadDocumentSuccess,
    uploadDocumentReset,
} from '../../actions';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: uploadDocumentRequest.toString(),
        SUCCESS: uploadDocumentSuccess.toString(),
        FAILURE: uploadDocumentFailure.toString(),
        RESET: uploadDocumentReset.toString(),
    },
});

export const uploadApiReducer = containerReducer({
    childReducer,
    actionTypes: [
        uploadDocumentRequest.toString(),
        uploadDocumentSuccess.toString(),
        uploadDocumentFailure.toString(),
        uploadDocumentReset.toString(),
    ],
    selectors: {
        itemId: (
            action:
                | ReturnType<typeof uploadDocumentRequest>
                | ReturnType<typeof uploadDocumentSuccess>
                | ReturnType<typeof uploadDocumentFailure>
                | ReturnType<typeof uploadDocumentReset>,
        ) => action.meta.uploadId,
    },
});
