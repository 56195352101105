import React, { FC } from 'react';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './PopoverContainer.styles';
import PopoverItem from './PopoverItem';

type PopoverProps = {
    extend?: RulesExtend<typeof felaRules>;
};

const PopoverContainer: FC<PopoverProps> & { Item: typeof PopoverItem } = ({ children, extend }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend });
    return <div className={styles.container}>{children}</div>;
};

PopoverContainer.Item = PopoverItem;
export default PopoverContainer;
