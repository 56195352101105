import React from 'react';

import { TableActionPanel, TableSort } from 'modules/table';
import type { Contract } from 'modules/entities/modules/documents-contracts';
import { ContractField, DEFAULT_SORT } from 'modules/entities/modules/documents-contracts';

import { DocumentsDownloadAll } from '../../DocumentsDownloadAll';

export const SORT_FIELDS = [
    ContractField.STATUS,
    ContractField.CONTRACT_TYPE,
    ContractField.FRAMEWORK_AGREEMENT,
    ContractField.START_DATE,
    ContractField.VALID_UNTIL,
    ContractField.CANCEL_UNTIL,
    ContractField.SERVICE_PROVIDER,
    ContractField.ELEVATORS,
    ContractField.NET_PRICE,
];

interface ContractsActionPanelProps {
    documents: Contract[];
}

export const ContractsActionPanel = ({ documents }: ContractsActionPanelProps) => (
    <TableActionPanel testId="contracts-actions">
        <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
        <DocumentsDownloadAll documents={documents} />
    </TableActionPanel>
);
