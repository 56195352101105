import React from 'react';
import type { ProColumns } from '@ant-design/pro-table';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';

import type { Invoice } from 'modules/entities/modules/documents-invoices';
import { Tables, StatusIcon, TableDataSpace } from 'modules/table';

import config from 'config/index';
import InvoiceItemInfo from '../InvoiceItemInfo';
import { DocumentElevator } from '../../DocumentElevator';

const defaultColumns: ProColumns<Invoice>[] = [
    {
        title: '',
        key: 'status',
        search: false,
        sorter: null,
        render: (_, record) => <StatusIcon table={Tables.INVOICES} state={record.status} />,
    },
    {
        title: '',
        key: 'items.elevator.elevatorId',
        dataIndex: 'items',
        search: false,
        render: (_, record) =>
            record.items.map(record => (
                <DocumentElevator key={record.elevator.elevatorId} elevator={record.elevator} />
            )),
    },
    {
        title: <FormattedMessage id="documents.table.invoiceReason" />,
        key: 'invoiceReason',
        dataIndex: 'invoiceReason',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.invoiceReason" />}
                value={record.invoiceReason}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.invoiceDate" />,
        key: 'invoiceDate',
        dataIndex: 'invoiceDate',
        valueType: 'date',
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.invoiceDate" />}
                value={<FormattedDate value={record.invoiceDate} />}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.discountPeriod" />,
        key: 'discountPeriod',
        dataIndex: 'discountPeriod',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.discountPeriod" />}
                value={record.discountPeriod}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.creditPeriod" />,
        key: 'creditPeriod',
        dataIndex: 'creditPeriod',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.creditPeriod" />}
                value={record.creditPeriod}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.totalReduction" />,
        key: 'totalReduction',
        dataIndex: 'totalReduction',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.totalReduction" />}
                value={
                    <FormattedNumber
                        value={record.totalReduction}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency={config.currency}
                    />
                }
            />
        ),
    },
    {
        title: '',
        key: 'netTotal',
        dataIndex: 'netTotal',
        valueType: 'text',
        sorter: null,
        render: (_, record) => <InvoiceItemInfo invoice={record} />,
    },
];

export const invoiceElevatorDetailColumns: ProColumns<Invoice>[] = [
    {
        title: '',
        key: 'status',
        search: false,
        sorter: null,
        render: (_, record) => <StatusIcon table={Tables.INVOICES} state={record.status} />,
    },
    {
        title: <FormattedMessage id="documents.table.invoiceReason" />,
        key: 'invoiceReason',
        dataIndex: 'invoiceReason',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.invoiceReason" />}
                value={record.invoiceReason}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.invoiceDate" />,
        key: 'invoiceDate',
        dataIndex: 'invoiceDate',
        valueType: 'date',
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.invoiceDate" />}
                value={<FormattedDate value={record.invoiceDate} />}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.discountPeriod" />,
        key: 'discountPeriod',
        dataIndex: 'discountPeriod',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.discountPeriod" />}
                value={record.discountPeriod}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.creditPeriod" />,
        key: 'creditPeriod',
        dataIndex: 'creditPeriod',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.creditPeriod" />}
                value={record.creditPeriod}
            />
        ),
    },
    {
        title: <FormattedMessage id="documents.table.totalReduction" />,
        key: 'totalReduction',
        dataIndex: 'totalReduction',
        valueType: 'text',
        sorter: null,
        render: (_, record) => (
            <TableDataSpace
                title={<FormattedMessage id="documents.table.totalReduction" />}
                value={
                    <FormattedNumber
                        value={record.totalReduction}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency={config.currency}
                    />
                }
            />
        ),
    },
    {
        title: '',
        key: 'netTotal',
        dataIndex: 'netTotal',
        valueType: 'text',
        sorter: null,
        render: (_, record) => <InvoiceItemInfo invoice={record} />,
    },
];

export default defaultColumns;
