import React from 'react';

const NoIoTIcon = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="ic/24/no_iot" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M18.667,17 L5.333,17 C4.047,17 3,15.99 3,14.75 C3,13.51 4.047,12.5 5.333,12.5 C5.886,12.5 6.333,12.053 6.333,11.5 C6.333,8.468 8.875,6 12,6 C15.125,6 17.667,8.468 17.667,11.5 C17.667,12.053 18.114,12.5 18.667,12.5 C19.953,12.5 21,13.51 21,14.75 C21,15.99 19.953,17 18.667,17 M19.612,10.602 C19.157,6.889 15.917,4 12,4 C8.083,4 4.843,6.889 4.388,10.602 C2.451,11.026 1,12.725 1,14.75 C1,17.094 2.943,19 5.333,19 L18.667,19 C21.057,19 23,17.094 23,14.75 C23,12.725 21.549,11.026 19.612,10.602"
                id="Fill-1"
                fill="currentColor"
            />
            <polygon
                id="Fill-3"
                fill="currentColor"
                points="14 8.5859 12 10.5859 10 8.5859 8.586 9.9999 10.586 11.9999 8.586 13.9999 10 15.4139 12 13.4139 14 15.4139 15.414 13.9999 13.414 11.9999 15.414 9.9999"
            />
        </g>
    </svg>
);

export default NoIoTIcon;
