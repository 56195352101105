import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.ELEVATORS,
});

export const fetchElevatorsTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ELEVATORS_' }),
});

export const fetchElevatorTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_ELEVATOR_' }),
});

export const subscribeElevatorStateTypes = strictObjectAccess({
    ...apiRequestType({
        typePrefix: 'SUBSCRIBE_ELEVATOR_STATE_',
        types: ['REQUEST', 'FAILURE', 'CANCEL'],
    }),
});

export const UPDATE_ELEVATOR_STATE = 'elevators/UPDATE_ELEVATOR_STATE';
