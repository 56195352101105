import React, { FC } from 'react';
import { Button as AntButton } from 'antd';
import type { ButtonProps as AntButtonProps } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { RulesExtend } from 'styles/theme';

import * as felaRules from './Button.styles';

export interface ButtonProps extends AntButtonProps {
    active?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

const Button: FC<ButtonProps> = ({ extend, active, ...rest }) => {
    const { styles } = useFelaEnhanced(felaRules, { extend, active, ...rest });

    return <AntButton className={styles.button} {...rest} />;
};

export default Button;
