import { TRuleWithTheme } from 'styles/theme';
import type { TRule } from 'fela';

export const row: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
});

export const card: TRuleWithTheme = ({ theme }) => ({
    marginBottom: '1rem',

    ':last-of-type': {
        marginBottom: 0,
    },
});

export const divider: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    height: 1,
    backgroundColor: theme.colors.dividerColor,
    margin: '1.25rem 0',
});


export const container: TRuleWithTheme = ({ theme }) => ({
    maxWidth: theme.width.container,
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const title: TRule = () => ({
    '&.ant-typography': {
        fontSize: '1.5rem',
        marginBottom: 0,
    },
});

export const selectContainer: TRule = () => ({
    marginRight: '.5rem',

    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

export const pagination: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
});


export const dataCard: TRuleWithTheme = () => ({
    borderRadius: 0,
    borderRight: 0,
    borderLeft: 0,
    '& .ant-card-body': {
        paddingLeft: '0.9375rem',
        paddingRight: '0.9375rem',
        paddingTop: '0rem',
        paddingBottom: '0rem',
    },
});