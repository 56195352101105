import React, { lazy, Suspense } from 'react';
import type { ProTableProps } from '@ant-design/pro-table';

import { RulesExtend } from 'styles/theme';
import { useFelaEnhanced } from 'hooks';
import { Loader } from 'modules/ui';

import * as felaRules from './Table.styles';

const ProTable = lazy(() => import('@ant-design/pro-table'));

export interface TableProps<T = any> extends ProTableProps<T, {}> {
    hideSort?: boolean;
    showCurrentPaginationTotal?: boolean;
    extend?: RulesExtend<typeof felaRules>;
}

export function Table<T = any>({ extend, showCurrentPaginationTotal = true, hideSort = true, ...rest }: TableProps<T>) {
    const { styles } = useFelaEnhanced(felaRules, { extend, hideSort, showCurrentPaginationTotal });

    return (
        <Suspense fallback={<Loader show />}>
            <div className={styles.tableWrapper}>
                <ProTable tableClassName={styles.table} {...rest} />
            </div>
        </Suspense>
    );
}
