import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    updateIncidentFailure,
    updateIncidentRequest,
    updateIncidentSuccess,
    updateIncidentReset,
} from '../../actions';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: updateIncidentRequest.toString(),
        SUCCESS: updateIncidentSuccess.toString(),
        FAILURE: updateIncidentFailure.toString(),
        RESET: updateIncidentReset.toString(),
    },
});

export const updateApiReducer = containerReducer({
    childReducer,
    actionTypes: [
        updateIncidentRequest.toString(),
        updateIncidentSuccess.toString(),
        updateIncidentFailure.toString(),
        updateIncidentReset.toString(),
    ],
    selectors: {
        itemId: (
            action:
                | ReturnType<typeof updateIncidentRequest>
                | ReturnType<typeof updateIncidentSuccess>
                | ReturnType<typeof updateIncidentFailure>
                | ReturnType<typeof updateIncidentReset>,
        ) => action.meta.caseNumber,
    },
});
