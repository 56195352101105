import { TRuleWithTheme } from 'styles/theme';

export const divider: TRuleWithTheme = ({ theme }) => ({
    margin: 0,

    borderTop: `1px solid ${theme.colors.dividerColor}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
});
