import type { ApiReducerState } from '@ackee/redux-utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchIotConfig as actions } from '../services/actions';

import { selectIotConfigApi } from '../services/selectors';

export const useFetchIotConfig = (): ApiReducerState => {
    const api = useSelector(selectIotConfigApi);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchIotConfigRequest());

        return () => {
            dispatch(actions.fetchIotConfigCancel());
            dispatch(actions.fetchIotConfigReset());
        };
    }, [dispatch]);

    return api;
};
