import React, { FC, ReactNode } from 'react';
import { useFelaEnhanced } from 'hooks';
import { TooltipProps as ReTooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/src/component/DefaultTooltipContent';

import * as felaRules from './Tooltip.styles';

interface TooltipProps extends ReTooltipProps<ValueType, NameType> {
    names?: Record<string, ReactNode>;
}

const Tooltip: FC<TooltipProps> = ({ names, active, payload, label = ['value'] }) => {
    const { styles } = useFelaEnhanced(felaRules);
    if (!(active && payload?.length > 0)) {
        return null;
    }

    return (
        <div className={styles.container}>
            <p className={styles.label}>{payload[0].payload.tooltipValue ?? label}</p>
            <hr className={styles.line} />
            {payload.map(payloadItem => (
                <div key={payloadItem.dataKey} className={styles.dataWrapper}>
                    <p className={styles.totalTrips}>{names[payloadItem.dataKey]}</p>
                    <p className={styles.description}>{payloadItem.value}</p>
                </div>
            ))}
        </div>
    );
};

export default Tooltip;
