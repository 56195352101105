import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';

import { EntityKey } from 'constants/index';

import { ElevatorCoordinatesState, ProgressPayload, PropertyUnitCoordinates } from '../types';

const COORDINATES_EXPIRE_SECONDS = 60 * 60 * 24 * 90;

const initialState: ElevatorCoordinatesState = {
    coordinates: {},
    progress: 100,
};

const elevatorCoordinates = createSlice({
    name: EntityKey.ELEVATOR_COORDINATES,
    initialState,
    reducers: {
        geocodeCoordinates(state, action: PayloadAction<string[]>) {
            if (state.progress === initialState.progress) {
                state.progress = 0;
            }
        },
        setCoordinates(state, action: PayloadAction<PropertyUnitCoordinates>) {
            state.coordinates = {
                ...state.coordinates,
                ...action.payload,
            };
        },
        setProgress(state, action: PayloadAction<ProgressPayload>) {
            state.progress = (action.payload.done / action.payload.all) * 100;
        },
        finishGeocoding(state) {
            state.progress = 100;
        },
    },
});

const persistConfig: PersistConfig<any> = {
    key: EntityKey.ELEVATOR_COORDINATES,
    storage,
    whitelist: ['coordinates'],
    transforms: [
        expireReducer('coordinates', {
            expireSeconds: COORDINATES_EXPIRE_SECONDS,
            expiredState: {},
            autoExpire: true,
        }),
    ],
};

export const { geocodeCoordinates, setCoordinates, setProgress, finishGeocoding } = elevatorCoordinates.actions;
export const entityReducer = persistReducer(persistConfig, elevatorCoordinates.reducer);
