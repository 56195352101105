import React from 'react';
import { FormattedNumber } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { useTemperatureScaleAngle, lowToMid, midToHigh } from '../../../../hooks';

import * as felaRules from './TemperatureGraph.rules';

export interface TemparatureGraphProps {
    temperature: number;
}

export const TemparatureGraph = ({ temperature }: TemparatureGraphProps) => {
    const angle = useTemperatureScaleAngle(temperature);

    const { styles } = useFelaEnhanced(felaRules, { angle });

    return (
        <div className={styles.container} role="presentation">
            <svg className={styles.scale} viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip)">
                    <path
                        d="M197.98 100H177.98C177.45 100 176.941 99.7894 176.566 99.4144C176.191 99.0393 175.98 98.5306 175.98 98.0002C175.663 86.1427 172.58 74.5232 166.978 64.068C161.375 53.6128 153.407 44.611 143.71 37.7802L157.53 18.1602C170.367 27.2108 180.897 39.152 188.271 53.0205C195.645 66.8891 199.656 82.2966 199.98 98.0002C199.98 98.5306 199.769 99.0393 199.394 99.4144C199.019 99.7894 198.51 100 197.98 100Z"
                        fill="#E84E1B"
                    />
                    <path
                        d="M143.68 37.8102C130.895 28.7919 115.625 23.9664 99.98 24.0002C84.3346 23.9664 69.0647 28.7919 56.28 37.8102L42.47 18.1902C59.2911 6.31228 79.3879 -0.0442138 99.98 0.000231494C120.575 -0.0423243 140.674 6.3139 157.5 18.1902L143.68 37.8102Z"
                        fill="#FFD200"
                    />
                    <path
                        d="M21.98 100H1.97998C1.44955 100 0.940838 99.7895 0.565765 99.4144C0.190693 99.0394 -0.0200195 98.5306 -0.0200195 98.0002C0.314772 82.306 4.33155 66.9099 11.7067 53.0524C19.0818 39.195 29.609 27.2638 42.44 18.2202L56.26 37.8402C46.5687 44.664 38.6041 53.6558 33.0001 64.0999C27.3961 74.544 24.3075 86.1521 23.98 98.0002C23.98 98.5306 23.7693 99.0394 23.3942 99.4144C23.0191 99.7895 22.5104 100 21.98 100Z"
                        fill="#01BE19"
                    />
                </g>
                <defs>
                    <clipPath id="clip">
                        <rect width="200" height="100" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <span className={styles.lowerBound}>
                <FormattedNumber
                    value={lowToMid.temperature}
                    // eslint-disable-next-line react/style-prop-object
                    style="unit"
                    unit="celsius"
                />
            </span>
            <span className={styles.upperBound}>
                <FormattedNumber
                    value={midToHigh.temperature}
                    // eslint-disable-next-line react/style-prop-object
                    style="unit"
                    unit="celsius"
                />
            </span>
            <div className={styles.value} />
        </div>
    );
};
