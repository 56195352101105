import { createAction } from '@reduxjs/toolkit';

import { EntityKey } from 'constants/index';
import { createFetchFailureAction, createFetchSuccessAction } from 'services/utils/actions';

import type { Quotation } from '../types';

export const fetchQuotations = createAction(
    `${EntityKey.DOCUMENT_QUOTATIONS}/FETCH_QUOTATIONS`,
    ({ elevatorId }: { elevatorId: string }) => ({
        meta: {
            elevatorId,
        },
        payload: null,
    }),
);
export const fetchQuotationsRequest = createAction(`${EntityKey.DOCUMENT_QUOTATIONS}/FETCH_QUOTATIONS_REQUEST`);
export const fetchQuotationsSuccess = createFetchSuccessAction<Quotation>(
    `${EntityKey.DOCUMENT_QUOTATIONS}/FETCH_QUOTATIONS_SUCCESS`,
);
export const fetchQuotationsFailure = createFetchFailureAction(
    `${EntityKey.DOCUMENT_QUOTATIONS}/FETCH_QUOTATIONS_FAILURE`,
);
export const clearQuotations = createAction(`${EntityKey.DOCUMENT_QUOTATIONS}/CLEAR_QUOTATIONS`);
export const resetQuotations = createAction(`${EntityKey.DOCUMENT_QUOTATIONS}/RESET_QUOTATIONS`);
