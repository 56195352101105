import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchMaintenances as actions } from '../services/actions';
import { selectMaintenancesApi } from '../services/selectors';

export const useFetchMaintenances = () => {
    const api = useSelector(selectMaintenancesApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchMaintenancesRequest());

        return () => {
            batch(() => {
                dispatch(actions.fetchMaintenancesCancel());
                dispatch(actions.fetchMaintenancesReset());
            });
        };
    }, [dispatch]);

    return api;
};
