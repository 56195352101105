import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Checkbox, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import type { RulesExtend } from 'styles/theme';

import { useFelaEnhanced } from 'hooks';

import { ButtonCheckbox } from 'modules/ui';

import * as felaRules from './StatusFilter.rules';

export const SHOW_ALL = 'show_all';

const defaultValue = [SHOW_ALL];

export interface StatusFilterProps<Status extends string = string> {
    titleMessageId?: string;
    allMessageId: string;
    allIcon?: ReactNode;
    statuses: {
        icon: ReactNode;
        count: number;
        messageId: string;
        value: Status;
    }[];
    value: string[];
    onChange: (e: CheckboxChangeEvent) => void;
    extend?: RulesExtend<typeof felaRules>;
}

export function StatusFilter<Status extends string = string>({
    statuses,
    titleMessageId,
    allMessageId,
    allIcon = null,
    value = defaultValue,
    onChange,
    extend,
}: StatusFilterProps<Status>) {
    const { styles, rules } = useFelaEnhanced(felaRules, { extend });

    const allCount = useMemo(() => statuses.reduce((count, status) => (count += status.count), 0), [statuses]);

    return (
        <div className={styles.container}>
            {titleMessageId ? (
                <Typography.Title level={5} className={styles.title}>
                    <FormattedMessage id={titleMessageId} />
                </Typography.Title>
            ) : null}
            <Checkbox.Group value={value} className={styles.checkboxGroup}>
                <ButtonCheckbox
                    value={SHOW_ALL}
                    count={allCount}
                    onChange={onChange}
                    extend={{
                        checkbox: rules.checkbox,
                    }}
                >
                    <span className={styles.label}>
                        {allIcon}
                        <FormattedMessage id={allMessageId} />
                    </span>
                </ButtonCheckbox>
                {statuses.map(status => (
                    <ButtonCheckbox
                        key={status.value}
                        value={status.value}
                        count={status.count}
                        onChange={onChange}
                        extend={{
                            checkbox: rules.checkbox,
                        }}
                    >
                        {status.icon}
                        <span className={styles.label}>
                            <FormattedMessage id={status.messageId} />
                        </span>
                    </ButtonCheckbox>
                ))}
            </Checkbox.Group>
        </div>
    );
}
