import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    deactivateActivateUserRequestFailure,
    deactivateActivateUserRequest,
    deactivateActivateUserRequestSuccess,
    deactivateActivateUserRequestReset,
} from '../../actions'
import { DEACTIVATE_USER_SELECTOR_ID } from '../../../types';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: deactivateActivateUserRequest.toString(),
        SUCCESS: deactivateActivateUserRequestSuccess.toString(),
        FAILURE: deactivateActivateUserRequestFailure.toString(),
        RESET: deactivateActivateUserRequestReset.toString(),
    },
});

export const deactivateActivateUserReducer = containerReducer({
    childReducer,
    actionTypes: [
        deactivateActivateUserRequest.toString(),
        deactivateActivateUserRequestSuccess.toString(),
        deactivateActivateUserRequestFailure.toString(),
        deactivateActivateUserRequestReset.toString(),
    ],
    selectors: {
        itemId: () => DEACTIVATE_USER_SELECTOR_ID,
    },
});