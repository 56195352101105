import React from 'react';
import { useIntl } from 'react-intl';

import { SeoHead } from 'modules/seo';
import { Divider } from 'modules/ui';
import { TableFilterPanel } from 'modules/table';
import { useAssessmentReports } from 'modules/entities/modules/documents-assessment-reports';

import { AssessmentReportsTable } from './AssessmentReportsTable';
import { AssessmentReportsActionPanel } from './AssessmentReportsActionPanel';
import { AssessmentReportsStatusFilter } from './AssessmentReportStatusFilter';
import { AssessmentReportsSearch } from './AssessmentReportsSearch';
import { useRouteMatch } from 'react-router-dom';

export const AssessmenReportsTab = () => {
    const intl = useIntl();
    const { loading, documents, defaultSearch } = useAssessmentReports();
    const match = useRouteMatch('/elevators/:id');

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'documents.assessment-reports.title' })} />
            <TableFilterPanel>
                <AssessmentReportsStatusFilter />
                <Divider />
                <AssessmentReportsSearch defaultValue={defaultSearch} isElevatorDetail={match?.isExact} />
            </TableFilterPanel>
            <AssessmentReportsActionPanel documents={documents} />
            <AssessmentReportsTable loading={loading} documents={documents} />
        </>
    );
};
