import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import config from 'config';
import * as Log from 'config/loglevel';
import { actions as formActions, takeFormSubmission } from 'modules/form';
import { createUIErrorMessage } from 'modules/errors';

import type { ForgottenPasswordValues } from '../../types';
import { forms } from '../../config';
import { FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY } from '../../constants';

function* handleForgottenPasswordForm(action: formActions.types.SubmitFormAction<ForgottenPasswordValues>) {
    const { email, code, newPassword } = action.payload;

    try {
        yield Auth.forgotPasswordSubmit(email, code, newPassword);

        yield put(formActions.submitFormSuccess(forms.forgottenPassword));
        localStorage.removeItem(FORGOTTEN_PASSWORD_EMAIL_STORAGE_KEY);
        yield put(push(config.routes.login));
    } catch (error) {
        Log.error(error);

        const uiError = createUIErrorMessage(error);
        yield put(formActions.submitFormFailure(forms.forgottenPassword, uiError));
    }
}

export default function* forgottenPasswordForm() {
    yield takeFormSubmission(forms.forgottenPassword, handleForgottenPasswordForm);
}
