import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { SortingType, UrlParam } from 'constants/index';

import { useFelaEnhanced } from 'hooks';

import { useSearchParams } from 'services/hooks';
import { manipulateQueryString } from 'services/utils';

import { Paragraph, IconType } from 'modules/ui';
import { Select, OptionRenderer } from 'modules/form';

import * as felaRules from './TableSort.rules';

export interface TableSortProps {
    fields: (string | { field: string; labelId: string })[];
    defaultField?: string;
    defaultType?: SortingType;
    table?: string;
}

const getValue = (field: string, type: SortingType) => `${field}:${type}`;

export const TableSort = ({
    fields,
    defaultField = typeof fields[0] === 'string' ? fields[0] : fields[0].field,
    defaultType = SortingType.DESC,
    table = '',
}: TableSortProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const history = useHistory();
    const { search } = useLocation();

    const value = useSearchParams(UrlParam.SORT, null) as string;

    return (
        <div className={styles.container}>
            <Paragraph size="xsmall" extend={{ paragraph: rules.text }}>
                <FormattedMessage id="table.sortBy" />
            </Paragraph>
            <Select
                data-testid="table-sort"
                value={value ?? getValue(defaultField, defaultType)}
                onChange={(value: string) => {
                    history.push({ search: manipulateQueryString(value, UrlParam.SORT, search) });
                }}
                options={fields.flatMap(field => [
                    {
                        value: getValue(typeof field === 'string' ? field : field.field, SortingType.DESC),
                        label: (
                            <OptionRenderer
                                text={
                                    <FormattedMessage
                                        id={
                                            typeof field === 'string'
                                                ? `documents${table ? `.${table}` : ''}.table.${field}`
                                                : field.labelId
                                        }
                                    />
                                }
                                icon={IconType.SORT_DOWN}
                            />
                        ),
                    },
                    {
                        value: getValue(typeof field === 'string' ? field : field.field, SortingType.ASC),
                        label: (
                            <OptionRenderer
                                text={
                                    <FormattedMessage
                                        id={
                                            typeof field === 'string'
                                                ? `documents${table ? `.${table}` : ''}.table.${field}`
                                                : field.labelId
                                        }
                                    />
                                }
                                icon={IconType.SORT_UP}
                            />
                        ),
                    },
                ])}
            />
        </div>
    );
};
