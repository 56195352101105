import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { clearAssessmentReports, fetchAssessmentReports } from '../services/actions';
import { selectAssessmentReportsApi } from '../services/selectors';

export const useFetchAssessmentReports = () => {
    const dispatch = useDispatch();

    const api = useSelector(selectAssessmentReportsApi);
    const match = useRouteMatch('/elevators/:id');
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isElevatorDetail = useMemo(() => {
        return location ? match?.isExact : false;
    }, [location, match]);

    useEffect(() => {
        dispatch(fetchAssessmentReports({elevatorId: isElevatorDetail? id: null}));

        return () => {
            dispatch(clearAssessmentReports());
        };
    }, [dispatch, isElevatorDetail, id]);

    return api;
};
