import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';

import * as felaRules from './FakeSuccess.rules';

export const FakeSuccess = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.success}>
            <Icon type={IconType.COMING_SOON} className={styles.successIcon} />
            <Typography.Title level={2} className={styles.successTitle}>
                <FormattedMessage id="page.fakePages.success.title" />
            </Typography.Title>
            <Typography.Paragraph className={styles.successMessage}>
                <FormattedMessage id="page.fakePages.success.message" />
            </Typography.Paragraph>
        </div>
    );
};
