import React, { useRef } from 'react';
import type { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from 'modules/ui';

import * as felaRules from './NewElevatorDialogUploadExcelTooltip.rules';

export interface NewElevatorDialogUploadExcelTooltipProps {
    children: ReactNode[] | ReactNode;
}

export const NewElevatorDialogUploadExcelTooltip = ({ children }: NewElevatorDialogUploadExcelTooltipProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    const intl = useIntl();

    const ref = useRef();

    return (
        <span ref={ref}>
            <Tooltip overlay={children} overlayClassName={styles.tooltip} getTooltipContainer={() => ref.current}>
                <Icon
                    className={styles.icon}
                    type={IconType.UNKNOWN_ERROR}
                    aria-label={intl.formatMessage({
                        id: 'elevator.new.dialog.upload.excel.tooltipIcon',
                    })}
                />
            </Tooltip>
        </span>
    );
};
