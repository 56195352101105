import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Typography } from 'antd';

import { useFelaEnhanced } from 'hooks';
import { DataCard, DataSpace, Loader } from 'modules/ui';
import { selectors } from 'modules/entities/modules/elevators';

import { useAvailableCards } from '../../hooks';
import * as felaRules from './ComponentsTab.styles';

type ComponentsTabProps = {};

const ComponentsTab: FC<ComponentsTabProps> = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    const elevator = useSelector(selectors.selectElevator);
    const { inProgress } = useSelector(selectors.selectElevatorApi);
    const availableCards = useAvailableCards(elevator);

    return (
        <div className={styles.container}>
            <Loader show={inProgress}>
                {availableCards.map(card => (
                    <DataCard
                        key={card.key}
                        spacingSize="large"
                        title={
                            <Typography.Title level={4}>
                                <FormattedMessage id={card.nameId} />
                            </Typography.Title>
                        }
                        extend={{ card: rules.card }}
                        data-testid={card.key}
                    >
                        {card.rows
                            .filter(row => elevator[row.manufacturer] || elevator[row.model])
                            .map((row, index) => (
                                <div key={row.key} data-testid={row.key}>
                                    <Row>
                                        <Col md={6} className={styles.rowNameContainer}>
                                            <Typography.Text className={styles.rowName}>
                                                <FormattedMessage id={row.nameId} />
                                            </Typography.Text>
                                        </Col>
                                        {elevator[row.manufacturer] && (
                                            <Col md={6} data-test-id={row.manufacturer}>
                                                <DataSpace
                                                    name={
                                                        <FormattedMessage id="page.elevatorDetail.tabs.components.manufacturer" />
                                                    }
                                                    data={elevator[row.manufacturer]}
                                                />
                                            </Col>
                                        )}
                                        {elevator[row.model] && (
                                            <Col md={6} data-test-id={row.model}>
                                                <DataSpace
                                                    name={
                                                        <FormattedMessage id="page.elevatorDetail.tabs.components.model" />
                                                    }
                                                    data={elevator[row.model]}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    {card.rows.length > 1 && index + 1 !== card.rows.length && (
                                        <div className={styles.divider} />
                                    )}
                                </div>
                            ))}
                    </DataCard>
                ))}
            </Loader>
        </div>
    );
};

export default ComponentsTab;
