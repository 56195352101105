import React from 'react';

const ChevronUp = props => {
    return (
        <svg viewBox="0 0 12 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.002 0.000183105C6.258 0.000183105 6.515 0.0981832 6.71 0.293183L12 5.57618L10.595 7.00018L6.002 2.41418L1.41 6.99918L0 5.58118L5.294 0.293183C5.489 0.0981832 5.746 0.000183105 6.002 0.000183105Z"
                fill="#E84E1B"
            />
        </svg>
    );
};

export default ChevronUp;
