import React from 'react';
import type { ReactNode } from 'react';
import { RendererProvider, ThemeProvider } from 'react-fela';
import type { ProviderProps, ThemeProviderProps } from 'react-fela';
import { Router } from 'react-router';
import type { RouterProps } from 'react-router';
import { RawIntlProvider } from 'react-intl';
import type { IntlShape } from 'react-intl';

export interface InfoWindowProvidersProps {
    intl: IntlShape;
    history: RouterProps['history'];
    renderer: ProviderProps['renderer'];
    theme: ThemeProviderProps['theme'];
    children: ReactNode;
}

export const InfoWindowProviders = ({ intl, history, renderer, theme, children }: InfoWindowProvidersProps) => (
    <RawIntlProvider value={intl}>
        <Router history={history}>
            <RendererProvider renderer={renderer}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </RendererProvider>
        </Router>
    </RawIntlProvider>
);
