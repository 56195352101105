const DEFAULT_CENTER = { lat: 52.52437, lng: 13.41053 };
const DEFAULT_ZOOM = 12;
const MIN_ZOOM = 2;
const MAX_ZOOM = 18;

export const createMap = (ref: React.MutableRefObject<HTMLDivElement>) => {
    const map = new google.maps.Map(ref.current, {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        streetViewControl: false,
        restriction: {
            latLngBounds: {
                east: 180,
                north: 84,
                south: -84,
                west: -180,
            },
            strictBounds: true,
        },
        zoomControl: false,
    });

    return map;
};
