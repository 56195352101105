import { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { fetchUsers as actions } from '../services/actions';
import { selectFetchUsersApi } from '../services/selector';

export const useFetchUsers = () => {
    const api = useSelector(selectFetchUsersApi);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchUsersRequest());
        return () => {
            batch(() => {
                dispatch(actions.fetchUsersCancel());
                dispatch(actions.fetchUsersReset());
            });
        };
    }, [dispatch]);

    return api;
};
