import { createApiRequestType, strictObjectAccess } from '@ackee/redux-utils';
import { EntityKey } from 'constants/index';

const apiRequestType = createApiRequestType({
    modulePrefix: EntityKey.WORKORDERS,
});

export const fetchWorkorderTypes = strictObjectAccess({
    ...apiRequestType({ typePrefix: 'FETCH_WORKORDERS_' }),
});
