import { basicApiReducer, containerReducer } from '@ackee/redux-utils/es';

import {
    inviteUsersRequestFailure,
    inviteUsersRequest,
    inviteUsersRequestSuccess,
    inviteUsersRequestReset,
} from '../../actions';
import { INVITE_USERS_SELECTOR_ID } from '../../../types';

const childReducer = basicApiReducer({
    actionTypes: {
        REQUEST: inviteUsersRequest.toString(),
        SUCCESS: inviteUsersRequestSuccess.toString(),
        FAILURE: inviteUsersRequestFailure.toString(),
        RESET: inviteUsersRequestReset.toString(),
    },
});

export const inviteUsersReducer = containerReducer({
    childReducer,
    actionTypes: [
        inviteUsersRequest.toString(),
        inviteUsersRequestSuccess.toString(),
        inviteUsersRequestFailure.toString(),
        inviteUsersRequestReset.toString(),
    ],
    selectors: {
        itemId: () => INVITE_USERS_SELECTOR_ID,
    },
});
