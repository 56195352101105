import React from 'react';

const TechnicianOnSite = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ic/24/technician" transform="translate(-0.000000, -2.000000)" fill="currentColor">
                <path d="M9,10 L9,9 L15,9 L15,10 C15,11.654 13.654,13 12,13 C10.346,13 9,11.654 9,10 M11,4.142 L11,7 L8.142,7 C8.506,5.601 9.601,4.506 11,4.142 M15.858,7 L13,7 L13,4.142 C14.399,4.506 15.494,5.601 15.858,7 M15.305,13.723 C16.336,12.807 17,11.485 17,10 L17,9 L18,9 C18.553,9 19,8.553 19,8 C19,7.447 18.553,7 18,7 L17.91,7 C17.432,4.167 14.967,2 12,2 C9.033,2 6.568,4.167 6.09,7 L6,7 C5.447,7 5,7.447 5,8 C5,8.553 5.447,9 6,9 L7,9 L7,10 C7,11.485 7.664,12.807 8.695,13.723 C5.931,14.984 4,17.768 4,21 L4,22 L6,22 L6,21 C6,17.691 8.691,15 12,15 C15.309,15 18,17.691 18,21 L18,22 L20,22 L20,21 C20,17.768 18.069,14.984 15.305,13.723" id="Fill-1"></path>
            </g>
        </g>
    </svg>
);

export default TechnicianOnSite;
