import { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '& .recharts-cartesian-axis-tick-line': {
        display: 'none',
    },

    '& .recharts-cartesian-axis-line': {
        display: 'none',
    },

    '& .recharts-cartesian-grid-vertical': {
        display: 'none',
    },

    '& .recharts-text.recharts-cartesian-axis-tick-value': {
        fill: theme.colors.textSecondary,
        fontSize: '.625rem',
    },
});
