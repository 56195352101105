import React, { FC } from 'react';
import { useFelaEnhanced } from 'hooks';

import * as felaRules from './AuthPage.styles';
import backgroundImage from 'assets/images/login_illustration.jpg';

type AuthPageProps = {};

const AuthPage: FC<AuthPageProps> = ({ children }) => {
    const { styles } = useFelaEnhanced(felaRules, {backgroundImage});
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default AuthPage;
