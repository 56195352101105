import { DocumentFile, DocumentElevator } from 'modules/entities/modules/documents';

export enum WorkorderType {
    GBU = 'GBU',
    NBA = 'NBA',
    ZB = 'ZB',
}

export interface Workorder {
    id: string;
    elevator: DocumentElevator;
    file?: DocumentFile;
    highestRisk: number;
    inspectionRecommendation: string[];
    inspectionDate: string;
}

export enum WorkorderField {
    INSPECTION_DATE = 'inspectionDate',
    INSPECTION_DATE_FROM = 'inspectionDate.from',
    INSPECTION_DATE_TO = 'inspectionDate.to',
    INSPECTION_RECOMMENDATION = 'inspectionRecommendation',
    HIGHEST_RISK = 'highestRisk',
    ELEVATOR_FACTORY_NUMNER = 'elevator.factoryNumber',
    ELEVATOR_PROPERTY_UNIT_CITY = 'elevator.propertyUnit.city',
    ELEVATOR_PROPERTY_UNIT_STREET = 'elevator.propertyUnit.street',
    ELEVATOR_PROPERTY_UNIT_BUSINESS_UNIT = 'elevator.propertyUnit.businessUnit',
}
