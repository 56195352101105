import React, { FC } from 'react';
import TeamsSearchPanel from '../TeamsSearchPanel';
import TeamsTable from '../TeamsTable';
import { useFelaEnhanced } from 'hooks';
import * as felaRules from './TeamPage.styles';
import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import { TeamDialogState } from 'modules/teams/context/TeamDialogContext';
import { useUsers } from 'modules/entities/modules/teams/hooks';

interface TeamsPageProps {}

const TeamsPage: FC<TeamsPageProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const { setState } = useTeamDialog();
    const { loading, users, defaultSearch } = useUsers();
    return (
        <div className={styles.teamPage}>
            <div className={styles.header}>
                <Typography.Title level={4} className={styles.title}>
                    <FormattedMessage id="teams.pageTitle" />
                </Typography.Title>

                <Button type="primary" size="large" onClick={() => setState(TeamDialogState.INVITE_USER)}>
                    <FormattedMessage id="teams.inviteUsers.cta" />
                </Button>
            </div>
            <TeamsSearchPanel defaultSearch={defaultSearch} />
            <TeamsTable loading={loading} users={users} />
        </div>
    );
};

export default TeamsPage;
