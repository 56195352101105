import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/actions';
import { selectEditNotificationRequestApi } from '../services/selector';
import { useEffect } from 'react';

export const useEditNotification = () => {
    const dispatch = useDispatch();
    const api = useSelector(state => selectEditNotificationRequestApi(state));

    const handleSubmit = ({ notifications, userId, email }) => {
        dispatch(
            actions.editNotificationRequest({
                notifications,
                userId,
                email
            }),
        );
    };

    useEffect(() => {
        if (api.success) {
            dispatch(actions.fetchUsers.fetchUsersRequest())
            dispatch(actions.editNotificationRequestReset())
        }
        if (api.error) {
            dispatch(actions.editNotificationRequestReset())
        }
    }, [api, dispatch])

    useEffect(() => {
        actions.editNotificationRequestReset();
        
        return () => {
            actions.editNotificationRequestReset();
        };
    }, []);

    return {
        handleSubmit,
        api,
    };
};
