import type { TRule } from 'fela';

export const Notification: TRule = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const title: TRule = () => ({
    marginBottom: '0 !important',
    padding: 0,
    fontSize: '16px',
});

export const toggle: TRule = () => ({
    margin: '0 !important',
});

export const text: TRule = () => ({
    color: '#8F8F8F',
    fontSize: '12px',

    '> strong': {
        color: '#262626',
    },
});
