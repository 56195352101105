import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.recharts-responsive-container': {
        paddingTop: '2rem',
        marginBottom: '2.5rem',
    },

    '& svg.recharts-surface': {
        overflow: 'visible',
    },

    '& .recharts-text.recharts-cartesian-axis-tick-value': {
        fill: theme.colors.text,
        fontSize: '.875rem',
        textAlign: 'left',
    },

    '& .recharts-legend-wrapper ul': {
        marginTop: '1rem',
        marginBottom: 0,
    },
});
