import type { TRule } from 'fela';
import { Theme } from 'styles/theme';

export const button: TRule<{ size?: string; type?: string; theme: Theme; active?: boolean }> = ({
    size,
    type,
    theme,
    active,
}) => {
    const isPrimary = type === 'primary';
    const isMiddleSize = !size || size === 'middle';

    let color = null;

    if (active && type === 'link') {
        color = theme.colors.primary;
    }

    if (isPrimary) color = theme.colors.white;

    return {
        fontSize: isMiddleSize ? 14 : null,
        color: color ?? 'inherit',

        '&:hover': isPrimary
            ? {
                  background: theme.colors.btnPrimaryHover,
                  borderColor: theme.colors.btnPrimaryHover,
              }
            : {
                  color: theme.colors.primary,
              },

        '& a': {
            transition: 'none',
        },
    };
};
