import React from 'react';
import { useIntl } from 'react-intl';

import { Button, Typography } from 'antd';

import type { RcFile } from 'antd/lib/upload';

import { useFelaEnhanced } from 'hooks';

import { Icon, IconType } from '../../Icon';

import * as felaRules from './DropZoneFile.rules';

export interface DropZoneFileProps {
    file: RcFile;
    disabled: boolean;
    onRemove: (file: RcFile) => void;
}

export const DropZoneFile = ({ file, disabled, onRemove }: DropZoneFileProps) => {
    const intl = useIntl();

    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.attachment} data-testid="dropzone-file">
            <div>
                <Icon type={IconType.DOCUMENT_SMALL} className={styles.docIcon} />
                <Typography.Text className={styles.name}>{file.name}</Typography.Text>
            </div>
            <Button
                aria-label={intl.formatMessage({
                    id: 'general.upload.remove',
                })}
                disabled={disabled}
                icon={<Icon type={IconType.TRASH} className={styles.trashIcon} />}
                className={styles.trashButton}
                onClick={() => {
                    onRemove(file);
                }}
            />
        </div>
    );
};
