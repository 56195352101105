import { ApiAction } from 'modules/entities/types';

import { MeasureName } from '../../../types';
import type { ElevatorDetail, CurrentFloor } from '../../../types';

import {
    fetchElevatorTypes,
    UPDATE_ELEVATOR_STATE,
    UpdateElevatorStateAction,
    UpdateElevatorFloorHistoryAction,
    updateElevatorFloorHistory,
} from '../../actions';

import { parseFloor } from '../../utils';

const initialState: ElevatorDetail = null;

export const currentFloorReducer = (
    state: CurrentFloor | null = null,
    action: UpdateElevatorFloorHistoryAction,
): CurrentFloor => {
    switch (action.type) {
        case updateElevatorFloorHistory.toString(): {
            const { payload } = action;

            if (payload.measure_name === MeasureName.FLOOR) {
                return {
                    floor: parseFloor(payload.measure_value),
                    time: payload.time,
                };
            }

            return state;
        }
        default: {
            return state;
        }
    }
};

export default (
    state: ElevatorDetail = initialState,
    action: ApiAction | UpdateElevatorStateAction | UpdateElevatorFloorHistoryAction,
): ElevatorDetail => {
    switch (action.type) {
        case fetchElevatorTypes.FETCH_ELEVATOR_SUCCESS: {
            const elevator: ElevatorDetail = action.payload;

            return elevator;
        }

        case fetchElevatorTypes.FETCH_ELEVATOR_RESET:
            return initialState;

        case UPDATE_ELEVATOR_STATE:
            return {
                ...state,
                state: {
                    ...state.state,
                    state: action.payload.state,
                },
            };

        case updateElevatorFloorHistory.toString(): {
            if (state) {
                return {
                    ...state,
                    currentFloor: currentFloorReducer(state.currentFloor, action as UpdateElevatorFloorHistoryAction),
                };
            }

            return state;
        }

        default:
            return state;
    }
};
