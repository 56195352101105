import { ApiReducerState } from '@ackee/redux-utils/es/config';
import { apiSelector } from '@ackee/redux-utils/es';
import { createSelector } from 'reselect';
import get from 'lodash/get';

import { ApiReducerKey, EntityKey, Sorting, Search, SortingType, URL_ARRAY_SEPARATOR } from 'constants/index';
import { generateUTCDate, compareValueByType } from 'services/utils';

import {
    dateComparatorFactory,
    numberComparatorFactory,
    stateComparatorFactory,
    stringComparatorFactory,
} from 'modules/table/utils/comparators';
import { Fields } from 'modules/documents/components/OtherDocumentsTab/types';
import { Tables } from 'modules/table/types';

import { OtherDocument, OtherDocumentState } from '../types';

export const selectOtherDocumentsApi = (state): ApiReducerState =>
    apiSelector(state, EntityKey.DOCUMENT_OTHER, ApiReducerKey.LIST);

export const selectOtherDocumentsEntityList = (state): OtherDocument[] =>
    state.entities[EntityKey.DOCUMENT_OTHER][ApiReducerKey.LIST];

export const selectOtherDocuments = createSelector(selectOtherDocumentsEntityList, (documents): OtherDocument[] =>
    documents.map(document => ({
        ...document,
        status: document.status?.toLowerCase().replaceAll(' ', '_') as OtherDocumentState,
        file: document.file ?? null,
    })),
);

function chooseDocumentsComparator(document: OtherDocument, sorting: Sorting) {
    switch (sorting.field) {
        case Fields.STATUS:
            return stateComparatorFactory(sorting, Tables.OTHER_DOCUMENTS);
        case Fields.DATE:
            return dateComparatorFactory(sorting);
        default:
            const field = document[sorting.field];

            return ['number', 'boolean'].includes(typeof field)
                ? numberComparatorFactory(sorting)
                : stringComparatorFactory(sorting);
    }
}

export const selectCountOtherDocumentsByState = createSelector(selectOtherDocuments, docs => {
    const counts = Object.values(OtherDocumentState).reduce((map, status) => {
        if (status === OtherDocumentState.SHOW_ALL) {
            map[status] = docs.length;
        } else {
            map[status] = docs.filter(doc => doc.status === status).length;
        }
        return map;
    }, {});
    return counts;
});

export const selectOtherDocumentTypeSearchOptions = createSelector(selectOtherDocuments, docs =>
    Array.from(new Set(docs.map(doc => doc.documentType)))
        .map(documentType => ({ value: documentType, label: documentType }))
        .filter(Boolean)
        .sort(stringComparatorFactory({ field: 'value', type: SortingType.ASC })),
);

export const selectSortedDocuments = createSelector(
    [selectOtherDocuments, (_, { sorting }: { sorting: Sorting }) => sorting],
    (documents, sorting) => {
        if (!sorting || documents.length === 0) {
            return documents;
        }

        const comparator = chooseDocumentsComparator(documents[0], sorting);

        return [...documents].sort(comparator);
    },
);

export const selectFilteredSortedOtherDocuments = createSelector(
    [
        selectSortedDocuments,
        (
            _,
            {
                search,
            }: {
                search: Search[];
            },
        ) => search,
    ],
    (docs, search) =>
        docs.filter(doc =>
            search.every(({ field, query }) => {
                if (field.startsWith('elevators.')) {
                    const elevatorField = field.split('elevators.')[1];

                    return doc.elevators.some(item =>
                        compareValueByType({
                            value: get(item, elevatorField),
                            query,
                        }),
                    );
                }

                if (field.startsWith('documentDate.')) {
                    const foundItemTime = generateUTCDate(new Date(doc.documentDate)).getTime();
                    const utcQueryTime = generateUTCDate(new Date(query)).getTime();

                    if (field === 'documentDate.from') {
                        return foundItemTime >= utcQueryTime;
                    }

                    if (field === 'documentDate.to') {
                        return foundItemTime <= utcQueryTime;
                    }
                }

                if (field === 'documentType') {
                    const types = query.split(URL_ARRAY_SEPARATOR);
                    return types.some(type =>
                        compareValueByType({
                            value: doc.documentType,
                            query,
                        }),
                    );
                }

                if (field === 'status') {
                    return query.includes(doc.status);
                }

                return compareValueByType({
                    value: get(doc, field),
                    query,
                });
            }),
        ),
);
