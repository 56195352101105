import { HTTPStatusCode, errorMessages as defaultErrorMessages, UIErrorMessages, UIMessageID } from '../../config';

interface ErrorKeys {
    status?: HTTPStatusCode;
    code?: string;
}

const getApiErrorData = error => error?.response?.data ?? error;

export const parseApiError = (error): ErrorKeys => {
    const errorData = getApiErrorData(error);

    return {
        status: error?.response?.status ?? errorData?.status,
        code: errorData?.errorCode ?? errorData?.code,
    };
};

const findErrorMessages = (keys: ErrorKeys, messages: UIErrorMessages): UIMessageID => {
    if (messages?.code && messages.code[keys?.code]) {
        return messages.code[keys.code];
    }

    if (messages?.status && messages.status[keys?.status]) {
        return messages.status[keys.status];
    }

    return null;
};

export const createUIErrorMessage = (error: object, messages: UIErrorMessages = defaultErrorMessages): UIMessageID => {
    const errorKeys = parseApiError(error);
    const errorMessageId = findErrorMessages(errorKeys, messages);

    return errorMessageId ?? messages.fallbackId;
};
