import React, { useMemo } from 'react';

import { useFelaEnhanced } from 'hooks';

import { URL_ARRAY_SEPARATOR } from 'constants/index';

import { Icon, IconType } from 'modules/ui';
import { StatusFilter, SHOW_ALL } from 'modules/table';
import type { StatusFilterProps } from 'modules/table';
import { ElevatorStatusIcon } from 'modules/elevators';
import { ElevatorState, ElevatorStateGroup, useElevatorsCountByState } from 'modules/entities/modules/elevators';

import * as felaRules from './ElevatorMapStatusFilter.rules';

const filters = [
    ElevatorState.RUNNING,
    ElevatorState.POTENTIAL_ISSUES,
    ElevatorState.OUT_OF_ORDER,
    ElevatorState.TECHNICIAN_ON_SITE,
];

type StatusFilter = StatusFilterProps<ElevatorState | ElevatorStateGroup>['statuses'][number];

export interface ElevatorMapStatusFilterProps {
    value: string | undefined;
    onChange: (value: string) => void;
}

export const ElevatorMapStatusFilter = ({ value, onChange }: ElevatorMapStatusFilterProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const countByState = useElevatorsCountByState();

    const filterValue = useMemo(() => (value ? value.split(URL_ARRAY_SEPARATOR) : undefined), [value]);

    const states = filters.map<StatusFilter>(state => ({
        icon: <ElevatorStatusIcon state={state} extend={{ icon: rules.icon }} />,
        value: state,
        count: countByState[state],
        messageId: `page.elevators.filter.${state}`,
    }));

    states.push({
        icon: <ElevatorStatusIcon extend={{ icon: rules.icon }} />,
        value: ElevatorStateGroup.UNKNOWN,
        count: countByState[ElevatorStateGroup.UNKNOWN],
        messageId: `page.elevators.filter.${ElevatorStateGroup.UNKNOWN}`,
    });

    return (
        <StatusFilter<ElevatorState | ElevatorStateGroup>
            allMessageId="page.elevators.filter.SHOW_ALL"
            allIcon={<Icon type={IconType.ELEVATOR} className={styles.icon} />}
            statuses={states}
            value={filterValue}
            onChange={e => {
                if (e.target.value === SHOW_ALL) {
                    onChange(null);

                    return;
                }

                onChange(
                    e.target.checked
                        ? [...(filterValue ?? []), e.target.value].join(URL_ARRAY_SEPARATOR)
                        : (filterValue ?? []).filter(v => v !== e.target.value).join(URL_ARRAY_SEPARATOR),
                );
            }}
            extend={{
                container: rules.container,
            }}
        />
    );
};
