import { combineReducers } from 'redux';

import { ApiReducerKey } from 'constants/index';

import avgList from './avgList';
import totalList from './totalList';

export default combineReducers({
    [ApiReducerKey.AVERAGE_LIST]: avgList,
    [ApiReducerKey.TOTAL_LIST]: totalList,
});
