import React from 'react';

import { useFelaEnhanced } from 'hooks';

import type { RulesExtend } from 'styles/theme';

import { Icon, IconType } from 'modules/ui';

import { ElevatorState, ElevatorStateGroup, isSupportedState } from 'modules/entities/modules/elevators';

import * as felaRules from './ElevatorStatusIcon.styles';

interface ElevatorStatusIconProps {
    state?: ElevatorState;
    extend?: RulesExtend<typeof felaRules>;
}

export const ElevatorStatusIcon = ({ state, extend }: ElevatorStatusIconProps) => {
    const { styles } = useFelaEnhanced(felaRules, { state, extend });

    return (
        <Icon
            type={IconType.ELEVATOR_STATUS}
            data-testid={`status-icon-${isSupportedState(state) ? state : ElevatorStateGroup.UNKNOWN}`}
            className={styles.icon}
        />
    );
};
